import queryString from 'query-string'

export function queryParams() {
  return queryString.parse(window.location.search)
}

export function updateURLSearch(update = {}) {
  const current = queryString.parse(window.location.search)
  const updated = {
    ...current,
    ...update,
  }

  return queryString.stringify(updated, {
    skipEmptyString: true,
    skipNull: true,
  })
}
