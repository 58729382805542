export const OPERATORS = {
  OR: { label: 'Any one', value: 'or' },
  AND: { label: 'All', value: 'and' },

  WITHIN: { label: 'Within range', value: 'within_range', range: true },
  NOT_WITHIN: { label: 'Not in range', value: 'not_within_range', range: true },

  GREATER_THAN: { label: 'Greater than', value: 'more_than' },
  GREATER_THAN_EQ: {
    label: 'Greater than or equal to',
    value: 'more_than_equal',
  },
  LESS_THAN: { label: 'Less than', value: 'less_than' },
  LESS_THAN_EQ: { label: 'Less than or equal to', value: 'less_than_equal' },

  CONTAINS: { label: 'Contains', value: 'contains' },
  DOES_NOT_CONTAINS: { label: 'Does not contain', value: 'does_not_contains' },

  IN: { label: 'In', value: 'in', list: true },
  NOT_IN: { label: 'Not in', value: 'not_in', list: true },

  IS: { label: 'Equal to', value: 'is' },
  IS_NOT: { label: 'Not equal to', value: 'is_not' },
}

export const TRIGGERS = [
  {
    label: 'customer_accepted_marketing',
    name: 'Customer Has accepted marketing',
    help: '',
    object: 'Customer',
    dataType: 'bool',
    operators: ['is', 'is_not'],
    disabled: true,
  },
  {
    label: 'customer_order_count',
    name: "Customer's Order Count",
    help: '',
    object: 'Customer',
    dataType: 'number',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
    disabled: true,
  },
  {
    label: 'customer_register',
    name: 'Customer is registered',
    help: '',
    object: 'Customer',
    dataType: 'bool',
    operators: ['is', 'is_not'],
    disabled: true,
  },
  {
    label: 'customer_tags',
    name: 'Customer has tags',
    help: '',
    object: 'Customer',
    dataType: 'string',
    operators: ['in', 'not_in'],
    disabled: true,
  },
  {
    label: 'order_item_count',
    name: 'Order Items Count',
    help: '',
    object: 'Order',
    dataType: 'number',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
  },
  {
    label: 'order_item_quantity',
    name: 'Order Quantity',
    help: '',
    object: 'Order',
    dataType: 'number',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
  },
  {
    label: 'order_total',
    name: 'Order Total',
    help: '',
    object: 'Order',
    dataType: 'currency',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
  },
  {
    label: 'product_discount',
    name: 'A product with a discount..',
    help: '',
    object: 'Product',
    dataType: 'percent',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
  },
  {
    label: 'product_in_order',
    name: 'Product in order..',
    help: '',
    object: 'Product',
    dataType: 'variant',
    operators: ['in', 'not_in'],
  },
  {
    label: 'product_metafield',
    name: 'Product in order with metafield..',
    help: 'Add "pp_upsell" key in "glood_ai" namespace to trigger showing upsell offer',
    object: 'Product',
    dataType: 'any',
    operators: ['exist', 'does_not_exist'],
    disabled:true
  },
  {
    label: 'product_price',
    name: 'Product in order with Price..',
    help: '',
    object: 'Product',
    dataType: 'currency',
    operators: ['is', 'less_than', 'more_than', 'within_range'],
  },
  {
    label: 'product_sku',
    name: 'Product in order with SKU..',
    help: '',
    object: 'Product',
    dataType: 'string',
    operators: ['contains', 'does_not_contains', 'is'],
  },
  {
    label: 'product_tags',
    name: 'Product in order with tags..',
    help: '',
    object: 'Product',
    dataType: 'string',
    operators: ['in', 'not_in'],
  },
  {
    label: 'product_title',
    name: 'Product in order with title..',
    help: '',
    object: 'Product',
    dataType: 'string',
    operators: ['contains', 'does_not_contains', 'is'],
  },
  {
    label: 'product_vendor',
    name: 'Product in order with vendor..',
    help: '',
    object: 'Product',
    dataType: 'string',
    operators: ['is', 'is_not', 'in', 'not_in'],
  },
  {
    label: 'shipping_country',
    name: 'Shipping Country',
    help: '',
    object: 'Country',
    dataType: 'country',
    operators: ['in', 'not_in'],
  },
]
