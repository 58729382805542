import React, { useRef } from 'react'

import { SingleSelect } from 'components/EditorForm'
import { useHelpArticle } from 'components/HelpArticle'
import { PROMOTION_RECOMMENDATION_TYPES } from 'constants/promotions'
import { CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES } from 'constants/support'
import { _find } from 'utils/lodash'

import Manual from './Manual'
import PersonalizedRecommendations from './PersonalizedRecommendations'
import Metafields from './Metafields'

export default function PromotionBuilderLayoutUpsellProductSelection({
  value,
  handleUpdate,
}) {
  const initialValue = useRef(value)
  const { recommendationType, recommendationRules } = value
  const recTypes = Object.keys(PROMOTION_RECOMMENDATION_TYPES).map(
    (k) => PROMOTION_RECOMMENDATION_TYPES[k]
  )
  const recommendationTypeObject = _find(recTypes, {
    value: recommendationType,
  })

  let RuleComponent = null
  if (recommendationTypeObject) {
    RuleComponent = RECOMMENDATION_TYPE_TO_COMPONENT_MAP[recommendationType]
  }

  return (
    <div className="flex flex-col items-stretch">
      <SingleSelect
        value={value.recommendationType}
        onChange={(newValue) => {
          handleUpdate({
            recommendationType: newValue,
            recommendationRules:
              newValue === initialValue.current?.recommendationType
                ? initialValue.current?.recommendationRules || {}
                : {},
          })
        }}
        controlProps={{
          options: recTypes.filter((option) =>
            typeof option.visible === 'function' ? option.visible() : true
          ),
          label: 'Recommendations',
        }}
      />
      {recommendationTypeObject && recommendationTypeObject.help ? (
        <div className="flex mt-3 text-xs text-gray-600">
          {recommendationTypeObject.value === 'metafields' ? (
            <MetaFieldsHelp />
          ) : (
            recommendationTypeObject.help
          )}
        </div>
      ) : null}
      {RuleComponent && (
        <div className="mt-4">
          <RuleComponent
            value={recommendationRules}
            handleUpdate={(rulesUpdate, errors) => {
              handleUpdate(
                {
                  recommendationRules: rulesUpdate,
                },
                errors
              )
            }}
          />
        </div>
      )}
    </div>
  )
}

function MetaFieldsHelp() {
  const openHelpArticle = useHelpArticle()

  return (
    <span>
      Recommendations will be picked from your meta field configuration.{' '}
      <span
        className="text-xs font-semibold cursor-pointer text-primary hover:underline"
        onClick={() =>
          openHelpArticle(
            CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES.METAFIELDS_CONFIGURATION
          )
        }
      >
        Learn more
      </span>
    </span>
  )
}

const RECOMMENDATION_TYPE_TO_COMPONENT_MAP = {
  [PROMOTION_RECOMMENDATION_TYPES.MANUAL.value]: Manual,
  [PROMOTION_RECOMMENDATION_TYPES.PR.value]: PersonalizedRecommendations,
  [PROMOTION_RECOMMENDATION_TYPES.METAFIELDS.value]: Metafields,
}
