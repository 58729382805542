import React from 'react'
import { Helmet } from 'react-helmet'

export default function ExternalScripts() {
  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '3166545866699995');
fbq('track', 'PageView');`}
        </script>

        {/* Start of HubSpot Embed Code  */}
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/22080564.js"
        ></script>
        {/* End of HubSpot Embed Code */}

        {/* Start of HubSpot Forms Embed Code  */}
        <script
          charset="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/v2.js"
        ></script>
        {/* End of HubSpot Forms Embed Code  */}

        {/* Start of Segment Embed Code  */}
        <script>
          {`!function () {
            var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
            analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
              analytics.load("mG3XLgtzfOFJiTwp3fHjawCNaQmb8Jyr");
              analytics.page();
            }
          }();`}
        </script>
        {/* End of Segment Embed Code  */}

        {/* Start of Google Analytics Embed Code  */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-138147486-3"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());

          gtag('config', 'UA-138147486-3');`}
        </script>
        {/* End of Google Analytics Embed Code  */}

        {/* Start of Clarity Embed Code  */}
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "f2o0e9z973");`}
        </script>
        {/* End of Clarity Embed Code  */}
      </Helmet>
      <noscript>
        <img
          alt="fb_pageview"
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=3166545866699995&ev=PageView&noscript=1"
        />
      </noscript>
    </React.Fragment>
  )
}
