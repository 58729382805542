import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

import Button from 'components/Button'
import Callout from 'components/Callout'
import { Select } from 'components/Form/controls'
import { SEGMENTS } from 'constants/segmentation'
import Tooltip from 'components/Tooltip'
import { openChat } from 'utils/external'
import Box from 'components/Box'

export default function Segmentation({
  account,
  widget,
  onSave,
  mode,
  onChange,
  headingClassName,
}) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      segment: widget.segment,
    },
  })
  const hasSegmentsEnabled =
    account.data.plan.featuresEnabled.includes('segments')

  const formData = watch()
  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(formData.segment)
    }
  })

  return (
    <Box className={classNames(
      "mb-5",
      {
        "px-6 shadow-none": mode==='builder'
      }
    )}>
      <div
        className={classNames(
          'mb-5 text-sm font-semibold text-gray-800 uppercase',
          headingClassName
        )}
      >
        Segmentation
      </div>
        <form
          className="flex flex-col items-stretch flex-1 w-full"
          onSubmit={handleSubmit((data) => onSave(data))}
        >
      <Tooltip
        content={
          !hasSegmentsEnabled
            ? 'Segmentation is not enabled for your store.'
            : null
        }
        triggerClassName='w-full'
      >
          <Select
            options={SEGMENTS}
            name="segment"
            className={hasSegmentsEnabled ? '' : 'opacity-50'}
            ref={register()}
            disabled={!hasSegmentsEnabled}
            formError={errors.segment}
          />
      </Tooltip>
      {hasSegmentsEnabled ? (
        <div>
          <div className="flex items-center justify-end mt-2 text-sm text-gray-800">
            <span className="text-xs text-gray-600">
              Please select the segment of users you want to show this widget
              to. By default this widget will be visible to all the visitors.
            </span>
          </div>
          {mode !== 'builder' && (
            <div className="flex flex-row justify-end mt-4">
              <Button size="small">Save</Button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-end mt-2">
          <Callout type="info" noHoverEffect={true}>
            <span className="mr-2">
              Please contact us if you need support for segmentation
            </span>
            <Button
              color="primary"
              size="xSmall"
              onClick = {()=>{ openChat() }}
              >
              Contact Us
            </Button>
          </Callout>
        </div>
      )}
      </form>
    </Box>
  )
}
