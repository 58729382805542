import { useEffect } from 'react'
import { trackEvent } from 'utils/analytics'

export function useTrackView(payload) {
  useEffect(() => {
    if (payload && payload.category) {
      trackEvent(payload)
    }
  }, [])
}
