import React from 'react'
import classNames from 'classnames'
import { SectionHeading } from '../index'

export default function ChannelSelect({ campaign, onSelect, onBack }) {
  return (
    <React.Fragment>
      <SectionHeading>
        <span className="font-semibold">{campaign.label}</span>
        <span
          className="text-primary underline opacity-75 cursor-pointer hover:opacity-100"
          onClick={onBack}
        >
          Change
        </span>
      </SectionHeading>
      <SectionHeading>Select your email provider</SectionHeading>
      <div className="flex flex-row flex-wrap justify-center -mx-3">
        {campaign.allowedChannels.map((channel) => (
          <div
            className="flex flex-col items-stretch justify-center w-full px-4 mb-6 lg:w-1/3"
            key={channel.value}
          >
            <img
              src={channel.icon}
              alt={channel.label}
              onClick={() => onSelect(channel)}
              className={classNames(
                'border border-gray-200 hover:border-gray-400 rounded cursor-pointer h-32 px-3 object-contain'
              )}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}
