import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'
import { _find } from 'utils/lodash'

export default function ConditionSlider({
  triggers,
  onClose,
  onChooseCondition,
}) {
  const categories = getCategoriesFromAttributes(triggers)

  return (
    <div className="flex flex-col items-stretch flex-1 h-full">
      <header className="px-4 py-4 border-b border-gray-200 sm:px-6">
        <div className="flex items-start justify-between space-x-3">
          <h2 className="text-lg font-medium leading-7 text-gray-900">
            Choose Condition
          </h2>
          <div className="flex items-center h-7">
            <button
              aria-label="Close condition selector"
              className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
              onClick={onClose}
            >
              <Icons.Cross className="w-6 h-6 text-current" />
            </button>
          </div>
        </div>
      </header>
      <div className="flex flex-1 min-h-0">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col items-stretch flex-1 px-6 pt-1 mt-4 mb-8 space-y-6 overflow-y-auto">
            {categories.map(({ category, attributes }, index) => {
              return (
                <li
                  key={category}
                  className={classNames('flex flex-col items-stretch pb-4 ', {
                    'border-b border-gray-100': index < categories.length - 1,
                  })}
                >
                  <span className="mb-3 text-base font-medium text-gray-600 uppercase">
                    {category}
                  </span>
                  <ul className="flex flex-col items-stretch">
                    {attributes.map((attribute) => {
                      return (
                        <li
                          key={attribute.label}
                          className="flex flex-col items-stretch px-3 py-2 mb-2 rounded-sm hover:bg-gray-50 hover:cursor-pointer"
                          onClick={() => {
                            onChooseCondition({ attribute })
                          }}
                        >
                          <span className="mb-1 text-base font-semibold text-black">
                            {attribute.name}
                          </span>
                          {attribute.help && (
                            <span className="text-sm text-gray-400">
                              {attribute.help}
                            </span>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function getCategoriesFromAttributes(attributes) {
  return Object.keys(attributes).reduce((categories, key) => {
    const attributeObject = attributes[key]
    if (attributeObject.disabled) {
      return categories
    }
    let categoryObject
    if (_find(categories, (c) => c.category === attributeObject.object)) {
      return categories.map((c) => {
        return c.category === attributeObject.object
          ? {
              ...c,
              attributes: [...c.attributes, attributeObject],
            }
          : c
      })
    } else {
      categoryObject = {
        category: attributeObject.object,
        attributes: [attributeObject],
      }
      return [...categories, categoryObject]
    }
  }, [])
}
