import React from 'react'

import EditorForm from 'components/EditorForm'

export default function General({ value, handleUpdate, accountData }) {
  const {
    settings: { general: generalValues },
  } = value
  return (
    <EditorForm
      layout={LAYOUT}
      value={generalValues}
      handleUpdate={(values) => {
        const updateObj = {
          settings: {
            general: {
              ...generalValues,
              ...values,
            },
          },
        }
        handleUpdate(updateObj)
      }}
    />
  )
}

const LAYOUT = [
  {
    key: 'backgroundColor',
    label: 'Background',
    controlType: 'color',
  },
  {
    key: 'primaryColor',
    label: 'Primary Color',
    controlType: 'color',
  },
  {
    key: 'secondaryColor',
    label: 'Secondary Color',
    controlType: 'color',
    helpText: 'This color is used for displaying free shipping applied text',
  },
  {
    key: 'tertiaryColor',
    label: 'Tertiary Color',
    controlType: 'color',
    helpText: 'Use this to change incomplete threshold/shipping bar color',
  },
  {
    key: 'fontFamily',
    label: 'Font',
    controlType: 'input',
  },
]
