import React from 'react'
import classNames from 'classnames'

import Badge from 'components/Badge'
import Icons from 'components/Icons'
import Link from 'components/Link'
import { useOpenModal } from 'components/Modal'
import { openChat, openPartnerProgram, openRequestDemo } from 'utils/external'
import { _get } from 'utils/lodash'
import { isTemplateEnabled } from 'utils/features'
import { trackEvent } from 'utils/analytics'
import {
  emailCampaignsEnabled,
  isRkSupport,
  isShopifyPlus,
  isStaffPlan,
  visitorInsightsEnabled,
} from 'utils/account'
import { isEmbeddedApp } from 'utils/shopify'

import { ReactComponent as HomeIcon } from './icons/home.svg'
import { ReactComponent as ChatIcon } from './icons/translations.svg'
import { ReactComponent as RecommendationConfigurationIcon } from './icons/configuration.svg'
import { ReactComponent as AnalyticsIcon } from './icons/analytics.svg'
import { ReactComponent as AccountIcon } from './icons/account.svg'
import { ReactComponent as UpgradeIcon } from './icons/upgrade.svg'
import { ReactComponent as PartnerIcon } from './icons/partner.svg'
import { ReactComponent as IntegrationsIcon } from './icons/integrations.svg'
import { ReactComponent as EyeIcon } from './icons/eye.svg'
import { ReactComponent as PreviewIcon } from './icons/preview.svg'
import { ReactComponent as UserIcon } from './icons/user.svg'
import { ReactComponent as GlobeIcon } from './icons/globe.svg'
import styles from './styles.module.css'

const SECTIONS = [
  {
    label: '',
    links: [{ label: 'Dashboard', url: '/', icon: HomeIcon }],
  },
  {
    label: 'Personalize',
    links: [
      {
        label: 'A/B Experiences',
        url: '/experiences',
        icon: RecommendationConfigurationIcon,
      },
      {
        label: 'Recommendations',
        url: '/recommendationconfig',
        icon: Icons.AddProduct,
      },

      {
        label: 'Emails',
        url: '/campaigns',
        icon: Icons.Email,
      },
      {
        label: 'Analytics',
        url: '/analytics',
        icon: AnalyticsIcon,
      },
      {
        label: 'Attributed Orders',
        url: '/attribution',
        icon: Icons.Dollar,
      },
      {
        label: 'Visitors',
        url: '/visitors',
        icon: UserIcon,
        isNew: true,
        visible: (accountData) => visitorInsightsEnabled(accountData),
      },
      {
        label: 'Segments',
        url: '/segments',
        icon: Icons.Segments,
        isNew: true,
        visible: () => false,
      },
    ],
  },
  {
    label: 'AoV Boosters',
    isNew: true,
    links: [
      {
        label: 'Checkout Funnels',
        url: '/funnels',
        icon: Icons.Funnel,
      },
      {
        label: 'Bundles',
        url: '/bundles',
        icon: Icons.Bundle,
      },
      {
        label: 'Smart Popups',
        url: '/popups',
        icon: Icons.Popup,
        inBeta: true,
      },
      {
        label: 'Smart Mini Cart',
        url: '/minicart',
        icon: Icons.Cart,
        inBeta: true,
      },
    ],
  },
  {
    label: 'Customise Store',
    links: [
      {
        label: 'Translations',
        url: '/translations',
        icon: ChatIcon,
        isNew: true,
        visible: (accountData, storeData) => {
          return !isTemplateEnabled(accountData)
        },
      },
      {
        label: 'Templates',
        url: '/templates',
        icon: Icons.Templates,
      },
      { label: 'Advanced', url: '/advanced', icon: Icons.Code },
      {
        label: 'Admin',
        url: '/adminpanel',
        icon: Icons.KeyMajor,
        isNew: true,
        visible: (accountData) => {
          return accountData && isRkSupport(accountData)
        },
      },
    ],
  },
  {
    label: 'Account',
    links: [
      {
        label: 'Integrations',
        url: '/integrations',
        icon: IntegrationsIcon,
      },
      {
        label: 'Account',
        url: '/account',
        icon: AccountIcon,
      },

      {
        label: 'Pricing',
        url: '/plans',
        icon: UpgradeIcon,
      },
      {
        label: 'Settings',
        url: '/settings',
        icon: Icons.Settings,
        visible: (accountData) => isRkSupport(accountData),
      },
    ],
  },
  {
    label: 'Help',
    links: [
      {
        label: 'Shopify Admin',
        url: (accountData) => `https://${accountData.name}/admin/`,
        external: true,
        icon: GlobeIcon,
        newTab: true,
      },
      {
        label: 'Live Chat',
        onClick: openChat,
        icon: ChatIcon,
      },
      {
        label: 'Schedule a Demo',
        icon: EyeIcon,
        onClick: ({ accountData, openModal }) =>
          openModal(openRequestDemo({ accountData })),
      },
      {
        label: 'Guides',
        icon: PreviewIcon,
        url: 'https://glood-ai.notion.site/glood-ai/Glood-AI-Help-Center-fc7e38a64c4a499487cd80e75b59d91b',
        external: true,
        newTab: true,
      },
      {
        label: 'Partner Program',
        onClick: ({ accountData, openModal }) =>
          openModal(openPartnerProgram({ accountData })),
        icon: PartnerIcon,
        visible: (accountData, storeData) => {
          if (!accountData) {
            return false
          }

          const {
            shop: { planName },
          } = accountData
          return (
            ['affiliate', 'partner_test', 'plus_partner_sandbox'].indexOf(
              planName
            ) !== -1
          )
        },
      },
    ],
  },
]

export default function Sidebar({ account, store }) {
  const isEmbedded = React.useMemo(() => isEmbeddedApp(), [])
  const openModal = useOpenModal()
  return (
    <div className={classNames(styles.container, 'bg-secondary')}>
      <div className="flex flex-row items-center px-4 pt-2 font-bold leading-tight text-primary-600">
        <Icons.Glood className="w-20 mr-2 " />
        <span className="text-sm">Personalized Recommendations</span>
      </div>
      {isEmbedded && (
        <a
          className="block p-2 m-3 text-sm font-bold text-white rounded-sm bg-primary hover:bg-primary-700"
          href={window.self.location.href}
          target="_top"
        >
          Switch to Full Screen Mode
        </a>
      )}
      {SECTIONS.map((section, index) => {
        return (
          <React.Fragment key={index}>
            <div className="mt-6">
              {section.label && (
                <div className="flex flex-row items-center justify-between px-4 pb-3">
                  <span className="text-xs font-semibold text-gray-400 uppercase ">
                    {section.label}
                  </span>
                  {section.isNew && (
                    <Badge color="primary" className="ml-auto opacity-95">
                      <span className="uppercase">New</span>
                    </Badge>
                  )}
                  {section.inBeta && (
                    <Badge color="primary" className="ml-auto opacity-95">
                      <span className="uppercase">Beta</span>
                    </Badge>
                  )}
                  {section.comingSoon && (
                    <Badge color="primary" className="ml-auto opacity-95">
                      <span className="uppercase">Soon</span>
                    </Badge>
                  )}
                </div>
              )}
              {section.links
                .filter((link) => {
                  if (
                    (isStaffPlan(account.data) ||
                      isShopifyPlus(account.data)) &&
                    link.comingSoon
                  ) {
                    return false
                  }
                  return link
                })
                .map((link, index) => {
                  if (
                    typeof link.visible === 'function' &&
                    !link.visible(account.data, store.data)
                  ) {
                    return
                  }

                  const wrapperClasses = classNames(
                    styles.link,
                    'flex flex-row items-center px-4 py-2 text-gray-400 hover:text-white'
                  )
                  const content = (
                    <React.Fragment>
                      <link.icon
                        className={'w-5 mr-3 fill-current opacity-50'}
                      />
                      <span className="text-sm capitalize">{link.label}</span>
                      {link.isNew && (
                        <Badge color="primary" className="ml-auto opacity-95">
                          <span className="uppercase">New</span>
                        </Badge>
                      )}
                      {link.inBeta && (
                        <Badge color="primary" className="ml-auto opacity-95">
                          <span className="uppercase">Beta</span>
                        </Badge>
                      )}
                      {link.comingSoon && (
                        <Badge color="primary" className="ml-auto opacity-95">
                          <span className="uppercase">Soon</span>
                        </Badge>
                      )}
                    </React.Fragment>
                  )

                  if (typeof link.onClick === 'function') {
                    return (
                      <button
                        className={classNames(
                          wrapperClasses,
                          'outline-none focus:outline-none active:outline-none'
                        )}
                        onClick={() =>
                          link.onClick({ accountData: account.data, openModal })
                        }
                        key={index}
                      >
                        {content}
                      </button>
                    )
                  }

                  return link.external ? (
                    <a
                      href={`${
                        typeof link.url === 'function'
                          ? link.url(account.data)
                          : link.url
                      }${window.location.search}`}
                      className={wrapperClasses}
                      key={index}
                      target={link.newTab ? '_blank' : ''}
                    >
                      {content}
                    </a>
                  ) : (
                    <Link
                      to={link.url}
                      exact={link.url === '/' ? true : false}
                      useNav={true}
                      className={wrapperClasses}
                      activeStyle={{ color: '#fff' }}
                      key={index}
                    >
                      {content}
                    </Link>
                  )
                })}
            </div>
            {false && index === 2 && (
              <a
                className="flex flex-col p-2 mx-3 my-3 text-white rounded-sm cursor-pointer notranslate"
                style={{ backgroundColor: '#dd6b20' }}
                href="https://apps.shopify.com/otis-pwa-push-notifications?utm_source=personalized%20recommendations&utm_medium=dashboard&utm_campaign=sidebar"
                target="_blank"
                onClick={() =>
                  trackEvent({
                    category: 'Promotions',
                    action: 'Open PWA Mobile App & Notifications From Sidebar',
                  })
                }
              >
                <span className="mb-3 text-xs">
                  <strong>PWA Mobile App and Notifications</strong> under 2
                  minutes 🤩
                </span>
                <span className="mb-4 text-sm font-semibold">
                  Automated Personalized Notifications, Abandoned Cart Recovery
                  and more 🙌
                </span>
                <span className="p-1 text-sm font-semibold text-center text-gray-800 bg-white rounded-sm">
                  Engage with customers now
                </span>
              </a>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
