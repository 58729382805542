import React from 'react'

import EditorForm from 'components/EditorForm'

export default function GeneralSettings({ value, handleUpdate }) {
  return (
    <EditorForm layout={LAYOUT} value={value} handleUpdate={handleUpdate} />
  )
}

const LAYOUT = [
  [
    {
      key: 'layout',
      label: 'Layout',
      controlType: 'single_select',
      options: [
        { label: 'Full Width', value: 'full_width' },
        { label: 'Centered', value: 'center' },
      ],
    },
    {
      key: 'border',
      label: 'Border',
      controlType: 'single_select',
      options: [
        { label: 'Hide', value: 'none' },
        { label: 'Show', value: 'block' },
      ],
    },
  ],
  {
    key: [
      'sourceKey',
      'sourceFullKey',
      'sourcePrefix',
      'sourceFileName',
      'aspectRatio',
    ],
    label: 'Upload Image',
    controlType: 'image',
  },
  {
    key: 'altText',
    label: 'Image Alt Text',
    controlType: 'input',
    helpText: 'Alternate text related to image for screen readers',
  },
]
