import React from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import Box from 'components/Box'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { create as createRecommendationUploadApi } from 'api/recommendations_upload'
import * as Widgets from 'constants/widgets'

export default function RecommendationUploadModal({ close, onSave, shop }) {
  const [uploading, setUploading] = React.useState(false)
  const [selectedRecommendation, setSelectedRecommendation] =
    React.useState(null)
  const openToast = useToast()

  function handleClick(data) {
    setSelectedRecommendation(data)
  }

  async function handleUpload(file) {
    const formData = new FormData()
    formData.append('recommendation_type', selectedRecommendation)
    formData.append('file', file)
    setUploading(true)

    try {
      const { data } = await createRecommendationUploadApi(formData, { shop })
      onSave(data)
      close()
      openToast({
        type: 'success',
        text: 'Recommendations have been updated successfully.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error in upload. Ensure csv is valid & has less than 1000 recommendations.',
      })
    }

    setUploading(false)
  }

  return (
    <React.Fragment>
      <div className="flex flex-col items-stretch">
        <Box>
          <Box.Heading>
            Select the Recommendation type for which you want to upload
            recommendations.
          </Box.Heading>
          <Box.Section>
            <div>
              <div className="flex flex-col md:flex-row">
                <div className="w-full pr-8 md:w-2/3">
                  <RecommendationSelect
                    selectedRecommendation={selectedRecommendation}
                    onClick={handleClick}
                  />
                </div>
                <div className="flex flex-col items-stretch w-full md:w-1/3">
                  <UploadBox
                    selectedRecommendation={selectedRecommendation}
                    onUpload={handleUpload}
                    uploading={uploading}
                  />
                  <div className="mt-1 text-xs italic text-gray-600">
                    * Select the Widget Type / Recommendation Type for which you
                    want
                    <br />
                    to upload bulk recommendations.
                  </div>
                  <div className="mt-1 text-xs italic text-gray-600">
                    * Maximum recommendations of 1000 allowed per file. Incase
                    of more please split across multiple files or contact
                    support.
                  </div>
                </div>
              </div>
            </div>
          </Box.Section>
        </Box>
      </div>
    </React.Fragment>
  )
}

function UploadBox({ selectedRecommendation, onUpload, uploading }) {
  const disabled = !selectedRecommendation || uploading
  const [message, setMessage] = React.useState(null)
  const [file, setFile] = React.useState(null)

  const onDropAccepted = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    let isValid = false
    if (file && file.name) {
      const frags = file.name.split('.')
      isValid = frags[frags.length - 1] === 'csv'
    }
    if (isValid) {
      setFile(file)
      setMessage(
        <span className="text-primary">{`${file.name} is ready for upload now.`}</span>
      )
    } else {
      setFile(null)
      setMessage(
        <span className="text-red-700">
          An incorrect file was uploaded. Please upload a CSV file under 1MB.
        </span>
      )
    }
  }, [])

  const onDropRejected = React.useCallback((rejectedFiles, ...data) => {
    setMessage(
      <span className="text-red-700">
        An incorrect file was uploaded. Please upload a CSV file under 1MB.
      </span>
    )
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    disabled: disabled,
    maxSize: 1000000,
    multiple: false,
    onDropRejected,
    onDropAccepted,
  })

  return (
    <div className="flex flex-col items-stretch flex-grow h-full">
      <div className="flex items-stretch flex-grow w-full ">
        <div
          {...getRootProps({
            className:
              'border-dashed border-2 m-3 rounded flex justify-center items-center',
          })}
        >
          <input {...getInputProps()} />
          <span
            className={classNames('align-middle text-center py-8 px-4', {
              'text-gray-600': disabled,
              'text-gray-800': !disabled,
            })}
          >
            {message
              ? message
              : disabled
              ? 'Select the Recommendation Type and then select the file.'
              : 'Drag & drop recommendation files here, or click here to select file.'}
          </span>
        </div>
      </div>
      <div className="flex w-full">
        <div className="self-start w-full p-3 mb-6">
          <Button
            className="w-full"
            onClick={() => onUpload(file)}
            disabled={!selectedRecommendation || !file}
            loading={uploading}
          >
            Upload Recommendations
          </Button>
        </div>
      </div>
    </div>
  )
}

function RecommendationItem({
  selectedRecommendation,
  recommendation,
  onClick,
}) {
  return (
    <div
      onClick={(e) => onClick(recommendation.value, e.target.checked)}
      className={classNames(
        'flex items-start p-4 cursor-pointer rounded border lg:border-0 group hover:bg-gray-300 flex-row',
        { 'bg-gray-300': selectedRecommendation === recommendation.value }
      )}
    >
      <div className="p-2 mr-3 bg-gray-200 rounded-full">
        <recommendation.icon
          className="opacity-50 group-hover:opacity-100"
          height="40"
          width="40"
        />
      </div>
      <div className="flex flex-col items-stretch flex-1">
        <div className="mb-2 text-sm font-semibold uppercase">
          {recommendation.label}
        </div>
        <p className="text-xs leading-tight">{recommendation.description}</p>
      </div>
      <input
        id={recommendation.value}
        type="radio"
        className="mt-3"
        name="recommendation"
        checked={selectedRecommendation === recommendation.value}
        onChange={() => {}}
      />
    </div>
  )
}

function RecommendationSelect({ selectedRecommendation, onClick }) {
  const allowedRecommendations = [
    Widgets.BOUGHT_TOGETHER,
    Widgets.SIMILAR_PRODUCTS,
    Widgets.CROSS_SELL,
  ]
  return (
    <React.Fragment>
      {allowedRecommendations.map((recommendation, index) => (
        <RecommendationItem
          key={recommendation.value}
          selectedRecommendation={selectedRecommendation}
          recommendation={recommendation}
          onClick={onClick}
          index={index}
        />
      ))}
    </React.Fragment>
  )
}
