import React from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import StatCard from 'components/StatCard'
import PromotionCard from 'components/PromotionCard'
import useAppState from 'hooks/appState'
import { getStoreCurrency } from 'utils/account'

export default function AnalyticsCards({ analytics, account, store }) {
  const {
    data: { activePromotion },
  } = account

  const { state: appState } = useAppState()

  const isNativeCurrencyMode = true
  const storeCurrency = getStoreCurrency(account.data)
  const currencySymbol = getSymbolFromCurrency(
    isNativeCurrencyMode ? storeCurrency : 'USD'
  )

  return (
    <div className="flex flex-wrap -mx-3">
      {analytics.data.map(
        (
          {
            title,
            currency,
            total,
            value,
            description,
            type,
            nativeTotal,
            nativeValue,
          },
          index
        ) => {
          const numberFormat = currency ? '0a' : '0.0a'
          return (
            <React.Fragment key={`${title}${value}`}>
              <StatCard
                title={title}
                description={description}
                numberFormat={numberFormat}
                storeCurrency={storeCurrency}
                currencySymbol={currencySymbol}
                className="w-full px-3 mb-6"
                type={type}
                value={
                  type == 'currency' && isNativeCurrencyMode
                    ? nativeValue
                    : value
                }
                total={
                  isNativeCurrencyMode && type == 'currency'
                    ? nativeTotal
                    : total
                }
                key={title}
              />
              {index === 2 && activePromotion && activePromotion.title && (
                <PromotionCard
                  promotion={activePromotion}
                  className="w-full px-3 mb-6"
                />
              )}
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}
