import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import Text from 'components/Text'
import Button from 'components/Button'
import Form from 'components/Form'
import WaitForData from 'components/WaitForData'
import Pricing from 'components/Pricing'
import { useToast } from 'components/Toast'
import { useApi } from 'hooks/api'
import { useQueryParams } from 'hooks/router'
import { updateURLSearch } from 'utils/router'

export default function Plans({ account }) {
  const { name: shop } = account.data
  const history = useHistory()
  const location = useLocation()
  const openToast = useToast()
  const { plan, promo_code, all } = useQueryParams()
  const accountData = useApi('account/', { shop })
  const pricingData = useApi('account/plans/', {
    shop,
    ...(plan ? { plan } : {}),
    ...(promo_code ? { promo_code } : {}),
    ...(all ? { all } : {}),
  })
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      promoCode: null,
    },
  })
  var { on_annual: onAnnual } = useQueryParams()
  onAnnual = onAnnual == 'true' ? true : false

  useEffect(() => {
    pricingData.get({
      ...(all ? { all } : {}),
    })
  }, [location.search])

  async function handlePromoCodeUpdate(data) {
    try {
      await pricingData.get({ promo_code: data.promoCode })
      openToast({
        type: 'success',
        text: 'Plans have been updated!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Could Not apply the promo code.!',
      })
    }
  }

  return (
    <WaitForData
      objects={[accountData, pricingData]}
      loadingMessage="Fetching plan details"
      errorMessage="Trouble fetching plan details"
    >
      {() => {
        return (
          <div className="flex flex-col items-stretch">
            <div className="flex flex-row justify-between">
              <div>
                <Text.ScreenHeading marginBottom={0}>
                  Checkout Plans
                </Text.ScreenHeading>
                <Text.SubText marginBottom={5}>
                  <span
                    className="mr-1 underline cursor-pointer"
                    onClick={() => {
                      history.replace({
                        search: updateURLSearch({ all: true }),
                      })
                    }}
                  >
                    Select a Billing Plan
                  </span>
                  to continue showing Recommendations and loosing out on
                  revenue.
                </Text.SubText>
              </div>
              <form
                className="flex"
                onSubmit={handleSubmit(handlePromoCodeUpdate)}
              >
                <span className="flex">
                  <Form.Field
                    name="promoCode"
                    control="input"
                    placeholder="Promotion Code"
                    ref={register({
                      required: 'Promotion Code cannot be empty',
                    })}
                    formError={errors.promoCode}
                  />
                </span>
                <span className="flex mt-1 mb-3 ml-3">
                  <Button className="pl-8 pr-8" size="xSmall">
                    Apply
                  </Button>
                </span>
              </form>
            </div>
            <Pricing
              plans={
                [
                  account.data.plan.name === 'Free' ? account.data.plan : null,
                  ...pricingData.data,
                ]
                  .filter(Boolean)
                  .slice(0, 4) || []
              }
              shop={shop}
              billing={accountData.data}
              onAnnual={onAnnual}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}
