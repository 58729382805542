import * as React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
// import useKey from '~/hooks/useKey'

export default function ModalOverlay({ children, close }) {
  // useKey('esc', close)

  React.useEffect(() => {
    document.body.classList.add(styles.disableScroll)
    return () => document.body.classList.remove(styles.disableScroll)
  }, [])

  return (
    <div className={classNames('px-20 py-10', styles.overlay)} onClick={close}>
      {children}
    </div>
  )
}
