import React from 'react'

import FieldValue from 'components/ResourceList/FieldValue'

export default function DetailsCard({ visitor, schema }) {
  return (
    <div className="flex flex-col items-stretch p-0 bg-white border border-gray-300 rounded shadow">
      {schema.sections.map((section) => (
        <Section
          key={section.name}
          section={section}
          visitor={visitor}
          schema={schema.schema}
        />
      ))}
    </div>
  )
}

function Section({ section, visitor, schema }) {
  return (
    <React.Fragment>
      <span className="flex flex-row items-center justify-between px-4 py-2 leading-6 text-gray-900">
        <span className="font-medium leading-relaxed">{section.name}</span>
      </span>
      <div className="flex flex-col p-4 border-t border-b border-gray-200">
        {section.attributes.map((attribute) => (
          <div
            key={attribute}
            className="flex flex-row items-center justify-between"
          >
            <span className="w-2/5 text-sm font-medium leading-loose text-gray-600">
              {schema[attribute].title}
            </span>
            <span className="flex-1 ml-2 text-sm font-medium text-gray-900 break-all">
              <FieldValue
                value={visitor[attribute]}
                type={schema[attribute].type}
              />
            </span>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}
