import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Box from 'components/Box'
import Button from 'components/Button'
import { getCurrencyFormat, isRkSupport } from 'utils/account'
import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'
import ExcludedProducts from './ExcludedProducts'
import DisabledProducts from './DisabledProducts'
import WhitelistedProducts from './WhitelistedProducts'

export default function Settings({ widget, onSave, onDelete, account, store }) {
  const currencyFormat = getCurrencyFormat(account)
  const rkSupport = isRkSupport(account.data)
  return (
    <div className="flex flex-col">
      <WidgetActions
        widget={widget}
        onSave={(data) => onSave(data)}
        onDelete={() => onDelete(widget.id)}
        account={account}
        store={store}
      />
      <PlacementCode widget={widget} onSave={(data) => onSave(data)} />
      {[
        Widgets.PERSONALIZED.value,
        Widgets.SIMILAR_PRODUCTS.value,
        Widgets.BOUGHT_TOGETHER.value,
        Widgets.CROSS_SELL.value,
      ].includes(widget.type) && (
        <React.Fragment>
          <ExcludedProducts
            rkSupport={rkSupport}
            currencyFormat={currencyFormat}
            widget={widget}
            onSave={onSave}
          />
          {widget.pageType === Pages.PRODUCT.value && (
            <React.Fragment>
              <DisabledProducts
                rkSupport={rkSupport}
                currencyFormat={currencyFormat}
                widget={widget}
                onSave={onSave}
              />

              <WhitelistedProducts
                rkSupport={rkSupport}
                currencyFormat={currencyFormat}
                widget={widget}
                onSave={onSave}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

function WidgetActions({ widget, onSave, onDelete, account, store }) {
  const isEnabled = widget.enabled

  return (
    <Box className="flex flex-col items-stretch mb-6">
      <div className="flex flex-row items-center justify-between pb-2 mb-4 text-sm text-gray-800 border-b border-gray-400">
        <span>
          This widget is currently{' '}
          <strong>{isEnabled ? 'Enabled' : 'Disabled'}</strong>
        </span>
        <Button
          className="w-24"
          variant={isEnabled ? 'bordered' : 'solid'}
          size="xSmall"
          onClick={(event) => {
            onSave(
              {
                enabled: !widget.enabled,
              },
              event,
              widget.enabled
                ? 'Widget is now disabled on the store!'
                : 'Widget is now enabled on the store!'
            )
          }}
        >
          {isEnabled ? 'Disable' : 'Enable'}
        </Button>
      </div>
      <div className="flex flex-row items-center justify-between pb-2 mb-4 text-sm text-gray-800 border-b border-gray-400">
        Click delete to remove the widget permanently from the page.
        <Button size="xSmall" className="w-24" color="red" onClick={onDelete}>
          Delete
        </Button>
      </div>
      <div className="flex flex-row items-center justify-between pb-2 mb-4 text-sm text-gray-800 border-b border-gray-400">
        Clear cache for this widgets. Clearing cache may increase the widget
        load time temporarily.
        <Button
          size="xSmall"
          className="w-24"
          color="red"
          onClick={(event) => {
            onSave({}, event, 'Cache cleared for this widget.')
          }}
        >
          Clear Cache
        </Button>
      </div>
      <div className="flex flex-row items-center pb-2 mb-4 text-sm text-gray-800 border-b border-gray-400">
        Widget ID:{' '}
        <span className="ml-2 text-2xl font-semibold">{widget.id}</span>
      </div>
    </Box>
  )
}

export function PlacementCode({ widget }) {
  const [copied, setCopied] = React.useState(false)
  const code = `<div id='rk-widget-${widget.id}' class='rk-${widget.type}'></div>`

  return (
    <Box className="flex flex-col items-stretch mb-6">
      <span className="mb-1 text-sm font-semibold text-gray-800">
        *Comfortable with editing your Shopify theme code ? Paste the code below
        in your theme to place the widget at a specific location.
      </span>
      <span className="mb-3 text-sm font-semibold text-gray-800">
        You can also use this code with your existing Page Builder application.
      </span>
      <div className="flex flex-row items-center">
        <code className="px-6 py-3 mr-4 text-black bg-gray-300 rounded-sm select-all">
          {code}
        </code>
        <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
          <Button>{copied ? 'Copied' : 'Copy to Clipboard'}</Button>
        </CopyToClipboard>
      </div>
    </Box>
  )
}
