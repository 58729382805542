import React from 'react'
import Overlay from './Overlay'
import Frame from './Frame'

import { useHistory } from 'react-router-dom'

export const ModalContext = React.createContext({
  modals: [],
  open() {},
  close() {},
})

export function ModalProvider({ children }) {
  const [modals, setModals] = React.useState([])

  const contextValue = React.useMemo(
    () => ({
      modals,
      open(modal, options = { fullScreen: true }) {
        setModals([...modals, { modal, options }])
      },
      close(index) {
        setModals(removeAtIndex(modals, index))
      },
    }),
    [modals, setModals]
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

export function ModalContent() {
  const history = useHistory()
  const context = React.useContext(ModalContext)

  // NOTE(Dhruv): Close opened modals when route changes
  React.useEffect(() => {
    function cb() {
      if (context.modals.length === 0) {
        return
      }

      context.modals.forEach((modal, index) => context.close(index))
    }

    return history.listen(cb)
  })

  if (context.modals.length === 0) {
    return null
  }
  const lastIndex = context.modals.length - 1

  return (
    <React.Fragment>
      {React.Children.map(
        context.modals.map((m) => m.modal),
        (modal, i) => (
          <Overlay
            index={i}
            close={() => context.close(i)}
            modals={context.modals}
          >
            <Frame
              isVisible={lastIndex === i}
              close={() => context.close(i)}
              options={context.modals[i].options}
            >
              {React.cloneElement(modal, {
                close: () => context.close(i),
              })}
            </Frame>
          </Overlay>
        )
      )}
    </React.Fragment>
  )
}

export function useOpenModal() {
  return React.useContext(ModalContext).open
}

function removeAtIndex(array, index) {
  const newArray = [...array]
  newArray.splice(index, 1)
  return newArray
}
