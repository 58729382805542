import { _get } from 'utils/lodash'
import { CUSTOM_TEMPLATES } from 'constants/features'

import { isRkSupport } from './account'

export function isTemplateEnabled(account) {
  return account && account.templatesEnabled
}

export function canUpdateTemplate(account) {
  const featuresEnabled = _get(account, 'plan.featuresEnabled', [])
  return account
    ? isRkSupport(account) ||
        featuresEnabled.indexOf(CUSTOM_TEMPLATES.value) !== -1
    : false
}

export function isFeatureEnabled(account, feature) {
  const featuresEnabled = _get(account, 'plan.featuresEnabled', [])
  return account
    ? isRkSupport(account) || featuresEnabled.indexOf(feature.value) !== -1
    : false
}
