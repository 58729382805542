import React from 'react'

import Button from 'components/Button'
import Box from 'components/Box'
import Callout from 'components/Callout'
import WaitForData from 'components/WaitForData'
import { Select } from 'components/Form/controls'
import ResourcePicker from 'components/ResourcePicker'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import { useApi } from 'hooks/api'
import * as Tracker from 'utils/tracker'
import COUNTRIES from 'constants/countries.json'
import { geoConfigsSave as geoConfigsSaveApi } from 'api/geo_config'

const COUNTRY_OPTIONS = COUNTRIES.map((c) => ({
  label: c.name,
  value: c['alpha-2'],
}))

export default function GeoTagging({ account, store }) {
  const {
    name: shop,
    plan: { featuresEnabled },
    preferredPlan,
  } = account.data
  const [savingGeoConfigs, setSavingGeoConfigs] = React.useState(false)
  const [selectedCountry, setSelectedCountry] = React.useState(null)
  const openModal = useOpenModal()
  const openToast = useToast()
  const geoConfigs = useApi('geoconfig/', { shop })

  async function updateGeoConfigs(payload) {
    setSavingGeoConfigs(true)
    try {
      const { data: newGeoConfigs } = await geoConfigsSaveApi(payload, shop)
      geoConfigs.update(newGeoConfigs, { local: true })
      openToast({
        type: 'success',
        text: 'Geo config has been successfully updated for your store',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text:
          'There was an error in saving the geo tagging configuration. Please reload the application',
      })
    }
    setSavingGeoConfigs(false)
  }

  function handleAddNewRule() {
    openModal(
      <ResourcePicker
        heading="Choose products"
        ignoreProductIds={[]}
        initialSelectedProductIds={[]}
        showTwoWaySyncOption={false}
        onSelect={(selectedProductIds) =>
          updateGeoConfigs([
            {
              countryCode: selectedCountry.value,
              country: selectedCountry.label,
              productIds: selectedProductIds,
            },
          ])
        }
      />
    )
  }

  function setCountry(e) {
    setSelectedCountry(
      COUNTRY_OPTIONS.filter((c) => c.value === e.target.value)[0]
    )
  }

  return featuresEnabled.includes('geo_targeting') ? (
    <WaitForData
      objects={[geoConfigs]}
      loadingMessage={'Loading Geo Tagging Configuration'}
    >
      {() => {
        const addedCountryCodes = geoConfigs.data.geoConfigs.map(
          (c) => c.countryCode
        )
        const newCountryOptions = COUNTRY_OPTIONS.filter(
          (c) => addedCountryCodes.indexOf(c.value) === -1
        )

        return (
          <div className="flex flex-col items-stretch">
            <span className="mb-3 text-sm text-gray-800">
              Select a country and then choose products to be recommended only
              to visitors from that country.
            </span>
            <Box className="flex flex-row items-center mb-3">
              <div className="mr-3">
                <Select
                  value={selectedCountry ? selectedCountry.value : ''}
                  options={newCountryOptions}
                  onChange={setCountry}
                  placeholder="Choose country"
                />
              </div>
              <Button onClick={handleAddNewRule} disabled={!selectedCountry}>
                Choose products to recommend in{' '}
                {selectedCountry ? `"${selectedCountry.label}"` : ''}
              </Button>
            </Box>
            {geoConfigs.data.geoConfigs.map((rule) => {
              return (
                <Box
                  className="flex flex-col items-stretch flex-1 mb-2"
                  key={rule.id}
                >
                  <RuleItem
                    rule={rule}
                    isSaving={savingGeoConfigs}
                    onUpdateProducts={(productIds) => {
                      updateGeoConfigs([
                        {
                          country:
                            rule.country ||
                            countryNameFromCode(rule.countryCode),
                          countryCode: rule.countryCode,
                          productIds,
                        },
                      ])
                    }}
                    onRemoveProducts={(productIds) => {
                      updateGeoConfigs([
                        {
                          country:
                            rule.country ||
                            countryNameFromCode(rule.countryCode),
                          countryCode: rule.countryCode,
                          productIds: rule.productIds.filter(
                            (id) => productIds.indexOf(id) === -1
                          ),
                        },
                      ])
                    }}
                  />
                </Box>
              )
            })}
          </div>
        )
      }}
    </WaitForData>
  ) : (
    <div className="flex flex-row">
      <div className="p-3 bg-white rounded">
        <Callout noHoverEffect={true}>
          <span className="mr-2">
            Geo targeting allows you to recommend products based on your users
            geographic location. This feature is available only on our paid
            plans
          </span>
          <Button
            size="xSmall"
            onClick={async () => {
              Tracker.record('geo_targeting:start_trial:click')
              await Tracker.flush()

              window.top.location.href = `${preferredPlan.chargeUrl}&shop=${shop}`
            }}
          >
            Start Free Trial
          </Button>
        </Callout>
      </div>
    </div>
  )
}

function RuleItem({ rule, isSaving, onRemoveProducts, onUpdateProducts }) {
  const openModal = useOpenModal()
  const countryName = countryNameFromCode(rule.countryCode)
  function handleAddProducts() {
    openModal(
      <ResourcePicker
        heading="Choose products"
        ignoreProductIds={[]}
        initialSelectedProductIds={rule.productIds}
        showTwoWaySyncOption={false}
        onSelect={(selectedProductIds) => onUpdateProducts(selectedProductIds)}
      />
    )
  }

  return rule.products.length > 0 ? (
    <div className="flex flex-col items-start mb-3">
      <div className="flex flex-row items-center self-stretch flex-1">
        <span className="mb-3 text-xl font-semibold">
          {countryName}
          <span className="text-xs font-normal text-gray-600">
            {' '}
            (Below products will only be visible to visitors from {countryName})
          </span>
        </span>
        <Button
          variant="solid"
          size="xSmall"
          className="ml-auto mr-3"
          onClick={handleAddProducts}
          disabled={isSaving}
        >
          + Add Products
        </Button>
        <Button
          variant="solid"
          color="red"
          size="xSmall"
          onClick={() => {
            if (
              window.confirm(
                'Are you sure you want to remove the following geo target rule?'
              )
            ) {
              onUpdateProducts([])
            }
          }}
          disabled={isSaving}
        >
          Remove Rule
        </Button>
      </div>
      <div className="flex flex-row flex-wrap flex-1">
        {rule.products.map((product) => {
          return (
            <div
              key={product.id}
              className="flex flex-col items-center p-2 mt-2 mr-2 bg-gray-100 rounded"
            >
              <span className="mb-1 text-xs">{product.title}</span>
              <img
                className="object-contain w-24 h-24 mb-1"
                src={product.image}
                alt={product.title}
              />
              <Button
                size="xSmall"
                variant="bordered"
                onClick={() => onRemoveProducts([product.id])}
              >
                Remove
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  ) : null
}

function countryNameFromCode(code) {
  return COUNTRY_OPTIONS.filter((c) => c.value === code)[0].label
}
