import React from 'react'
import { useMutation } from 'react-query'
import { useToast } from 'components/Toast'

import {
  updateFunnel as updateFunnelApi,
  createFunnel as createFunnelApi,
  updateWorkflowNode as updateWorkflowNodeApi,
  createWorkflowNode as createWorkflowNodeApi,
} from 'api/funnel'

import { invalidate, refetch } from 'utils/query'

export default function useFunnelMutations({ shop }) {
  const openToast = useToast()
  const updateFunnel = useMutation(
    async (data) => {
      return await updateFunnelApi(shop, data)
    },
    {
      onSuccess: () => {
        invalidate('funnels')
        refetch('funnels')
        invalidate('funnel')
        openToast({
          type: 'success',
          text: 'Funnel updated successfully!',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error updating the funnel',
        })
      },
    }
  )

  const createFunnel = useMutation(
    async (data) => {
      return await createFunnelApi(shop, data)
    },
    {
      onSuccess: () => {
        refetch('funnels')
        openToast({
          type: 'success',
          text: 'Funnel created successfully',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error creating the funnel',
        })
      },
    }
  )

  return {
    createFunnel,
    updateFunnel,
  }
}

export function useWorkflowNodeMutations({ shop, funnel }) {
  const openToast = useToast()

  const createNode = useMutation(
    async (data) => {
      return await createWorkflowNodeApi(shop, funnel, data)
    },
    {
      onSuccess: () => {
        refetch('nodes')
        openToast({
          type: 'success',
          text: 'Task created successfully',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error creating the task',
        })
      },
    }
  )

  const updateNode = useMutation(
    async (data) => {
      return await updateWorkflowNodeApi(shop, funnel, data)
    },
    {
      onSuccess: () => {
        refetch('nodes')
        openToast({
          type: 'success',
          text: 'Task updated successfully',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error updating the task',
        })
      },
    }
  )

  return {
    createNode,
    updateNode,
  }
}
