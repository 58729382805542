import React, { useEffect, useState } from 'react'
import api from 'api'

import Button from 'components/Button'
import Form from 'components/Form'
import Icons from 'components/Icons'
import * as PAGES_OBJECT from 'constants/pages'
import * as WIDGETS_OBJECT from 'constants/widgets'
import { LOOM_TUTORIAL_VIDEO_URL } from 'constants/support'
import { invalidate } from 'utils/query'

import { LAYOUTS } from '../Layout'
import { SEGMENTS } from 'constants/segmentation'
import { _find } from 'utils/lodash'
import { RECOMMENDATION_RULES } from 'constants/features'

const PAGES = Object.values(PAGES_OBJECT)
const WIDGETS = Object.values(WIDGETS_OBJECT)

export default function Review({ builder, rb }) {
  const {
    widget,
    setActiveScreen,
    account: {
      data: { name, plan },
    },
    openModal,
    openToast,
    shop,
    setPublishHandler,
    setWidget,
    account,
  } = builder

  const supportedRecommendations = SECTIONS.map((section) => {
    const featureEnabled = section.isFeatureEnabled(account.data)
    const supported = _find(section.supportedWidgets, { value: widget.type })
    return (
      featureEnabled &&
      supported && (
        <li className="list-disc text-primary-600">{section.label}</li>
      )
    )
  }).filter((widget) => Boolean(widget))

  const { supportedSettings } = _find(WIDGETS, {
    value: widget.type,
  })

  useEffect(() => {
    async function getConfirmation(){
      return widget.enabled ||
        window.confirm(
          'The widget is not yet enabled. You can see this widget on the dashboard and enable it. Would you still like to exit the create flow?'
        )
    }
    async function enableWidget() {
      try {
        const { id } = widget
        const { data } = await api.put(
          `widgets/${id}/`,
          { id, enabled: true },
          { params: { shop } }
        )
        setWidget(data)
        rb.setCloseHandler(()=>async()=>{
          return true
        })
        openToast({
          type: 'success',
          text: 'Widget Published successfully!',
        })
        invalidate(['pages', shop])
      } catch (e) {
        openToast({
          type: 'error',
          text: 'There was an error in Publishing the widget',
        })
      }
    }

    setPublishHandler(() => async () => {
      await enableWidget()
    })
    rb.setCloseHandler(()=>getConfirmation)
  }, [widget,setWidget])

  return (
    <div className="">
      {/* Placement Section */}
      <div className={'px-16 py-10 flex items-center justify-between'}>
        <div>
          <h1 className="text-lg font-semibold text-gray-800">Placement</h1>
          <p className="mt-2 text-gray-600">
            You can update the placement of the widget using Shopify Theme
            Editor
          </p>
        </div>
        <div className="flex">
          <Button
            className={'mr-4 items-center justify-center flex'}
            variant="text"
            onClick={() => {
              openModal(<TutorialVideoModal />)
            }}
          >
            <div className={'flex items-center '}>
              <Icons.Youtube className="mr-2 w-6 text-primary-600" />
              <p className="text-primary-600 text-sm">Watch Tutorial</p>
            </div>
          </Button>
          <Button
            size="small"
            className={'flex justify-center items-center'}
            href={`https://${name}/admin/`}
            target="_blank"
          >
            <div className={'flex items-center'}>
              <p>Visit Shopify Editor </p>
              <Icons.External color="white" className="ml-1 w-6" />
            </div>
          </Button>
        </div>
      </div>
      {/* Recommendation Section */}
      <div className="w-full h-0.5 bg-gray-200"></div>
      {supportedRecommendations.length !== 0 && (
        <div className={'px-16 py-10'}>
          <div>
            <h1 className=" text-lg font-semibold text-gray-800">
              Recommendation
            </h1>
            <p className="mt-2 text-gray-600 mb-4">
              This Widget Support following types of recommendation
              configurations
            </p>
          </div>
          <ul className="flex flex-row gap-10 translate-x-4">
            {supportedRecommendations}
          </ul>
        </div>
      )}
      <div className="w-full h-0.5 bg-gray-200"></div>
      {/* Details */}
      <div className={'px-16 py-10'}>
        <div>
          <div className=" -translate-x-8 flex justify-between">
            <h1 className=" text-lg font-semibold text-gray-800 flex mb-2">
              <Icons.CircleTick className="w-6 mr-2 text-primary-600" />
              Details
            </h1>
            <Button
              size="small"
              variant="light"
              onClick={() => {
                setActiveScreen('detail')
              }}
              className="translate-x-8"
            >
              Edit
            </Button>
          </div>
          <div className="grid grid-cols-3 xl:grid-cols-4 gap-4 grid-rows-1">
            <Form.Field
              label="Title"
              name="title"
              control="input"
              disabled
              value={widget?.title}
            />
            <Form.Field
              label="A/B Experience"
              name="experience"
              control="input"
              disabled
              value={widget?.experience?.name}
            />
            <Form.Field
              label="Page Type"
              name="page_type"
              control="input"
              disabled
              value={getPageType(widget)}
            />
            <Form.Field
              label="Widget Type"
              name="widget_type"
              control="input"
              disabled
              value={getWidgetType(widget)}
            />
          </div>
        </div>
      </div>
      {/* Layout and Editor */}
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className={'px-16 py-10'}>
        <div>
          <div className=" -translate-x-8 flex justify-between">
            <h1 className="text-lg font-semibold text-gray-800 flex mb-2">
              <Icons.CircleTick className="w-6 mr-2 text-primary-600" />
              Layout and Editor
            </h1>
            <Button
              size="small"
              className="translate-x-8"
              variant="light"
              onClick={() => {
                setActiveScreen('layout')
              }}
            >
              Edit
            </Button>
          </div>
          <div>
            <LayoutImage widget={widget} />
          </div>
        </div>
      </div>
      {/* Configurations */}
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className={'px-16 py-10'}>
        <div>
          <div className=" -translate-x-8 flex justify-between">
            <h1 className=" text-lg font-semibold text-gray-800 flex mb-2">
              <Icons.CircleTick className="w-6 mr-2 text-primary-600" />
              Configurations
            </h1>
            <Button
              size="small"
              className="translate-x-8"
              variant="light"
              onClick={() => {
                setActiveScreen('configure')
              }}
            >
              Edit
            </Button>
          </div>
          <div className="grid grid-cols-3 xl:grid-cols-4 gap-4">
            {CONFIGURATIONS.map(({ label, value, key }, index) => {
              if (key === 'segment' || key === 'discount') {
                const featureEnabled =
                  plan.featuresEnabled.includes('segments') ||
                  plan.featuresEnabled.includes('discounts')
                return (
                  <>
                    {featureEnabled && (
                      <Form.Field
                        control="input"
                        disabled
                        value={value(widget)}
                        label={label}
                      />
                    )}
                  </>
                )
              }
              return (
                <>
                  {supportedSettings.includes(key) && (
                    <Form.Field
                      control="input"
                      disabled
                      value={value(widget)}
                      label={label}
                    />
                  )}
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function LayoutImage({ widget }) {
  const imgSrc = LAYOUTS.find(
    (layout) => layout.value === widget?.layout
  )?.image
  return (
    <div className="w-full h-max flex items-center justify-center">
      <img src={imgSrc} alt={widget?.layout} className="w-3/4" />
    </div>
  )
}

function getPageType(widget) {
  const pages = new Array(...PAGES)
  return pages.find((page) => page.value === widget?.pageType)?.label
}

function getWidgetType(widget) {
  const widgets = new Array(...WIDGETS)
  return widgets.find((wid) => wid.value === widget?.type)?.label
}

const CONFIGURATIONS = [
  {
    label: 'Automatic Recommendations',
    value: (widget) => (widget?.automaticEnabled ? 'Enabled' : 'Disabled'),
    key: 'automaticEnabled',
  },
  {
    label: 'Random Recommendations',
    value: (widget) => (widget?.enableRandom ? 'Enabled' : 'Disabled'),
    key: 'enableRandom',
  },
  {
    label: 'Show Soldout items as Recommendations',
    value: (widget) => (widget?.allowIfUnavailable ? 'Enabled' : 'Disabled'),
    key: 'allowIfUnavailable',
  },
  {
    label: 'Segment',
    value: (widget) =>
      SEGMENTS.find((segment) => segment.value === widget?.segment)?.label,
    key: 'segment',
  },
  {
    label: 'Enable Discounts',
    value: (widget) =>
      widget?.discountConfig?.enabled ? 'Enabled' : 'Disabled',
    key: 'discount',
  },
]

function TutorialVideoModal() {
  return (
    <div className="">
      <iframe
        width="100%"
        src={LOOM_TUTORIAL_VIDEO_URL}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope"
        allowFullScreen
        style={{ height: '80vh' }}
        title="tutorial video"
      ></iframe>
    </div>
  )
}

const SECTIONS = [
  {
    type: 'manual-recommendations',
    label: '1-1 Manual Recommendations',
    isFeatureEnabled: (accountData) => true,
    supportedWidgets: [
      WIDGETS_OBJECT.BOUGHT_TOGETHER,
      WIDGETS_OBJECT.CROSS_SELL,
      WIDGETS_OBJECT.SIMILAR_PRODUCTS,
    ],
  },
  {
    type: 'recommendation-rules',
    label: 'Rule Based Recommendations',
    isFeatureEnabled: (accountData) => {
      accountData &&
        accountData.plan.featuresEnabled.includes(RECOMMENDATION_RULES.value)
    },
    supportedWidgets: [
      WIDGETS_OBJECT.BOUGHT_TOGETHER,
      WIDGETS_OBJECT.CROSS_SELL,
    ],
  },
  {
    type: 'global-recommendations',
    label: 'Global Recommendations',
    isFeatureEnabled: (accountData) => true,
    supportedWidgets: [
      WIDGETS_OBJECT.TRENDING,
      WIDGETS_OBJECT.BOUGHT_TOGETHER,
      WIDGETS_OBJECT.SIMILAR_PRODUCTS,
      WIDGETS_OBJECT.PURCHASES,
      WIDGETS_OBJECT.BESTSELLERS,
      WIDGETS_OBJECT.RECENTLY_LAUNCHED,
    ],
  },
]
