import React, { useState } from 'react'

import Form from 'components/Form'
import { Select } from 'components/Form/controls'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import * as Pages from 'constants/pages'
import classNames from 'classnames'

export default function Exclusions({ exclusions, setExclusions }) {
  const openToast = useToast()

  function updateExclusion(index, updatedExclusion) {
    const updatedExclusions = [...exclusions]
    updatedExclusions[parseInt(index)] = updatedExclusion
    setExclusions(updatedExclusions)
  }

  return (
    <div className="flex flex-col w-full py-10 pl-16 pr-12">
      <div className="flex flex-col items-start mb-4">
        <span className="text-lg font-semibold">Exclusions</span>
        <span className="mt-2 text-base text-gray-500">
          Complete the following steps to get started
        </span>
      </div>
      <div className="flex flex-col w-full pr-8 overflow-y-auto max-h-96">
        {exclusions.map((exclusion, index) => (
          <ExclusionCard
            key={`${exclusion.pageType}_${index}`}
            exclusion={exclusion}
            updateExclusion={(updatedExclusion) => {
              updateExclusion(index, { ...exclusion, ...updatedExclusion })
            }}
          />
        ))}
      </div>
    </div>
  )
}

function ExclusionCard({ exclusion, updateExclusion }) {
  const { pageLinks } = exclusion
  const openToast = useToast()
  function addNewPageLink() {
    updateExclusion({ pageLinks: exclusion.pageLinks.concat('') })
  }
  function removePageLink(index) {
    const updatedPageLinks = [...pageLinks]
    updatedPageLinks.splice(index, 1)
    updateExclusion({ pageLinks: updatedPageLinks })
  }
  function updatePageLink(index, url) {
    const updatedPageLinks = [...pageLinks]
    updatedPageLinks[index] = url
    const pageLinkExists =
      pageLinks.some((pageLink) => url && pageLink === url).length > 1
    if (pageLinkExists) {
      openToast({
        type: 'error',
        text: 'URL already exists',
      })
    } else {
      updateExclusion({ pageLinks: updatedPageLinks })
    }
  }
  return (
    <div className="flex flex-col items-start mb-6 text-sm">
      {pageLinks.map((link, index) => {
        const canBeDeleted = pageLinks.length > 1
        return (
          <div className="flex flex-col w-full mb-4" key={`${link}_${index}`}>
            <span className="mb-1">Page Link</span>
            <div className="flex items-center ">
              <Form.Field
                control="input"
                className="w-full mb-0 mr-3"
                defaultValue={link}
                onChange={(event) => {
                  const value = event.target.value
                  updatePageLink(index, value)
                }}
              />
              <Icons.Delete
                className={classNames('w-5 text-gray-500', {
                  'opacity-80 cursor-not-allowed': !canBeDeleted,
                  'cursor-pointer': canBeDeleted,
                })}
                onClick={() => {
                  if (canBeDeleted) {
                    removePageLink(index)
                  }
                }}
              />
            </div>
          </div>
        )
      })}
      <div className="flex items-center justify-between w-full">
        <span
          className="text-xs font-semibold cursor-pointer text-primary hover:text-primary-500"
          onClick={addNewPageLink}
        >
          + Add Page Link
        </span>
      </div>
    </div>
  )
}
