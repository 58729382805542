import React, { useState } from 'react'

import Form from 'components/Form'
import { Select } from 'components/Form/controls'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import * as Pages from 'constants/pages'
import classNames from 'classnames'

export default function Inclusions({ inclusions, setInclusions }) {
  const openToast = useToast()
  function addNewInclusion() {
    setInclusions([
      ...inclusions,
      { pageType: Pages.HOME.value, pageLinks: [''] },
    ])
  }
  function removeInclusion(index) {
    const updatedInclusions = [...inclusions]
    updatedInclusions.splice(index, 1)
    setInclusions(updatedInclusions)
  }
  function updateInclusion(index, updatedInclusion) {
    const updatedInclusions = [...inclusions]
    updatedInclusions[parseInt(index)] = updatedInclusion
    const pageTypeInclusionExists =
      updatedInclusions.filter(
        (inclusion) =>
          updatedInclusion.pageType &&
          inclusion.pageType === updatedInclusion.pageType
      ).length > 1
    if (pageTypeInclusionExists) {
      openToast({
        type: 'error',
        text: 'Inclusion with this page type already exists. Please add required links in that section',
      })
    } else {
      setInclusions(updatedInclusions)
    }
  }

  return (
    <div className="flex flex-col w-full py-10 pl-16 pr-12">
      <div className="flex flex-col items-start mb-4">
        <span className="text-lg font-semibold">Inclusions</span>
        <span className="mt-2 text-base text-gray-500">
          Complete the following steps to get started
        </span>
      </div>
      <div className="flex flex-col w-full pr-8 overflow-y-auto max-h-96">
        {inclusions.map((inclusion, index) => (
          <InclusionCard
            key={`${inclusion.pageType}_${index}`}
            inclusion={inclusion}
            updateInclusion={(updatedInclusion) => {
              updateInclusion(index, { ...inclusion, ...updatedInclusion })
            }}
            removeInclusion={() => {
              removeInclusion(index)
            }}
          />
        ))}
        <div className="flex items-center justify-between w-full">
          <span
            className="text-base font-semibold cursor-pointer text-primary hover:text-primary-500"
            onClick={addNewInclusion}
          >
            + Add Inclusion
          </span>
        </div>
      </div>
    </div>
  )
}

function InclusionCard({ inclusion, updateInclusion, removeInclusion }) {
  const { pageLinks, pageType } = inclusion
  const pageLinksDisabled = PAGE_LINKS_DISABLED_PAGE_TYPES.includes(pageType)
  const openToast = useToast()
  function addNewPageLink() {
    updateInclusion({ pageLinks: inclusion.pageLinks.concat('') })
  }
  function removePageLink(index) {
    const updatedPageLinks = [...pageLinks]
    updatedPageLinks.splice(index, 1)
    updateInclusion({ pageLinks: updatedPageLinks })
  }
  function updatePageLink(index, url) {
    const updatedPageLinks = [...pageLinks]
    updatedPageLinks[index] = url
    const pageLinkExists =
      pageLinks.some((pageLink) => url && pageLink === url).length > 1
    if (pageLinkExists) {
      openToast({
        type: 'error',
        text: 'URL already exists',
      })
    } else {
      updateInclusion({ pageLinks: updatedPageLinks })
    }
  }

  return (
    <div className="flex flex-col items-start px-6 py-5 mb-6 text-sm border border-gray-300 rounded-lg">
      <div className="flex flex-col w-full mb-3">
        <span className="mb-1">Page Type</span>
        <Select
          className="w-full"
          options={Object.keys(Pages)
            .filter((k) => Pages[k].value !== Pages.AJAX_CART.value)
            .map((k) => {
              const page = Pages[k]
              return { label: page.label, value: page.value }
            })}
          value={pageType}
          onChange={(event) => {
            const value = event.target.value
            updateInclusion({
              pageType: value,
            })
          }}
        ></Select>
      </div>
      {!pageLinksDisabled &&
        pageLinks.map((link, index) => {
          const canBeDeleted = pageLinks.length > 1
          return (
            <div className="flex flex-col w-full mb-4" key={`${link}_${index}`}>
              <span className="mb-1">Page Link</span>
              <div className="flex items-center ">
                <Form.Field
                  control="input"
                  className="w-full mb-0 mr-3"
                  defaultValue={link}
                  onChange={(event) => {
                    const value = event.target.value
                    updatePageLink(index, value)
                  }}
                />
                <Icons.Delete
                  className={classNames('w-5 text-gray-500', {
                    'opacity-80 cursor-not-allowed': !canBeDeleted,
                    'cursor-pointer': canBeDeleted,
                  })}
                  onClick={() => {
                    if (canBeDeleted) {
                      removePageLink(index)
                    }
                  }}
                />
              </div>
            </div>
          )
        })}
      <div
        className={classNames('flex items-center w-full', {
          'justify-end': pageLinksDisabled,
          'justify-between': !pageLinksDisabled,
        })}
      >
        {!pageLinksDisabled && (
          <span
            className="text-xs font-semibold cursor-pointer text-primary hover:text-primary-500"
            onClick={addNewPageLink}
          >
            + Add Page Link
          </span>
        )}
        <span
          className="text-sm font-semibold text-red-500 cursor-pointer hover:text-red-400"
          onClick={removeInclusion}
        >
          Remove
        </span>
      </div>
    </div>
  )
}

const PAGE_LINKS_DISABLED_PAGE_TYPES = [
  Pages.HOME.value,
  Pages.CHECKOUT.value,
  Pages.CART.value,
  Pages.ORDER_CONFIRM.value,
  Pages.NOT_FOUND.value,
]
