import api from './index'

export function fetchAccountConfig(shop) {
  return api.get('/account/config/', { params: { shop } })
}

export function fetchBilling({ queryKey }) {
  const [_key, shop] = queryKey
  return api.get('account/', {
    params: {
      shop,
    },
  })
}
