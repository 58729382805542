import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'

import { CURRENCY } from 'constants/fieldTypes'
import * as FILTER_OPERATORS from 'constants/filterOperators'

export default function CurrencyFilterInput({ filters, field }) {
  const fieldState = filters.state[field.key]
  const primaryInputRef = useRef()
  const secondaryInputVisible = [
    FILTER_OPERATORS.WITHIN_RANGE.value,
    FILTER_OPERATORS.OUTSIDE_RANGE.value,
  ].includes(fieldState.operator)

  useEffect(() => {
    if (!fieldState.operator) {
      filters.setFieldOperator({
        key: field.key,
        value: CURRENCY.filterOperatorOptions[0].value,
      })
    }
  }, [])

  return (
    <div className="flex flex-col items-stretch w-full mt-2 space-y-1 pl-7">
      <select
        onChange={(e) => {
          filters.setFieldOperator({
            key: field.key,
            value: e.target.value,
          })
          primaryInputRef.current.focus()
        }}
        value={fieldState.operator}
        className="block w-full px-1 py-1 mb-1 bg-gray-200 border border-gray-400 rounded sm:text-sm focus:border-indigo-500"
      >
        {CURRENCY.filterOperatorOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <div className="flex flex-row items-center justify-between">
        <CurrencyInput
          ref={primaryInputRef}
          value={fieldState.values[0]}
          onChange={(e) => {
            filters.setFieldValues({
              key: field.key,
              values: [e.target.value, fieldState.values[1]],
            })
          }}
        />
        {secondaryInputVisible && (
          <React.Fragment>
            <span className="mx-3 font-bold">-</span>
            <CurrencyInput
              value={fieldState.values[1]}
              onChange={(e) => {
                filters.setFieldValues({
                  key: field.key,
                  values: [fieldState.values[0], e.target.value],
                })
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const CurrencyInput = React.forwardRef(
  ({ value, onChange, className }, ref) => {
    return (
      <div className={classNames('flex flex-1 rounded', className)}>
        <span className="inline-flex items-center px-2 text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">
          $
        </span>
        <input
          ref={ref}
          className={classNames(
            'appearance-none block w-full px-2 py-1 border focus:outline-none ease-in-out border-l-0 sm:text-sm rounded border-gray-400 placeholder-gray-400 focus:border-primary-500'
          )}
          value={value}
          onChange={onChange}
          type="number"
          step="0.01"
          min="0"
        />
      </div>
    )
  }
)
