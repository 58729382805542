import React from 'react'
import Button from 'components/Button'
import Onboarding from 'components/Onboarding'
import * as Tracker from 'utils/tracker'

export default function ({ onboardingState }) {
  const {
    stages: { next: nextStage },
    updateOnboarding,
  } = onboardingState

  React.useEffect(() => {
    Tracker.record('view:onboarding:welcome')
  }, [])

  return (
    <React.Fragment>
      <Onboarding.Box>
        <Onboarding.BoxHeading>
          Welcome to Personalized Recommendations by Glood.AI
        </Onboarding.BoxHeading>
        <p className="mb-6 text-lg">
          Use the power of AI/ML to increase conversions and Average Order
          Value(AoV) for your store. We use realtime events to ensure that each
          visitor see the right products for them.
        </p>
        <div className="flex items-center justify-center mb-6">
          <Button
            className="self-center"
            onClick={() => {
              Tracker.record('click:onboarding:begin_setup')
              updateOnboarding({ stage: nextStage })
            }}
          >
            Begin Setup
          </Button>
        </div>
        <h4 className="mb-2 font-bold leading-snug tracking-wide">
          Why us? Check out our demo video
        </h4>
        <iframe
          width="100%"
          height="457"
          src="https://www.loom.com/embed/16977bb00c1347e98018a14c22e5023f/?hide_owner=true"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope"
          allowFullScreen
        ></iframe>
      </Onboarding.Box>
    </React.Fragment>
  )
}
