import React from 'react'

import NavigationTabs from 'components/NavigationTabs'
import WaitForData from 'components/WaitForData'

import Usage from './Usage'
import Billing from './Billing'

export default function Account({ account, store }) {
  return (
    <WaitForData
      objects={[]}
      loadingMessage="Fetching account data"
      errorMessage="Trouble fetching account data"
    >
      {() => (
        <div className="flex flex-col items-stretch flex-1 mb-56">
          <div className="flex flex-row flex-wrap items-center mb-6">
            <h3 className="text-sm font-bold text-gray-800 uppercase">
              Account
            </h3>
          </div>
          <NavigationTabs
            tabs={TABS}
            visibilityCheckParams={[]}
            tabProps={{
              account,
              store,
            }}
          />
        </div>
      )}
    </WaitForData>
  )
}

const TABS = [
  {
    label: 'Usage',
    value: 'usage',
    component: Usage,
    root: true,
  },
  {
    label: 'Billing Info',
    value: 'billing',
    component: Billing,
  },
]
