import React from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'
import { useOpenModal } from 'components/Modal'
import { _omit } from 'utils/lodash'

export default function ObjectEdit({ object, onBack, onSave }) {
  const [recommendations, setRecommendations] = React.useState(
    recommendationsFromObject(object)
  )
  const openModal = useOpenModal()

  React.useEffect(() => {
    setRecommendations(recommendationsFromObject(object))
  }, [object])

  return (
    <div className="flex flex-col items-stretch">
      <Box className="flex flex-col items-stretch">
        <span className="text-xl font-semibold text-gray-800">
          Search Term: <span className="text-primary">{object.objectId}</span>
        </span>
      </Box>
      <span
        className="self-start mb-6 text-sm text-primary underline cursor-pointer"
        onClick={onBack}
      >
        ← Choose another term
      </span>
      <Box className="flex flex-col items-stretch">
        <div className="flex flex-row justify-between mb-4">
          <span className="text-xl font-semibold text-gray-800">
            Recommendations
          </span>
          <Button
            size="small"
            onClick={() =>
              openModal(
                <ResourcePicker
                  heading="Choose recommended products"
                  initialSelectedProductIds={recommendationsFromObject(
                    object
                  ).map((o) => o.productId)}
                  onSelect={(
                    selectedProductIds,
                    _twoWaysync,
                    selectedProducts
                  ) => {
                    const existingRecommendations =
                      recommendationsFromObject(object)
                    const selectedRecommendations = selectedProducts.map(
                      (p) => ({
                        ...p,
                        id: p.productId,
                      })
                    )

                    const newRecommendations = [
                      ...selectedRecommendations,
                      ...existingRecommendations,
                    ].filter((rec) =>
                      selectedProductIds.includes(rec.productId)
                    )

                    setRecommendations(newRecommendations)
                    onSave(recommendationsForObject(newRecommendations))
                  }}
                />
              )
            }
          >
            + Add Recommendations
          </Button>
        </div>
        <div>
          {recommendations.length === 0 && (
            <span className="text-sm text-gray-600">
              You can add manual recommendations for this search term using "Add
              Recommendation" button above. Once added you can also re-order the
              recommendations
            </span>
          )}
          <RecommendationsList
            recommendations={recommendations}
            type="page"
            allowReorder={true}
            onReorder={(recommendations) => {
              onSave(recommendationsForObject(recommendations))
            }}
            onRemove={(recommendation) => {
              const newRecommendations = recommendations.filter(
                (r) => r.productId !== recommendation.productId
              )
              onSave(recommendationsForObject(newRecommendations))
              setRecommendations(newRecommendations)
            }}
          />
        </div>
      </Box>
    </div>
  )
}

function recommendationsFromObject(object) {
  return object.data.recommendations.map((r) => ({
    ...r.value,
    id: r.value.productId,
  }))
}

function recommendationsForObject(recommendations) {
  return recommendations.map((r) => {
    return {
      value: _omit(r, ['id']),
    }
  })
}
