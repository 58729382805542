import React, { useState } from 'react'
import classNames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'

import Portal from 'components/Portal'
import Icons from 'components/Icons'
import { Switch } from 'components/Form/controls'
import useAppState from 'hooks/appState'
import { isEmbeddedApp } from 'utils/shopify'

import styles from './styles.module.css'

export default function AccountSwitcher({ activeShop, shops, isRkSupport }) {
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const { name } = activeShop
  const initials = name.substr(0, 1)
  const isEmbedded = React.useMemo(() => isEmbeddedApp(), [])
  const showDropdown = !isEmbedded && (shops.length > 1 || isRkSupport)

  function toggleDropdown() {
    if (showDropdown) {
      setDropdownVisible(!dropdownVisible)
    }
  }

  function switchShop(shop) {
    if (shop.shopName === activeShop.name) {
      setDropdownVisible(false)
    } else {
      const newUrl = `${window.location.protocol}//${window.location.host}/?shop=${shop.name}`
      window.location.href = newUrl
    }
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            onClick={toggleDropdown}
            className={classNames(
              'ml-auto self-stretch flex flex-row items-center -mr-5 px-5',
              {
                'hover:bg-gray-300 cursor-pointer': showDropdown,
                'bg-gray-300': dropdownVisible,
              }
            )}
            style={{
              maxWidth: 350,
            }}
            data-account-switcher={true}
          >
            <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 text-xs text-white uppercase rounded-full bg-primary notranslate">
              {initials}
            </div>
            <div className="flex-1 ml-2 text-lg font-medium text-gray-800 capitalize truncate group-hover:underline notranslate">
              {name}
            </div>
            {showDropdown && (
              <span className="ml-2 text-gray-700">
                <Icons.ChevronDown className="w-5 fill-current" />
              </span>
            )}
          </div>
        )}
      </Reference>
      {dropdownVisible && (
        <Portal>
          <Popper placement={'bottom'} modifiers={POPPER_MODIFIERS}>
            {({ ref, style, placement }) => (
              <div
                ref={ref}
                className={classNames(
                  'flex flex-col items-start bg-white pt-3 rounded-sm shadow border border-gray-300 h-64 overflow-y-auto',
                  styles.container
                )}
                style={style}
                data-placement={placement}
              >
                {isRkSupport && <AdminModeToggle />}
                {shops.length > 1 && (
                  <SwitchShop
                    shops={shops}
                    activeShop={activeShop}
                    switchShop={switchShop}
                  />
                )}
              </div>
            )}
          </Popper>
          <div className={styles.overlay} onClick={toggleDropdown} />
        </Portal>
      )}
    </Manager>
  )
}

const POPPER_MODIFIERS = {
  preventOverflow: { boundariesElement: 'viewport' },
}

function SwitchShop({ shops, activeShop, switchShop }) {
  return (
    <React.Fragment>
      <span className="pl-4 mb-1 text-xs text-gray-500 uppercase">
        Switch Shop
      </span>
      {shops.map((shop, index) => {
        const isActive = shop.shopName === activeShop.name
        return (
          <div
            className={classNames(
              'flex flex-col w-full items-start py-2 pl-4 pr-12 cursor-pointer hover:bg-gray-100 text-lg font-medium',
              {
                'border-b border-gray-200': index !== shops.length - 1,
                'text-primary-800': isActive,
              }
            )}
            key={shop.id}
            onClick={() => switchShop(shop)}
          >
            <span>{shop.shopName}</span>
            <span className="text-xs text-gray-600">{shop.name}</span>
          </div>
        )
      })}
    </React.Fragment>
  )
}

function AdminModeToggle() {
  const { state: appState, updateState: updateAppState } = useAppState()

  function toggleAdminMode(value) {
    setAdminMode(value)
    updateAppState({ adminMode: value })
    setTimeout(() => {
      window.location.href = window.location.href
    }, 500)
  }

  const [adminMode, setAdminMode] = useState(
    typeof appState.adminMode === 'undefined' ? true : appState.adminMode
  )

  return (
    <div className="flex flex-row items-center justify-between w-full py-2 pl-4 pr-12 mb-6 ">
      <span className="">Admin Mode</span>
      <Switch
        checked={adminMode}
        onChange={(value) => toggleAdminMode(value)}
      />
    </div>
  )
}
