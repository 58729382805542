import { useState } from 'react'
import { toggleFastLoad as toggleFastLoadApi } from 'api/dashboard'
import { useToast } from 'components/Toast'

export default function (account) {
  const { fastLoadState: initialState, name: shop } = account.data
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const openToast = useToast()

  async function toggleFastLoad() {
    setLoading(true)
    try {
      const fastLoadEnabled = state === 'enabled'
      await toggleFastLoadApi({ state: !fastLoadEnabled }, { shop })
      setState(fastLoadEnabled ? 'disabled' : 'enabled')

      account.update(
        {
          ...account.data,
          fastLoadState: fastLoadEnabled ? 'disabled' : 'enabled',
        },
        { local: true }
      )
      setSuccess(true)

      openToast({
        type: 'success',
        text: `Fast Load is successfully ${
          fastLoadEnabled ? 'disabled' : 'enabled'
        } for your store`,
      })
    } catch (err) {
      account.update(
        {
          ...account.data,
          fastLoadState: 'failed',
        },
        { local: true }
      )
      setState('failed')
      setSuccess(false)

      openToast({
        type: 'error',
        text: 'There was an error in updating fast load.',
      })
    }

    setLoading(false)
  }

  return {
    state,
    loading,
    success,
    toggleFastLoad,
  }
}
