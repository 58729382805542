import React from 'react'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import Loader from 'components/Loader'
import {
  campaignCreate as campaignCreateApi,
  campaignUpdate as campaignUpdateApi,
} from 'api/campaigns'
import { useQueryParams } from 'hooks/router'

import getTemplate from './templates'

const TEMPLATE_DEFAULTS = {
  titleFontSize: 24,
  showPrice: true,
  productsCount: 4,
  productsPerRow: 2,
  showProductPrice: true,
  showProductTitle: true,
  callToActionText: 'Shop now',
}

export default function EmailCampaignEditor({ account, campaign, onSave }) {
  const accountEmail = account.data.shop.email
  const [isSaving, setIsSaving] = React.useState(false)
  const [previewMode, setPreviewMode] = React.useState('desktop')
  const [localCampaign, setLocalCampaign] = React.useState({
    settings: {
      ...TEMPLATE_DEFAULTS,
      title: campaign.label,
    },
    products: [],
    ...campaign,
  })
  const { shop } = useQueryParams()
  const EmailTemplate = getTemplate(
    campaign.recommendationType,
    campaign.channelSubType
  )

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      ...TEMPLATE_DEFAULTS,
      title: campaign.name,
      ...(campaign.settings || {}),
      previewEmail: accountEmail,
    },
  })
  const settingsData = watch()

  async function onSubmit() {
    try {
      setIsSaving(true)
      const { data } = await campaignUpdateApi(
        localCampaign.id,
        {
          settings: settingsData,
          template: EmailTemplate.toString(),
          html: EmailTemplate(account.data, {
            ...localCampaign,
            settings: settingsData,
          }),
        },
        { shop }
      )

      setIsSaving(false)
      onSave(data.campaign)
    } catch (err) {}
  }

  React.useEffect(() => {
    async function ensureCampaignIsPersisted() {
      try {
        const { data } = await campaignCreateApi(
          {
            ...localCampaign,
            settings: settingsData,
            html: EmailTemplate(account.data, localCampaign),
            template: EmailTemplate.toString(),
          },
          { shop }
        )
        setLocalCampaign(data.campaign)
      } catch (err) {
        console.log('ERROR CREATING CAMPAIGN!', err)
      }
    }

    if (campaign && !campaign.id) {
      ensureCampaignIsPersisted()
    } else {
      setLocalCampaign(campaign)
    }
  }, [campaign])

  return (
    <React.Fragment>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-row justify-between">
          <Form.Field
            label="Title"
            name="title"
            control="input"
            className="flex-1 mr-4"
            type="text"
            ref={register({ required: true })}
            helpText="This text will be used as title of the section in your email."
          />
          <Form.Field
            label="Title font size"
            name="titleFontSize"
            control="input"
            className="flex-1"
            type="number"
            ref={register({ required: true })}
            helpText="Font size of the section title."
          />
        </div>
        <div className="flex flex-row justify-between">
          <Form.Field
            label="Total Products"
            name="productsCount"
            control="input"
            className="flex-1 mr-4"
            type="number"
            ref={register({ required: true })}
            helpText="Total number of products to display in the section."
          />
          <Form.Field
            label="Products per row"
            name="productsPerRow"
            control="input"
            disabled={true}
            className="flex-1"
            type="number"
            ref={register({ required: true })}
            helpText="Number of products in each row of the section."
          />
        </div>
        {campaign.recommendationType !== 'personalized' &&
          campaign.recommendationType !== 'bought_together' && (
            <div className="flex flex-row justify-between">
              <Form.Field
                label="Show product title"
                name="showProductTitle"
                control="checkbox"
                className="flex-1 mr-4"
                ref={register()}
                helpText="Include title of each product in the email."
              />
              <Form.Field
                label="Show product price"
                name="showProductPrice"
                control="checkbox"
                className="flex-1"
                ref={register()}
                helpText="Include price of each product in the email."
              />
            </div>
          )}
        <div className="flex flex-row justify-between">
          <Form.Field
            label="Call to action text for button"
            name="callToActionText"
            control="input"
            className="flex-1 mr-4"
            ref={register()}
            helpText="Include custom call to action text. Leave empty to hide the button."
          />
          {(campaign.recommendationType === 'personalized' ||
            campaign.recommendationType === 'bought_together') && (
            <Form.Field
              label="Preview Email"
              name="previewEmail"
              control="input"
              className="flex-1"
              ref={register()}
              helpText="Edit email to preview for a specific customer."
            />
          )}
        </div>
        <Button loading={isSaving} size="small" className="self-end">
          Save
        </Button>
      </form>
      <div className="flex flex-row items-center justify-center mt-6 mb-2 text-sm text-gray-400">
        <span
          className={classnames('mr-1 hover:text-gray-800 cursor-pointer', {
            ['text-gray-800 underline']: previewMode === 'desktop',
          })}
          onClick={() => setPreviewMode('desktop')}
        >
          🖥 Desktop
        </span>
        <span
          className={classnames('ml-1 hover:text-gray-800 cursor-pointer', {
            ['text-gray-800 underline']: previewMode === 'mobile',
          })}
          onClick={() => setPreviewMode('mobile')}
        >
          📱 Mobile
        </span>
      </div>
      <div className="flex flex-row items-center justify-center p-4 bg-gray-400 rounded">
        {localCampaign.id ? (
          <PreviewFrame
            width={previewMode === 'desktop' ? 600 : 340}
            height={500}
            content={EmailTemplate(
              { shop: { email: settingsData.previewEmail || accountEmail } },
              {
                ...localCampaign,
                settings: settingsData,
              },
              true
            )}
          />
        ) : (
          <div className="my-5">
            <Loader message="Building campaign preview..." />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

function PreviewFrame({ width, height, content }) {
  const $frame = React.useRef(null)
  React.useEffect(() => {
    if (!$frame) {
      return
    }

    const document = $frame.current.contentDocument
    document.open()
    document.write(content)
    document.close()
  }, [content])

  return (
    <iframe
      style={{ width, height, background: '#fff', borderRadius: 3 }}
      ref={$frame}
    />
  )
}
