import React, { useEffect } from 'react'

import { Select } from 'components/Form/controls'

export default function ConditionInput({
  value,
  onChange,
  type,
  readOnly,
  currencySymbol,
}) {
  useEffect(() => {
    if (type === 'bool' && !readOnly) {
      onChange({ target: { value: 'true' } })
    }
  }, [])

  switch (type) {
    case 'currency':
    case 'percent':
      // TODO: Pick from store currency
      const icon = type === 'currency' ? currencySymbol : '%'
      return (
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
            <span className="text-xs font-semibold text-gray-500">{icon}</span>
          </div>
          <input
            type="number"
            value={value || ''}
            onChange={onChange}
            className="font-semibold appearance-none block w-full px-2 py-1.5 border focus:outline-none ease-in-out rounded shadow-sm border-gray-300 placeholder-gray-400 focus:ring-primary focus:border-primary pl-5 leading-none text-xs"
            placeholder="0.00"
            disabled={readOnly}
          />
        </div>
      )

    case 'string':
    case 'number':
      return (
        <div className="relative">
          <input
            type={type === 'number' ? 'number' : 'text'}
            value={value || ''}
            onChange={onChange}
            className="font-semibold appearance-none block w-full px-2 py-1.5 border focus:outline-none ease-in-out rounded shadow-sm border-gray-300 placeholder-gray-400 focus:ring-primary focus:border-primary leading-none text-xs"
            placeholder={type === 'number' ? '0' : 'Enter value...'}
            step={1}
            disabled={readOnly}
          />
        </div>
      )
    case 'datetime':
      return <div className="text-xs text-gray-600">Date Picker</div>

    case 'bool':
      return (
        <Select
          control="select"
          name="operator"
          options={[
            { label: 'True', value: true },
            { label: 'False', value: false },
          ]}
          value={value}
          disabled={readOnly}
          onChange={onChange}
          size="small"
        />
      )

    default:
      return null
  }
}
