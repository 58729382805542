import React from 'react'
import classNames from 'classnames'

export default function Table({ headings, rowItems, renderRow, className }) {
  if (!headings || headings.length === 0) {
    return
  }

  return (
    <table className={classNames('table-auto', className)}>
      <thead>
        <tr>
          {headings.map((heading, index) => (
            <th className="px-4 py-2 text-left text-gray-800" key={index}>
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowItems.map((rowItem, index) => {
          const rowClassName = index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
          return renderRow(rowItem, index, rowClassName)
        })}
      </tbody>
    </table>
  )
}

Table.defaultProps = {
  headings: [],
  rowItems: [],
}
