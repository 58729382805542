import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useQueryParams } from 'hooks/router'
import useFunnelMutations from 'hooks/queries/funnels'
import { getAccountData } from 'utils/account'
import { fetchFunnel } from 'api/funnel'

export default function useFunnelDetails() {
  const { funnelId } = useParams()
  const { shop } = useQueryParams()
  const accountData = getAccountData()

  const query = useQuery(['funnel', { shop, funnelId }], fetchFunnel)
  const {
    updateFunnel: { mutate: updateFunnel, status: updateFunnelStatus },
  } = useFunnelMutations({ shop: accountData.name })

  const funnelUpdating = updateFunnelStatus === 'loading'

  return {
    funnelDetailsQuery: query,
    funnelUpdating,
    updateFunnel,
    accountData,
  }
}
