import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'

import Box from 'components/Box'
import Icons from 'components/Icons'
import Button from 'components/Button'
import Form from 'components/Form'
import { Select } from 'components/Form/controls'
import LANGUAGES from 'constants/languages'

export default function TemplateTranslations({
  account,
  template,
  onSave,
  isSaving,
  openUpgradeAccount,
}) {
  const { primaryLocale = 'en' } = account.data.shop
  const translations = getTranslationsFromTemplateData(template)
  const hasEnabledTranslations = true
  const [activeLanguage, setActiveLanguage] = React.useState(primaryLocale)
  const updateTranslationsDisabled =
    openUpgradeAccount && typeof openUpgradeAccount === 'function'

  const { register, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: translations.primaryLocale,
  })

  useEffect(() => {
    if (activeLanguage === primaryLocale) {
      reset(translations.primaryLocale)
    } else {
      reset(translations[activeLanguage])
    }
  }, [activeLanguage])

  async function onSubmit(data) {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      const translationsObject = {
        ...translations,
        [activeLanguage]: data,
        ...(activeLanguage === primaryLocale ? { primaryLocale: data } : {}),
      }

      const updateObject = Object.keys(translationsObject).reduce(
        (accum, key) => {
          if (Object.keys(translationsObject[key]).length > 0) {
            return { ...accum, [key]: translationsObject[key] }
          } else {
            return accum
          }
        },
        {}
      )

      onSave({
        translations: updateObject,
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <Box>
        <div className={'flex flex-col items-stretch mb-6'}>
          <label
            className="mb-1 text-xs font-semibold"
            htmlFor={'languageSelect'}
          >
            Choose Language
          </label>
          <Select
            options={LANGUAGES.map((l) => ({
              value: l.value,
              label: l.label,
            }))}
            name="languageSelect"
            className={hasEnabledTranslations ? '' : 'opacity-50'}
            onChange={(event) => {
              setActiveLanguage(event.target.value)
            }}
            disabled={!hasEnabledTranslations}
            value={activeLanguage}
          />
          <div className="flex items-center justify-end mt-2 text-sm text-gray-800">
            <span className="text-xs text-gray-600">
              Please choose the language you want to modify the translations
              for.
            </span>
          </div>
        </div>
        <form
          className="flex flex-col items-start flex-1 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-row flex-wrap w-full">
            {TRANSLATION_FIELDS.map((field, index) => {
              const isEven = index % 2 === 0
              return (
                <Form.Field
                  label={field.label}
                  name={field.value}
                  control="input"
                  ref={register({ required: false })}
                  controlClassName="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none flex-1/2 hover:border-gray-500 focus:outline-none focus:shadow-outline"
                  className={classnames('w-full lg:w-1/2 mb-6', {
                    'lg:pr-3': isEven,
                    'lg:pl-3': !isEven,
                  })}
                  helpText={field.helpText}
                  key={field.value}
                />
              )
            })}
          </div>
          <Button loading={isSaving}>
            {' '}
            <div className="flex items-center justify-center">
              <span>Save</span>
              {updateTranslationsDisabled && (
                <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
              )}
            </div>
          </Button>
        </form>
      </Box>
    </div>
  )
}

function getTranslationsFromTemplateData(template) {
  const defaults = LANGUAGES.reduce(
    (obj, lang) => ({
      ...obj,
      [lang.value]: {},
    }),
    {}
  )

  return {
    ...defaults,
    ...template.translations,
  }
}

const TRANSLATION_FIELDS = [
  {
    label: 'Add to cart button text',
    value: 'addToCartText',
    default: 'Add to cart',
    helpText:
      'This text will be displayed inside add to cart button for carousel/grid layouts.',
  },
  {
    label: 'Added to cart text',
    value: 'addedToCartText',
    default: 'Added to cart',
    helpText:
      'This text will be displayed when a customer has added a recommended product to their cart.',
  },
  {
    label: 'Adding to cart text',
    value: 'addingToCartText',
    default: 'Adding...',
    helpText:
      'This text will be displayed whenever a customer is adding a recommended product to their cart.',
  },
  {
    label: 'Sale(Discount) label text',
    value: 'discountLabelText',
    default: '{percent} off',
    helpText:
      'This text will be displayed inside sale labels for each product item.',
  },
  {
    label: 'Go to cart text',
    value: 'goToCartText',
    default: 'Go to cart',
    helpText:
      'This text will be displayed in the go to cart link under every recommendation.',
  },
  {
    label: 'Out of stock text',
    value: 'outOfStockText',
    default: 'Out of stock',
    helpText:
      'This text will be displayed whenever a recommended product is out of stock.',
  },
  {
    label: 'Add to cart button text (FBT Amazon Layout)',
    value: 'fbtAddToCartText',
    default: 'Add selected to cart',
    helpText:
      'This text will be displayed inside add to cart button for Amazon style frequently bought together widget.',
  },
  {
    label: 'This Product text (FBT Amazon Layout)',
    value: 'thisProductText',
    default: 'This Product:',
    helpText:
      "This text will replace 'This Product:' label in Amazon style frequently bought together widget.",
  },
  {
    label: 'Total price text (FBT Amazon Layout)',
    value: 'totalPriceText',
    default: 'Total Price:',
    helpText:
      "This text will replace 'Total Price:' label in Amazon style frequently bought together widget.",
  },
]
