import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { TRIGGER_OPTIONS } from 'containers/Popups/constants'
import { useToast } from 'components/Toast'
import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import Tooltip from 'components/Tooltip'

import TimeTriggered, { TimeTriggeredExtra } from './TriggerTypes/TimeTriggered'
import Inclusions from './Inclusions'
import Exclusions from './Exclusions'

export default function Trigger({ builder }) {
  const {
    popup,
    savePopup,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
    accountData,
  } = builder

  const [selectedTriggers, setSelectedTriggers] = useState(
    popup?.triggers ? popup.triggers : {}
  )
  const [inclusions, setInclusions] = useState(
    popup?.inclusions ? popup.inclusions : []
  )
  const [exclusions, setExclusions] = useState(
    popup?.exclusions ? popup.exclusions : [{ pageLinks: [''] }]
  )

  const openToast = useToast()

  function validateInclusions() {
    let errorMessage
    inclusions.forEach((inclusion) => {
      const { pageLinks, pageType } = inclusion
      if (
        inclusions.filter((inclusion) => inclusion.pageType === pageType)
          .length > 1
      ) {
        errorMessage = 'Two entries for inclusions found with same page type'
      }
      pageLinks.forEach((link) => {
        if (link === '') {
          return
        }
        try {
          const url = new URL(link)
          if (
            !(
              url.host.includes(accountData.name) ||
              url.host.includes(accountData.shop.domain)
            )
          ) {
            errorMessage = 'Inclusions Error: URL does not contain shop domain'
          }
        } catch (err) {
          errorMessage = 'Inclusions Error: Ensure URL is in the correct format'
        }
      })
    })
    return errorMessage
  }

  function validateExclusions() {
    let errorMessage = ''
    exclusions[0].pageLinks.forEach((link) => {
      if (link === '') {
        return
      }
      try {
        const url = new URL(link)
        if (
          !(
            url.host.includes(accountData.name) ||
            url.host.includes(accountData.shop.domain)
          )
        ) {
          errorMessage = 'Exclusions Error: URL does not contain shop domain'
        }
      } catch (err) {
        errorMessage = 'Exclusions Error: Ensure URL is in the correct format'
      }
    })
    return errorMessage
  }

  function validateTriggers() {
    const atLeastOneTriggerEnabled = Object.keys(selectedTriggers).some(
      (triggerKey) => selectedTriggers[triggerKey].enabled
    )

    return atLeastOneTriggerEnabled
      ? false
      : 'At least one trigger must be enabled'
  }

  useEffect(() => {
    async function popupSave() {
      const validatedErrorMessage =
        validateInclusions() || validateExclusions() || validateTriggers()
      if (validatedErrorMessage) {
        openToast({
          type: 'error',
          text: validatedErrorMessage,
        })
        return null
      } else {
        return await savePopup({
          ...popup,
          inclusions,
          exclusions,
          triggers: selectedTriggers,
        })
      }
    }
    setSaveHandler(() => popupSave)
    setSaveNextHandler(() => async () => {
      const savedPopup = await popupSave()
      if (savedPopup) {
        setActiveScreen('template')
      }
    })
  }, [selectedTriggers, inclusions, exclusions])

  function addOrRemoveTrigger(trigger) {
    const selectedTrigger = selectedTriggers[trigger.type]
    if (selectedTrigger?.enabled) {
      setSelectedTriggers({
        ...selectedTriggers,
        [trigger.type]: {
          type: trigger.value,
          settings: trigger.settings,
          enabled: false,
        },
      })
    } else {
      setSelectedTriggers({
        ...selectedTriggers,
        [trigger.type]: {
          type: trigger.value,
          settings: trigger.settings,
          enabled: true,
        },
      })
    }
    return selectedTrigger
  }

  return (
    <div className="flex flex-col items-stretch w-full h-full">
      <div className="flex flex-col w-full px-16 py-10 ">
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">Trigger Popup on</span>
          <span className="mt-2 text-base text-gray-500">
            Complete the following steps to get started
          </span>
        </div>
        <div>
          <div className="grid items-stretch grid-cols-3 gap-5 mt-5 lg:grid-cols-4">
            {TRIGGER_OPTIONS.map((trigger) => {
              return (
                <TriggerCard
                  key={trigger.value}
                  trigger={trigger}
                  selectedTriggers={selectedTriggers}
                  addOrRemoveTrigger={addOrRemoveTrigger}
                  SettingsComponent={
                    TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP[trigger.type]
                      .SettingsComponent
                  }
                  ExtraComponent={
                    TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP[trigger.type]
                      .ExtraComponent
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className="bg-gray-300 h-0.5 w-full"></div>
      <div className="flex h-full">
        <div className="w-1/2 border-r border-gray-300">
          <Inclusions inclusions={inclusions} setInclusions={setInclusions} />
        </div>
        <div className="w-1/2">
          <Exclusions exclusions={exclusions} setExclusions={setExclusions} />
        </div>
      </div>
    </div>
  )
}

const TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP = {
  popupManual: {},
  popupExit: {},
  popupTime: {
    SettingsComponent: TimeTriggered,
    ExtraComponent: TimeTriggeredExtra,
  },
}

function TriggerCard({
  selectedTriggers,
  addOrRemoveTrigger,
  trigger,
  SettingsComponent,
  ExtraComponent,
}) {
  const selectedTrigger = selectedTriggers[trigger.type]
  const isUnavailable =
    typeof trigger.available != 'undefined' && !trigger.available

  const settings = selectedTrigger?.settings ? selectedTrigger.settings : {}
  const openModal = useOpenModal()
  return (
    <Tooltip content={trigger.tooltipContent} triggerClassName="!flex">
      <div
        className={classNames(
          'flex flex-row items-start justify-between p-4 border border-gray-400 rounded shadow-sm w-full',
          {
            'opacity-50 cursor-not-allowed': isUnavailable,
            'cursor-pointer hover:border-primary focus:border-primary-400 group':
              !isUnavailable,
          }
        )}
        onClick={() => {
          if (!isUnavailable) {
            if (!selectedTrigger?.enabled && SettingsComponent) {
              openModal(
                <SettingsComponent
                  settings={settings}
                  onSave={(updatedSettings) => {
                    addOrRemoveTrigger({
                      ...trigger,
                      settings: {
                        ...settings,
                        ...updatedSettings,
                      },
                    })
                  }}
                />,
                {
                  fullScreen: false,
                  className: 'w-2/5',
                }
              )
            } else {
              addOrRemoveTrigger({
                ...trigger,
              })
            }
          }
        }}
      >
        <div className="flex flex-col items-center justify-start h-full">
          <div className="flex flex-row items-center justify-start w-full">
            <span className="text-sm font-semibold">{trigger.label}</span>
          </div>
          <span className="mt-2 text-xs text-gray-600">
            {trigger.description}
          </span>
        </div>
        <div className="flex flex-col items-center justify-between w-1/5 ml-4">
          <span
            className={classNames(
              'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:border-primary-400 group-hover:border',
              {
                'bg-primary': selectedTrigger?.enabled,
              }
            )}
          >
            <Icons.Tick className="w-3 text-white stroke-current" />
          </span>
          {ExtraComponent && (
            <ExtraComponent settings={selectedTrigger?.settings} />
          )}
        </div>
      </div>
    </Tooltip>
  )
}
