import { useState } from 'react'

import { widgetSave as widgetSaveApi } from 'api/widgets'
import { report } from 'utils/reporting'
import * as QueryUtils from 'utils/query'

export default function ({ account, pages }) {
  const [savingWidgetId, setSavingWidgetId] = useState(null)
  const { name: shop } = (account && account.data) || {}

  async function saveWidget(widget, payload, onSuccess) {
    setSavingWidgetId(widget.id)

    try {
      const { data: newWidget } = await widgetSaveApi(widget.id, payload, {
        shop,
      })
      const newPages = pages.data.map((page) => {
        if (page.type !== widget.pageType) {
          return page
        }

        return {
          ...page,
          widgets: page.widgets.map((item) =>
            item.id === newWidget.id ? newWidget : item
          ),
        }
      })
      if (typeof pages.update === 'function') {
        pages.update(newPages, { local: true })
      }
      QueryUtils.invalidate('pages')
      typeof onSuccess === 'function' && onSuccess()
    } catch (err) {
      report(err)
    }
    setSavingWidgetId(null)
  }

  return { savingWidgetId, saveWidget }
}
