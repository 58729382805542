import React from 'react'
import classNames from 'classnames'
import { optimisedImage } from 'utils/shopify'

import styles from './styles.module.css'

export default function ProductItem({
  product,
  onSelect,
  onDeselect,
  isSelected,
}) {
  function handleSelection() {
    if (isSelected) {
      onDeselect(product)
    } else {
      onSelect(product)
    }
  }

  return (
    <div
      className={classNames(
        'flex flex-row items-center flex-1 py-2 border-b border-gray-400 cursor-pointer hover:bg-gray-100',
        styles.resultContainer
      )}
      onClick={handleSelection}
    >
      <input
        type="checkbox"
        value={isSelected}
        checked={isSelected}
        className="ml-2 mr-2 bg-white border border-gray-400 rounded shadow cursor-pointer hover:border-gray-500 focus:outline-none focus:shadow-outline"
        onChange={handleSelection}
      />
      <img
        src={optimisedImage({ baseUrl: product.image, size: 100 })}
        className="object-contain w-16 h-16 mr-4 border border-gray-100"
        alt={product.title}
      />
      <span className="flex-1 text-sm leading-snug text-gray-800 notranslate">
        {product.title}{' '}
        <span className="text-xs text-gray-600">
          (Product ID: {product.productId}, Handle: {product.handle})
        </span>
        {!product.isAvailable ||
          (!product.image && (
            <span className="ml-3 text-xs text-red-600">
              This product{' '}
              {!product.isAvailable && (
                <span>
                  is <span className="font-semibold">unavailable</span>
                </span>
              )}{' '}
              {!product.isAvailable && !product.image && 'and'}{' '}
              {!product.image && (
                <span>
                  has <span className="font-semibold">no image</span>
                </span>
              )}{' '}
              and might not show up as recommendation.
            </span>
          ))}
      </span>
    </div>
  )
}
