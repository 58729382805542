import React, { useEffect } from 'react'
import { DateTimePicker } from 'react-widgets'
import dateFnsLocalizer from 'react-widgets-date-fns'

import { TIMESTAMP } from 'constants/fieldTypes'
import * as FILTER_OPERATORS from 'constants/filterOperators'

dateFnsLocalizer()

export default function TimestampFilterInput({ filters, field }) {
  const fieldState = filters.state[field.key]
  const secondaryInputVisible = [
    FILTER_OPERATORS.WITHIN_RANGE.value,
    FILTER_OPERATORS.OUTSIDE_RANGE.value,
  ].includes(fieldState.operator)

  useEffect(() => {
    if (!fieldState.operator) {
      filters.setFieldOperator({
        key: field.key,
        value: TIMESTAMP.filterOperatorOptions[0].value,
      })
    }
  }, [])

  return (
    <div className="flex flex-col items-stretch w-full mt-2 space-y-1 pl-7">
      <select
        onChange={(e) => {
          filters.setFieldOperator({
            key: field.key,
            value: e.target.value,
          })
        }}
        value={fieldState.operator}
        className="block w-full px-1 py-1 mb-1 bg-gray-200 border border-gray-400 rounded sm:text-sm focus:border-indigo-500"
      >
        {TIMESTAMP.filterOperatorOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <div className="flex flex-row items-center justify-between">
        <DateTimePicker
          autoFocus={false}
          date={true}
          time={false}
          max={new Date()}
          value={
            fieldState.values[0] ? new Date(fieldState.values[0] * 1000) : null
          }
          onChange={(value) => {
            filters.setFieldValues({
              key: field.key,
              values: [
                Math.floor(value.getTime() / 1000),
                fieldState.values[1],
              ],
            })
          }}
        />
        {secondaryInputVisible && (
          <React.Fragment>
            <span className="mx-3 font-bold">-</span>
            <DateTimePicker
              autoFocus={false}
              date={true}
              time={false}
              max={new Date()}
              value={
                fieldState.values[1]
                  ? new Date(fieldState.values[1] * 1000)
                  : null
              }
              onChange={(value) => {
                filters.setFieldValues({
                  key: field.key,
                  values: [
                    fieldState.values[0],
                    Math.floor(value.getTime() / 1000),
                  ],
                })
              }}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}
