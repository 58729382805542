import React from 'react'
import classNames from 'classnames'
import { SketchPicker } from 'react-color'
import styles from './styles.module.css'

export default function ColorPicker({
  className,
  value,
  onChange,
  disabled,
  pickerPosition = 'top',
}) {
  const [visible, setVisible] = React.useState(false)
  function updateVisible(value) {
    if (disabled) {
      return
    }

    setVisible(value)
  }

  return (
    <div
      className={classNames('relative flex flex-row items-center', className)}
    >
      <div
        className={classNames('relative border border-gray-600 rounded-sm', {
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
        })}
      >
        <span
          className="flex w-5 h-5 p-1 rounded-sm"
          style={{ backgroundColor: value }}
          onClick={() => updateVisible(true)}
        ></span>
        {visible && (
          <div className={styles.cover} onClick={() => updateVisible(false)} />
        )}
        <div
          className={classNames(
            styles.pickerWrap,
            POSITION_TO_CLASS_MAP[pickerPosition],
            {
              [styles.pickerWrapVisible]: visible,
            }
          )}
        >
          <SketchPicker
            color={value}
            onChange={({ hex, rgb }) => {
              let alphaHex = Math.floor(rgb.a * 255).toString(16)
              if (alphaHex.length === 1) {
                alphaHex = `0${alphaHex}`
              }
              onChange(`${hex}${alphaHex}`)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const POSITION_TO_CLASS_MAP = {
  top: styles.pickerWrapTop,
  bottom: styles.pickerWrapBottom,
}
