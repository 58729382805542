import React from 'react'
import Tooltip from 'components/Tooltip'
import Link from 'components/Link'
import Redirect from 'components/Redirect'
import { useRouteMatch, matchPath } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import styles from './styles.module.css'

function isTabVisible(tab, visibilityCheckParams) {
  return typeof tab.isVisible === 'function'
    ? tab.isVisible.apply(this, visibilityCheckParams)
    : true
}

export default function NavigationTabs({
  tabs,
  visibilityCheckParams,
  tabProps,
  background,
}) {
  const { url, path } = useRouteMatch()
  const visibleTabs = tabs.filter((t) => isTabVisible(t, visibilityCheckParams))

  return (
    <React.Fragment>
      <div className="flex flex-row items-center pb-3 mb-6 border-b border-gray-400">
        {visibleTabs.map((tab) => {
          return (
            <Tooltip
              content={tab.tooltip}
              key={tab.value}
              triggerClassName="group"
            >
              <Link
                useNav={true}
                exact={true}
                activeClassName={styles.activeTab}
                to={tab.root ? url : `${url}/${tab.value}`}
                className={
                  'px-4 py-2 mr-2 text-sm rounded capitalize cursor-pointer hover:opacity-100 bg-white opacity-75 text-gray-800 group-last:mr-0 whitespace-nowrap'
                }
              >
                {tab.label}
              </Link>
            </Tooltip>
          )
        })}
      </div>
      <Switch>
        {visibleTabs.map((tab) => (
          <Route
            exact={tab.root}
            path={tab.root ? path : `${path}/${tab.value}`}
            key={tab.value}
          >
            <tab.component {...tabProps} activeTab={tab} />
          </Route>
        ))}
        <Redirect to={url} />
      </Switch>
    </React.Fragment>
  )
}

NavigationTabs.defaultProps = {
  visibilityCheckParams: [],
}
