import React from 'react'

import RenderMoney from 'components/RenderMoney'

export default function Pricing({ layout }) {
  const {
    data: {
      settings: {
        pricing: {
          showCompare,
          showDiscountedAmt,
          showDiscountPercent,
          totalPriceColor,
          comparePriceColor,
          discountPercentageColor,
        },
      },
    },
    lineItems,
  } = layout

  const lineItemsTotal = lineItems.reduce((accm, lineItem) => {
    return parseFloat(lineItem.price) + accm
  }, 0.0)
  const lineItemsOriginalTotal = lineItems.reduce((accm, lineItem) => {
    return parseFloat(lineItem.variants[0].compareAtPrice) + accm
  }, 0.0)

  const discountPercentage = lineItemsTotal
    ? parseInt(
        ((lineItemsOriginalTotal - lineItemsTotal) / lineItemsOriginalTotal) *
          100
      )
    : 0

  const isDiscounted = lineItemsOriginalTotal > lineItemsTotal

  return (
    <div className="flex flex-col items-stretch justify-center pt-4 border-t border-gray-400 px-7">
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-col items-start">
          <span className="text-xs text-gray-600">Subtotal (3 Items)</span>
          <RenderMoney
            price={lineItemsTotal}
            className="mt-1 font-semibold"
            style={{
              color: totalPriceColor,
            }}
          />
        </div>
        <div className="flex flex-col items-end">
          {showDiscountPercent && (
            <span
              className="text-xs "
              style={{
                color: discountPercentageColor,
              }}
            >
              {`(${discountPercentage}% OFF)`}
            </span>
          )}
          {showCompare && (
            <RenderMoney
              price={lineItemsOriginalTotal}
              className="mt-1 line-through"
              style={{
                color: comparePriceColor,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
