import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Text from 'components/Text'
import { useOpenModal } from 'components/Modal'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import { trackEvent } from 'utils/analytics'
import { isFeatureEnabled } from 'utils/features'
import { getAccountData } from 'utils/account'
import { BUNDLES } from 'constants/features'

import BundleList from './BundleList'
import BundleBuilder from './BundleBuilder'
import BundleDetails from './BundleDetails'

export default function Bundles({ account, store }) {
  const accountData = getAccountData()
  const openModal = useOpenModal()
  return (
    <Switch>
      <Route path="/bundles/:bundleId">
        <BundleDetails
          onEditBundle={(bundle, screen) => {
            if (bundle) {
              openModal(
                <BundleBuilder
                  initialActiveScreen={screen}
                  initialBundle={bundle}
                  store={store}
                />,
                {
                  hideCloseButton: true,
                  fullScreen: true,
                  className: 'bg-white h-screen',
                }
              )
            }
          }}
        />
      </Route>
      <Route path="/bundles">
        <BundleHome accountData={accountData} store={store} />
      </Route>
    </Switch>
  )
}

function BundleHome({ accountData, store }) {
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(accountData, BUNDLES)
  return (
    <div className="flex flex-col ">
      <div className="flex flex-row items-center justify-between mb-5">
        <Text.ScreenHeading marginBottom={0}>Bundles</Text.ScreenHeading>
        <Button
          onClick={() => {
            trackEvent({
              category: BUNDLES.label,
              label: 'Dashboard',
              action: 'Click add bundle',
            })
            if (!featureEnabled) {
              openModal(
                <UpgradeAccountModal
                  featureToEnable={BUNDLES}
                  account={{ data: accountData }}
                  customMessage={
                    <React.Fragment>
                      <span className="mr-1 font-semibold">
                        {BUNDLES.label}
                      </span>{' '}
                      is currently a beta feature. If you would like to opt in,
                      let us know in the chat
                    </React.Fragment>
                  }
                />
              )
            } else {
              openModal(<BundleBuilder store={store} />, {
                hideCloseButton: true,
                fullScreen: true,
                className: 'bg-white h-screen',
              })
            }
          }}
        >
          <div className="flex items-center justify-center">
            <span className="">+ Add New Bundle</span>
            {!featureEnabled && (
              <Icons.Lock className={'w-5 ml-4 mb-1 fill-current opacity-75'} />
            )}
          </div>
        </Button>
      </div>
      <div className="flex flex-row w-full">
        <BundleList />
      </div>
    </div>
  )
}
