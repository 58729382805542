import React from 'react'
import classNames from 'classnames'

import Line from '../Line'

export default function PromotionBuilderPreviewImage({ section }) {
  const {
    settings: {
      layout,
      sourceFullKey,
      altText,
      border,
      textBlockVisible,
      spacing,
      alignment,
      textBlockPlacement,
      textBlockSpacing,
      lines = [],
    },
  } = section

  const imageContent = sourceFullKey ? (
    <div className="flex flex-col flex-1">
      <img
        src={`https://cdn.glood.ai/${sourceFullKey}`}
        alt={altText}
        className={classNames('w-full', {
          'border border-gray-600': border,
        })}
      />
    </div>
  ) : null

  const textBlockContent =
    textBlockVisible && lines.filter((l) => !!l.text).length > 0 ? (
      <div
        className={classNames('flex-1 flex flex-col items-center', {
          'ml-4': textBlockPlacement === 'right' && spacing === 'xtight',
          'mr-4': textBlockPlacement === 'left' && spacing === 'xtight',

          'ml-8': textBlockPlacement === 'right' && spacing === 'tight',
          'mr-8': textBlockPlacement === 'left' && spacing === 'tight',

          'ml-12': textBlockPlacement === 'right' && spacing === 'loose',
          'mr-12': textBlockPlacement === 'left' && spacing === 'loose',

          'ml-16': textBlockPlacement === 'right' && spacing === 'xloose',
          'mr-16': textBlockPlacement === 'left' && spacing === 'xloose',

          'space-y-2': textBlockSpacing === 'xtight',
          'space-y-3': textBlockSpacing === 'tight',
          'space-y-5': textBlockSpacing === 'loose',
          'space-y-7': textBlockSpacing === 'xloose',
        })}
      >
        {lines
          .filter((l) => !!l.text)
          .map((line) => (
            <Line line={line} key={line.id} />
          ))}
      </div>
    ) : null

  return (
    <div
      className={classNames('flex flex-row w-full mx-auto px-8', {
        'max-w-[1024px]': layout === 'center',
        'items-center': alignment === 'center',
        'items-start': alignment === 'leading',
        'items-end': alignment === 'trailing',
      })}
    >
      {textBlockPlacement === 'left' && imageContent && textBlockContent}
      {imageContent}
      {textBlockPlacement === 'right' && imageContent && textBlockContent}
    </div>
  )
}

// {
/* <div
        className={classNames(
          'flex flex-col max-w-[1024px] w-full mx-auto space-y-2',
          {
            'items-center': alignment === 'center',
            'items-start': alignment === 'leading',
            'items-end': alignment === 'trailing',
          }
        )}
      >
        
      </div> */
// }
