import { BUNDLES } from 'constants/features'
import { DISCOUNT_TYPES } from 'constants/discount'
import { isFeatureEnabled } from 'utils/features'
import { keyFromType } from 'utils/constants'
import { renderCurrency } from 'utils/currency'

import {
  BUNDLE_STATUS_OPTIONS,
  DISCOUNT_LEVEL_OPTIONS,
  TRIGGER_OPTIONS,
} from './constants'

export function calculateDiscountPrice(price, discountType, discountValue) {
  if (!discountType || !discountValue) {
    return price
  }
  if (discountType === DISCOUNT_TYPES.FLAT.value) {
    return (parseFloat(price) - parseFloat(discountValue)).toFixed(2)
  }
  return (parseFloat(price) * (1 - discountValue / 100)).toFixed(2)
}

export function calculateDiscountedValue(discountType, price, discountedPrice) {
  if (!discountType) {
    return 0
  }
  if (discountType === DISCOUNT_TYPES.FLAT.value) {
    return parseFloat(((price - discountedPrice) / price) * 100).toFixed(2)
  }

  return parseFloat(price - discountedPrice).toFixed(2)
}

export function getBundleStatusOptions(currStatus, accountData) {
  let allowedPromotions = []
  const featureEnabled = isFeatureEnabled(accountData, BUNDLES)
  switch (currStatus) {
    case 'draft':
      allowedPromotions = ['active']
      if (featureEnabled) {
        allowedPromotions.push('deleted')
      }
      break
    case 'inactive':
      allowedPromotions = ['active', 'deleted']
      break
    case 'active':
      allowedPromotions = ['inactive', 'deleted']
      break
    case 'deleted':
      break
  }

  return BUNDLE_STATUS_OPTIONS.map((option) => {
    if (
      allowedPromotions.includes(option.value) ||
      option.value === currStatus
    ) {
      return {
        ...option,
        ...(option.value === 'active'
          ? { locked: !featureEnabled }
          : { locked: false }),
      }
    }
  }).filter(Boolean)
}

export function getDiscountLevelLabelFromType(type) {
  return keyFromType('label', DISCOUNT_LEVEL_OPTIONS, type)
}

export function getDiscountTypeLabelFromType(type) {
  return keyFromType('label', DISCOUNT_TYPES, type)
}

export function getTriggerTypeLabelFromType(type) {
  return keyFromType('label', TRIGGER_OPTIONS, type)
}

export function getDiscountValue(type, value, currencyFormat) {
  if (type === DISCOUNT_TYPES.FLAT.value) {
    return renderCurrency(value, currencyFormat)
  }
  return `${value}%`
}
