import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'
import Form from 'components/Form'
import { _find, _get } from 'utils/lodash'

import useCountriesSlider from './hooks/useCountriesSlider'
import COUNTRIES_DATA from './data.json'

export default function CountriesSlider({
  onClose,
  onSelect,
  initialSelectedCountries = [],
  allowMultiple,
}) {
  const slider = useCountriesSlider({
    allCountries: COUNTRIES,
    initialSelectedCountries,
    onClose,
    onSelect,
    allowMultiple,
  })

  const hasSelectedCountries = slider.selectedCountries?.length > 0

  return (
    <div className="flex flex-col items-stretch flex-1 h-full">
      <header className="flex flex-col items-stretch">
        <div className="px-4 py-4 mb-4 border-b border-gray-200">
          <div className="flex items-start justify-between space-x-3">
            <h2 className="text-lg font-medium leading-7 text-gray-900">
              Select Countries
            </h2>
            <div className="flex items-center h-7">
              <button
                aria-label="Close condition selector"
                className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
                onClick={onClose}
              >
                <Icons.Cross className="w-6 h-6 text-current" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative px-4 mb-4">
          <Form.Field
            control="input"
            name="products-search"
            controlProps={{
              value: slider.query,
              onChange: (e) => slider.setQuery(e.target.value),
              placeholder: 'Search country...',
              ref: slider.queryInput,
            }}
          />
          <span className="absolute flex items-center justify-center w-4 h-4 text-black -translate-y-1/2 bg-gray-300 rounded-full cursor-pointer top-1/2 right-6 hover:bg-gray-600 hover:text-white">
            <Icons.Cross className="w-3 mb-px" onClick={slider.clearQuery} />
          </span>
        </div>
      </header>
      <div className="flex flex-1 min-h-0">
        <div className="flex-1 overflow-y-auto">
          <ul className="flex flex-col items-stretch flex-1">
            {slider.countries.map((country) => (
              <CountryItem
                key={country.value}
                country={country}
                selectedCountries={slider.selectedCountries}
                toggleSelect={slider.toggleCountrySelection}
              />
            ))}
          </ul>
        </div>
      </div>
      {hasSelectedCountries && (
        <button
          className="w-full py-2 text-lg font-medium text-center text-white cursor-pointer bg-primary hover:bg-primary-dark"
          onClick={slider.handleSelectionComplete}
        >
          Done
        </button>
      )}
    </div>
  )
}

function CountryItem({ country, selectedCountries, toggleSelect }) {
  const countryObjectInSelectedCountries = selectedCountries.includes(
    country.value
  )

  return (
    <li
      className="flex flex-col items-stretch px-2 py-3 border-b border-gray-200 cursor-pointer hover:bg-gray-50"
      onClick={() => toggleSelect(country.value)}
    >
      <div className="flex flex-row items-start">
        <span className="mt-1 mr-4 text-xs font-semibold text-black">
          {country.label} ({country.value})
        </span>
        <span
          className={classNames(
            'flex-shrink-0 flex items-center mt-1 justify-center w-6 h-6 ml-auto text-2xl font-semibold border rounded-full border-primary',
            {
              'bg-primary text-white': !!countryObjectInSelectedCountries,
              'bg-white text-primary': !countryObjectInSelectedCountries,
            }
          )}
        >
          {countryObjectInSelectedCountries ? (
            <Icons.Tick className="w-3 text-white stroke-current" />
          ) : (
            '+'
          )}
        </span>
      </div>
    </li>
  )
}

export const COUNTRIES = COUNTRIES_DATA.map((c) => ({
  value: c['alpha-2'],
  label: c.name,
}))

export function countryLabelFromCode(code) {
  return _get(_find(COUNTRIES, { value: code }), 'label')
}
