import React from 'react'
import sub from 'date-fns/sub'

import { useApi, cacheKey } from 'hooks/api'
import useAppState from 'hooks/appState'
import { parseStateDate } from 'utils/date'
import { isFeatureEnabled } from 'utils/features'
import { AB_TESTING } from 'constants/features'
import { _find } from 'utils/lodash'

export default function useExperiences({ account }) {
  const {
    data: { name: shop },
  } = account
  const pages = useApi('pages/', { shop })
  const billing = useApi('account/', { shop })
  //   const analytics = useApi('analytics/stats/', { shop })
  const experiences = useApi('experiences/', { shop })

  const { state: appState, updateState: updateAppState } = useAppState()

  const startDate = parseStateDate(
    appState.statsStartDate,
    sub(new Date(), { days: 30 })
  )
  const endDate = parseStateDate(appState.statsEndDate, new Date())

  const featureEnabled = isFeatureEnabled(account.data, AB_TESTING)

  function updateWidgetData(widgetsData, experience) {
    // var allWidgets = _find(
    //   experiences.data.experiences,
    //   (exp) => exp.id === experience.id
    // ).widgets

    // allWidgets = allWidgets.map((widget) => {
    //   const updatedData = _find(widgetsData, (w) => w.id === widget.id)
    //   return updatedData ? { ...widget, ...updatedData } : widget
    // })
    // var newData = JSON.parse(JSON.stringify(experiences.data))
    // newData.experiences = newData.experiences.map((exp) => {
    //   if (exp.id == experience.id) {
    //     var updatedExperience = exp
    //     updatedExperience.widgets = allWidgets
    //     return updatedExperience
    //   }
    //   return exp
    // })
    // experiences.update(newData, {
    //   cacheKey: cacheKey('experiences/', { shop }),
    //   local: true,
    // })
    experiences.get()
  }

  function updateLocalExperiences(data, single = true) {
    var newData
    var updatedData = experiences.data
    if (single) {
      newData = experiences.data.experiences.map((exp) => {
        if (exp.id == data.experience.id) {
          return data.experience
        } else {
          return exp
        }
      })
      updatedData.experiences = newData
    } else {
      updatedData = data
    }

    experiences.update(updatedData, {
      cacheKey: cacheKey('experiences/', { shop }),
      local: true,
    })
  }

  function onDateRangeChange(date) {
    if (!date) {
      return
    }

    const [startDate, endDate] = date
    if (!startDate || !endDate) {
      return
    }

    updateAppState({ statsStartDate: date[0], statsEndDate: date[1] })
    // const params = {
    //   date__gte: format(startDate, DATE_FORMAT),
    //   date__lte: format(endDate, DATE_FORMAT),
    // }
  }
  return {
    onDateRangeChange,
    updateLocalExperiences,
    updateWidgetData,
    startDate,
    endDate,
    pages,
    billing,
    // analytics,
    experiences,
    featureEnabled,
  }
}
