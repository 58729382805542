import { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as Widgets from 'constants/widgets'

const ALL_WIDGETS = Object.keys(Widgets).map((k) => Widgets[k])

export default function useSlots({ initialSlots = [] }) {
  const [slots, setSlots] = useState(clearInvalidSlots(ensureIDs(initialSlots)))
  const [newSlotType, setNewSlotType] = useState(Widgets.TRENDING.value)
  const [editSlotId, markSlotForEdit] = useState(null)

  return {
    newSlotType,
    handleNewSlotTypeChange: (e) => setNewSlotType(e.target.value),

    editSlotId,
    markSlotForEdit,

    slots,
    addSlot: useCallback(
      (type) => {
        setSlots([
          ...slots,
          {
            id: uuidv4(),
            type,
            randomEnabled: true,
            automaticEnabled: true,
            extra: {},
          },
        ])
      },
      [slots]
    ),

    updateSlot: useCallback(
      (id, update) => {
        setSlots(
          slots.map((slot) => {
            if (slot.id === id) {
              return { ...slot, ...update }
            } else {
              return slot
            }
          })
        )
      },
      [slots]
    ),

    removeSlot: useCallback(
      (id) => {
        setSlots(slots.filter((s) => s.id !== id))
      },
      [slots]
    ),
  }
}

function clearInvalidSlots(slots) {
  return slots.filter((s) => s.type)
}

function ensureIDs(initialSlots = []) {
  return initialSlots.map((slot) => ({ ...slot, id: slot.id || uuidv4() }))
}
