import React from 'react'
import Box from 'components/Box'
import EmailEditor from 'components/EmailEditor'

export default function CampaignLayout({ account, campaign }) {
  return (
    <Box className="flex flex-col items-stretch">
      <EmailEditor
        account={account}
        campaign={campaign}
        onSave={campaign => {
          console.log('campaign saved!')
        }}
      />
    </Box>
  )
}
