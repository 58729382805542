import React, {
  useEffect,
  useMemo,
  useReducer,
  useState,
  useCallback,
} from 'react'

import { updateSmartCart as updateSmartCartApi } from 'api/smart_cart'
import useSmartCartMutations from 'hooks/queries/smartcart'
import { _pick, _isEqual } from 'utils/lodash'

export default function useMiniCart({ cart, accountData, products, store }) {
  const [state, dispatch] = useReducer(reducer, cart)
  const [formError, setFormError] = useState({})
  const {
    updateSmartCart: { mutate: updateSmartCart, status: updateSmartCartStatus },
  } = useSmartCartMutations({ shop: accountData.name })
  const groups = useMemo(
    () => [
      {
        label: 'General Settings',
        id: 'general',
        value: _pick(state?.settings, ['general']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
      },
      {
        label: 'Banner',
        id: 'banner',
        value: _pick(state?.settings, ['banner']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
      },
      {
        label: 'Offer Thresholds',
        id: 'thresholds',
        value: _pick(state?.settings, ['thresholds']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
        validate: (values) => {
          const {
            settings: { thresholds },
          } = values
          const {
            settings: { shipping },
          } = state
          let isValid = true
          let indexOfThresholdShipping = thresholds.findIndex(
            (threshold) => threshold.type == 'free_shipping'
          )
          const errors = {}
          if (shipping.enabled && indexOfThresholdShipping !== -1) {
            isValid = false
            errors[indexOfThresholdShipping] = {
              type: 'Shipping Bar and Shipping Offer Threshold cannot be enabled at the same time',
            }
          }
          for (let i = 1; i < thresholds.length; i++) {
            if (
              parseFloat(thresholds[i].amount.default) <
              parseFloat(thresholds[i - 1].amount.default)
            ) {
              errors[i] = {
                ...errors[i],
                amount: 'Amount must be higher than previous threshold',
              }
            }
          }
          setErrors(errors, { id: 'thresholds' })
          return isValid
        },
      },
      {
        label: 'Free Shipping',
        id: 'shipping',
        value: _pick(state?.settings, ['shipping']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
      },
      {
        label: 'Recommendations',
        id: 'recommendation',
        value: _pick(state?.settings, ['recommendation']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
      },
      {
        label: 'Pricing',
        id: 'pricing',
        value: _pick(state?.settings, ['pricing']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
      },
      {
        label: 'CTA/Checkout',
        id: 'actions',
        value: _pick(state?.settings, ['actions']),
        buildUpdate: (values) => ({
          settings: {
            ...values,
          },
        }),
        validate: (values) => {
          const {
            settings: { actions },
          } = values
          let isValid = true
          for (let i = 0; i < actions.length - 1; i++) {
            for (let j = i + 1; j < actions.length; j++) {
              if (actions[i].priority == actions[j].priority) {
                isValid = false
                setErrors(
                  {
                    [i]: { priority: 'Same priority detected' },
                    [j]: { priority: 'Same priority detected' },
                  },
                  { id: 'actions' }
                )
              }
            }
          }
          if (isValid) {
            setErrors({}, { id: 'actions' })
          }
          return isValid
        },
      },
    ],
    [state?.settings]
  )

  const [activeGroup, setActiveGroup] = useState(null)
  const toggleActiveGroup = useCallback(
    (groupId) => {
      setActiveGroup(activeGroup && activeGroup === groupId ? null : groupId)
    },
    [activeGroup]
  )
  const updateGroup = (update) => {
    dispatch({ type: 'UPDATE', update })
  }

  const setErrors = (errors, group) => {
    if (errors && Object.keys(errors).length > 0) {
      setFormError({ ...formError, [group.id]: errors })
    } else {
      const updatedErrors = { ...formError }
      delete updatedErrors[group.id]
      setFormError(updatedErrors)
    }
  }

  // useEffect(() => {
  // if (!_isEqual(state, cart)) {
  // console.log(state)
  // updateGroup(state)
  // }
  // }, [state])

  return {
    data: state,
    groups,
    activeGroup,
    toggleActiveGroup,
    updateGroup,
    setErrors,
    formError,
    lineItems: products.length > 0 ? [products[0]] : [],
    recommendedItem: products.length > 0 ? products[products.length - 1] : null,
    productSyncInProgress:
      !store.data.productsSyncedAt || !store.data.ordersSyncedAt,
    onSave: (data) => updateSmartCart({ id: cart.id, ...data }),
    isSaving: updateSmartCartStatus === 'loading',
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...{
          settings: {
            ...state.settings,
            ...action.update.settings,
          },
          translations: {
            ...state.translations,
            ...action.update.translations,
          },
        },
      }
    default:
      return state
  }
}
