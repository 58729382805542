import React, { useState, useEffect } from 'react'

import { fetchAccountConfig } from 'api/account'
import {
  eventsReceived,
  isStorefrontPasswordEnabled,
  storeScriptTagEnabled,
  appEmbedEnableLink,
} from 'utils/account'
import { getSourceAsDOM } from 'utils/misc'
import { APP_EMBED_ENABLE_HELP_LINK } from 'constants/support'
import { poll } from 'utils/misc'
import { proxyHost } from 'utils/account'
import * as QueryUtils from 'utils/query'

export default function useAppEmbedCard({
  account,
  checkStorefront,
  onEnabled,
}) {
  const accountData = account.data
  const storefrontPasswordEnabled = isStorefrontPasswordEnabled(accountData)
  const storefrontEventsReceived = eventsReceived(accountData)
  const isEmbedBlockActive = checkStorefront
    ? storefrontPasswordEnabled
      ? storefrontEventsReceived
      : false
    : storefrontEventsReceived
  const appEmbedLink = appEmbedEnableLink(accountData)

  const [state, setState] = useState(EMBED_BLOCK_STATES.LOADING)

  async function asyncCheckStorefront() {
    if (storefrontPasswordEnabled) {
      const { data: newData } = await fetchAccountConfig(accountData.name)
      const updatedEventsRecieved = eventsReceived(newData)
      if (updatedEventsRecieved) {
        setState(EMBED_BLOCK_STATES.ENABLED)
        return true
      } else {
        setState(EMBED_BLOCK_STATES.DISABLED)
        return false
      }
    }
    try {
      const embedActive = await checkShopStorefront(
        accountData,
        checkEventsRecieved
      )
      if (embedActive) {
        setState(EMBED_BLOCK_STATES.SHOP_STOREFRONT_ENABLED)
        return true
      } else {
        setState(EMBED_BLOCK_STATES.DISABLED)
        return false
      }
    } catch (err) {
      console.log(err?.statusText)

      // Request timed out
      if (err?.status === 0) {
      }
      return false
    }
  }

  async function handleConfigure() {
    const url = appEmbedLink || APP_EMBED_ENABLE_HELP_LINK
    const windowRef = window.open(url, '_blank')
    if (typeof windowRef.focus === 'function') {
      windowRef.focus()
    }
    try {
      await poll(
        asyncCheckStorefront,
        60,
        1.5,
        () => setState(EMBED_BLOCK_STATES.ENABLED),
        checkEventsRecieved
      )
      QueryUtils.refetch('account')
    } catch (err) {
      console.log(err)
    }
  }

  function checkEventsRecieved() {
    if (storefrontEventsReceived) {
      setState(EMBED_BLOCK_STATES.ENABLED_ON_START)
      return true
    } else {
      setState(EMBED_BLOCK_STATES.DISABLED)
      return false
    }
  }

  useEffect(() => {
    if (
      checkStorefront &&
      !storefrontPasswordEnabled &&
      !storeScriptTagEnabled(accountData)
    ) {
      setState(EMBED_BLOCK_STATES.LOADING)
      asyncCheckStorefront()
    } else if (isEmbedBlockActive) {
      setState(EMBED_BLOCK_STATES.ENABLED_ON_START)
    } else {
      setState(EMBED_BLOCK_STATES.DISABLED)
    }
  }, [isEmbedBlockActive])

  useEffect(() => {
    if (
      (state === EMBED_BLOCK_STATES.ENABLED_ON_START ||
        state === EMBED_BLOCK_STATES.ENABLED ||
        state === EMBED_BLOCK_STATES.SHOP_STOREFRONT_ENABLED) &&
      typeof onEnabled === 'function'
    ) {
      onEnabled()
    }
  }, [state])

  return { state, handleConfigure, appEmbedLink }
}

async function checkShopStorefront(accountData, onTimeout = () => {}) {
  const {
    shop: { domain },
  } = accountData
  const storefrontSourceDOM = await getSourceAsDOM(
    `https://${domain.split('.').join('---')}.${proxyHost(accountData)}`,
    onTimeout
  )
  var scriptEnabled = false
  storefrontSourceDOM.querySelectorAll('script').forEach((scriptTag) => {
    if (
      scriptTag.src.includes('glood-ai-recommendations.js') ||
      scriptTag.src.includes('personalized-recommendations-v1.js')
    ) {
      scriptEnabled = true
    }
  })
  return scriptEnabled
}

export const EMBED_BLOCK_STATES = {
  LOADING: 'loading',
  SHOP_STOREFRONT_ENABLED: 'shop-storefront-enabled',
  ENABLED_ON_START: 'enabled-on-start',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
}
