import React from 'react'
import { BarChart, AreaChart } from 'components/Chart'

export default function Charts({ data, isNativeCurrencyMode, currencyFormat }) {
  const formattedData = data.map((item) => {
    return {
      ...item,
      viewRate: parseFloat((item.viewRate * 100).toFixed(2)),
      clickRate: parseFloat((item.clickRate * 100).toFixed(2)),
    }
  })
  const charts = [
    {
      title: 'Attributed Revenue',
      bars: [
        {
          id: 'a',
          title: 'Revenue From Recommendations',
          key: isNativeCurrencyMode ? 'nativeRevenueByRk' : 'revenueByRk',
          color: '#9a3412',
        },
        {
          id: 'a',
          title: 'Revenue Without Recommendations',
          key: isNativeCurrencyMode ? 'nativeRevenue' : 'revenue',
          color: '#f97316',
        },
      ],
    },
    {
      title: 'Attributed Product Quantity Count',
      bars: [
        {
          id: 'a',
          title: 'Sold Using Recommendations',
          key: 'productsSoldCountByRk',
          color: '#9a3412',
        },
        {
          id: 'a',
          title: 'Sold Without Recommendations',
          key: 'productsSoldCount',
          color: '#f97316',
        },
      ],
    },
    {
      title: 'Conversion Performance',
      areas: [
        { id: 'a', title: 'Page Views', key: 'ppv', color: '#9a3412' },
        { id: 'a', title: 'Widget Served', key: 'served', color: '#f97316' },
        { id: 'a', title: 'Widget Views', key: 'viewed', color: '#2dd4bf' },
        { id: 'a', title: 'Widget Clicks', key: 'clicked', color: '#ecc94b' },
      ],
    },
    {
      title: 'Widget Performance',
      areas: [
        { id: 'a', title: 'View Rate', key: 'viewRate', color: '#9a3412' },
        {
          id: 'a',
          title: 'Click Through Rate',
          key: 'clickRate',
          color: '#f97316',
        },
      ],
      type: 'percent',
    },
  ]

  return (
    <div className="flex flex-row flex-wrap -mx-3">
      {charts.map((chart) => (
        <div className="w-full px-3 mb-6" key={chart.title}>
          {chart.bars ? (
            <BarChart
              key={chart.title}
              data={formattedData}
              chart={chart}
              currencyFormat={isNativeCurrencyMode ? currencyFormat : null}
            />
          ) : (
            <AreaChart key={chart.title} data={formattedData} chart={chart} />
          )}
        </div>
      ))}
    </div>
  )
}
