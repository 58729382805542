import React from 'react'
import classNames from 'classnames'

import Line from '../Line'

export default function PromotionBuilderPreviewText({ section }) {
  const {
    settings: { alignment, layout, ...lineProps },
  } = section

  return (
    <div
      className={classNames('flex flex-col w-full mx-auto px-8', {
        'items-center': alignment === 'center',
        'items-start': alignment === 'leading',
        'items-end': alignment === 'trailing',
        'max-w-[1024px]': layout === 'center',
      })}
    >
      <Line line={lineProps} />
    </div>
  )
}
