import React from 'react'
import { Waypoint } from 'react-waypoint'
import classNames from 'classnames'

import Icons from 'components/Icons'
import Loader from 'components/Loader'
import * as ShopifyUtils from 'utils/shopify'
import { queryClient } from 'utils/query'
import { renderCurrency } from 'utils/currency'
import { _find, _get } from 'utils/lodash'

import useProductsSlider from './hooks/useProductsSlider'
import Form from 'components/Form'

export default function ProductsSlider({
  onClose,
  onSelect,
  initialSelectedProducts = [],
  allowMultipleVariants,
  allowMultipleProducts,
  productsLimit,
  selectAllVariants = false,
}) {
  const slider = useProductsSlider({
    initialSelectedProducts,
    allowMultipleProducts,
    allowMultipleVariants,
    productsLimit,
    onClose,
    onSelect,
  })

  const {
    products,
    selectedProducts,
    query,
    setQuery,
    isFetching,
    clearQuery,
    toggleProductSelection,
    toggleVariantSelection,
    isLoadingMore,
    loadMore,
    handleSelectionComplete,
  } = slider

  const hasSelectedProducts = selectedProducts?.length > 0

  return (
    <div className="flex flex-col items-stretch flex-1 h-full">
      <header className="flex flex-col items-stretch">
        <div className="px-4 py-4 mb-4 border-b border-gray-200">
          <div className="flex items-start justify-between space-x-3">
            <h2 className="text-lg font-medium leading-7 text-gray-900">
              Select Products
            </h2>
            <div className="flex items-center h-7">
              <button
                aria-label="Close condition selector"
                className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
                onClick={onClose}
              >
                <Icons.Cross className="w-6 h-6 text-current" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative px-4 mb-4">
          <Form.Field
            control="input"
            name="products-search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search products..."
            ref={slider.queryInput}
          />

          {isFetching ? (
            <Loader className="w-3 h-3" />
          ) : (
            <span className="absolute flex items-center justify-center w-4 h-4 text-black bg-gray-300 rounded-full cursor-pointer right-6 hover:bg-gray-600 hover:text-white top-4">
              <Icons.Cross className="w-3 mb-px" onClick={clearQuery} />
            </span>
          )}
        </div>
      </header>
      <div className="flex flex-1 min-h-0">
        <div className="flex-1 overflow-y-auto">
          {products?.length > 0 ? (
            <ul className="flex flex-col items-stretch flex-1">
              {products?.map((product) => (
                <ProductItem
                  key={product.productId}
                  product={product}
                  selectedProducts={selectedProducts}
                  toggleSelect={toggleProductSelection}
                  toggleVariantSelect={toggleVariantSelection}
                  selectAllVariants={selectAllVariants}
                />
              ))}
            </ul>
          ) : (
            <div className="flex items-center justify-center px-4 py-6 font-medium text-gray-600">
              No products found
            </div>
          )}
          <Waypoint
            key={'infinite-scroll'}
            fireOnRapidScroll={true}
            bottomOffset="-20%"
            onEnter={loadMore}
          />
          {isLoadingMore && (
            <span className="flex items-center justify-center py-3 text-primary">
              <Loader />
            </span>
          )}
        </div>
      </div>
      {hasSelectedProducts && (
        <button
          className="w-full py-2 text-lg font-medium text-center text-white cursor-pointer bg-primary hover:bg-primary-dark"
          onClick={handleSelectionComplete}
        >
          Done
        </button>
      )}
    </div>
  )
}

function ProductItem({
  product,
  selectedProducts,
  toggleSelect,
  toggleVariantSelect,
  selectAllVariants,
}) {
  const { data: accountData } = queryClient.getQueryData(['account'])
  const { image, variants } = product
  const productObjectInSelectedProducts = _find(selectedProducts, {
    productId: product.productId,
  })

  return (
    <li
      className="flex flex-col items-stretch px-4 py-6 border-b border-gray-200 cursor-pointer hover:bg-gray-50"
      onClick={() => toggleSelect(product)}
    >
      <div className="flex flex-row items-start">
        {image ? (
          <img
            src={ShopifyUtils.optimisedImage({
              baseUrl: image,
              size: 160,
            })}
            alt={product.title}
            className="object-contain w-20 mr-4"
          />
        ) : (
          <div className="w-20 h-20 mr-4 bg-gray-100"></div>
        )}
        <div className="flex flex-col items-stretch flex-1">
          <div className="flex flex-row items-center pt-0 pb-4">
            <span className="mt-1 mr-4 text-sm font-semibold text-black">
              {product.title}
            </span>
            <span
              className={classNames(
                'flex-shrink-0 flex items-center mt-1 justify-center w-6 h-6 ml-auto text-2xl font-semibold border rounded-full border-primary',
                {
                  'bg-primary text-white': !!productObjectInSelectedProducts,
                  'bg-white text-primary': !productObjectInSelectedProducts,
                }
              )}
            >
              {productObjectInSelectedProducts ? (
                <Icons.Tick className="w-3 text-white stroke-current" />
              ) : (
                '+'
              )}
            </span>
          </div>
          <ul className="flex flex-col items-stretch">
            {variants.map((variant) => {
              const selectedVariants = _get(
                productObjectInSelectedProducts,
                'variantIds',
                []
              )
              const variantSelected =
                selectedVariants.includes(variant.id) ||
                selectedVariants.length == 0 ||
                selectAllVariants

              return (
                <li
                  key={variant.id}
                  className={classNames(
                    'flex flex-row py-2 border-t border-gray-100 pl-2',
                    {
                      'hover:bg-gray-200 cursor-pointer':
                        productObjectInSelectedProducts && !selectAllVariants,
                    }
                  )}
                  onClick={(e) => {
                    if (productObjectInSelectedProducts && !selectAllVariants) {
                      e.preventDefault()
                      e.stopPropagation()
                      toggleVariantSelect(variant.id, product.productId)
                    }
                  }}
                >
                  <span className="flex flex-row items-baseline text-sm">
                    <span className="font-medium">{variant.title}</span>
                    {accountData && (
                      <span
                        className="ml-3 text-xs"
                        dangerouslySetInnerHTML={{
                          __html: renderCurrency(
                            variant.price,
                            _get(accountData, 'currencyFormat')
                          ),
                        }}
                      ></span>
                    )}
                  </span>
                  {productObjectInSelectedProducts && (
                    <span
                      className={classNames(
                        'flex flex-shrink-0 items-center self-center justify-center w-4 h-4 ml-auto text-sm font-semibold border rounded-full border-primary',
                        {
                          'bg-primary text-white': variantSelected,
                          'bg-white text-primary': !variantSelected,
                        }
                      )}
                    >
                      {variantSelected ? (
                        <Icons.Tick className="w-2 text-white stroke-current" />
                      ) : (
                        '+'
                      )}
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </li>
  )
}
