import React from 'react'
import classNames from 'classnames'

import ManualRecommendations from 'components/SetupRecommendations/ProductRecommendations/ManualRecommendations'
import ProductRecommendations from 'components/SetupRecommendations/ProductRecommendations'

import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'

export default function Recommendations({ account, store }) {
  const [widgetType, setWidgetType] = React.useState(false)
  return widgetType ? (
    <div className="flex flex-col">
      <ProductRecommendations
        widgetType={widgetType.value}
        pageType={Pages.PRODUCT.value}
        account={account}
        store={store}
        onClose={() => setWidgetType(null)}
        title={widgetType.label}
      />
    </div>
  ) : (
    <div className="flex flex-row flex-wrap -mx-3">
      {WIDGET_TYPES.map((widgetType) => (
        <div
          className={classNames('flex flex-col items-stretch w-1/2 px-3 mb-5')}
          key={widgetType.value}
          onClick={() => setWidgetType(widgetType)}
        >
          <div className="relative flex flex-col h-full p-5 bg-white border border-gray-200 rounded cursor-default hover:border-gray-400">
            <span className="mb-2 text-xl font-semibold">
              {widgetType.label}
            </span>
            <span className="text-sm text-gray-600">
              {widgetType.description} <br />
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}

const WIDGET_TYPES = [
  Widgets.SIMILAR_PRODUCTS,
  Widgets.BOUGHT_TOGETHER,
  Widgets.CROSS_SELL,
  Widgets.TRENDING,
  Widgets.BESTSELLERS,
]
