import React, { useState } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import { createWorkflowNode, fetchWorkflowNodes } from 'api/funnel'
import Icons from 'components/Icons'
import SlideOver from 'components/SlideOver'
import WaitForData from 'components/WaitForData'
import { useToast } from 'components/Toast'
import * as QueryUtils from 'utils/query'

import Node from './Nodes/Node'
import AddNode from './Nodes/AddNode'
import useWorkflow from './hooks/useWorkflow'
import TaskOptions from './TaskOptions'
import { getTaskNode, getWorkflowObject, getWorkflowRoot } from './utils'
import { pageLabelFromType } from 'utils/constants'

const StartNode = ({ wb, readOnly }) => {
  const { root } = wb
  const [showAddNode, setShowAddNode] = useState(!root)
  return (
    <>
      <div className="bg-white rounded-[8px] shadow-card p-3 border-t-[8px] border-primary flex flex-col gap-2 max-w-[240px] mx-auto items-center">
        <h2 className="text-sm font-semibold">Trigger Funnel</h2>
        {/* <p className="text-sm text-gray-400">
          <strong className="text-black">Segment</strong> Lorem ipsum dolor
        </p> */}
      </div>
      <div className="flex flex-col items-center mx-auto">
        <div className="w-[5px] h-[5px] bg-black rounded-full" />
        <div className="w-[1px] h-[20px] bg-black" />
        {!root && (
          <div
            className="p-2 font-semibold text-white rounded-full cursor-pointer bg-primary"
            style={{ fontSize: '24px', lineHeight: '14px' }}
            onClick={() => setShowAddNode(true)}
          >
            +
          </div>
        )}
        {showAddNode && !readOnly && (
          <div className="absolute z-10" style={{ top: 'calc(20% + 40px)' }}>
            <AddNode
              onClose={() => setShowAddNode(false)}
              root={true}
              wb={wb}
            />
          </div>
        )}
        <div className="w-[1px] h-[20px] bg-black" />
        <div className=" text-black -mt-[0.4rem]">
          <Icons.ChevronDown width="15px" />
        </div>
      </div>
    </>
  )
}

export default function Workflow({
  builder = {},
  funnel,
  rb = {},
  readOnly,
  accountData,
}) {
  funnel = funnel || builder.funnel
  const { name: shop } = accountData
  const workflowNodesQuery = useQuery(
    ['workflow-nodes', { shop, funnel }],
    fetchWorkflowNodes
  )

  return (
    <WaitForData objects={[workflowNodesQuery]}>
      {() => {
        return (
          <WorkflowBuilderView
            builder={builder}
            funnel={funnel}
            rb={rb}
            readOnly={readOnly}
            accountData={accountData}
            initialWorkflow={QueryUtils.data(workflowNodesQuery).workflow}
            initialNodes={QueryUtils.data(workflowNodesQuery).nodes}
          />
        )
      }}
    </WaitForData>
  )
}

function WorkflowBuilderView({
  builder = {},
  funnel,
  rb = {},
  readOnly,
  initialWorkflow,
  initialNodes,
}) {
  const openToast = useToast()
  const { saveFunnel, setSaveHandler, setSaveNextHandler, setActiveScreen } =
    builder

  const { setCloseHandler } = rb

  const wb = useWorkflow({
    initialWorkflow,
    initialNodes,
    builder,
  })

  const {
    workflowSpace,
    root,
    setRoot,
    taskOptionsVisible,
    closeTaskOptions,
    saveNodeOneByOne,
  } = wb

  React.useEffect(() => {
    if (setSaveHandler && setCloseHandler) {
      async function workflowSave() {
        const wobject = await saveNodeOneByOne()
        const payload = {
          workflow: {
            nodes: wobject.tasks.map((task) => {
              return task.id
            }),
            edges: wobject.edges,
            startNode: wobject.startNode,
          },
        }
        return await saveFunnel(payload)
      }

      async function confirmClose() {
        const confirmation = window.confirm(
          'Any unsaved changes will be lost! Are you sure you want to close the window ?'
        )
        if (confirmation) {
          setRoot(null)
          //TODO(Azhar): Garbage Collection - Delete all node and edge objects
        }
        return confirmation
      }

      setSaveHandler(() => workflowSave)
      setSaveNextHandler(() => async () => {
        const savedWorkflow = await workflowSave()
        if (savedWorkflow) {
          setActiveScreen('review')
        }
      })
      setCloseHandler(() => confirmClose)
    }
  }, [root])

  return (
    <>
      <div
        className={classNames(' flex overflow-hidden bg-gray-200 min-w-[75%]', {
          'min-h-[100vh]': !readOnly,
        })}
      >
        <div
          className={classNames(
            'pt-8 pb-[240px] px-[240px] flex-1 overflow-auto',
            {
              'min-h-[100vh]': !readOnly,
            }
          )}
          ref={workflowSpace}
        >
          <StartNode builder={builder} wb={wb} readOnly={readOnly} />
          <Node
            readOnly={readOnly}
            workflow={funnel.workflow}
            node={root}
            wb={wb}
          />
        </div>
        {!readOnly && (
          <SlideOver
            open={taskOptionsVisible}
            name={'WorkflowTaskOptions'}
            onClose={closeTaskOptions}
            relative={true}
          >
            <TaskOptions wb={wb} builder={builder} />
          </SlideOver>
        )}
      </div>
    </>
  )
}
