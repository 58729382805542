import React from 'react'

export default function Shipping({ layout }) {
  const {
    data: {
      settings: {
        shipping: {
          shippingText: { default: shippingText },
          thresholdBypassText: { default: thresholdBypassText },
          enabled,
          amount,
        },
        general: { primaryColor, secondaryColor, tertiaryColor },
      },
    },
    lineItems,
  } = layout

  if (!enabled) {
    return null
  }

  const lineItemsTotal = lineItems.reduce((accm, lineItem) => {
    return parseFloat(lineItem.price) + accm
  }, 0.0)

  const shippingThresholdPercentage =
    (lineItemsTotal / parseFloat(amount.default)) * 100

  const progressBarWidth =
    shippingThresholdPercentage > 100
      ? '100%'
      : `${shippingThresholdPercentage.toFixed(0)}%`

  if (shippingThresholdPercentage > 100) {
    return (
      <div className="flex flex-row items-center justify-center py-4 px-7">
        <span
          style={{
            color: secondaryColor,
          }}
          className="text-base font-semibold"
        >
          {thresholdBypassText}
        </span>
      </div>
    )
  }
  return (
    <div className="py-4 px-7">
      <div className="flex justify-between mb-1">
        <span
          className="text-xs font-medium"
          style={{
            color: primaryColor,
          }}
        >
          {shippingText}
        </span>
        <span
          className="text-xs font-medium "
          style={{
            color: primaryColor,
          }}
        >
          {progressBarWidth}
        </span>
      </div>
      <div
        className="w-full  rounded-full h-2.5"
        style={{ background: tertiaryColor }}
      >
        <div
          className=" h-2.5 rounded-full transition-[width] duration-300"
          style={{ width: progressBarWidth, background: primaryColor }}
        ></div>
      </div>
    </div>
  )
}
