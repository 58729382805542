import React from 'react'
import classNames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'

import Portal from 'components/Portal'

import styles from './styles.module.css'

export default function Tooltip({
  content,
  position,
  isInteractable,
  showHelpIcon,
  helpIconVariant,
  children,
  triggerClassName,
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [startOperation, cancelOperation] = useOperation(
    isInteractable ? 200 : 0
  )

  let triggerClassNames = classNames(styles.inline, triggerClassName)
  triggerClassNames = showHelpIcon
    ? classNames(
        'w-5 h-5 flex flex-row items-center justify-center border cursor-pointer text-xs font-semibold rounded-full',
        HELP_ICON_VARIANT_TO_CLASS_MAP[helpIconVariant]
      )
    : triggerClassNames

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span
            className={triggerClassNames}
            ref={ref}
            onMouseEnter={() => {
              startOperation(() => setIsOpen(true))
            }}
            onMouseLeave={() => {
              startOperation(() => setIsOpen(false))
            }}
          >
            {showHelpIcon ? '?' : children}
          </span>
        )}
      </Reference>
      {isOpen && content && (
        <Portal>
          <Popper placement={position} modifiers={POPPER_MODIFIERS}>
            {({ ref, style, placement, arrowProps }) => (
              <div
                ref={ref}
                className={styles.popper}
                style={style}
                data-placement={placement}
                onMouseEnter={cancelOperation}
                onMouseLeave={() => {
                  setIsOpen(false)
                  cancelOperation()
                }}
              >
                {content}
                <div
                  ref={arrowProps.ref}
                  className={styles.arrow}
                  style={arrowProps.style}
                />
              </div>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  )
}

Tooltip.defaultProps = {
  position: 'top',
  isInteractable: false,
  helpIconVariant: 'light',
}

const POPPER_MODIFIERS = {
  preventOverflow: { boundariesElement: 'viewport' },
}

const HELP_ICON_VARIANT_TO_CLASS_MAP = {
  light: 'border-gray-400 text-gray-400 hover:text-white hover:border-white',
  dark: 'border-gray-800 text-gray-800 opacity-50 hover:opacity-100',
}

function useOperation(delay) {
  const timerRef = React.useRef(null)

  function cancelOperation() {
    if (!timerRef.current) {
      return
    }

    clearTimeout(timerRef.current)
    timerRef.current = null
  }

  function startOperation(fn) {
    cancelOperation()
    timerRef.current = setTimeout(fn, delay)
  }

  return [startOperation, cancelOperation]
}
