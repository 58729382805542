import React from 'react'
import classNames from 'classnames'

import Badge from 'components/Badge'
import Button from 'components/Button'
import Callout from 'components/Callout'
import Loader from 'components/Loader'
import Tooltip from 'components/Tooltip'
import { useOpenModal } from 'components/Modal'
import * as Tracker from 'utils/tracker'
import { openRequestDemo } from 'utils/external'
import { _get } from 'utils/lodash'

import AccountSwitcher from './AccountSwitcher'
import styles from './styles.module.css'

export default function Header({ account, store, shops }) {
  const { name: shopName, shop, billingCycle } = account
  const openModal = useOpenModal()
  const isRkSupport = _get(account, 'isRkSupport', false)
  let syncStatusBadge
  let useSyncStatusBadge
  if (
    store &&
    store.productsSyncedAt &&
    store.ordersSyncedAt &&
    store.trendingGeneratedAt &&
    store.bestsellerGeneratedAt
  ) {
    syncStatusBadge = (
      <Tooltip
        position="right"
        content="Product changes are synced in real time. In case you want to do a manual sync of all product, please go to Account >> Settings."
      >
        <Badge color="green" spacing="regular">
          <span className="font-normal">All Products synced</span>
        </Badge>
      </Tooltip>
    )
  } else if (store) {
    let syncProgressMessage = ''
    if (!store.productsSyncedAt || !store.ordersSyncedAt) {
      syncProgressMessage = 'Product sync in progress'
    } else if (!store.trendingGeneratedAt || !store.bestsellerGeneratedAt) {
      syncProgressMessage = 'Generating recommendations for your store'
    }

    useSyncStatusBadge = true
    syncStatusBadge = (
      <Badge color="yellow" spacing="regular">
        <span className="mr-2">{syncProgressMessage}</span>
        <Loader.Circle color="gray" />
      </Badge>
    )
  }

  const recommendationServedLimit =
    account.plan && account.plan.isExtendedUsageEnabled
      ? account.billingCycle.recommendationsServedHardLimit
      : account.billingCycle.maximumRecommendationsServed

  let accountQuotaExpiredBadge
  if (
    account &&
    account.plan &&
    account.billingCycle &&
    account.billingCycle.currentUsage >= recommendationServedLimit
  ) {
    accountQuotaExpiredBadge = (
      <Tooltip
        position="right"
        content="Your widget serve quota for current plan has finished. Widgets have stopped displaying on your store. Please upgrade your current plan to re-enable widgets."
      >
        <Callout type="error" noHoverEffect={true}>
          <span className="mr-2">Widgets have been disabled!</span>
          <Button
            color="red"
            size="xSmall"
            href={`/plans/`}
            internalLink={true}
          >
            Upgrade to re-enable
          </Button>
        </Callout>
      </Tooltip>
    )
  }

  let startTrialBadge
  if (account.plan && account.plan.name === 'Free' && account.preferredPlan) {
    startTrialBadge = (
      <Tooltip
        position="right"
        content={`Widgets will stop displaying on your store once you exhaust your free quota of 1000 widget serves. Upgrade now to a paid plan to avoid service cut. You also get a free trial of ${account.preferredPlan.trialDays} days.`}
      >
        <Callout noHoverEffect={true}>
          <span className="mr-2">Prevent Service Cut!</span>
          <Button
            size="xSmall"
            onClick={async () => {
              Tracker.record('header:start_trial:click')
              await Tracker.flush()

              window.top.location.href = `${account.preferredPlan.chargeUrl}&shop=${shopName}`
            }}
          >
            Start Free Trial
          </Button>
        </Callout>
      </Tooltip>
    )
  }

  return (
    <div
      className={classNames(
        styles.container,
        'px-5 shadow-sm flex flex-row items-center'
      )}
    >
      {useSyncStatusBadge
        ? syncStatusBadge
        : accountQuotaExpiredBadge || startTrialBadge || syncStatusBadge}
      {Object.keys(billingCycle).length === 0 && (
        <Badge color="yellow" spacing="regular" className="ml-2">
          <span className="uppercase">App Uninstalled</span>
        </Badge>
      )}
      <Button
        variant="light"
        size="small"
        className="ml-4"
        onClick={() => {
          openModal(openRequestDemo({ accountData: account.data }))
        }}
      >
        Book a Demo
      </Button>
      <AccountSwitcher
        activeShop={shop}
        shops={shops}
        isRkSupport={isRkSupport}
      />
    </div>
  )
}
