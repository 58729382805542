import { useQuery } from 'react-query'

import { fetchVisitor as fetchVisitorApi } from 'api/visitors'
import { _get, _find } from 'utils/lodash'
import * as QueryUtils from 'utils/query'
import { useQueryParams } from 'hooks/router'

export default function useVisitor({ visitorId }) {
  const { shop } = useQueryParams()
  const visitorRecordFromCache = getVisitorRecordFromCache({
    visitorId: Number(visitorId),
  })

  const queryKey = ['visitor', shop, visitorId]
  const visitorQuery = useQuery(queryKey, fetchVisitorApi)

  return {
    data: QueryUtils.data(visitorQuery) || visitorRecordFromCache,
    fetching: visitorQuery.status === 'loading' && !visitorRecordFromCache,
  }
}

function getVisitorRecordFromCache({ visitorId }) {
  const cachedData = QueryUtils.queryClient.getQueriesData('visitors')[0]
  if (cachedData) {
    const [queryKey, data] = cachedData
    const cachedRecordsForObjectType = _get(data, 'data.data')
    return _find(cachedRecordsForObjectType, { id: visitorId })
  }
  return undefined
}
