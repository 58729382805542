import React from 'react'

import { Input } from 'components/EditorForm'

export default function PromotionBuilderProductUpsellMetafields({
  value,
  handleUpdate,
}) {
  const { product_key, variant_key } = value

  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-col items-stretch mb-4">
        <Input
          value={product_key}
          onChange={(v) => handleUpdate({ product_key: v, variant_key })}
          controlProps={{
            label: 'Product Key',
            placeholder: 'Enter product key...',
          }}
        />
        {false && (
          <span className="mt-1 text-gray-600 text-xxs">Help text here</span>
        )}
      </div>
      <div className="flex flex-col">
        <Input
          value={variant_key}
          onChange={(v) => handleUpdate({ variant_key: v, product_key })}
          controlProps={{
            label: 'Variant Key',
            placeholder: 'Enter variant key...',
          }}
        />
        {false && (
          <span className="mt-1 text-gray-600 text-xxs">Help text here</span>
        )}
      </div>
    </div>
  )
}
