import React from 'react'
import * as Widgets from 'constants/widgets'
import ProductRecommendations from './ProductRecommendations'
import ObjectRecommendations from './ObjectRecommendations'
import CollectionRecommendations from './CollectionRecommendations'
import SlotRecommendations from './SlotRecommendations'

export default function SetupRecommendations(props) {
  if (props.widgetType === Widgets.SLOTS_WIDGET.value) {
    return <SlotRecommendations {...props} />
  } else if (props.widgetType === Widgets.PROMOTED_PRODUCTS.value) {
    return <ObjectRecommendations {...props} />
  } else if (props.widgetType === Widgets.COLLECTIONS_WIDGET.value) {
    return <CollectionRecommendations {...props} />
  } else {
    return <ProductRecommendations {...props} />
  }
}
