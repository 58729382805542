import React from 'react'

import { useOpenModal } from 'components/Modal'
import ResourceList from 'components/ResourceList'

import PopupBuilder from '../PopupBuilder'
import TableCell from './Cell'
export default function PopupList() {
  const openModal = useOpenModal()
  return (
    <ResourceList
      resourceType="popup"
      schema={POPUP_SCHEMA}
      initialView={{
        fields: [
          'details',
          'triggers',
          'status',
          'revenueGenerated',
          'conversion',
          'views',
        ],
        filters: {},
        order: [],
      }}
      CustomTableCell={TableCell}
      options={{
        filters: { skipFilters: true },
        onRowClick: (popup) => {
          openModal(<PopupBuilder initialPopup={popup} />, {
            hideCloseButton: true,
            fullScreen: true,
            className: 'bg-white h-screen',
          })
        },
      }}
    />
  )
}

const POPUP_SCHEMA = {
  sections: [],
  schema: {
    details: {
      title: 'Popup Details',
      type: 'details',
      allowFilter: true,
    },
    triggers: {
      title: 'Triggers',
      type: 'triggers',
      allowFilter: true,
    },
    status: {
      title: 'Status',
      type: 'status',
      allowFilter: true,
    },
    revenueGenerated: {
      title: 'Revenue Generated',
      type: 'currency',
      accessor: 'stats.revenue',
      allowFilter: true,
      minWidth: '150px',
    },
    conversion: {
      title: 'Conversion',
      type: 'percentage',
      accessor: 'stats.conversion',
      allowFilter: true,
      minWidth: '150px',
    },
    views: {
      title: 'Views',
      type: 'number',
      accessor: 'stats.views',
      allowFilter: true,
      minWidth: '150px',
    },
  },
}
