import React from 'react'

export default function CartItems({ itemsAdded }) {
  if (!itemsAdded) {
    return null
  }
  return (
    <div className="flex flex-col w-full mb-7">
      <h1 className="pb-3 text-lg">Cart</h1>
      <div className="flex flex-col">
        {itemsAdded.map((item) => (
          <div
            className="flex justify-between w-full p-4 border border-gray-300 text-xxs"
            key={item.productId}
          >
            <span>1 x {item.title}</span>
            <span className="pl-3 cursor-pointer text-denim">Remove</span>
          </div>
        ))}
      </div>
    </div>
  )
}
