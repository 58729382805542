export const DISCOUNT_TYPES = {
  FLAT: {
    label: 'Flat OFF',
    value: 'AMOUNT',
  },
  PERCENTAGE: {
    label: 'Percent OFF',
    value: 'PERCENTAGE',
  },
}
