import React from 'react'

import EditorForm from 'components/EditorForm'

export default function PromotionBuilderLayoutUpsellTranslations({
  value,
  handleUpdate,
}) {
  return (
    <EditorForm layout={LAYOUT} value={value} handleUpdate={handleUpdate} />
  )
}

const LAYOUT = [
  [
    {
      key: 'buyButtonText',
      label: 'Buy button text',
      controlType: 'input',
      helpText: 'Use {{total}} to embed total price',
    },
  ],
  [
    {
      key: 'declineButtonText',
      label: 'Skip offer button text',
      controlType: 'input',
    },
  ],
  [
    {
      key: 'freeText',
      label: 'Free text',
      controlType: 'input',
      helpText:
        'Display this text instead of 0.00 price when giving 100% discount',
    },
  ],
]
