import { useState, useMemo, useReducer, useCallback, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import updateObject from 'immutability-helper'

import { _isEqual, _omit, _uniqBy } from 'utils/lodash'

export default function useBannerSection({ section, handleUpdate }) {
  const [state, dispatch] = useReducer(reducer, section)
  const groups = useMemo(
    () => [
      {
        label: 'General Settings',
        id: 'general_settings',
        settings: _omit(state.settings, 'lines'),
      },
      ...(state.settings?.lines || []).map((line, index) => ({
        label: `Line ${index + 1}`,
        id: `line_${line.id}`,
        type: 'line',
        line,
      })),
    ],
    [state.settings]
  )

  const [activeGroup, setActiveGroup] = useState(null)
  const toggleActiveGroup = useCallback(
    (groupId) => {
      setActiveGroup(activeGroup && activeGroup === groupId ? null : groupId)
    },
    [activeGroup]
  )

  useEffect(() => {
    if (!_isEqual(state, section)) {
      handleUpdate(state)
    }
  }, [state, handleUpdate, section])

  return useMemo(
    () => ({
      data: state,
      groups,
      activeGroup,
      toggleActiveGroup,

      handleSettingsUpdate: (update) => {
        dispatch({ type: 'UPDATE', update: { settings: { $merge: update } } })
      },

      handleLineAdd: () => {
        const newLineId = uuid()
        dispatch({ type: 'ADD_LINE', line: { id: newLineId } })

        // Allow ADD_LINE to update state
        setTimeout(() => {
          setActiveGroup(`line_${newLineId}`)
        }, 100)
      },

      handleLineDelete: (lineId) => {
        dispatch({ type: 'DELETE_LINE', lineId })
      },

      handleLineUpdate: ({ id, update: lineUpdate }) => {
        dispatch({
          type: 'UPDATE',
          update: {
            settings: {
              lines: (lines) =>
                lines.map((l) => (l.id === id ? { ...l, ...lineUpdate } : l)),
            },
          },
        })
      },
    }),
    [state, groups, dispatch, activeGroup, toggleActiveGroup]
  )
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return updateObject(state, action.update)

    case 'ADD_LINE':
      return {
        ...state,
        settings: {
          ...state.settings,
          lines: _uniqBy([...(state.settings?.lines || []), action.line], 'id'),
        },
      }

    case 'DELETE_LINE':
      return {
        ...state,
        settings: {
          ...state.settings,
          lines: (state.settings?.lines || []).filter(
            (l) => l.id !== action.lineId
          ),
        },
      }

    default:
      return state
  }
}
