import * as ReactDOM from 'react-dom'

export default function Portal({ children }) {
  const element = document.getElementById('portal')

  if (!element) {
    return null
  }

  return ReactDOM.createPortal(children, element)
}
