import { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import { _get, _find } from 'utils/lodash'
import ResourceListCell from 'components/ResourceList/Table/Cell'

export default function useListView({
  data,
  resourceType,
  resourceSchema,
  initialView,
  CustomTableCell,
}) {
  const { schema } = resourceSchema
  const [visibleFields, setVisibleFields] = useState(
    initialView.fields.filter((f) => schema[f])
  )

  const table = useTable({
    data: useMemo(() => _get(data, 'data', []), [data]),
    columns: useMemo(
      () =>
        visibleFields.map((f) => ({
          Header: schema[f].title,
          id: schema[f].id || f,
          accessor: schema[f].accessor || f,
          Cell: CustomTableCell || ResourceListCell,
          minWidth: schema[f].minWidth,
          maxWidth: schema[f].maxWidth,
          width: schema[f].width,
        })),
      [visibleFields]
    ),
    initialState: {
      resourceSchema,
    },
  })

  return {
    table,
    fields: visibleFields,
  }
}
