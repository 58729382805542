import { QueryClient } from 'react-query'
import { _get } from 'utils/lodash'

export const queryClient = new QueryClient({
  defaultConfig: {
    queries: {
      staleTime: Infinity,
      retry: 0,
    },
  },
})

if (process.env.NODE_ENV === 'development') {
  window._queryClient = queryClient
}

export function data(query) {
  return _get(query, 'data.data')
}

export function pages(query) {
  return _get(query, 'data.pages', [])
}

export function update(query, updater, config) {
  if (typeof updater === 'function') {
    const newUpdater = (old) => ({
      ...old,
      data: updater(old.data),
    })
    return queryClient.setQueryData(query, newUpdater, config)
  } else {
    return queryClient.setQueryData(query, updater, config)
  }
}

export function refetch(query, config = { exact: true }) {
  return queryClient.refetchQueries(query, config)
}

export function fetch(...args) {
  return queryClient.fetchQuery(...args)
}

export function remove(...args) {
  return queryClient.removeQueries(...args)
}

export function cachedData(queryKey) {
  return queryClient.getQueryData(queryKey)
}

export function invalidate(...args) {
  return queryClient.invalidateQueries(...args)
}
