import React from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import Text from 'components/Text'
import DateRangePicker from 'components/DateRangePicker'
import WidgetMultiSelect from 'components/MultiSelects/Widgets'
import PageMultiSelect from 'components/MultiSelects/Pages'
import WaitForData from 'components/WaitForData'
import { getCurrencyFormat, getStoreCurrency } from 'utils/account'

import AnalyticsCards from './AnalyticsCards'
import Charts from './Charts'
import useAnalytics from './hooks/useAnalytics'

export default function Analytics({ account, store }) {
  const {
    stats,
    charts,
    allWidgets,
    allPages,
    onDateRangeChange,
    state: statsState,
    setSelectedWidgetIds,
    setSelectedPages,
  } = useAnalytics({ account })
  const storeCurrency = getStoreCurrency(account.data)
  const isNativeCurrencyMode = true
  const currencyFormat = getCurrencyFormat(account, store)
  const currencySymbol = getSymbolFromCurrency(
    isNativeCurrencyMode ? storeCurrency : 'USD'
  )
  return (
    <WaitForData
      objects={[stats, charts]}
      loadingMessage="Loading analytics data"
      errorMessage="Trouble fetching analytics data..."
    >
      {() => {
        return (
          <div className="flex flex-col items-stretch flex-1">
            <Text.ScreenHeading>Analytics</Text.ScreenHeading>
            <div className="flex flex-row justify-end mb-4">
              <div className="flex flex-row items-stretch justify-end ">
                <PageMultiSelect
                  className="mr-3"
                  allPages={allPages}
                  selectedPages={statsState.selectedPages}
                  setSelectedPages={setSelectedPages}
                />
                <WidgetMultiSelect
                  className="mr-3"
                  allWidgets={allWidgets}
                  selectedWidgetIds={statsState.selectedWidgetIds}
                  setSelectedWidgetIds={setSelectedWidgetIds}
                />
                <DateRangePicker
                  startDate={statsState.startDate}
                  endDate={statsState.endDate}
                  onRangeChange={onDateRangeChange}
                  className="h-full"
                />
              </div>
            </div>
            <AnalyticsCards
              data={stats.data}
              isNativeCurrencyMode={isNativeCurrencyMode}
              currencySymbol={currencySymbol}
              storeCurrency={storeCurrency}
            />
            <Charts
              data={charts.data}
              isNativeCurrencyMode={isNativeCurrencyMode}
              currencyFormat={currencyFormat}
              storeCurrency={storeCurrency}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}
