import React from 'react'
import ConditionNode from './ConditionNode'
import PreCheckoutNode from './PreCheckoutNode'
import PostCheckoutNode from './PostCheckoutNode'

export default function NodeComponent({ node, readOnly, wb }) {
  return (
    !!node && (
      <>
        {node.task.type === 'pre_checkout' && (
          <PreCheckoutNode readOnly={readOnly} node={node} wb={wb} />
        )}
        {node.task.type === 'post_checkout' && (
          <PostCheckoutNode readOnly={readOnly} node={node} wb={wb} />
        )}
        {node.task.group === 'condition' && (
          <ConditionNode readOnly={readOnly} node={node} wb={wb} />
        )}
      </>
    )
  )
}
