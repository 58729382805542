import React from 'react'
import _get from 'lodash.get'
import { useHistory } from 'react-router-dom'
import Button from 'components/Button'
import Callout from 'components/Callout'
import Box from 'components/Box'
import Form from 'components/Form'
import * as Widgets from 'constants/widgets'
import * as Layouts from 'constants/layouts'
import { useForm } from 'react-hook-form'
import WidgetPlacement from 'components/WidgetPlacement'
import WidgetEditor from 'components/WidgetEditor'
import { PlacementCode } from 'containers/Widget/Settings'
import TEMPLATE_TYPES from 'constants/templates'
import { WIDGET_POSITIONING_HELP_LINK } from 'constants/support'
import * as Tracker from 'utils/tracker'
import { isTemplateEnabled, canUpdateTemplate } from 'utils/features'

export default function WidgetDesign({
  account,
  store,
  templates,
  widget,
  onSave,
}) {
  const history = useHistory()
  const templatesEnabled = isTemplateEnabled(account.data)

  async function onUpgrade() {
    Tracker.record('custom_template:start_trial')
    await Tracker.flush()

    history.push({
      pathname: '/plans',
      search: `?shop=${_get(account.data, 'name')}&plan=pro`,
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-start justify-between p-0">
        {templatesEnabled && (
          <div className="flex-1 mr-6">
            <WidgetEditor
              widget={widget}
              account={account.data}
              store={store.data}
              onSave={(payload) => {
                onSave(payload, 'Widget style update published successfully!')
              }}
            />
          </div>
        )}
        <div className="flex-1">
          <Box>
            <div className="flex flex-col items-start">
              <span className="mb-4 text-sm font-semibold text-gray-800 uppercase">
                Widget Position
              </span>
              <Button href={WIDGET_POSITIONING_HELP_LINK} target="_blank">
                Update Widget Position
              </Button>
            </div>
          </Box>
        </div>
      </div>
      <Positioning widget={widget} onSave={onSave} />
      <Template
        widget={widget}
        onSave={onSave}
        account={account}
        templates={templates}
        onUpgrade={onUpgrade}
      />
      <PlacementCode widget={widget} />
      {!templatesEnabled && <Layout widget={widget} onSave={onSave} />}
    </div>
  )
}

function Positioning({ widget, onSave }) {
  const { register, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      location: widget.location,
      position: widget.position,
    },
  })

  React.useEffect(() => {
    setValue('location', widget.location)
    setValue('position', widget.position)
  }, [widget.position, widget.location])

  return (
    <Box className="mb-5">
      <div className="mb-5 text-sm font-semibold text-gray-800 uppercase">
        Widget Location
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit((data) => {
          onSave({
            ...data,
            extra: {
              autoPositionOn: false,
              autoPositionLastUpdatedAt: null,
            },
          })
        })}
      >
        <Form.Field
          label="Div Id, Class selector or xPath of the div where you want to place the widget"
          name="location"
          control="input"
          ref={register({ required: 'Location cannot be empty' })}
          formError={errors.location}
          helpText="By default widget is placed in #shopify-section-product-template. For advanced usage you can enter complete XPATH or a valid class selector of the location where you want to insert this widget."
        />
        <Form.Field
          label="Position of the widget on the Storefront"
          name="position"
          control="input"
          type="number"
          ref={register({
            required: 'Please enter a valid number as position',
          })}
          formError={errors.position}
          helpText="This is the position of the widget on the storefront. 1 means that it will be the first section within the location configured on the left."
        />
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}

function Template({ widget, onSave, onUpgrade, account, templates }) {
  const updateAllowed = canUpdateTemplate(account.data)
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      template: widget.template,
    },
  })

  return (
    <Box className="mb-5">
      <div className="mb-5 text-sm font-semibold text-gray-800 uppercase">
        Widget Template
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit((data) => onSave(data))}
      >
        <Form.Field
          label="Widget Template"
          name="template"
          disabled={!updateAllowed}
          control="select"
          options={_get(templates, 'data.templates', [])
            .filter((temp) => temp.type === TEMPLATE_TYPES.WIDGET.value)
            .map((t) => ({
              label: t.name,
              value: t.id,
            }))}
          ref={register()}
          helpText="Choose the template used for this widget."
        />
        {updateAllowed ? (
          <div className="flex flex-row justify-end">
            <Button
              size="small"
              variant="bordered"
              className="mr-3"
              href={`/templates/${widget.template}`}
              internalLink={true}
            >
              Configure Widget Template
            </Button>
            <Button size="small">Save</Button>
          </div>
        ) : (
          <div className="flex flex-row justify-end">
            <Callout className="mt-1" textSize="small" noHoverEffect={true}>
              Upgrade to <span className="font-bold">&nbsp;Pro&nbsp;</span> plan
              to customise widget template to exactly match recommendation
              sections in your store theme.
              <Button
                size="xxSmall"
                className="ml-1"
                onClick={onUpgrade}
                type="button"
              >
                Start Free Trial
              </Button>
            </Callout>
          </div>
        )}
      </form>
    </Box>
  )
}

function Layout({ widget, onSave }) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      layout: widget.layout,
      carouselControl: widget.carouselControl,
      desktopPerView: widget.desktopPerView,
      mobilePerView: widget.mobilePerView,
      enableCartButton: widget.enableCartButton,
      cartButtonText: widget.cartButtonText,
    },
  })

  const watchLayout = watch('layout')
  const watchEnableCartButton = watch('enableCartButton')

  return (
    <Box className="my-5">
      <div className="mb-5 text-sm font-semibold text-gray-800 uppercase">
        Layout
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit((data) => onSave(data))}
      >
        <Form.Field
          label="Enable Add to Cart Button under every product"
          name="enableCartButton"
          control="checkbox"
          ref={register()}
          helpText='Check this box to show "Add To Cart" button under every product in the widget.'
        />
        {(watchEnableCartButton ||
          watchLayout === Layouts.AMAZON_BOUGHT_TOGETHER.value) && (
          <Form.Field
            label="Add to Cart Button Text"
            name="cartButtonText"
            control="input"
            formError={errors.cartButtonText}
            ref={register({ required: 'This text cannot be empty' })}
            helpText="You can add custom text to 'Add to Cart' button for this widget. You can write in any language."
          />
        )}
        <Form.Field
          label="Widget layout type"
          name="layout"
          control="select"
          options={[
            Layouts.CAROUSEL,
            Layouts.HORIZONTAL_GRID,
            [
              Widgets.BOUGHT_TOGETHER.value,
              Widgets.PROMOTED_PRODUCTS.value,
            ].includes(widget.type)
              ? Layouts.AMAZON_BOUGHT_TOGETHER
              : null,
          ].filter(Boolean)}
          ref={register()}
          helpText="It is adviced to use 'Amazon like bought together' for Frequently Bought Together and 'Carousel' for others. This can lead to higher order value."
        />
        {watchLayout === Layouts.CAROUSEL.value && (
          <React.Fragment>
            <Form.Field
              label="Choose control type for Carousel layout"
              name="carouselControl"
              control="select"
              options={[
                { label: 'Arrows', value: 'arrow' },
                { label: 'Bullets', value: 'bullet' },
              ]}
              ref={register()}
              helpText="You can either choose Arrows or Bullets for users to control Carousel transition."
            />
            <div className="flex flex-row justify-between">
              <Form.Field
                label="Maximum number of product visible in the Carousel on Desktop"
                name="desktopPerView"
                control="input"
                className="flex-1 mr-4"
                type="number"
                ref={register()}
                helpText="This is the maximum number of products that will be visible in the carousel on Desktop. We advice to keep the value between 4 and 6."
              />
              <Form.Field
                label="Maximum number of product visible in the Carousel on Mobile"
                name="mobilePerView"
                control="input"
                className="flex-1 ml-4"
                type="number"
                ref={register()}
                helpText="This is the maximum number of products that will be visible in the carousel on  Mobile. We advice to keep the value between 1 and 3."
              />
            </div>
          </React.Fragment>
        )}
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}
