import React from 'react'
import accounting from 'accounting'
import { format } from 'date-fns'
import numeral from 'numeral'

import COUNTRIES from 'constants/countries.json'

export default function FieldValue({ type, value, isList }) {
  switch (type) {
    case 'country':
      return value ? COUNTRIES_MAP[value] : null
    case 'currency':
      return value !== undefined || value !== null
        ? accounting.formatMoney(value, '$')
        : null
    case 'timestamp':
      return value ? format(new Date(value), 'do LLL, yyyy') : null
    case 'boolean':
      return value ? 'Yes' : 'No'
    case 'integer':
      return isInt(value) ? value : null
    case 'number':
      return isInt(value) ? numeral(value).format('0.0a') : null
    case 'string':
      return value ? (isList ? value.join(',') : value) : null
    case 'percentage':
      return value !== undefined || value !== null
        ? isInt(value)
          ? `${value}%`
          : `${parseFloat(value).toFixed(1)}%`
        : null
    default:
      throw new Error(`FieldValue component is missing this type => ${type}`)
  }
}

const COUNTRIES_MAP = COUNTRIES.reduce(
  (accum, item) => ({ ...accum, [item['alpha-2']]: item.name }),
  {}
)

function isInt(n) {
  return n % 1 === 0
}
