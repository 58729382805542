import React from 'react'
import { useForm } from 'react-hook-form'

import Form from 'components/Form'
import { validCssColor } from 'utils/browser'

import TranslationUpsell from '../TranslationUpsell'

export default function CartButtonForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Cart Button Background"
          name="cartBackground"
          control="color"
          setColor={setValue}
          color={formValues.cartBackground}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.cartBackground}
        />
        <Form.Field
          label="Cart Button Text Color"
          name="cartColor"
          control="color"
          setColor={setValue}
          color={formValues.cartColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.cartColor}
        />
        <Form.Field
          label="Cart Button Interaction"
          name="cartButtonMode"
          control="select"
          options={CART_BUTTON_MODES}
          ref={register({
            required: 'Cart button interaction cannot be empty',
          })}
        />
      </Form.FieldRow>
      <Form.FieldRow>
        <Form.Field
          label="Cart Button Text"
          name="addToCartText"
          control="input"
          ref={register({
            required: 'Required',
          })}
          formError={errors.addToCartText}
          supportElement={
            <TranslationUpsell
              templateId={props.widget.template}
              store={props.store}
            />
          }
        />
        <Form.Field
          label="Added to cart text"
          name="addedToCartText"
          control="input"
          ref={register({
            required: 'Required',
          })}
          formError={errors.addedToCartText}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, translations }) {
  return {
    cartButtonMode: settings.productCard.addToCartMode,
    cartBackground: settings.productCard.addToCartBackground,
    cartColor: settings.productCard.addToCartColor,
    addToCartText: translations.primaryLocale.addToCartText,
    addedToCartText: translations.primaryLocale.addedToCartText,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      productCard: {
        addToCartMode: values.cartButtonMode,
        addToCartBackground: values.cartBackground,
        addToCartColor: values.cartColor,
      },
    },
    translations: {
      primaryLocale: {
        addToCartText: values.addToCartText,
        addedToCartText: values.addedToCartText,
      },
    },
  }
}

const CART_BUTTON_MODES = [
  { label: 'Hidden', value: 'hidden' },
  { label: 'Slide In On Hover', value: 'slide' },
  { label: 'Always Visible', value: 'visible' },
]
