import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import * as Layouts from 'constants/layouts'

export default function ItemsForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues, props))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  React.useEffect(() => {
    reset(getDefaultValues(props))
  }, [props.widget.layout])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Maximum items in row (Desktop)"
          name="perRowDesktop"
          control="input"
          type="number"
          ref={register({
            required: 'Required!',
          })}
          formError={errors.perRowDesktop}
        />
        <Form.Field
          label="Maximum items in row (Mobile)"
          name="perRowMobile"
          control="input"
          type="number"
          ref={register({
            required: 'Required!',
          })}
          formError={errors.perRowMobile}
        />
        <Form.Field
          label="Maximum items in row (Widescreen)"
          name="perRowWidescreen"
          control="input"
          type="number"
          ref={register({
            required: 'Required!',
          })}
          formError={errors.perRowWidescreen}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, widget }) {
  const perRowItems = [
    'perRowMobileSmall',
    'perRowMobile',
    'perRowTablet',
    'perRowDesktop',
    'perRowWidescreen',
  ]

  let key
  if (widget.layout === Layouts.HORIZONTAL_GRID.value) {
    key = 'horizontalGrid'
  } else if (widget.layout === Layouts.CAROUSEL.value) {
    key = 'carousel'
  }

  const perRowSettings = perRowItems.reduce((accm, perRowItem) => {
    accm[perRowItem] =
      (settings[key] && settings[key][perRowItem]) ||
      settings.productCard[perRowItem]
    return accm
  }, {})

  return perRowSettings
}

function getUpdateObjectFromValues(values, { widget }) {
  if (widget.layout === Layouts.HORIZONTAL_GRID.value) {
    return {
      settings: {
        horizontalGrid: {
          perRowDesktop: values.perRowDesktop,
          perRowMobile: values.perRowMobile,
          perRowWidescreen: values.perRowWidescreen,
        },
      },
    }
  } else if (widget.layout === Layouts.CAROUSEL.value) {
    return {
      settings: {
        carousel: {
          perRowDesktop: values.perRowDesktop,
          perRowMobile: values.perRowMobile,
          perRowWidescreen: values.perRowWidescreen,
        },
      },
    }
  }
  return {
    settings: {
      productCard: {
        perRowDesktop: values.perRowDesktop,
        perRowMobile: values.perRowMobile,
        perRowWidescreen: values.perRowWidescreen,
      },
    },
  }
}
