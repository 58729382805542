import React from 'react'
import numeral from 'numeral'
import classNames from 'classnames'

export default function ProgressBar({ progress, color, backgroundColor }) {
  return (
    <div className="w-full pb-3">
      <div className={classNames('shadow w-full', backgroundColor)}>
        <div
          className={classNames(
            'text-xs leading-none py-1 text-center text-white',
            color
          )}
          style={{ width: `${progress * 100}%` }}
        >
          {numeral(progress).format('0.00%')}
        </div>
      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  progress: 0,
  color: 'bg-primary-500',
  backgroundColor: 'bg-gray-300',
}
