import React from 'react'
import FileDownload from 'js-file-download'
import Box from 'components/Box'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import WaitForData from 'components/WaitForData'
import UploadList from './UploadList'
import RecommendationUploadModal from './RecommendationUploadModal'
import _uniqBy from 'lodash.uniqby'
import { useApi } from 'hooks/api'
import { useOpenModal } from 'components/Modal'

const SAMPLE_CSV_DATA = `Product,Recommended Product
Parent Product 1 Title,Single Recommmended Product 1 Title
Parent Product 1 Title,Single Recommmended Product 2 Title
Parent Product 2 Title,Single Recommmended Product 3 Title`

export default function BulkConfigure({ account, store }) {
  const { name: shop } = account.data

  const uploads = useApi('recommendations/uploads/', { shop })
  const openModal = useOpenModal()

  function handleRecommendationUploadSave(data) {
    uploads.update(
      {
        ...uploads.data,
        uploads: _uniqBy([data.upload, ...uploads.data.uploads], (p) => p.id),
      },
      {
        local: true,
      }
    )
  }

  function onSampleDownload() {
    FileDownload(SAMPLE_CSV_DATA, 'bulk-recommendations-sample.csv')
  }

  return (
    <WaitForData objects={[uploads]} loadingMessage="Loading email campaigns">
      {() => (
        <div className="flex flex-row items-start">
          <Box className="flex flex-col items-stretch flex-1 mb-6 mr-6">
            <div className="flex flex-row items-center justify-between pb-3 mb-3 border-b border-gray-200">
              <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
                <span className="mr-2">Recommendation Bulk Uploads</span>
                <Tooltip
                  position="right"
                  content="You can upload Recommendations for multiple products in bulk using this option."
                  showHelpIcon={true}
                  helpIconVariant="dark"
                />
              </span>
              <span
                className="text-sm text-primary underline cursor-pointer"
                onClick={onSampleDownload}
              >
                Download sample csv
              </span>
            </div>
            <UploadList uploads={uploads.data.uploads} />
          </Box>
          <Button
            onClick={() =>
              openModal(
                <RecommendationUploadModal
                  shop={shop}
                  onSave={handleRecommendationUploadSave}
                />
              )
            }
          >
            + Upload Recommendations
          </Button>
        </div>
      )}
    </WaitForData>
  )
}
