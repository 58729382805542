import React from 'react'

import Icons from 'components/Icons'

export default function Header({ className, customStlye }) {
  return (
    <div className="flex flex-row items-center justify-between px-4 py-5">
      <div></div>
      <span className="text-2xl font-semibold uppercase">Your Cart</span>
      <Icons.Cross className="w-12" />
    </div>
  )
}
