import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'

import Button from 'components/Button'
import Icons from 'components/Icons'
import Tooltip from 'components/Tooltip'
import { PROMOTION_LAYOUT_SECTION_TYPES } from 'constants/promotions'
import * as AccountUtils from 'utils/account'
import { _find } from 'utils/lodash'

import Banner from './Banner'
import SingleProductUpsell from './SingleProductUpsell'
import Text from './Text'
import Image from './Image'
import MultiProductUpsell from './MultiProductUpsell'

export default function PromotionBuilderLayoutPreview({
  layout,
  accountData,
  readOnly,
}) {
  const shopLocale = AccountUtils.getShopLocale(accountData)
  const { data, activeSectionId } = layout
  const [showAddSection, setShowAddSection] = useState(false)

  useEffect(() => {
    if (activeSectionId) {
      const activeSectionWrapper = document.getElementById(
        `preview-section-${activeSectionId}`
      )

      if (
        activeSectionWrapper &&
        typeof activeSectionWrapper.scrollIntoView === 'function'
      ) {
        activeSectionWrapper.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }
  }, [activeSectionId])

  return (
    <div
      className={classNames('relative flex flex-1 min-h-0 bg-white', {
        rounded: readOnly,
      })}
    >
      <div className="flex-1 overflow-y-auto">
        <div
          className={classNames('flex flex-col items-stretch flex-1', {
            'mb-20': !readOnly,
          })}
        >
          <header className="flex flex-col items-center mb-6">
            <div className="flex flex-row w-full max-w-[1024px] px-8 py-3 border-b border-gray-200">
              <span className="flex items-center justify-center w-6 h-6 mt-2 mr-3 text-blue-600 border-2 border-blue-600 rounded-full ">
                <Icons.Tick className="w-2.5 stroke-current" />
              </span>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-800">
                  {TRANSLATIONS.paid_for_order[shopLocale]}
                </span>
                <span className="flex flex-row text-xs font-semibold text-blue-600">
                  {TRANSLATIONS.view_order_confirmation[shopLocale]}{' '}
                  <Icons.ChevronRight className="w-1.5 ml-2" />
                </span>
              </div>
            </div>
          </header>
          <div className="flex flex-col items-stretch">
            {(data.sections || []).map((section, index) => {
              const SectionComponent =
                SECTION_TYPE_TO_COMPONENT_MAP[section.type]
              const sectionElementId = `preview-section-${section.uuid}`
              return SectionComponent ? (
                <div
                  key={sectionElementId}
                  id={sectionElementId}
                  className={classNames('flex flex-col items-center', {
                    'border-2 border-dashed border-black':
                      section.uuid === activeSectionId,
                    'mb-5': index < data.sections.length - 1,
                  })}
                >
                  <SectionComponent
                    key={section.uuid}
                    section={section}
                    accountData={accountData}
                  />
                </div>
              ) : null
            })}
          </div>
          <footer className="flex flex-col items-center my-8">
            <div className="flex flex-row w-full border-t border-gray-200 max-w-[1024px] pt-4 space-x-3 px-8">
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {TRANSLATIONS.privacy_policy[shopLocale]}
              </span>
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {TRANSLATIONS.terms_of_service[shopLocale]}
              </span>
              <span className="flex flex-row text-xs font-semibold text-blue-600">
                {TRANSLATIONS.refund_policy[shopLocale]}
              </span>
            </div>
          </footer>
        </div>
      </div>
      {!readOnly && false && (
        <React.Fragment>
          <div className="absolute bottom-5 right-5">
            <Button
              variant="solid"
              onClick={() => setShowAddSection(!showAddSection)}
            >
              Add Section
            </Button>
          </div>
          {showAddSection && (
            <div className="absolute z-10 flex flex-col items-center max-w-xl px-10 py-5 bg-white border-2 rounded border-gray-300 shadow-card bottom-[10%] right-[2%]">
              <div className="flex flex-row items-center self-stretch text-gray-800 mb-9">
                <span className="ml-auto text-base font-medium">
                  Choose section to add
                </span>
                <Icons.Cross
                  className="w-6 ml-auto -mr-6 text-gray-800 cursor-pointer"
                  onClick={() => setShowAddSection(false)}
                />
              </div>
              <ul className="grid grid-cols-2 pb-6 gap-x-4 gap-y-6">
                {Object.keys(PROMOTION_LAYOUT_SECTION_TYPES).map((k) => {
                  const { value, label, icon, allowNewSection } =
                    PROMOTION_LAYOUT_SECTION_TYPES[k]
                  return allowNewSection ? (
                    <li
                      key={value}
                      className="flex flex-col items-center px-4 pt-4 pb-2 rounded-sm cursor-pointer hover:bg-gray-50"
                      onClick={() => {
                        const { getNewSectionDefaults } = _find(
                          Object.keys(PROMOTION_LAYOUT_SECTION_TYPES).map(
                            (k) => PROMOTION_LAYOUT_SECTION_TYPES[k]
                          ),
                          { value }
                        )

                        layout.addSection({
                          uuid: uuid(),
                          type: value,
                          ...(typeof getNewSectionDefaults === 'function'
                            ? getNewSectionDefaults()
                            : { settings: {}, translations: {} }),
                        })
                        setTimeout(() => setShowAddSection(false), 10)
                      }}
                    >
                      <div className="flex justify-center items-center mb-2 rounded-lg bg-primary-300 text-primary h-[78px] w-[78px]">
                        {icon}
                      </div>
                      <span className="text-xs text-center text-black">
                        {label}
                      </span>
                    </li>
                  ) : null
                })}
              </ul>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

const TRANSLATIONS = {
  paid_for_order: {
    en: "You've paid for your order",
  },

  view_order_confirmation: {
    en: 'View order confirmation',
  },

  privacy_policy: {
    en: 'Privacy Policy',
  },

  terms_of_service: {
    en: 'Terms of Service',
  },

  refund_policy: {
    en: 'Refund Policy',
  },
}

const SECTION_TYPE_TO_COMPONENT_MAP = {
  [PROMOTION_LAYOUT_SECTION_TYPES.BANNER.value]: Banner,
  [PROMOTION_LAYOUT_SECTION_TYPES.TEXT.value]: Text,
  [PROMOTION_LAYOUT_SECTION_TYPES.IMAGE.value]: Image,
  [PROMOTION_LAYOUT_SECTION_TYPES.SINGLE_PRODUCT_UPSELL.value]:
    SingleProductUpsell,
  [PROMOTION_LAYOUT_SECTION_TYPES.MULTI_PRODUCT_UPSELL.value]:
    MultiProductUpsell,
}
