import * as React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'

export default function ModalFrame({ children, close, isVisible, options }) {
  const { fullScreen, className, hideCloseButton } = options
  return (
    <div
      className={classNames('flex flex-col', {
        'items-stretch': fullScreen,
        'items-center h-full justify-center': !fullScreen,
      })}
      style={hideCloseButton ? { margin: '-40px -80px' } : undefined}
    >
      {fullScreen && !hideCloseButton && (
        <Icons.Close
          className="self-end h-6 mb-2 -mr-6 text-white opacity-75 cursor-pointer fill-current hover:opacity-100"
          onClick={close}
        />
      )}
      <div
        onClick={(e) => e.stopPropagation()}
        className={classNames('relative', className)}
      >
        {!fullScreen && !hideCloseButton && (
          <Icons.Close
            className="absolute h-6 text-white opacity-75 cursor-pointer fill-current hover:opacity-100"
            style={{ top: '-1rem', right: '-2rem' }}
            onClick={close}
          />
        )}
        {children}
      </div>
    </div>
  )
}

const hiddenStyle = {
  display: 'none',
}
