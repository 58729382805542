import ICON_KLAVIYO from './icons/klaviyo.png'
import ICON_MAILCHIMP from './icons/mailchimp.png'
import ICON_OMNISEND from './icons/omnisend.svg'
import ICON_SHOPIFY from './icons/shopify.webp'
import ICON_SPENTLY from './icons/spently.png'

const KLAVIYO = {
  value: 'klaviyo',
  label: 'Klaviyo',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  icon: ICON_KLAVIYO,
}

const MAILCHIMP = {
  value: 'mailchimp',
  label: 'Mailchimp',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  icon: ICON_MAILCHIMP,
}

const OMNISEND = {
  value: 'omnisend',
  label: 'Omnisend',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  icon: ICON_OMNISEND,
}

const SHOPIFY = {
  value: 'shopify',
  label: 'Shopify',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  icon: ICON_SHOPIFY,
}

const SPENTLY = {
  value: 'spently',
  label: 'Spently',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  icon: ICON_SPENTLY,
}

export { KLAVIYO, MAILCHIMP, OMNISEND, SHOPIFY, SPENTLY }
