import React, { useState } from 'react'

import Form from 'components/Form'
import Box from 'components/Box'
import Button from 'components/Button'

export default function TimeTriggeredSettings({
  settings,
  onChange,
  onSave,
  close,
}) {
  const [timeInterval, setTimeInterval] = useState(
    settings.timeInterval ? settings.timeInterval : undefined
  )
  return (
    <Box>
      <Box.Heading>Configure Settings</Box.Heading>
      <div>
        <Form.Field
          control="input"
          type="number"
          label="Time Interval"
          value={timeInterval}
          onChange={(e) => {
            setTimeInterval(parseInt(e.target.value))
          }}
        />
      </div>
      <div className="flex justify-end w-full">
        <Button
          size={'small'}
          onClick={() => {
            onSave({
              timeInterval,
            })
            close()
          }}
        >
          Save
        </Button>
      </div>
    </Box>
  )
}

export function TimeTriggeredExtra({ settings }) {
  const { timeInterval } = settings
  return (
    <div className="mt-3">
      <div className="w-16 text-gray-500 border-b border-gray-500">
        <span className="text-sm">
          {!timeInterval ? 'In Secs' : `$${timeInterval} s`}
        </span>
      </div>
    </div>
  )
}
