export const WORKFLOW_TASK_TYPES = {
  PRE_CHECKOUT: {
    label: 'Pre-Checkout',
    value: 'pre_checkout',
  },
  POST_CHECKOUT: {
    label: 'Post-Checkout',
    value: 'post_checkout',
  },
}
