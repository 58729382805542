import React from 'react'
import api from 'api'
import _uniq from 'lodash.uniq'

import { useToast } from 'components/Toast'
import Products from 'components/MultiSelects/Products'
import Tags from 'components/MultiSelects/Tags'
import WaitForData from 'components/WaitForData'
import { useApi } from 'hooks/api'

export default function DisabledProducts({
  account,
  store,
  currencyFormat,
  rkSupport,
}) {
  const { name: shop } = account.data
  const disabledData = useApi('disabled/', { shop })
  const { disabledTags = [] } = store.data
  const openToast = useToast()

  async function updateDisabledProducts(productIds, twoWayEnabled = false) {
    try {
      const { data } = await api.post(
        '/disabled/',
        { productIds: productIds, reset: true },
        { params: { shop } }
      )
      disabledData.update(data, { local: true })
      openToast({
        type: 'success',
        text: 'Disabled List has been Updated. It might take a few minutes for the changes to be visible.!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Disabled List could not be updated successfully! Please contact support.',
      })
    }
  }

  async function removeProduct(productId) {
    try {
      await api.delete(`/disabled/${productId}/`, { params: { shop } })
      const updatedProducts = disabledData.data.products.filter(
        (product) => product.id !== productId
      )
      disabledData.update({ products: updatedProducts }, { local: true })
      openToast({
        type: 'success',
        text: 'Product removed from Disabled List. It might take a few minutes for the effects to take place!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Product could not be removed from disabled list! Please contact support.',
      })
    }
  }

  async function updateTags(newTags) {
    try {
      await store.update({ disabledTags: newTags })
      openToast({
        type: 'success',
        text: 'Tags successfully updated..',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Unable to update the tags. Please contact support.',
      })
    }
  }

  async function onTagAdd({ tag }) {
    if (disabledTags.includes(tag)) {
      openToast({
        type: 'error',
        text: 'Tag already exists',
      })
    } else {
      const newTags = _uniq([...disabledTags, tag])
      await updateTags(newTags)
    }
  }

  async function onTagRemove(tag) {
    const newTags = _uniq(disabledTags.filter((t) => t !== tag))
    await updateTags(newTags)
  }

  return (
    <WaitForData
      objects={[disabledData, store]}
      loadingMessage="Loading Disabled Products"
    >
      {() => {
        return (
          <div className="flex flex-row items-start">
            <Products
              products={disabledData.data.products}
              currencyFormat={currencyFormat}
              rkSupport={rkSupport}
              updateProducts={updateDisabledProducts}
              removeProduct={removeProduct}
              copies={PRODUCT_COPIES}
            />
            <Tags
              tags={disabledTags}
              onTagAdd={onTagAdd}
              onTagRemove={onTagRemove}
              copies={TAG_COPIES}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}

const PRODUCT_COPIES = {
  title: 'Disabled Products',
  tooltipContent:
    "Add a product here to hide widgets from showing up on it's page. It may take a few minutes for the configuration to take effect.",
  resourcePickerHeading: 'Choose disabled products',
  fallbackContent:
    'No products added to disabled list yet. Adding a product here will prevent widgets from showing up on those product pages.',
}
const TAG_COPIES = {
  title: 'Disabled Tags',
  tooltipContent:
    'Widgets will not show up for products that have any of the following tags.',
  fallbackContent:
    'No tags added to disabled list yet. Adding a tag here will remove the widgets on Product pages with these tags.',
}
