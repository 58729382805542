import React from 'react'
import classNames from 'classnames'
import numeral from 'numeral'

import { Plan } from 'components/Pricing'
import Box from 'components/Box'
import WaitForData from 'components/WaitForData'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'
import * as Integrations from 'constants/integrations'
import * as Widgets from 'constants/widgets'
import Features, { FEATURES_ORDER } from 'constants/features'
import { _find } from 'utils/lodash'
import { openChat } from 'utils/external'
import { trackEvent } from 'utils/analytics'
import { useApi } from 'hooks/api'

export default function UpgradeAccountModal({
  account,
  featureToEnable,
  widgetToEnable,
  pageToEnable,
  customMessage,
  customPlan,
  mode = 'modal',
}) {
  const { name: shop } = account.data
  const pricingData = useApi('account/plans/', { shop })
  const accountData = useApi('account/', { shop })
  const feature = featureToEnable
    ? featureToEnable
    : widgetToEnable
    ? widgetToEnable
    : pageToEnable
  const trackingInfo = {
    category: 'Upgrade Modal',
    label: feature?.label || customPlan ? 'See Recommendations' : '',
  }
  return (
    <Box>
      <WaitForData objects={[accountData, pricingData]}>
        {() => {
          let plan =
            customPlan ||
            _find(pricingData.data, function (o) {
              if (featureToEnable) {
                return o.featuresEnabled.includes(featureToEnable.value)
              } else if (widgetToEnable) {
                return o.recommendationsEnabled.includes(widgetToEnable.value)
              } else if (pageToEnable) {
                return o.pagesEnabled.includes(pageToEnable.value)
              }
              return null
            })
          const currentPlan = account.data.plan
          let isCurrentPlan = false
          if (
            !customPlan &&
            (plan?.name === accountData.data.shopPlan.name ||
              parseFloat(plan?.price) <=
                parseFloat(accountData.data.shopPlan.price))
          ) {
            plan = null
          }
          if (plan) {
            trackEvent({
              ...trackingInfo,
              action: 'View',
              value: plan ? parseInt(plan.price) : 0,
            })
            isCurrentPlan = currentPlan.id === plan.id
          }

          if (mode === 'banner') {
            const featureNotEnabled =
              (plan &&
                parseFloat(currentPlan.price) >= parseFloat(plan.price)) ||
              !plan
            return (
              <div className="flex flex-row items-center justify-between px-5 py-3 rounded bg-primary-200">
                <div className="flex flex-col items-stretch mr-4">
                  <span className="mb-1 text-xl font-semibold text-primary">
                    {featureNotEnabled
                      ? `${feature.label} is not enabled for your store`
                      : `You are on ${currentPlan.name} plan`}
                  </span>
                  <span className="text-sm font-medium text-gray-800">
                    {featureNotEnabled
                      ? `Contact support to enable ${feature.label} on your store`
                      : customMessage
                      ? customMessage
                      : `${feature.label} are supported only on ${plan.name} plans and above. Start your free trial now to set ${feature.label} live`}
                  </span>
                </div>
                {plan && !featureNotEnabled ? (
                  <Button
                    onClick={() => {
                      trackEvent({
                        ...trackingInfo,
                        action: 'Click Upgrade',
                        value: parseInt(plan.price),
                      })
                      window.top.location.href = `${plan.chargeUrl}&shop=${shop}&promo_code=${plan.discountedData.promoCode}`
                    }}
                  >
                    {plan.trialDays && plan.trialDays > 0
                      ? 'Start Free Trial'
                      : 'Upgrade Plan'}
                  </Button>
                ) : (
                  <Button onClick={openChat}>Chat with us</Button>
                )}
              </div>
            )
          }
          return (
            <div className="flex flex-col items-start text-center">
              <div className="flex flex-row w-full">
                <div
                  className={classNames('flex flex-col items-center mt-8', {
                    'w-1/2': plan,
                    'w-full': !plan,
                  })}
                >
                  <span className="mb-8 text-2xl">
                    {customMessage ? (
                      customMessage
                    ) : customPlan ? (
                      <React.Fragment>
                        <span className="mr-1 font-semibold uppercase">
                          {isCurrentPlan ? 'Current Plan' : 'Upgrade Now'}
                        </span>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className="mr-1 font-semibold">
                          {feature.label}
                        </span>
                        {plan ? (
                          <React.Fragment>
                            is available on the{' '}
                            <span className="font-semibold">
                              {plan.name.toUpperCase()}
                            </span>{' '}
                            plan. <br />
                            Please click on{' '}
                            <span className="font-semibold">
                              "Upgrade"
                            </span>{' '}
                            button to enable it 👉
                          </React.Fragment>
                        ) : (
                          'is not enabled for your store.'
                        )}
                      </React.Fragment>
                    )}
                  </span>
                  <div className="flex flex-row justify-center flex-1 pt-3 font-semibold text-gray-800">
                    Have any questions?
                    <span
                      className="ml-1 text-primary underline cursor-pointer"
                      onClick={() => openChat()}
                    >
                      Chat with us
                    </span>
                  </div>
                </div>
                {plan && (
                  <PricingTag
                    plan={plan}
                    shop={shop}
                    isCurrentPlan={isCurrentPlan}
                    trackingInfo={trackingInfo}
                  />
                )}
              </div>
              {plan && (
                <React.Fragment>
                  <div className="flex flex-row w-full mt-4">
                    <div className="flex flex-col w-1/2">
                      <TrialDays plan={plan} />
                      <FeaturesList plan={plan} />
                    </div>
                    <div className="flex flex-col w-1/2 pl-4">
                      <div className="w-full">
                        <UpgradeButton
                          plan={plan}
                          shop={shop}
                          isCurrentPlan={isCurrentPlan}
                          trackingInfo={trackingInfo}
                          className="rounded-b-none"
                        />
                      </div>
                      <WidgetServeInformation plan={plan} />
                      <WidgetTypeList plan={plan} />
                      <IntegrationsList plan={plan} />
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full">
                    <div className="w-1/2">
                      <UpgradeButton
                        plan={plan}
                        shop={shop}
                        isCurrentPlan={isCurrentPlan}
                        trackingInfo={trackingInfo}
                        className="mt-3 rounded-t-none"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )
        }}
      </WaitForData>
    </Box>
  )
}

function UpgradeButton({ plan, trackingInfo, shop, isCurrentPlan, className }) {
  return (
    <Button
      className={classNames('w-full py-3', className)}
      onClick={() => {
        trackEvent({
          ...trackingInfo,
          action: 'Click Upgrade',
          value: parseInt(plan.price),
        })
        window.top.location.href = `${plan.chargeUrl}&shop=${shop}&promo_code=${plan.discountedData.promoCode}`
      }}
      disabled={isCurrentPlan}
      tooltipContent={isCurrentPlan ? 'This is the current plan.' : ''}
      tooltipTriggerClassName="w-full"
    >
      {plan.trialDays && plan.trialDays > 0 ? 'Start Free Trial' : 'Upgrade'}
    </Button>
  )
}

function PricingTag({ plan, trackingInfo, shop, isCurrentPlan }) {
  return (
    <div className="flex flex-col items-center justify-center w-1/2 py-4">
      <h2 className="mb-4 text-xl font-semibold uppercase">{plan.name}</h2>
      <div className="flex flex-col items-center w-full pl-4">
        <div className="flex items-baseline">
          <span className="self-center mr-1 text-gray-800">$</span>
          <span className="text-4xl font-semibold leadiflex flex-rowng-none">
            {plan.price}
          </span>
          <span className="text-gray-800">/mo</span>
        </div>
      </div>
    </div>
  )
}

function TrialDays({ plan }) {
  return (
    <div className="flex flex-col items-center justify-center w-full px-6 py-6 bg-gray-300">
      {plan.trialDays > 0 || true ? (
        <React.Fragment>
          <div className="flex items-baseline">
            <span>
              <span className="mr-1 text-2xl font-semibold">
                {plan.trialDays}
              </span>
              Days
            </span>
          </div>
          <span className="text-gray-800">Risk Free Trial</span>
        </React.Fragment>
      ) : null}
    </div>
  )
}

function FeaturesList({ plan, hideDisabledFeatures, hideFeatures = [] }) {
  const { featuresEnabled = [] } = plan
  const featuresToShow = Object.keys(Features)
    .filter(
      (k) =>
        !Features[k].hideInPricing && !hideFeatures.includes(Features[k].value)
    )
    .map((k) => Features[k])
  return (
    <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
      <span className="mb-3 text-sm text-gray-800 uppercase">Features</span>
      <div className="flex flex-wrap w-full">
        {featuresToShow
          .sort((f1, f2) => {
            const f1Enabled = featuresEnabled.includes(f1.value)
            const f2Enabled = featuresEnabled.includes(f2.value)
            if ((!f1Enabled && !f2Enabled) || (f1Enabled && f2Enabled)) {
              return FEATURES_ORDER.indexOf(f1) - FEATURES_ORDER.indexOf(f2)
            }
            if (!f1Enabled) {
              return 1
            }
            return -1
          })
          .map((feature) => {
            const isEnabled = featuresEnabled.includes(feature.value)
            if (!isEnabled && hideDisabledFeatures) {
              return null
            }

            return (
              <span
                className={classNames(
                  'mb-1 pb-1 border-b border-gray-200 font-medium capitalize w-1/2',
                  {
                    ['line-through']: !isEnabled,
                  }
                )}
                key={feature.value}
              >
                {isEnabled ? '' : '❌'}{' '}
                {plan.name === 'Free'
                  ? feature.freePlanLabel || feature.label
                  : feature.label}
              </span>
            )
          })}
      </div>
    </div>
  )
}

function WidgetServeInformation({ plan }) {
  return (
    <div className="flex flex-col items-center justify-center w-full px-6 py-6 bg-gray-300">
      <span className="mr-1 text-2xl font-semibold">
        {numeral(plan.maximumRecommendationsServed).format('0,0')}
      </span>
      <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
        <span className="mr-2 text-gray-800">Widgets Served </span>
        <Tooltip
          position="top"
          content="Widget Served is the number of times a widget is served to a visitor. It is directly proportional to number of widgets on a page and page views."
          showHelpIcon={true}
          helpIconVariant="dark"
        />
      </span>
      {plan.isUsageBased && (
        <p className="mt-2 text-xs text-center text-gray-800">
          *Once usage exceeds plan quota, you will be charged{' '}
          <strong>
            ${plan.recommendationServesBatchPrice} per{' '}
            {numeral(plan.recommendationServesBatchSize).format('0,0')}{' '}
          </strong>{' '}
          widget serves. This additional charge is capped at{' '}
          <strong>${plan.maxPlanUsageCharge}/mo. </strong>
          Maximum widget serve threshold{' '}
          <strong>
            {numeral(plan.recommendationsServedHardLimit).format('0,0')}{' '}
          </strong>{' '}
        </p>
      )}
    </div>
  )
}

function IntegrationsList({ plan }) {
  const { integrationsEnabled = [] } = plan
  const integrationsToShow = Object.keys(Integrations).map(
    (k) => Integrations[k]
  )
  return (
    <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
      <span className="mb-3 text-sm text-gray-800 uppercase">Integrations</span>
      <div className="flex flex-wrap w-full">
        {integrationsToShow
          .sort((i1, i2) => i1.score - i2.score)
          .map((integration) => {
            const isEnabled = integrationsEnabled.includes(integration.value)
            return (
              <span
                className={classNames('mb-1 font-medium capitalize w-1/2', {
                  ['line-through']: !isEnabled,
                })}
                key={integration.value}
              >
                {isEnabled ? '' : '❌'} {integration.label}
              </span>
            )
          })}
      </div>
    </div>
  )
}

function WidgetTypeList({ plan }) {
  const { recommendationsEnabled = [] } = plan
  const widgetTypesToShow = Object.keys(Widgets).map((k) => Widgets[k])
  return (
    <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
      <span className="mb-3 text-sm text-gray-800 uppercase">Widget Types</span>
      <div className="flex flex-wrap w-full">
        {widgetTypesToShow
          .sort((w1, w2) => {
            const w1Enabled = recommendationsEnabled.includes(w1.value)
            const w2Enabled = recommendationsEnabled.includes(w2.value)
            if ((!w1Enabled && !w2Enabled) || (w1Enabled && w2Enabled)) {
              return w1.score - w2.score
            }
            if (!w1Enabled) {
              return 1
            }
            return -1
          })
          .map((widgetType) => {
            const isEnabled = recommendationsEnabled.includes(widgetType.value)
            return (
              <span
                className={classNames('mb-1 font-medium capitalize w-1/2', {
                  ['line-through']: !isEnabled,
                })}
                key={widgetType.value}
              >
                {isEnabled ? '' : '❌'} {widgetType.label}
              </span>
            )
          })}
      </div>
    </div>
  )
}
