import React from 'react'
import { useHistory } from 'react-router-dom'
import { format, parse } from 'date-fns'
import classNames from 'classnames'
import numeral from 'numeral'

import Box from 'components/Box'
import ProgressBar from 'components/ProgressBar'
import Text from 'components/Text'
import UpgradeCallout from 'components/Callout/UpgradeCallout'
import { updateURLSearch } from 'utils/router'

export default function Usage({ account }) {
  const { billingCycle, plan } = account.data
  const showUsageBasedAmount = plan.isUnlimited || plan.isExtendedUsageEnabled
  const isOnFreePlan = plan.name === 'Free'
  const history = useHistory()

  let usageBasedCharge
  let usageBasedChargeRule
  if (showUsageBasedAmount) {
    usageBasedCharge = numeral(
      plan.isUnlimited
        ? Math.min(
            parseFloat(billingCycle.billingPrice),
            parseFloat(billingCycle.maximumPrice)
          )
        : Math.min(
            parseFloat(billingCycle.billingPrice),
            parseFloat(plan.maxPlanUsageCharge)
          )
    ).format('0.00')

    const extraUsage = Math.max(
      0,
      plan.currentUsage - plan.maximumRecommendationsServed
    )
    usageBasedChargeRule = plan.isUnlimited
      ? `*This amount is calculated using ${billingCycle.revenuePercent}% of total revenue generated through Personalized Recommendations for your store.`
      : `*This amount is calculated for additional usage of ${
          extraUsage > 0 ? extraUsage : ''
        } widgets by your store at $${
          plan.recommendationServesBatchPrice
        } per ${plan.recommendationServesBatchSize} widget serves`
  }
  const maxRecommendationsLimit = plan.isExtendedUsageEnabled
    ? plan.recommendationsServedHardLimit
    : plan.maximumRecommendationsServed

  function goToPricingScreen() {
    history.push({ search: updateURLSearch({ all: true }), pathname: '/plans' })
  }
  const onAnnual = plan.onAnnual || false
  return (
    <div>
      <div className="flex flex-row flex-wrap">
        <Box className="w-full">
          {billingCycle.endingOn && (
            <Box.Heading>
              <div className="flex flex-row justify-between">
                <h3>Widgets Served</h3>
                {billingCycle.isTrialPeriod ? (
                  <div
                    className="underline cursor-pointer "
                    onClick={goToPricingScreen}
                  >
                    Trial ends on:{' '}
                    {format(
                      parse(billingCycle.endingOn, 'yyyy-MM-dd', new Date()),
                      'd MMMM, yyyy'
                    )}
                  </div>
                ) : (
                  <div
                    className="underline cursor-pointer "
                    onClick={goToPricingScreen}
                  >
                    Billing Period:{' '}
                    {format(
                      parse(billingCycle.startedOn, 'yyyy-MM-dd', new Date()),
                      'd MMMM, yyyy'
                    )}{' '}
                    -{' '}
                    {format(
                      parse(billingCycle.endingOn, 'yyyy-MM-dd', new Date()),
                      'd MMMM, yyyy'
                    )}
                  </div>
                )}
              </div>
            </Box.Heading>
          )}
          <Box.Section>
            <div className="flex justify-between">
              {onAnnual ? (
                <div className="flex items-center self-auto">
                  Annual Charge: ${plan.annualPrice}
                </div>
              ) : (
                <div className="flex items-center self-auto">
                  Monthly Charge: ${plan.price}
                </div>
              )}

              <div className="flex items-center self-auto ml-8">
                {isOnFreePlan && (
                  <UpgradeCallout
                    text="Prevent Service Cut & Enable More Features"
                    type="info"
                    buttonText="Upgrade Now"
                    tooltip="The widgets will stop showing up once the quota expires. Upgrade your plan to prevent service cut."
                  />
                )}
              </div>
            </div>
          </Box.Section>
          {showUsageBasedAmount && !isOnFreePlan && (
            <Box.Section>
              <div>
                {plan.isUnlimited ? '' : 'Additional '}Usage Charge:{' '}
                <Text.Strong>${usageBasedCharge}</Text.Strong>
              </div>
              <div>
                <Text.SubText>{usageBasedChargeRule}</Text.SubText>
              </div>
            </Box.Section>
          )}

          <Box.Section
            className={classNames({
              'opacity-50': !billingCycle.endingOn,
            })}
          >
            <ProgressBar
              progress={billingCycle.currentUsage / maxRecommendationsLimit}
            />
            You have consumed{' '}
            {numeral(
              billingCycle.currentUsage / maxRecommendationsLimit
            ).format('0.00%')}{' '}
            (Usage: {numeral(billingCycle.currentUsage).format('0,0')}/
            {numeral(maxRecommendationsLimit).format('0,0')}) of your quota.
            {plan.isExtendedUsageEnabled && !isOnFreePlan && (
              <div>
                <Text.SubText>
                  *You will be charged post{' '}
                  {numeral(plan.maximumRecommendationsServed).format('0,0')}{' '}
                  widget serves
                </Text.SubText>
              </div>
            )}
          </Box.Section>

          {!plan.isUnlimited && (
            <Box.Section>
              Once your monthly quota is exhausted your website visitors will
              stop seeing recommendations.
            </Box.Section>
          )}
        </Box>
      </div>
    </div>
  )
}
