import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import { fetchPages } from 'api/pages'
import Icons from 'components/Icons'
import Box from 'components/Box'
import Form from 'components/Form'
import WaitForData from 'components/WaitForData'
import { useOpenModal } from 'components/Modal'
import Button from 'components/Button'
import Badge from 'components/Badge'
import * as Widgets from 'constants/widgets'
import * as Layouts from 'constants/layouts'
import * as Pages from 'constants/pages'
import { getAccountData } from 'utils/account'
import * as QueryUtils from 'utils/query'
import WidgetMultiSelect from 'components/MultiSelects/Widgets'

export default function ShowWidgetsSettings({
  settings,
  onChange,
  onSave,
  close,
}) {
  const { name: shop } = getAccountData()
  const pagesQuery = useQuery(['pages', shop], fetchPages)
  const [selectedWidgetIds, setSelectedWidgetIds] = useState(
    settings.widgets ? settings.widgets : []
  )
  function addOrRemoveWidget(id) {
    if (selectedWidgetIds.includes(parseInt(id))) {
      setSelectedWidgetIds(
        selectedWidgetIds.filter((widgetId) => widgetId != id)
      )
    } else {
      setSelectedWidgetIds(selectedWidgetIds.concat(id))
    }
  }
  useEffect(() => {
    onChange({
      widgets: selectedWidgetIds,
    })
  }, [selectedWidgetIds])

  return (
    <Box>
      <Box.Heading>Configure Settings</Box.Heading>
      <WaitForData isQueries={true} objects={[pagesQuery]}>
        {() => {
          const pagesData = QueryUtils.data(pagesQuery)
          const allWidgets = (pagesData || []).reduce(
            (widgets, page) => [...widgets, ...page.widgets],
            []
          )
          const allSupportedWidgets = allWidgets.filter((widget) => {
            return (
              widget.type === Widgets.BOUGHT_TOGETHER.value &&
              widget.layout === Layouts.AMAZON_BOUGHT_TOGETHER.value &&
              widget.pageType === Pages.PRODUCT.value
            )
          })

          const hasSupportedWidgets = allSupportedWidgets.length > 0
          return (
            <div className="flex flex-col items-stretch">
              <span className="text-sm font-semibold">
                Select widget to associate.
              </span>

              <div className="flex flex-col my-4">
                {hasSupportedWidgets ? (
                  allSupportedWidgets.map((widget) => {
                    return (
                      <div
                        className="flex flex-col px-2 py-2 border border-gray-400 rounded cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                          addOrRemoveWidget(widget.id)
                        }}
                        key={widget.id}
                      >
                        <div className="flex flex-row items-center justify-between mb-2">
                          <span className="text-base font-semibold">
                            {widget.title}
                          </span>
                          {selectedWidgetIds.includes(widget.id) && (
                            <Icons.CircleTickThin className="w-4 text-green-700" />
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between mb-1 text-xs">
                          <span>Widget ID: {widget.id}</span>
                          {!widget.enabled && (
                            <Badge color="yellow">Disabled</Badge>
                          )}
                        </div>
                        <div className="flex flex-row items-center justify-between mb-1 text-xs">
                          <span>Experience ID: {widget.experience.id}</span>
                          {widget.experience.status === 'unpublished' ||
                            (widget.experience.status === 'archived' && (
                              <Badge color="yellow">Unpublished</Badge>
                            ))}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="flex flex-col px-2 py-2 bg-red-200 border border-red-400 rounded cursor-pointer">
                    <span className="text-sm text-red-600">
                      There are no supported widgets available. Ensure that you
                      have at least 1{' '}
                      <strong>{Widgets.BOUGHT_TOGETHER.label}</strong> widget on
                      the <strong>{Pages.PRODUCT.label}</strong> with an{' '}
                      <strong>{Layouts.AMAZON_BOUGHT_TOGETHER.label}</strong>{' '}
                      layout
                    </span>
                  </div>
                )}
              </div>

              <div className="flex flex-row items-center justify-end w-full pt-4 border-t">
                {hasSupportedWidgets ? (
                  <Button
                    onClick={() => {
                      onSave({
                        widgets: selectedWidgetIds,
                      })
                      close()
                    }}
                    size="small"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      close()
                    }}
                    size="small"
                  >
                    Close
                  </Button>
                )}
              </div>
            </div>
          )
        }}
      </WaitForData>
    </Box>
  )
}
