import React from 'react'
import classNames from 'classnames'
import Table from 'components/Table'
import Badge from 'components/Badge'
import { widgetLabelFromType } from 'utils/constants'
import styles from './styles.module.css'

export default function UploadList({ uploads = [] }) {
  return (
    <Table
      headings={['File Name', 'Recommendation Type', 'Upload Status']}
      rowItems={uploads}
      renderRow={(upload, index, rowClassName) => {
        return (
          <tr key={index} className={rowClassName}>
            <td className="px-4 py-2 text-left border">{upload.name}</td>
            <td className="px-4 py-2 text-left border">
              <Badge
                color="yellow"
                className={classNames('uppercase', styles.uploadBadge)}
                spacing="regular"
                textSize="small"
              >
                {widgetLabelFromType(upload.recommendationType)}
              </Badge>
            </td>
            <td className="px-4 py-2 text-center border">
              <Badge
                color={upload.status === 'success' ? 'green' : 'red'}
                className={classNames('uppercase', styles.uploadBadge)}
                spacing="regular"
                textSize="small"
              >
                {upload.status}
              </Badge>
            </td>
          </tr>
        )
      }}
    />
  )
}
