import React, { useState } from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import { getPercentage } from 'utils/numbers'
import { experienceSave } from 'api/experiences'

export default function ConfirmStatusChange({
  experience,
  close,
  totalWeight,
  action,
  canUnpublish,
  shop,
  updateLocalExperiences,
}) {
  const { name } = experience
  const [loading, setLoading] = useState(false)

  async function handleStatusChange() {
    var payload
    if (action === 'published') {
      payload = { status: 'published' }
    } else {
      payload = { status: 'unpublished' }
    }
    setLoading(true)
    var { data: updatedExperiences } = await experienceSave(
      experience.id,
      payload,
      { shop }
    )
    updateLocalExperiences(updatedExperiences)
    setLoading(false)
    close()
  }

  var content
  if (action === 'published') {
    content = (
      <span>
        Are you sure you wish to publish this experience ? This will account for{' '}
        {getPercentage(
          parseFloat(experience.weight),
          totalWeight + parseFloat(experience.weight)
        )}
        % of all experiences.
      </span>
    )
  } else if (action === 'unpublished' && canUnpublish) {
    content = <span>Are you sure you wish to unpublish this experience ?</span>
  } else if (action === 'unpublished' && experience.isDefault) {
    content = <span>Default experience cannot be unpublished.</span>
  } else {
    content = <span>This experience cannot be unpublished.</span>
  }
  return (
    <Box>
      <Box.Heading>{name} - Confirm Status Change</Box.Heading>
      <div className="flex flex-col items-start ">
        {content}
        <div className="flex flex-row items-center justify-end w-full mt-4">
          <Button size="small" variant="bordered" onClick={close}>
            Close
          </Button>

          {action === 'unpublished' && canUnpublish && (
            <Button
              loading={loading}
              size="small"
              className="ml-3"
              onClick={handleStatusChange}
            >
              Unpublish
            </Button>
          )}
          {action === 'published' && (
            <Button
              loading={loading}
              size="small"
              className="ml-3"
              onClick={handleStatusChange}
            >
              Publish
            </Button>
          )}
        </div>
      </div>
    </Box>
  )
}
