import React, { useEffect } from 'react'

export function HubspotContactForm() {
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '22080564',
        formId: '03b9c871-5f13-48b6-a0f2-d5e64b1665ad',
        target: '#hubspotContactForm',
      })
    }
  }, [])

  return (
    <div>
      <div id="hubspotContactForm"></div>
    </div>
  )
}
