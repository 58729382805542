import React from 'react'

import * as FIELD_TYPES from 'constants/fieldTypes'

import StringInput from './String'
import IntegerInput from './Integer'
import CountryInput from './Country'
import CurrencyInput from './Currency'
import TimestampInput from './Timestamp'
import ChoiceInput from './Choice'

export default function Field({ filters, field }) {
  const fieldState = filters.state[field.key]
  const fieldCheckboxId = `filters-checkbox-${field.key}`
  const FilterInputComponent = FIELD_TYPE_TO_FILTER_INPUT_MAP[field.type]

  if (!FilterInputComponent) {
    return null
  }

  return (
    <div className="flex flex-col items-start mb-1">
      <div className="relative flex items-center">
        <div className="flex items-center">
          <input
            id={fieldCheckboxId}
            type="checkbox"
            className="w-3 h-3 text-indigo-600 border-gray-300 rounded cursor-pointer"
            onChange={() => {
              filters.toggleField({
                key: field.key,
                defaultOperator: '',
                defaultValues: [],
              })
            }}
            value={fieldState.active}
            checked={fieldState.active}
          />
        </div>
        <label
          htmlFor={fieldCheckboxId}
          className="pl-2 text-sm font-medium text-gray-900 cursor-pointer"
        >
          {field.title}
        </label>
      </div>
      {fieldState.active && (
        <div className="flex flex-col items-stretch self-stretch mb-2">
          <FilterInputComponent field={field} filters={filters} />
        </div>
      )}
    </div>
  )
}

const FIELD_TYPE_TO_FILTER_INPUT_MAP = {
  [FIELD_TYPES.STRING.value]: StringInput,
  [FIELD_TYPES.INTEGER.value]: IntegerInput,
  [FIELD_TYPES.NUMBER.value]: IntegerInput,
  [FIELD_TYPES.COUNTRY.value]: CountryInput,
  [FIELD_TYPES.CURRENCY.value]: CurrencyInput,
  [FIELD_TYPES.TIMESTAMP.value]: TimestampInput,
  [FIELD_TYPES.CHOICE.value]: ChoiceInput,
}
