import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Icons from 'components/Icons'
import './styles.css'
import useFastLoad from './hooks/useFastLoad'
import { openChat } from 'utils/external'

export default function EnableFastLoadModal({ close, account }) {
  const { state, toggleFastLoad, loading, success } = useFastLoad(account)
  const {
    description,
    icon: Icon,
    button,
  } = getContent({
    state,
    loading,
    success,
    onToggleFastLoad: toggleFastLoad,
    onClose: close,
  })

  return (
    <React.Fragment>
      <Box>
        <div>
          <h2 className="pb-2 mb-6 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
            Fast Load 🚀
          </h2>
          <Box.Section className="flex flex-row items-center px-3 mb-6">
            {Icon && <Icon className="h-full fastLoad__icon" />}
            <div className="flex flex-col items-stretch flex-1 p-8 ml-6">
              {description}
            </div>
          </Box.Section>
        </div>
        <Box.ActionTray>
          <Button
            variant="bordered"
            className="mr-5"
            href="https://glood-ai.notion.site/glood-ai/Glood-AI-Help-Center-fc7e38a64c4a499487cd80e75b59d91b"
            internalLink={false}
            target="_blank"
          >
            Manual Setup
          </Button>
          {button}
        </Box.ActionTray>
      </Box>
    </React.Fragment>
  )
}

function getContent({
  state,
  success,
  loading,
  error,
  onToggleFastLoad,
  onClose,
}) {
  let icon = Icons.FastLoader
  let description = null
  let button = null

  if (success && state === 'enabled') {
    icon = Icons.Done
    description = (
      <React.Fragment>
        <div className="mb-6 text-2xl font-semibold text-primary-800">
          ✅ Congratulations! Fast Load is enabled for your store.
        </div>
        <div className="mb-1 text-sm text-gray-800">
          Fast Load speeds up the widgets on your site without affecting the
          page load speed. <br />
          *Disabling Fast Load can lead to sub-optimal experience on your store.
        </div>
      </React.Fragment>
    )
    button = (
      <Button
        variant="bordered"
        className="mr-5"
        type="button"
        onClick={onClose}
      >
        Close
      </Button>
    )
  } else if ((loading && state === 'enabled') || state === 'disabling') {
    // User is trying to disable fast load.
    description = (
      <div className="mb-6 text-xl font-semibold text-primary-800">
        Disabling Fast Load... This can degrade the loading time of widgets on
        your site.
      </div>
    )
    button = (
      <Button
        variant="bordered"
        className="mr-5"
        type="button"
        onClick={onToggleFastLoad}
        loading={loading}
      >
        Disable Automatically
      </Button>
    )
  } else if ((loading && state === 'disabled') || state === 'enabling') {
    // User is trying to enable fast load
    description = (
      <div className="mb-6 text-xl font-semibold text-primary-800">
        We are enabling fast load for you. Please hold on...
      </div>
    )
    button = (
      <Button
        variant="bordered"
        className="mr-5"
        type="button"
        onClick={onToggleFastLoad}
        loading={loading}
      >
        Enable Automatically
      </Button>
    )
  } else if (state === 'enabled') {
    // Fast load is currently enabled
    icon = Icons.FastLoader
    description = (
      <React.Fragment>
        <div className="mb-6 text-2xl font-semibold text-primary-800">
          ✅ Fast Load is enabled for your store.
        </div>
        <div className="mb-1 text-sm text-gray-800">
          Fast Load speeds up the widgets on your site without affecting the
          page load speed. Disabling Fast Load can lead to sub-optimal
          experience on your store.
        </div>
      </React.Fragment>
    )
    button = (
      <Button
        variant="bordered"
        className="mr-5"
        type="button"
        onClick={onToggleFastLoad}
        loading={loading}
      >
        Disable Automatically
      </Button>
    )
  } else if (state === 'disabled') {
    // Fast load is currently disabled
    icon = Icons.FastLoader
    description = (
      <React.Fragment>
        <div className="mb-6 text-2xl font-semibold text-gray-800">
          Enable Fast Load to improve widget performance
        </div>
        <div className="mb-1 text-sm text-gray-600">
          We'll add a script to your theme which will ensure that the widgets
          load faster on your store. This will not impact the performance of the
          rest of the store because our Javascript will be loaded
          asynchronously.
        </div>
      </React.Fragment>
    )
    button = (
      <Button
        className="mr-5"
        type="button"
        onClick={onToggleFastLoad}
        loading={loading}
      >
        Enable Automatically
      </Button>
    )
  } else if (state === 'failed') {
    // There was a failure. Keep same for enabling or disabling.
    icon = Icons.Failure
    description = (
      <React.Fragment>
        <div className="mb-3 text-2xl font-semibold text-red-800">
          Could not toggle Fast Load. Please contact support
        </div>
        <div className="mb-1 text-sm text-gray-800">
          You last status change of Fast Load could not be completed. Please
          contact support for assistance.
        </div>
        <Button
          className="self-start"
          size="regular"
          color="red"
          onClick={openChat}
        >
          Contact Us
        </Button>
      </React.Fragment>
    )
  }

  return {
    icon,
    description,
    button,
  }
}
