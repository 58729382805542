import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

const COLOR_TO_CLASS_MAP = {
  neutral: 'text-gray-800 bg-gray-200',
  purple: 'bg-purple-800 text-purple-200',
  purpleLight: 'bg-purple-300 text-gray-800',
  red: 'bg-red-600 text-white',
  green: 'bg-green-600 text-white',
  yellow: 'bg-yellow-200 text-gray-800',
  primary: 'bg-primary-500 text-white',
  teal: 'bg-teal-400 text-white',
}

const SPACING_TO_CLASS_MAP = {
  tight: 'px-2',
  regular: 'px-3 py-1',
  loose: 'px-4 py-2',
}

const TEXT_SIZE_TO_CLASS_MAP = {
  small: styles.textSmall,
  regular: 'text-xs',
}

export default function Badge({
  children,
  color,
  spacing,
  textSize,
  className,
}) {
  return (
    <div
      className={classNames(
        'text-xs font-semibold tracking-wide rounded-full flex flex-row items-center',
        COLOR_TO_CLASS_MAP[color],
        SPACING_TO_CLASS_MAP[spacing],
        TEXT_SIZE_TO_CLASS_MAP[textSize],
        className
      )}
    >
      {children}
    </div>
  )
}

Badge.defaultProps = {
  color: 'neutral',
  className: '',
  spacing: 'tight',
  textSize: 'regular',
}
