import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import useKeypress from 'react-use-keypress'

import CountriesSlider from 'components/CountriesSlider'
import ProductsSlider from 'components/ProductsSlider'
import Icons from 'components/Icons'
import WithProducts from 'components/WithProducts'
import SlideOver from 'components/SlideOver'
import { countryLabelFromCode } from 'components/CountriesSlider'
import { _uniqBy, _find } from 'utils/lodash'
import * as ShopifyUtils from 'utils/shopify'

export default function ConditionListInput({
  value = [],
  onChange,
  type,
  readOnly,
  controlProps = {},
  renderOnlyList,
  renderOnlyInput,
}) {
  const { placeholder } = controlProps
  const { Item, Input, getItemId } = TYPE_TO_UTILITY_MAP[type]

  const saveItems = useCallback(
    (cb) => {
      if (readOnly) {
        return
      }

      const newItems = cb(value)
      onChange(newItems)
    },
    [value, onChange, readOnly]
  )

  let listContent = (
    <ul className="flex flex-row flex-wrap items-stretch space-y-1.5 space-x-1.5 mt-1 ml-14">
      {value.map((item, index) => {
        return (
          <Item
            key={getItemId(item)}
            index={index}
            item={item}
            items={value}
            saveItems={saveItems}
            readOnly={readOnly}
          />
        )
      })}
    </ul>
  )

  if (renderOnlyList) {
    return listContent
  }

  let inputContent = readOnly ? null : (
    <Input
      items={value}
      saveItems={saveItems}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  )
  if (renderOnlyInput) {
    return inputContent
  }

  return (
    <div className="flex flex-row items-center">
      {inputContent}
      {listContent}
    </div>
  )
}

const TYPE_TO_UTILITY_MAP = {
  string: {
    Item: function ({ item, saveItems, index, readOnly }) {
      return (
        <li
          className={classNames(
            'flex items-center group justify-center py-1 px-2.5 text-xs text-black rounded bg-primary-200',
            {
              'mt-1.5': index === 0,
              'hover:text-white hover:bg-primary': !readOnly,
            }
          )}
        >
          {item}
          {!readOnly && (
            <Icons.Cross
              className="hidden w-3 ml-2 cursor-pointer stroke-current group-hover:flex"
              onClick={() =>
                saveItems((prev) => prev.filter((i) => i !== item))
              }
            />
          )}
        </li>
      )
    },

    Input: function ({ saveItems, placeholder }) {
      const [newItemQuery, setNewItemQuery] = useState('')
      const [active, setActive] = useState(false)

      function saveInput(value) {
        if (!value) {
          return
        }

        saveItems((prev) => _uniqBy([...prev, value]))
        setNewItemQuery('')
      }

      useKeypress('Enter', () => saveInput(newItemQuery))

      return (
        <div className="relative flex flex-row mr-2">
          <div className="relative flex flex-col">
            <input
              type="text"
              value={newItemQuery}
              onChange={(e) => setNewItemQuery(e.target.value)}
              className="font-semibold appearance-none block w-full px-2 py-1.5 border focus:outline-none ease-in-out rounded shadow-sm border-gray-300 placeholder-gray-400 focus:ring-primary focus:border-primary leading-none text-xs"
              placeholder={placeholder}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
            />
            {active && (
              <span className="absolute bottom-0 pt-1 text-gray-600 translate-y-full text-xxs">
                Press "Enter" to save new
              </span>
            )}
          </div>
          <span
            className="flex items-center justify-center w-5 h-5 p-1 my-auto ml-2 text-lg border border-gray-200 rounded-full cursor-pointer hover:text-white hover:bg-primary hover:border-primary"
            onClick={() => saveInput(newItemQuery)}
          >
            +
          </span>
        </div>
      )
    },

    getItemId: function (item) {
      return item
    },
  },

  variant: {
    Item: function ({ items, item, saveItems, index, readOnly }) {
      return (
        <WithProducts productIds={items.map((i) => i.id)}>
          {({ products }) => {
            const product = _find(products, { id: item.id })
            const { image, variants } = product
            const selectedVariants =
              item.variantIds.length > 0
                ? variants.filter((v) => item.variantIds.includes(v.id))
                : variants

            return (
              <li
                className={classNames(
                  'flex flex-row group items-start justify-center py-2 px-2 text-xs text-black rounded  bg-primary-200 relative',
                  {
                    'mt-1.5': index === 0,
                    'hover:text-white hover:bg-primary pr-8': !readOnly,
                  }
                )}
                key={product.id}
              >
                <img
                  src={ShopifyUtils.optimisedImage({
                    baseUrl: image,
                    size: 160,
                  })}
                  alt={product.title}
                  className="object-contain mr-2 w-14"
                />
                <div className="flex flex-col items-start">
                  <span className="mb-1 text-xs font-semibold">
                    {product.title}
                  </span>
                  {selectedVariants.map((v) => (
                    <span className="text-xxs" key={v.id}>
                      {v.title}
                    </span>
                  ))}
                </div>
                {!readOnly && (
                  <Icons.Cross
                    className="absolute hidden w-4 ml-2 cursor-pointer stroke-current group-hover:flex top-1 right-1"
                    onClick={() =>
                      saveItems((prev) => prev.filter((i) => i !== item))
                    }
                  />
                )}
              </li>
            )
          }}
        </WithProducts>
      )
    },

    Input: function ({ items, saveItems }) {
      const [sliderVisible, setSliderVisible] = useState(false)

      return (
        <React.Fragment>
          <div
            className="text-xs font-semibold cursor-pointer text-primary hover:underline"
            onClick={() => setSliderVisible(true)}
          >
            + Choose Products
          </div>
          <SlideOver
            open={sliderVisible}
            name="ProductSelection_ConditionListInput"
            onClose={() => setSliderVisible(false)}
          >
            <ProductsSlider
              onClose={() => setSliderVisible(false)}
              initialSelectedProducts={items}
              onSelect={(selectedProducts = []) => {
                saveItems(() =>
                  selectedProducts.map((selectedProduct) => ({
                    id: selectedProduct.id,
                    variantIds:
                      selectedProduct.variantsCount ===
                      selectedProduct.variantIds.length
                        ? []
                        : selectedProduct.variantIds,
                  }))
                )
              }}
              allowMultipleProducts={true}
              allowMultipleVariants={true}
            />
          </SlideOver>
        </React.Fragment>
      )
    },

    getItemId: function (item) {
      return item.id
    },
  },

  country: {
    Item: function ({ item, saveItems, index, readOnly }) {
      return (
        <li
          className={classNames(
            'flex items-center group justify-center py-1 px-2.5 text-xs text-black rounded bg-primary-200 ',
            {
              'mt-1.5': index === 0,
              'hover:text-white hover:bg-primary': !readOnly,
            }
          )}
        >
          {countryLabelFromCode(item)}
          {!readOnly && (
            <Icons.Cross
              className="hidden w-3 ml-2 cursor-pointer stroke-current group-hover:flex"
              onClick={() =>
                saveItems((prev) => prev.filter((i) => i !== item))
              }
            />
          )}
        </li>
      )
    },

    Input: function ({ items, saveItems }) {
      const [sliderVisible, setSliderVisible] = useState(false)

      return (
        <React.Fragment>
          <div
            className="text-xs font-semibold cursor-pointer text-primary hover:underline"
            onClick={() => setSliderVisible(true)}
          >
            + Choose Countries
          </div>
          <SlideOver
            open={sliderVisible}
            onClose={() => setSliderVisible(false)}
          >
            <CountriesSlider
              onClose={() => setSliderVisible(false)}
              initialSelectedCountries={items}
              onSelect={(selected = []) => {
                saveItems(() => selected)
              }}
              allowMultiple={true}
            />
          </SlideOver>
        </React.Fragment>
      )
    },

    getItemId: function (item) {
      return item
    },
  },
}
