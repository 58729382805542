import React from 'react'

export default function Banner({ settings }) {
  const {
    banner: {
      backgroundColor,
      textColor,
      bannerText: { default: bannerText },
      fontSize,
      alignment,
      fontWeight,
      textDecoration,
      fontStyle,
    },
  } = settings
  return (
    <div
      className="py-4 px-7"
      style={{
        backgroundColor,
        color: textColor,
        fontSize,
        textAlign: alignment,
      }}
    >
      <span
        className=""
        style={{
          fontWeight,
          textDecoration,
          fontStyle,
        }}
      >
        {bannerText}
      </span>
    </div>
  )
}
