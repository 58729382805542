import React from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { useHistory } from 'react-router-dom'

import Loader from 'components/Loader'
import { _find } from 'utils/lodash'

export default function ResourceListTable({ resourceList }) {
  const {
    listView: { table: tableInstance },
    order,
    resourceType,
    query,
    options,
  } = resourceList
  const history = useHistory()
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance
  const orderableColumnIds = Object.keys(order.fields)

  return (
    <div
      className={classNames('rounded-md ', {
        'opacity-25': query?.isLoading,
      })}
    >
      {/* // apply the table props */}
      <table {...getTableProps()} className="w-full mb-28">
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => {
                    const allowSort =
                      orderableColumnIds.indexOf(column.id) !== -1
                    const sortField = _find(order.state, { field: column.id })
                    const { onClick, ...headerProps } = column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth || '150px',
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    })

                    return (
                      // Apply the header cell props
                      <th
                        {...headerProps}
                        className={classNames(
                          'px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-800 uppercase bg-gray-100 border-b border-gray-400 border-r',
                          {
                            'cursor-pointer group hover:bg-gray-200': allowSort,
                          }
                        )}
                        onClick={(e) => {
                          typeof onClick === 'function' && onClick(e)
                          allowSort && order.toggleOrderBy(column.id)
                        }}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                        {allowSort && (
                          <React.Fragment>
                            <span className="ml-2 text-xs">
                              {sortField
                                ? sortField.value === 'desc'
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                            </span>
                            {!sortField && (
                              <span className="normal-case opacity-0 text-xxs group-hover:opacity-100">
                                🔽
                              </span>
                            )}
                          </React.Fragment>
                        )}
                      </th>
                    )
                  })
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()} className="bg-white">
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  className="group"
                  onClick={() => {
                    if (
                      options?.onRowClick &&
                      typeof options.onRowClick === 'function'
                    ) {
                      options.onRowClick(row.original)
                    } else {
                      history.push(
                        `/${pluralize(resourceType)}/${
                          row.original.slug
                            ? row.original.slug
                            : row.original.id
                        }${window.location.search}`
                      )
                    }
                  }}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-4 py-3 text-sm font-medium leading-5 text-black border-b border-r border-gray-200 cursor-pointer whitespace-nowrap group-hover:bg-gray-100"
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {rows.length === 0 && !query.isLoading ? (
        <div className="flex items-center justify-center w-full -mt-28 h-60 bg-gray-50">
          <span className="text-3xl font-semibold text-gray-500">
            No data to display
          </span>
        </div>
      ) : (
        (rows.length === 0 || query.isLoading) && (
          <div className="flex items-center justify-center w-full -mt-28 h-60 bg-gray-50">
            <Loader />
          </div>
        )
      )}
    </div>
  )
}
