import React from 'react'
import numeral from 'numeral'
import _get from 'lodash.get'
import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import styles from './styles.module.css'
import Button from 'components/Button'
import { openChat } from 'utils/external'
import classNames from 'classnames'
import { trackEvent } from 'utils/analytics'
import { hasSeenReviewModalRecently } from 'utils/account'
import { report } from 'utils/reporting'

const REVIEW_LINK =
  'https://apps.shopify.com/recommendation-kit#modal-show=ReviewListingModal'

export default function ReviewModal({ store, account, analytics, shop }) {
  const openModal = useOpenModal()

  React.useEffect(() => {
    const isRkSupport = _get(account, 'data.isRkSupport')
    const reviewGiven = _get(store, 'data.onboarding.reviewGiven', false)
    const showReviewBox = _get(account, 'data.showReviewBox')
    const canAskForReview =
      ['basic', 'professional', 'dormant', 'unlimited', 'shopify_plus'].indexOf(
        _get(account, 'data.shop.planName')
      ) !== -1

    if (
      !isRkSupport &&
      showReviewBox &&
      canAskForReview &&
      !reviewGiven &&
      !hasSeenReviewModalRecently(store.data)
    ) {
      setTimeout(() => {
        const punchline = getReviewPunchline({ analytics })
        punchline &&
          openModal(
            <Content punchline={punchline} store={store} account={account} />
          )
      }, 2000)
    }
  }, [])

  return null
}

function Content({ punchline, store }) {
  function onReviewClick() {
    trackEvent({
      category: 'Review Modal',
      action: 'Click Review Button',
    })
  }

  React.useEffect(() => {
    trackEvent({
      category: 'Review Modal',
      action: 'Seen',
    })

    try {
      store.update({
        onboarding: {
          ...store.data.onboarding,
          reviewModalLastSeenAt: new Date().getTime(),
        },
      })
    } catch (err) {
      report(err)
    }
  }, [])

  return (
    <div className="bg-white flex flex-1 w-full flex-col items-center rounded-sm overflow-hidden p-8">
      <span className="text-3xl lg:text-4xl mb-2 text-center font-medium text-gray-800 mx-10 lg:mx-24 leading-snug">
        {punchline}
      </span>
      <span className="my-6 px-3 text-center text-base lg:text-lg lg:w-4/5 text-gray-800">
        We aim to help your store grow further in sales with our AI powered
        on-site recommendations 🚀🚀🚀. Feel free to{' '}
        <span
          className="font-semibold underline cursor-pointer"
          onClick={openChat}
        >
          Chat With Us 💬
        </span>{' '}
        incase you have any queries or facing issues. <br />
        We are always <span className="font-semibold">here to help you</span>.
      </span>
      <span className="mb-10 px-3 text-center text-xl lg:text-2xl lg:w-4/5">
        Please do leave us a review, it will help our team immensely! <br />
        🙏🙏🙏
      </span>
      <AnimatedStars onClick={onReviewClick} />
      <Button
        size="large"
        className="mt-2 mb-2 p-6 capitalize"
        onClick={onReviewClick}
        href={REVIEW_LINK}
      >
        🚀 Click To Leave Review 🚀
      </Button>
      <AnimatedStars onClick={onReviewClick} />
    </div>
  )
}

function AnimatedStars({ className = 'h-16 w-16 mr-4', onClick }) {
  const list = [1, 2, 3, 4, 5]

  return (
    <a
      className="flex flex-row items-center my-4 cursor-pointer"
      onClick={onClick}
      href={REVIEW_LINK}
    >
      {list.map((i) => (
        <Icons.Star className={classNames(className, styles.star)} key={i} />
      ))}
    </a>
  )
}

function getReviewPunchline({ analytics }) {
  return (analytics.data || []).reduce((previousPunchLine, dataPoint = {}) => {
    const { value, currency, title } = dataPoint
    if (previousPunchLine || !dataPoint) {
      return previousPunchLine
    }

    const numberFormat = currency ? '0.0a' : '0a'
    let line
    if (title === 'Attributed Revenue' && value > 100) {
      line = (
        <span>
          Your store has made sales worth of{' '}
          <span className="font-bold text-black">
            ${numeral(value).format(numberFormat)}
          </span>{' '}
          via Personalised Recommendations 💸
        </span>
      )
    } else if (title === 'Products Sold' && value > 10) {
      line = (
        <span>
          <span className="font-bold text-black">
            {numeral(value).format(numberFormat)} products
          </span>{' '}
          have been sold via Personalised Recommendation on your store till now
          💸
        </span>
      )
    } else if (title === 'Page Views' && value > 0) {
      line = (
        <span>
          Personalised Recommendation widgets have generated{' '}
          <span className="font-bold text-black">
            {numeral(value).format(numberFormat)} additional page views
          </span>{' '}
          for your store 📈
        </span>
      )
    }

    return line
  }, '')
}
