import React from 'react'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import CodeEditor from 'components/CodeEditor'

export default function AdminPanelJS({ data, onSave, isSaving }) {
  const [js, setJs] = React.useState(data.js || '')
  const openToast = useToast()
  function validateAndSave(js) {
    try {
      const hook = new Function(js)
      onSave({ js })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error updating! Please ensure JS is valid',
      })
    }
  }
  return (
    <div className="flex flex-col items-start flex-1 w-full">
      <div className="flex flex-row flex-wrap w-full notranslate">
        <CodeEditor
          placeholder="You can add custom JS for the shop here."
          mode="javascript"
          value={js}
          onChange={setJs}
          name="shop_js_editor"
        />
      </div>
      <Button
        size="small"
        loading={isSaving}
        onClick={() => validateAndSave(js)}
      >
        Save
      </Button>
    </div>
  )
}
