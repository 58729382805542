import { useQueryParams } from 'hooks/router'

import usePagination from './usePagination'
import useOrderBy from './useOrderBy'
import useFilters from './useFilters'
import useListData from './useListData'
import useListView from './useListView'

export default function useResourceList({
  resourceType,
  initialView,
  schema: resourceSchema,
  CustomTableCell,
  options = {},
}) {
  const queryParams = useQueryParams()
  const pagination = usePagination()
  const order = useOrderBy({
    queryParams,
    initialView,
    resourceType,
    resourceSchema,
    onChange: () => pagination.setOffset(0),
  })
  const filters = useFilters({
    queryParams,
    initialView,
    resourceType,
    resourceSchema,
    options: options.filters,
    onChange: () => pagination.setOffset(0),
  })

  const listData = useListData({
    resourceType,
    resourceSchema,
    pagination,
    filters,
    order,
  })
  const listView = useListView({
    resourceType,
    resourceSchema,
    initialView,
    CustomTableCell,
    data: listData.queryData || listData.resolvedData,
  })

  return {
    filters,
    pagination,
    order,
    listView,
    resourceType,
    resourceSchema,
    query: listData.query,
    queryData: listData.queryData,
    resolvedData: listData.resolvedData,
    queryStatus: listData.queryStatus,
    options,
  }
}
