import React from 'react'
import classNames from 'classnames'

export default function Callout({
  children,
  type,
  textSize,
  noHoverEffect,
  className,
}) {
  return (
    <div
      className={classNames(
        'border-2 rounded flex flex-row items-center p-1',
        TYPE_TO_CLASS_MAP[type],
        TEXT_SIZE_TO_CLASS_MAP[textSize],
        { 'opacity-75 hover:opacity-100': !noHoverEffect },
        className
      )}
    >
      {children}
    </div>
  )
}

const TYPE_TO_CLASS_MAP = {
  info: 'border-primary-600 text-primary',
  error: 'border-red-600 text-red-600',
}

const TEXT_SIZE_TO_CLASS_MAP = {
  small: 'text-xs',
  regular: 'text-sm',
}

Callout.defaultProps = {
  type: 'info',
  textSize: 'regular',
  noHoverEffect: false,
}
