const MOBILE_APP = {
  value: 'mobile',
  label: 'Mobile App',
  score: 1,
}
const ACR = {
  value: 'acr',
  label: 'Abandoned Cart Recovery',
  score: 0,
}

const THANK_YOU_PAGE = {
  value: 'thank_you_page',
  label: 'Thank You Page',
  score: 2,
}

const PRODUCT_REVIEWS = {
  value: 'product_reviews',
  label: 'Product Reviews App',
  score: 3,
}

const SWATCH = {
  value: 'swatch',
  label: 'Color Swatches',
  score: 4,
}

const PAGE_BUILDER = {
  value: 'page_builder',
  label: 'Page Builders',
  score: 5,
}

const WISHLIST = {
  value: 'wishlist',
  label: 'Wishlist',
  score: 6,
}

const RETURNS = {
  value: 'returns',
  label: 'Returns',
  score: 7,
}

export {
  PAGE_BUILDER,
  MOBILE_APP,
  PRODUCT_REVIEWS,
  SWATCH,
  THANK_YOU_PAGE,
  WISHLIST,
  ACR,
  RETURNS,
}
