import React, { useState, useMemo, useEffect } from 'react'
import classNames from 'classnames'

import WithProducts from 'components/WithProducts'
import {
  PROMOTION_RECOMMENDATION_TYPES,
  DISCOUNT_TYPES,
} from 'constants/promotions'
import RenderMoney from 'components/RenderMoney'
import { _get, _find, _pick } from 'utils/lodash'
import { htmlToText, trimString } from 'utils/string'
import * as ShopifyUtils from 'utils/shopify'

export default function PromotionBuilderPreviewSingleProductUpsell({
  section,
  accountData,
}) {
  const {
    settings: { recommendationRules, recommendationType },
  } = section

  let productIdsForPreview = []
  if (recommendationType === PROMOTION_RECOMMENDATION_TYPES.MANUAL.value) {
    productIdsForPreview = [_get(recommendationRules, 'productId')].filter(
      (i) => i
    )
  }

  return (
    <WithProducts productIds={productIdsForPreview}>
      {({ products }) => {
        return products && products.length > 0 ? (
          <ProductDetails
            product={products[0]}
            accountData={accountData}
            section={section}
            key={products[0].id}
          />
        ) : null
      }}
    </WithProducts>
  )
}

function ProductDetails({ product, accountData, section }) {
  const {
    settings: { discount = {}, recommendationRules = {}, recommendationType },
    translations: { freeText, declineButtonText },
  } = section
  const { currency, currencyFormat } = accountData
  const { image, title, variants, bodyHtml } = product
  const {
    productTitle: titleFromRules,
    productDescription: descriptionFromRules,
    allowQuantityChange,
  } = recommendationRules
  const defaultQuantity = parseInt(recommendationRules.defaultQuantity) || 1
  const minQuantity = parseInt(recommendationRules.minQuantity) || 1
  const maxQuantity = parseInt(recommendationRules.maxQuantity) || 1

  const allowedVariants = variants
    ? recommendationType === 'manual' &&
      _get(recommendationRules, 'variantIds', []).length > 0
      ? variants.filter((v) =>
          _get(recommendationRules, 'variantIds', []).includes(v.id)
        )
      : variants
    : []

  const description = useMemo(
    () => htmlToText(descriptionFromRules || bodyHtml),
    [descriptionFromRules, bodyHtml]
  )

  const [quantity, setQuantity] = useState(parseInt(defaultQuantity) || 1)
  const [selectedVariantId, setSelectedVariantId] = useState(
    allowedVariants[0].id
  )

  useEffect(() => {
    setQuantity(parseInt(defaultQuantity) || 1)
  }, [defaultQuantity])

  if (!product || !selectedVariantId) {
    return null
  }

  const selectedVariant =
    _find(allowedVariants, { id: selectedVariantId }) || allowedVariants[0]
  const originalPrice = selectedVariant.price * quantity
  const discountedPrice =
    discount.value > 0
      ? getDiscountedAmount({ original: originalPrice, discount })
      : null

  const allowedQuantities = buildAllowedQuantities({
    min: allowQuantityChange ? minQuantity || defaultQuantity : defaultQuantity,
    max: allowQuantityChange ? maxQuantity || defaultQuantity : defaultQuantity,
  })

  const totalPriceFormatted = ShopifyUtils.formatMoney({
    currency,
    currencyFormat,
    price: (discountedPrice != null ? discountedPrice : originalPrice) * 1.13,
  })

  let buyButtonText = section.translations.buyButtonText
  if (discountedPrice == 0) {
    buyButtonText = `Add now • ${freeText}`
  } else {
    if (TOTAL_REGEX.test(buyButtonText)) {
      buyButtonText = buyButtonText.replace(TOTAL_REGEX, totalPriceFormatted)
    } else {
      buyButtonText = `${buyButtonText} · ${totalPriceFormatted}`
    }
  }

  return (
    <div className="flex flex-col max-w-[1024px] px-8 w-full mx-auto bg-white mb-5">
      <div className="flex flex-row items-start">
        <div className="flex flex-col items-stretch flex-1 mr-8">
          {image ? (
            <img
              src={ShopifyUtils.optimisedImage({
                baseUrl: image,
                size: 460,
              })}
              alt={titleFromRules || title}
              className="w-full"
            />
          ) : (
            <div className="w-full pt-[80%] bg-gray-100"></div>
          )}
        </div>
        <div className="flex flex-col items-stretch flex-1">
          <span className="mb-3 text-xl font-semibold text-gray-800">
            {titleFromRules || title}
          </span>
          <div className="flex flex-row items-start mb-3">
            <RenderMoney
              price={originalPrice}
              className={classNames('mr-2', {
                'text-gray-600 line-through': discountedPrice != null,
                'text-gray-800': discountedPrice == null,
              })}
            />
            {discountedPrice != null &&
              (parseInt(discountedPrice) > 0 ? (
                <RenderMoney
                  price={discountedPrice}
                  className="font-semibold text-red-800"
                />
              ) : (
                freeText
              ))}
          </div>
          <div className="mb-4 text-sm text-gray-700">
            {trimString(description, SHORT_DESCRIPTION_CHARACTER_LIMIT)}
          </div>
          <React.Fragment>
            <span className="mb-1 text-xs text-gray-600">Choose variant</span>
            <select
              className="py-2 mb-4 bg-white border border-gray-200 rounded cursor-pointer"
              value={selectedVariantId}
              onChange={(e) => setSelectedVariantId(parseInt(e.target.value))}
            >
              {allowedVariants.map((variant) => (
                <option value={variant.id} key={variant.id}>
                  {variant.title}
                </option>
              ))}
            </select>
          </React.Fragment>
          <span className="mb-1 text-xs text-gray-600">Quantity</span>
          <select
            className="py-2 mb-6 bg-white border border-gray-200 rounded cursor-pointer"
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
          >
            {allowedQuantities.map((quantity) => (
              <option value={quantity} key={quantity}>
                {quantity}
              </option>
            ))}
          </select>
          <div className="flex flex-col items-stretch py-4 space-y-2 text-sm border-t border-b border-gray-200">
            <div className="flex flex-row justify-between">
              <span className="text-gray-700">Subtotal</span>
              <RenderMoney
                price={
                  discountedPrice != null ? discountedPrice : originalPrice
                }
                className="font-semibold text-gray-800"
                currFormat={'{{amount}}'}
              />
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-gray-700">Shipping</span>
              <span className="font-semibold text-gray-800">Free</span>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-gray-700">Taxes</span>
              <RenderMoney
                price={
                  (discountedPrice != null ? discountedPrice : originalPrice) *
                  0.13
                }
                className="font-semibold text-gray-800"
                currFormat={'{{amount}}'}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between mt-4 mb-2">
            <span className="text-base font-medium text-gray-800">Total</span>
            <span
              className="text-lg font-semibold text-gray-800"
              dangerouslySetInnerHTML={{
                __html: totalPriceFormatted,
              }}
            ></span>
          </div>
          <div className="flex flex-col items-stretch mt-3">
            <button
              className="py-3 border border-blue-600 hover:border-blue-700 hover:bg-blue-700 mb-1.5 text-sm font-semibold text-center text-white bg-blue-600 rounded"
              dangerouslySetInnerHTML={{
                __html: buyButtonText,
              }}
            ></button>
            <button className="py-3 text-sm font-semibold text-center text-blue-600 border border-gray-200 rounded hover:bg-gray-10">
              {declineButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const SHORT_DESCRIPTION_CHARACTER_LIMIT = 200
const TOTAL_REGEX = /{{(\s+)?total(\s+)?}}/g

function getDiscountedAmount({ original, discount }) {
  const originalAmount = parseFloat(original)
  if (!discount || !discount.type) {
    return original
  }

  let discountAmount = originalAmount
  if (discount.type === DISCOUNT_TYPES.PERCENTAGE.value) {
    discountAmount = originalAmount - (originalAmount / 100) * discount.value
  } else if (discount.type === DISCOUNT_TYPES.FLAT.value) {
    discountAmount = Math.max(0, originalAmount - parseFloat(discount.value))
  }

  return discountAmount
}

function buildAllowedQuantities({ min = 1, max = 1 }) {
  const arr = []

  let i = min
  while (i <= max) {
    arr.push(i)
    i++
  }

  return arr
}
