import React from 'react'
import _get from 'lodash.get'
import Box from 'components/Box'
import CampaignDetails from './CampaignDetails'
import CampaignSelect from './CampaignSelect'
import ChannelSelect from './ChannelSelect'
import Editor from './Editor'

export default function CreateCampaign({
  account,
  campaigns,
  close: closeModal
}) {
  const [selectedCampaign, setSelectedCampaign] = React.useState(null)
  const [selectedChannel, setSelectedChannel] = React.useState(null)
  const [savedCampaign, setSavedCampaign] = React.useState(false)

  let content = null
  if (!selectedCampaign) {
    content = (
      <CampaignSelect
        onSelect={setSelectedCampaign}
        campaignsEnabled={_get(account, 'plan.campaignsEnabled', [])}
      />
    )
  } else if (!selectedChannel) {
    content = (
      <ChannelSelect
        onSelect={setSelectedChannel}
        campaign={selectedCampaign}
      />
    )
  } else if (!savedCampaign) {
    let editorCampaign = {
      name: selectedCampaign.label,
      recommendationType: selectedCampaign.value,
      channel: 'email',
      channelSubType: selectedChannel.value
    }

    content = (
      <Editor
        account={account}
        campaign={editorCampaign}
        channel={selectedChannel}
        onResetCampaign={() => {
          setSelectedCampaign(null)
          setSelectedChannel(null)
        }}
        onResetChannel={() => {
          setSelectedChannel(null)
        }}
        onCampaignSaved={campaign => {
          setSavedCampaign(campaign)
        }}
      />
    )
  } else {
    content = (
      <CampaignDetails
        account={account}
        campaign={savedCampaign}
        onCampaignActivated={campaign => {
          campaigns.update(
            {
              ...campaigns.data,
              campaigns: [campaign, ...campaigns.data.campaigns]
            },
            { local: true }
          )
          closeModal()
        }}
      />
    )
  }

  return <Box className="flex flex-col items-stretch">{content}</Box>
}

export function SectionHeading({ children }) {
  return (
    <h2 className="flex flex-row items-center justify-between pb-2 mb-6 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
      {children}
    </h2>
  )
}
