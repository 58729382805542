import { useState, useMemo, useRef, useCallback } from 'react'

import { _uniqBy } from 'utils/lodash'

export default function useCountriesSlider({
  allCountries,
  initialSelectedCountries,
  allowMultiple,
  onSelect,
  onClose,
}) {
  const [query, setQuery] = useState('')
  const [selectedCountries, setSelectedCountries] = useState(
    initialSelectedCountries
  )
  const queryInput = useRef(null)

  const countries = allCountries.filter((country) => {
    const { label } = country
    return label.toLowerCase().indexOf(query.toLowerCase()) !== -1
  }, [])

  const clearQuery = useCallback(() => {
    setQuery('')
    if (queryInput && queryInput.current) {
      setTimeout(() => queryInput.current.focus())
    }
  }, [queryInput])

  const toggleCountrySelection = useCallback(
    (value) => {
      const isSelected = selectedCountries.includes(value)
      if (isSelected) {
        setSelectedCountries(selectedCountries.filter((v) => v !== value))
      } else {
        if (allowMultiple) {
          setSelectedCountries(_uniqBy([value, ...selectedCountries]))
        } else {
          setSelectedCountries([value])
        }
      }
    },
    [selectedCountries, setSelectedCountries, allowMultiple]
  )

  const handleSelectionComplete = useCallback(() => {
    if (typeof onSelect === 'function') {
      onSelect(selectedCountries)
    }
    setTimeout(onClose)
  }, [selectedCountries, onClose, onSelect])

  console.log('Initial seelcted counties', selectedCountries)
  return useMemo(
    () => ({
      query,
      setQuery,
      clearQuery,
      queryInput,
      countries,
      toggleCountrySelection,
      handleSelectionComplete,
      selectedCountries,
    }),
    [
      setQuery,
      query,
      countries,
      clearQuery,
      toggleCountrySelection,
      handleSelectionComplete,
      selectedCountries,
    ]
  )
}
