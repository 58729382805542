export const LIVE_SUPPORT = {
  value: 'live_support',
  label: 'Live Chat Support',
  freePlanLabel: 'Email Support',
  description: 'Talk to our support team via in-app live chat',
}

export const BASIC_RECOMMENDATIONS = {
  value: 'basic_rec',
  label: 'Basic Recommendations',
  description:
    'Recommend products automatically or set recommendations for each product manually.',
}

export const ADVANCED_RECOMMENDATIONS = {
  value: 'advanced_reco',
  label: 'Custom Recommendations',
  description: 'Build a custom recommendation model based on store specifics.',
}

export const CUSTOM_TEMPLATES = {
  value: 'custom_templates',
  label: 'Custom Templates',
  description: 'Custom Templates allows you to add and edit the templates',
}

export const CUSTOM_CSS = {
  value: 'custom_css',
  label: 'Custom CSS',
  description:
    'Custom CSS allows you edit visual aspects of the widgets using CSS code.',
}

export const SEGMENTATION = {
  value: 'segments',
  label: 'User Segmentation',
  description:
    'Display widgets based on user attributes like first time visitor, is_customer, returning visitor etc.',
}

export const DISCOUNTS = {
  value: 'discounts',
  label: 'Bundle Discounts',
  description: 'Add discount to frequently bought together bundles.',
}

export const PRODUCT_DISCOUNTS = {
  value: 'product_discounts',
  label: 'Product Discounts',
  description: 'Add discounts to recommended products.',
}

export const EMAIL_RECOMMENDATIONS = {
  value: 'emails',
  label: 'Personalized Email Recommendations',
  description:
    'Display personalized recommendation widgets in your email campaigns.',
}

export const RECOMMENDATION_RULES = {
  value: 'recommendation_rules',
  label: 'Rule Based Recommendations',
  description:
    'Configure recommendation rules based on product tags, product_type, collection or vendor.',
}

export const TRANSLATIONS = {
  value: 'translations',
  label: 'Multi-language Support',
  description: 'Display product recommendations in multiple languages',
}

export const GEO_TARGETING = {
  value: 'geo_targeting',
  label: 'Geo Targeting',
  description: 'Recommend products to visitors based on their country.',
}

export const SEARCH = {
  value: 'search',
  label: 'Search API',
  description: 'Use personalised recommendations in Product search.',
}

export const VISITOR_INSIGHTS = {
  value: 'visitor_insights',
  label: 'Visitor Insights',
  description: 'Explore your visitors journey on your store.',
}

export const AB_TESTING = {
  value: 'ab_experience',
  label: 'A/B Testing',
  description: 'Experiment with variations of different widgets.',
}

export const FUNNELS = {
  value: 'funnels',
  label: 'Checkout Funnels',
  description: 'Add sales funnels to your checkout flow.',
}

export const BUNDLES = {
  value: 'bundles',
  label: 'Bundles',
  description: 'Create custom bundles and offer discounts for them.',
}

export const POPUPS = {
  value: 'popups',
  label: 'Popups',
  description: 'Create custom popups and highlight recommendations.',
}

export const MINI_CART = {
  value: 'minicart',
  label: 'Mini Cart',
  description:
    'Add a mini cart to your store for a seamless shopping experience for your customers.',
}

export const GLOOD_PROXY = {
  value: 'glood_proxy',
  label: 'Personalized Page',
  description:
    'Custom proxy page that you can use to render bundles, recommendations and more.',
}

export const FEATURES_ORDER = [
  BASIC_RECOMMENDATIONS,
  CUSTOM_CSS,
  DISCOUNTS,
  RECOMMENDATION_RULES,
  ADVANCED_RECOMMENDATIONS,
  EMAIL_RECOMMENDATIONS,
  VISITOR_INSIGHTS,
  GEO_TARGETING,
  PRODUCT_DISCOUNTS,
  BUNDLES,
  CUSTOM_TEMPLATES,
  AB_TESTING,
  TRANSLATIONS,
  SEGMENTATION,
  LIVE_SUPPORT,
]

export default {
  LIVE_SUPPORT,
  BASIC_RECOMMENDATIONS,
  DISCOUNTS,
  ADVANCED_RECOMMENDATIONS,
  CUSTOM_TEMPLATES,
  SEGMENTATION,
  EMAIL_RECOMMENDATIONS,
  VISITOR_INSIGHTS,
  RECOMMENDATION_RULES,
  TRANSLATIONS,
  CUSTOM_CSS,
  GEO_TARGETING,
  SEARCH,
  PRODUCT_DISCOUNTS,
  AB_TESTING,
  FUNNELS,
  BUNDLES,
  POPUPS,
  MINI_CART,
}
