import React from 'react'
import { NavLink } from 'react-router-dom'

import Icons from 'components/Icons'
import Link from 'components/Link'

export default function Breadcrumbs({ items }) {
  return (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1">
        {items.map((item, index) => {
          const showChevron = index !== 0
          return (
            <li className="flex" key={index}>
              <div className="flex items-center text-gray-500">
                {showChevron && (
                  <div className="flex flex-row items-center">
                    <Icons.ChevronRight className="w-4 h-4" />
                    <Icons.ChevronRight className="w-4 h-4 mr-1 -ml-3" />
                  </div>
                )}
                <NavLink
                  to={`${item.path}${window.location.search}`}
                  exact={true}
                  activeClassName="text-gray-900"
                  className="text-sm font-medium hover:text-gray-900"
                >
                  {item.label}
                </NavLink>
              </div>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
