export function dispatchNativeEvent(target, type) {
  if (!target || typeof target.dispatchEvent !== 'function') {
    return
  }

  let event
  if (typeof Event === 'function') {
    event = new Event(type)
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(type, false, false, {})
  }

  target.dispatchEvent(event)
}

export function pathnameFromURL(url) {
  try {
    return new URL(url).pathname
  } catch (err) {
    return null
  }
}

export function domainNameFromURL(url) {
  try {
    return new URL(url).host
  } catch (err) {
    return null
  }
}

export function fullURLFromPathname(pathname, domain) {
  return `https://${domain}${pathname}`
}

export function validURL(url) {
  try {
    return Boolean(new URL(url))
  } catch (err) {
    return false
  }
}

export function validCssColor(strColor) {
  if (!Boolean(strColor)) {
    return false
  }

  let s = new Option().style
  s.color = strColor
  return s.color !== ''
}

export function deviceType(ua) {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }

  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }

  return 'desktop'
}

export function isInIframe() {
  return window.self !== window.top
}

export async function urlFromFile(file) {
  if (!file) {
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export async function imageElementFromUrl(url) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = function () {
      resolve(this)
    }
    image.onerror = function (err) {
      reject(err)
    }
    image.src = url
  })
}
