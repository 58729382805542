import React from 'react'
import Box from 'components/Box'
import Icons from 'components/Icons'
import Link from 'components/Link'
import classNames from 'classnames'

export default function Banner({ banner, onClose }) {
  let { title, text, link, buttonText, status } = banner

  const internalLink =
    link && typeof link.startsWith === 'function' && link.startsWith('/')
  const linkClass =
    'relative px-3 py-1 text-xs font-medium text-white bg-primary border border-primary-600 rounded shadow focus:outline-none focus:shadow-outline hover:bg-primary-700 hover:border-primary-600'

  return (
    <Box
      className={classNames(
        'relative mb-6',
        BANNER_STATUS_TO_CLASS_MAP[status]
      )}
      spacing="xSmall"
    >
      <div className="font-semibold text-gray-800 text">{title}</div>
      <div className="mb-2 text-xs text-gray-800">{text}</div>
      {link &&
        buttonText &&
        (internalLink ? (
          <Link className={linkClass} to={link}>
            {buttonText}
          </Link>
        ) : (
          <a className={linkClass} href={link}>
            {buttonText}
          </a>
        ))}
      <Icons.Close
        className="absolute top-0 right-0 w-4 h-4 mt-3 mr-3 cursor-pointer fill-current text-primary-800"
        onClick={() => onClose(banner)}
      />
    </Box>
  )
}

const BANNER_STATUS_TO_CLASS_MAP = {
  info: 'bg-primary-200',
  warning: 'bg-primary-200',
  critical: 'bg-red-200',
  success: 'bg-green-200',
}
