import React, { useState } from 'react'

import WithProducts from 'components/WithProducts'
import ProductsSlider from 'components/ProductsSlider'
import SlideOver from 'components/SlideOver'
import RenderMoney from 'components/RenderMoney'
import Icons from 'components/Icons'
import { getAccountData } from 'utils/account'
import * as ShopifyUtils from 'utils/shopify'

export default function FreeGift({ threshold, handleUpdate }) {
  const value = threshold.settings
  const { productId, variantIds } = value
  const [productSliderVisible, setProductSliderVisible] = useState(false)
  const accountData = getAccountData()

  return (
    <div className="mt-6">
      {productId ? (
        <div className="min-h-[5rem]">
          <WithProducts productIds={[productId]}>
            {({ products }) => {
              const [product] = products
              const { variants } = product
              const selectedVariants = variants.filter((v) =>
                variantIds.length > 0 ? variantIds.includes(v.id) : true
              )

              return (
                <ProductItem
                  key={product.id}
                  product={product}
                  selectedVariants={selectedVariants}
                  accountData={accountData}
                  onDelete={() => handleUpdate({})}
                />
              )
            }}
          </WithProducts>
        </div>
      ) : (
        <React.Fragment>
          <button
            className="flex justify-center w-full py-2 text-sm font-semibold rounded cursor-pointer sitems-center bg-primary-200 hover:bg-primary-300 text-primary"
            onClick={() => setProductSliderVisible(true)}
          >
            Choose Product
          </button>
          <SlideOver
            open={productSliderVisible}
            onClose={() => setProductSliderVisible(false)}
          >
            <ProductsSlider
              onClose={() => setProductSliderVisible(false)}
              initialSelectedProducts={[]}
              onSelect={(selectedProducts = []) => {
                const [selectedProduct] = selectedProducts
                if (selectedProduct) {
                  handleUpdate({
                    productId: selectedProduct.productId,
                    variantIds:
                      selectedProduct.variantsCount ===
                      selectedProduct.variantIds?.length
                        ? []
                        : selectedProduct.variantIds,
                    defaultQuantity: '1',
                  })
                }
              }}
              allowMultipleProducts={false}
              allowMultipleVariants={true}
            />
          </SlideOver>
        </React.Fragment>
      )}
    </div>
  )
}

function ProductItem({ product, onDelete }) {
  const { title, image, variants } = product
  const firstVariant = variants[0]
  return (
    <div className="flex flex-row items-center py-4">
      <div className="w-2/12">
        <div className="relative overflow-hidden h-0 pt-[150%]">
          <img
            src={ShopifyUtils.optimisedImage({
              baseUrl: image,
              size: 460,
            })}
            alt={title}
            className="absolute top-0 left-0 object-cover object-top w-full h-full"
          />
        </div>
      </div>
      <div className="flex flex-col items-start w-9/12 pl-6">
        <span className="text-sm font-semibold">{title}</span>
        <span className="mt-1 text-xs text-gray-600">{firstVariant.title}</span>
        <div className="flex flex-row items-center mt-1">
          <RenderMoney price={firstVariant.price} className={'text-xs'} />
          {firstVariant.comparePrice && (
            <RenderMoney
              price={firstVariant.comparePrice}
              className={'pl-2 text-xxs line-through text-gray-600'}
            />
          )}
        </div>
      </div>
      <div className="w-1/12">
        <div
          className="flex flex-col items-center cursor-pointer"
          onClick={onDelete}
        >
          <Icons.Delete className="w-4 text-red-600" />
        </div>
      </div>
    </div>
  )
}
