import api from './index'

export function experienceCreate(experienceData, params = {}) {
  return api.post('experiences/', experienceData, { params })
}

export function experienceSave(experienceId, experienceData, params = {}) {
  return api.put(`experiences/${experienceId}/`, experienceData, { params })
}

export function getExperiences({ queryKey }) {
  const [_key, shop] = queryKey
  return api.get('experiences/', {
    params: {
      shop,
    },
  })
}
