import React from 'react'
import { Helmet } from 'react-helmet'
import Languages from 'constants/languages'

export default function LanguageSwitcher() {
  const [visible, setVisible] = React.useState(false)
  const componentVisible = Boolean(document.querySelector('.goog-te-gadget'));

  React.useEffect(() => {
    const $languageSwitcherElem = document.querySelector(
      '#google_translate_element'
    )
    const $accountLink = document.querySelector('div[data-account-switcher]')

    $languageSwitcherElem.style.opacity = visible ? 1 : 0
    $languageSwitcherElem.style.cursor = 'pointer'
    if ($accountLink) {
      $languageSwitcherElem.style.right = `${$accountLink.offsetWidth + 40}px`
    }
  }, [visible])

  React.useEffect(() => {
    function syncLanguageOptions($select) {
      function cleanOptions($select) {
        const visibleLanguageCodes = Languages.map((l) => l.value)
        Array.prototype.slice
          .call($select.querySelectorAll('option'))
          .forEach((option) => {
            const optionValue = option.getAttribute('value')
            if (
              Boolean(optionValue) &&
              visibleLanguageCodes.indexOf(optionValue) === -1
            ) {
              option.parentElement.removeChild(option)
            }
          })
      }

      $select.addEventListener('change', (event) => {
        setTimeout(() => {
          cleanOptions(event.target)
        }, 700)
      })

      cleanOptions($select)
    }

    let pollingTimer
    pollingTimer = setInterval(() => {
      const $select = document.querySelector('#google_translate_element select')
      if (!$select) {
        return
      }

      clearInterval(pollingTimer)
      syncLanguageOptions($select)
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }, 2000)
  }, [])

  if(componentVisible) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {`function googleTranslateElementInit() {
          new google.translate.TranslateElement(
              {pageLanguage: 'en'},
              'google_translate_element'
        );
      }`}
      </script>
      <script
        type="text/javascript"
        async
        src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      ></script>
    </Helmet>
  )
}
