import { GA_TRACKING_ID } from 'constants/analytics'
import { getAccountData, isRkSupport } from './account'

export function trackEvent(payload) {
  const accountData = getAccountData()
  const rkSupport = isRkSupport(accountData)
  if (rkSupport) {
    console.log('Admin: Track Event', payload)
  } else {
    sendEventToGA(payload)
    sendEventToSegment(payload)
  }
}

function sendEventToGA({ category, action, label = '', value = '' }) {
  if (typeof window.gtag !== 'function') {
    console.log('GA: Track Event', action, { category, label, value })
    return
  }

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

function sendEventToSegment({ category, action, label = '', value = '' }) {
  if (
    typeof window.analytics === 'undefined' ||
    typeof window.analytics.track !== 'function'
  ) {
    console.log('Segment: Track Event', action, { category, label, value })
    return
  }

  window.analytics.track(action, {
    category,
    label,
    value,
  })
}

export function trackPageView() {
  const page_path = window.location.pathname

  const accountData = getAccountData()
  const rkSupport = isRkSupport(accountData)
  if (rkSupport) {
    console.log('Admin: Track Page View', { page_path })
    return
  } else if (typeof window.gtag !== 'function') {
    console.log('GA: Track Page View', { page_path })
    return
  }

  window.gtag('config', GA_TRACKING_ID, { page_path })
}
