import React from 'react'

import GridTemplateImage from './assets/template_grid.png'
import AmazonTemplateImage from './assets/template_amazon.png'

import { BOUGHT_TOGETHER } from 'constants/widgets'
import { PRODUCT } from 'constants/pages'
import { DISCOUNT_TYPES } from 'constants/discount'

export const DISCOUNT_LEVEL_OPTIONS = {
  INDIVIDUAL_DISCOUNTS: {
    label: 'Apply discount on individual product',
    value: 'product_discount',
    disabled: true,
    tooltipContent: 'Coming Soon',
  },
  BUNDLE_DISCOUNT: {
    label: 'Apply single discount on all products',
    value: 'bundle_discount',
  },
}

export const TRIGGER_OPTIONS = [
  {
    label: 'Show as widget on Product Page',
    value: 'show_widget',
    type: 'showWidget',
    description: `Link a ${BOUGHT_TOGETHER.label} widget and display the bundles on it. Currently only supported on ${PRODUCT.label}.`,
    available: true,
  },
  {
    label: 'Show only on custom personalized page',
    value: 'recommendations_page',
    type: 'recommendationsPage',
    description: (accountData) => {
      const domain = accountData.name
      return (
        <div>
          Ensure that the bundle is only displayed on the{' '}
          <a
            className="cursor-pointer text-primary"
            href={`https://${domain}/a/recommendations`}
            target="_blank"
            rel="noopener noreferrer"
          >
            /a/recommendations
          </a>
          / page
        </div>
      )
    },
    available: true,
  },
  {
    label: 'Manual',
    value: 'popup_manual',
    type: 'popupManual',
    description:
      'Show the bundle as a pop up when an item has been added to cart. Increase AoV by suggesting bundles that go with the added item',
    available: false,
    tooltipContent: 'Coming Soon',
  },
  {
    label: 'Exit intent pop up',
    value: 'popup_exit',
    type: 'popupExit',
    description:
      'Show the bundle as a pop up when the customer is about to leave your store. Display bundles to pique customer interest.',
    available: false,
    tooltipContent: 'Coming Soon',
  },
  {
    label: 'Time triggered pop up',
    value: 'popup_time',
    type: 'popupTime',
    description:
      'Show the bundle as a pop up after a time interval. Have a bundle sale ? Let your customers know',
    available: false,
    tooltipContent: 'Coming Soon',
  },
]

export const BUNDLE_TEMPLATES = {
  GRID_TEMPLATE: {
    image: GridTemplateImage,
    label: 'Grid Template',
    value: 'bundle_grid',
  },
  AMAZON_FBT: {
    image: AmazonTemplateImage,
    label: 'Amazon Template',
    value: 'bundle_amazon',
  },
}

export const BUNDLE_STATUS_OPTIONS = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Active',
    actionLabel: 'Publish',
    value: 'active',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    actionLabel: 'Inactivate',
  },
  {
    label: 'Deleted',
    actionLabel: 'Delete',
    value: 'delete',
  },
]

export const DEFAULT_DISCOUNT = {
  type: DISCOUNT_TYPES.FLAT.value,
  value: 30,
  enabled: true,
}

export const DEFAULT_DISCOUNT_TRANSLATIONS = {
  widgetMessage: 'Buy these products together and get a bundle discount.',
  cartMessage: 'Your Bundle discount will be applied at checkout.',
}
