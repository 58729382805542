import React from 'react'

import Box from 'components/Box'
import { _uniqBy } from 'utils/lodash'

import WidgetsCard from '../WidgetsCard'

export default function ExperienceList({
  pages,
  billing,
  account,
  experiences,
}) {
  const allExperiences = experiences.data.experiences
  function updateWidgetData(widgetData, experience) {
    experiences.get()
  }

  return allExperiences
    .sort((e1, e2) => {
      if (e1.status === 'unpublished' && e2.status === 'unpublished') {
        if (e1.id > e2.id) {
          return 1
        } else {
          return -1
        }
      } else if (e1.status === 'unpublished') {
        return 1
      } else if (e2.status === 'unpublished') {
        return -1
      } else if (e1.id > e2.id) {
        return 1
      } else {
        return -1
      }
    })
    .map((exp) => {
      return (
        <Box className="flex flex-col w-full mb-3" key={exp.id}>
          <WidgetsCard
            pages={pages}
            billing={billing}
            account={account}
            experience={exp}
            totalWeight={experiences.data.totalWeight}
            updateWidgetData={updateWidgetData}
          />
        </Box>
      )
    })
}
