import * as FILTER_OPERATORS from './filterOperators'

export const STRING = {
  value: 'string',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_EQUAL,
    FILTER_OPERATORS.IS_NOT_EQUAL,
    FILTER_OPERATORS.CONTAINS,
    FILTER_OPERATORS.NOT_CONTAINS,
  ],
}

export const ORG_USER = {
  value: 'orgUser',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_ANY,
    FILTER_OPERATORS.IS_NOT_ANY,
    FILTER_OPERATORS.IS_PRESENT,
    FILTER_OPERATORS.IS_NOT_PRESENT,
  ],
}

export const COUNTRY = {
  value: 'country',
  filterOperatorOptions: [FILTER_OPERATORS.IS_ANY, FILTER_OPERATORS.IS_NOT_ANY],
}

export const CURRENCY = {
  value: 'currency',
  filterOperatorOptions: [
    FILTER_OPERATORS.MORE_THAN,
    FILTER_OPERATORS.LESS_THAN,
    FILTER_OPERATORS.WITHIN_RANGE,
    FILTER_OPERATORS.OUTSIDE_RANGE,
    FILTER_OPERATORS.IS_EQUAL,
  ],
}

export const BOOLEAN = {
  value: 'boolean',
  filterOperatorOptions: [FILTER_OPERATORS.IS_TRUE, FILTER_OPERATORS.IS_FALSE],
}

export const INTEGER = {
  value: 'integer',
  filterOperatorOptions: [
    FILTER_OPERATORS.MORE_THAN,
    FILTER_OPERATORS.LESS_THAN,
    FILTER_OPERATORS.WITHIN_RANGE,
    FILTER_OPERATORS.OUTSIDE_RANGE,
    FILTER_OPERATORS.IS_EQUAL,
  ],
}
export const NUMBER = {
  value: 'number',
  filterOperatorOptions: [
    FILTER_OPERATORS.MORE_THAN,
    FILTER_OPERATORS.LESS_THAN,
    FILTER_OPERATORS.WITHIN_RANGE,
    FILTER_OPERATORS.OUTSIDE_RANGE,
    FILTER_OPERATORS.IS_EQUAL,
  ],
}

export const TIMESTAMP = {
  value: 'timestamp',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_BEFORE,
    FILTER_OPERATORS.IS_AFTER,
    FILTER_OPERATORS.WITHIN_RANGE,
    FILTER_OPERATORS.OUTSIDE_RANGE,
    FILTER_OPERATORS.IS_EQUAL,
  ],
}

export const CRM_ACCOUNT = {
  value: 'account',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_ANY,
    FILTER_OPERATORS.IS_NOT_ANY,
    FILTER_OPERATORS.IS_PRESENT,
    FILTER_OPERATORS.IS_NOT_PRESENT,
  ],
}

export const CRM_CONTACT = {
  value: 'contact',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_ANY,
    FILTER_OPERATORS.IS_NOT_ANY,
    FILTER_OPERATORS.IS_PRESENT,
    FILTER_OPERATORS.IS_NOT_PRESENT,
  ],
}

export const CHOICE = {
  value: 'choice',
  filterOperatorOptions: [
    FILTER_OPERATORS.IS_ANY,
    FILTER_OPERATORS.IS_NOT_ANY,
    FILTER_OPERATORS.IS_PRESENT,
    FILTER_OPERATORS.IS_NOT_PRESENT,
  ],
}

export const IMAGE = {
  value: 'image',
}
