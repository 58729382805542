import api from 'api'
import { fakeApi } from 'utils/misc'

export function fetchBundles({ queryKey }) {
  const [_key, shop, { pagination, order, filters }] = queryKey
  return api
    .get(`/aov/bundles/`, {
      params: {
        shop,
        filters,
        pagination,
        order,
      },
    })
    .then((response) => {
      return {
        data: {
          size: pagination.size,
          offset: pagination.offset,
          data: response.data.bundles,
          total: response.data.totalPages,
        },
      }
    })
}

export function fetchBundle({ queryKey }) {
  const [_key, { shop, bundleId }] = queryKey

  if (!bundleId) {
    return null
  }
  return api
    .get(`/aov/bundles/${bundleId}/`, {
      params: {
        shop,
      },
    })
    .then((response) => {
      return {
        data: {
          data: response.data.bundle,
        },
      }
    })
}

export function saveBundle(shop, payload) {
  if (!payload.id) {
    return null
  }

  return api.put(`/aov/bundles/${payload.id}/`, payload, {
    params: {
      shop,
    },
  })
}

export function createBundle(shop, payload) {
  const { name } = payload

  if (!name) {
    return null
  }

  return api.post(`/aov/bundles/`, payload, {
    params: {
      shop,
    },
  })
}
