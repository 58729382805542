import React from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form'
import TranslationUpsell from '../TranslationUpsell'

export default function FBTForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="This Product Text"
          name="thisProductText"
          control="input"
          ref={register({
            required: 'Required',
          })}
          formError={errors.thisProductText}
          supportElement={
            <TranslationUpsell
              templateId={props.widget.template}
              store={props.store}
            />
          }
        />
        <Form.Field
          label="Total Price Text"
          name="totalPriceText"
          control="input"
          ref={register({
            required: 'Required',
          })}
          formError={errors.totalPriceText}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ translations }) {
  return {
    thisProductText: translations.primaryLocale.thisProductText,
    totalPriceText: translations.primaryLocale.totalPriceText,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    translations: {
      primaryLocale: {
        thisProductText: values.thisProductText,
        totalPriceText: values.totalPriceText,
      },
    },
  }
}
