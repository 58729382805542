import React, { useState } from 'react'
import _get from 'lodash.get'

import Box from 'components/Box'
import Button from 'components/Button'
import * as Widgets from 'constants/widgets'
import { Select } from 'components/Form/controls'

import useSlots from './hooks/useSlots'

const ALL_WIDGETS = Object.keys(Widgets).map((k) => Widgets[k])

export default function SlotRecommendations({ widget, onWidgetSave }) {
  const [saving, setSaving] = useState(false)
  const slotsState = useSlots({
    initialSlots: _get(widget, 'extra.slots', []),
  })

  async function saveSlots() {
    setSaving(true)
    await onWidgetSave({ extra: { slots: slotsState.slots } })
    setSaving(false)
  }

  return (
    <Box className="mb-5">
      <div className="flex flex-row justify-between mb-5 text-sm font-semibold text-gray-800 uppercase">
        Configure Slots
        <span
          className="ml-auto text-red-600 text-xxs"
          style={{ textTransform: 'none' }}
        >
          *Please click on "Save" to save changes.
        </span>
      </div>
      <div className="flex flex-wrap items-end mb-6">
        {slotsState.slots.map((slot, index) => {
          const widget = ALL_WIDGETS.filter((w) => w.value === slot.type)[0]

          return (
            <div
              className="flex flex-col items-stretch p-2 mb-3 mr-3 border border-gray-800 rounded-sm"
              style={{ minWidth: 230, minHeight: 120 }}
              key={slot.id}
            >
              {slot.id === slotsState.editSlotId ? (
                <React.Fragment>
                  <span className="mb-2 text-xs text-black">
                    Slot {index + 1}
                  </span>
                  <div className="flex flex-col items-stretch">
                    <span className="mb-1 font-semibold">Choose Type</span>
                    <Select
                      className="text-sm"
                      options={ALL_WIDGETS.filter((w) => w.allowedInSlots)}
                      value={slot.type}
                      onChange={(e) => {
                        slotsState.updateSlot(slot.id, { type: e.target.value })
                        setTimeout(() => {
                          slotsState.markSlotForEdit(null)
                        })
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <span className="mb-1 text-xs text-black">
                    Slot {index + 1}
                  </span>
                  <div className="mb-3 text-xl">
                    <span className="font-semibold text-black">
                      {widget.label}
                    </span>
                  </div>
                  <div className="flex flex-row mt-auto">
                    <Button
                      onClick={() => slotsState.markSlotForEdit(slot.id)}
                      variant="bordered"
                      size="xSmall"
                      className="mr-3"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="bordered"
                      size="xSmall"
                      color="red"
                      onClick={() => slotsState.removeSlot(slot.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
          )
        })}
        <div
          className="flex flex-col items-stretch p-2 mb-3 mr-3 border border-gray-200 rounded-sm"
          style={{ minWidth: 230, minHeight: 120 }}
        >
          <div className="flex flex-col items-stretch mt-auto mb-3">
            <span className="mb-1 text-xs font-semibold">
              Choose New Slot Type
            </span>
            <Select
              className="text-sm"
              options={ALL_WIDGETS.filter((w) => w.allowedInSlots)}
              value={slotsState.newSlotType}
              onChange={slotsState.handleNewSlotTypeChange}
            />
          </div>
          <Button
            size="small"
            onClick={() => slotsState.addSlot(slotsState.newSlotType)}
          >
            +Add New Slot
          </Button>
        </div>
      </div>
      <div className="flex flex-row items-center">
        <Button className="mr-3" loading={saving} onClick={saveSlots}>
          Save
        </Button>
      </div>
    </Box>
  )
}
