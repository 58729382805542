import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import api from 'api'
import { useForm } from 'react-hook-form'

import Icons from 'components/Icons'
import Tooltip from 'components/Tooltip'
import { invalidate } from 'utils/query'
import Form from 'components/Form'
import Callout from 'components/Callout'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import TEMPLATE_TYPES from 'constants/templates'

export default function Layout({ builder }) {
  const {
    widget,
    setWidget,
    openToast,
    setActiveScreen,
    shop,
    setSaveHandler,
    setSaveNextHandler,
    templates,
    account,
    openModal,
    localWidget,
    setLocalWidget,
  } = builder
  const [layout, setLayout] = useState(widget?.layout || null)

  const amazonLikeEnabled = Boolean(
    widget.type === 'bought_together' && widget.pageType === 'product_page'
  )

  const { register, watch } = useForm()
  const customTemplateEnabled =
    account.data.plan.featuresEnabled.includes('custom_templates')
  useEffect(() => {
    widget.layout = layout
    setWidget(widget)
    async function updateLayout() {
      const { id, layout } = widget
      const payload = { id, layout, enabled: false }
      if (customTemplateEnabled) {
        payload.template = watch('template')
      }
      try {
        const { data } = await api.put(`widgets/${id}/`, payload, {
          params: { shop },
        })
        setWidget(data)
        localWidget.layout = layout
        setLocalWidget(localWidget)
        openToast({
          type: 'success',
          text: 'Widget updated successfully!',
        })
        invalidate(['pages', shop])
        return data
      } catch (e) {
        openToast({
          type: 'error',
          text: 'There was an error in updating the widget',
        })
      }
    }

    setSaveHandler(() => async () => {
      await updateLayout()
    })

    setSaveNextHandler(() => async () => {
      const data = await updateLayout()
      if (data) {
        setActiveScreen('configure')
      }
    })
  }, [layout, setLayout, widget, setWidget])

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-col w-full px-16 py-10 h-max">
        <h1 className="text-lg font-semibold ">Select Template</h1>
        <p className="mt-2 mb-4 text-base text-gray-500">
          Complete this step to continue
        </p>
        <Tooltip
          content={
            customTemplateEnabled
              ? null
              : 'Your plan does not have access to custom templates'
          }
        >
          <div className="flex items-center justify-center w-full">
            <Form.Field
              control="select"
              options={
                customTemplateEnabled
                  ? templates
                      .filter(
                        (temp) => temp.type === TEMPLATE_TYPES.WIDGET.value
                      )
                      .map((template) => ({
                        label: template.name,
                        value: template.id,
                      }))
                  : [
                      {
                        label: 'Default Template',
                      },
                    ]
              }
              name="template"
              ref={register()}
              disabled={!customTemplateEnabled}
              className={classNames('w-full', {
                'opacity-50': !customTemplateEnabled,
              })}
            />
            {!customTemplateEnabled && (
              <Icons.Lock className="w-5 ml-2 text-gray-400 -translate-y-1" />
            )}
          </div>
        </Tooltip>
        {/* {
          !customTemplateEnabled && 
          <div className="flex justify-end">
          <Callout type="info" noHoverEffect={true}>
            <span className="mr-2">
              Current plan does not support custom templates. Upgrade your plan to add support for custom templates in your store.
            </span>
            <Button
              color="primary"
              size="xSmall"
              onClick = {()=>{ openModal(
                <UpgradeAccountModal account={account} featureToEnable="custom_templates"/>
              ) }}
            >
              Upgrade
            </Button>
          </Callout>
        </div>
        } */}
      </div>
      <div className="w-full h-0.5 bg-gray-200"></div>
      <LayoutSelector
        layout={layout}
        setLayout={setLayout}
        amazonLikeEnabled={amazonLikeEnabled}
      />
    </div>
  )
}

function LayoutSelector({ layout, setLayout, amazonLikeEnabled }) {
  return (
    <div className="flex flex-col w-full px-16 py-10 h-max">
      <h1 className="text-lg font-semibold ">Select Layout</h1>
      <p className="mt-2 mb-4 text-base text-gray-500">
        Complete this step to continue
      </p>
      <div className="grid grid-cols-3 grid-rows-1 gap-4 h-max">
        {LAYOUTS.map(({ label, value, description, image }, index) => (
          <Tooltip
            content={
              !amazonLikeEnabled && value === 'amazon_bought_together'
                ? 'Layout not supported on selected widget and page type.'
                : null
            }
          >
            <div
              className={classNames(
                'flex w-92 h-full shadow-md rounded-lg transition-all duration-100 ease-in-out flex-col hover:shadow-lg group',
                value === layout
                  ? 'border border-primary-500 bg-primary-50'
                  : 'border border-gray-400',
                {
                  'cursor-not-allowed opacity-50':
                    !amazonLikeEnabled && value === 'amazon_bought_together',
                },
                !amazonLikeEnabled && value === 'amazon_bought_together'
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer hover:border-primary-500'
              )}
              key={index}
              onClick={() => {
                if (!amazonLikeEnabled && value === 'amazon_bought_together') {
                  return
                }
                setLayout(value)
              }}
            >
              <div className="pb-6 pr-4 pt-7 pl-7">
                <div className="flex justify-between mb-1">
                  <h1 className="text-sm font-semibold">{label}</h1>
                  <span
                    className={classNames(
                      'flex items-center justify-center w-5 h-5 border border-gray-300 rounded ',
                      {
                        'bg-primary': layout === value,
                        'group-hover:bg-primary-500 group-hover:border-primary-400 group-hover:border':
                          !(
                            !amazonLikeEnabled &&
                            value === 'amazon_bought_together'
                          ),
                      }
                    )}
                  >
                    <Icons.Tick className="w-3 text-white stroke-current" />
                  </span>
                </div>
                <p className="mt-2 text-xs text-gray-500">{description}</p>
              </div>
              <div className="flex items-center justify-center w-full h-full p-4 bg-gray-800">
                <img
                  src={image}
                  alt={`${value}`}
                  className="w-full rounded-md"
                />
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

export const LAYOUTS = [
  {
    value: 'amazon_bought_together',
    label: 'Amazon like bought together',
    description: 'Display products in Amazon Bought together layout.',
    image: require('./assets/Images/Layouts/BoughtTogether.png'),
  },
  {
    value: 'carousel',
    label: 'Carousel',
    description: 'Display products in scrollable Carousel layout',
    image: require('./assets/Images/Layouts/Carousel.png'),
  },
  {
    value: 'horizontal_grid',
    label: 'Horizontal Grid',
    description: 'Display product in Horizontal Grid layout',
    image: require('./assets/Images/Layouts/HoriontalGrid.png'),
  },
]
