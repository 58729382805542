import React from 'react'
import Button from 'components/Button'
import Text from 'components/Text'
import WaitForData from 'components/WaitForData'
import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { EMAIL_RECOMMENDATIONS } from 'constants/features'
import { isFeatureEnabled } from 'utils/features'
import { useApi } from 'hooks/api'

import AddCampaignModal from './AddCampaignModal'
import CampaignsCard from './CampaignsCard'
import AnalyticsCard from './AnalyticsCard'

export default function EmailCampaigns({ account, store }) {
  const { name: shop } = account.data

  const campaigns = useApi('marketing/campaigns/', { shop })
  const billing = useApi('account/', { shop })
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(account.data, EMAIL_RECOMMENDATIONS)

  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal
        account={account}
        featureToEnable={EMAIL_RECOMMENDATIONS}
      />
    )
  }

  return (
    <WaitForData
      objects={[campaigns, billing]}
      loadingMessage="Loading email campaigns"
    >
      {() => (
        <div className="flex flex-col items-stretch flex-1">
          <Text.ScreenHeading marginBottom={1}>
            Email Recommendations Widget
          </Text.ScreenHeading>

          <div className="flex flex-col items-stretch lg:items-start lg:flex-row">
            <div className="flex flex-col w-full mr-3 lg:w-2/3">
              <CampaignsCard campaigns={campaigns} />
            </div>
            <div className="flex flex-col w-full ml-3 lg:w-1/3">
              <div className="self-start w-full pr-3 mb-6">
                <Button
                  className="w-full"
                  onClick={() =>
                    featureEnabled
                      ? openModal(
                          <AddCampaignModal
                            campaigns={campaigns}
                            account={account}
                          />
                        )
                      : openUpgradeAccount()
                  }
                >
                  <div className="flex items-center justify-center">
                    <span className=""> + Create Campaign</span>
                    {!featureEnabled && (
                      <Icons.Lock
                        className={'w-5 ml-4 mb-1 fill-current opacity-75'}
                      />
                    )}
                  </div>
                </Button>
              </div>
              {false && <AnalyticsCard shop={shop} />}
            </div>
          </div>
        </div>
      )}
    </WaitForData>
  )
}
