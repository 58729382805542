import React from 'react'

export default function Actions({ settings }) {
  const { actions } = settings
  const enabledActions = actions.filter((action) => action.enabled)

  if (enabledActions.length < 1) {
    return null
  }

  const primaryAction = enabledActions.find((action) => action.priority == 1)
  const secondaryAction = enabledActions.find((action) => action.priority == 2)
  const tertiaryAction = enabledActions.find((action) => action.priority == 3)
  return (
    <div className="flex flex-col items-stretch justify-center pb-4 px-7">
      <div className="flex flex-col mt-4">
        {secondaryAction && <ActionItem action={secondaryAction} />}
        {primaryAction && <ActionItem action={primaryAction} />}
        {tertiaryAction && (
          <ActionItem action={tertiaryAction} buttonType={'text'} />
        )}
      </div>
    </div>
  )
}

function ActionItem({ action, buttonType = 'solid' }) {
  const {
    label: { default: defaultLabel },
    type: actionType,
    backgroundColor,
    textColor,
  } = action
  if (buttonType === 'text') {
    return (
      <div className="flex flex-row justify-center">
        <button
          key={actionType}
          className="py-2 text-sm font-semibold text-right border border-transparent rounded cursor-pointer hover:opacity-80 first:mt-0"
          style={{
            // backgroundColor,
            color: textColor,
          }}
        >
          {defaultLabel}
        </button>
      </div>
    )
  }
  return (
    <button
      key={actionType}
      className="w-full py-4 mt-4 text-lg font-semibold rounded cursor-pointer hover:opacity-80 first:mt-0"
      style={{
        backgroundColor,
        color: textColor,
        border: `1px solid ${textColor}`,
      }}
    >
      {defaultLabel}
    </button>
  )
}
