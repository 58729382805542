import format from 'date-fns/format'
import formatRelative from 'date-fns/formatRelative'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import formatDistance from 'date-fns/formatDistance'

export function fromNow(time) {
  const now = new Date()
  const compare = new Date(time*1000)
  if (differenceInDays(now, compare) > 6) {
    return format(compare, 'PPP')
  } else {
    return formatRelative(compare, now)
  }
}


export function hoursAgo(time) {
  const now = new Date()
  const compare = new Date(time*1000)
  return differenceInHours(now, compare)
}


export function differnceInWords(time, from) {
  from = new Date() || new Date(from*1000)
  return formatDistance(new Date(time*1000), from)
}
