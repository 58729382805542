import React from 'react'

import { useQueryParams } from 'hooks/router'

import ProductList from './ProductList'

export default function GlobalRecommendations({
  widgetType,
  onClose,
  store,
  recommendations,
}) {
  const { shop } = useQueryParams()
  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-col mb-3">
        <ProductList
          shop={shop}
          widgetType={widgetType}
          topProductsData={recommendations}
          onClose={onClose}
          store={store}
        />
      </div>
    </div>
  )
}
