import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from 'components/Button'
import Box from 'components/Box'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'

export default function CampaignGeneral({ campaign, onSave }) {
  return (
    <div className="flex flex-col">
      <Title campaign={campaign} onSave={onSave} />
      <HTML campaign={campaign} onSave={onSave} />
    </div>
  )
}

function Title({ campaign, onSave }) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name: campaign.name,
    },
  })

  return (
    <Box className="mb-5">
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(onSave)}
      >
        <Form.Field
          label="Campaign name"
          name="name"
          control="input"
          ref={register()}
          spacing="loose"
        />
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}

function HTML({ campaign }) {
  if (!campaign.html) {
    return null
  }

  return (
    <Box className="flex flex-col items-stretch">
      <span className="mb-1 text-xs font-semibold">Email HTML Code</span>
      <textarea
        className="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow hover:border-gray-500 focus:outline-none focus:shadow-outline"
        readOnly={true}
        value={campaign.html}
        rows={8}
      />
      <CopyToClipboard
        text={campaign.html}
        onCopy={() => alert('Email template copied to clipboard!')}
      >
        <Button size="small" variant="solid" className="self-end mt-2 mb-6">
          Copy template to clipboard
        </Button>
      </CopyToClipboard>
      <span className="mb-1 text-sm text-gray-600">
        Copy the code snippet and include it into your email template such as a
        newsletter.
      </span>
      {false && (
        <Button
          type="button"
          size="xSmall"
          variant="bordered"
          className="self-start"
          onClick={() => {
            console.log('OPen video in modal')
          }}
        >
          ▶️ Watch an example video
        </Button>
      )}
    </Box>
  )
}
