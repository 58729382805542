import { v4 as uuid } from 'uuid'

import {
  PROMOTION_LAYOUT_SECTION_TYPES,
  PROMOTION_TYPES,
} from 'constants/promotions'
import { FUNNELS } from 'constants/features'
import { isFeatureEnabled } from 'utils/features'

import { FUNNEL_STATUS_OPTIONS } from './constants'

export function getFunnelStatusOptions(funnel, accountData) {
  const currStatus = funnel.status
  let allowedPromotions = []
  const featureEnabled = isFeatureEnabled(accountData, FUNNELS)
  switch (currStatus) {
    case 'draft':
      allowedPromotions =
        (funnel?.workflow?.nodes || []).length > 0 ? ['active'] : []
      if (featureEnabled) {
        allowedPromotions.push('deleted')
      }
      break
    case 'paused':
      allowedPromotions = ['active', 'deleted']
      break
    case 'active':
      allowedPromotions = ['paused', 'deleted']
      break
    case 'deleted':
      break
    default:
      break
  }

  return FUNNEL_STATUS_OPTIONS.map((option) => {
    if (
      allowedPromotions.includes(option.value) ||
      option.value === currStatus
    ) {
      return {
        ...option,
        ...(option.value === 'active'
          ? { locked: !featureEnabled }
          : { locked: false }),
      }
    }
  }).filter(Boolean)
}

export function getPostCheckoutDefaultSections({ promotionType }) {
  switch (promotionType) {
    case PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.value:
      return [
        {
          uuid: uuid(),
          type: PROMOTION_LAYOUT_SECTION_TYPES.BANNER.value,
          ...PROMOTION_LAYOUT_SECTION_TYPES.BANNER.getNewSectionDefaults(),
        },
        {
          uuid: uuid(),
          type: PROMOTION_LAYOUT_SECTION_TYPES.SINGLE_PRODUCT_UPSELL.value,
          ...PROMOTION_LAYOUT_SECTION_TYPES.SINGLE_PRODUCT_UPSELL.getNewSectionDefaults(),
        },
      ]

    case PROMOTION_TYPES.MULTI_PRODUCT_UPSELL.value:
      return [
        {
          uuid: uuid(),
          type: PROMOTION_LAYOUT_SECTION_TYPES.BANNER.value,
          ...PROMOTION_LAYOUT_SECTION_TYPES.BANNER.getNewSectionDefaults(),
        },
        {
          uuid: uuid(),
          type: PROMOTION_LAYOUT_SECTION_TYPES.MULTI_PRODUCT_UPSELL.value,
          ...PROMOTION_LAYOUT_SECTION_TYPES.MULTI_PRODUCT_UPSELL.getNewSectionDefaults(),
        },
      ]

    default:
      return []
  }
}
