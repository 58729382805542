import React from 'react'
import Form from 'components/Form'
import Button from 'components/Button'
import Box from 'components/Box'
import { EXTERNAL_TRACK_KEYS } from 'constants/analytics'
export default function AdminPanelStoreSettings({
  data,
  onSave,
  isSaving,
  account,
}) {
  const [analyticsEnabled, setAnalyticsEnabled] = React.useState(
    data.analyticsEnabled || []
  )
  const [currencyFormat, setCurrencyFormat] = React.useState(
    account?.data?.currencyFormat
  )
  async function handleOnChange(value, checked) {
    let updatingData
    if (checked && !analyticsEnabled.includes(value)) {
      updatingData = analyticsEnabled.concat(value)
    } else if (!checked && analyticsEnabled.includes(value)) {
      updatingData = analyticsEnabled.filter((item) => item !== value)
    }
    await onSave({ analyticsEnabled: updatingData })
    setAnalyticsEnabled(updatingData)
  }
  return (
    <div className="flex flex-col items-start flex-1 w-full">
      <div className="flex flex-row flex-wrap w-full notranslate">
        {EXTERNAL_TRACK_KEYS.map((item) => {
          return (
            <Box
              key={item.value}
              className="flex flex-col items-stretch w-full mb-3"
            >
              <div className="flex items-center justify-between">
                <span className="mb-1 text-lg font-semibold">{item.label}</span>
                <Form.Field
                  control="switch"
                  checked={analyticsEnabled.includes(item.value)}
                  onChange={(checked) => handleOnChange(item.value, checked)}
                />
              </div>
              <span>This enables / disables {item.label}</span>
            </Box>
          )
        })}

        <Box className="flex flex-col items-stretch w-full mb-3">
          <Form.Field
            control="input"
            label="Currency Format"
            onChange={(event) => setCurrencyFormat(event.target.value)}
            value={currencyFormat}
          />
        </Box>

        <Button
          size="small"
          loading={isSaving}
          onClick={() => onSave({ analyticsEnabled, currencyFormat })}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
