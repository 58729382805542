import React from 'react'
import api from 'api'

import Box from 'components/Box'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { useToast } from 'components/Toast'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'
import { useOpenModal } from 'components/Modal'

export default function Products({
  products,
  currencyFormat,
  rkSupport,
  updateProducts,
  removeProduct,
  copies = {},
}) {
  const openModal = useOpenModal()
  const { title, tooltipContent, resourcePickerHeading, fallbackContent } =
    copies
  return (
    <Box className="flex flex-col items-stretch flex-1 mb-6 mr-3">
      <div className="flex flex-col mb-3">
        <div className="flex flex-row justify-between">
          <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
            <span className="mr-2">{title ? title : 'Products'}</span>
            {tooltipContent && (
              <Tooltip
                position="right"
                content={tooltipContent}
                showHelpIcon={true}
                helpIconVariant="dark"
              />
            )}
          </span>
          <Button
            size="xSmall"
            onClick={() =>
              openModal(
                <ResourcePicker
                  heading={
                    resourcePickerHeading
                      ? resourcePickerHeading
                      : 'Choose products'
                  }
                  ignoreProductIds={[]}
                  initialSelectedProductIds={products.map(
                    (rec) => rec.productId
                  )}
                  showTwoWaySyncOption={false}
                  onSelect={(selectedProductIds, twoWayEnabled) =>
                    updateProducts(
                      selectedProductIds.map((id) => id.toString())
                    )
                  }
                />
              )
            }
          >
            + Add Products
          </Button>
        </div>
      </div>
      {products.length > 0 ? (
        <RecommendationsList
          recommendations={products}
          type="manual"
          allowReorder={false}
          allowEdit={true}
          currencyFormat={currencyFormat}
          rkSupport={rkSupport}
          onRemove={(product) => removeProduct(product.id)}
        />
      ) : fallbackContent ? (
        <div className="mt-3 text-xs text-gray-600">{fallbackContent}</div>
      ) : null}
    </Box>
  )
}
