import api from 'api'
import { fakeApi } from 'utils/misc'

export function fetchSmartCart({ queryKey }) {
  const [_key, { shop }] = queryKey

  return fakeApi(
    {
      data: {
        smartCart: {
          id: 12234,
          enabled: false,
          settings: {
            general: {
              backgroundColor: '#f9f9f9ff',
              primaryColor: '#000',
              secondaryColor: '#2f855a',
              tertiaryColor: '#cbd5e0',
              fontFamily: '',
            },
            banner: {
              bannerText: {
                default:
                  'Additional 10% OFF on purchase above $65 and 20% OFF on purchase above $100',
              },
              backgroundColor: '#f0fff4',
              textColor: '#2f855a',
              fontSize: 16,
              alignment: 'center',
              textDecoration: 'none',
              fontWeight: 400,
              fontStyle: 'normal',
            },
            thresholds: [
              {
                type: 'free_gift',
                amount: { default: 5 },
                settings: {},
                label: { default: 'Free Gift' },
              },
              {
                type: 'discount',
                amount: { default: 70 },
                settings: {
                  type: 'PERCENTAGE',
                  amount: '10',
                },
                label: { default: '10% OFF' },
              },
              {
                type: 'discount',
                amount: { default: 100 },
                settings: {
                  type: 'PERCENTAGE',
                  amount: '20',
                },
                label: { default: '20% OFF' },
              },
            ],
            shipping: {
              shippingText: { default: 'Free shipping beyong Rs 20' },
              thresholdBypassText: {
                default: 'Congratulations! This order has free shipping',
              },
              enabled: true,
              amount: { default: 20 },
            },
            recommendation: {
              settings: {},
            },
            pricing: {
              showCompare: true,
              showDiscountedAmt: true,
              showDiscountPercent: true,
              totalPriceColor: '#000',
              comparePriceColor: '#a0aec0',
              discountPercentageColor: '#2f855a',
            },
            actions: [
              {
                label: { default: 'Go to Cart' },
                enabled: false,
                type: 'cart',
                priority: 2,
                backgroundColor: '#fff',
                textColor: '#000',
              },
              {
                label: { default: 'Checkout' },
                enabled: true,
                type: 'checkout',
                priority: 1,
                backgroundColor: '#000',
                textColor: '#fff',
              },
              {
                label: { default: 'Continue Shopping' },
                enabled: true,
                type: 'continue',
                priority: 3,
                backgroundColor: '#fff',
                textColor: '#eb4040',
              },
            ],
          },
        },
      },
    },
    1000
  )
  // return api
  //   .get(`/aov/smartcart/`, {
  //     params: {
  //       shop,
  //     },
  //   })
  //   .then((response) => {
  //     return {
  //       data: {
  //         data: response.data.smartCart,
  //       },
  //     }
  //   })
}

export function updateSmartCart(shop, payload) {
  if (!payload.id) {
    return null
  }
  return fakeApi({ data: { smartCart: { ...payload } } }, 1000)
  // return api.put(`/aov/smartcart/`, payload, {
  //   params: {
  //     shop,
  //   },
  // })
}
