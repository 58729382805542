import { useState } from 'react'
import { widgetSave as widgetSaveApi } from 'api/widgets'

export default function ({ account, widgets, onboardingState }) {
  const [enablingWidgets, setEnablingWidgets] = useState(null)
  const { name: shop } = account.data

  async function enableWidgets() {
    setEnablingWidgets(true)
    await Promise.all(
      widgets.map((widget) => {
        return widgetSaveApi(
          widget.id,
          { enabled: true },
          {
            shop,
          }
        )
      })
    )
    setEnablingWidgets(false)
  }

  return { enablingWidgets, enableWidgets }
}
