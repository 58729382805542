import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

import { uploadImage as uploadImageApi } from 'api/image'
import { useHelpArticle } from 'components/HelpArticle'
import ColorPickerComp from 'components/ColorPicker'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { Select, Switch } from 'components/Form/controls'
import usePrevious from 'hooks/usePrevious'
import { _get, _pick } from 'utils/lodash'
import { urlFromFile, imageElementFromUrl } from 'utils/browser'
import {
  getAccountData,
  getStoreCurrency,
  translatableLocales,
} from 'utils/account'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useOpenModal } from 'components/Modal'
import Box from 'components/Box'

export default function EditorForm({
  layout,
  value,
  handleUpdate,
  turnOffAutoFocus = true,
  disabled,
  formError,
}) {
  const accountData = getAccountData()
  return (
    <React.Fragment>
      {layout.map((item, index) => {
        const isArray = Array.isArray(item)
        let content
        if (isArray) {
          content = (
            <React.Fragment key={`formRow-${index}`}>
              {item.map((item, index) => {
                const ControlComponent =
                  CONTROL_TYPE_TO_COMPONENT_MAP[item?.controlType]

                let itemKey
                let itemValue
                if (Array.isArray(item.key)) {
                  itemKey = item.key.join('')
                  itemValue = _pick(value, item.key)
                } else {
                  itemKey = item.key
                  itemValue = _get(value, item.key)
                }

                return (
                  <div
                    key={itemKey || `formRowItem-${index}`}
                    className={classNames('flex flex-1', {
                      'mr-3': index === 0 && item.controlType !== 'switch_icon',
                      'ml-3': index === 1 && item.controlType !== 'switch_icon',
                      'opacity-40 pointer-events-none': disabled,
                    })}
                  >
                    {ControlComponent ? (
                      <ControlComponent
                        value={itemValue}
                        accountData={accountData}
                        onChange={(v) => {
                          if (Array.isArray(item.key)) {
                            handleUpdate(v)
                          } else {
                            handleUpdate({ [item.key]: v })
                          }
                        }}
                        controlProps={{
                          disabled: disabled || item.disabled,
                          label: item.label,
                          options: item.options,
                          helpText: item.helpText,
                          helpArticle: item.helpArticle,
                          autoFocus:
                            !turnOffAutoFocus &&
                            index === 0 &&
                            (item.controlType === 'input' ||
                              item.controlType === 'textarea'),
                          ...(formError && formError[item.key]
                            ? { formError: formError[item.key] }
                            : {}),
                        }}
                        {...(item.Icon ? { Icon: item.Icon } : {})}
                      />
                    ) : null}
                  </div>
                )
              })}
            </React.Fragment>
          )
        } else {
          const ControlComponent =
            CONTROL_TYPE_TO_COMPONENT_MAP[item.controlType]

          let itemKey
          let itemValue
          if (Array.isArray(item.key)) {
            itemKey = item.key.join('')
            itemValue = _pick(value, item.key)
          } else {
            itemKey = item.key
            itemValue = _get(value, item.key)
          }
          content = (
            <div
              className={classNames('flex flex-1', {
                'opacity-40 pointer-events-none': disabled,
              })}
              key={itemKey}
            >
              <ControlComponent
                value={itemValue}
                onChange={(v) => {
                  if (Array.isArray(item.key)) {
                    handleUpdate(v)
                  } else {
                    handleUpdate({ [item.key]: v })
                  }
                }}
                accountData={accountData}
                controlProps={{
                  disabled: disabled || item.disabled,
                  label: item.label,
                  options: item.options,
                  helpText: item.helpText,
                  helpArticle: item.helpArticle,
                  autoFocus:
                    !turnOffAutoFocus &&
                    index === 0 &&
                    (item.controlType === 'input' ||
                      item.controlType === 'textarea'),
                  ...(formError && formError[item.key]
                    ? { formError: formError[item.key] }
                    : {}),
                }}
                {...(item.Icon ? { Icon: item.Icon } : {})}
              />
            </div>
          )
        }

        return (
          <div
            className={classNames('flex flex-row items-start justify-between', {
              'mt-6': index > 0,
            })}
            key={index}
          >
            {content}
          </div>
        )
      })}
    </React.Fragment>
  )
}

const CONTROL_TYPE_TO_COMPONENT_MAP = {
  single_select: SingleSelect,
  textarea: TextArea,
  number: NumberInput,
  currency: CurrencyInput,
  percentage: PercentageInput,
  translation: TranslationInput,
  input: Input,
  switch_select: SwitchSelect,
  switch_toggle: SwitchToggle,
  switch_icon: SwitchIcon,
  timer: Timer,
  checkbox: Checkbox,
  image: Image,
  color: ColorPicker,
}

let controlId = 0
export function SingleSelect({ value, onChange, controlProps }) {
  const {
    label,
    options,
    disabled,
    helpText,
    helpArticle,
    highlightType,
    formError,
  } = controlProps
  const selectId = `editor-select-${controlId++}`

  value = typeof value === 'undefined' ? '' : value

  return (
    <div className="flex flex-col items-stretch flex-1">
      <label htmlFor={selectId} className="mb-1 text-xs text-gray-600">
        {label}
      </label>
      <div
        className={classNames(
          'relative flex flex-col items-stretch w-full cursor-pointer group rounded',
          {
            'border-2 border-red-600': formError,
          }
        )}
      >
        <Select
          value={value}
          id={selectId}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          options={options}
        />
      </div>
      {formError && (
        <span className="mt-1 text-red-600 text-xxs">
          {formError.message || formError || 'Error'}
        </span>
      )}
    </div>
  )
}

export function TextArea({ ...restProps }) {
  return <Input tag={'textarea'} {...restProps} />
}

export function NumberInput({ value, onChange, controlProps }) {
  return (
    <Input
      value={value}
      onChange={onChange}
      controlProps={{
        ...controlProps,
        type: 'number',
      }}
    />
  )
}

export function Input({ value, onChange, tag = 'input', controlProps = {} }) {
  const {
    label,
    helpText,
    helpArticle,
    formError,
    currencySymbol,
    valType,
    ...restControlProps
  } = controlProps
  const inputId = `field-${tag}-${controlId++}`
  const Component = tag === 'input' ? 'input' : 'textarea'
  const openHelpArticle = useHelpArticle()

  value = typeof value === 'undefined' ? '' : value
  const isPercentOrCurr =
    valType && (valType === 'percentage' || valType === 'currency')
  const isPercent = valType && valType === 'percentage'
  const content = (
    <Component
      value={value}
      autoComplete="off"
      onChange={(e) => onChange(e.target.value)}
      id={inputId}
      className={classNames(
        'py-1 text-sm border border-gray-300 rounded resize-none w-full',
        {
          'border-red-600 focus-visible:outline-none border-2': formError,
          'px-5': isPercentOrCurr && !isPercent,
          'px-2': !isPercentOrCurr || isPercent,
        }
      )}
      {...restControlProps}
    />
  )
  return (
    <div className="flex flex-col items-stretch flex-1">
      {label && (
        <label htmlFor={inputId} className="mb-1 text-xs text-gray-600">
          {label}
        </label>
      )}
      {isPercentOrCurr ? (
        <div className="relative">
          {content}
          <span
            className={classNames('absolute text-sm top-1/2 -translate-y-1/2', {
              'left-2': !isPercent,
              'right-2': isPercent,
            })}
          >
            {valType === 'percentage'
              ? '%'
              : currencySymbol
              ? currencySymbol
              : '$'}
          </span>
        </div>
      ) : (
        <React.Fragment>{content}</React.Fragment>
      )}

      {helpText && (
        <div className="">
          <span className="text-gray-600 text-xxs">{helpText} </span>
          {helpArticle ? (
            <span
              className="font-medium cursor-pointer text-primary text-xxs hover:underline"
              onClick={() => openHelpArticle(helpArticle)}
            >
              Read more.
            </span>
          ) : null}
        </div>
      )}
      {formError && (
        <span className="text-red-600 text-xxs">
          {formError.message || formError || 'Error'}
        </span>
      )}
    </div>
  )
}

export function SwitchSelect({ value, onChange, controlProps = {} }) {
  const { label, helpText, helpArticle, formError, options = [] } = controlProps

  return options.length ? (
    <div className="flex flex-col items-stretch flex-1">
      {label && (
        <label
          htmlFor={controlProps.name}
          className="mb-1 text-xs text-gray-600"
        >
          {label}
        </label>
      )}
      <div className="flex flex-row">
        {options.map((option, index) => {
          const isSelected = option.value === value
          return (
            <span
              key={option.value}
              className={classNames(
                'py-1 px-3 border-t border-r border-b border-primary cursor-pointer',
                {
                  'bg-primary text-white': isSelected,
                  'bg-white text-primary hover:bg-gray-50': !isSelected,
                  'border-l': index === 0,
                  'rounded-l-md': index === 0,
                  'rounded-r-md': index === options.length - 1,
                }
              )}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </span>
          )
        })}
      </div>
      {helpText && (
        <div className="">
          <span className="text-gray-600 text-xxs">{helpText} </span>
        </div>
      )}
      {formError && (
        <span className="text-red-600 text-xxs">
          {formError.message || formError || 'Error'}
        </span>
      )}
    </div>
  ) : null
}

export function Timer({ value = '', onChange, controlProps = {} }) {
  const [enabled, setEnabled] = useState(!!value)
  const prevEnabled = usePrevious(enabled)
  const inputRef = useRef(null)
  const { label, helpText, helpArticle } = controlProps
  const checkboxId = `editor-checkbox-${controlId++}`

  useEffect(() => {
    if (
      !prevEnabled &&
      enabled &&
      inputRef.current &&
      typeof inputRef.current.focus === 'function'
    ) {
      inputRef.current.focus()
    }

    if (!enabled && value) {
      onChange('')
    }
  }, [enabled, inputRef, prevEnabled, onChange, value])

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <input
          type="checkbox"
          value={enabled}
          checked={enabled}
          id={checkboxId}
          name={checkboxId}
          className="w-4 h-4 border-gray-300 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 checked:bg-primary hover:bg-gray-100 focus:ring-primary text-primary"
          onChange={(e) => {
            setEnabled(e.target.checked)
            if (e.target.checked) {
              onChange('120')
            }
          }}
        />
        <label
          htmlFor={checkboxId}
          className="ml-2 text-xs text-gray-600 cursor-pointer"
        >
          {label}
        </label>
      </div>
      <div
        className={classNames('flex flex-row items-center mt-2', {
          'opacity-20 pointer-events-none': !enabled,
        })}
      >
        <div className="relative flex-1">
          <input
            type="number"
            value={value}
            className="w-full px-2 py-1 pr-8 text-sm border border-gray-300 rounded appearance-none"
            ref={inputRef}
            onChange={(e) => onChange(e.target.value)}
          ></input>
          <span className="absolute text-xs -translate-y-1/2 right-2 top-1/2 text-gray-">
            sec
          </span>
        </div>
        <span className="flex-1 flex-shrink-0 ml-2 text-gray-600 text-xxs">
          Use "{`{{ timer }}`}" to place timer in the line.
        </span>
      </div>
    </div>
  )
}

function Checkbox({ value, onChange, controlProps = {} }) {
  const { label, helpText, helpArticle, ...restControlProps } = controlProps
  const checkboxId = `editor-checkbox-${controlId++}`
  value = typeof value === 'undefined' ? false : value

  return (
    <div className="flex flex-row items-center">
      <input
        type="checkbox"
        value={value}
        checked={value}
        id={checkboxId}
        name={checkboxId}
        className="w-4 h-4 border-gray-300 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 checked:bg-primary hover:bg-gray-100 focus:ring-primary text-primary"
        onChange={(e) => {
          onChange(e.target.checked)
        }}
        {...restControlProps}
      />
      <label
        htmlFor={checkboxId}
        className="ml-2 text-xs text-gray-600 cursor-pointer"
      >
        {label}
      </label>
    </div>
  )
}

function Image({ value, onChange, controlProps = {} }) {
  const openToast = useToast()
  const { sourceKey, sourceFileName } = value
  const [uploading, setUploading] = useState(false)

  async function handleImageUpload(imageFile) {
    setUploading(true)
    try {
      const imageUrl = await urlFromFile(imageFile)
      const imageElem = await imageElementFromUrl(imageUrl)
      const aspectRatio = parseFloat(
        imageElem.width / imageElem.height
      ).toFixed(2)

      const imageName = imageFile.name ? imageFile.name : 'image'
      const { data: imageData } = await uploadImageApi({
        name: imageName,
        file: imageFile,
      })

      onChange({
        aspectRatio,
        sourceKey: imageData.key,
        sourcePrefix: imageData.prefix,
        sourceFullKey: imageData.fullKey,
        sourceFileName: imageName,
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in uploading the file. Please contact support',
      })
    }
    setUploading(false)
  }

  return sourceKey && sourceFileName ? (
    <div className="flex flex-row items-center w-full p-4 border border-dashed rounded-sm bg-gray-50 border-primary-300">
      <span className="mr-2 text-sm font-semibold text-primary">
        {sourceFileName || value}
      </span>
      <Icons.Delete
        onClick={() =>
          onChange({
            sourceKey: '',
            sourceFullKey: '',
            sourcePrefix: '',
            sourceFileName: '',
            aspectRatio: '',
          })
        }
        className="w-4 ml-auto text-red-400 cursor-pointer hover:text-red-600"
      />
    </div>
  ) : (
    <Dropzone
      accept="image/*"
      maxFiles={1}
      multiple={false}
      maxSize={2000000}
      disabled={uploading}
      onDropAccepted={(acceptedFiles) => handleImageUpload(acceptedFiles[0])}
      onDropRejected={(rejections) => {
        if (rejections && rejections.length > 0) {
          openToast({
            type: 'error',
            text: 'Only image file types are supported!',
          })
        }
      }}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <section className="w-full">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div
                className={classNames(
                  'flex flex-col items-center w-full p-3 text-xs border border-dashed rounded bg-gray-50 border-primary',
                  {
                    'cursor-wait': uploading,
                    'cursor-pointer': !uploading,
                  }
                )}
              >
                {uploading ? (
                  <React.Fragment>
                    <Loader className="w-6.5 my-2.5 text-primary" />
                    <span>Uploading...</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Icons.Upload className="w-6 mb-2 fill-current stroke-current text-primary" />
                    <span>Upload from device</span>
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
        )
      }}
    </Dropzone>
  )
}

function ColorPicker({ value, onChange, controlProps = {} }) {
  const { helpText, helpArticle, formError, label, ...restControlProps } =
    controlProps

  return (
    <div className={'flex flex-col items-stretch w-full'}>
      <div className="flex flex-row justify-between mb-1 text-xs">
        <label className="flex-1 text-gray-600" htmlFor={controlProps.name}>
          {label}
        </label>
        {formError && (
          <span className="font-semibold text-red-600">
            {formError.message || formError || 'Error'}
          </span>
        )}
      </div>
      <div className="relative">
        <input
          // {...restControlProps}
          type="text"
          value={value || ''}
          onChange={(event) => onChange(event.target.value)}
          className="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow hover:border-gray-500 focus:outline-none focus:shadow-outline"
        />

        <div className="absolute top-0 right-0 flex items-center h-full mr-2">
          <ColorPickerComp
            value={value}
            onChange={(value) => onChange(value)}
            pickerPosition="bottom"
          />
        </div>
      </div>
      {helpText && (
        <div className="flex justify-end mt-1 text-xs italic text-gray-600">
          {helpText}
        </div>
      )}
    </div>
  )
}

function CurrencyInput({ value, onChange, controlProps, accountData }) {
  const storeCurrency = getStoreCurrency(accountData)
  const currencySymbol = getSymbolFromCurrency(storeCurrency)
  return (
    <Input
      value={value.default}
      onChange={(changedValue) => {
        onChange({ default: changedValue })
      }}
      controlProps={{
        ...controlProps,
        type: 'number',
        currencySymbol,
        valType: 'currency',
      }}
    />
  )
}

function PercentageInput({ value, onChange, controlProps, accountData }) {
  return (
    <Input
      value={value}
      onChange={onChange}
      controlProps={{
        ...controlProps,
        type: 'number',
        valType: 'percentage',
      }}
    />
  )
}

function SwitchToggle({ value, onChange, controlProps, accountData }) {
  const { helpText, helpArticle, formError, label, ...restControlProps } =
    controlProps

  return (
    <div className="flex flex-col items-stretch w-full">
      <div className="flex flex-row items-center justify-between w-full">
        <label
          className="flex-1 text-xs text-gray-600"
          htmlFor={controlProps.name}
        >
          {label}
        </label>
        <Switch checked={value} onChange={onChange} />
      </div>
      {helpText && (
        <div className="flex justify-end mt-1 text-xs italic text-gray-600">
          {helpText}
        </div>
      )}
    </div>
  )
}

function SwitchIcon({ value, onChange, controlProps, Icon }) {
  const { helpText, helpArticle, formError, label, ...restControlProps } =
    controlProps
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center w-full cursor-pointer',
        {
          'text-primary': value,
          'text-gray-600': !value,
        }
      )}
      onClick={() => {
        onChange(!Boolean(value))
      }}
    >
      <Icon className={classNames('transition-colors duration-200', {})} />
      <label
        className={classNames('flex-1 text-xs mt-1 cursor-pointer', {})}
        htmlFor={controlProps.name}
      >
        {label}
      </label>
    </div>
  )
}

function TranslationInput({ value, onChange, controlProps, Icon }) {
  const { helpText, helpArticle, formError, label, ...restControlProps } =
    controlProps
  const openModal = useOpenModal()

  return (
    <div className="flex flex-col w-full">
      <Input
        value={value.default}
        onChange={(changedValue) => {
          onChange({ default: changedValue })
        }}
        controlProps={{
          ...controlProps,
        }}
      />
      <span
        className="w-full mt-1 font-semibold text-right cursor-pointer text-xxs text-primary hover:text-primary-500"
        onClick={() => {
          openModal(
            <TranslationsModal
              value={value}
              onChange={(localeTranslation) => {
                onChange({
                  ...value,
                  ...localeTranslation,
                })
              }}
            />,
            { fullScreen: false, className: 'w-3/5' }
          )
        }}
      >
        + Add translations
      </span>
    </div>
  )
}

function TranslationsModal({ value, onChange, close }) {
  const accountData = getAccountData()
  const allowedLocales = translatableLocales({ data: accountData })
  const [selectedLocale, setSelectedLocale] = useState(allowedLocales[0].value)
  const [translations, setTranslations] = useState(value || {})
  return (
    <Box>
      <Box.Heading>Translations</Box.Heading>
      <div className="flex flex-col">
        <div className="flex flex-row items-center h-full">
          <Select
            options={allowedLocales}
            value={selectedLocale}
            onChange={(event) => {
              const value = event.target.value
              setSelectedLocale(value)
            }}
          />
          <div className="w-2/3 h-full ml-4">
            <Input
              value={translations[selectedLocale]}
              onChange={(value) => {
                // console.log(translations)

                setTranslations({
                  ...translations,
                  [selectedLocale]: value,
                })
              }}
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-end mt-4">
          <Button
            size="small"
            onClick={() => {
              onChange(translations)
              close()
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Box>
  )
}
