const EMAIL_SUPPORT = 'support@glood.ai'

const REDIRECT_ON_LOGIN_STORAGE_KEY = 'loopRedirectOnLogin'
const GLOOD_MIGRATION_BANNER_STORAGE_KEY = 'loopGloodBannerHiddenAt'
const APP_EMBED_ENABLE_HELP_LINK =
  'https://glood-ai.notion.site/Activate-Personalized-Recommendations-App-Embed-Block-88c4889ada5a489094c38533a701628c'
const WIDGET_POSITIONING_HELP_LINK =
  'https://glood-ai.notion.site/Place-widgets-using-Shopify-Theme-Editor-1feeb5b94de34e3bbfa7065646736cf7'
const PRODUCT_DISCOUNTS_SHOPIFY_PLUS_HELP_LINK =
  'https://glood-ai.notion.site/Product-Discounts-for-Shopify-Plus-Customers-b75b643fc833464bbb759f3c7eed577c'
const LOOM_TUTORIAL_VIDEO_URL =
  'https://www.loom.com/embed/16977bb00c1347e98018a14c22e5023f/?hide_owner=true'

const BACKEND_DOMAIN = 'https://recommendationsapi.loopclub.io/'

const CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES = {
  ENABLE_PP_EXTENSION:
    'https://glood-ai.notion.site/Enable-Checkout-App-on-Post-Purchase-Page-aa4b09d94d5245648063759626ad33ea',
  METAFIELDS_CONFIGURATION:
    'https://glood-ai.notion.site/Using-Metafields-for-Upsell-Configuration-b9714c541ff44cf38728428125c5ff60',
  DYNAMIC_FIELDS:
    'https://glood-ai.notion.site/Dynamic-text-fields-in-promotions-c8e186b6fe4045e4b8aa0f4bb5cc81d2',
}

export {
  EMAIL_SUPPORT,
  REDIRECT_ON_LOGIN_STORAGE_KEY,
  GLOOD_MIGRATION_BANNER_STORAGE_KEY,
  APP_EMBED_ENABLE_HELP_LINK,
  WIDGET_POSITIONING_HELP_LINK,
  PRODUCT_DISCOUNTS_SHOPIFY_PLUS_HELP_LINK,
  LOOM_TUTORIAL_VIDEO_URL,
  BACKEND_DOMAIN,
  CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES,
}
