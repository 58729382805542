import React, { useEffect, useState } from 'react'

import { useToast } from 'components/Toast'
import { getAccountData } from 'utils/account'

import Editor from './Editor'
import Preview from './Preview'
import useLayout from './hooks/useLayout'

export default function PromotionBuilderLayout({ builder, rb }) {
  const openToast = useToast()
  const { promotion, savePromotionLayout, setSaveHandler, setActiveScreen } =
    builder

  const { setCloseHandler } = rb
  const activeStep = builder.promotion.steps[0]
  const [saved, setSaved] = useState(false)

  const accountData = getAccountData()
  const layout = useLayout({
    promotionType: builder.promotion.type,
    initialLayout: {
      sections: activeStep.sections,
      settings: activeStep.settings,
    },
  })

  useEffect(() => {
    async function promotionSave() {
      if (Object.keys(layout.formError).length > 0) {
        openToast({
          type: 'error',
          text: 'Correct all errors and save',
        })
        return
      }
      await savePromotionLayout(layout.data)
      setSaved(true)
    }

    async function confirmClose() {
      let confirmation = true
      if (!saved && layout.updated) {
        confirmation = window.confirm(
          'Any unsaved changes will be lost! Are you sure you want to close the window ?'
        )
      }
      return confirmation
    }

    setSaveHandler(() => promotionSave)
    setCloseHandler(() => confirmClose)
  }, [
    savePromotionLayout,
    setSaveHandler,
    setActiveScreen,
    setCloseHandler,
    layout,
  ])

  return (
    <div className="flex flex-row items-stretch h-full pt-1">
      <div className="flex items-stretch flex-1 h-full">
        <Preview layout={layout} accountData={accountData} />
      </div>
      <div className="relative flex w-1/4 h-full max-w-md">
        <Editor
          promotionType={promotion.type}
          layout={layout}
          accountData={accountData}
        />
      </div>
    </div>
  )
}
