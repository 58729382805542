import _get from 'lodash.get'
import _uniq from 'lodash.uniq'
import classNames from 'classnames'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { pathnameFromURL, fullURLFromPathname, validURL } from 'utils/browser'

export default function PageRules({ account, widget, onSave }) {
  const shopDomain = _get(account, 'data.shop.domain', null)
  const [updating, setUpdating] = React.useState(false)
  const [enabledUrls, setEnabledUrls] = React.useState(
    _get(widget, 'enabledUrls', [])
  )
  const { register, handleSubmit, setValue, watch, errors } = useForm({
    mode: 'onChange',
  })
  const watchPageUrl = watch('page_url')

  async function updateUrls(urls) {
    setUpdating(true)
    await onSave({
      enabledUrls: normalizeProtocol(urls),
    })
    setValue('page_url', null)
    setUpdating(false)
  }

  function onSubmit(data) {
    updateUrls(_uniq([...enabledUrls, data.page_url]))
  }

  function removeUrl(url) {
    updateUrls(_uniq(enabledUrls.filter((u) => u !== url)))
  }

  React.useEffect(() => {
    setEnabledUrls(_get(widget, 'enabledUrls', []))
  }, [widget])

  return (
    <div className="flex flex-col items-stretch flex-1">
      <Box className="flex flex-col items-stretch mb-6">
        <div className="flex flex-col flex-1">
          <span className="text-xl font-semibold">
            Add Pages to display widget on
          </span>
          <span className="text-xs text-gray-600">
            You can setup the widget to be displayed only on specific pages by
            adding them below.
          </span>
        </div>
        <form className="flex flex-col mt-3" onSubmit={handleSubmit(onSubmit)}>
          <Form.Field
            label={'Page URL'}
            name={'page_url'}
            control="input"
            placeholder="Paste URL of the page here..."
            ref={register({ validate: (value) => validURL(value) })}
            controlClassName="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none flex-1/2 hover:border-gray-500 focus:outline-none focus:shadow-outline"
          />
          {errors.page_url ? (
            <span className="-my-2 text-xs text-red-600">
              Please enter a valid URL
            </span>
          ) : (
            watchPageUrl &&
            shopDomain && (
              <span className="text-sm text-gray-800">
                Widget will be displayed on following page:{' '}
                <a
                  className="underline text-primary hover:text-primary-700"
                  href={`${watchPageUrl}`}
                  target="_blank"
                >
                  {fullURLFromPathname(
                    pathnameFromURL(watchPageUrl),
                    shopDomain
                  )}
                </a>
              </span>
            )
          )}
          <div className="flex flex-row justify-end">
            <Button size="small" disabled={!watchPageUrl || updating}>
              {updating ? 'Saving...' : '+ Add Page'}
            </Button>
          </div>
        </form>
      </Box>
      <Box className="flex flex-col items-stretch mb-6">
        <div className="flex flex-col flex-1">
          <span className="text-xl font-semibold">Visible On Pages</span>
        </div>
        <div className="flex flex-col items-stretch mt-3">
          {enabledUrls.map((url) => (
            <div
              key={url}
              className="flex flex-row items-center justify-between px-4 py-2 mb-3 text-white border border-gray-400 rounded"
            >
              <a
                target="_blank"
                href={url}
                className="flex-shrink-0 mr-3 text-sm underline text-primary notranslate"
              >
                {url}
              </a>
              <span
                className={classNames(
                  'ml-1 font-semibold cursor-pointer text-lg text-black hover:text-red-800',
                  {
                    'opacity-40': updating,
                  }
                )}
                onClick={() => !updating && removeUrl(url)}
              >
                {updating ? '...' : 'ⓧ'}
              </span>
            </div>
          ))}
        </div>
      </Box>
    </div>
  )
}

function normalizeProtocol(urls) {
  return urls.map((url) => {
    if (url.indexOf('http://') === 0) {
      return url.replace('http://', 'https://')
    }
    return url
  })
}
