import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import * as PAGES_OBJECT from 'constants/pages'
import * as WIDGETS_OBJECT from 'constants/widgets'
import Form from 'components/Form'
import classNames from 'classnames'
import Tooltip from 'components/Tooltip'
import Badge from 'components/Badge'
import { _get } from 'utils/lodash'
import * as Tracker from 'utils/tracker'
import Callout from 'components/Callout'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import { invalidate } from 'utils/query'
import api from 'api'

export default function Details({ builder, rb }) {
  const {
    account: {
      data: {
        plan: { pagesEnabled },
      },
    },
    experience,
    widget,
    setSaveHandler,
    setSaveNextHandler,
    billing,
    openModal,
    account,
    openToast,
    setActiveScreen,
    createWidget,
    isExistingWidgetAllowedOnPageMap,
    experienceSpecificWidgets,
    setWidget: saveWidget,
    shop,
    setLocalWidget,
    localWidget,
    getConfirmation
  } = builder

  const PAGES = Object.values(PAGES_OBJECT)
    .map((page) => {
      const present = pagesEnabled.find((value) => value === page.value)
      if (present) {
        page.enable = true
      } else {
        page.enable = false
      }
      return page
    })
    .sort((p1, p2) => {
      if (p1.score > p2.score) {
        return 1
      } else if (p1.score < p2.score) {
        return -1
      }
      return 0
    })

  const WIDGETS = Object.values(WIDGETS_OBJECT)

  const [activePage, setPage] = useState(widget?.pageType || null)
  const [activeWidget, setWidget] = useState(widget?.type || null)
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: widget?.title || 'New Widget',
    },
  })

  useEffect(() => {
    rb.setCloseHandler(() => async () => getConfirmation())
  }, [widget])

  useEffect(() => {
    async function widgetSave(formData) {
      if (!activePage) {
        openToast({
          type: 'error',
          text: 'Page Type need to be selected.',
        })
        return
      }
      if (!activeWidget) {
        openToast({
          type: 'error',
          text: 'Widget need to be selected.',
        })
        return
      }
      let res
      if ('id' in widget) {
        const { id } = widget
        const { data } = await api.put(
          `widgets/${id}/`,
          { title: formData.title },
          {
            params: { shop },
          }
        )
        res = data
      } else {
        res = await createWidget({
          title: formData.title,
          type: activeWidget,
          pageType: activePage,
        })
      }
      saveWidget(res)
      invalidate(['pages', shop])
      return res
    }
    setSaveHandler(() => handleSubmit(widgetSave))
    setSaveNextHandler(() => async () => {
      await handleSubmit(
        async function onSubmit(formData) {
          const widget = await widgetSave(formData)
          if (widget) {
            localWidget.title = formData.title
            localWidget.type = activeWidget
            localWidget.pageType = activePage
            setLocalWidget(localWidget)
            setActiveScreen('layout')
          }
        },
        function onError(errors, e) {
          setActiveScreen('detail')
        }
      )()
    })
  }, [activePage, activeWidget, saveWidget, widget])

  function widgetUpgradeAccount(widget) {
    let widgetToEnable = null
    Object.keys(WIDGETS_OBJECT).some((widgetKey) => {
      if (WIDGETS_OBJECT[widgetKey].value === widget.value) {
        widgetToEnable = WIDGETS_OBJECT[widgetKey]
        return true
      }
      return false
    })
    openModal(
      <UpgradeAccountModal account={account} widgetToEnable={widgetToEnable} />
    )
  }

  function pageUpgradeModal(page) {
    openModal(<UpgradeAccountModal account={account} pageToEnable={page} />)
  }

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-col w-full px-16 py-10">
        <Form.FieldRow>
          <Form.Field
            control="input"
            name="title"
            label="Title"
            ref={register({
              required: 'Widget title cannot be empty',
            })}
            formError={errors.title}
          />
          <Form.Field
            control="input"
            name="experience"
            label="A/B Experience"
            disabled
            value={experience.name}
            controlClassName="cursor-not-allowed"
          />
        </Form.FieldRow>
      </div>
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className="flex flex-col w-full px-16 py-10">
        <PagesSelector
          widget={widget}
          onUpgrade={(page) => {
            pageUpgradeModal(page)
          }}
          PAGES={PAGES}
          activePage={activePage}
          setPage={setPage}
        />
      </div>
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className={'flex flex-col w-full px-16 py-10'}>
        <WidgetSelector
          recommendationsEnabled={_get(
            billing.data,
            'shopPlan.recommendationsEnabled',
            []
          )}
          WIDGETS={WIDGETS}
          activePage={activePage}
          activeWidget={activeWidget}
          setWidget={setWidget}
          onUpgrade={(widget) => {
            widgetUpgradeAccount(widget)
          }}
          isExistingWidgetAllowedOnPageMap={isExistingWidgetAllowedOnPageMap}
          experienceSpecificWidgets={experienceSpecificWidgets}
          widget={widget}
        />
      </div>
    </div>
  )
}

function PagesSelector({ PAGES, activePage, setPage, onUpgrade, widget }) {
  return (
    <div className="block">
      <h1 className=" text-lg font-semibold">
        {DETAIL_SCREEN_TEXT.SELECT_PAGE_HEADING}
      </h1>
      <p className="mt-2 text-base text-gray-500 mb-4">
        {DETAIL_SCREEN_TEXT.SELECT_PAGE_SUBHEADING}
      </p>
      <div
        className={classNames('flex flex-wrap gap-4', {
          'opacity-50': 'enabled' in widget,
        })}
      >
        {PAGES.map((page, index) => (
          <Tooltip
            key={index}
            content={
              'enabled' in widget
                ? "You can't change page type once a widget is created"
                : !page.enable
                ? 'Enable this page by upgrading your plan'
                : page.description
            }
          >
            <div
              className={classNames(
                'py-2 px-3 shadow-md duration-100 transition-all ease-in-out rounded-lg flex',
                ' hover:shadow-lg hover:bg-slate-50 border border-bg-slate-50',
                {
                  'border border-primary-500 bg-primary-50':
                    page.value === activePage,
                  'hover:border hover:border-primary-500':
                    page.enable && !('enabled' in widget),
                  'hover:border hover:border-gray-400':
                    !page.enable && !('enabled' in widget),
                  'cursor-not-allowed': 'enabled' in widget,
                  'cursor-pointer': !('enabled' in widget),
                }
              )}
              onClick={() => {
                if ('enabled' in widget) {
                  return
                }
                if (page.enable) {
                  setPage(page.value)
                } else {
                  onUpgrade(page)
                }
              }}
            >
              {!page.enable && <Icons.Lock className="w-3 mr-1" />}
              {page.label}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

function WidgetSelector({
  WIDGETS,
  activePage,
  activeWidget,
  setWidget,
  recommendationsEnabled,
  onUpgrade,
  isExistingWidgetAllowedOnPageMap,
  experienceSpecificWidgets,
  widget: widgetState,
}) {
  WIDGETS = WIDGETS.map((widget) => {
    if (Boolean(recommendationsEnabled.includes(widget.value))) {
      widget.disabled = false
    } else {
      widget.disabled = true
    }
    return widget
  })

  return (
    <div className="block">
      <h1 className=" text-lg font-semibold">
        {DETAIL_SCREEN_TEXT.SELECT_WIDGET_HEADING}
      </h1>
      <p className="mt-2 text-base text-gray-500">
        {DETAIL_SCREEN_TEXT.SELECT_WIDGET_SUBHEADING}
      </p>
      <div
        className={'grid grid-cols-1 mt-4 md:grid-cols-2 xl:grid-cols-3 gap-4'}
      >
        {WIDGETS.map((widget, index) => {
          let isAvailableToCreate = false

          if (activePage) {
            isAvailableToCreate =
              isExistingWidgetAllowedOnPageMap[widget.value][activePage]
          }

          const isEnabled = Boolean(
            widget.allowedOnPages.find((page) => page.value === activePage)
          )

          const isRecommended = widget.recommendedPages.find(
            (page) => page.value === activePage
          )

          const disabledWidgets = experienceSpecificWidgets.reduce(
            (prev, { type, pageType, enabled }) => {
              if (
                widget.value === type &&
                pageType === activePage &&
                !enabled
              ) {
                prev++
              }
              return prev
            },
            0
          )

          const canBeEnabled = Boolean(disabledWidgets > 0)

          return (
            <Tooltip
              key={index}
              content={
                'enabled' in widgetState
                  ? "You can't change widget type once a widget is created"
                  : !activePage
                  ? DETAIL_SCREEN_TEXT.SELECT_WIDGET_NO_PAGE
                  : !isEnabled
                  ? DETAIL_SCREEN_TEXT.SELECT_WIDGET_NO_PAGE_SUPPORT
                  : !isAvailableToCreate
                  ? `Maximum allowed number of ${widget.label} on the ${
                      Object.values(PAGES_OBJECT).find(
                        (page) => page.value === activePage
                      ).label
                    } has reached. Please contact support to add them.`
                  : null
              }
            >
              <div
                className={classNames(
                  'flex group w-full h-full relative border-primary-500 p-4 shadow-md rounded-lg transition-all duration-100 ease-in-out',
                  widget.value === activeWidget
                    ? 'border border-primary-500 bg-primary-50'
                    : 'border border-gray-200',
                  {
                    'cursor-pointer hover:shadow-lg hover:border-primary-500':
                      isEnabled &&
                      isAvailableToCreate &&
                      !('enabled' in widgetState),
                    'cursor-not-allowed opacity-50':
                      !Boolean(activePage) ||
                      !isAvailableToCreate ||
                      'enabled' in widgetState,
                    'hover:border-gray-500':
                      (!isEnabled || !isAvailableToCreate) &&
                      !('enabled' in widgetState),
                  }
                )}
                onClick={() => {
                  if ('enabled' in widget) {
                    return
                  }
                  if (
                    isEnabled &&
                    Boolean(activePage) &&
                    !widget.disabled &&
                    isAvailableToCreate
                  ) {
                    setWidget(widget.value)
                    Tracker.record(`click:add_widget:${widget.value}`)
                  }
                }}
              >
                <div className="h-full  grid items-center p-2">
                  <div className=" w-20 h-20 flex items-center bg-gray-200 justify-center rounded-full">
                    <widget.icon className={'w-16'} />
                  </div>
                </div>
                <div className="flex grow flex-col p-1">
                  <div className="w-full flex mb-2 items-center">
                    <h1 className="text-lg font-semibold">
                      {widget.label}
                      <span
                        className={classNames(
                          'flex items-center absolute right-4 top-4 justify-center w-5 h-5 border border-gray-300 rounded ',
                          {
                            'bg-primary': widget.value === activeWidget,
                            'group-hover:bg-primary':
                              isEnabled &&
                              isAvailableToCreate &&
                              !('enabled' in widgetState),
                          }
                        )}
                      >
                        <Icons.Tick className="w-3 text-white stroke-current" />
                      </span>
                    </h1>
                    {isEnabled && isRecommended && (
                      <Badge
                        color={'primary'}
                        children={'Recommended'}
                        className=" ml-2 p-1"
                      />
                    )}
                  </div>
                  <p className=" text-xs text-gray-700">
                    {widget.description}
                    {widget.disabled && isEnabled && (
                      <Callout
                        className="self-start mt-2 flex w-max justify-between"
                        textSize="small"
                        noHoverEffect={true}
                      >
                        Upgrade plan to add this widget.
                        <Button
                          size="xxSmall"
                          className="ml-1"
                          onClick={() => {
                            if ('enabled' in widgetState) {
                              return
                            }
                            onUpgrade(widget)
                          }}
                        >
                          Start Free Trial
                        </Button>
                      </Callout>
                    )}
                  </p>
                </div>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}

const DETAIL_SCREEN_TEXT = {
  SELECT_PAGE_HEADING: 'Select Page Type',
  SELECT_PAGE_SUBHEADING: 'Complete the following step to continue',
  SELECT_WIDGET_HEADING: 'Select Widget',
  SELECT_WIDGET_SUBHEADING: 'Complete the following step to continue',
  SELECT_WIDGET_NO_PAGE: 'Select a page first',
  SELECT_WIDGET_NO_PAGE_SUPPORT: 'This widget does not support selected page',
  WIDGET_NUMBER_EXCEED:
    'Total number of this widget exceeds the maximum widgets possible for current experience.',
  WIDGET_CAN_BE_ENABLED:
    'This widget is currently disabled and can be enabled.',
}
