import React from 'react'
import { useForm } from 'react-hook-form'
import Form from 'components/Form'
import { validCssColor } from 'utils/browser'
import TranslationUpsell from '../TranslationUpsell'

export default function AmazonCartButtonForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Cart Button Background"
          name="atcBackgroundColor"
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          control="color"
          setColor={setValue}
          color={formValues.atcBackgroundColor}
          formError={errors.atcBackgroundColor}
        />
        <Form.Field
          label="Cart Button Text Color"
          name="atcTextColor"
          control="color"
          setColor={setValue}
          color={formValues.atcTextColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.atcTextColor}
        />
        <Form.Field
          label="Cart Button Text"
          name="addToCartText"
          control="input"
          ref={register({
            required: 'Required',
          })}
          formError={errors.addToCartText}
          supportElement={
            <TranslationUpsell
              templateId={props.widget.template}
              store={props.store}
            />
          }
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, translations }) {
  return {
    atcBackgroundColor: settings.amazonBoughtTogether.buttonBackgroundColor,
    atcTextColor: settings.amazonBoughtTogether.buttonTextColor,
    addToCartText: translations.primaryLocale.fbtAddToCartText,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      amazonBoughtTogether: {
        buttonBackgroundColor: values.atcBackgroundColor,
        buttonTextColor: values.atcTextColor,
      },
    },
    translations: {
      primaryLocale: {
        fbtAddToCartText: values.addToCartText,
      },
    },
  }
}
