import React, { useCallback, useState } from 'react'
import { getAccountData } from 'utils/account'

import { _find, _identity, _get } from 'utils/lodash'

export default function useResourceBuilder({ screens, resource, close }) {
  const [closeHandler, setCloseHandler] = useState(_identity)
  const accountData = getAccountData()

  return {
    screens,
    accountData,
    resource,
    setCloseHandler,
    close: useCallback(
      async ({ skipCloseHandler }) => {
        let shouldClose = true
        const shouldSkipCloseHandler = Boolean(skipCloseHandler)
        console.log('skipCloseHandler', shouldSkipCloseHandler)
        if (!shouldSkipCloseHandler && typeof closeHandler === 'function') {
          shouldClose = await closeHandler()
        }
        shouldClose && close()
      },
      [close, closeHandler]
    ),
  }
}
