import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import CodeEditor from 'components/CodeEditor'
import { escapeSpecialChars } from 'utils/json'
import { canUpdateTemplate } from 'utils/features'
import { isRkSupport } from 'utils/account'
import { EMAIL_SUPPORT } from 'constants/support'

export default function TemplateSettings({
  account,
  template,
  onSave,
  onChange,
  isSaving,
  openUpgradeAccount,
}) {
  const [settings, setSettings] = React.useState(
    JSON.stringify(template.settings, null, 4)
  )
  const canCreateTemplate = canUpdateTemplate(account.data)
  const openToast = useToast()

  function handleOnClick() {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      try {
        const settingsJSON = JSON.parse(escapeSpecialChars(settings))
        onSave({ settings: settingsJSON })
      } catch (err) {
        openToast({
          type: 'error',
          text: 'Error updating! Please ensure JSON is valid',
        })
      }
    }
  }

  function handleChange(value) {
    setSettings(value)
  }

  return (
    <div className="flex flex-col items-stretch">
      <Text.ScreenHeading marginBottom={1}>Edit Settings</Text.ScreenHeading>
      <Text.SubText marginBottom={2}>
        Proficient with JSON? Edit away. For any assistance please write to
        <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <div className="flex flex-col items-start flex-1 w-full">
        <div className="flex flex-row flex-wrap w-full">
          <CodeEditor
            placeholder="You can edit/add Javascript code here."
            mode="json"
            value={settings}
            onChange={setSettings}
            name="shop_json_editor"
            rkSupport={isRkSupport(account.data)}
          />
        </div>
        <Button loading={isSaving} onClick={handleOnClick}>
          <div className="flex items-center justify-center">
            <span>Save</span>
            {!canCreateTemplate && (
              <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
            )}
          </div>
        </Button>
      </div>
    </div>
  )
}
