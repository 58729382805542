import React from 'react'
import _get from 'lodash.get'
import classNames from 'classnames'
import { animateScroll } from 'react-scroll'
import Button from 'components/Button'
import Onboarding from 'components/Onboarding'
import * as Widgets from 'constants/widgets'
import useCreateWidget from './hooks/useCreateWidget'
import { useApi } from 'hooks/api'
import { useTrackView } from 'hooks/analytics'
import { trackEvent } from 'utils/analytics'
import * as Tracker from 'utils/tracker'

export default function ({ onboardingState, account, billing }) {
  const {
    stages: { next: nextStage, previous: previousStage },
    data: onboardingData,
    updateOnboarding,
  } = onboardingState
  const { name: shop, id: shopId } = account.data

  const pages = useApi('pages/', { shop })
  const { widget, setWidget, checkedPages, setCheckedPages, createWidget } =
    useCreateWidget({
      pages,
    })
  const [savingWidget, setSavingWidget] = React.useState(false)

  function togglePage(page, selected) {
    if (selected) {
      setCheckedPages([page])
    } else {
      setCheckedPages([])
    }
  }

  function onBack() {
    if (widget) {
      setWidget(null)
      setCheckedPages([])
    } else {
      updateOnboarding({
        stage: previousStage,
      })
    }
  }

  useTrackView({
    category: 'Onboarding',
    action: 'Load',
    label: 'Widget Setup',
  })

  let content = null
  if (widget) {
    content = (
      <PageSelect
        pages={widget.allowedOnPages}
        checkedPages={checkedPages}
        onTogglePage={togglePage}
        widget={widget}
      />
    )
  } else {
    content = (
      <WidgetSelect
        onSelect={(widget) => {
          setWidget(widget)
        }}
        recommendationsEnabled={_get(
          billing.data,
          'shopPlan.recommendationsEnabled',
          []
        )}
      />
    )
  }

  React.useEffect(() => {
    Tracker.record('view:onboarding:widget_setup')
  }, [])

  return (
    <React.Fragment>
      <Onboarding.Box>{content}</Onboarding.Box>
      <Onboarding.ActionTray>
        {widget && (
          <Button variant="bordered" className="mr-5" onClick={onBack}>
            Back
          </Button>
        )}
        <Button
          variant="solid"
          loading={savingWidget}
          onClick={async () => {
            Tracker.record('click:onboarding:create_widgets')
            trackEvent({
              category: 'Onboarding',
              action: 'Save Widget',
              label: 'Widget Setup',
              value: `${checkedPages[0].value}:${widget.value}`,
            })
            setSavingWidget(true)

            const data = await createWidget(
              widget,
              checkedPages.map(({ value }) => value),
              shop,
              shopId
            )

            updateOnboarding({
              stage: nextStage,
              widgetIds: [
                ...data.map((w) => w.id),
                ...(onboardingData.widgetIds || []),
              ],
              hasSetupWidgetsOnce: true,
            })
          }}
          disabled={checkedPages.length === 0}
        >
          Save & Next
        </Button>
      </Onboarding.ActionTray>
    </React.Fragment>
  )
}

const ORDER = [
  Widgets.SIMILAR_PRODUCTS.value,
  Widgets.BOUGHT_TOGETHER.value,
  Widgets.PERSONALIZED.value,
  Widgets.CROSS_SELL.value,
  Widgets.BESTSELLERS.value,
  Widgets.RECENTLY_VIEWED.value,
  Widgets.RECENTLY_LAUNCHED.value,
  Widgets.TRENDING.value,
  Widgets.PROMOTED_PRODUCTS.value,
  Widgets.PURCHASES.value,
  // Widgets.DISCOUNTS.value,
  Widgets.CUSTOM_WIDGET.value,
  Widgets.COLLECTIONS_WIDGET.value,
]

function WidgetSelect({ onSelect, recommendationsEnabled }) {
  const widgets = Object.keys(Widgets)
    .map((k) => Widgets[k])
    .map((widget) => ({
      ...widget,
      disabled: !recommendationsEnabled.includes(widget.value),
    }))
    .sort((widget1, widget2) => {
      const widget1Score = widget1.disabled
        ? 1000
        : ORDER.indexOf(widget1.value)
      const widget2Score = widget2.disabled
        ? 1000
        : ORDER.indexOf(widget2.value)

      return widget1Score - widget2Score
    })

  return (
    <React.Fragment>
      <Onboarding.BoxHeading>
        Select a type of Recommendation to add
      </Onboarding.BoxHeading>
      <div className="flex flex-wrap -mx-3">
        {widgets.map((widget, index) => (
          <div className="w-full px-4 mb-6 lg:w-1/2" key={widget.value}>
            <WidgetItem
              widget={widget}
              onClick={() => {
                onSelect(widget)
                Tracker.record(`click:onboarding:widget:${widget.value}`)
              }}
              index={index}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

function WidgetItem({ widget, onClick, index }) {
  const { disabled } = widget

  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={classNames(
        'flex items-start p-4 cursor-pointer rounded h-full border lg:border-0',
        {
          'flex-row': index % 2 === 0,
          'lg:flex-row-reverse': index % 2 === 1,
          'group hover:bg-gray-300': !disabled,
          'opacity-50 cursor-not-allowed': disabled,
        }
      )}
    >
      <div
        className={classNames('rounded-full p-2 bg-gray-200 mr-3', {
          'lg:ml-3 lg:mr-0': index % 2 === 1,
        })}
      >
        <widget.icon
          className="opacity-50 group-hover:opacity-100"
          height="40"
          width="40"
        />
      </div>
      <div
        className={classNames('flex flex-col items-stretch', {
          'mr-auto': index % 2 === 1,
        })}
      >
        <div className="mb-2 text-sm font-semibold uppercase">
          {widget.label}
        </div>
        <p className="text-xs leading-tight">{widget.description}</p>
      </div>
    </div>
  )
}

function PageSelect({ widget, pages = [], checkedPages, onTogglePage }) {
  return (
    <React.Fragment>
      <h2 className="pb-2 mb-6 text-xs font-bold leading-tight tracking-wide text-black uppercase border-b">
        {widget.label}
      </h2>
      <Onboarding.BoxHeading>
        Choose a page to display this widget on
      </Onboarding.BoxHeading>
      <div className="flex flex-col items-stretch">
        {pages
          .sort((pageOne, pageTwo) => {
            const pageOneScore = widget.recommendedPages.includes(pageOne)
              ? 1
              : 0
            const pageTwoScore = widget.recommendedPages.includes(pageTwo)
              ? 1
              : 0

            return pageTwoScore - pageOneScore
          })
          .map((page) => (
            <div className="px-2 mb-2" key={page.value}>
              <PageItem
                page={page}
                checkedPages={checkedPages}
                onTogglePage={onTogglePage}
                widget={widget}
              />
            </div>
          ))}
      </div>
      <div className="flex justify-end mt-1 text-xs italic text-gray-600">
        You'll be able to add more widgets after onboarding.
      </div>
    </React.Fragment>
  )
}

function PageItem({ widget, page, checkedPages, onTogglePage }) {
  const isRecommended = widget.recommendedPages.includes(page)
  const pageDescription =
    widget.recommendedPageDescriptions[page.value] || page.description
  return (
    <div
      className={classNames(
        'relative overflow-hidden flex items-start border rounded group hover:bg-gray-300 pr-3 mb-3',
        {
          'opacity-75': !isRecommended && !checkedPages.includes(page),
        }
      )}
      key={page.value}
    >
      <label
        className="flex flex-col items-start flex-1 p-3 cursor-pointer xl:pr-12"
        htmlFor={page.value}
      >
        <div className="mb-2 text-sm font-semibold uppercase">{page.label}</div>
        {pageDescription && (
          <p className="mb-2 text-xs leading-tight">{pageDescription}</p>
        )}
        {isRecommended && (
          <div className="px-1 text-xs text-white bg-primary rounded-sm">
            Recommended
          </div>
        )}
      </label>
      <input
        id={page.value}
        type="radio"
        className="mt-3"
        checked={checkedPages.indexOf(page) !== -1}
        onChange={(e) => {
          if (e.target.checked) {
            animateScroll.scrollToBottom()
          }
          onTogglePage(page, e.target.checked)
        }}
      />
    </div>
  )
}

// function ThemeSelect({ widget, shop }) {
//   const { data: themes } = useApi('shopify/themes/', { shop })

//   return (
//     <React.Fragment>
//       <h2 className="pb-2 mb-6 text-xs font-bold leading-tight tracking-wide text-black uppercase border-b">
//         {widget.label}
//       </h2>
//       <Form.Field
//         label="Choose your store's theme"
//         name="storeTheme"
//         control="select"
//         options={[]}
//         helpText="Please choose your store's theme from the list. This will help us to ensure that the widgets are in sync with your store's UI."
//       />
//     </React.Fragment>
//   )
// }
