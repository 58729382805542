export const FUNNEL_STATUS_OPTIONS = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Active',
    actionLabel: 'Publish',
    value: 'active',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Paused',
    value: 'paused',
    actionLabel: 'Pause',
  },
  {
    label: 'Deleted',
    actionLabel: 'Delete',
    value: 'deleted',
  },
]

export const LIMIT_BANNER_UPDATES = true
