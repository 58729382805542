import React from 'react'
import Button from 'components/Button'
import classNames from 'classnames'
import * as Tracker from 'utils/tracker'
import Icons from 'components/Icons'

const styles = {
  container: {
    position: 'relative',
  },
  locked: {
    opacity: 0.1,
    pointerEvents: 'none',
  },
  unlocked: {},
  icon: {
    opacity: 1,
    padding: '20px',
    height: '100px',
    width: '100%',
    position: 'absolute',
    top: '100px',
    marginTop: '-50px',
    textAlign: 'center',
    color: 'black',
  },
}

export default function Locked({ account, locked = false, children }) {
  const { name: shop, preferredPlan } = account.data

  async function onUpgrade() {
    Tracker.record('integration:start_trial')
    await Tracker.flush()
    window.top.location.href = `${preferredPlan.chargeUrl}&shop=${shop}`
  }

  return (
    <div style={styles.container}>
      <div style={locked ? styles.locked : {}}>{children}</div>
      {locked && (
        <div style={styles.icon}>
          <div className="flex justify-center w-full">
            <Icons.Lock className="w-6 h-6 my-3 fill-current align-center"></Icons.Lock>
          </div>
          <div>
            Start Your Free trial to integrate with your other applications to
            increase conversions.
          </div>
          <Button size="regular" className="mt-2" onClick={onUpgrade}>
            Start Free Trial
          </Button>
        </div>
      )}
    </div>
  )
}
