import pluralize from 'pluralize'
import { v4 as uuid } from 'uuid'
import { _get, _find } from 'utils/lodash'
import { WORKFLOW_TASK_TYPES } from './constants'
class Edge {
  constructor({ id, nextNode, previousNode, condition, extra }) {
    this.id = id
    this.previousNode = previousNode
    this.node = nextNode
    this.condition = condition
    this.extra = extra
  }
}

class Node {
  constructor(task) {
    this.id = task.id
    this.tempId = task.tempId
    this.task = task
    this.left = null
    this.right = null
    this.center = null
    this.updated = false
  }

  setId = (id) => {
    this.id = id
    this.task = { ...this.task, id }
  }

  updateTask = (update) => {
    this.task = { ...this.task, ...update }
    this.updated = true
  }

  attach = ({ id, condition, extra }, nextNode) => {
    const edge = new Edge({
      id,
      nextNode,
      previousNode: this,
      condition,
      extra,
    })

    if (condition === null) {
      this.center = edge
    } else if (condition === true) {
      this.right = edge
    } else if (condition === false) {
      this.left = edge
    }
  }
}

let rootNode
export const getWorkflowRoot = (workflow, nodeDetails) => {
  if (
    !workflow ||
    !workflow.nodes ||
    nodeDetails.length < 1 ||
    !workflow.startNode
  ) {
    return null
  }
  const nodes = workflow.nodes.reduce((map, nodeId) => {
    const task = nodeDetails.find((node) => node.id == nodeId)

    const node = new Node(task)
    if (workflow.startNode === nodeId) {
      rootNode = node
    }

    map[node.id] = node
    return map
  }, {})

  if (!rootNode) {
    return null
  }

  workflow.edges.forEach((edge) => {
    const previousNode = nodes[edge.previousId]
    const nextNode = nodes[edge.nextId]
    if (!previousNode || !nextNode) {
      return
    }

    previousNode.attach(edge, nextNode)
  })

  return rootNode
}

export const addNewNode = ({
  type,
  edge,
  previousNode,
  isRoot,
  settings = {},
}) => {
  if (!type || (!isRoot && !previousNode)) {
    return
  }

  const node = new Node({
    ...DEFAULT_TASK_CONFIG,
    tempId: uuid(),
    type,
    settings,
  })

  if (isRoot) {
    return node
  }

  if (edge) {
    /*
    
    TODO(Azhar): If edge exists then new node is being added in the 
    middle of two nodes. Update the existing edge accordingly.
    Also need to check the case where this node might be replacing the root node.
    
    // edge.previousNode.attach({ id: uuid() }, node)
    // if (!edge.previousNode) {
    //   return
    // }
    
    */

    return node
  }

  previousNode.attach({ id: uuid(), condition: null }, node)
  return node
}

export const getWorkflowObject = (root) => {
  if (!root) {
    return {
      root,
      tasks: [],
      edges: [],
    }
  }
  let tasks = []
  let edges = []
  function getAllTasksAndEdges(node) {
    if (node.task) {
      tasks.push(node.task)
    }
    if (node.center) {
      edges.push({
        id: node.center.id,
        previousId: node.task.id || node.task.tempId,
        nextId: node.center.node.id || node.center.node.tempId,
        condition: null,
        // ...(node.center.node.tempId || node.task.tempId
        //   ? { isTemp: true }
        //   : {}),
      })
      getAllTasksAndEdges(node.center.node)
    }
    if (node.left) {
      edges.push({
        id: node.left.id,
        previousId: node.task.id || node.task.tempId,
        nextId: node.left.node.id || node.left.node.tempId,
        condition: false,
        // ...(node.left.node.tempId || node.task.tempId ? { isTemp: true } : {}),
      })
      getAllTasksAndEdges(node.left.node)
    }
    if (node.right) {
      edges.push({
        id: node.right.id,
        previousId: node.task.id || node.task.tempId,
        nextId: node.right.node.id || node.right.node.tempId,
        condition: true,
        // ...(node.right.node.tempId || node.task.tempId ? { isTemp: true } : {}),
      })
      getAllTasksAndEdges(node.right.node)
    }
  }
  getAllTasksAndEdges(root)

  return {
    startNode: root?.id,
    tasks,
    edges,
  }
}

export function getTaskNode(node, searchNode) {
  if (!searchNode) {
    return null
  }

  if (
    node.tempId == searchNode.tempId ||
    (typeof node.id != 'undefined' && node.id == searchNode.id)
  ) {
    return node
  }

  return (
    getTaskNode(node.left?.node) ||
    getTaskNode(node.center?.node) ||
    getTaskNode(node.right?.node)
  )
}

export const formatTimeLimit = (limit) => {
  if (limit < 60) {
    return pluralize('second', limit, true)
  } else if (limit < 60 * 60) {
    return pluralize('minute', limit / 60, true)
  } else if (limit < 60 * 24) {
    return pluralize('hour', limit / (60 * 60), true)
  } else {
    return pluralize('day', limit / (60 * 60 * 24), true)
  }
}

export const countLeafNodes = (node) => {
  if (!node) {
    return 0
  }

  if (!node.left && !node.center && !node.right) {
    return 1
  }

  return (
    countLeafNodes(node.center?.node) +
    Math.max(
      countLeafNodes(node.left?.node),
      countLeafNodes(node.right?.node)
    ) *
      2
  )
}

export const sectionLabelFromType = (type) => {
  return _get(
    _find(
      Object.keys(WORKFLOW_TASK_TYPES).map((k) => WORKFLOW_TASK_TYPES[k]),
      { value: type }
    ),
    'label'
  )
}

export const canDeleteTask = ({ node }) => {
  if (!node.center && (node.left || node.right)) {
    return false
  }
  return Boolean(node)
}

export const deleteTask = ({ node, isRoot }) => {
  if (!node.center && (node.left || node.right)) {
    return null
  }

  if (isRoot) {
    rootNode = node.center?.node
    return rootNode
  }

  const parentNode = getParentNode({ node: rootNode, nodeId: node.id })
  if (parentNode) {
    if (node.center?.node) {
      parentNode.attach({ id: uuid(), condition: null }, node.center.node)
    } else {
      parentNode.center = null
    }
  } else {
    return null
  }

  return rootNode
}

const getParentNode = ({ node, nodeId }) => {
  if (!node || !node.center) {
    return null
  }
  if (node.center?.node.id == nodeId) {
    return node
  }

  return getParentNode({ node: node.center.node, nodeId })
}

const DEFAULT_TASK_CONFIG = {
  status: 'active',
  group: 'action',
}
