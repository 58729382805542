import React, { useCallback, useState } from 'react'

import { useToast } from 'components/Toast'
import * as QueryUtils from 'utils/query'
import { _find, _identity, _get } from 'utils/lodash'
import {
  createBundle as createBundleApi,
  saveBundle as saveBundleApi,
} from 'api/bundles'
import useBundleMutations from 'hooks/queries/bundles'
import { getAccountData } from 'utils/account'

export default function useBundleBuilder({
  initialBundle = {},
  initialActiveScreen,
  store,
}) {
  const [bundle, setBundle] = useState(initialBundle)
  const [activeScreen, setActiveScreen] = useState(
    getInitialScreen({ bundle: initialBundle, initialActiveScreen })
  )
  const [saveHandler, setSaveHandler] = useState(_identity)
  const [saveNextHandler, setSaveNextHandler] = useState(_identity)
  const [publishHandler, setPublishHandler] = useState(_identity)
  const [bundleSaveError, setBundleSaveError] = useState(null)
  const [savingButton, setSavingButton] = useState(null)
  const openToast = useToast()
  const accountData = getAccountData()
  const { name: shop } = accountData

  const saveBundle = useCallback(
    async (
      data,
      {
        successMessage = 'Bundle saved successfully!',
        errorMessage = 'There was an error in saving the bundle.',
      } = {}
    ) => {
      if (bundle?.id) {
        data.slug = bundle.slug
        data.id = bundle.id
      }

      setBundleSaveError(null)
      try {
        let response
        if (!data.id) {
          response = await createBundleApi(shop, data)
        } else {
          response = await saveBundleApi(shop, data)
        }
        const {
          data: { bundle: savedBundle },
        } = response

        QueryUtils.invalidate(['bundles'])
        QueryUtils.invalidate(['bundle'])
        openToast({
          type: 'success',
          text: successMessage,
        })
        setBundle(savedBundle)
        return savedBundle
      } catch (err) {
        openToast({
          type: 'error',
          text: errorMessage,
        })
        setBundleSaveError(err.response.data)
      }
    },
    [bundle?.id]
  )

  return {
    screens: SCREENS,
    accountData,
    store,
    activeScreen,
    bundle,
    setBundle,
    saveBundle,
    saveHandler,
    setSaveHandler,
    saveNextHandler,
    setSaveNextHandler,
    publishHandler,
    setPublishHandler,
    savingButton,
    setSavingButton,
    heading: 'Create Bundle',
    setActiveScreen: useCallback(
      (name) => {
        const screen = _find(SCREENS, { name })
        if (screen) {
          setActiveScreen(screen)
        }
      },
      [setActiveScreen]
    ),

    bundleSaveError,
  }
}

function getInitialScreen({ bundle, initialActiveScreen }) {
  if (initialActiveScreen) {
    return _find(SCREENS, { name: initialActiveScreen })
  }
  return _find(SCREENS, (s) => !s.complete(bundle)) || SCREENS[0]
}

export const SCREENS = [
  {
    name: 'details',
    label: 'Details',
    complete: (bundle) => bundle?.id && bundle?.name,
    disabled: () => false,
  },
  {
    name: 'trigger',
    label: 'Trigger',
    complete: (bundle) =>
      bundle?.triggers &&
      Object.keys(bundle.triggers).some(
        (triggerKey) => bundle.triggers[triggerKey].enabled
      ),
    disabled: (bundle) => !bundle?.id,
  },
  {
    name: 'bundle',
    label: 'Bundle',
    complete: (bundle) =>
      bundle.items?.products && bundle.items.products.length > 0,
    disabled: (bundle) => !bundle?.id,
  },
  {
    name: 'review',
    label: 'Review',
    complete: () => false,
    disabled: (bundle) => !bundle?.id,
  },
]
