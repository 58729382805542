import React from 'react'

import EditorForm from 'components/EditorForm'
import { CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES } from 'constants/support'

export default function PromotionBuilderLayoutEditorTextSection({
  section,
  handleUpdate,
}) {
  return (
    <div className="flex flex-col items-stretch p-4 border-t border-gray-50">
      <EditorForm
        layout={LAYOUT}
        value={section.settings}
        handleUpdate={(update) => {
          handleUpdate({
            ...section,
            settings: {
              ...section.settings,
              ...(update || {}),
            },
          })
        }}
      />
    </div>
  )
}

const LAYOUT = [
  [
    {
      key: 'text',
      label: 'Text',
      controlType: 'textarea',
      helpArticle: CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES.DYNAMIC_FIELDS,
      helpText: 'Use dynamic fields to modify text blocks.',
    },
  ],
  [
    {
      key: 'timer',
      label: 'Allow Timer',
      controlType: 'timer',
    },
  ],
  [
    {
      key: 'appearance',
      label: 'Color',
      controlType: 'single_select',
      options: [
        { label: 'Gray', value: 'regular' },
        { label: 'Red', value: 'critical' },
        { label: 'Yellow', value: 'warning' },
        { label: 'Green', value: 'success' },
      ],
    },
    {
      key: 'size',
      label: 'Size',
      controlType: 'single_select',
      options: [
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'Extra Large', value: 'xlarge' },
      ],
    },
  ],
  [
    {
      key: 'layout',
      label: 'Layout',
      controlType: 'single_select',
      options: [
        { label: 'Full Width', value: 'full_width' },
        { label: 'Centered', value: 'center' },
      ],
    },
    {
      key: 'alignment',
      label: 'Alignment',
      controlType: 'single_select',
      options: [
        { label: 'Leading', value: 'leading' },
        { label: 'Center', value: 'center' },
        { label: 'Trailing', value: 'trailing' },
      ],
    },
  ],
]
