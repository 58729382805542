import React, { useEffect, useRef } from 'react'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

export default function SlideOver({
  open,
  onClose,
  children,
  relative = false,
}) {
  const sidebarContentRef = useRef(null)

  useEffect(() => {
    if (open && typeof onClose === 'function') {
      const listener = (event) => {
        if (
          !sidebarContentRef.current ||
          sidebarContentRef.current.contains(event.target)
        ) {
          return
        }

        onClose()
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [open, sidebarContentRef.current, onClose])

  return (
    <Transition show={open}>
      <div
        className={classNames('inset-0 z-20 overflow-hidden', {
          fixed: !relative,
          absolute: relative,
        })}
      >
        <div className="absolute inset-0 overflow-hidden">
          <section
            ref={sidebarContentRef}
            className="absolute inset-y-0 right-0 flex max-w-full "
          >
            <Transition.Child
              className="w-screen max-w-md"
              enter="transform transition ease-in-out duration-300 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="flex flex-col h-full space-y-6 overflow-y-auto bg-white border border-gray-300 shadow-xl">
                {children}
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  )
}
