import React from 'react'
import _uniq from 'lodash.uniq'

import { useToast } from 'components/Toast'
import Products from 'components/MultiSelects/Products'
import Tags from 'components/MultiSelects/Tags'
import Box from 'components/Box'

export default function ExcludedProducts({
  currencyFormat,
  rkSupport,
  onSave,
  widget,
}) {
  const { excludedProducts } = widget
  const { excludedTags = [] } = widget
  const openToast = useToast()

  async function updateExcludedProducts(productIds, twoWayEnabled = false) {
    onSave(
      { excludedProducts: productIds, reset: true },
      'Exclusion List has been Updated. It might take a few minutes for the changes to be visible.!'
    )
  }

  async function removeProduct(productId) {
    onSave(
      {
        excludedProducts: excludedProducts
          .filter((product) => product.id !== productId)
          .map((product) => product.id),
      },
      'Product removed from Exclusion List. It might take a few minutes for the effects to take place.!'
    )
  }

  async function updateTags(newTags) {
    onSave({ excludedTags: newTags }, 'Tags successfully updated..')
  }

  async function onTagAdd({ tag }) {
    if (excludedTags.includes(tag)) {
      openToast({
        type: 'error',
        text: 'Tag already exists',
      })
    } else {
      const newTags = _uniq([...excludedTags, tag])
      await updateTags(newTags)
    }
  }

  async function onTagRemove(tag) {
    const newTags = _uniq(excludedTags.filter((t) => t !== tag))
    await updateTags(newTags)
  }
  return (
    <Box>
      <div className="flex flex-row items-start w-full">
        <Products
          products={excludedProducts}
          currencyFormat={currencyFormat}
          rkSupport={rkSupport}
          updateProducts={updateExcludedProducts}
          removeProduct={removeProduct}
          copies={PRODUCT_COPIES}
        />
        <Tags
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          tags={excludedTags}
          copies={TAG_COPIES}
        />
      </div>
    </Box>
  )
}

const PRODUCT_COPIES = {
  title: 'Excluded Products',
  tooltipContent:
    'Add a product here to exclude it from showing up in recommendations. It may take a few minutes for the configuration to take effect.',
  resourcePickerHeading: 'Choose excluded products',
  fallbackContent:
    'No products added to exclusion list yet. Adding a product here will remove that product from showing up as recommendation.',
}
const TAG_COPIES = {
  title: 'Excluded Tags',
  tooltipContent:
    'Any product in your store with the following tags will not show up as a recommendation.',
  fallbackContent:
    'No tags added to exclusion list yet. Adding a tag here will remove the products with these tags from recommendations.',
}
