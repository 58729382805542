import React, { useState } from 'react'

import Button from 'components/Button'
import { _pick } from 'utils/lodash'

import Discounts from './Discounts'
import ProductSelection from './ProductSelection'

export default function PromotionBuilderLayoutUpsellDiscounts({
  value,
  handleUpdate,
  accountData,
  handleDelete,
  deleteDisabled,
}) {
  const groups = [
    {
      id: 'select-product',
      label: 'Product to be recommended',
      value: _pick(value, ['recommendationType', 'recommendationRules']),
    },
    {
      id: 'discounts',
      label: 'Discount',
      value: _pick(value, ['discount']),
    },
  ]
  const [formError, setFormError] = useState({})
  function setErrors(errors, group) {
    let updatedErrors = { ...formError }
    if (errors && Object.keys(errors).length > 0) {
      updatedErrors = { ...updatedErrors, [group.id]: errors }
      setFormError(updatedErrors)
    } else {
      delete updatedErrors[group.id]
      setFormError(updatedErrors)
    }
    return updatedErrors
  }
  return (
    <div className="flex flex-col items-center">
      {groups.map((group) => {
        const GroupComponent = GROUP_ID_TO_COMPONENT_MAP[group.id]

        if (!GroupComponent) {
          return null
        }
        return (
          <div className="flex flex-col w-full mb-5" key={group.id}>
            <span className="mb-2 text-sm font-medium">{group.label}</span>
            <GroupComponent
              value={group.value}
              handleUpdate={(values, errors) => {
                let updatedErrors = setErrors(errors, group)
                handleUpdate(values, updatedErrors)
              }}
              accountData={accountData}
            />
          </div>
        )
      })}
      <Button
        color="red"
        disabled={deleteDisabled}
        onClick={() => handleDelete(value)}
      >
        Delete Slot
      </Button>
    </div>
  )
}

const GROUP_ID_TO_COMPONENT_MAP = {
  'select-product': ProductSelection,
  discounts: Discounts,
}
