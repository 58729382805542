import React from 'react'
import Form from 'components/Form'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'

export default function TagsCard({ tags, onAdd, onRemove }) {
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  async function onAddTag(data) {
    await onAdd(data)
    reset()
  }

  return (
    <React.Fragment>
      <form
        className="flex flex-row items-start"
        onSubmit={handleSubmit(onAddTag)}
      >
        <div className="flex flex-col items-stretch flex-grow">
          <input
            name="tag"
            placeholder="Add tag name."
            ref={register({
              required: 'Tag is required before saving',
            })}
            className="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow hover:border-gray-500 focus:outline-none focus:shadow-outline"
          />
          {errors.tag && (
            <span className="mt-1 text-xs text-red-600">
              {errors.tag.message}
            </span>
          )}
        </div>
        <Button size="small" className="mt-1 ml-3">
          + Add
        </Button>
      </form>
      {tags.length > 0 ? (
        <div className="flex flex-row flex-wrap my-3">
          {tags.map((tag) => (
            <Tag key={tag} tag={tag} onRemove={onRemove} />
          ))}
        </div>
      ) : null}
    </React.Fragment>
  )
}

function Tag({ tag, onRemove }) {
  return (
    <span className="pr-4 my-2 mr-1 text-white">
      <span className="py-1 pl-3 pr-3 rounded-l-lg bg-primary">{tag}</span>
      <span
        onClick={() => onRemove(tag)}
        className="p-1 px-3 rounded-r-lg cursor-pointer bg-primary-700 hover:bg-primary-800"
      >
        x
      </span>
    </span>
  )
}
