import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import Icons from 'components/Icons'
import TemplateErrorBox from 'components/TemplateErrorBox'
import { useToast } from 'components/Toast'
import CodeEditor from 'components/CodeEditor'
import { canUpdateTemplate } from 'utils/features'
import { isRkSupport } from 'utils/account'
import { EMAIL_SUPPORT } from 'constants/support'

export default function TemplateJS({
  account,
  template,
  onSave,
  isSaving,
  openUpgradeAccount,
}) {
  const [js, setJs] = React.useState(template.jsTemplate)
  const canCreateTemplate = canUpdateTemplate(account.data)
  const openToast = useToast()
  function handleOnClick() {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      try {
        onSave({ jsTemplate: js })
      } catch (err) {
        openToast({
          type: 'error',
          text: 'Error updating! Please ensure JS is valid',
        })
      }
    }
  }
  return (
    <div
      className="flex flex-col items-stretch"
      style={{ height: template.jsError ? '125vh' : '100%' }}
    >
      <Text.ScreenHeading marginBottom={1}>Edit Javascript</Text.ScreenHeading>
      <Text.SubText marginBottom={2}>
        For any assistance please write to{' '}
        <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <div className="flex flex-col items-center flex-1">
        <div className="flex flex-col items-start w-full">
          <CodeEditor
            placeholder="You can edit/add Javascript code here."
            mode="javascript"
            value={js}
            onChange={setJs}
            name="shop_js_editor"
            rkSupport={isRkSupport(account.data)}
          />

          <Button loading={isSaving} onClick={handleOnClick}>
            <div className="flex items-center justify-center">
              <span>Save</span>
              {!canCreateTemplate && (
                <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
              )}
            </div>
          </Button>
        </div>
        {template.jsError && (
          <TemplateErrorBox label="JS Error" error={template.jsError} />
        )}
      </div>
    </div>
  )
}
