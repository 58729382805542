import React from 'react'

import api from 'api'
import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { useToast } from 'components/Toast'
import { useQueryParams } from 'hooks/router'
import { useApi } from 'hooks/api'
import { _pick, _get } from 'utils/lodash'

import ObjectAdd from './ObjectAdd'
import ObjectEdit from './ObjectEdit'

export default function ObjectList({ widgetType, objectType, account, store }) {
  const [selectedObject, setSelectedObject] = React.useState(null)
  const [showAddPageForm, setShowAddPageForm] = React.useState(false)
  const { shop } = useQueryParams()
  const openToast = useToast()
  const objectSearch = useApi(`autoreco/`, {
    shop,
    page: 1,
    count: 50,
    recommendation_type: widgetType,
    object_type: objectType,
  })

  async function objectCreate(payload) {
    try {
      const { data } = await api.post(
        `autoreco/`,
        [
          {
            ...payload,
            recommendation_type: widgetType,
            object_type: objectType,
          },
        ],
        {
          params: {
            shop,
          },
        }
      )

      objectSearch.update(
        {
          ...objectSearch.data,
          recommendations: [
            ...data.recommendations,
            ...objectSearch.data.recommendations,
          ],
        },
        { local: true }
      )

      setShowAddPageForm(false)
      openToast({
        type: 'success',
        text: 'Search term saved with recommendations successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error saving the page',
      })
    }
  }

  async function objectUpdate(updatedRecommendations) {
    if (!selectedObject) {
      return
    }

    try {
      await api.post(
        `autoreco/`,
        [
          {
            ..._pick(selectedObject, ['id', 'shop', 'widget']),
            recommendation_type: selectedObject.recommendationType,
            object_id: selectedObject.objectId,
            object_type: selectedObject.objectType,
            count: updatedRecommendations.length,
            data: {
              recommendations: updatedRecommendations.map((r) => ({
                value: r.value.productId,
              })),
            },
          },
        ],
        {
          params: {
            shop,
          },
        }
      )

      const searchObjectUpdate = {
        ...objectSearch.data,
        recommendations: objectSearch.data.recommendations.map(
          (recommendation) =>
            recommendation.id === selectedObject.id
              ? {
                  ...recommendation,
                  count: updatedRecommendations.length,
                  data: {
                    recommendations: updatedRecommendations,
                  },
                }
              : recommendation
        ),
      }
      objectSearch.update(searchObjectUpdate, { local: true })

      setShowAddPageForm(false)
      openToast({
        type: 'success',
        text: 'Search term recommendations updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in updating recommendations',
      })
    }
  }

  let content
  if (objectSearch.loading || !objectSearch.data) {
    content = <Loader />
  } else if (showAddPageForm) {
    content = (
      <ObjectAdd
        type={objectType}
        onSave={objectCreate}
        account={account.data}
        store={store.data}
        onClose={() => setShowAddPageForm(false)}
      />
    )
  } else if (selectedObject) {
    content = (
      <ObjectEdit
        object={selectedObject}
        onSave={objectUpdate}
        account={account.data}
        store={store.data}
        onBack={() => setSelectedObject(null)}
      />
    )
  } else {
    content = (
      <React.Fragment>
        <div className="flex flex-row items-center mb-3">
          <div className="flex flex-col flex-1">
            <span className="text-xl font-semibold">Choose search term</span>
            <span className="text-xs text-gray-600">
              You can setup recommendations for a specific search term by
              clicking configure on it. Use "Add new search term" button if term
              doesn't exist.
            </span>
          </div>
          <Button
            className="flex-shrink-0 ml-6"
            size="small"
            onClick={() => setShowAddPageForm(true)}
          >
            + Add New Search Term
          </Button>
        </div>
        <div className="flex flex-row flex-wrap items-stretch -mx-2">
          {objectSearch.data.recommendations.map((recommendation) => (
            <RecommendationItem
              recommendation={recommendation}
              onSelect={() => setSelectedObject(recommendation)}
              objectSearch={objectSearch}
              openToast={openToast}
              shop={shop}
              key={recommendation.id}
            />
          ))}
        </div>
      </React.Fragment>
    )
  }

  return <div className="flex flex-col items-stretch">{content}</div>
}

ObjectList.defaultProps = {
  objectType: 'search',
}

function RecommendationItem({
  recommendation,
  onSelect,
  objectSearch,
  openToast,
  shop,
}) {
  const [removing, setRemoving] = React.useState(false)

  async function onRemove(recommendationId) {
    if (
      !window.confirm(
        `Are you sure you want to delete "${recommendation.objectId}" term ?`
      )
    ) {
      return
    }

    setRemoving(true)
    try {
      await api.delete(`autoreco/${recommendationId}/`, {
        data: null,
        params: {
          shop,
        },
      })

      objectSearch.update(
        {
          ...objectSearch.data,
          recommendations: objectSearch.data.recommendations.filter(
            (r) => r.id !== recommendation.id
          ),
        },
        { local: true }
      )

      openToast({
        type: 'success',
        text: 'Search term removed successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in removing the page',
      })
    }
    setRemoving(false)
  }

  return (
    <div className="w-1/2 px-2 mb-4">
      <Box className="flex flex-col items-stretch">
        <div className="flex flex-row items-baseline mb-1 text-sm">
          <span className="mr-1 text-gray-600">Search term: </span>
          <span className="font-semibold text-gray-600 underline hover:text-primary-700">
            {recommendation.objectId}
          </span>
        </div>
        <div className="flex flex-row items-baseline mb-3">
          <span className="mr-1 text-sm text-gray-600">
            Recommendations Count:{' '}
          </span>
          <span className="font-semibold text-gray-800">
            {recommendation.count}
          </span>
        </div>

        <div className="flex flex-row items-center">
          <Button size="small" variant="bordered" onClick={onSelect}>
            View/Edit Recommendations
          </Button>
          <Button
            size="small"
            variant="bordered"
            className="ml-3 opacity-25 hover:opacity-100"
            color="red"
            loading={removing}
            onClick={() => onRemove(recommendation.id)}
          >
            Remove
          </Button>
        </div>
      </Box>
    </div>
  )
}
