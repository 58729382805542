import parseISO from 'date-fns/parseISO'

export function getNearestHalfHour() {
  var now = new Date()
  now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30)
  return now
}

export function parseStateDate(date, defaultDate) {
  if (typeof date === 'string') {
    const formatted = parseISO(date)
    if (formatted === 'Invalid Date') {
      return defaultDate
    } else {
      return formatted
    }
  } else if (typeof date === 'object') {
    return date
  } else {
    return defaultDate
  }
}
