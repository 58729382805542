import React from 'react'
import Icons from 'components/Icons'
import classNames from 'classnames'

const ToastContext = React.createContext({
  toast: null,
  open() {},
  close() {},
})

let gid = 0

export function ToastProvider({ children }) {
  const [toast, setToast] = React.useState(null)

  const contextValue = React.useMemo(
    () => ({
      toast,
      open(value) {
        setToast({ ...value, type: value.type || 'success', id: (gid += 1) })
      },
      close() {
        setToast(null)
      },
    }),
    [toast, setToast]
  )

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  )
}

export function ToastContent() {
  const context = React.useContext(ToastContext)

  if (!context.toast) {
    return null
  }

  return (
    <Toast key={context.toast.id} toast={context.toast} close={context.close} />
  )
}

export function useToast() {
  return React.useContext(ToastContext).open
}

const TOAST_TYPE_TO_CLASS_MAP = {
  success: 'bg-primary text-white',
  error: 'bg-red-600 text-white',
}

function Toast({ toast, close }) {
  const [active, setActive] = React.useState(false)

  const activate = () => setActive(true)
  const deactivate = () => setActive(false)

  React.useEffect(() => {
    if (active) {
      return
    }

    const timeoutId = setTimeout(close, 5000)
    return () => clearTimeout(timeoutId)
  }, [active, close])

  return (
    <div
      key={toast.id}
      onFocus={activate}
      onBlur={deactivate}
      onKeyUp={activate}
      onMouseOver={activate}
      onClick={activate}
      onMouseEnter={activate}
      onMouseLeave={deactivate}
      className={classNames(
        'fixed bottom-0 left-0 flex flex-row flex-wrap items-center py-2 px-3 mb-5 ml-5 text-sm font-semibold rounded shadow text z-50',
        TOAST_TYPE_TO_CLASS_MAP[toast.type]
      )}
      role="alert"
    >
      <span className="mr-2">{toast.text}</span>
      <Icons.Close
        onClick={close}
        className="flex flex-row items-center justify-center w-4 h-4 text-xs font-normal cursor-pointer fill-current"
      />
    </div>
  )
}
