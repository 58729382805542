import React from 'react'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import WaitForData from 'components/WaitForData'
import { SelectVersionTwo } from 'components/Form/controls'
import NavigationTabs from 'components/NavigationTabs'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Breadcrumbs from 'components/Breadcrumbs'
import { isFeatureEnabled } from 'utils/features'
import * as QueryUtils from 'utils/query'
import { _find } from 'utils/lodash'
import { FUNNELS } from 'constants/features'

import useFunnelDetails from './hooks/useFunnelDetails'
import Overview from '../FunnelBuilder/Review/Overview'
import Funnel from './Funnel'
import Trigger from './Trigger'
import Analytics from './Analytics'
import { getFunnelStatusOptions } from '../utils'

export default function FunnelDetails({ onEditFunnel }) {
  const {
    funnelDetailsQuery,
    slug,
    funnelUpdating,
    updateFunnel,
    accountData,
  } = useFunnelDetails()

  const featureEnabled = isFeatureEnabled(accountData, FUNNELS)
  const openModal = useOpenModal()
  const openToast = useToast()

  return (
    <WaitForData objects={[funnelDetailsQuery]} isQueries={true}>
      {() => {
        const funnelData = QueryUtils.data(funnelDetailsQuery)?.data
        const funnelStatusOptions = getFunnelStatusOptions(
          funnelData,
          accountData
        )
        return (
          <div className="flex flex-col items-stretch">
            <Breadcrumbs
              items={[
                { label: 'Funnels', path: '/funnels' },
                { label: funnelData.name, path: `/funnels/${funnelData.id}` },
              ]}
              className="py-2 pl-8"
            />
            <Box className="mb-4">
              <div className="flex flex-row items-center justify-between">
                <span className="text-lg font-semibold">{funnelData.name}</span>
                <div className="flex flex-row items-stretch">
                  <div className="flex-1 w-32 mr-5">
                    {funnelUpdating ? (
                      <Loader />
                    ) : (
                      <SelectVersionTwo
                        label={
                          <div
                            className={classNames(
                              'flex flex-row py-1.5 px-4 rounded border border-gray-400 w-full h-full justify-between items-center',
                              // STATUS_TO_CLASS_NAME_MAP[original.status],
                              {
                                'pointer-events-none opacity-50':
                                  funnelUpdating,
                              }
                            )}
                          >
                            <span>
                              {
                                _find(funnelStatusOptions, function (o) {
                                  return o.value === funnelData.status
                                }).label
                              }
                            </span>
                            <Icons.ChevronDown className="w-3 ml-2 text-current" />
                          </div>
                        }
                        options={funnelStatusOptions}
                        onChange={(selectedOption) => {
                          const status = selectedOption.value
                          if (!funnelUpdating) {
                            if (selectedOption.locked) {
                              openModal(
                                <UpgradeAccountModal
                                  featureToEnable={FUNNELS}
                                  account={{ data: accountData }}
                                />
                              )
                            } else {
                              updateFunnel({
                                id: funnelData.id,
                                status,
                              })
                            }
                          }
                        }}
                        value={_find(funnelStatusOptions, function (o) {
                          return o.value === funnelData.status
                        })}
                      />
                    )}
                  </div>
                  <Button onClick={() => onEditFunnel(funnelData)}>Edit</Button>
                </div>
              </div>
            </Box>
            <Box>
              <NavigationTabs
                tabs={TABS}
                visibilityCheckParams={[]}
                tabProps={{
                  funnel: funnelData,
                  onEdit: (screen) => {
                    onEditFunnel(funnelData, screen)
                  },
                  accountData,
                }}
              />
            </Box>
          </div>
        )
      }}
    </WaitForData>
  )
}

const STATUS_TO_CLASS_NAME_MAP = {
  active: 'bg-primary-200 text-primary',
  draft: 'bg-red-100 text-red-600',
  paused: 'bg-gray-200 text-black',
  archived: 'bg-gray-200 text-black',
}

const TABS = [
  {
    label: 'Funnel',
    value: 'funnel',
    component: Funnel,
    root: true,
  },
  {
    label: 'Trigger',
    value: 'trigger',
    component: Trigger,
  },
  // {
  //   label: 'Analytics',
  //   value: 'analytics',
  //   component: Analytics,
  // },
]
