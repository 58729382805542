import React from 'react'
import classNames from 'classnames'
import Button from 'components/Button'
import Icons from 'components/Icons'
import Connector from '../Connector'
import NodeComponent from './Node'
import { formatTimeLimit, countLeafNodes } from '../utils'

const ConditionNode = ({ node, workflow, wb }) => {
  const { setSelectedNode, selectedNode } = wb
  const leafNodes = countLeafNodes(node) - 1 || 1

  return (
    <div className="flex items-start justify-center">
      <div style={{ minWidth: leafNodes * 400 }}>
        <Connector position="left" edge={node.left} />
        <div className="mt-[48px] grid">
          <NodeComponent node={node.left?.node} wb={wb} />
        </div>
      </div>
      <div
        className={classNames(
          'p-3 flex rounded-[10px] border shadow-card w-[400px] min-w-[400px] bg-white gap-2.5 cursor-pointer min-h-[92px]',
          {
            'bg-white border-gray-100': selectedNode?.id !== node.id,
            'bg-card-selected border-primary': selectedNode?.id === node.id,
          }
        )}
        onClick={() => setSelectedNode(node)}
      >
        <div>
          <div className="rounded-full p-2.5 bg-gray-300 text-brand">
            {/* <Icons.Condition width="20px" height="20px" /> */}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm font-medium">Condition</p>
          <p className="text-xs text-gray-400">
            <strong className="text-black">Type:</strong>{' '}
            {node.task.extra.name || node.task.type || 'Cart recovered'}
          </p>
          <p className="text-xs text-gray-400">
            <strong className="text-black">Time:</strong>{' '}
            {node.task.time_limit
              ? `within ${formatTimeLimit(node.task.time_limit)}`
              : 'no delay'}
          </p>
        </div>
      </div>
      <div style={{ minWidth: leafNodes * 400 }}>
        <Connector position="right" edge={node.right} />
        <div className="mt-[48px] relative">
          <NodeComponent node={node.right?.node} wb={wb} />
        </div>
      </div>
    </div>
  )
}

export default ConditionNode
