import api from 'api'
import { report } from 'utils/reporting'
import * as Logger from 'utils/logger'

let queue = []
let autoFlushTimer = null
let currentShopName = null
let currentShopId = null
export function configure({ shopName, shopId }) {
  Logger.log('CONFIGURE TRACKING', { shopName, shopId })
  currentShopId = shopId
  currentShopName = shopName

  if (autoFlushTimer) {
    clearInterval(autoFlushTimer)
  }
  autoFlushTimer = setInterval(flush, 2000)
  flush()
}

export function record(eventType) {
  queue.push({ eventType, shop: currentShopId })
}

export async function flush() {
  Logger.log('FLUSH EVENT QUEUE')

  if (
    !queue ||
    queue.length < 1 ||
    !currentShopId ||
    !currentShopName ||
    process.env.NODE_ENV !== 'production'
  ) {
    return
  }

  try {
    Logger.log('PUSH EVENT QUEUE')
    await api.post('events/', queue, { params: { shop: currentShopName } })
    queue = []
  } catch (err) {
    report(err)
  }
}
