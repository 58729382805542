import React from 'react'
import { useMutation } from 'react-query'
import { useToast } from 'components/Toast'

import { updateSmartCart as updateSmartCartApi } from 'api/smart_cart'

import { invalidate, refetch } from 'utils/query'

export default function useSmartCartMutations({ shop }) {
  const openToast = useToast()
  const updateSmartCart = useMutation(
    async (data) => {
      return await updateSmartCartApi(shop, data)
    },
    {
      onSuccess: (data, variables, context) => {
        invalidate(['smart-cart'])
        openToast({
          type: 'success',
          text: 'Smart Cart updated successfully!',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error updating the smatt cart',
        })
      },
    }
  )

  return {
    updateSmartCart,
  }
}
