import React from 'react'

import { LIMIT_BANNER_UPDATES } from 'containers/Funnels/constants'
import Icons from 'components/Icons'
import EditorForm from 'components/EditorForm'
import { CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES } from 'constants/support'

export default function LineSettings({
  value,
  handleUpdate,
  handleDelete,
  canBeDeleted,
}) {
  return (
    <div className="flex flex-col items-stretch">
      <EditorForm
        layout={LAYOUT}
        value={value}
        handleUpdate={(update) => handleUpdate({ id: value.id, update })}
      />
      {canBeDeleted && (
        <button
          className="flex items-center justify-end mt-6 text-sm text-red-400 transition-colors rounded-sm cursor-pointer hover:text-red-600"
          onClick={() => handleDelete(value.id)}
        >
          <Icons.Delete className="w-3 mr-2" /> <span>Delete line</span>
        </button>
      )}
    </div>
  )
}

const LAYOUT = [
  [
    {
      key: 'text',
      label: 'Text',
      controlType: 'textarea',
      // disabled: true,
      // helpArticle: CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES.DYNAMIC_FIELDS,
      // helpText: 'Use dynamic fields to modify text blocks.',
      disabled: LIMIT_BANNER_UPDATES,
    },
  ],
  // [
  //   {
  //     key: 'timer',
  //     label: 'Allow Timer',
  //     controlType: 'timer',
  //   },
  // ],
  [
    {
      key: 'appearance',
      label: 'Color',
      controlType: 'single_select',
      options: [
        { label: 'Gray', value: 'regular' },
        { label: 'Red', value: 'critical' },
        { label: 'Yellow', value: 'warning' },
        { label: 'Green', value: 'success' },
      ],
    },
    {
      key: 'size',
      label: 'Size',
      controlType: 'single_select',
      options: [
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'Extra Large', value: 'xlarge' },
      ],
    },
  ],
]
