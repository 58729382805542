import React from 'react'
import SearchRecommendations from './SearchRecommendations'

export default function SearchApi({ account, store }) {
  return (
    <SearchRecommendations
      store={store}
      account={account}
      widgetType="search"
      objectType="search_term"
    />
  )
}
