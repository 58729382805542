import React, { useState } from 'react'
import Icons from 'components/Icons'

import { WORKFLOW_TASK_TYPES } from '../constants'
import ShopifyPlus from 'components/Badge/ShopifyPlus'
import Tooltip from 'components/Tooltip'

export default function AddNode({ onClose, edge, previousNode, root, wb }) {
  const { setSelectedNode, addNode } = wb
  return (
    <div className="shadow-card py-6 px-10 rounded-[12px] border border-gray-300 bg-white relative">
      <Icons.Cross
        width="24px"
        className="text-primary absolute right-[24px] top-[24px] cursor-pointer"
        onClick={onClose}
      />
      <h3 className="mb-6 text-xl font-semibold text-center">Add Node</h3>
      <div className="flex gap-[60px]">
        {(!previousNode ||
          previousNode.task.type != WORKFLOW_TASK_TYPES.PRE_CHECKOUT.value) && (
          <Tooltip content="Coming soon">
            <div className="group cursor-pointer flex flex-col items-center min-w-[95px]">
              <div
                className="rounded-[12px] bg-gray-300  text-gray-400 w-[70px] h-[70px] flex cursor-not-allowed"
                onClick={() => {
                  // addNode({
                  //   type: WORKFLOW_TASK_TYPES.PRE_CHECKOUT.value,
                  //   edge,
                  //   previousNode,
                  // })
                  // onClose()
                }}
              >
                <div className="flex items-center justify-center w-full">
                  <Icons.PreCheckout className="w-8" />
                </div>
              </div>
              <div className="flex flex-col items-start w-full">
                <h4 className="mt-3 font-medium text-center text-gray-400 ">
                  Pre Checkout
                </h4>
                <ShopifyPlus size="small" className="ml-3 opacity-40" />
              </div>
            </div>
          </Tooltip>
        )}

        <div className="group cursor-pointer flex flex-col items-center min-w-[95px]">
          <div
            className="rounded-[12px] bg-primary-200 group-hover:bg-primary-300 text-primary group-hover:text-primary-700 w-[70px] h-[70px] flex cursor-pointer"
            onClick={() => {
              addNode({
                type: WORKFLOW_TASK_TYPES.POST_CHECKOUT.value,
                edge,
                previousNode,
              })
              onClose()
            }}
          >
            <div className="flex items-center justify-center w-full">
              <Icons.PostCheckout className="w-8" />
            </div>
          </div>
          <h4 className="mt-3 font-medium text-center text-gray-700 group-hover:text-black">
            Post Checkout
          </h4>
        </div>
      </div>
    </div>
  )
}
