import React from 'react'

import NavigationTabs from 'components/NavigationTabs'
import Text from 'components/Text'

import CSS from './CSS'
import JS from './JS'
import ConfigData from './ConfigData'
import Settings from './Settings'
import StorefrontTemplate from './StorefrontTemplate'

export default function Advanced({ account, store }) {
  return (
    <div className="flex flex-col items-stretch flex-1">
      <div className="flex flex-row flex-wrap items-center mb-6">
        <Text.ScreenHeading marginBottom={0}>Advanced</Text.ScreenHeading>
      </div>
      <NavigationTabs
        tabs={TABS}
        visibilityCheckParams={[account.data]}
        tabProps={{
          account,
          store,
        }}
      />
    </div>
  )
}

const TABS = [
  {
    label: 'Personalized Page',
    value: 'storefront_template',
    tooltip: 'Update storefront level templates here.',
    component: StorefrontTemplate,
    root: true,
  },
  {
    label: 'CSS',
    value: 'css',
    tooltip: 'Update style using CSS here.',
    component: CSS,
  },
  {
    label: 'Javascript',
    value: 'js',
    tooltip: 'Add shop level hooks here.',
    component: JS,
  },
  {
    label: 'Manual Sync',
    value: 'manual-sync',
    tooltip:
      'Trigger actions such as product sync or regeneration of recommendations from here.',
    component: Settings,
  },
  {
    label: 'Advanced Config',
    value: 'config-data',
    component: ConfigData,
  },
]
