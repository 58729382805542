import { formApi } from './index'

export function uploadImage({ file }) {
  const fd = new FormData()
  fd.append('image', file)

  // return formApi
  //   .post('images/', fd, {
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   })
  //   .then((res) => res.data)
}
