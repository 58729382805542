import accounting from 'accounting'
import { MONEY_TAGS } from 'constants/currency'

import { isInIframe } from './browser'

const IMAGE_EXTENSIONS = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG']

export function optimisedImage({ baseUrl, size }) {
  if (!size || !baseUrl) {
    return baseUrl
  }

  let optimisedImageUrl = ''
  IMAGE_EXTENSIONS.forEach((extension) => {
    if (optimisedImageUrl || !baseUrl.includes(`.${extension}`)) {
      return
    }
    var lastIndexOf = baseUrl.lastIndexOf(`.${extension}`)
    if (lastIndexOf !== -1) {
      optimisedImageUrl = `${baseUrl.substr(
        0,
        lastIndexOf
      )}_x${size}${baseUrl.substr(lastIndexOf)}`
    }
  })

  return optimisedImageUrl || baseUrl
}

export function isEmbeddedApp() {
  return isInIframe() || window.Shopify?.EmbeddedApp
}

export function formatMoney({ price, currency, currencyFormat }) {
  return formatTag({ price, tag: currencyFormat || MONEY_TAGS[currency] })
}

function formatTag({ price, tag }) {
  let money
  if (tag.includes('amount_no_decimals_with_comma_separator')) {
    money = tag.replace(
      'amount_no_decimals_with_comma_separator',
      accounting.formatMoney(price, '', 0, '.', ',')
    )
  } else if (tag.includes('amount_with_comma_separator')) {
    money = tag.replace(
      'amount_with_comma_separator',
      accounting.formatMoney(price, '', 2, '.', ',')
    )
  } else if (tag.includes('amount_with_apostrophe_separator')) {
    money = tag.replace(
      'amount_with_apostrophe_separator',
      accounting.formatMoney(price, '', 2, "'")
    )
  } else if (tag.includes('amount_no_decimals')) {
    money = tag.replace(
      'amount_no_decimals',
      accounting.formatMoney(price, '', 0)
    )
  } else if (tag.includes('amount')) {
    money = tag.replace('amount', accounting.formatMoney(price, '', 2))
  } else {
    money = price
  }

  money = money.replace('{{', '')
  money = money.replace('}}', '')
  return money
}
