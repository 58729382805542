export default [
  {
    label: 'Chinese (Simplified)',
    value: 'zh-CN',
    auto: false,
  },
  {
    label: 'Chinese (Traditional)',
    value: 'zh-TW',
    auto: false,
  },
  {
    label: 'English',
    value: 'en',
    auto: true,
  },
  {
    label: 'French',
    value: 'fr',
    auto: false,
  },
  {
    label: 'German',
    value: 'de',
    auto: false,
  },
  {
    label: 'Japanese',
    value: 'ja',
    auto: false,
  },
  {
    label: 'Italian',
    value: 'it',
    auto: false,
  },
  {
    label: 'Korean',
    value: 'ko',
    auto: false,
  },
  {
    label: 'Spanish',
    value: 'es',
    auto: true,
  },
  {
    label: 'Dutch',
    value: 'nl',
    auto: false,
  },
  {
    label: 'Portuguese',
    value: 'pt',
    auto: false,
  },
  {
    label: 'Portuguese - Brazil',
    value: 'pt-BR',
    auto: false,
  },
  {
    label: 'Portuguese - Portugal',
    value: 'pt-PT',
    auto: false,
  },
  {
    label: 'Arabic',
    value: 'ar',
    auto: false,
  },
  {
    label: 'Icelandic',
    value: 'is',
    auto: false,
  },
  {
    label: 'Turkish',
    value: 'tr',
    auto: false,
  },
  {
    label: 'Russian',
    value: 'ru',
    auto: false,
  },
  {
    label: 'Indonesian',
    value: 'id',
    auto: false,
  },
  {
    label: 'Persian',
    value: 'fa',
    auto: false,
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    auto: false,
  },
  {
    label: 'Ukrainian',
    value: 'uk',
    auto: false,
  },
  {
    label: 'Thai',
    value: 'th',
    auto: false,
  },
  {
    label: 'Hebrew',
    value: 'he',
    auto: false,
  },
  {
    label: 'Polish',
    value: 'pl',
    auto: false,
  },
  {
    label: 'Greek',
    value: 'el',
    auto: false,
  },
  {
    label: 'Romanian',
    value: 'ro',
    auto: false,
  },
  {
    label: 'Romanian -Romania',
    value: 'ro-RO',
    auto: false,
  },
  {
    label: 'Czech',
    value: 'cs',
    auto: false,
  },
  {
    label: 'Serbian',
    value: 'sr',
    auto: false,
  },
  {
    label: 'Swedish',
    value: 'sv',
    auto: false,
  },
  {
    label: 'Danish',
    value: 'da',
    auto: false,
  },
  {
    label: 'Norwegian',
    value: 'nn',
    auto: false,
  },
  {
    label: 'Norwegian Bokmål',
    value: 'nb',
    auto: false,
  },
  {
    label: 'Finnish',
    value: 'fi',
    auto: false,
  },
  {
    label: 'Slovenian (Slovenia)',
    value: 'sl-SI',
    auto: false,
  },
]
