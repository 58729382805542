import React from 'react'
import classNames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Button from 'components/Button'
import { BACKEND_DOMAIN } from 'constants/support'
import { optimisedImage } from 'utils/shopify'
import { renderCurrency } from 'utils/currency'

export default function RecommendationsList({
  recommendations,
  type,
  allowReorder,
  onReorder,
  onRemove,
  allowEdit = false,
  currencyFormat,
  rkSupport,
}) {
  const [localRecommendations, setLocalRecommendations] = React.useState(
    recommendations || []
  )

  function onDragEnd(result) {
    if (!result.destination || !result.source) {
      return
    }

    const reorderedRecommendations = orderRecommendations(
      recommendations,
      result.source.index,
      result.destination.index
    )

    setLocalRecommendations(reorderedRecommendations)
    onReorder(reorderedRecommendations)
  }

  React.useEffect(() => {
    if (recommendations) {
      setLocalRecommendations(recommendations)
    }
  }, [recommendations])

  if (allowReorder) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`${type}Recommendations`}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="flex flex-col items-stretch flex-1"
            >
              {localRecommendations
                .filter(Boolean)
                .map((recommendation, index) => (
                  <Draggable
                    key={recommendation.id}
                    draggableId={recommendation.id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <RecommendationItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        recommendation={recommendation}
                        allowReorder={allowReorder}
                        allowEdit={true}
                        onRemove={() => onRemove(recommendation)}
                        currencyFormat={currencyFormat}
                        rkSupport={rkSupport}
                      />
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  } else {
    return (
      <div className="flex flex-col items-stretch flex-1">
        {recommendations.map((recommendation) => (
          <RecommendationItem
            key={recommendation.id}
            recommendation={recommendation}
            allowEdit={allowEdit}
            onRemove={() => onRemove(recommendation)}
            currencyFormat={currencyFormat}
            rkSupport={rkSupport}
          />
        ))}
      </div>
    )
  }
}

RecommendationsList.defaultProps = {
  allowReorder: false,
  onReorder: () => {},
  onRemove: () => {},
}

function getListStyle(isDraggingOver) {
  return {
    ...(isDraggingOver ? { background: '#f9f9f9' } : {}),
  }
}

function getItemStyle(isDragging, draggableStyle) {
  return {
    userSelect: 'none',
    ...(isDragging ? { background: 'lightblue' } : {}),
    ...draggableStyle,
  }
}

function orderRecommendations(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const RecommendationItem = React.forwardRef(
  (
    {
      recommendation,
      allowEdit,
      onRemove,
      allowReorder,
      currencyFormat,
      rkSupport,
      ...otherProps
    },
    ref
  ) => {
    const recommendationUrl = recommendation.url
    return (
      <div
        ref={ref}
        {...otherProps}
        className={classNames('flex flex-col p-3 border-t border-gray-200', {
          'group hover:bg-gray-200': allowEdit,
        })}
      >
        <div className="flex flex-row items-center mb-1">
          <img
            src={optimisedImage({ baseUrl: recommendation.image, size: 100 })}
            className="object-cover w-16 h-16 mr-3"
          />
          <div className="flex flex-col justify-between flex-auto">
            <a
              {...(recommendationUrl ? { href: recommendationUrl } : {})}
              className={classNames('notranslate', {
                'hover:underline cursor-pointer': recommendationUrl,
              })}
              target="_blank"
            >
              {recommendation.title}
            </a>
            <span
              className={classNames('mt-1 text-xs text-gray-600', {
                'cursor-pointer hover:underline': rkSupport,
              })}
              onClick={
                rkSupport
                  ? () => {
                      const windowRef = window.open(
                        `${BACKEND_DOMAIN}admin/djshopify/product/${recommendation.productId}/change/`,
                        '_blank'
                      )
                      if (typeof windowRef.focus === 'function') {
                        windowRef.focus()
                      }
                    }
                  : undefined
              }
            >
              Id : {recommendation.productId}
            </span>
            {recommendation.handle && (
              <span className="text-xs text-gray-600">
                Handle : {recommendation.handle}
              </span>
            )}
            <span className="text-xs text-gray-600">
              Price :{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: renderCurrency(
                    recommendation.minPrice || recommendation.price,
                    currencyFormat
                  ),
                }}
              ></span>
            </span>
          </div>
          {allowEdit && (
            <div className="flex flex-col flex-shrink-0 ml-6 opacity-0 group-hover:opacity-100">
              <Button variant="bordered" size="xSmall" onClick={onRemove}>
                Remove
              </Button>
              {allowReorder && (
                <span className="mt-1 text-xs text-gray-600">
                  drag to re-order
                </span>
              )}
            </div>
          )}
        </div>
        {recommendation.isAvailable === false && (
          <span className="text-xs text-red-600">
            This product is unavailable and might not show up as recommendation.
          </span>
        )}
        {!recommendation.image && (
          <span className="text-xs text-red-600">
            This product might not show up as recommendation as it does not have
            an image.
          </span>
        )}
      </div>
    )
  }
)

RecommendationItem.defaultProps = {
  allowEdit: false,
  onRemove: () => {},
}
