import React, { useCallback, useState } from 'react'
import { mergeWith } from 'lodash'

import { useToast } from 'components/Toast'
import { _find, _identity } from 'utils/lodash'
import api from 'api'
import * as QueryUtils from 'utils/query'
import { useOpenModal } from 'components/Modal'
import * as WIDGETS from 'constants/widgets'
import { useApi } from 'hooks/api'

export default function useWidgetBuilder({
  initialWidget,
  intialActiveScreen,
  account,
  experience,
  pages,
  billing,
}) {
  const existingWidgetToPageMap = pages.data.reduce((prev, page) => {
    const { type } = page
    page.widgets.forEach((widget) => {
      const { type: widgetVal } = widget
      if (widget.experience.id === experience.id) {
        prev[widgetVal] = mergeWith(
          prev[widgetVal],
          { [type]: 1 },
          (objValue, srcValue) => {
            if (objValue && srcValue) {
              return objValue + srcValue
            }
            if (srcValue) {
              return srcValue
            }
            return objValue
          }
        )
      }
    })
    return prev
  }, {})

  const experienceSpecificWidgets = pages.data.reduce((prev, page) => {
    page.widgets.forEach((widget) => {
      if (widget.experience.id === experience.id) {
        prev = [...prev, widget]
      }
    })
    return prev
  }, [])

  const isExistingWidgetAllowedOnPageMap = widgetOnPageChecker(
    WIDGETS,
    existingWidgetToPageMap
  )

  let [widget, setWidget] = useState(initialWidget || {})
  const [localWidget, setLocalWidget] = useState({})
  const [activeScreen, setActiveScreen] = useState(
    getInitialScreen(initialWidget, intialActiveScreen)
  )
  const [savingAndNextLoading, setSavingAndNextBtn] = useState(false)
  const [savingWidget, setSavingWidget] = useState(false)
  const { name: shop, id: shopId } = account.data
  const openToast = useToast()
  const [configuration, setConfigurations] = useState({})
  const [saveHandler, setSaveHandler] = useState(_identity)
  const [saveNextHandler, setSaveNextHandler] = useState(_identity)
  const [publishHandler, setPublishHandler] = useState(_identity)
  const openModal = useOpenModal()
  const { data, loading } = useApi('templates/', { shop })

  async function createWidget({ pageType, title, type }) {
    const pagesData = pages.data
    const existingWidgets = pagesData.reduce((widgets, page) => {
      var expWidgets = []
      page.widgets.forEach((widget) => {
        if (widget.experience.id === experience.id) {
          expWidgets.push(widget)
        }
      })
      return [...widgets, ...expWidgets]
    }, [])

    return await new Promise(async (resolve, reject) => {
      try {
        const res = await enableWidgetForPage(
          type,
          title,
          pageType,
          shop,
          shopId,
          existingWidgets,
          pagesData,
          experience
        )
        setWidget(res)
        resolve(res)
      } catch (e) {
        reject(e)
      }
    }).then(async (widget) => {
      if (typeof pages.get === 'function') {
        await pages.get()
      }
      await QueryUtils.invalidate('pages')
      return widget
    })
  }

  function getConfirmation() {
    if (widget?.enabled === false) {
      return window.confirm(
        'The widget is not yet enabled. You can see this widget on the dashboard and enable it. Would you still like to exit the create flow?'
      )
    }
    return true
  }

  return {
    screens: SCREENS,
    widget,
    setWidget,
    activeScreen,
    savingAndNextLoading,
    setSavingAndNextBtn,
    heading: 'Create Widget',
    setActiveScreen: useCallback(
      (name) => {
        const screen = _find(SCREENS, { name })
        if (screen) {
          setActiveScreen(screen)
        }
      },
      [setActiveScreen]
    ),
    account,
    experience,
    createWidget,
    savingWidget,
    openToast,
    shop,
    setSavingWidget,
    configuration,
    setConfigurations,
    openModal,
    billing,
    saveHandler,
    setSaveHandler,
    saveNextHandler,
    setSaveNextHandler,
    publishHandler,
    setPublishHandler,
    isExistingWidgetAllowedOnPageMap,
    existingWidgetToPageMap,
    experienceSpecificWidgets,
    templates: loading ? [] : data?.templates,
    localWidget,
    setLocalWidget,
    getConfirmation
  }
}

function getInitialScreen(widget, intialActiveScreen) {
  if (intialActiveScreen) {
    return _find(SCREENS, { name: intialActiveScreen })
  }
  return _find(SCREENS, (screen) => !screen.complete(widget)) || SCREENS[0]
}

export const SCREENS = [
  {
    name: 'detail',
    label: 'Detail',
    complete: (widget) => widget?.title && widget?.type && widget?.pageType,
    disabled: () => false,
  },
  {
    name: 'layout',
    label: 'Layout',
    complete: (widget) => widget?.layout,
    disabled: (widget) => !widget?.layout,
  },
  {
    name: 'configure',
    label: 'Configure',
    complete: (widget) => 'automaticEnabled' in widget,
    disabled: (widget) => !('automaticEnabled' in widget),
  },
  {
    name: 'review',
    label: 'Review',
    complete: (widget) =>
      widget?.title &&
      widget?.type &&
      widget?.pageType &&
      widget?.layout &&
      'automaticEnabled' in widget,
    disabled: (widget) =>
      !(
        widget?.title &&
        widget?.type &&
        widget?.pageType &&
        widget?.layout &&
        'automaticEnabled' in widget
      ),
  },
]

async function enableWidgetForPage(
  widgetType,
  title,
  pageType,
  shop,
  shopId,
  existingWidgets,
  pagesData,
  experience
) {
  let widgetForPage = existingWidgets.filter(
    (widget) => widget.type === widgetType && widget.pageType === pageType
  )[0]
  let page = pagesData.filter((p) => p.type === pageType)[0]

  if (!widgetForPage) {
    if (!page) {
      page = await createPage(shop, shopId, pageType)
    }
    widgetForPage = await createWidgetForPage(
      widgetType,
      title,
      page,
      shop,
      shopId,
      experience
    )
  } else {
    const { id } = widgetForPage

    const widget = Object.values(WIDGETS).find(
      (widget) => widget.value === widgetType
    )

    if (widget.supportMultiple) {
      const createdWidget = await createWidgetForPage(
        widgetType,
        title,
        page,
        shop,
        shopId,
        experience
      )
      return createdWidget
    }

    const { data } = await api.put(
      `widgets/${id}/`,
      { id, title },
      { params: { shop } }
    )
    return data
  }
  return widgetForPage
}

async function createWidgetForPage(
  type,
  title,
  page,
  shop,
  shopId,
  experience
) {
  const request = {
    page: page.id,
    type: type,
    shop: shopId,
    title: title === 'Custom' ? 'You May Also Like' : title,
    ...(experience ? { experience: experience.id } : {}),
    enabled: false,
  }

  const { data: createdWidget } = await api.post('widgets/', request, {
    params: { shop },
  })

  return createdWidget
}

async function createPage(shop, shopId, pageType) {
  const { data: createdPage } = await api.post(
    'pages/',
    {
      shop: shopId,
      type: pageType,
    },
    {
      params: { shop },
    }
  )
  return createdPage
}

function widgetOnPageChecker(widgets, existingWidgetToPageMap) {
  widgets = Object.values(widgets)
  const result = {}
  for (let widgetKey in existingWidgetToPageMap) {
    result[widgetKey] = {}
    const { maxAllowed } = _find(widgets, { value: widgetKey })
    widgets = widgets.filter(({ value }) => value !== widgetKey)
    const widget = existingWidgetToPageMap[widgetKey]
    for (let page in maxAllowed) {
      if (widget[page]) {
        result[widgetKey][page] = maxAllowed[page] > widget[page] ? true : false
      } else {
        result[widgetKey][page] = true
      }
    }
  }
  widgets.forEach(({ value, maxAllowed }) => {
    result[value] = {}
    for (let key in maxAllowed) {
      result[value][key] = true
    }
  })
  return result
}
