import React from 'react'
import { useBoolean } from 'react-hanger'
import Text from 'components/Text'
import { useToast } from 'components/Toast'
import Tabs from 'components/Tabs'
import { EMAIL_SUPPORT } from 'constants/support'
import AdminPanelCSS from './CSS'
import AdminPanelJS from './JS'
import AdminPanelExtra from './ShopExtra'
import AdminPanelOnboarding from './Onboarding'
import AdminPanelStoreSettings from './StoreSettings'

export default function AdminPanel({ account, store }) {
  const openToast = useToast()
  const isSaving = useBoolean(false)
  function onFailure() {
    openToast({
      type: 'error',
      text: 'There was an error updating! ',
    })
  }
  async function onSave(data) {
    isSaving.setTrue()
    await store.update(data, {
      onFailure: () =>
        openToast({
          type: 'error',
          text: 'There was an error updating! ',
        }),
      onSuccess: () =>
        openToast({
          type: 'success',
          text: 'Updated successfully!',
        }),
    })

    isSaving.setFalse()
  }

  return (
    <div className="flex flex-col items-stretch">
      <Text.ScreenHeading marginBottom={1}>Admin Panel</Text.ScreenHeading>
      <Text.SubText marginBottom={5}>
        Add custom configuration to all the widgets on all pages. For any
        assistance please write to{' '}
        <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <Tabs tabs={TABS}>
        {(activeTab) => (
          <activeTab.component
            data={store.data}
            onSave={(data) => onSave(data)}
            isSaving={isSaving.value}
            account={account}
          />
        )}
      </Tabs>
    </div>
  )
}

const TABS = [
  {
    label: 'CSS',
    value: 'css',
    tooltip: 'Update style using CSS from this tab.',
    component: AdminPanelCSS,
  },
  {
    label: 'Javascript',
    value: 'js',
    tooltip: 'Update Javascript from this tab.',
    component: AdminPanelJS,
  },
  {
    label: 'Shop Extra',
    value: 'extra',
    tooltip: 'Update Shop Extras from this tab.',
    component: AdminPanelExtra,
  },
  {
    label: 'Onboarding',
    value: 'onboarding',
    tooltip: 'Update Onboarding from this tab.',
    component: AdminPanelOnboarding,
  },
  {
    label: 'Store Settings',
    value: 'store_settings',
    tooltip: 'Update Store Settings from this tab.',
    component: AdminPanelStoreSettings,
  },
]
