import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import getSymbolFromCurrency from 'currency-symbol-map'

import Form from 'components/Form'
import Button from 'components/Button'
import ResourcePicker from 'components/ResourcePicker'
import { useOpenModal } from 'components/Modal'
import { getAccountData, getStoreCurrency } from 'utils/account'

import _get from 'lodash.get'
import _uniq from 'lodash.uniq'
import _uniqBy from 'lodash.uniqby'

import styles from './styles.module.css'

export default function RuleSection({
  rule,
  extra,
  type,
  disableValuesUpdate,
  onUpdate,
  mode,
}) {
  const canEdit = mode === 'edit'
  const openModal = useOpenModal()
  const accountData = getAccountData()
  const storeCurrency = getStoreCurrency(accountData)
  const currencySymbol = getSymbolFromCurrency(storeCurrency)

  const [ruleExtra, setRuleExtra] = React.useState(extra || {})
  const [condition, setCondition] = React.useState(
    _get(rule, `${type}.condition`, 'or')
  )
  const [objectValues, setObjectValues] = React.useState(
    _get(rule, `[${type}].objectValues`, [])
  )
  const [cartPriceValues, setCartPriceValues] = React.useState(
    objectValues.length > 0
      ? { ...objectValues[0] }
      : {
          price: 0.0,
          condition: CART_PRICE_CONDITIONS[0].value,
        }
  )

  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues: {
      objectType: _get(rule, `[${type}].objectType`, OBJECT_TYPES[0].value),
    },
  })
  const formValues = watch()
  const prevObjectType = usePrevious(formValues.objectType)

  function onAdd(data) {
    const newObjectValues = _uniq([data.objectValue, ...objectValues])
    setObjectValues(newObjectValues)
    reset({ objectValue: '', objectType: data.objectType })
    onUpdate({
      objectType: data.objectType,
      objectValues: newObjectValues,
      condition: condition,
    })
  }

  function onRemove(value) {
    const newObjectValues = objectValues.filter((v) => v !== value)
    setObjectValues(newObjectValues)
    onUpdate({
      objectType: formValues.objectType,
      objectValues: newObjectValues,
      condition: condition,
    })
  }

  function onAddProducts(products) {
    setRuleExtra({
      ...ruleExtra,
      products: _uniqBy(
        [...(ruleExtra.products || []), ...products],
        (p) => p.productId
      ),
    })

    const newObjectValues = products.map((p) => p.productId)
    setObjectValues(newObjectValues)
    onUpdate({
      objectType: formValues.objectType,
      objectValues: newObjectValues,
      condition: condition,
    })
  }

  function onRemoveProduct(productId) {
    const newObjectValues = objectValues.filter((p) => p !== productId)
    setObjectValues(newObjectValues)
    onUpdate({
      objectType: formValues.objectType,
      objectValues: newObjectValues,
      condition: condition,
    })
  }

  function onCartPriceChange(event) {
    setCartPriceValues({
      ...cartPriceValues,
      price: event.target.value,
    })
    const parsedPrice = parseFloat(event.target.value)
    if (parsedPrice) {
      onUpdate({
        objectType: formValues.objectType,
        objectValues: [
          {
            condition: cartPriceValues.condition
              ? cartPriceValues.condition
              : CART_PRICE_CONDITIONS[0].value,
            price: parsedPrice,
          },
        ],
        condition: condition,
      })
    } else {
      onUpdate({
        objectType: formValues.objectType,
        objectValues: [],
        condition: condition,
      })
    }
  }

  React.useEffect(() => {
    if (!prevObjectType) {
      return
    }

    if (!['tag', 'collection'].includes(formValues.objectType)) {
      setCondition('or')
    }

    if (formValues.objectType.includes('cartPrice')) {
      setObjectValues([cartPriceValues])
      onUpdate({
        objectType: formValues.objectType,
        objectValues: [cartPriceValues],
        condition: condition,
      })
    } else {
      setObjectValues([])
      onUpdate({
        objectType: formValues.objectType,
        objectValues: [],
        condition: condition,
      })
    }

    setRuleExtra(extra || {})
  }, [formValues.objectType])

  return (
    <div
      className={classNames(
        'flex flex-col flex-1 p-3 bg-gray-200 border-gray-200 rounded',
        { 'mr-3': type === 'if' }
      )}
    >
      <div className="flex flex-row items-baseline pb-1 mb-3 border-b border-gray-400">
        <span className="text-xl font-semibold">
          {type === 'if' ? 'If' : 'Then'}
        </span>
        <span className="ml-1 text-xs text-gray-600">
          (
          {type === 'if'
            ? 'Specify criteria to match products'
            : 'Specify criteria for recommendations'}
          )
        </span>
        <span className="flex justify-end flex-grow ml-1 text-xs">
          <span className="text-s">
            <label htmlFor="condition">Condition: </label>
            <select
              name="condition"
              id="condition"
              disabled={
                !canEdit ||
                !['tag', 'collection'].includes(formValues.objectType)
              }
              value={condition}
              onChange={(e) => {
                setCondition(e.target.value)
                onUpdate({
                  objectType: formValues.objectType,
                  objectValues: objectValues,
                  condition: e.target.value,
                })
              }}
            >
              <option value="or">OR</option>
              <option value="and">AND</option>
            </select>
          </span>
        </span>
      </div>
      <form
        className="flex flex-col items-stretch"
        onSubmit={handleSubmit(onAdd)}
      >
        <div className="flex flex-row items-start">
          <Form.Field
            label=""
            name="objectType"
            control="select"
            controlClassName="text-sm px-2 py-2"
            className={styles.filterTypeSelect}
            ref={register({ required: true })}
            options={
              type === 'if'
                ? OBJECT_TYPES
                : OBJECT_TYPES.filter((types) => types.value !== 'cartPrice')
            }
            disabled={!canEdit}
          />
          {formValues.objectType === 'cartPrice' ? (
            <div className="mx-3">
              <Form.Field
                label=""
                name="cartPriceCondition"
                control="select"
                controlClassName="text-sm px-2 py-2"
                className={styles.filterTypeSelect}
                options={CART_PRICE_CONDITIONS}
                disabled={!canEdit}
                value={cartPriceValues.condition}
                onChange={(event) => {
                  setCartPriceValues({
                    price: 0.0,
                    condition: event.target.value,
                  })
                  onUpdate({
                    objectType: formValues.objectType,
                    objectValues: [],
                    condition: condition,
                  })
                }}
              />
            </div>
          ) : (
            <span className="mx-3 mt-1 text-xl font-semibold">In</span>
          )}
          {formValues.objectType === 'product' ? (
            canEdit && (
              <div className="flex flex-row self-stretch flex-1">
                <Button
                  size="small"
                  type="button"
                  onClick={() => {
                    openModal(
                      <ResourcePicker
                        heading="Choose recommended products"
                        ignoreProductIds={[]}
                        initialSelectedProductIds={objectValues}
                        showTwoWaySyncOption={false}
                        onSelect={(
                          _selectedProductIds,
                          _twoWayEnabled,
                          selectedProducts
                        ) => onAddProducts(selectedProducts)}
                      />
                    )
                  }}
                >
                  Choose products to add
                </Button>
              </div>
            )
          ) : formValues.objectType === 'cartPrice' ? (
            <div className="flex flex-row items-center">
              <div className="flex flex-col items-stretch flex-grow">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                    <span className="text-sm font-semibold text-gray-500">
                      {currencySymbol}
                    </span>
                  </div>
                  <input
                    type="number"
                    className="block w-full py-2 pl-5 pr-8 text-sm leading-tight bg-white border border-gray-400 rounded shadow hover:border-gray-500 focus:outline-none focus:shadow-outline"
                    placeholder="0.00"
                    step={0.01}
                    min={0.0}
                    value={cartPriceValues.price}
                    onChange={onCartPriceChange}
                    disabled={!canEdit}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-stretch flex-1">
              {objectValues.length > 0 && (
                <div className="flex flex-row flex-wrap flex-1 px-1 pb-1 mb-2">
                  {objectValues.map((value) => {
                    if (typeof value === 'object') {
                      return null
                    }
                    return (
                      <Tag
                        key={value}
                        value={value}
                        onRemove={onRemove}
                        disabled={!canEdit}
                      />
                    )
                  })}
                </div>
              )}
              {canEdit && (
                <div className="flex flex-row items-center">
                  <div className="flex flex-col items-stretch flex-grow">
                    <input
                      name="objectValue"
                      placeholder="Enter value"
                      ref={register({
                        required: 'Value cannot be empty',
                      })}
                      className="block w-full px-2 py-2 pr-8 text-sm leading-tight bg-white border border-gray-400 rounded shadow hover:border-gray-500 focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <Button
                    size="small"
                    className="ml-3"
                    disabled={disableValuesUpdate}
                  >
                    + Add
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {formValues.objectType === 'product' &&
          objectValues.length > 0 &&
          ruleExtra.products &&
          ruleExtra.products.length > 0 && (
            <div className="flex flex-row flex-wrap mt-3">
              {objectValues.map((productId) => {
                const product = ruleExtra.products.filter(
                  (p) => p.productId === productId
                )[0]
                if (!product) {
                  return null
                }

                return (
                  <div
                    key={product.productId}
                    className="flex flex-col items-center w-1/5 p-1 mb-1 mr-1 bg-white border border-gray-400"
                  >
                    <img
                      src={product.image}
                      className="object-contain w-12 h-12"
                    />
                    <span className="my-1 text-xs text-gray-800">
                      {product.title}
                    </span>
                    {canEdit && (
                      <Button
                        variant="bordered"
                        size="xSmall"
                        onClick={() => onRemoveProduct(product.productId)}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                )
              })}
            </div>
          )}
      </form>
    </div>
  )
}

RuleSection.defaultProps = {
  mode: 'edit',
}

function Tag({ value, onRemove, disabled }) {
  return (
    <span className="flex flex-row items-center px-2 py-1 mt-1 mr-1 text-sm text-white rounded bg-primary">
      <span>{value}</span>
      {!disabled && (
        <span
          className="ml-1 font-semibold cursor-pointer hover:bg-primary-800"
          onClick={() => onRemove(value)}
        >
          ⓧ
        </span>
      )}
    </span>
  )
}

const OBJECT_TYPES = [
  { label: 'Tag', value: 'tag' },
  { label: 'Collection', value: 'collection' },
  { label: 'Product Type', value: 'product_type' },
  { label: 'Product', value: 'product' },
  { label: 'Cart Price', value: 'cartPrice' },
  { label: 'Vendor', value: 'vendor' },
]

const CART_PRICE_CONDITIONS = [
  { label: 'Less Than', value: 'lt' },
  { label: 'Greater Than', value: 'gt' },
  { label: 'Equal', value: 'eq' },
]

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef()

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
