import * as Widgets from 'constants/widgets'

export function canConfigureRecommendations(widget) {
  return [
    Widgets.BOUGHT_TOGETHER,
    Widgets.CROSS_SELL,
    Widgets.SIMILAR_PRODUCTS,
    Widgets.PROMOTED_PRODUCTS,
    Widgets.TRENDING,
    Widgets.BESTSELLERS,
    Widgets.RECENTLY_LAUNCHED,
    Widgets.COLLECTIONS_WIDGET,
    Widgets.PURCHASES,
  ]
    .map((widget) => widget.value)
    .includes(widget.type)
}
