import React from 'react'
import { useForm } from 'react-hook-form'

import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'
import { useOpenModal } from 'components/Modal'
import { _get } from 'utils/lodash'

export default function ObjectAdd({ type, onClose, onSave }) {
  const [recommendations, setRecommendations] = React.useState([])
  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: 'onChange',
  })
  const openModal = useOpenModal()
  const watchObjectId = watch('object_id')

  async function onSubmit(data) {
    const payload = {
      ...data,
      count: recommendations.length,
      data: {
        recommendations: recommendations.map((r) => ({
          value: r.productId,
        })),
      },
    }
    onSave(payload)
  }

  return (
    <div className="flex flex-col items-stretch">
      <Box className="flex flex-col items-stretch mb-6">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <Form.Field
            label={'Search Term'}
            name={'object_id'}
            control="input"
            placeholder="Enter search term..."
            ref={register({ required: true })}
            controlClassName="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none flex-1/2 hover:border-gray-500 focus:outline-none focus:shadow-outline"
          />
          {errors.object_id ? (
            <span className="-my-2 text-xs text-red-600">
              Please enter a valid search term
            </span>
          ) : (
            watchObjectId && (
              <span className="text-sm text-gray-800">
                Products will be returned for following search term:{' '}
                <span className="text-primary">{watchObjectId}</span>
              </span>
            )
          )}
          <div className="flex flex-row justify-end">
            <div className="flex flex-col items-end">
              <span className="flex flex-row items-center mb-1">
                <Button
                  size="small"
                  className="mr-3 opacity-75 hover:opacity-100"
                  variant="bordered"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button size="small" disabled={recommendations.length === 0}>
                  Save
                </Button>
              </span>
              {recommendations.length === 0 && (
                <span className="text-xs text-right text-gray-600">
                  Please add some recommendations to save
                </span>
              )}
            </div>
          </div>
        </form>
      </Box>
      <Box className="flex flex-col items-stretch">
        <div className="flex flex-row justify-between mb-4">
          <span className="text-xl font-semibold text-gray-800">
            Recommendations
          </span>
          <Button
            size="small"
            onClick={() =>
              openModal(
                <ResourcePicker
                  heading="Choose recommended products"
                  ignoreProductIds={[]}
                  initialSelectedProductIds={recommendations.map(
                    (r) => r.productId
                  )}
                  onSelect={(
                    selectedProductIds,
                    _twoWaysync,
                    selectedProducts
                  ) => {
                    const existingRecommendations = recommendations || []
                    const selectedRecommendations = selectedProducts.map(
                      (p) => ({
                        ...p,
                        id: p.productId,
                      })
                    )

                    const newRecommendations = [
                      ...selectedRecommendations,
                      ...existingRecommendations,
                    ].filter((rec) =>
                      selectedProductIds.includes(rec.productId)
                    )

                    setRecommendations(newRecommendations)
                  }}
                />
              )
            }
          >
            + Add Recommendations
          </Button>
        </div>
        <RecommendationsList
          recommendations={recommendations}
          type={type}
          allowReorder={true}
          onReorder={(recommendations) => {
            setRecommendations(recommendations)
          }}
          onRemove={(recommendation) =>
            setRecommendations(
              recommendations.filter((r) => r !== recommendation)
            )
          }
        />
      </Box>
    </div>
  )
}
