import { useEffect } from 'react'

export default function () {
  useEffect(() => {
    const $langSelector = document.getElementById('google_translate_element')
    if (!$langSelector) {
      return
    }

    $langSelector.setAttribute('style', 'top: 10px; right: auto; left: 10px;')
    return () => $langSelector && $langSelector.removeAttribute('style')
  }, [])
}
