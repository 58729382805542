import * as Widgets from 'constants/widgets'
import * as Channels from 'constants/campaigns/channels'

import MailchimpStatic from './mailchimp/static'
import KlaviyoStatic from './klaviyo/static'
import OmnisendStatic from './omnisend/static'
import MailchimpPersonalized from './mailchimp/personalized'
import KlaviyoPersonalized from './klaviyo/personalized'
import OmnisendPersonalized from './omnisend/personalized'
import LiquidStatic from './liquid/static'
import LiquidPersonalized from './liquid/personalized'

export {
  MailchimpStatic,
  MailchimpPersonalized,
  KlaviyoStatic,
  KlaviyoPersonalized,
  OmnisendStatic,
  OmnisendPersonalized,
  LiquidStatic,
}

export default function (type, channel) {
  switch (channel) {
    case Channels.KLAVIYO.value:
      if (
        [Widgets.PERSONALIZED.value, Widgets.BOUGHT_TOGETHER.value].indexOf(
          type
        ) !== -1
      ) {
        return KlaviyoPersonalized
      }
      return KlaviyoStatic
    case Channels.MAILCHIMP.value:
      if (
        [Widgets.PERSONALIZED.value, Widgets.BOUGHT_TOGETHER.value].indexOf(
          type
        ) !== -1
      ) {
        return MailchimpPersonalized
      }
      return MailchimpStatic
    case Channels.OMNISEND.value:
      if (
        [Widgets.PERSONALIZED.value, Widgets.BOUGHT_TOGETHER.value].indexOf(
          type
        ) !== -1
      ) {
        return OmnisendPersonalized
      }
      return OmnisendStatic
    case Channels.SPENTLY.value:
    case Channels.SHOPIFY.value:
      if (
        [Widgets.PERSONALIZED.value, Widgets.BOUGHT_TOGETHER.value].indexOf(
          type
        ) !== -1
      ) {
        return LiquidPersonalized
      }
      return LiquidStatic
  }
}
