const titleToLanguageMap = {
    "zh-CN":"你也许也喜欢",
    "zh-TW":"你也許也喜歡",
    "en":"You may also like",
    "fr": "Tu pourrais aussi aimer",
    "de":"Sie können auch mögen",
    "ja":"あなたも好きかも",
    "it": "Potrebbe piacerti anche",
    "ko":"당신은 또한 좋아할 수 있습니다",
    "es":"También te puede interesar",
    "nl":"Dit vind je misschien ook leuk",
    "pt":"você pode gostar",
    "pt-BR": "Você também pode gostar",
    "pt-PT":"Você também pode gostar",
    "ar":"ربما يعجبك أيضا",
    "is":"þér gæti einnig líkað við",
    "tr":"Şunlar da hoşunuza gidebilir",
    "ru":"Вам также может понравиться",
    "id":"Anda mungkin juga suka",
    "fa":"شما هم ممکن است دوست داشته باشید",
    "vi":"Bạn cũng có thể thích",
    "uk":"Вам також може сподобатися",
    "th":"คุณอาจชอบ",
    "he":"אולי גם תאהב",
    "pl":"Może Ci się spodobać",
    "el":"μπορεί να σου αρέσει επίσης",
    "ro":"Ați putea dori, de asemenea",
    "ro-RO":"Ați putea dori, de asemenea",
    "cs":"Mohlo by se vám také líbit",
    "sr":"Можда ће ти се свидети и",
    "sv":"Du kanske också gillar",
    "da":"Du vil måske også kunne lide",
    "nn":"Kan hende du også liker",
    "nb":"Du vil kanskje også like",
    "fi":"saatat pitää myös",
    "sl-SI":"Morda vam bo tudi všeč"
}

export default titleToLanguageMap