import React from 'react'

import WithProducts from 'components/WithProducts'
import RenderMoney from 'components/RenderMoney'
import * as ShopifyUtils from 'utils/shopify'

export default function Recommendations({ layout }) {
  const {
    data: {
      settings: {
        recommendation: { type: recommendationType },
      },
    },
    recommendedItem,
    productSyncInProgress,
  } = layout

  if (recommendationType === 'none') {
    return null
  }

  return (
    <div className="flex flex-col items-stretch justify-center py-4 px-7">
      <span className="mb-2 text-sm font-semibold">Recommended for you</span>
      {recommendedItem ? (
        <RecommendedItem product={recommendedItem} />
      ) : (
        <div className="flex items-center justify-center h-24 px-6 text-sm border border-black border-dashed">
          {productSyncInProgress
            ? 'Recommended item will be displayed here. Please wait for product sync to be completed for a better preview.'
            : 'Recommended item will be displayed here.'}
        </div>
      )}
    </div>
  )
}

function RecommendedItem({ product }) {
  const { title, image, variants } = product
  const firstVariant = variants[0]
  return (
    <div className="flex flex-row items-center py-4">
      <div className="w-2/12">
        <div className="relative overflow-hidden h-0 pt-[150%]">
          <img
            src={ShopifyUtils.optimisedImage({
              baseUrl: image,
              size: 460,
            })}
            alt={title}
            className="absolute top-0 left-0 object-cover object-top w-full h-full"
          />
        </div>
      </div>
      <div className="flex flex-col items-start w-9/12 pl-6">
        <span className="text-sm font-semibold">{title}</span>
        <span className="mt-1 text-xs text-gray-600">{firstVariant.title}</span>
        <div className="flex flex-row items-center mt-1">
          <RenderMoney price={firstVariant.price} className={'text-xs'} />
          {firstVariant.comparePrice && (
            <RenderMoney
              price={firstVariant.comparePrice}
              className={'pl-2 text-xxs line-through text-gray-600'}
            />
          )}
        </div>
      </div>
      <div className="w-1/12">
        <div className="flex flex-col items-center cursor-pointer">
          <span className="flex items-center justify-center w-6 h-6 border border-black">
            +
          </span>
          <span className="mt-2 text-xs uppercase">Add</span>
        </div>
      </div>
    </div>
  )
}
