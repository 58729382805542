import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import Icons from 'components/Icons'
import TemplateErrorBox from 'components/TemplateErrorBox'
import CodeEditor from 'components/CodeEditor'
import { canUpdateTemplate } from 'utils/features'
import { isRkSupport } from 'utils/account'
import { EMAIL_SUPPORT } from 'constants/support'

export default function TemplateCSS({
  account,
  template,
  onSave,
  isSaving,
  openUpgradeAccount,
}) {
  const [css, setCss] = React.useState(template.cssTemplate)
  const canCreateTemplate = canUpdateTemplate(account.data)
  const rkSupport = isRkSupport(account.data)
  function handleChange(value) {
    setCss(value)
  }

  function handleOnClick() {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      onSave({ cssTemplate: css })
    }
  }

  return (
    <div
      className="flex flex-col items-stretch"
      style={{ height: template.cssError ? '125vh' : '100%' }}
    >
      <Text.ScreenHeading marginBottom={1}>Edit CSS</Text.ScreenHeading>
      <Text.SubText marginBottom={2}>
        For any assistance please write to{' '}
        <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <div className="flex flex-col items-center flex-1">
        <div className="flex flex-col items-start w-full">
          <CodeEditor
            placeholder="You can edit/add CSS code here."
            mode="liquid"
            value={css}
            onChange={setCss}
            name="shop_template_css_editor"
            rkSupport={rkSupport}
          />
          <Button loading={isSaving} onClick={handleOnClick}>
            <div className="flex items-center justify-center">
              <span>Save</span>
              {!canCreateTemplate && (
                <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
              )}
            </div>
          </Button>
        </div>
        {template.cssError && (
          <TemplateErrorBox label="CSS Error" error={template.cssError} />
        )}
      </div>
    </div>
  )
}
