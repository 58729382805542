import React from 'react'

import ResourceBuilder from 'components/ResourceBuilder'
import Button from 'components/Button'
import Icons from 'components/Icons'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useOpenModal } from 'components/Modal'

import useBundleBuilder from './hooks/useBundleBuilder'
import Details from './Details'
import Bundle from './Bundle'
import Review from './Review'
import Trigger from './Trigger'
import { isFeatureEnabled } from 'utils/features'
import { getAccountData } from 'utils/account'
import { BUNDLES } from 'constants/features'

export default function BundleBuilder({
  initialBundle,
  close,
  initialActiveScreen,
  store,
}) {
  const builder = useBundleBuilder({
    initialBundle,
    initialActiveScreen,
    store,
  })
  const ActiveScreenComponent =
    SCREEN_NAME_TO_COMPONENT_MAP[builder.activeScreen.name]

  return (
    <ResourceBuilder
      close={close}
      builder={builder}
      resource={builder.bundle}
      initialActiveScreen={initialActiveScreen}
      PrimaryCTA={PrimaryCTA}
      SecondaryCTA={SecondaryCTA}
      ActiveScreenComponent={ActiveScreenComponent}
    />
  )
}

function PrimaryCTA({ builder, rb }) {
  const {
    activeScreen,
    savingButton,
    setSavingButton,
    saveNextHandler,
    publishHandler,
  } = builder
  const isReviewScreen = activeScreen.name === 'review'
  const accountData = getAccountData()
  const canPublish = isFeatureEnabled(accountData, BUNDLES)
  const openModal = useOpenModal()

  if (isReviewScreen) {
    return (
      <Button
        variant="solid"
        loading={savingButton === 'publish'}
        disabled={!!savingButton}
        onClick={async () => {
          if (canPublish) {
            if (!savingButton) {
              setSavingButton('publish')
              try {
                await publishHandler()
              } catch (err) {}
              setSavingButton(null)
            }
          } else {
            openModal(
              <UpgradeAccountModal
                featureToEnable={BUNDLES}
                account={{ data: accountData }}
              />
            )
          }
        }}
      >
        <div className="flex flex-row items-center justify-start">
          {!canPublish && (
            <Icons.Lock className="w-3 mr-2 text-white fill-current" />
          )}
          Publish Now
        </div>
      </Button>
    )
  }
  return (
    <Button
      variant="solid"
      loading={savingButton === 'saveAndNext'}
      disabled={!!savingButton}
      onClick={async () => {
        if (!savingButton) {
          setSavingButton('saveAndNext')
          try {
            await saveNextHandler()
          } catch (err) {}
          setSavingButton(null)
        }
      }}
    >
      Save & Next
    </Button>
  )
}

function SecondaryCTA({ builder, rb }) {
  const { activeScreen, savingButton, setSavingButton, saveHandler } = builder
  const isReviewScreen = activeScreen.name === 'review'

  if (isReviewScreen) {
    return (
      <Button variant="bordered" className="mr-4" onClick={rb.close}>
        Close
      </Button>
    )
  }

  return (
    <Button
      variant="bordered"
      className="mr-4"
      loading={savingButton === 'save'}
      disabled={!!savingButton}
      onClick={async () => {
        if (!savingButton) {
          setSavingButton('save')
          try {
            await saveHandler()
          } catch (err) {}
          setSavingButton(null)
        }
      }}
    >
      Save
    </Button>
  )
}

const SCREEN_NAME_TO_COMPONENT_MAP = {
  details: Details,
  trigger: Trigger,
  bundle: Bundle,
  review: Review,
}
