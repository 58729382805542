import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import VisitorDetail from './VisitorDetail'
import VisitorList from './VisitorList'

export default function Visitors({ account, store }) {
  const { path } = useRouteMatch()

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={path}>
          <VisitorList
            account={account}
            store={store}
            schema={VISITOR_SCHEMA}
          />
        </Route>
        <Route path={`${path}/:visitorId`} exact>
          <VisitorDetail
            account={account}
            store={store}
            schema={VISITOR_SCHEMA}
          />
        </Route>
      </Switch>
    </React.Fragment>
  )
}

const VISITOR_SCHEMA = {
  objectType: 'visitor',
  sections: [
    {
      name: 'Basic Information',
      attributes: [
        'email',
        'shopifyUser',
        'deviceType',
        'os',
        // 'osVersion',
        'browser',
        // 'browserVersion',
        'city',
        'country',
        'timezone',
        'phone',
        'postalCode',
      ],
    },
    {
      name: 'Store Activity',
      attributes: [
        'createdAt',
        'lastSeenAt',
        'lastBoughtAt',
        'totalPurchaseValue',
        'orderCount',
        'pageViewCount',
        'tags',
        'productTypes',
        'collections',
      ],
    },
  ],
  schema: {
    browser: {
      title: 'Browser',
      type: 'string',
      allowFilter: true,
    },
    browserVersion: {
      title: 'Browser Version',
      type: 'string',
      allowFilter: true,
    },
    city: {
      title: 'City',
      type: 'string',
      allowFilter: true,
    },
    country: {
      title: 'Country',
      type: 'country',
      allowFilter: true,
    },
    collections: {
      title: 'Collections',
      type: 'string',
      list: true,
      // allowFilter: true,
    },
    createdAt: {
      title: 'First Seen At',
      type: 'timestamp',
      // allowFilter: true,
    },
    deviceType: {
      title: 'Device Type',
      type: 'string',
      allowFilter: true,
    },
    email: {
      title: 'Email',
      type: 'string',
      allowFilter: true,
    },
    id: {
      title: 'ID',
      type: 'string',
    },
    ip: {
      title: 'IP Address',
      type: 'string',
      allowFilter: true,
    },
    userId: {
      title: 'ID',
      type: 'string',
      allowFilter: true,
    },
    lastBoughtAt: {
      title: 'Last Purchase At',
      type: 'timestamp',
      // allowFilter: true,
    },
    lastSeenAt: {
      title: 'Last Seen At',
      type: 'timestamp',
      // allowFilter: true,
    },
    orderCount: {
      title: 'Orders Count',
      type: 'integer',
      allowFilter: true,
      allowSort: true,
    },
    os: {
      title: 'OS',
      type: 'string',
      allowFilter: true,
    },
    osVersion: {
      title: 'OS Version',
      type: 'string',
      allowFilter: true,
    },
    pageViewCount: {
      title: 'Page Views Count',
      type: 'integer',
      allowFilter: true,
      allowSort: true,
    },
    phone: {
      title: 'Phone',
      type: 'string',
    },
    postalCode: {
      title: 'Postal Code',
      type: 'string',
      allowFilter: true,
    },
    productTypes: {
      title: 'Product Types',
      type: 'string',
      list: true,
      // allowFilter: true,
    },
    shopifyUser: {
      title: 'Customer ID(Shopify)',
      type: 'string',
      allowFilter: true,
    },
    tags: {
      title: 'Tags',
      type: 'string',
      list: true,
      // allowFilter: true,
    },
    timezone: {
      title: 'Timezone',
      type: 'string',
      allowFilter: true,
    },
    totalPurchaseValue: {
      title: 'Total Purchase Value',
      type: 'currency',
      allowFilter: true,
      allowSort: true,
    },
  },
}
