import EditorForm from 'components/EditorForm'
import React from 'react'

export default function Pricing({ value, handleUpdate, accountData }) {
  const {
    settings: { pricing: pricingValues },
  } = value

  return (
    <EditorForm
      layout={LAYOUT}
      value={pricingValues}
      handleUpdate={(values) => {
        const updateObj = {
          settings: {
            pricing: {
              ...pricingValues,
              ...values,
            },
          },
        }
        handleUpdate(updateObj)
      }}
    />
  )
}

const LAYOUT = [
  {
    key: 'showDiscountedAmt',
    label: 'Show Discounted Amount',
    controlType: 'switch_toggle',
  },
  {
    key: 'showCompare',
    label: 'Show Original Price',
    controlType: 'switch_toggle',
  },
  {
    key: 'showDiscountPercent',
    label: 'Show Discount Percentage',
    controlType: 'switch_toggle',
  },
  {
    key: 'totalPriceColor',
    label: 'Total Price Color',
    controlType: 'color',
  },
  {
    key: 'comparePriceColor',
    label: 'Original Price Color',
    controlType: 'color',
  },
  {
    key: 'discountPercentageColor',
    label: 'Discount Percentage Color',
    controlType: 'color',
  },
]
