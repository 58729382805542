import React from 'react'
import { useForm } from 'react-hook-form'
import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'
import { useOpenModal } from 'components/Modal'
import {
  pathnameFromURL,
  domainNameFromURL,
  fullURLFromPathname,
  validURL,
} from 'utils/browser'
import { _get } from 'utils/lodash'

export default function ObjectAdd({ type, onClose, onSave, account, store }) {
  const [recommendations, setRecommendations] = React.useState([])
  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: 'onChange',
  })
  const openModal = useOpenModal()
  const watchObjectId = watch('object_id')

  const customDomainAllowed = _get(store, 'extra.allowCustomDomainInPageRules')
  const shopDomain = _get(account, 'shop.domain')

  const objectIdDomain = customDomainAllowed
    ? domainNameFromURL(watchObjectId)
    : null
  const objectIdPathname = pathnameFromURL(watchObjectId)

  async function onSubmit(data) {
    const payload = {
      ...data,
      object_id:
        objectIdDomain && objectIdDomain !== shopDomain
          ? fullURLFromPathname(objectIdPathname, objectIdDomain)
          : objectIdPathname,
      count: recommendations.length,
      data: {
        recommendations: recommendations.map((r) => ({
          value: r.productId,
        })),
      },
    }
    onSave(payload)
  }

  return (
    <div className="flex flex-col items-stretch">
      <Box className="flex flex-col items-stretch mb-6">
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <Form.Field
            label={'Page URL'}
            name={'object_id'}
            control="input"
            placeholder="Paste URL of the page here..."
            ref={register({ validate: (value) => validURL(value) })}
            controlClassName="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none flex-1/2 hover:border-gray-500 focus:outline-none focus:shadow-outline"
          />
          {errors.object_id ? (
            <span className="-my-2 text-xs text-red-600">
              Please enter a valid URL
            </span>
          ) : (
            watchObjectId && (
              <span className="text-sm text-gray-800">
                Widget will be displayed on following page:{' '}
                <a
                  className="underline text-primary hover:text-primary-700"
                  href={`${watchObjectId}`}
                  target="_blank"
                >
                  {fullURLFromPathname(
                    objectIdPathname,
                    customDomainAllowed && objectIdDomain
                      ? objectIdDomain
                      : shopDomain
                  )}
                </a>
              </span>
            )
          )}
          <div className="flex flex-row justify-end">
            <Button
              size="small"
              className="mr-3 opacity-75 hover:opacity-100"
              variant="bordered"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button size="small" disabled={recommendations.length === 0}>
              Save
            </Button>
          </div>
        </form>
      </Box>
      <Box className="flex flex-col items-stretch">
        <div className="flex flex-row justify-between mb-4">
          <span className="text-xl font-semibold text-gray-800">
            Recommendations
          </span>
          <Button
            size="small"
            onClick={() =>
              openModal(
                <ResourcePicker
                  heading="Choose recommended products"
                  ignoreProductIds={[]}
                  initialSelectedProductIds={recommendations.map(
                    (r) => r.productId
                  )}
                  onSelect={(
                    selectedProductIds,
                    _twoWaysync,
                    selectedProducts
                  ) => {
                    const existingRecommendations = recommendations || []
                    const selectedRecommendations = selectedProducts.map(
                      (p) => ({
                        ...p,
                        id: p.productId,
                      })
                    )

                    const newRecommendations = [
                      ...selectedRecommendations,
                      ...existingRecommendations,
                    ].filter((rec) =>
                      selectedProductIds.includes(rec.productId)
                    )

                    setRecommendations(newRecommendations)
                  }}
                />
              )
            }
          >
            + Add Recommendations
          </Button>
        </div>
        <RecommendationsList
          recommendations={recommendations}
          type={type}
          allowReorder={true}
          onReorder={(recommendations) => {
            setRecommendations(recommendations)
          }}
          onRemove={(recommendation) =>
            setRecommendations(
              recommendations.filter((r) => r !== recommendation)
            )
          }
        />
      </Box>
    </div>
  )
}
