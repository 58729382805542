import { useEffect, useState, useReducer, useMemo, useCallback } from 'react'

import { getPostCheckoutDefaultSections } from 'containers/Funnels/utils'
import { useToast } from 'components/Toast'
import { _uniqBy } from 'utils/lodash'

export default function usePromotionBuilderLayout({
  promotionType,
  initialLayout,
}) {
  const [state, dispatch] = useReducer(reducer, initialLayout || {})
  const [formError, setFormError] = useState({})
  const [activeSectionId, setActiveSectionId] = useState(null)
  const [updated, setUpdated] = useState(false)
  const openToast = useToast()
  const populateDefaultSections = useCallback(() => {
    if (state?.sections?.length > 0) {
      return
    }
    const sections = getPostCheckoutDefaultSections({ promotionType })
    sections.forEach((section) => {
      dispatch({ type: 'ADD_SECTION', section })
    })
  }, [dispatch, state?.sections?.length, promotionType])

  useEffect(() => {
    populateDefaultSections()
  }, [])

  return useMemo(
    () => ({
      data: state,
      formError,
      updated,
      activeSectionId,
      setActiveSectionId,
      addSection: (section) => {
        dispatch({ type: 'ADD_SECTION', section })
        setActiveSectionId(section.uuid)
        setUpdated(true)
      },
      deleteSection: (sectionId) => {
        const shouldDelete = window.confirm(
          'Are you sure you want to delete this section?'
        )

        if (shouldDelete) {
          dispatch({ type: 'DELETE_SECTION', sectionId })
          openToast({
            type: 'success',
            text: 'Section deleted',
          })
          setUpdated(true)
        }
      },
      updateSection: ({ sectionId, update, errors }) => {
        dispatch({ type: 'UPDATE_SECTION', sectionId, update })
        if (errors && Object.keys(errors).length > 0) {
          setFormError({ ...formError, [sectionId]: errors })
        } else {
          const updatedErrors = { ...formError }
          delete updatedErrors[sectionId]
          setFormError(updatedErrors)
        }
        setUpdated(true)
      },

      reorderSections: (newOrder) => {
        dispatch({ type: 'REORDER_SECTIONS', order: newOrder })
        setUpdated(true)
      },
    }),
    [state, activeSectionId, setActiveSectionId, updated]
  )
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_SECTION':
      return {
        ...state,
        sections: _uniqBy([...(state.sections || []), action.section], 'uuid'),
      }

    case 'DELETE_SECTION':
      return {
        ...state,
        sections: (state.sections || []).filter(
          (section) => section.uuid !== action.sectionId
        ),
      }

    case 'UPDATE_SECTION':
      return {
        ...state,
        sections: (state.sections || []).map((section) =>
          section.uuid === action.sectionId
            ? { ...section, ...action.update }
            : section
        ),
      }

    case 'REORDER_SECTIONS':
      return {
        ...state,
        sections: state.sections.sort((sectionA, sectionB) => {
          const sectionAIndex = action.order.indexOf(sectionA.uuid)
          const sectionBIndex = action.order.indexOf(sectionB.uuid)

          return sectionAIndex - sectionBIndex
        }),
      }

    default:
      return state
  }
}
