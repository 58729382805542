import React from 'react'
import Loader from 'components/Loader'
import WaitForData from 'components/WaitForData'
import classNames from 'classnames'
import { useApi } from 'hooks/api'
import useOnboarding from './hooks/useOnboarding'
import useLanguageSelector from './hooks/useLanguageSelector'
import useAccountConfigPolling from './hooks/useAccountConfigPolling'

export default function ({ account, store }) {
  const { name: shop } = account.data
  const billing = useApi('account/', { shop })
  const onboardingState = useOnboarding({
    account,
    store,
  })

  useLanguageSelector()
  useAccountConfigPolling({ account })

  return (
    <WaitForData
      objects={[billing, store]}
      loadingMessage="Fetching store details"
    >
      {() => {
        return (
          onboardingState.component && (
            <div className="flex flex-col items-center flex-1 p-5 bg-gray-200 md:p-10">
              {onboardingState.isSyncingStore && false && (
                <div className="absolute top-0 right-0 flex flex-row items-center p-2 text-xs text-gray-600">
                  <Loader.Circle className="mr-1" />
                  Product sync in progress. Widgets may not show up until we've
                  finished syncing your data.
                </div>
              )}
              <ProgressBar
                total={onboardingState.progress.total}
                progress={onboardingState.progress.current}
              />
              <onboardingState.component
                onboardingState={onboardingState}
                account={account}
                billing={billing}
                store={store}
              />
            </div>
          )
        )
      }}
    </WaitForData>
  )
}

function ProgressBar({ progress, total }) {
  const arr = []
  let i = 0
  while (i < total) {
    arr.push(i)
    i++
  }

  return (
    <div className="flex flex-row items-center w-full mt-2 mb-2 lg:w-5/6 xl:w-3/4">
      {arr.map((val, index) => {
        const isLastItem = index === arr.length - 1
        const isCompleted = progress > val

        return (
          <div
            key={val}
            className={classNames('flex-1 h-1 bg-gray-400 rounded-sm', {
              'mr-0': isLastItem,
              'mr-1': !isLastItem,
              'bg-primary-400': isCompleted,
              'bg-gray-400': !isCompleted,
            })}
          />
        )
      })}
    </div>
  )
}
