import React from 'react'
import Box from 'components/Box'
import EmptyState from 'components/EmptyState'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'

import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import { widgetLabelFromType } from 'utils/constants'

export default function ProductList({
  widgetType,
  topProductsData,
  shop,
  onClose,
  store,
}) {
  const openModal = useOpenModal()
  const openToast = useToast()
  const topProducts = topProductsData.data ? topProductsData.data.products : []

  async function updateTopProducts(productIds, twoWayEnabled = false) {
    try {
      const deletedProducts = topProducts.filter(
        (r) => !productIds.includes(r.product.id.toString())
      )
      const requestData = [
        ...productIds
          .map((id) => ({ productId: id, recommendationType: widgetType }))
          .reverse(),
        ...deletedProducts.map((r) => ({
          productId: r.product.id,
          recommendationType: widgetType,
          enabled: false,
        })),
      ]
      await topProductsData.update(requestData)
      openToast({
        type: 'success',
        text: 'Global Recommendations have been Updated. It might take a few minutes for the changes to be visible.!',
      })
    } catch (err) {
      console.log(err)
      openToast({
        type: 'error',
        text: 'Global Recommendations could not be updated successfully! Please contact support.',
      })
    }
  }

  async function removeProduct(topProductId) {
    try {
      await topProductsData.destroy(topProductId)
      openToast({
        type: 'success',
        text: 'Product removed from recommendation List. It might take a few minuts for the effects to take place.!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Product could not be removed from recommendation list! Please contact support.',
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-col mb-3">
        <div className="flex flex-row justify-between mb-4">
          <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
            <span className="mr-1">
              Add/Remove {widgetLabelFromType(widgetType)} Recommendations{' '}
            </span>
            <Tooltip
              position="right"
              content={`These products will be shown before Automatic Recommendations in all ${widgetLabelFromType(
                widgetType
              )} widgets`}
              showHelpIcon={true}
              helpIconVariant="dark"
            />
          </span>

          <Button
            size="xSmall"
            onClick={() =>
              openModal(
                <ResourcePicker
                  heading={`Choose ${widgetLabelFromType(widgetType)}`}
                  ignoreProductIds={[]}
                  initialSelectedProductIds={topProducts.map(
                    (rec) => rec.product.productId
                  )}
                  showTwoWaySyncOption={false}
                  onSelect={(selectedProductIds, twoWayEnabled) =>
                    updateTopProducts(
                      selectedProductIds.map((id) => id.toString())
                    )
                  }
                  close={onClose}
                  store={store}
                />
              )
            }
          >
            {`+ Add ${widgetLabelFromType(widgetType)}`}
          </Button>
        </div>
      </div>
      {onClose && (
        <div className="flex flex-row items-center mt-1 mb-6">
          <span
            className="text-sm underline cursor-pointer text-primary"
            onClick={onClose}
          >
            ← Go Back
          </span>
        </div>
      )}
      <div className="flex flex-col mb-3">
        {topProducts.length > 0 ? (
          <Box>
            <RecommendationsList
              recommendations={topProducts.map((rec) => ({
                ...rec.product,
                topProductId: rec.id,
              }))}
              type="manual"
              allowReorder={true}
              allowEdit={true}
              onReorder={(products) => {
                updateTopProducts(products.map((p) => p.productId.toString()))
              }}
              onRemove={(product) => removeProduct(product.topProductId)}
            />
          </Box>
        ) : (
          <EmptyState
            text={`No products add to ${widgetLabelFromType(
              widgetType
            )} yet. Automatic Recommendations will show up instead.`}
          />
        )}
      </div>
    </div>
  )
}
