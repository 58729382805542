import { useState } from 'react'

export const CACHE_KEY = '__rkAppState'

const INITIAL_STATE = {
  pageTypeFilter: 'all',
}

let globalState
try {
  globalState = JSON.parse(localStorage.getItem(CACHE_KEY))
  globalState = globalState || INITIAL_STATE
} catch (err) {
  globalState = INITIAL_STATE
}
localStorage.setItem(CACHE_KEY, JSON.stringify(globalState))

let writeStateToLocalStorageTimer = null

export default function () {
  const [state, setState] = useState(globalState)

  function updateState(newState) {
    globalState = {
      ...globalState,
      ...newState,
    }

    setState(globalState)

    if (writeStateToLocalStorageTimer) {
      clearTimeout(writeStateToLocalStorageTimer)
    }
    writeStateToLocalStorageTimer = setTimeout(() => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(globalState))
    })
  }

  return {
    state,
    updateState,
  }
}

export function getAppState() {
  return globalState
}
