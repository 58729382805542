const AMAZON_BOUGHT_TOGETHER = {
  value: 'amazon_bought_together',
  label: 'Amazon like bought together',
}

const CAROUSEL = {
  value: 'carousel',
  label: 'Carousel',
}

const HORIZONTAL_GRID = {
  value: 'horizontal_grid',
  label: 'Horizontal Grid',
}

export { AMAZON_BOUGHT_TOGETHER, CAROUSEL, HORIZONTAL_GRID }
