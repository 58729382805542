import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import Box from 'components/Box'
import Button from 'components/Button'
import { getUserName } from 'utils/account'
import { LOOM_TUTORIAL_VIDEO_URL } from 'constants/support'
import Loader from 'components/Loader'

import Step from './Step'

export default function Welcome({ account, onboarding }) {
  const openModal = useOpenModal()
  const {
    loading,
    onboardingState: { completed: onboardingCompleted, stage: onboardingStage },
  } = onboarding

  return loading ? (
    <div className="flex flex-row justify-center w-full h-20">
      <Loader.Circle size="xLarge" />
    </div>
  ) : !onboardingCompleted ? (
    <Box className="mb-6">
      <div
        className={classNames('flex flex-row items-center justify-between', {
          'pb-[27px]': !onboardingCompleted,
        })}
      >
        <div className="flex flex-col items-start mb-4">
          <span className="pb-2 text-xl font-semibold leading-6 text-gray-900">
            Welcome {getUserName(account.data)},
          </span>
          <span className="text-base leading-5 text-gray-600">
            Complete the following steps to get started...
          </span>
        </div>
        <Button
          variant="text"
          onClick={() => openModal(<TutorialVideoModal />)}
        >
          <div className="flex">
            <Icons.Youtube className="w-4 mr-2" />
            <span className="pl-[5.5px] text-xs font-semibold leading-6">
              Watch Tutorial
            </span>
          </div>
        </Button>
      </div>
      {onboarding.onboardingSteps.map((item, index) => {
        return (
          <Step
            key={index}
            info={item}
            index={index + 1}
            onboarding={onboarding}
          />
        )
      })}
    </Box>
  ) : null
}

function TutorialVideoModal() {
  return (
    <div className="">
      <iframe
        width="100%"
        src={LOOM_TUTORIAL_VIDEO_URL}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope"
        allowFullScreen
        style={{ height: '80vh' }}
      ></iframe>
    </div>
  )
}
