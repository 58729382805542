import React, { useEffect, Fragment } from 'react'

import Box from 'components/Box'
import Icons from 'components/Icons'
import Form from 'components/Form'
import Badge from 'components/Badge'
import { _find } from 'utils/lodash'
import { Listbox, Transition } from '@headlessui/react'

export default function WidgetMultiSelect({
  selectedWidgetIds = [],
  setSelectedWidgetIds,
  className,
  allWidgets,
}) {
  const [nameQuery, setNameQuery] = React.useState('')
  const [filteredWidgets, setFilteredWidgets] = React.useState(allWidgets)
  useEffect(() => {
    setFilteredWidgets(
      allWidgets.filter((widget) => {
        return (
          String(widget.title)
            .toLowerCase()
            .includes(nameQuery.toLowerCase()) ||
          String(widget.id).includes(nameQuery) ||
          String(widget.experience.id).includes(nameQuery)
        )
      })
    )
  }, [nameQuery, allWidgets])

  function addOrRemoveWidget(id) {
    if (selectedWidgetIds.includes(parseInt(id))) {
      setSelectedWidgetIds(
        selectedWidgetIds.filter((widgetId) => widgetId != id)
      )
    } else {
      setSelectedWidgetIds(selectedWidgetIds.concat(id))
    }
  }

  return (
    <Listbox value={selectedWidgetIds} onChange={addOrRemoveWidget}>
      <div className="relative">
        <Listbox.Button className={className}>
          <div className="flex flex-row items-center justify-between w-48 px-5 py-2 overflow-x-auto bg-white border border-gray-400 rounded shadow cursor-pointer">
            {selectedWidgetIds.length === 0 ? (
              <span>All widgets</span>
            ) : (
              <span>Selected Widgets</span>
            )}
            <Icons.ChevronDown className="w-4" />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute z-10 w-full py-1 mt-3 rounded-md shadow-lg"
            style={{ width: '20rem', left: '-35%' }}
          >
            <Box className="w-full">
              <div className="flex flex-col">
                <div className="flex flex-row flex-wrap mb-3">
                  {selectedWidgetIds.map((widgetId) => {
                    const widget = _find(allWidgets, (w) => w.id === widgetId)
                    return (
                      <div
                        key={widgetId}
                        className="flex flex-row items-center justify-start px-2 py-1 mb-1 mr-2 text-sm bg-gray-400 rounded"
                      >
                        <span className="pr-1">{`${widget.title} (${widget.id})`}</span>
                        <Icons.Close
                          className="w-2 cursor-pointer"
                          onClick={() => {
                            addOrRemoveWidget(widgetId)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <Form.Field
                  name="widgetName"
                  control="input"
                  type="text"
                  value={nameQuery}
                  onChange={(event) => setNameQuery(event.target.value)}
                />
                <div className="flex flex-col w-full h-64 mt-3 overflow-y-auto">
                  {allWidgets.length > 0 ? (
                    filteredWidgets.map((widget) => {
                      return (
                        <Listbox.Option key={widget.id} value={widget.id}>
                          <div
                            className="flex flex-col px-2 py-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                              addOrRemoveWidget(widget.id)
                            }}
                          >
                            <div className="flex flex-row items-center justify-between mb-2">
                              <span className="text-base font-semibold">
                                {widget.title}
                              </span>
                              {selectedWidgetIds.includes(widget.id) && (
                                <Icons.CircleTickThin className="w-4 text-green-700" />
                              )}
                            </div>
                            <div className="flex flex-row items-center justify-between mb-1 text-xs">
                              <span>Widget ID: {widget.id}</span>
                              {!widget.enabled && (
                                <Badge color="yellow">Disabled</Badge>
                              )}
                            </div>
                            <div className="flex flex-row items-center justify-between mb-1 text-xs">
                              <span>Experience ID: {widget.experience.id}</span>
                              {widget.experience.status === 'unpublished' ||
                                (widget.experience.status === 'archived' && (
                                  <Badge color="yellow">Unpublished</Badge>
                                ))}
                            </div>
                          </div>
                        </Listbox.Option>
                      )
                    })
                  ) : (
                    <div className="flex items-center justify-center w-full h-full mb-3">
                      No widgets to display
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
