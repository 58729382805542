import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'

import Badge from 'components/Badge'
import Box from 'components/Box'
import Loader from 'components/Loader'
import { useApi } from 'hooks/api'
import useAppState from 'hooks/appState'
import { useQueryParams } from 'hooks/router'
import Button from 'components/Button'
import InfiniteScroll from 'components/InfiniteScroll'
import Tooltip from 'components/Tooltip'
import Icons from 'components/Icons'
import { _uniqBy, _uniq } from 'utils/lodash'

import ProductItem from './ProductItem'
import styles from './styles.module.css'
import { Select } from 'components/Form/controls'
import { fetchProducts as fetchProductsApi } from 'api/products'
import { getAccountData } from 'utils/account'

export default function ResourcePicker({
  heading,
  chooseButtonText,
  onSelect,
  additionalSearchParams,
  ignoreProductIds,
  initialSelectedProductIds,
  showTwoWaySyncOption,
  close,
  store,
}) {
  const accountData = getAccountData()
  const [query, setQuery] = React.useState('')
  const resultsContainerRef = React.useRef(null)
  const { state: appState, updateState: updateAppState } = useAppState()
  const [enableTwoWaySync, setEnableTwoWaySync] = React.useState(
    typeof appState.enableTwoWaySync != 'undefined'
      ? appState.enableTwoWaySync
      : false
  )
  const [sortOption, setSortOption] = useState(
    typeof appState.resourcePickerSortOption != 'undefined'
      ? appState.resourcePickerSortOption
      : SORT_OPTIONS.ALPHABETICAL.value
  )
  const [selectedProductIds, setSelectedProductIds] = React.useState(
    initialSelectedProductIds
  )
  const [loadingInitialData, setLoadingInitialData] = useState(
    initialSelectedProductIds.length > 0 ? true : false
  )

  // NOTE(Dhruv): Needed when resource picker should supply product
  // objects for the selection as well (e.g Promoted product recommendations flow)
  const [selectedProducts, setSelectedProducts] = React.useState([])

  const { shop } = useQueryParams()
  const productSearch = useApi('recommendations/', {
    shop,
    page: 1,
    count: 20,
    ...additionalSearchParams,
  })

  useEffect(() => {
    async function getInitialSelectedProductsData() {
      let {
        data: { data: initialSelectedProductsData },
      } = await fetchProductsApi({
        queryKey: [
          'products',
          {
            filters: { ids: initialSelectedProductIds },
            shop: accountData.name,
          },
        ],
      })
      initialSelectedProductsData = initialSelectedProductsData.sort(
        (pdt1, pdt2) => {
          const productOneId = pdt1.productId
          const productTwoId = pdt2.productId
          return (
            initialSelectedProductIds.indexOf(productOneId) -
            initialSelectedProductIds.indexOf(productTwoId)
          )
        }
      )

      setSelectedProducts(
        _uniqBy(
          [...selectedProducts, ...initialSelectedProductsData],
          (p) => p.productId
        )
      )
      setLoadingInitialData(false)
    }
    if (initialSelectedProductIds.length > 0) {
      getInitialSelectedProductsData()
    }
  }, [])

  const getUpdatedSearchResults = useDebouncedCallback(() => {
    productSearch.get({ q: query })
  }, 300)

  function onQueryChange(event) {
    const query = event.target.value
    setQuery(query)
    getUpdatedSearchResults.callback()
  }

  function sortProducts(product) {
    let sortedProducts = product
      ? [product, ...selectedProducts]
      : selectedProducts
    switch (sortOption) {
      case SORT_OPTIONS.ALPHABETICAL.value:
        sortedProducts = sortedProducts.sort((pdt1, pdt2) => {
          return pdt1.title > pdt2.title ? 1 : -1
        })
        break
      case SORT_OPTIONS.REVERSE_ALPHABETICAL.value:
        sortedProducts = sortedProducts.sort((pdt1, pdt2) => {
          return pdt1.title < pdt2.title ? 1 : -1
        })
        break
      case SORT_OPTIONS.CHEAPESTFIRST.value:
        sortedProducts = sortedProducts.sort((pdt1, pdt2) => {
          const productOnePrice = parseFloat(pdt1.minPrice || pdt1.price)
          const productTwoPrice = parseFloat(pdt2.minPrice || pdt2.price)
          return productOnePrice - productTwoPrice
        })
        break
      case SORT_OPTIONS.CHEAPESTLAST.value:
        sortedProducts = sortedProducts.sort((pdt1, pdt2) => {
          const productOnePrice = parseFloat(pdt1.minPrice || pdt1.price)
          const productTwoPrice = parseFloat(pdt2.minPrice || pdt2.price)
          return productTwoPrice - productOnePrice
        })
        break
      // case SORT_OPTIONS.LASTSELECTEDLAST.value:
      //   sortedProducts = product
      //     ? [...selectedProducts, product]
      //     : selectedProducts
      //   break
      case SORT_OPTIONS.DEFAULT.value:
      default:
        sortedProducts = product
          ? [...selectedProducts, product]
          : selectedProducts
        break
    }
    return {
      products: _uniqBy(sortedProducts, (p) => p.productId),
      productIds: _uniq(sortedProducts.map((product) => product.productId)),
    }
  }

  function addProductToSelection(product) {
    const { products: sortedProducts, productIds: sortedProductIds } =
      sortProducts(product)
    setSelectedProducts(sortedProducts)
    setSelectedProductIds(sortedProductIds)
  }

  function removeProductFromSelection(product) {
    setSelectedProducts(
      selectedProducts.filter((p) => p.productId !== product.productId)
    )
    setSelectedProductIds(
      selectedProductIds.filter((id) => id !== product.productId)
    )
  }

  return (
    <Box className="flex flex-col items-stretch">
      {store && !store.data?.productsSyncedAt && (
        <div className="flex mb-3">
          <Badge color="yellow" spacing="regular">
            <Loader.Circle color="gray" />
            <span className="ml-2">
              Product sync in progress. You might not be able to search all
              products
            </span>
          </Badge>
        </div>
      )}
      <div className="flex flex-row items-center justify-between">
        <span className="mb-3 text-xl font-semibold text-gray-800">
          {heading}
        </span>
        <span
          className="mb-3 text-sm underline cursor-pointer text-primary"
          onClick={close}
        >
          ← Go Back
        </span>
      </div>
      <input
        type="text"
        value={query}
        onChange={onQueryChange}
        className="block px-3 py-3 text-sm leading-tight bg-white border border-gray-400 rounded shadow cursor-pointer hover:border-gray-500 focus:outline-none focus:shadow-outline"
        placeholder="Search products by name..."
      />
      <div
        className={classNames(
          'flex flex-col flex-1 mt-3',
          styles.resultsContainer
        )}
        ref={resultsContainerRef}
      >
        {!productSearch.data ||
        productSearch.loading ||
        loadingInitialData ||
        !resultsContainerRef.current ? (
          <Loader className="align-self" />
        ) : !productSearch.data.products.length && query ? (
          <div className="flex flex-col items-center">
            <Icons.Search className="w-12 h-12 mt-3 ml-2 opacity-25" />
            <span className="mt-4 text-center text-gray-600">
              {' '}
              No products found matching "{query}"
            </span>
          </div>
        ) : (
          <React.Fragment>
            <InfiniteScroll
              scrollableAncestor={resultsContainerRef.current}
              onLoadMore={() => {
                productSearch.fetchMore((existingData, newData) => {
                  return {
                    ...existingData,
                    ...newData,
                    products: _uniqBy(
                      [...existingData.products, ...(newData.products || [])],
                      (p) => p.productId
                    ),
                  }
                })
              }}
            >
              {productSearch.data.products
                .filter(
                  (product) => !ignoreProductIds.includes(product.productId)
                )
                .map((product) => (
                  <ProductItem
                    product={product}
                    key={product.productId}
                    onSelect={addProductToSelection}
                    onDeselect={removeProductFromSelection}
                    isSelected={selectedProductIds.includes(product.productId)}
                  />
                ))}
            </InfiniteScroll>
            {productSearch.loadingMore && <Loader className="mt-3" />}
          </React.Fragment>
        )}
      </div>
      <div className="flex flex-row items-stretch justify-end mt-3">
        {showTwoWaySyncOption && (
          <Tooltip
            position="top"
            content="Check this box to ensure current product is also added as
                  recommendation for the selected products."
          >
            <div className="flex flex-row items-center mr-3 border-2 border-gray-400 rounded shadow">
              <input
                type="checkbox"
                value={enableTwoWaySync}
                className="ml-1"
                name="two-way-sync-checkbox"
                id="two-way-sync-checkbox"
                onChange={(event) => {
                  const value = event.target.checked
                  updateAppState({ enableTwoWaySync: value })
                  setEnableTwoWaySync(value)
                }}
                checked={enableTwoWaySync}
              />
              <label
                className="w-full p-1 text-xs font-semibold cursor-pointer"
                htmlFor={'two-way-sync-checkbox'}
              >
                Enable Reverse Mapping
              </label>
            </div>
          </Tooltip>
        )}
        <div className="mr-3">
          <Select
            size="small"
            options={Object.keys(SORT_OPTIONS).map((option) => {
              return { ...SORT_OPTIONS[option] }
            })}
            name="sortCriteria"
            onChange={(e) => {
              const value = e.target.value
              updateAppState({ resourcePickerSortOption: value })
              setSortOption(value)
            }}
            value={sortOption}
            className="h-full"
          />
        </div>
        <Button
          size="small"
          onClick={() => {
            const { products: sortedProducts, productIds: sortedProductIds } =
              sortProducts()
            setSelectedProducts(sortedProducts)
            setSelectedProductIds(sortedProductIds)
            onSelect(sortedProductIds, enableTwoWaySync, sortedProducts)
            close()
          }}
          disabled={loadingInitialData}
        >
          {chooseButtonText}
        </Button>
      </div>
    </Box>
  )
}

ResourcePicker.defaultProps = {
  resourceType: 'products',
  chooseButtonText: 'Choose selected products',
  additionalSearchParams: {},
  ignoreProductIds: [],
  initialSelectedProductIds: [],
  showTwoWaySyncOption: false,
}

const SORT_OPTIONS = {
  DEFAULT: {
    label: 'Default',
    value: 'default',
  },
  ALPHABETICAL: {
    label: 'Alphabetical',
    value: 'alphabetical_asc',
  },
  REVERSE_ALPHABETICAL: {
    label: 'Reverse Alphabetical',
    value: 'alphabetical_desc',
  },
  // LASTSELECTEDFIRST: {
  //   label: 'Last Selected First',
  //   value: 'last_first',
  // },
  // LASTSELECTEDLAST: {
  //   label: 'Last Selected Last',
  //   value: 'last_last',
  // },
  CHEAPESTFIRST: {
    label: 'Cheapest First',
    value: 'price_asc',
  },
  CHEAPESTLAST: {
    label: 'Cheapest Last',
    value: 'price_desc',
  },
}
