import React from 'react'
import classNames from 'classnames'

import { getAccountData } from 'utils/account'
import * as ShopifyUtils from 'utils/shopify'

export default function RenderMoney({ price, className, currFormat, style }) {
  const accountData = getAccountData()
  const { currency, currencyFormat } = accountData
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: ShopifyUtils.formatMoney({
          currency,
          currencyFormat: currFormat || currencyFormat,
          price,
        }),
      }}
      style={style}
      className={classNames(className)}
    ></span>
  )
}
