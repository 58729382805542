import React, { useState } from 'react'

import { getPostCheckoutDefaultSections } from 'containers/Funnels/utils'
import Button from 'components/Button'
import { useOpenModal } from 'components/Modal'
import ResourceBuilder from 'components/ResourceBuilder'
import EditorForm from 'components/EditorForm'
import { PROMOTION_TYPES, DEFAULT_PROMOTION } from 'constants/promotions'
import { CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES } from 'constants/support'
import { getAccountData } from 'utils/account'

import useLayoutBuilder from './hooks/useLayoutBuilder'
import Layout from './Layout'

export default function PostCheckout({ builder, node, wb }) {
  const openModal = useOpenModal()
  const { name: shop } = getAccountData()
  const { store } = builder
  const [promotionLayout, setPromotionLayout] = useState(
    Object.keys(node.task.settings).length < 1
      ? DEFAULT_PROMOTION
      : node.task.settings
  )
  const productSyncInProgress =
    !store.data.productsSyncedAt || !store.data.ordersSyncedAt

  return (
    <React.Fragment>
      <div className="flex flex-col h-full px-6 mt-4">
        <EditorForm
          layout={LAYOUT}
          value={{
            type: promotionLayout.type,
          }}
          handleUpdate={async (updatedValue) => {
            const { steps, settings, translations } = promotionLayout
            let confirmation = true
            if (
              steps[0].sections?.length != 0 ||
              Object.keys(settings).length != 0 ||
              Object.keys(translations).length != 0
            ) {
              confirmation = window.confirm(
                'Changing the layout type will remove all existing settings. Are you sure you want to continue ?'
              )
            }
            if (confirmation) {
              const updatedPromotion = {
                ...DEFAULT_PROMOTION,
                steps: [
                  {
                    ...DEFAULT_PROMOTION.steps[0],
                    sections: getPostCheckoutDefaultSections({
                      promotionType: updatedValue.type,
                    }),
                  },
                ],
                ...updatedValue,
              }
              setPromotionLayout(updatedPromotion)
              node.updateTask({ settings: updatedPromotion })
            }
          }}
        />
        <span className="pb-3 mt-4 text-sm text-gray-600">
          Show recommendations after checkout and before the thank you page.
          This allows you to increase AoV after purchase has been made. If you
          already have a post-purchase app installed, you must manually select
          to make Glood Personalized Recommendations active. You can do this in
          the Shopify admin checkout settings. This option only shows if you
          already have a post-purchase app installed.{' '}
          <a
            href={CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES.ENABLE_PP_EXTENSION}
            className="ml-1 underline text-primary"
            target="_blank"
          >
            Learn more
          </a>
        </span>
        <Button
          tooltipContent={
            productSyncInProgress
              ? 'Product sync is in progress. Cannot edit layout.'
              : null
          }
          disabled={productSyncInProgress}
          onClick={() => {
            openModal(
              <LayoutBuilder
                funnel={builder.funnel}
                accountData={builder.accountData}
                initialPromotion={promotionLayout}
                node={node}
                onSave={async () => {
                  const wobject = await wb.saveNodeOneByOne()
                  const payload = {
                    workflow: {
                      nodes: wobject.tasks.map((task) => {
                        return task.id
                      }),
                      edges: wobject.edges,
                      startNode: wobject.startNode,
                    },
                  }
                  return await builder.saveFunnel(payload)
                }}
              />,
              {
                hideCloseButton: true,
                fullScreen: true,
                className: 'bg-white h-screen',
              }
            )
          }}
        >
          Visit Editor
        </Button>
      </div>
    </React.Fragment>
  )
}

export function LayoutBuilder({
  accountData,
  initialPromotion,
  close,
  node,
  funnel,
  onSave,
}) {
  const layoutBuilder = useLayoutBuilder({
    initialPromotion,
    accountData,
    node,
    funnel,
    onSave,
  })
  return (
    <ResourceBuilder
      accountData={accountData}
      close={close}
      builder={layoutBuilder}
      resource={layoutBuilder.promotion}
      PrimaryCTA={PrimaryCTA}
      ActiveScreenComponent={Layout}
    />
  )
}

function PrimaryCTA({ builder, rb }) {
  const { setSaving, saving, saveHandler } = builder
  return (
    <Button
      variant="solid"
      loading={saving}
      // disabled={!!saving}
      onClick={async () => {
        if (!saving) {
          setSaving(true)
          try {
            await saveHandler()
          } catch (err) {}
          setSaving(null)
        }
      }}
    >
      Save
    </Button>
  )
}

const LAYOUT = [
  [
    {
      key: 'type',
      label: 'Promotion Type',
      controlType: 'single_select',
      options: [
        {
          label: PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.label,
          value: PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.value,
        },
        {
          label: PROMOTION_TYPES.MULTI_PRODUCT_UPSELL.label,
          value: PROMOTION_TYPES.MULTI_PRODUCT_UPSELL.value,
        },
      ],
    },
  ],
]
