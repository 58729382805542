import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { validCssColor } from 'utils/browser'

const NAVIGATION_MODES = [
  { label: 'Arrows', value: 'arrows' },
  { label: 'Bullets', value: 'bullets' },
]
const ARROW_STYLE_OPTIONS = [
  { label: 'Rectangle', value: 'rectangle' },
  { label: 'Circle', value: 'circle' },
]
const ARROW_TRANSITIONS = [
  { label: 'Appear on mouse hover', value: 'appear' },
  { label: 'Always visible', value: 'fixed' },
]
const CAROUSEL_BEHAVIOUR_TYPES = [
  { label: 'Loop through products', value: 'carousel' },
  { label: 'Move to first/last at ends', value: 'slider' },
]

export default function CarouselForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })
  const [isArrowLayout, setArrowLayout] = React.useState(
    props.settings.carousel.navigationMode === 'arrows'
  )
  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Carousel Navigation"
          name="navigationMode"
          control="select"
          options={NAVIGATION_MODES}
          onChange={(event) => setArrowLayout(event.target.value === 'arrows')}
          ref={register({
            required: 'Carousel navigation cannot be empty',
          })}
        />
        <Form.Field
          label="Carousel Behaviour"
          name="carouselBehaviour"
          key="carouselBehaviour"
          control="select"
          options={CAROUSEL_BEHAVIOUR_TYPES}
          ref={register({})}
        />
      </Form.FieldRow>
      <Form.FieldRow>
        {isArrowLayout && (
          <Form.Field
            label="Arrow Style"
            name="arrowStyle"
            key="arrowStyle"
            control="select"
            options={ARROW_STYLE_OPTIONS}
            ref={register({})}
          />
        )}
        {isArrowLayout && (
          <Form.Field
            label="Arrow Color"
            name="arrowFill"
            control="color"
            setColor={setValue}
            color={formValues.arrowFill}
            key="arrowFill"
            ref={register({
              required: 'Arrow Color cannot be empty',
              validate: (value) =>
                validCssColor(value) || 'Enter valid CSS color value',
            })}
            formError={errors.arrowFill}
          />
        )}
        {isArrowLayout && (
          <Form.Field
            label="Arrow Background Color"
            name="arrowBackground"
            key="arrowBackground"
            control="color"
            setColor={setValue}
            color={formValues.arrowBackground}
            ref={register({
              required: 'Arrow Background Color cannot be empty',
              validate: (value) =>
                validCssColor(value) || 'Enter valid CSS color value',
            })}
            formError={errors.arrowBackground}
          />
        )}
        {isArrowLayout && (
          <Form.Field
            label="Arrow Transition"
            name="arrowTransition"
            key="arrowTransition"
            control="select"
            options={ARROW_TRANSITIONS}
            ref={register({})}
          />
        )}
        {!isArrowLayout && (
          <Form.Field
            label="Bullet Background Color"
            name="bulletBackground"
            key="bulletBackground"
            control="color"
            setColor={setValue}
            color={formValues.bulletBackground}
            ref={register({
              required: 'Bullet Background Color cannot be empty',
              validate: (value) =>
                validCssColor(value) || 'Enter valid CSS color value',
            })}
            formError={errors.bulletBackground}
          />
        )}
        {!isArrowLayout && (
          <Form.Field
            label="Active Bullet Color"
            name="bulletActiveBackground"
            key="bulletActiveBackground"
            control="color"
            setColor={setValue}
            color={formValues.bulletActiveBackground}
            ref={register({
              required: 'Active Bullet Color cannot be empty',
              validate: (value) =>
                validCssColor(value) || 'Enter valid CSS color value',
            })}
            formError={errors.bulletActiveBackground}
          />
        )}
        {!isArrowLayout && (
          <Form.Field
            label="Bullet Size"
            name="bulletSize"
            key="bulletSize"
            control="input"
            type="number"
            ref={register({
              required: 'Bullet Size cannot be empty',
            })}
            formError={errors.bulletSize}
          />
        )}
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings }) {
  return {
    navigationMode: settings.carousel.navigationMode,
    carouselBehaviour: settings.carousel.glideConfig.type,
    arrowFill: settings.carousel.arrowFill,
    arrowStyle: settings.carousel.arrowStyle,
    arrowBackground: settings.carousel.arrowBackground,
    arrowTransition: settings.carousel.arrowTransition,
    bulletBackground: settings.carousel.bulletBackground,
    bulletActiveBackground: settings.carousel.bulletActiveBackground,
    bulletSize: settings.carousel.bulletSize,
  }
}

function getUpdateObjectFromValues(values) {
  if (
    !values.bulletBackground &&
    !values.bulletActiveBackground &&
    !values.bulletSize
  ) {
    return {
      settings: {
        carousel: {
          navigationMode: values.navigationMode,
          arrowFill: values.arrowFill,
          arrowStyle: values.arrowStyle,
          arrowBackground: values.arrowBackground,
          arrowTransition: values.arrowTransition,
          glideConfig: {
            type: values.carouselBehaviour,
          },
        },
      },
    }
  }
  return {
    settings: {
      carousel: {
        navigationMode: values.navigationMode,
        bulletBackground: values.bulletBackground,
        bulletActiveBackground: values.bulletActiveBackground,
        bulletSize: values.bulletSize,
        glideConfig: {
          type: values.carouselBehaviour,
        },
      },
    },
  }
}
