import { useState, useMemo, useReducer, useCallback, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import updateObject from 'immutability-helper'

import { _isEqual, _pick, _uniqBy } from 'utils/lodash'

export default function useImageSection({ section, handleUpdate }) {
  const [state, dispatch] = useReducer(reducer, section)
  const groups = useMemo(
    () => [
      {
        label: 'Image Settings',
        id: 'image_settings',
        settings: _pick(
          state.settings,
          'sourceKey',
          'sourceFullKey',
          'sourcePrefix',
          'sourceFileName',
          'aspectRatio',
          'altText',
          'layout',
          'border'
        ),
      },
      {
        label: 'Support Text',
        id: 'line_settings',
        settings: _pick(
          state.settings,
          'textBlockVisible',
          'textBlockPlacement',
          'textBlockSpacing',
          'spacing',
          'alignment',
          'lines'
        ),
      },
    ],
    [state.settings]
  )

  const [activeGroup, setActiveGroup] = useState(groups[0]?.id)
  const toggleActiveGroup = useCallback(
    (groupId) => {
      setActiveGroup(activeGroup && activeGroup === groupId ? null : groupId)
    },
    [activeGroup]
  )

  useEffect(() => {
    if (!_isEqual(state, section)) {
      handleUpdate(state)
    }
  }, [state, handleUpdate, section])

  return useMemo(
    () => ({
      data: state,
      groups,
      activeGroup,
      toggleActiveGroup,

      handleSettingsUpdate: (update) => {
        dispatch({ type: 'UPDATE', update: { settings: { $merge: update } } })
      },

      handleLineAdd: () => {
        const newLineId = uuid()
        dispatch({ type: 'ADD_LINE', line: { id: newLineId } })
      },

      handleLineDelete: (lineId) => {
        dispatch({ type: 'DELETE_LINE', lineId })
      },

      handleLineUpdate: ({ id, update: lineUpdate }) => {
        dispatch({
          type: 'UPDATE',
          update: {
            settings: {
              lines: (lines) =>
                lines.map((l) => (l.id === id ? { ...l, ...lineUpdate } : l)),
            },
          },
        })
      },
    }),
    [state, groups, dispatch, activeGroup, toggleActiveGroup]
  )
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return updateObject(state, action.update)

    case 'ADD_LINE':
      return {
        ...state,
        settings: {
          ...state.settings,
          lines: _uniqBy([...(state.settings?.lines || []), action.line], 'id'),
        },
      }

    case 'DELETE_LINE':
      return {
        ...state,
        settings: {
          ...state.settings,
          lines: (state.settings?.lines || []).filter(
            (l) => l.id !== action.lineId
          ),
        },
      }

    default:
      return state
  }
}
