import React from 'react'
import classNames from 'classnames'
import { RadioGroup, Listbox, Transition } from '@headlessui/react'

import Tooltip from 'components/Tooltip'
import Icons from 'components/Icons'

import styles from './styles.module.css'

let controlId = 0
function getControlId() {
  return controlId++
}

export const Switch = React.forwardRef(
  ({ className, label, checked, onChange, name }, ref) => {
    const controlId = getControlId()

    return (
      <div
        className={classNames('flex items-center justify-center', className)}
      >
        <label htmlFor={controlId} className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              id={controlId}
              name={name}
              type="checkbox"
              className="hidden"
              onChange={(event) => onChange(event.target.checked)}
              checked={checked}
              ref={ref}
            />
            <div className="w-8 h-3 bg-gray-400 rounded-full shadow-inner toggle__line "></div>
            <div
              className={classNames(
                'absolute inset-y-0 left-0 w-5 h-5 bg-white rounded-full shadow border-2 border-primary',
                styles.dot
              )}
            ></div>
          </div>
          {label}
        </label>
      </div>
    )
  }
)

const SIZE_TO_CLASS_MAP = {
  small: 'px-2 py-1 text-sm',
  regular: 'px-4 py-2',
}

export const Select = React.forwardRef(
  (
    {
      className,
      options,
      size = 'regular',
      disabled,
      formError,
      ...restControlProps
    },
    ref
  ) => {
    return (
      <div className="relative h-full">
        <select
          {...restControlProps}
          ref={ref}
          className={classNames(
            'block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline',
            { 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
            SIZE_TO_CLASS_MAP[size],
            className
          )}
          disabled={disabled}
        >
          {options.map(({ label, value, locked, ...restOptionsProps }) => (
            <option
              value={value}
              key={value}
              data-locked={locked}
              {...restOptionsProps}
            >
              {label}
            </option>
          ))}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
          <svg
            className="w-4 h-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    )
  }
)

const SELECT_VER_2_SIZE_TO_CLASS_MAP = {
  regular: 'px-4 py-4 text-base',
  small: 'px-3 py-2 text-xs',
}

export function SelectVersionTwo({
  className,
  options,
  size = 'regular',
  label,
  value,
  onChange,
  formError,
  optionsClassName,
  ...restControlProps
}) {
  return (
    <div className="relative h-full">
      <Listbox value={value} onChange={onChange}>
        <Listbox.Button className="flex items-center w-full h-full transition duration-150 ease-in-out rounded-md focus:outline-none">
          {label ? <>{label}</> : value.label}
        </Listbox.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className={classNames('absolute z-10 mt-3 w-full', {
            'min-w-[10rem] -right-[12.5%]': size == 'regular',
          })}
        >
          <Listbox.Options
            className={classNames(
              ' min-w-[3rem] flex flex-col items-stretch shadow-dropdown bg-white rounded border-gray-300 border-[0.3px]',
              optionsClassName
            )}
          >
            {options.map((option, index) => {
              return (
                <Listbox.Option
                  key={option.value}
                  value={option}
                  disabled={option.disabled}
                  className={classNames({
                    'border-gray-100 border-t': index !== 0,
                    hidden: option.hidden,
                  })}
                >
                  {({ active, selected }) => {
                    return (
                      <div
                        className={classNames(
                          'flex flex-row items-center justify-between font-medium leading-5 text-gray-900 transition duration-150 ease-in-out  focus:outline-none ',
                          SELECT_VER_2_SIZE_TO_CLASS_MAP[size],
                          {
                            'text-primary cursor-not-allowed': selected,
                            'cursor-pointer hover:bg-gray-200 focus:bg-gray-200':
                              !selected,
                          }
                        )}
                      >
                        <span>{option.actionLabel || option.label}</span>
                        {option.locked && (
                          <Icons.Lock
                            className={classNames({
                              'w-3': size == 'small',
                              'w-4': size == 'regular',
                            })}
                          />
                        )}
                      </div>
                    )
                  }}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

export const Radio = React.forwardRef(
  (
    {
      className,
      optionsClassName,
      activeClassName,
      options,
      label,
      onChange,
      value,
      size = 'regular',
      formError,
      ...restControlProps
    },
    ref
  ) => {
    return (
      <div className={classNames('flex flex-col')}>
        <RadioGroup
          {...restControlProps}
          className={classNames(className)}
          value={value}
          onChange={onChange}
        >
          {label && <RadioGroup.Label>{label}</RadioGroup.Label>}
          {options.map((option) => {
            return (
              <RadioGroup.Option
                key={option.value}
                className={classNames(optionsClassName, {
                  'opacity-75 cursor-not-allowed text-gray-600':
                    option.disabled,
                  'cursor-pointer': !option.disabled,
                })}
                value={option.value}
                disabled={option.disabled}
              >
                {({ checked }) => (
                  <Tooltip content={option.tooltipContent}>
                    <div
                      className={classNames('flex flex-row items-center', {})}
                    >
                      <div className="relative">
                        <div className="flex items-center justify-center w-5 h-5 mr-2 border border-gray-400 rounded-full">
                          <span
                            className={classNames(
                              'h-3 w-3 rounded-full block',
                              {
                                'bg-primary': checked,
                              }
                            )}
                          ></span>
                        </div>
                      </div>
                      <span className={classNames(activeClassName)}>
                        {option.label}
                      </span>
                    </div>
                  </Tooltip>
                )}
              </RadioGroup.Option>
            )
          })}
        </RadioGroup>
      </div>
    )
  }
)
