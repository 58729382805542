import React from 'react'

import { TriggerReview } from 'containers/Funnels/FunnelBuilder/Review/Overview'

export default function Trigger({ funnel, accountData, onEdit }) {
  if (!funnel) {
    return null
  }
  return (
    <TriggerReview
      onEdit={() => onEdit('trigger')}
      accountData={accountData}
      funnel={funnel}
    />
  )
}
