import React from 'react'
import StatCard from 'components/StatCard'

export default function AnalyticsCards({
  data,
  isNativeCurrencyMode,
  currencySymbol,
  storeCurrency,
}) {
  if (!data) {
    return null
  }

  return (
    <div className="flex flex-row flex-wrap -mx-3">
      {data.map(
        ({
          title,
          currency,
          total,
          value,
          description,
          nativeTotal,
          type,
          nativeValue,
        }) => {
          const numberFormat = currency ? '0a' : '0.0a'
          return (
            <StatCard
              title={title}
              description={description}
              numberFormat={numberFormat}
              storeCurrency={storeCurrency}
              className="self-stretch w-full px-3 mb-6 lg:w-1/3"
              value={
                type == 'currency' && isNativeCurrencyMode ? nativeValue : value
              }
              total={
                isNativeCurrencyMode && type == 'currency' ? nativeTotal : total
              }
              key={title}
              type={type}
              currencySymbol={currencySymbol}
            />
          )
        }
      )}
    </div>
  )
}
