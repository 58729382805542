import React from 'react'

import EditorForm from 'components/EditorForm'
import Icons from 'components/Icons'
import classNames from 'classnames'

export default function Banner({ value, handleUpdate, accountData }) {
  const {
    settings: {
      banner: { fontWeight, textDecoration, fontStyle },
    },
  } = value
  const bannerValues = {
    ...value.settings.banner,
    fontStyle: fontStyle === 'italic' ? true : false,
    textDecoration: textDecoration === 'underline' ? true : false,
    fontWeight: fontWeight === 700 ? true : false,
  }
  return (
    <EditorForm
      layout={LAYOUT}
      value={bannerValues}
      handleUpdate={(values) => {
        const updatedValues = {}

        if (typeof values.fontStyle !== 'undefined') {
          updatedValues.fontStyle = values.fontStyle ? 'italic' : 'normal'
        }
        if (typeof values.textDecoration !== 'undefined') {
          updatedValues.textDecoration = values.textDecoration
            ? 'underline'
            : 'none'
        }
        if (typeof values.fontWeight !== 'undefined') {
          updatedValues.fontWeight = values.fontWeight ? 700 : 400
        }
        const updateObj = {
          settings: {
            banner: {
              ...value.settings.banner,
              ...values,
              ...updatedValues,
            },
          },
        }
        handleUpdate(updateObj)
      }}
    />
  )
}

const LAYOUT = [
  [
    {
      key: 'backgroundColor',
      label: 'Background',
      controlType: 'color',
    },
    {
      key: 'textColor',
      label: 'Text Color',
      controlType: 'color',
    },
  ],
  {
    key: 'bannerText',
    label: 'Banner Text',
    controlType: 'translation',
  },
  [
    {
      key: 'fontFamily',
      label: 'Font',
      controlType: 'input',
    },
    {
      key: 'fontSize',
      label: 'Font Size (In px)',
      controlType: 'number',
    },
  ],
  {
    key: 'alignment',
    label: 'Alignment',
    controlType: 'single_select',
    options: [
      { label: 'Left', value: 'left' },
      { label: 'Center', value: 'center' },
      { label: 'Right', value: 'right' },
    ],
  },
  [
    {
      key: 'fontWeight',
      label: 'Bold',
      controlType: 'switch_icon',
      Icon: BoldIcon,
    },
    {
      key: 'textDecoration',
      label: 'Underline',
      controlType: 'switch_icon',
      Icon: UnderlineIcon,
    },
    {
      key: 'fontStyle',
      label: 'Italics',
      controlType: 'switch_icon',
      Icon: ItalicsIcon,
    },
  ],
]

function BoldIcon({ className }) {
  return <span className={classNames('text-lg font-bold', className)}>B</span>
}

function UnderlineIcon({ className }) {
  return (
    <span
      className={classNames(
        'text-lg font-bold underline underline-offset-4',
        className
      )}
    >
      U
    </span>
  )
}
function ItalicsIcon({ className }) {
  return (
    <span className={classNames('text-lg italic font-bold', className)}>I</span>
  )
}
