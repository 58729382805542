import { POPUP_TEMPLATES } from 'containers/Popups/constants'
import { fakeApi } from 'utils/misc'
import api from './index'

export function templateCreate(data, params = {}) {
  return api.post(`/templates/`, data, { params })
}

export function templateUpdate(tempalteId, data, params = {}) {
  return api.patch(`/templates/${tempalteId}/`, data, { params })
}

export function getDefaultTemplates({ queryKey }) {
  const [_key, shop] = queryKey
  return fakeApi(
    {
      data: {
        templates: [
          {
            id: 234,
            shopTemplateId: 45,
            name: 'Amazon FBT Template',
            type: POPUP_TEMPLATES.AMAZON_FBT.value,
          },
          {
            id: 34534,
            name: 'Grid Template',
            type: POPUP_TEMPLATES.GRID_TEMPLATE.value,
          },
          {
            id: 567,
            name: 'Carousel Template',
            type: POPUP_TEMPLATES.CAROUSEL.value,
          },
        ],
      },
    },
    1000
  )
}
