import React, { useEffect } from 'react'
import classNames from 'classnames'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useQuery } from 'react-query'

import { fetchTrigger, saveTrigger } from 'api/funnel'
import Button from 'components/Button'
import { Select } from 'components/Form/controls'
import SlideOver from 'components/SlideOver'
import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import WaitForData from 'components/WaitForData'
import { getAccountData, getStoreCurrency } from 'utils/account'
import { _find, _get } from 'utils/lodash'
import * as QueryUtils from 'utils/query'

import useTriggerRules from './hooks/useTriggerRules'
import ConditionSlider from './ConditionSlider'
import ConditionInput from './ConditionInput'
import ConditionListInput from './ConditionListInput'
import { OPERATORS, TRIGGERS } from './constants'
// import EligibilitySimulator from './EligibilitySimulator'

export default function Trigger({ builder, rb, accountData }) {
  const { funnel } = builder

  return (
    <TriggerBuilderView
      initialRules={funnel?.trigger}
      builder={builder}
      rb={rb}
      accountData={accountData}
    />
  )
}

function TriggerBuilderView({ initialRules, builder, rb, accountData }) {
  const {
    funnel,
    saveFunnel,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
    funnelSaveError,
  } = builder

  const { setCloseHandler } = rb
  const rules = useTriggerRules({
    initialRules,
  })
  const openModal = useOpenModal()
  const openToast = useToast()

  // function openEligibilitySimulator() {
  //   openModal(<EligibilitySimulator condition={rules} />, {
  //     fullScreen: true,
  //     transparentBackground: true,
  //   })
  // }

  useEffect(() => {
    async function triggerSave() {
      return await saveFunnel({ trigger: rules.data })
    }

    async function confirmClose() {
      // TODO: Use toast to accept confirmation
      return window.confirm(
        'Any unsaved changes will be lost! Are you sure you want to close the window ?'
      )
    }

    setSaveHandler(() => triggerSave)
    setSaveNextHandler(() => async () => {
      const savedFunnel = await triggerSave()
      if (savedFunnel) {
        setActiveScreen('workflow')
      }
    })
    setCloseHandler(() => confirmClose)
  }, [rules.data])

  return (
    <div className="flex flex-col items-stretch w-full px-8 mt-6">
      <section className="flex flex-col items-stretch py-10">
        <div className="flex items-end self-stretch justify-between mb-6">
          <span className="text-lg font-medium text-black">
            Trigger Conditions
          </span>
          {/* {false && (
            <Button
              variant="solid"
              color="primary"
              onClick={openEligibilitySimulator}
              size="small"
            >
              Run Eligibility Simulator
            </Button>
          )} */}
        </div>
        <div className="flex flex-col items-stretch">
          <ConditionGroup
            condition={rules.data}
            triggers={TRIGGERS}
            onAddNewGroup={rules.addNewGroup}
            onAddNewCondition={rules.openConditionSlider}
            onDelete={rules.deleteCondition}
            onUpdateCondition={rules.updateCondition}
            onUpdateGroup={rules.updateGroup}
            readOnly={false}
            errors={funnelSaveError?.errors}
          />
        </div>
      </section>
      <SlideOver
        open={rules.conditionSliderVisible}
        name={'ConditionSelection'}
        onClose={rules.closeConditionSlider}
        relative={true}
      >
        <ConditionSlider
          onClose={rules.closeConditionSlider}
          onChooseCondition={rules.addNewCondition}
          triggers={TRIGGERS}
        />
      </SlideOver>
    </div>
  )
}

export function ConditionGroup({
  condition,
  depth = 1,
  position,
  groupOperator,
  triggers,
  readOnly,
  onAddNewGroup,
  onAddNewCondition,
  onDelete,
  onUpdateCondition,
  onUpdateGroup,
  errors,
}) {
  const { id, operator, conditions = [] } = condition
  const accountData = getAccountData()
  return (
    <div
      className={classNames('relative flex flex-row w-full', {
        'pl-15': depth > 1,
        'border-b border-gray-100': depth === 2,
      })}
    >
      <div
        className={classNames('flex flex-row items-center justify-start', {
          'w-20': position > 1,
        })}
      >
        {position > 1 && groupOperator && (
          <span
            className={classNames(
              'text-xxs font-medium text-gray-50 uppercase bg-gray-800 opacity-60 border border-gray-300',
              {
                'rounded-full p-1 left-4.5':
                  groupOperator === OPERATORS.OR.value,
                'rounded p-1 px-1.5 left-1':
                  groupOperator === OPERATORS.AND.value,
              }
            )}
          >
            {groupOperator}
          </span>
        )}
      </div>
      <div
        className={classNames('relative flex flex-col items-stretch w-full', {
          'pl-4 py-6': depth > 1,
          'bg-gray-50': depth % 3 === 2,
          'bg-gray-200': depth % 3 === 0,
          'border-b border-t border-l border-gray-100': depth > 2,
          'mt-3': position > 0 && depth > 2,
        })}
      >
        <span className="hidden font-medium text-gray-600 text-xxs">{id}</span>
        {depth > 1 && !readOnly && (
          <span
            className="absolute flex flex-row items-center group right-10 top-5"
            onClick={() => onDelete({ type: 'group', id })}
          >
            <Icons.Delete className="text-gray-600 group-hover:text-red-700 w-3.5 mr-1 fill-current" />
            <span className="text-xs text-gray-600 cursor-pointer group-hover:text-red-700 group-hover:underline">
              Delete Group
            </span>
          </span>
        )}
        <div
          className={classNames('flex flex-row items-center', {
            'border-b border-gray-100 pb-6': depth === 1,
          })}
        >
          <div>
            <Select
              name="operator"
              disabled={readOnly}
              options={[OPERATORS.AND, OPERATORS.OR]}
              value={operator}
              onChange={(e) => {
                onUpdateGroup({
                  groupId: id,
                  update: { operator: e.target.value },
                })
              }}
              size="small"
              className=" min-w-8"
            />
          </div>
          <span className="w-auto ml-2 text-xs font-medium text-gray-500">
            of the following conditions match
          </span>
        </div>
        {conditions?.length < 1 && depth == 1 && (
          <span className="pb-2 text-sm font-semibold text-gray-600">
            Trigger Funnel for all orders
          </span>
        )}
        <div className="relative flex flex-col">
          {conditions.map((condition, i) => {
            const { type } = condition
            const Component = type === 'group' ? ConditionGroup : Condition

            return (
              <Component
                key={i}
                condition={condition}
                depth={depth + 1}
                position={i + 1}
                groupOperator={operator}
                groupId={id}
                triggers={triggers}
                readOnly={readOnly}
                onAddNewCondition={onAddNewCondition}
                onAddNewGroup={onAddNewGroup}
                onDelete={onDelete}
                onUpdateCondition={onUpdateCondition}
                onUpdateGroup={onUpdateGroup}
                errors={errors}
                storeCurrency={getStoreCurrency(accountData)}
              />
            )
          })}
        </div>
        {!readOnly && (
          <div className="flex flex-row mt-4 pl-15">
            <Button
              variant="solid"
              className="mr-3"
              onClick={() => onAddNewCondition({ groupId: id })}
              size="xSmall"
            >
              + New Condition
            </Button>
            <Button
              variant="bordered"
              onClick={() => onAddNewGroup({ groupId: id })}
              size="xSmall"
            >
              + New Group
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

function Condition({
  condition,
  depth,
  position,
  groupOperator,
  groupId,
  triggers,
  readOnly,
  onUpdateCondition,
  onDelete,
  errors,
  storeCurrency,
}) {
  const { attribute, operator, values, id } = condition
  const operatorObject = objectInMapFromValue(OPERATORS, operator)
  const attributeObject = _find(triggers, {
    label: condition.attribute,
  })
  const { operators, dataType: valueType } = attributeObject
  const currencySymbol = getSymbolFromCurrency(storeCurrency)

  const errorCode = _get(_find(_get(errors, 'condition', []), { id }), 'error')
  let errorMessage
  if (errorCode) {
    errorMessage =
      ERROR_MESSAGE_FROM_CODE[errorCode] ||
      'Please ensure correct data is added before saving condition.'
  }

  return (
    <div
      className={classNames('relative flex flex-row items-center pl-15', {
        'py-6 border-b border-gray-100': depth >= 2,
        'py-3': depth > 2,
        'mt-3': depth > 2 && position === 1,
        'border-red-400 border-t border-l border-r border-dashed':
          !!errorMessage,
      })}
    >
      <div className="flex flex-col items-stretch">
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center justify-start w-14">
            {position > 1 && groupOperator && (
              <span
                className={classNames(
                  'text-xxs font-medium text-gray-50 uppercase  bg-gray-800 opacity-60 border border-gray-300',
                  {
                    'rounded-full p-1 left-4.5':
                      groupOperator === OPERATORS.OR.value,
                    'rounded p-1 px-1.5 left-1':
                      groupOperator === OPERATORS.AND.value,
                  }
                )}
              >
                {groupOperator}
              </span>
            )}
          </div>
          <div>
            <Select
              name="attribute"
              disabled={readOnly}
              options={triggers.map(({ label, name }) => ({
                value: label,
                label: name,
              }))}
              value={attribute}
              onChange={(e) => {
                onUpdateCondition({
                  id,
                  groupId,
                  update: {
                    attribute: e.target.value,
                    operator: attributeObject.operators[0],
                    values: [],
                  },
                })
              }}
              size="small"
              className="min-w-8"
            />
          </div>
          <div className="ml-3">
            <Select
              name="operator"
              disabled={readOnly}
              options={operators
                .map((o) => objectInMapFromValue(OPERATORS, o))
                .map((o) => {
                  return {
                    label: o.label,
                    value: o.value,
                  }
                })}
              value={operator}
              onChange={(e) => {
                onUpdateCondition({
                  id,
                  groupId,
                  update: {
                    operator: e.target.value,
                    values: [],
                  },
                })
              }}
              size="small"
              className="min-w-8"
            />
          </div>
          <div className="flex flex-row items-center ml-3">
            {operatorObject.list ? (
              <ConditionListInput
                type={valueType}
                value={values}
                onChange={(values) => {
                  onUpdateCondition({
                    id,
                    groupId,
                    update: {
                      values,
                    },
                  })
                }}
                renderOnlyInput={true}
                readOnly={readOnly}
                controlProps={{
                  placeholder: 'Enter value...',
                }}
              />
            ) : (
              <ConditionInput
                type={valueType}
                readOnly={readOnly}
                onChange={(e) => {
                  onUpdateCondition({
                    id,
                    groupId,
                    update: {
                      values: operatorObject.range
                        ? [e.target.value, values[1]]
                        : [e.target.value],
                    },
                  })
                }}
                value={values[0]}
                currencySymbol={currencySymbol}
              />
            )}
            {operatorObject.range && (
              <>
                <span className="mx-2 text-xl font-semibold text-gray-600 text">
                  -
                </span>
                <ConditionInput
                  type={valueType}
                  readOnly={readOnly}
                  onChange={(e) => {
                    onUpdateCondition({
                      id,
                      groupId,
                      update: { values: [values[0], e.target.value] },
                    })
                  }}
                  value={values[1]}
                  currencySymbol={currencySymbol}
                />
              </>
            )}
          </div>
          {!readOnly && (
            <span
              className="flex flex-row items-center ml-3 cursor-pointer group"
              onClick={() => onDelete({ type: 'attribute', id })}
            >
              <Icons.Delete className="text-gray-600 group-hover:text-red-700 w-2.5 mr-1 fill-current" />
            </span>
          )}
        </div>
        {/* {position > 1 && (
          <span
            className={classNames(
              'absolute text-xxs font-medium text-gray-50 uppercase transform -translate-y-1/2 bg-gray-800 opacity-60 border border-gray-300 top-1/2',
              {
                'rounded-full p-1 left-4.5':
                  groupOperator === OPERATORS.OR.value,
                'rounded p-1 px-1.5 left-1':
                  groupOperator === OPERATORS.AND.value,
              }
            )}
          >
            {groupOperator}
          </span>
        )} */}
        {operatorObject.list && values.length > 0 && (
          <ConditionListInput
            type={valueType}
            value={values}
            onChange={(values) => {
              onUpdateCondition({
                id,
                groupId,
                update: {
                  values,
                },
              })
            }}
            renderOnlyList={true}
            readOnly={readOnly}
          />
        )}
        {errorMessage && (
          <span className="absolute self-start text-xs font-semibold text-red-400 top-1 right-1">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

function objectInMapFromValue(map, value) {
  return map[Object.keys(map).filter((key) => map[key].value === value)[0]]
}

const ERROR_MESSAGE_FROM_CODE = {
  invalid_operand_count: 'Please ensure value exists before saving.',
}
