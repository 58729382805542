import React from 'react'

import { createWorkflowNode, updateWorkflowNode } from 'api/funnel'
import { getAccountData } from 'utils/account'
import * as QueryUtils from 'utils/query'
import { getPostCheckoutDefaultSections } from 'containers/Funnels/utils'

import {
  getWorkflowObject,
  getWorkflowRoot,
  addNewNode,
  deleteTask,
  getTaskNode,
} from '../utils'
import { WORKFLOW_TASK_TYPES } from '../constants'
import { DEFAULT_PROMOTION, PROMOTION_TYPES } from 'constants/promotions'

export default function useWorkflow({
  initialWorkflow,
  initialNodes,
  builder,
}) {
  const [selectedNode, setSelectedNode] = React.useState(null)
  const [centered, setCentered] = React.useState(false)
  const [root, setRoot] = React.useState(
    getWorkflowRoot(initialWorkflow, initialNodes)
  )

  const [workflowObject, setWorkflowObject] = React.useState(
    getWorkflowObject(root)
  )
  const accountData = getAccountData()
  const { name: shop } = accountData
  const { funnel } = builder

  const workflowSpace = React.useRef()

  React.useEffect(() => {
    if (centered || !workflowSpace.current) {
      return
    }

    workflowSpace.current.scrollLeft =
      (workflowSpace.current.scrollWidth + 400) / 4
    setCentered(true)
  }, [workflowSpace, centered])

  React.useEffect(() => {
    setWorkflowObject(getWorkflowObject(root))
  }, [root, selectedNode])

  function addNode({ type, edge, previousNode }) {
    const isRoot = workflowObject.tasks.length < 1
    let settings = {}
    if (type === WORKFLOW_TASK_TYPES.POST_CHECKOUT.value) {
      settings = {
        ...DEFAULT_PROMOTION,
        steps: [
          {
            ...DEFAULT_PROMOTION.steps[0],
            sections: getPostCheckoutDefaultSections({
              promotionType: PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.value,
            }),
          },
        ],
      }
    }
    const newNode = addNewNode({
      type,
      edge,
      previousNode,
      setRoot,
      isRoot,
      settings,
    })
    if (isRoot) {
      setRoot(newNode)
    }
    setSelectedNode(newNode)
    QueryUtils.invalidate('workflow-nodes')
    return newNode
  }

  function deleteNode({ node }) {
    const isRoot = node.id == root.id || node.tempId == root.tempId

    QueryUtils.invalidate('workflow-nodes')
    const rootNode = deleteTask({
      node,
      isRoot,
    })

    setRoot(rootNode || null)
    setSelectedNode(null)
    return rootNode
  }

  async function saveNodeOneByOne() {
    const wobject = getWorkflowObject(root)
    try {
      const nodesToBeAdded = wobject.tasks.filter(
        (task) => task.tempId || !task.id
      )
      const nodesToBeUpdated = wobject.tasks.filter((node) => {
        const localNode = getTaskNode(root, node)
        return node.id && localNode.updated
      })

      for (const node of nodesToBeAdded) {
        const {
          data: { node: addedNode },
        } = await createWorkflowNode(shop, funnel, node)
        const localNode = getTaskNode(root, node)
        localNode.setId(addedNode.id)
      }

      for (const node of nodesToBeUpdated) {
        const {
          data: { node: updatedNode },
        } = await updateWorkflowNode(shop, funnel, node)
      }

      QueryUtils.invalidate('workflow-nodes')
      return getWorkflowObject(root)
    } catch (err) {
      console.log(err)
      return workflowObject
    }
  }
  return {
    root,
    setRoot,
    selectedNode,
    setSelectedNode,
    workflowSpace,
    addNode,
    deleteNode,
    workflowObject,
    taskOptionsVisible: Boolean(selectedNode),
    closeTaskOptions: () => {
      setSelectedNode(null)
    },
    saveNodeOneByOne,
  }
}
