import React from 'react'
import Text from 'components/Text'
import Loader from 'components/Loader'

export default function WaitForData({
  objects,
  dataObjects,
  loadingMessage,
  errorMessage,
  children,
  isQueries,
}) {
  if (objects.length === 0) {
    return children()
  }
  const isLoading = objects.reduce((isLoading, object) => {
    return isLoading || object.loading || !object.data || object.isLoading
  }, false)
  const allSuccess =
    objects.reduce((accum, q) => accum && q.isSuccess, true) &&
    dataObjects.reduce((dataAvailable, object) => {
      return dataAvailable && Boolean(object)
    }, true)
  const isError = objects.reduce(
    (isError, object) =>
      isError || object.error || !object.data || object.isError,
    false
  )

  if (isLoading || (isQueries && !allSuccess)) {
    return (
      <div className="flex items-center justify-center flex-1 h-full">
        <Loader message={loadingMessage} />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center flex-1 h-full">
        <Text>{errorMessage}</Text>
      </div>
    )
  }
  return typeof children === 'function' ? children() : children
}

WaitForData.defaultProps = {
  dataObjects: [],
  objects: [],
  children: () => null,
  loadingMessage: 'Fetching data...',
  errorMessage: 'Trouble fetching data!',
  isQueries: false,
}
