import React from 'react'
import classNames from 'classnames'
import numeral from 'numeral'

import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Icons from 'components/Icons'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import * as Integrations from 'constants/integrations'
import Features, { FEATURES_ORDER } from 'constants/features'
import { openChat } from 'utils/external'
import { trackEvent } from 'utils/analytics'
import { getAccountData } from 'utils/account'

export default function Pricing({ plans, shop, billing, onAnnual = false }) {
  const enabledFeaturesInPlans = plans.reduce((enabledFeatures, plan) => {
    return [...enabledFeatures, ...plan.featuresEnabled]
  }, [])
  const featuresToHide = Object.keys(Features)
    .filter((k) => {
      return !enabledFeaturesInPlans.includes(Features[k].value)
    })
    .map((k) => Features[k].value)
  const [showAnnualToggle, setShowAnnualToggle] = React.useState(
    plans.map((plan) => plan.hasAnnualPrice).every(Boolean)
  )
  const [annualSelected, setAnnualSelected] = React.useState(
    onAnnual && showAnnualToggle
  )

  const disabledButtonClass =
    'relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm'
  const activeButtonClass =
    'ml-0.5 relative w-1/2 border border-transparent rounded-md'

  async function toggleAnnual(anualSelected) {
    setAnnualSelected(anualSelected)
  }

  return (
    <React.Fragment>
      {showAnnualToggle && (
        <div className="relative self-center m-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button
            type="button"
            onClick={() => toggleAnnual(false)}
            className={
              (annualSelected ? activeButtonClass : disabledButtonClass) +
              ' py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8'
            }
          >
            Monthly billing
          </button>
          <button
            type="button"
            onClick={() => toggleAnnual(true)}
            className={
              (annualSelected ? disabledButtonClass : activeButtonClass) +
              ' py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8'
            }
          >
            Yearly billing
          </button>
        </div>
      )}

      <div className="flex flex-row items-stretch justify-center mb-6">
        {plans.map((plan) => {
          const isCurrentPlan =
            (billing.shopPlan.plan === plan.id ||
              billing.shopPlan.plan === plan.plan) &&
            Boolean(annualSelected) == Boolean(billing.shopPlan.onAnnual)
          return (
            <Plan
              isCurrentPlan={isCurrentPlan}
              plan={isCurrentPlan ? billing.shopPlan : plan}
              key={plan.id}
              shop={shop}
              hideFeatures={featuresToHide}
              annualSelected={annualSelected}
            />
          )
        })}
      </div>
      <div className="flex flex-row justify-center flex-1 pt-3 font-semibold text-gray-800 border-t">
        Have Questions?{' '}
        <span
          className="ml-1 text-primary underline cursor-pointer"
          onClick={() => openChat()}
        >
          Chat with us
        </span>
      </div>
    </React.Fragment>
  )
}

export function Plan({
  className,
  isCurrentPlan,
  plan,
  shop,
  trackingInfo = { category: 'Pricing', label: 'Dashboard' },
  hideFeatures = [],
  hideDisabledFeatures,
  annualSelected = false,
}) {
  const { integrationsEnabled = [], featuresEnabled = [] } = plan
  const integrationsToShow = Object.keys(Integrations).map(
    (k) => Integrations[k]
  )
  const openModal = useOpenModal()
  const accountData = getAccountData()

  const featuresToShow = Object.keys(Features)
    .filter(
      (k) =>
        !Features[k].hideInPricing && !hideFeatures.includes(Features[k].value)
    )
    .map((k) => Features[k])

  const discountApplied =
    plan.discountedData && plan.discountedData.promoCode && !annualSelected
  const codeNotApplied = plan.discountedData && plan.discountedData.error
  const priceTag = () => {
    if (annualSelected) {
      return (
        <span className="">
          <div className="flex items-baseline">
            <span className="self-center mr-1 text-gray-800">$</span>
            <span className="text-4xl font-semibold leading-none">
              {numeral(plan.annualPrice / 12).format('0,00')}
            </span>
            <span className="text-gray-800">/mo</span>
          </div>
          <div className="flex items-baseline justify-center text-red-400 line-through">
            <span className="mr-1 text-red-400 text-s">
              ${numeral(plan.price).format('0,0')}/mo
            </span>
          </div>
          <p className="mt-2 text-xs text-center text-gray-800">
            Charged Annually
          </p>
        </span>
      )
    } else if (discountApplied && plan.discountedData.price) {
      return (
        <span className="">
          <div className="flex items-baseline">
            <span className="self-center mr-1 text-gray-800">$</span>
            <span className="text-4xl font-semibold leading-none">
              {numeral(plan.discountedData.price).format('0,0')}
            </span>
            <span className="text-gray-800">/mo</span>
          </div>
          <div className="flex items-baseline justify-center text-red-400 line-through">
            <span className="mr-1 text-red-400 text-s">
              ${numeral(plan.price).format('0,0')}/mo
            </span>
          </div>
        </span>
      )
    } else {
      return (
        <span className="flex flex-col items-center">
          <div className="flex items-baseline">
            <span className="self-center mr-1 text-gray-800">$</span>
            <span className="text-4xl font-semibold leading-none">
              {plan.price}
            </span>
            <span className="text-gray-800">/mo</span>
          </div>
        </span>
      )
    }
  }
  return (
    <div
      className={classNames(
        'flex flex-col w-full items-center mx-3 bg-white border rounded',
        className
      )}
    >
      <div className="flex flex-col items-center justify-center w-full py-6">
        <h2 className="mb-4 text-sm font-semibold uppercase">{plan.name}</h2>
        {priceTag()}

        {discountApplied && !annualSelected && (
          <div className="w-4/5 mt-4 text-center text-primary">
            <span>
              Promo code {<strong>{plan.discountedData.promoCode}</strong>}{' '}
              Successfully Applied to this Plan. Please read the details
              carefully. You can upgrade the plan now to make these permanent.
            </span>
            <br />
            <span>{plan.discountedData.description}</span>
          </div>
        )}
        {codeNotApplied && (
          <div className="w-4/5 mt-4 text-center text-red-600">
            <span>Promo code could not be applied!</span>
          </div>
        )}
      </div>
      <Button
        className="w-full py-3 mt-3 rounded-t-none"
        onClick={() => {
          trackEvent({
            category: trackingInfo.category,
            label: trackingInfo.label,
            action: 'Click Upgrade Top',
            value: parseInt(plan.price),
          })
          window.top.location.href = `${plan.chargeUrl}&shop=${shop}&promo_code=${plan.discountedData.promoCode}&on_annual=${annualSelected}`
        }}
        disabled={isCurrentPlan}
      >
        {isCurrentPlan ? 'Your Current Plan' : 'Upgrade'}
      </Button>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 bg-gray-300">
        {plan.trialDays > 0 || true ? (
          <React.Fragment>
            <div className="flex items-baseline">
              {discountApplied && plan.discountedData.trialDays ? (
                <span className="">
                  <span className="mr-1 text-2xl font-semibold">
                    {plan.discountedData.trialDays}{' '}
                  </span>{' '}
                  Days
                  <span className="ml-4 text-red-400">
                    <span className="line-through text-red-400ml-4 text-s">
                      {plan.trialDays} Days
                    </span>
                  </span>
                </span>
              ) : (
                <span>
                  <span className="mr-1 text-2xl font-semibold">
                    {plan.trialDays}
                  </span>
                  Days
                </span>
              )}
            </div>
            <span className="text-gray-800">Risk Free Trial</span>
          </React.Fragment>
        ) : null}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
        <span className="mb-3 text-sm text-gray-800 uppercase">Features</span>
        <span
          className={classNames(
            'mb-1 pb-1 border-b border-gray-200 font-medium capitalize whitespace-no-wrap flex items-start hover:text-primary-300 text-primary underline cursor-pointer'
          )}
          onClick={() => {
            openModal(
              <UpgradeAccountModal
                customPlan={plan}
                account={{ data: accountData }}
              />
            )
          }}
        >
          <span>See Recommendations</span>
        </span>
        {featuresToShow
          .sort((f1, f2) => {
            const f1Enabled = featuresEnabled.includes(f1.value)
            const f2Enabled = featuresEnabled.includes(f2.value)
            if ((!f1Enabled && !f2Enabled) || (f1Enabled && f2Enabled)) {
              return FEATURES_ORDER.indexOf(f1) - FEATURES_ORDER.indexOf(f2)
            }
            if (!f1Enabled) {
              return 1
            }
            return -1
          })
          .map((feature) => {
            const isEnabled = featuresEnabled.includes(feature.value)
            if (!isEnabled && hideDisabledFeatures) {
              return null
            }

            return (
              <span
                className={classNames(
                  'mb-1 pb-1 border-b border-gray-200 font-medium capitalize whitespace-no-wrap',
                  {
                    ['line-through']: !isEnabled,
                  }
                )}
                key={feature.value}
              >
                {isEnabled ? '' : '❌'}{' '}
                {plan.name === 'Free'
                  ? feature.freePlanLabel || feature.label
                  : feature.label}
              </span>
            )
          })}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 bg-gray-300">
        {discountApplied && plan.discountedData.maximumRecommendationsServed ? (
          <span>
            <span className="mr-1 text-2xl font-semibold">
              {numeral(plan.discountedData.maximumRecommendationsServed).format(
                '0,0'
              )}
            </span>
            <span className="text-red-400">
              <span className="ml-4 mr-1 line-through text-s">
                {numeral(plan.maximumRecommendationsServed).format('0,0')}
              </span>
            </span>
          </span>
        ) : (
          <span className="mr-1 text-2xl font-semibold">
            {numeral(plan.maximumRecommendationsServed).format('0,0')}
          </span>
        )}
        <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
          <span className="mr-2 text-gray-800">Widgets Served </span>
          <Tooltip
            position="top"
            content="Widget Served is the number of times a widget is served to a visitor. It is directly proportional to number of widgets on a page and page views."
            showHelpIcon={true}
            helpIconVariant="dark"
          />
        </span>
        {!annualSelected && plan.isUsageBased && (
          <p className="mt-2 text-xs text-center text-gray-800">
            *Once usage exceeds plan quota, you will be charged{' '}
            <strong>
              ${plan.recommendationServesBatchPrice} per{' '}
              {numeral(plan.recommendationServesBatchSize).format('0,0')}{' '}
            </strong>{' '}
            widget serves. This additional charge is capped at{' '}
            <strong>${plan.maxPlanUsageCharge}/mo. </strong>
            Maximum widget serve threshold{' '}
            <strong>
              {numeral(plan.recommendationsServedHardLimit).format('0,0')}{' '}
            </strong>{' '}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
        <span className="mb-3 text-sm text-gray-800 uppercase">
          Integrations
        </span>
        {integrationsToShow
          .sort((i1, i2) => i1.score - i2.score)
          .map((integration) => {
            const isEnabled = integrationsEnabled.includes(integration.value)
            return (
              <span
                className={classNames('mb-1 font-medium capitalize', {
                  ['line-through']: !isEnabled,
                })}
                key={integration.value}
              >
                {isEnabled ? '' : '❌'} {integration.label}
              </span>
            )
          })}
      </div>
      <Button
        className="w-full py-3 mt-3 rounded-t-none"
        onClick={() => {
          trackEvent({
            category: trackingInfo.category,
            label: trackingInfo.label,
            action: 'Click Upgrade Bottom',
            value: parseInt(plan.price),
          })
          window.top.location.href = `${plan.chargeUrl}&shop=${shop}&promo_code=${plan.discountedData.promoCode}&on_annual=${annualSelected}`
        }}
        disabled={isCurrentPlan}
      >
        {isCurrentPlan ? 'Your Current Plan' : 'Upgrade'}
      </Button>
    </div>
  )
}
