import React from 'react'
import { openChat } from 'utils/external'

export default function ChatWithUs({ children = 'Chat with us' }) {
  return (
    <span className="text-primary underline cursor-pointer" onClick={openChat}>
      {children}
    </span>
  )
}
