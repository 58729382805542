import React from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import CodeEditor from 'components/CodeEditor'
import { useToast } from 'components/Toast'
import * as RankingCriteria from 'constants/widgets/rankingCriteria'
import * as FilterCriteria from 'constants/widgets/filterCriteria'
import * as FallbackCriteria from 'constants/widgets/fallbackCriteria'
import { escapeSpecialChars } from 'utils/json'
import { isRkSupport } from 'utils/account'
import { stringToArray } from 'utils/string'

export default function WidgetAdvanced({ widget, onSave, account }) {
  const rkSupport = isRkSupport(account.data)

  return (
    <div className="flex flex-col">
      <AdvancedSettings
        widget={widget}
        onSave={onSave}
        rkSupport={rkSupport}
        account={account}
      />
      {rkSupport && (
        <React.Fragment>
          <AdminSettings
            widget={widget}
            onSave={onSave}
            rkSupport={rkSupport}
            account={account}
          />
          <WidgetExtra widget={widget} onSave={onSave} rkSupport={rkSupport} />
        </React.Fragment>
      )}
    </div>
  )
}

function AdvancedSettings({ widget, onSave, account }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(widget),
  })

  function getDefaultValues(widget) {
    return {
      minPrice: widget.minPrice,
      maxPrice: widget.maxPrice,
      pricePercentageThreshold: widget.pricePercentageThreshold,
      rankingCriteria: widget.rankingCriteria,
      filterCriteria: widget.filterCriteria,
      fallbackCriteria: widget.fallbackCriteria,
      allowIfUnavailable: widget.allowIfUnavailable,
      addCartProperties: widget.addCartProperties,
    }
  }

  const advancedFields = WIDGET_FIELDS.filter((field) => !field.admin)
  return (
    <Box className="mb-6">
      <div className="flex flex-row mb-5 text-sm font-semibold text-gray-800 uppercase">
        Advanced Settings
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit((data) => onSave(data))}
        name="advanced_fields"
      >
        <div className="flex flex-wrap -mx-2">
          {advancedFields.map((fieldProps) => {
            const {
              className: fieldClassName,
              validate,
              pattern,
              admin,
              ...restProps
            } = fieldProps

            return (
              <Form.Field
                key={fieldProps.name}
                className={classNames('px-2', fieldClassName)}
                ref={register({
                  validate,
                  pattern,
                })}
                formError={errors[fieldProps.name]}
                {...restProps}
              />
            )
          })}
        </div>
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}

function AdminSettings({ widget, onSave, account }) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(widget),
  })
  function getDefaultValues(widget) {
    return {
      isCachingEnabled: widget.isCachingEnabled,
      enableRandom: widget.enableRandom,
      minQuantity: widget.minQuantity,
      orderBy: widget.orderBy,
      excludedProducts: getIdsFromArray(widget.excludedProducts).join(','),
      disabledProducts: getIdsFromArray(widget.disabledProducts).join(','),
      excludedTags: widget.excludedTags.join(','),
      enabledUrls: widget.enabledUrls.join(','),
    }
  }

  function getUpdateObjectFromValues(values) {
    return {
      isCachingEnabled: values.isCachingEnabled,
      enableRandom: values.enableRandom,
      ...(values.minQuantity ? { minQuantity: values.minQuantity } : {}),
      orderBy: values.orderBy,
      excludedProducts: stringToArray(values.excludedProducts),
      disabledProducts: stringToArray(values.disabledProducts),
      excludedTags: stringToArray(values.excludedTags),
      enabledUrls: stringToArray(values.enabledUrls),
    }
  }

  function getIdsFromArray(array) {
    return array.reduce((accm, currVar) => {
      return [...accm, currVar.id]
    }, [])
  }
  const adminFields = WIDGET_FIELDS.filter((field) => field.admin)
  return (
    <Box className="mb-6">
      <div className="flex flex-row mb-5 text-sm font-semibold text-gray-800 uppercase">
        Admin Settings
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit((data) =>
          onSave(getUpdateObjectFromValues(data))
        )}
        name="admin_fields"
      >
        <div className="flex flex-wrap -mx-2">
          {adminFields.map((fieldProps) => {
            const {
              className: fieldClassName,
              validate,
              pattern,
              admin,
              ...restProps
            } = fieldProps

            return (
              <Form.Field
                key={fieldProps.name}
                className={classNames('px-2', fieldClassName)}
                ref={register({
                  validate,
                  pattern,
                })}
                formError={errors[fieldProps.name]}
                {...restProps}
              />
            )
          })}
        </div>
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}

function WidgetExtra({ widget, onSave, rkSupport }) {
  const openToast = useToast()
  const [extra, setExtra] = React.useState(
    JSON.stringify(widget.extra, null, 4)
  )

  function handleChange(value) {
    setExtra(value)
  }

  return (
    <Box className="flex flex-col items-start mb-6">
      <div className="text-sm font-semibold text-gray-800 uppercase">
        Widget Extra
      </div>
      <div className="flex flex-row flex-wrap w-full">
        <CodeEditor
          placeholder="You can edit/add JSON here."
          mode="json"
          theme="monokai"
          value={extra}
          onChange={(value) => handleChange(value)}
          name="widget_extra_editor"
          rkSupport={rkSupport}
          height="200px"
        />
      </div>
      <Button
        size="small"
        onClick={() => {
          try {
            onSave({ extra: JSON.parse(escapeSpecialChars(extra)) })
          } catch (err) {
            openToast({
              type: 'error',
              text: 'Error in saving extra. Please ensure JSON is valid.',
            })
          }
        }}
      >
        Save
      </Button>
    </Box>
  )
}

const WIDGET_FIELDS = [
  {
    label: 'Caching Enabled',
    name: 'isCachingEnabled',
    control: 'checkbox',
    className: 'w-full',
    helpText: 'Check this option to enable/disable caching for the widget.',
    admin: true,
  },
  {
    label: 'Random Enabled',
    name: 'enableRandom',
    control: 'checkbox',
    className: 'w-full',
    helpText: 'Is random enabled or not',
    admin: true,
  },
  {
    label: 'Show sold-out items as recommendations',
    name: 'allowIfUnavailable',
    control: 'checkbox',
    className: 'w-full',
    helpText:
      'Products that are unavailable will also be shown as recommendations if enabled.',
  },
  {
    label: 'Enable Cart Properties',
    name: 'addCartProperties',
    control: 'checkbox',
    className: 'w-full',
    helpText: 'Is cart properties enabled or not',
    admin: false,
  },
  {
    label: 'Price Percentage Threshold',
    name: 'pricePercentageThreshold',
    control: 'input',
    className: 'w-1/2',
    helpText:
      'Products with price above this relative to main price will be excluded from the widget',
  },
  {
    label: 'Product Ranking Criteria',
    name: 'rankingCriteria',
    control: 'select',
    options: [
      RankingCriteria.NONE,
      RankingCriteria.PERSONALISED,
      RankingCriteria.BESTSELLERS,
      RankingCriteria.NEW_ARRIVALS,
      RankingCriteria.HIGHEST_PRICED,
      RankingCriteria.LOWEST_PRICED,
    ],
    className: 'w-1/2',
    helpText:
      'This is the criteria which should be used for ranking products. Change this according to your store setup. By default system ranks products randomly to ensure equal visibility for all matched products',
  },
  {
    label: 'Product Filter Criteria',
    name: 'filterCriteria',
    control: 'select',
    options: [
      FilterCriteria.NONE,
      FilterCriteria.PRODUCT_TYPE,
      FilterCriteria.COLLECTION,
      FilterCriteria.PRODUCTS,
      FilterCriteria.VENDOR,
    ],
    className: 'w-1/2',
    helpText:
      'This is the criteria which should be used for filtering products. Change this according to your store setup.',
  },
  {
    label: 'Fallback Criteria',
    name: 'fallbackCriteria',
    control: 'select',
    options: [
      FilterCriteria.NONE,
      FallbackCriteria.RANDOM,
      FallbackCriteria.BESTSELLERS,
      FallbackCriteria.TRENDING,
    ],
    className: 'w-1/2',
    helpText:
      'This is the criteria which should be used as fallback for recommended products.',
  },
  {
    label: 'Minimum Price',
    name: 'minPrice',
    control: 'input',
    validate: (value) => parseFloat(value) !== NaN || 'Enter valid price value',
    className: 'w-1/2',
    helpText:
      'Products below this price will not be recommended in the widget.',
  },
  {
    label: 'Maximum Price',
    name: 'maxPrice',
    control: 'input',
    validate: (value) => parseFloat(value) !== NaN || 'Enter valid price value',
    className: 'w-1/2',
    helpText:
      'Products above this price will not be recommended in the widget.',
  },
  {
    label: 'Minimum Quantity',
    name: 'minQuantity',
    control: 'input',
    className: 'w-1/2',
    validate: (value) => parseFloat(value) !== NaN || 'Enter valid quantity',
    helpText: '',
    admin: true,
  },

  {
    label: 'Order By',
    name: 'orderBy',
    control: 'input',
    className: 'w-1/2',
    helpText: '',
    admin: true,
  },

  {
    label: 'Excluded Products',
    name: 'excludedProducts',
    control: 'input',
    className: 'w-full',
    helpText:
      'Add product id. Adding a product here will make sure that product does not appear as recommendation. In case of multiple, seperate them using commas',
    validate: {
      doesNotEndWithComma: (value) =>
        value[value.length - 1] !== ',' || 'Cannot end with comma',
    },
    admin: true,
  },
  {
    label: 'Disabled Products',
    name: 'disabledProducts',
    control: 'input',
    className: 'w-full',
    helpText:
      'Add product id. Adding a product here will make sure that product does not show the widget recommendations. In case of multiple, seperate them using commas',
    validate: {
      doesNotEndWithComma: (value) =>
        value[value.length - 1] !== ',' || 'Cannot end with comma',
    },
    admin: true,
  },
  {
    label: 'Excluded Tags',
    name: 'excludedTags',
    control: 'input',
    className: 'w-full',
    helpText:
      'Adding a tag here will make sure that the product with those tags do not appear as recommendation. In case of multiple, seperate them using commas',
    validate: {
      doesNotEndWithComma: (value) =>
        value[value.length - 1] !== ',' || 'Cannot end with comma',
    },
    admin: true,
  },
  {
    label: 'Enabled URLs',
    name: 'enabledUrls',
    control: 'input',
    className: 'w-full',
    helpText: 'In case of multiple, seperate them using commas',
    validate: {
      doesNotEndWithComma: (value) =>
        value[value.length - 1] !== ',' || 'Cannot end with comma',
    },
    admin: true,
  },
]
