import React from 'react'
import classNames from 'classnames'
import './styles.css'

const SIZE_TO_CLASS_MAP = {
  regular: 'h-3 w-3',
  large: 'h-5 w-5',
  xLarge: 'h-8 w-8',
}

export default function Loader({
  className,
  message,
  size,
  color,
  style = {},
}) {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center',
        className
      )}
      style={style}
    >
      <div className="flex items-stretch justify-between">
        <div
          className={`loaderDots1 ${SIZE_TO_CLASS_MAP[size]} mr-1 bg-${color}-600`}
        ></div>
        <div
          className={`loaderDots2 ${SIZE_TO_CLASS_MAP[size]} mr-1 bg-${color}-600`}
        ></div>
        <div
          className={`loaderDots3 ${SIZE_TO_CLASS_MAP[size]} bg-${color}-600`}
        ></div>
      </div>
      {message && <div className="mt-2 text-base text-gray-600">{message}</div>}
    </div>
  )
}

Loader.defaultProps = {
  color: 'primary',
  className: '',
  message: '',
  style: {},
  size: 'regular',
}

Loader.Circle = function ({ className = '', size }) {
  return (
    <div
      className={classNames(
        'loaderSpinner w-6 h-6 relative flex justify-center items-center',
        className
      )}
    >
      <div className={classNames(SIZE_TO_CLASS_MAP[size])}></div>
      <div className={classNames(SIZE_TO_CLASS_MAP[size])}></div>
      <div className={classNames(SIZE_TO_CLASS_MAP[size])}></div>
      <div className={classNames(SIZE_TO_CLASS_MAP[size])}></div>
    </div>
  )
}

Loader.Circle.defaultProps = {
  className: '',
  size: 'regular',
}
