import Features from 'constants/features'
import LANGUAGES from 'constants/languages'
import { getAppState } from 'hooks/appState'
import * as QueryUtils from 'utils/query'

import { _get } from './lodash'

export function accountQuotaExpired(account) {
  const recommendationServedLimit =
    account.plan && account.plan.isExtendedUsageEnabled
      ? account.billingCycle.recommendationsServedHardLimit
      : account.billingCycle.maximumRecommendationsServed
  return account.billingCycle.currentUsage >= recommendationServedLimit
}

export function hasSeenReviewModalRecently(store) {
  const reviewModalLastSeenAt = _get(
    store,
    'onboarding.reviewModalLastSeenAt',
    null
  )
  if (!reviewModalLastSeenAt) {
    return false
  }

  const now = new Date().getTime()
  const lastSeen = new Date(reviewModalLastSeenAt).getTime()
  const diffInSeconds = Math.floor((now - lastSeen) / 1000)
  return diffInSeconds <= 24 * 60 * 60 * 20
}

export function isRkSupport(account) {
  const appState = getAppState()

  if (
    appState &&
    typeof appState.adminMode != 'undefined' &&
    !appState.adminMode
  ) {
    return false
  }

  return _get(account, 'isRkSupport', false)
}

export function isStorefrontPasswordEnabled(account) {
  return _get(account, 'storefrontPasswordEnabled', false)
}

export function proxyHost(account) {
  return 'proxy.glood.co'
}

export function emailCampaignsEnabled(account) {
  return featureEnabled(account, Features.EMAIL_RECOMMENDATIONS.value)
}

export function segmentsEnabled(account) {
  return featureEnabled(account, Features.SEGMENTATION.value)
}

export function visitorInsightsEnabled(account) {
  return (
    featureEnabled(account, Features.VISITOR_INSIGHTS.value) ||
    isRkSupport(account)
  )
}

function featureEnabled(account, feature) {
  return _get(account, 'plan.featuresEnabled', []).indexOf(feature) !== -1
}

export function isFreeUser(account) {
  return !isRkSupport(account) && account.plan.name === 'Free'
}

export function getCurrencyFormat(account, store) {
  return (
    store?.data?.currencyFormat ||
    account?.data?.currencyFormat ||
    '${{amount}}'
  )
}

export function translatableLocales(account) {
  // TODO(Dhruv): Add a filter to expose only account.translatable_locales
  return LANGUAGES.map((l) => ({
    value: l.value,
    label: l.label,
  }))
}

export function getShopLocale(accountData) {
  return _get(accountData, 'shop.locale', 'en')
}

export function getStoreCurrency(accountData) {
  return _get(accountData, 'currency', 'USD')
}

export function appEmbedEnableLink(account) {
  const {
    shop: { domain },
    activeThemeId,
    name: shop,
  } = account

  return activeThemeId
    ? `https://${shop}/admin/themes/${activeThemeId}/editor?context=apps`
    : ''
}

export function storeScriptTagEnabled(account) {
  return _get(account, 'storeScriptTagEnabled', true)
}

export function canAddProductDiscounts(account) {
  const discountsEnabled =
    featureEnabled(account, Features.PRODUCT_DISCOUNTS.value) ||
    isRkSupport(account)

  const shopifyScriptsSupported =
    _get(account, 'shop.planName') === 'shopify_plus'

  return discountsEnabled && shopifyScriptsSupported
}

export function isShopifyPlus(accountData) {
  return _get(accountData, 'shop.planName') === 'shopify_plus'
}

export function isStaffPlan(accountData) {
  const shopPlan = _get(accountData, 'shop.planName')
  return shopPlan === 'staff' || shopPlan === 'staff_business'
}

export function eventsReceived(account) {
  const { lastPageView } = account
  return (
    new Date().getTime() - new Date(lastPageView * 1000) <= 24 * 60 * 60 * 1000
  )
}

export function getUserName(account) {
  return (
    _get(account, 'user.firstName', null) ||
    _get(account, 'user.lastName', null) ||
    _get(account, 'shop.name', 'User')
  )
}

export function getAccountData() {
  return QueryUtils.cachedData('account')?.data
}
