import React from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'

import StatCard from 'components/StatCard'
import { AreaChart } from 'components/Chart'
import WaitForData from 'components/WaitForData'
import { useApi } from 'hooks/api'
import { getStoreCurrency } from 'utils/account'

export default function CampaigAnalytics({ account, campaign, store }) {
  const { name: shop } = account.data
  const campaignStats = useApi(`marketing/campaigns/${campaign.id}/stats/`, {
    shop,
  })

  const storeCurrency = getStoreCurrency(account.data)
  const currencySymbol = getSymbolFromCurrency(storeCurrency)

  const charts = [
    {
      title: 'Campaign Performance',
      areas: [
        { id: 'a', title: 'Views', key: 'viewed', color: '#9a3412' },
        { id: 'a', title: 'Clicks', key: 'clicked', color: '#f97316' },
      ],
    },
  ]
  return (
    <WaitForData
      objects={[campaignStats]}
      loadingMessage="Fetching campaign analytics data"
      errorMessage="Trouble fetching campaign analytics data"
    >
      {() => (
        <div>
          <div className="flex flex-row flex-wrap">
            {campaignStats.data.tilesData.map(
              ({ title, currency, total, value, description, type }) => {
                return (
                  <StatCard
                    title={title}
                    description={description}
                    numberFormat={'0a'}
                    currencySymbol={currencySymbol}
                    storeCurrency={storeCurrency}
                    className="w-full px-3 mb-6 lg:w-1/2"
                    value={value}
                    type={type}
                    total={total}
                    key={title}
                  />
                )
              }
            )}
          </div>
          {charts.map((chart) => {
            let tag = <div></div>
            if (chart.areas) {
              tag = (
                <AreaChart
                  key={chart.title}
                  data={campaignStats.data.conversionData}
                  chart={chart}
                />
              )
            }
            return (
              <div key={chart.title} className="w-full px-3 mb-6">
                {tag}
              </div>
            )
          })}
        </div>
      )}
    </WaitForData>
  )
}
