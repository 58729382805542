import React from 'react'
import { useQuery } from 'react-query'

import { fetchProducts as fetchProductsApi } from 'api/products'
import { _get } from 'utils/lodash'
import { getAccountData } from 'utils/account'
import * as QueryUtils from 'utils/query'
import Loader from 'components/Loader'

export default function WithProducts({
  pickAny = false,
  keepPreviousData = true,
  productIds,
  children,
}) {
  const accountData = getAccountData()
  const productSearchQuery = useQuery(
    [
      'products',
      {
        shop: accountData.name,
        filters: {
          ...(productIds.length > 0 ? { ids: productIds } : {}),
        },
      },
    ],
    fetchProductsApi,
    {
      staleTime: 20 * 60 * 1000,
    }
  )
  return (
    <React.Fragment>
      {productSearchQuery.isSuccess ? (
        children({
          products: QueryUtils.data(productSearchQuery).data,
        })
      ) : (
        <Loader className="h-full" />
      )}
    </React.Fragment>
  )
}
