import React from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import Icons from 'components/Icons'
import Button from 'components/Button'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import WithProducts from 'components/WithProducts'
import { BUNDLES } from 'constants/features'
import { _get, _find, _identity } from 'utils/lodash'
import { isFeatureEnabled } from 'utils/features'
import * as ShopifyUtils from 'utils/shopify'
import { getCurrencyFormat } from 'utils/account'
import { renderCurrency } from 'utils/currency'
import { DISCOUNT_LEVEL_OPTIONS } from 'containers/Bundles/constants'
import {
  calculateDiscountPrice,
  getDiscountLevelLabelFromType,
  getDiscountTypeLabelFromType,
  getDiscountValue,
  getTriggerTypeLabelFromType,
} from 'containers/Bundles/utils'
import { format } from 'date-fns'
import { DiscountLine } from '../../Bundle'

export default function Overview({
  onEdit,
  bundle,
  mode = 'builder',
  accountData,
  builder,
}) {
  const canPublish = isFeatureEnabled(accountData, BUNDLES)
  const openModal = useOpenModal()
  const isBuilder = mode == 'builder'
  if (!bundle) {
    return null
  }
  return (
    <div className="flex flex-col items-stretch pb-10">
      {!canPublish && isBuilder && (
        <div
          className={classNames('flex flex-col items-stretch mb-6', {
            'px-2': mode === 'details',
          })}
        >
          <UpgradeAccountModal
            featureToEnable={BUNDLES}
            account={{ data: accountData }}
            mode="banner"
          />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 border-b border-gray-200',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <DetailsReview bundle={bundle} onEdit={() => onEdit('details')} />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <TriggerReview
          bundle={bundle}
          accountData={accountData}
          onEdit={() => onEdit('trigger')}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <BundleReview
          builder={builder}
          bundle={bundle}
          accountData={accountData}
          onEdit={() => onEdit('bundle')}
        />
      </div>
    </div>
  )
}

export function DetailsReview({ bundle, accountData, onEdit }) {
  const DATE_FORMAT = 'dd-MM-yyyy hh:mmaa'
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick className="w-6 mr-2 text-primary" />
        <span className="text-lg font-semibold text-gray-800">Details</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <div className="flex flex-col mr-32">
          <span className="mb-1 text-sm text-gray-600">Name</span>
          <span className="font-medium text-gray-800">{bundle.name}</span>
        </div>
        {/* <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">
            Start Date &#38; Time
          </span>
          <span className="font-medium text-gray-800">
            {bundle.startTime
              ? format(bundle.startTime * 1000, DATE_FORMAT)
              : 'N/A'}
          </span>
        </div>
        <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">
            End Date &#38; Time
          </span>
          <span className="font-medium text-gray-800">
            {bundle.endTime
              ? format(bundle.endTime * 1000, DATE_FORMAT)
              : 'N/A'}
          </span>
        </div> */}
      </div>
    </React.Fragment>
  )
}

function TriggerReview({ accountData, bundle, onEdit }) {
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick className="w-6 mr-2 text-primary" />
        <span className="text-lg font-semibold text-gray-800">Trigger</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <div className="flex flex-col mr-32">
          <span className="mb-1 text-sm text-gray-600">Trigger Type</span>
          <span className="font-medium text-gray-800">
            {Object.values(bundle.triggers)
              .map((trigger) => {
                return getTriggerTypeLabelFromType(trigger.type)
              })
              .join(', ')}
          </span>
        </div>
        {/* <div className="flex flex-col mr-10">
          <span className="mb-1 text-sm text-gray-600">Template</span>
          <div className="flex flex-row items-center">
            <span className="mr-10 font-medium text-gray-800">
              {bundle.template?.type ? bundle.template.type : 'N/A'}
            </span>
            <Button size="small" variant="text">
              <div className="flex flex-row items-center">
                <Icons.WidgetView className="w-3" />
                <span className="ml-2">Preview</span>
              </div>
            </Button>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  )
}

function BundleReview({ accountData, bundle, onEdit }) {
  const currencyFormat = getCurrencyFormat({ data: accountData })

  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick className="w-6 mr-2 text-primary" />
        <span className="text-lg font-semibold text-gray-800">Bundle</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <div className="flex flex-col mr-32">
          <span className="mb-1 text-sm text-gray-600">Discount Level</span>
          <span className="font-medium text-gray-800">
            {bundle.type
              ? getDiscountLevelLabelFromType(bundle.type)
              : 'Apply discount on individual product'}
          </span>
        </div>
      </div>
      <div className="min-h-[6rem] flex items-center justify-center">
        {bundle.items?.products?.length > 0 ? (
          <WithProducts
            productIds={bundle.items.products.reduce((accm, currVal) => {
              accm.push(currVal.productId)
              return accm
            }, [])}
          >
            {({ products: productsWithData }) => {
              return (
                <div className="flex flex-col items-start w-full pl-8 mt-4">
                  {bundle.type ===
                    DISCOUNT_LEVEL_OPTIONS.BUNDLE_DISCOUNT.value && (
                    <DiscountLine
                      products={productsWithData}
                      discountState={bundle.discounts}
                      readOnly={true}
                    />
                  )}
                  {bundle.items.products.map((productConfig) => {
                    const productData = productsWithData.find(
                      (pdt) => pdt.productId == productConfig.productId
                    )
                    if (!productData) {
                      return null
                    }

                    const {
                      productId,
                      image,
                      variants,
                      title,
                      price,
                      // discount: { type: discountType, value: discountValue },
                    } = productData

                    const {
                      discount: { type: discountType, value: discountValue },
                      variantIds,
                      variantsCount,
                    } = productConfig

                    return (
                      <div
                        className="flex flex-row items-center gap-32 mb-4"
                        key={productId}
                      >
                        <div className="flex flex-row items-center w-72">
                          <img
                            src={ShopifyUtils.optimisedImage({
                              baseUrl: image,
                              size: 160,
                            })}
                            alt={title}
                            className="object-cover h-20 w-14"
                          />
                          <div className="flex flex-col items-start ml-4">
                            <span className="text-sm">{title}</span>
                            <span
                              className="text-xs text-gray-500"
                              dangerouslySetInnerHTML={{
                                __html: renderCurrency(price, currencyFormat),
                              }}
                            ></span>
                          </div>
                        </div>
                        {bundle.type ===
                          DISCOUNT_LEVEL_OPTIONS.INDIVIDUAL_DISCOUNTS.value && (
                          <React.Fragment>
                            <div className="flex flex-col ">
                              <span className="mb-1 text-sm text-gray-600">
                                Discount Type
                              </span>
                              <span className="font-medium text-gray-800">
                                {getDiscountTypeLabelFromType(discountType)}
                              </span>
                            </div>

                            <div className="flex flex-col">
                              <span className="mb-1 text-sm text-gray-600">
                                Discount Value
                              </span>
                              <span className="font-medium text-gray-800">
                                {getDiscountValue(
                                  discountType,
                                  discountValue,
                                  currencyFormat
                                )}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="mb-1 text-sm text-gray-600">
                                Discounted Price
                              </span>
                              <span className="font-medium text-gray-800">
                                {renderCurrency(
                                  calculateDiscountPrice(
                                    price,
                                    discountType,
                                    discountValue
                                  ),
                                  currencyFormat
                                )}
                              </span>
                            </div>
                          </React.Fragment>
                        )}
                        {variantsCount && (
                          <div className="flex flex-col">
                            <span className="mb-1 text-sm text-gray-600">
                              Variation Count
                            </span>
                            <span className="font-medium text-gray-800">
                              {variantsCount}
                            </span>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            }}
          </WithProducts>
        ) : (
          <span className="text-xl font-semibold text-gray-400">
            No products added to bundle
          </span>
        )}
      </div>
    </React.Fragment>
  )
}
