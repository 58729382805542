import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { validCssColor } from 'utils/browser'

const TITLE_ALIGN_OPTIONS = [
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' },
]
const VENDOR_POSITION_OPTIONS = [
  { label: 'Hidden', value: 'hidden' },
  { label: 'Above product title', value: 'above' },
  { label: 'Below product title', value: 'below' },
]

export default function ProductTitleForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Title Font Size"
          name="fontSize"
          control="input"
          type="number"
          ref={register({
            required: 'Please enter a valid number as product title font size',
          })}
          formError={errors.fontSize}
        />
        <Form.Field
          label="Title Color"
          name="color"
          control="color"
          setColor={setValue}
          color={formValues.color}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.color}
        />
        <Form.Field
          label="Title Alignment"
          name="align"
          control="select"
          options={TITLE_ALIGN_OPTIONS}
          ref={register({
            required: 'Product title alignment cannot be empty',
          })}
        />
        <Form.Field
          label="Title Maximum Rows"
          name="titleRows"
          control="input"
          type="number"
          ref={register({
            required:
              'Please enter a valid number as maximum rows for product title',
          })}
          formError={errors.titleRows}
        />
      </Form.FieldRow>
      <Form.FieldRow className="pt-3 mt-3 border-t border-gray-200">
        <Form.Field
          label="Vendor Position"
          name="vendorPosition"
          control="select"
          options={VENDOR_POSITION_OPTIONS}
          ref={register({ required: 'Vendor text position cannot be empty' })}
        />
        <Form.Field
          label="Vendor Font Size"
          name="vendorFontSize"
          control="input"
          type="number"
          ref={register({
            required: 'Please enter a valid number as vendor text font size',
          })}
          formError={errors.vendorFontSize}
        />
        <Form.Field
          label="Vendor Color"
          name="vendorColor"
          control="color"
          setColor={setValue}
          color={formValues.vendorColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.vendorColor}
        />

        {typeof props.settings.productCard.productTitleFontFamily !==
          'undefined' && (
          <Form.Field
            label="Title Font Family"
            name="productTitleFontFamily"
            control="input"
            type="text"
            ref={register()}
            formError={errors.productTitleFontFamily}
          />
        )}
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings }) {
  return {
    color: settings.productCard.titleColor,
    fontSize: parseInt(settings.productCard.titleFontSize),
    align: settings.productCard.titleAlign,
    titleRows: settings.productCard.maxTitleRows,
    vendorPosition: settings.productCard.vendorPosition,
    vendorColor: settings.productCard.vendorColor,
    vendorFontSize: parseInt(settings.productCard.vendorFontSize),
    productTitleFontFamily: settings.productCard.productTitleFontFamily,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      productCard: {
        titleFontSize: `${values.fontSize}px`,
        titleColor: values.color,
        titleAlign: values.align,
        vendorPosition: values.vendorPosition,
        vendorColor: values.vendorColor,
        vendorFontSize: `${values.vendorFontSize}px`,
        maxTitleRows: parseInt(values.titleRows),
        productTitleFontFamily: values.productTitleFontFamily,
      },
    },
  }
}
