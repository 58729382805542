import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'
import {
  PROMOTION_TYPES,
  PROMOTION_LAYOUT_SECTION_TYPES,
} from 'constants/promotions'
import { _find, _identity } from 'utils/lodash'

import Banner from './Banner'
import Text from './Text'
import Image from './Image'
import SingleProductUpsell from './SingleProductUpsell'
import MultiProductUpsell from './MultiProductUpsell'
import SectionNavigation, { sectionLabelFromType } from './SectionNavigation'

export default function PromotionBuilderLayoutEditor({
  layout,
  accountData,
  promotionType,
}) {
  const {
    data,
    setActiveSectionId,
    activeSectionId,
    deleteSection,
    updateSection,
    reorderSections,
  } = layout

  const activeSection = _find(data.sections, { uuid: activeSectionId })
  const ActiveSectionComponent = activeSection
    ? SECTION_TYPE_TO_COMPONENT_MAP[activeSection.type]
    : null

  return (
    <div className="flex flex-col items-stretch w-full h-full bg-gray-background shadow-sidebar-reverse">
      <nav
        className={classNames(
          'flex flex-row items-center px-4 bg-white h-16 group',
          {
            'hover:bg-gray-10 cursor-pointer': activeSection,
          }
        )}
        onClick={activeSection ? () => setActiveSectionId(null) : _identity}
      >
        {activeSection && (
          <Icons.ChevronRight className="w-5 mr-5 text-gray-600 transition-transform transform rotate-180 translate-x-1 stroke-current group-hover:translate-x-0 group-hover:text-primary" />
        )}
        <span
          className={classNames('text-black text-lg', {
            'font-semibold': !activeSection,
            'font-medium': activeSection,
          })}
        >
          {activeSection
            ? sectionLabelFromType(activeSection.type)
            : 'Editor Options'}
        </span>
      </nav>
      <div className="flex flex-1 min-h-0 bg-white">
        <div className="flex-1 overflow-y-auto">
          {activeSection && ActiveSectionComponent ? (
            <div className="flex flex-col h-full">
              <ActiveSectionComponent
                section={activeSection}
                handleUpdate={(sectionUpdate, errors) =>
                  updateSection({
                    sectionId: activeSection.uuid,
                    update: sectionUpdate,
                    errors,
                  })
                }
                accountData={accountData}
              />
              {canDeleteSection({ promotionType, section: activeSection }) &&
                false && (
                  <button
                    className="flex items-center justify-end flex-shrink-0 w-full h-12 px-6 mt-2 text-red-400 transition-colors cursor-pointer hover:text-red-600"
                    onClick={() => deleteSection(activeSectionId)}
                  >
                    <Icons.Delete className="w-4 mr-2" />{' '}
                    <span className="font-semibold leading-none">
                      Delete section
                    </span>
                  </button>
                )}
            </div>
          ) : (
            <SectionNavigation
              sections={data.sections}
              onSectionClick={(sectionId) => setActiveSectionId(sectionId)}
              handleSectionReorder={reorderSections}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const SECTION_TYPE_TO_COMPONENT_MAP = {
  [PROMOTION_LAYOUT_SECTION_TYPES.BANNER.value]: Banner,
  [PROMOTION_LAYOUT_SECTION_TYPES.TEXT.value]: Text,
  [PROMOTION_LAYOUT_SECTION_TYPES.IMAGE.value]: Image,
  [PROMOTION_LAYOUT_SECTION_TYPES.SINGLE_PRODUCT_UPSELL.value]:
    SingleProductUpsell,
  [PROMOTION_LAYOUT_SECTION_TYPES.MULTI_PRODUCT_UPSELL.value]:
    MultiProductUpsell,
}

function canDeleteSection({ promotionType, section }) {
  if (
    (promotionType === PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.value &&
      section.type ===
        PROMOTION_LAYOUT_SECTION_TYPES.SINGLE_PRODUCT_UPSELL.value) ||
    (promotionType === PROMOTION_TYPES.MULTI_PRODUCT_UPSELL.value &&
      section.type ===
        PROMOTION_LAYOUT_SECTION_TYPES.MULTI_PRODUCT_UPSELL.value)
  ) {
    return false
  }

  return true
}
