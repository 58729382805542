import React from 'react'

import ResourceList from 'components/ResourceList'
import TableCell from './Cell'
export default function BundleList() {
  return (
    <ResourceList
      resourceType="bundle"
      schema={BUNDLE_SCHEMA}
      initialView={{
        fields: ['details', 'status', 'type', 'triggers'],
        filters: {},
        order: [],
      }}
      CustomTableCell={TableCell}
      options={{ filters: { skipFilters: true } }}
    />
  )
}

const BUNDLE_SCHEMA = {
  sections: [],
  schema: {
    details: {
      title: 'Bundle Name',
      type: 'details',
      allowFilter: true,
      width: '19rem',
    },
    status: {
      title: 'Status',
      type: 'status',
      allowFilter: true,
    },
    type: {
      title: 'Type',
      type: 'discountLevel',
    },
    triggers: {
      title: 'Triggers',
      type: 'triggers',
      allowFilter: true,
    },
  },
}
