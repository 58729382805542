import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import api, { setSessionKey } from 'api'
import { useQueryParams } from 'hooks/router'
import useAppState from 'hooks/appState'
import { _get } from 'utils/lodash'

export function useLogin() {
  const [shops, setShops] = useState([])
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { state: appState, updateState: updateAppState } = useAppState()
  const { hmac, timestamp, locale, ...restQueryParams } = useQueryParams()
  const shop = restQueryParams.shop ? restQueryParams.shop : appState.shop
  const location = useLocation()

  useEffect(() => {
    const fetchData = async () => {
      setError(false)
      setLoading(true)
      updateAppState({ shop })
      try {
        const data = {
          hmac,
          shop,
          locale,
          timestamp,
          ...restQueryParams,
        }
        const response = await api.post(
          `shopify/auth/login/${location.search}`,
          data
        )
        setSessionKey(_get(response, 'data.sessionKey', ''))
        setShops(_get(response, 'data.shops', []))
        setSuccess(true)
      } catch (err) {
        setError(true)
      }

      setLoading(false)
    }

    if (shop) {
      fetchData()
    } else {
      setError(true)
    }
  }, [hmac, timestamp, locale])

  return [success, loading, error, shops]
}
