import { useEffect, useMemo, useReducer, useState, useCallback } from 'react'
import updateObject from 'immutability-helper'
import { v4 as uuid } from 'uuid'

import { _pick, _isEqual } from 'utils/lodash'

import {
  PROMOTION_RECOMMENDATION_TYPES,
  DISCOUNT_TYPES,
} from 'constants/promotions'

export default function useSingleProductUpsell({ section, handleUpdate }) {
  const [state, dispatch] = useReducer(reducer, section)
  const [formError, setFormError] = useState({})
  const groups = useMemo(
    () => [
      ...state?.settings?.slots.map((slot, index) => {
        return {
          label: `Slot ${index + 1}`,
          id: `slot-${index}`,
          type: 'slot',
          value: slot,
          buildUpdate: (values) => ({
            settings: (settings) =>
              updateObject(settings, {
                slots: (slots) =>
                  updateObject(slots, {
                    [index]: { $merge: values },
                  }),
              }),
          }),
        }
      }),
    ],
    [state?.settings]
  )

  function addSlot(values) {
    const add = {
      settings: (settings) =>
        updateObject(settings, {
          slots: (slots) =>
            updateObject(slots, {
              $push: [
                {
                  id: uuid(),
                  recommendationType:
                    PROMOTION_RECOMMENDATION_TYPES.AUTOMATIC.value,
                  recommendationRules: {},
                  discount: {
                    type: DISCOUNT_TYPES.PERCENTAGE.value,
                    value: 0,
                  },
                },
              ],
            }),
        }),
    }
    dispatch({ type: 'ADD', add })
  }

  function deleteSlot(slotToBeDeleted) {
    const del = {
      settings: (settings) =>
        updateObject(settings, {
          slots: (slots) =>
            updateObject(slots, {
              $set: slots.filter((slot) => slot.id !== slotToBeDeleted.id),
            }),
        }),
    }
    dispatch({ type: 'DELETE', del })
  }

  const [activeGroup, setActiveGroup] = useState(null)
  const toggleActiveGroup = useCallback(
    (groupId) => {
      setActiveGroup(activeGroup && activeGroup === groupId ? null : groupId)
    },
    [activeGroup]
  )

  useEffect(() => {
    if (!_isEqual(state, section)) {
      handleUpdate(state, formError)
    }
  }, [state, handleUpdate, section, formError])

  return useMemo(() => {
    return {
      data: state,
      groups,
      activeGroup,
      toggleActiveGroup,
      updateGroup: (update) => dispatch({ type: 'UPDATE', update }),
      setErrors: (errors, group) => {
        if (errors && Object.keys(errors).length > 0) {
          setFormError({ ...formError, [group.id]: errors })
        } else {
          const updatedErrors = { ...formError }
          delete updatedErrors[group.id]
          setFormError(updatedErrors)
        }
      },
      addSlot,
      deleteSlot,
    }
  }, [state, toggleActiveGroup, groups, activeGroup])
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      const update = updateObject(state, action.update)
      return _isEqual(update, state) ? state : update
    case 'ADD':
      const added = updateObject(state, action.add)
      return _isEqual(added, state) ? state : added
    case 'DELETE':
      const deleted = updateObject(state, action.del)
      return _isEqual(deleted, state) ? state : deleted
    default:
      return state
  }
}
