import React from 'react'
import classNames from 'classnames'
import numeral from 'numeral'
import api from 'api'
import { Disclosure } from '@headlessui/react'

import Button from 'components/Button'
import Badge from 'components/Badge'
import { Switch, Select } from 'components/Form/controls'
import Tooltip from 'components/Tooltip'
import { useOpenModal } from 'components/Modal'
import Icons from 'components/Icons'
import useAppState from 'hooks/appState'
import {
  pageLabelFromType,
  widgetDescriptionFromType,
  widgetLabelFromType,
} from 'utils/constants'
import { canConfigureRecommendations } from 'utils/widget'
import { getPercentage } from 'utils/numbers'
import * as QueryUtils from 'utils/query'

import AddWidgetNew from 'components/CreateWidgetNew'
import titleToLanguageMap from 'constants/widgets/defaultTitle'

export default function WidgetsCard({
  pages,
  billing,
  account,
  experience,
  totalWeight,
  updateWidgetData,
}) {
  const {
    data: { name: shop },
  } = account
  const pagesData = pages.data
  const { state: appState, updateState: updateAppState } = useAppState()
  const openModal = useOpenModal()

  let activePageType = appState.pageTypeFilter

  const activePageOptions = [
    { label: 'All pages', value: 'all' },
    ...(pagesData || []).map((page) => ({
      label: pageLabelFromType(page.type),
      value: page.type,
    })),
  ]

  let activePage
  if (activePageType === 'all') {
    activePage = {
      widgets: (pagesData || []).reduce(
        (widgets, page) => [...widgets, ...page.widgets],
        []
      ),
      order: (pagesData || []).reduce(
        (order, page) => [...order, ...page.order],
        []
      ),
    }
  } else {
    activePage = (pagesData || []).filter(
      ({ type }) => type === activePageType
    )[0]
  }

  function updateActivePageType(pageType) {
    updateAppState({ pageTypeFilter: pageType })
  }

  async function handleWidgetEnabledChange(widgetId, enabled) {
    const { data } = await api.put(
      `widgets/${widgetId}/`,
      { id: widgetId, enabled },
      { params: { shop } }
    )

    const newData = pages.data.map((page) => {
      const widget = page.widgets.filter(({ id }) => id === widgetId)[0]
      if (widget) {
        return {
          ...page,
          widgets: page.widgets.map((widget) => {
            return widget.id === widgetId ? data : widget
          }),
        }
      } else {
        return page
      }
    })

    if (typeof pages.update === 'function') {
      pages.update(newData, { local: true })
    }
    QueryUtils.invalidate('pages')
    updateWidgetData([data], experience)
  }

  return (
    <Disclosure defaultOpen={experience.isDefault}>
      {({ open }) => {
        return (
          <>
            <Disclosure.Button
              className="cursor-pointer focus:outline-none"
              as="div"
            >
              <div
                className={classNames(
                  'flex flex-row items-center justify-between'
                )}
              >
                <div className="flex flex-row items-center justify-start">
                  <Icons.ChevronDown
                    className={classNames('w-4 mr-2 text-gray-700')}
                    style={{
                      transition: 'all 0.25s',
                      transform: open ? 'rotate(180deg)' : '',
                    }}
                  />

                  <span className="text-xs leading-tight tracking-wide text-gray-700 uppercase">
                    {experience.name}
                  </span>
                </div>
                <div className="flex flex-row">
                  <Select
                    size="small"
                    className="h-full"
                    options={activePageOptions}
                    value={activePageType}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(event) =>
                      updateActivePageType(event.target.value)
                    }
                  />
                  <Button
                    className="ml-2"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation()
                      openModal(
                        <AddWidgetNew
                          pages={pages}
                          billing={billing}
                          account={account}
                          experience={experience}
                          initialWidget={{
                            title: titleToLanguageMap[account.data.shop.primaryLocale]
                          }}
                        />,
                        {
                          hideCloseButton: true,
                          fullScreen: true,
                          className: 'bg-white h-screen',
                        }
                      )
                    }}
                  >
                    + Add New Widget
                  </Button>
                </div>
              </div>
              <div
                className={classNames(
                  'flex flex-row items-center justify-between text-xs mt-5',
                  { 'border-b pb-2 mb-3': open }
                )}
              >
                <div className="flex flex-row items-center">
                  <span className="flex mr-1 text-xs leading-tight">
                    (Slug : {experience.slug},
                  </span>
                  <span className="flex mr-2 text-xs leading-tight">
                    ID : {experience.id})
                  </span>
                  <Tooltip
                    position="top"
                    content={
                      <div className="flex flex-col">
                        {experience.status === 'published' ? (
                          <span className="mb-1">
                            Showing to{' '}
                            {getPercentage(experience.weight, totalWeight)}% of
                            the current visitors
                          </span>
                        ) : (
                          <span className="mb-1">
                            This experience is unpublished and will not be shown
                            to any visitors.
                          </span>
                        )}
                      </div>
                    }
                    helpIconVariant="dark"
                  >
                    <span className="flex text-xs font-semibold leading-tight">
                      Weightage : {experience.weight}
                    </span>
                  </Tooltip>
                </div>
                <div className="flex flex-row">
                  {experience.status === 'published' ? (
                    <Badge
                      color="green"
                      className="uppercase"
                      spacing="regular"
                    >
                      Published
                    </Badge>
                  ) : (
                    <Badge
                      color="neutral"
                      className="uppercase"
                      spacing="regular"
                    >
                      Unpublished
                    </Badge>
                  )}

                  {experience.isDefault && (
                    <Badge
                      color="neutral"
                      className="ml-3 uppercase"
                      spacing="regular"
                    >
                      Default
                    </Badge>
                  )}
                  {experience.status === 'published' &&
                    experience.startTime &&
                    experience.startTime > new Date().getTime() && (
                      <Badge
                        color="yellow"
                        className="ml-3 uppercase"
                        spacing="regular"
                      >
                        Scheduled
                      </Badge>
                    )}
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              {activePage && (
                <WidgetsList
                  page={activePage}
                  onWidgetEnabledChange={handleWidgetEnabledChange}
                  experience={experience}
                />
              )}
            </Disclosure.Panel>
          </>
        )
      }}
    </Disclosure>
  )
}

function WidgetsList({ page, onWidgetEnabledChange, experience }) {
  const widgets = page.order
    .map((widgetId) =>
      page.widgets.find(
        (widget) =>
          widget.id === widgetId && widget.experience.id === experience.id
      )
    )
    .filter((widget) => Boolean(widget))

  return (
    <div className="flex flex-col items-stretch">
      {widgets.map((widget, index) => {
        const isLast = index === widgets.length - 1
        const supportsRecommendationConfig = canConfigureRecommendations(widget)

        return (
          <div
            className={classNames('flex flex-col items-start py-3', {
              ['opacity-50 pb-6 hover:opacity-100']: !widget.enabled,
              ['mb-3 border-b-2 border-gray-300 pb-6']: !isLast,
            })}
            key={widget.id}
          >
            <div className="flex flex-row items-baseline justify-between w-full">
              <div className="mb-2 text-xl font-semibold leading-tight text-gray-800 notranslate">
                {widget.title}
              </div>
              <Tooltip
                position="right"
                content={
                  widget.enabled
                    ? 'Disable this widget'
                    : widgetDescriptionFromType(widget.type)
                }
              >
                <Switch
                  checked={widget.enabled}
                  onChange={(value) => onWidgetEnabledChange(widget.id, value)}
                />
              </Tooltip>
            </div>
            <div className="flex flex-row items-center">
              <Badge
                color="yellow"
                className="mr-2 uppercase"
                spacing="regular"
                textSize="small"
              >
                {widgetLabelFromType(widget.type)}
              </Badge>
              <Badge
                color="teal"
                className="mr-2 uppercase"
                spacing="regular"
                textSize="small"
              >
                {pageLabelFromType(widget.pageType)}
              </Badge>
              {!widget.enabled && (
                <Badge color="neutral" className="uppercase" spacing="regular">
                  Disabled
                </Badge>
              )}
            </div>

            {widget.enabled && false && <WidgetStats stats={widget.stats} />}
            <div className="flex flex-row items-center justify-between w-full mt-3">
              <span className="mr-3 text-sm text-gray-600">
                (Widget ID :{' '}
                <strong className="font-semibold">{widget.id}</strong>)
              </span>
              <div>
                <Tooltip
                  position="left"
                  content={
                    supportsRecommendationConfig
                      ? 'Quickly configure product recommendations that appear in this widget as per your requirements.'
                      : 'Manual recommendations are not applicable to this widget'
                  }
                >
                  <Button
                    className="mr-3 underline underline-offset-4"
                    size="xSmall"
                    variant="text"
                    href={`/widgets/${widget.id}/recommendations`}
                    internalLink={true}
                    disabled={!supportsRecommendationConfig}
                  >
                    Edit Product Recommendations
                  </Button>
                </Tooltip>
                <Tooltip
                  position="right"
                  content="Configure widget title, position & visual aspects of the widget"
                >
                  <Button
                    size="xSmall"
                    variant="bordered"
                    href={`/widgets/${widget.id}`}
                    internalLink={true}
                  >
                    Configure
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

function WidgetStats({ stats }) {
  return (
    <div className="flex flex-col mt-3 mb-1">
      {stats.map(({ id, title, value, currency }) => {
        let numberFormat = currency ? '0a' : '0.0a'
        numberFormat = id === 'ctr' ? '0.00%' : numberFormat

        return (
          <div
            className="flex flex-row items-baseline leading-relaxed"
            key={id}
          >
            <span className="text-xs text-gray-600 uppercase">{title}:</span>
            <span className="ml-2 text-base font-semibold text-gray-800">
              {currency ? '$' : ''}
              {numeral(value).format(numberFormat)}
            </span>
          </div>
        )
      })}
    </div>
  )
}
