import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Badge from 'components/Badge'
import NavigationTabs from 'components/NavigationTabs'
import WaitForData from 'components/WaitForData'
import { useToast } from 'components/Toast'
import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'
import { useApi } from 'hooks/api'
import { widgetLabelFromType, pageLabelFromType } from 'utils/constants'
import * as QueryUtils from 'utils/query'

import WidgetDiscounts from 'components/Discounts'
import WidgetProductDiscounts from 'components/ProductDiscounts'

import WidgetDesign from './Design'
import WidgetAnalytics from './Analytics'
import WidgetGeneral from './General'
import WidgetRecommendations from './Recommendations'
import WidgetSettings from './Settings'
import WidgetPageRules from './PageRules'
import WidgetAdmin from './Admin'
import WidgetAdvanced from './Advanced'

export default function Widget({ account, store }) {
  const { name: shop } = account.data
  const history = useHistory()
  const {
    params: { widgetId },
  } = useRouteMatch('/widgets/:widgetId')
  const openToast = useToast()
  const widget = useApi(`widgets/${widgetId}/`, { shop })
  const templates = useApi('templates/', { shop })

  async function saveWidget(
    newData,
    toastMessage = 'Widget saved successfully!'
  ) {
    try {
      await widget.update(newData, { method: 'put' })
      openToast({
        type: 'success',
        text: toastMessage,
      })
      return true
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in saving the widget.',
      })
      return false
    }
  }

  async function deleteWidget(widgetId) {
    try {
      if (!window.confirm('Are you sure you want to delete this widget?')) {
        return
      }

      await widget.destroy()
      QueryUtils.invalidate('pages')
      openToast({
        type: 'success',
        text: 'Widget deleted successfully!',
      })
      history.replace({
        pathname: '/',
        search: window.location.search,
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in deleting the widget. Please contact support.',
      })
    }
  }

  return (
    <WaitForData
      objects={[widget, templates]}
      loadingMessage="Fetching widget data"
      errorMessage="Trouble fetching widget data"
    >
      {() => (
        <div className="flex flex-col items-stretch flex-1 mb-56">
          <div className="flex flex-row flex-wrap items-center justify-between mb-6">
            <div className="flex flex-row flex-wrap items-center ">
              <h3 className="mr-3 text-2xl font-semibold text-gray-800 lg:text-3xl notranslate">
                {widget.data.title}
              </h3>
              <Badge
                color="yellow"
                className="mr-1 uppercase"
                spacing="regular"
              >
                {widgetLabelFromType(widget.data.type)}
              </Badge>
              <Badge color="teal" className="uppercase" spacing="regular">
                {pageLabelFromType(widget.data.pageType)}
              </Badge>
              <span className="ml-2 text-sm text-gray-600">
                (Widget ID :{' '}
                <strong className="font-semibold">{widget.data.id}</strong>)
              </span>
            </div>
            {widget.data.experience?.name && (
              <span className="text-sm font-semibold tracking-tight">
                Experience : {widget.data.experience.name}
              </span>
            )}
          </div>
          <NavigationTabs
            tabs={TABS}
            visibilityCheckParams={[widget.data, account.data]}
            tabProps={{
              account,
              store,
              templates,
              widget: widget.data,
              onSave: saveWidget,
              onDelete: deleteWidget,
            }}
          />
        </div>
      )}
    </WaitForData>
  )
}

const TABS = [
  {
    label: 'General',
    value: 'general',
    root: true,
    component: WidgetGeneral,
  },
  {
    label: 'Design & Location',
    value: 'layout',
    tooltip: 'You can update the location & design of the widget via this tab.',
    component: WidgetDesign,
    isVisible: (widgetData, accountData) =>
      true || !accountData.templatesEnabled,
  },
  {
    label: 'Recommendations',
    value: 'recommendations',
    tooltip:
      'You can configure which products to show in the widget from here.',
    component: WidgetRecommendations,
    isVisible: (widgetData, accountData) => true,
  },
  {
    label: 'Analytics',
    value: 'analytics',
    tooltip: 'You can view widget performance and statistics via this tab.',
    component: WidgetAnalytics,
  },
  {
    label: 'Discounts',
    value: 'discounts',
    tooltip: 'Add discounts to this widget.',
    component: WidgetDiscounts,
  },
  {
    label: 'Settings',
    value: 'settings',
    tooltip: 'Control widget visibility & advanced configuration.',
    component: WidgetSettings,
  },
  {
    label: 'Page Rules',
    value: 'page-rules',
    tooltip: 'Control on which pages the widget is visible.',
    component: WidgetPageRules,
    isVisible: (widgetData) => {
      const otherPage = widgetData.pageType === Pages.OTHER.value
      const blogPage = widgetData.pageType === Pages.BLOG.value
      const promotedProductsWidget =
        widgetData.type === Widgets.PROMOTED_PRODUCTS.value

      return otherPage || (blogPage && !promotedProductsWidget)
    },
  },
  {
    label: 'Advanced',
    value: 'advanced',
    tooltip: 'Widget advanced settings',
    component: WidgetAdvanced,
    isVisible: (widgetData, accountData) => true,
  },
]
