import React from 'react'

import api from 'api'
import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { useToast } from 'components/Toast'
import { useQueryParams } from 'hooks/router'
import { useApi } from 'hooks/api'
import {
  fullURLFromPathname,
  pathnameFromURL,
  domainNameFromURL,
} from 'utils/browser'
import { _pick, _get } from 'utils/lodash'
import ObjectAdd from './ObjectAdd'
import ObjectEdit from './ObjectEdit'

export default function ObjectList({
  widgetType,
  widgetId,
  objectType,
  account,
  store,
}) {
  const [selectedObject, setSelectedObject] = React.useState(null)
  const [showAddPageForm, setShowAddPageForm] = React.useState(false)
  const { shop } = useQueryParams()
  const openToast = useToast()
  const objectSearch = useApi(`autoreco/`, {
    shop,
    page: 1,
    count: 50,
    recommendation_type: widgetType,
    object_type: objectType,
    widget_id: widgetId,
  })

  async function objectCreate(payload) {
    try {
      const { data } = await api.post(
        `autoreco/`,
        [
          {
            ...payload,
            recommendation_type: widgetType,
            object_type: objectType,
            widget: widgetId,
          },
        ],
        {
          params: {
            shop,
          },
        }
      )

      objectSearch.update(
        {
          ...objectSearch.data,
          recommendations: [
            ...data.recommendations,
            ...objectSearch.data.recommendations,
          ],
        },
        { local: true }
      )

      setShowAddPageForm(false)
      openToast({
        type: 'success',
        text: 'Page saved with recommendations successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error saving the page',
      })
    }
  }

  async function objectUpdate(updatedRecommendations) {
    try {
      await api.post(
        `autoreco/`,
        [
          {
            ..._pick(selectedObject, ['id', 'shop', 'widget']),
            recommendation_type: selectedObject.recommendationType,
            object_id: selectedObject.objectId,
            object_type: selectedObject.objectType,
            count: updatedRecommendations.length,
            widget: widgetId,
            data: {
              recommendations: updatedRecommendations.map((r) => ({
                value: r.value.productId,
              })),
            },
          },
        ],
        {
          params: {
            shop,
          },
        }
      )

      objectSearch.update(
        {
          ...objectSearch.data,
          recommendations: objectSearch.data.recommendations.map(
            (recommendation) =>
              recommendation.id === selectedObject.id
                ? {
                    ...recommendation,
                    count: updatedRecommendations.length,
                    data: {
                      recommendations: updatedRecommendations,
                    },
                  }
                : recommendation
          ),
        },
        { local: true }
      )

      setShowAddPageForm(false)
      openToast({
        type: 'success',
        text: 'Page recommendations updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in updating recommendations',
      })
    }
  }

  let content
  if (objectSearch.loading || !objectSearch.data) {
    content = <Loader />
  } else if (showAddPageForm) {
    content = (
      <ObjectAdd
        type={objectType}
        onSave={objectCreate}
        account={account.data}
        store={store.data}
        onClose={() => setShowAddPageForm(false)}
      />
    )
  } else if (selectedObject) {
    content = (
      <ObjectEdit
        object={selectedObject}
        onSave={objectUpdate}
        account={account.data}
        store={store.data}
        onBack={() => setSelectedObject(null)}
      />
    )
  } else {
    content = (
      <React.Fragment>
        <div className="flex flex-row items-center mb-3">
          <div className="flex flex-col flex-1">
            <span className="text-xl font-semibold">Choose page</span>
            <span className="text-xs text-gray-600">
              You can setup recommendations for a specific page by clicking
              configure on it. Use "Add new page" button if page doesn't exist.
            </span>
          </div>
          <Button
            className="flex-shrink-0 ml-6"
            size="small"
            onClick={() => setShowAddPageForm(true)}
          >
            + Add New Page
          </Button>
        </div>
        <div className="flex flex-row flex-wrap items-stretch -mx-2">
          {objectSearch.data.recommendations.map((recommendation) => (
            <RecommendationItem
              recommendation={recommendation}
              onSelect={() => setSelectedObject(recommendation)}
              objectSearch={objectSearch}
              openToast={openToast}
              widgetId={widgetId}
              shop={shop}
              key={recommendation.id}
              shopDomain={account.data.shop.domain}
              store={store.data}
            />
          ))}
        </div>
      </React.Fragment>
    )
  }

  return <div className="flex flex-col items-stretch">{content}</div>
}

ObjectList.defaultProps = {
  objectType: 'page',
}

function RecommendationItem({
  recommendation,
  onSelect,
  objectSearch,
  openToast,
  widgetId,
  shop,
  store,
  shopDomain,
}) {
  const { objectType } = recommendation
  const [removing, setRemoving] = React.useState(false)

  async function onRemove(recommendationId) {
    setRemoving(true)
    try {
      await api.delete(`autoreco/${recommendationId}/`, {
        data: null,
        params: {
          shop,
        },
      })

      objectSearch.update(
        {
          ...objectSearch.data,
          recommendations: objectSearch.data.recommendations.filter(
            (r) => r.id !== recommendation.id
          ),
        },
        { local: true }
      )

      openToast({
        type: 'success',
        text: 'Page removed successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in removing the page',
      })
    }
    setRemoving(false)
  }

  if (objectType === 'page') {
    const customDomainAllowed = _get(
      store,
      'extra.allowCustomDomainInPageRules'
    )
    const objectIdDomain = customDomainAllowed
      ? domainNameFromURL(recommendation.objectId)
      : null
    const objectIdPathname = customDomainAllowed
      ? pathnameFromURL(recommendation.objectId)
      : recommendation.objectId
    const pageURL = fullURLFromPathname(
      objectIdPathname || recommendation.objectId,
      objectIdDomain || shopDomain
    )

    return (
      <div className="w-1/2 px-2 mb-4">
        <Box className="flex flex-col items-stretch">
          <div className="flex flex-row items-baseline mb-1 text-sm">
            <span className="mr-1 text-gray-600">Page URL: </span>
            <a
              href={pageURL}
              className="font-semibold text-gray-600 underline hover:text-primary-700"
              target="_blank"
            >
              {pageURL}
            </a>
          </div>
          <div className="flex flex-row items-baseline mb-3">
            <span className="mr-1 text-sm text-gray-600">
              Recommendations Count:{' '}
            </span>
            <span className="font-semibold text-gray-800">
              {recommendation.count}
            </span>
          </div>

          <div className="flex flex-row items-center">
            <Button size="small" variant="bordered" onClick={onSelect}>
              View/Edit Recommendations
            </Button>
            <Button
              size="small"
              variant="bordered"
              className="ml-3 opacity-25 hover:opacity-100"
              color="red"
              loading={removing}
              onClick={() => onRemove(recommendation.id)}
            >
              Remove
            </Button>
          </div>
        </Box>
      </div>
    )
  }

  return null
}
