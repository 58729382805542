import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import getSymbolFromCurrency from 'currency-symbol-map'

import Form from 'components/Form'
import WithProducts from 'components/WithProducts'
import ProductsSlider from 'components/ProductsSlider'
import SlideOver from 'components/SlideOver'
import { useToast } from 'components/Toast'
import Button from 'components/Button'
import {
  getStoreCurrency,
  getAccountData,
  getCurrencyFormat,
} from 'utils/account'
import {
  calculateDiscountedValue,
  calculateDiscountPrice,
  getDiscountTypeLabelFromType,
  getDiscountValue,
} from 'containers/Bundles/utils'
import {
  DEFAULT_DISCOUNT,
  DEFAULT_DISCOUNT_TRANSLATIONS,
  DISCOUNT_LEVEL_OPTIONS,
} from 'containers/Bundles/constants'
import { DISCOUNT_TYPES } from 'constants/discount'

import ProductList from './ProductList'
import './bundle.css'
import FieldValue from 'components/ResourceList/FieldValue'

export default function Bundle({ builder }) {
  const {
    bundle,
    saveBundle,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
    store,
  } = builder
  const [discountLevel, setDiscountLevel] = useState(
    bundle.discountLevel
      ? bundle.discountLevel
      : DISCOUNT_LEVEL_OPTIONS.BUNDLE_DISCOUNT.value
  )
  const [productConfigs, setProductConfigs] = useState(
    bundle.items.products ? bundle.items.products : []
  )
  const [discountState, setDiscountState] = useState(
    Object.keys(bundle.discounts).length > 0
      ? bundle.discounts
      : { ...DEFAULT_DISCOUNT, ...DEFAULT_DISCOUNT_TRANSLATIONS }
  )

  const [discountTranslation, setDiscountTranslation] = useState(
    getDefaultDiscountTranslation(bundle.discounts)
  )
  const [sliderVisible, setSliderVisible] = useState(false)
  const [validateFields, setValidateFields] = useState({})
  const productSyncInProgress =
    !store.data.productsSyncedAt || !store.data.ordersSyncedAt

  useEffect(() => {
    async function bundleSave() {
      let validated = Object.keys(validateFields).reduce((accm, currVal) => {
        const validationFn = validateFields[currVal]
        if (validationFn && typeof validationFn === 'function') {
          return accm && validationFn()
        }
        return accm
      }, true)
      if (!validated) {
        return
      }

      return await saveBundle({
        items: { products: productConfigs },
        type: discountLevel,
        discounts: { ...discountState, ...discountTranslation, enabled: true },
      })
    }
    setSaveHandler(() => bundleSave)
    setSaveNextHandler(() => async () => {
      const savedBundle = await bundleSave()
      if (savedBundle) {
        setActiveScreen('review')
      }
    })
  }, [
    productConfigs,
    discountLevel,
    discountState,
    discountTranslation,
    validateFields,
  ])

  return (
    <div className="flex flex-col items-stretch w-full">
      <div className="flex flex-col w-full px-16 py-10">
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">
            Add products to the bundle
          </span>
          <span className="mt-2 text-base text-gray-500">
            Complete the following steps to get started
          </span>
        </div>
        <div className="flex flex-row items-center my-6">
          <Form.Field
            control="radio"
            name="discountLevel"
            onChange={(value) => {
              setDiscountLevel(value)
            }}
            options={Object.values(DISCOUNT_LEVEL_OPTIONS)}
            controlClassName="flex flex-row items-center"
            optionsClassName="first:ml-0 ml-12"
            value={discountLevel}
          />
        </div>
        <div className="">
          {productConfigs.length > 0 ? (
            <WithProducts
              productIds={productConfigs.reduce((accm, currVal) => {
                accm.push(currVal.productId)
                return accm
              }, [])}
            >
              {({ products: productsWithData }) => {
                return (
                  <div className="w-full">
                    {discountLevel ==
                      DISCOUNT_LEVEL_OPTIONS.BUNDLE_DISCOUNT.value && (
                      <DiscountLine
                        products={productsWithData}
                        discountState={discountState}
                        setDiscountState={setDiscountState}
                        bundle={bundle}
                        setValidation={(validations) => {
                          setValidateFields({
                            ...validateFields,
                            ...validations,
                          })
                        }}
                      />
                    )}
                    <ProductList
                      discountLevel={discountLevel}
                      productsWithData={productsWithData}
                      productConfigs={productConfigs}
                      setProductConfigs={setProductConfigs}
                      setSliderVisible={setSliderVisible}
                    />
                  </div>
                )
              }}
            </WithProducts>
          ) : (
            <div className={classNames('px-8 py-5 border border-gray-300')}>
              <Button
                variant="light"
                size="small"
                tooltipContent={
                  productSyncInProgress
                    ? 'Product sync is in progress. Cannot add products'
                    : null
                }
                disabled={productSyncInProgress}
                onClick={() => setSliderVisible(true)}
              >
                + Add Products
              </Button>
            </div>
          )}
          <SlideOver
            relative={true}
            open={sliderVisible}
            name="ProductSelection_ConditionListInput"
            onClose={() => setSliderVisible(false)}
          >
            <ProductsSlider
              onClose={() => setSliderVisible(false)}
              initialSelectedProducts={productConfigs}
              onSelect={(selectedProducts = []) => {
                setProductConfigs(
                  selectedProducts.map((selectedProduct) => ({
                    id: uuidv4(),
                    productId: selectedProduct.productId,
                    discount: DEFAULT_DISCOUNT,
                    variantIds:
                      selectedProduct.variantsCount ==
                      selectedProduct.variantIds.length
                        ? []
                        : selectedProduct.variantIds,
                    variantsCount: selectedProduct.variantsCount,
                  }))
                )
              }}
              allowMultipleProducts={true}
              productsLimit={4}
              allowMultipleVariants={true}
              selectAllVariants={true}
            />
          </SlideOver>
        </div>
      </div>
      <div className="flex flex-col w-full px-16 py-10 border-t border-gray-400">
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">Advanced</span>
        </div>
        <div className="pt-6">
          <DiscountTranslations
            discountTranslation={discountTranslation}
            setDiscountTranslation={setDiscountTranslation}
          />
        </div>
      </div>
    </div>
  )
}

export function DiscountLine({
  products,
  bundle,
  discountState,
  setDiscountState,
  readOnly = false,
  setValidation,
}) {
  const totalPrice = products
    ?.reduce((accm, product) => {
      return accm + parseFloat(product.price)
    }, 0)
    .toFixed(2)

  const accountData = getAccountData()
  const storeCurrency = getStoreCurrency(accountData)
  const currencySymbol = getSymbolFromCurrency(storeCurrency)
  const currencyFormat = getCurrencyFormat({ data: accountData })

  const { type: discountType, value: discountValue } = discountState
  const discountedPrice = calculateDiscountPrice(
    totalPrice,
    discountType,
    discountValue
  )
  const discountedValue = calculateDiscountedValue(
    discountType,
    totalPrice,
    discountedPrice
  )
  const [formErrors, setFormErrors] = useState({})
  const openToast = useToast()
  useEffect(() => {
    if (setValidation && typeof setValidation === 'function') {
      setValidation({
        discountLine: () => {
          if (discountedValue < 0) {
            setFormErrors({
              ...formErrors,
              discountValue: {},
            })
            openToast({
              type: 'error',
              text: 'Discount cannot be less than 0. Please correct errors and submit again',
            })
            return false
          } else if (parseFloat(discountedValue) > parseFloat(totalPrice)) {
            setFormErrors({
              ...formErrors,
              discountValue: {},
            })
            openToast({
              type: 'error',
              text: 'Discount cannot be more than total price. Please correct errors and submit again',
            })
            return false
          } else {
            setFormErrors({
              ...formErrors,
              discountValue: undefined,
            })
            return true
          }
        },
      })
    }
  }, [discountState])

  if (!products || products.length === 0) {
    return null
  }
  return (
    <div className="flex flex-row items-center mb-4">
      {!readOnly ? (
        <Form.Field
          control="select"
          label="Discount Type"
          options={Object.values(DISCOUNT_TYPES)}
          name={`discountType`}
          onChange={(e) => {
            if (!readOnly) {
              setDiscountState({ ...discountState, type: e.target.value })
            }
          }}
          value={discountType}
          readOnly={readOnly}
          className="w-36"
        />
      ) : (
        <div className="flex flex-col items-stretch mb-3 w-36">
          <span className="mb-1 text-xs font-semibold">Discount Type</span>
          <div className="block w-full py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline">
            <span className="">
              {getDiscountTypeLabelFromType(discountType)}
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-col items-start ml-4">
        {!readOnly ? (
          <Form.Field
            label="Discount"
            control="input"
            name={`discountValue`}
            onChange={(e) => {
              if (!readOnly) {
                setDiscountState({ ...discountState, value: e.target.value })
              }
            }}
            valType={
              discountType === DISCOUNT_TYPES.FLAT.value
                ? 'currency'
                : 'percentage'
            }
            type="number"
            step={0.01}
            min={0}
            max={totalPrice}
            currencySymbol={currencySymbol}
            value={discountValue}
            readOnly={readOnly}
            formError={formErrors['discountValue']}
            className="w-32 ml-4"
          />
        ) : (
          <div className="flex flex-col items-stretch w-32 mb-3 ml-4">
            <span className="mb-1 text-xs font-semibold">Discount</span>
            <div className="block w-full py-2 pr-8 leading-tight focus:outline-none focus:shadow-outline">
              <span className="">
                {getDiscountValue(discountType, discountValue, currencyFormat)}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-stretch mb-3 ml-8">
        <span className="mb-1 text-xs font-semibold">
          Actual and Discounted Price
        </span>
        <div className="flex flex-row items-center">
          <div className="flex flex-row w-full px-4 py-2 pr-8 leading-tight bg-gray-200 border border-gray-400 rounded shadow items hover:border-gray-500 focus:outline-none focus:shadow-outline">
            <span className="mr-3 text-red-500 line-through">
              {`${currencySymbol}${totalPrice}`}
            </span>
            <span className=""> {`${currencySymbol}${discountedPrice}`}</span>
          </div>
          <span className="flex flex-row w-full ml-3 text-sm font-medium text-red-500">
            {discountType === DISCOUNT_TYPES.FLAT.value
              ? `(${discountedValue}% OFF)`
              : `(${currencySymbol}${discountedValue} OFF)`}
          </span>
        </div>
      </div>
    </div>
  )
}

function DiscountTranslations({ discountTranslation, setDiscountTranslation }) {
  const { widgetMessage, cartMessage } = discountTranslation
  return (
    <div className="flex flex-col">
      <Form.Field
        label="Discount Message Shown in Widget"
        name="widgetMessage"
        control="input"
        spacing="loose"
        helpText="This message is shown below the widget heading. You can use any language."
        value={widgetMessage}
        onChange={(e) =>
          setDiscountTranslation({
            ...discountTranslation,
            widgetMessage: e.target.value,
          })
        }
      />
      <Form.Field
        label="Message shown on Cart Page for applied discount"
        name="cartMessage"
        control="input"
        spacing="loose"
        helpText="Due to a limitation in shopify, applied discount is visible only on the Checkout page. This message assures the buyer that their discount has been applied and will be visible on the Checkout page. You can use any language."
        value={cartMessage}
        onChange={(e) =>
          setDiscountTranslation({
            ...discountTranslation,
            cartMessage: e.target.value,
          })
        }
      />
    </div>
  )
}

function getDefaultDiscountTranslation(discount) {
  return {
    widgetMessage:
      discount.widgetMessage || DEFAULT_DISCOUNT_TRANSLATIONS.widgetMessage,
    cartMessage:
      discount.cartMessage || DEFAULT_DISCOUNT_TRANSLATIONS.cartMessage,
  }
}
