import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { validCssColor } from 'utils/browser'
import TranslationUpsell from '../TranslationUpsell'

const DISCOUNT_LABEL_MODE = [
  { label: 'Hidden', value: 'false' },
  { label: 'Show', value: 'true' },
]
const DISCOUNT_LABEL_POSITIONS = [
  { label: 'Top Left', value: 'left' },
  { label: 'Top Right', value: 'right' },
]

export default function PricesForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Sale Label Display Mode"
          name="visible"
          control="select"
          options={DISCOUNT_LABEL_MODE}
          ref={register({
            required: 'Required!',
          })}
        />
        <Form.Field
          label="Sale Label Background Color"
          name="background"
          control="color"
          setColor={setValue}
          color={formValues.background}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.background}
        />
        <Form.Field
          label="Sale Label Text Color"
          name="textColor"
          control="color"
          setColor={setValue}
          color={formValues.textColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.textColor}
        />
      </Form.FieldRow>
      <Form.FieldRow className="pt-3 mt-3 border-t border-gray-200">
        <Form.Field
          label="Sale Label Text Format"
          name="format"
          helpText="Use '{percent}' anywhere to add percentage off amount"
          control="input"
          ref={register({
            required: 'Required!',
          })}
          supportElement={
            <TranslationUpsell
              templateId={props.widget.template}
              store={props.store}
            />
          }
        />
        <Form.Field
          label="Sale Label Position"
          name="position"
          control="select"
          options={DISCOUNT_LABEL_POSITIONS}
          ref={register({
            required: 'Required!',
          })}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, translations }) {
  return {
    visible: settings.productCard.discountLabelVisible ? 'true' : 'false',
    format: translations.primaryLocale.discountLabelText,
    position: settings.productCard.discountLabelPosition,
    background: settings.productCard.discountLabelBackground,
    textColor: settings.productCard.discountLabelColor,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      productCard: {
        discountLabelVisible: values.visible === 'true' ? true : false,
        discountLabelPosition: values.position,
        discountLabelBackground: values.background,
        discountLabelColor: values.textColor,
      },
    },
    translations: {
      primaryLocale: {
        discountLabelText: values.format,
      },
    },
  }
}
