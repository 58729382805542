import React, { useState } from 'react'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import { translatableLocales } from 'utils/account'
import { labelFromLocale } from 'utils/languages'
import { _get } from 'utils/lodash'
import Icons from 'components/Icons'

export default function TitleTranslationsModal({
  widget,
  account,
  onSave,
  onUpgrade,
}) {
  const { primaryLocale = 'en' } = account.data.shop
  const [translations, setTranslations] = useState({
    primaryLocale: {
      title: widget.title,
      ...(widget.translations.primaryLocale || {}),
    },
    ...widget.translations,
  })
  const allowedLocales = translatableLocales(account)
  const existingLocalesInTranslations = Object.keys(translations).map((k) =>
    k === 'primaryLocale' ? primaryLocale : k
  )
  const possibleNewLocales = allowedLocales.filter(
    (l) => existingLocalesInTranslations.indexOf(l.value) === -1
  )

  const [newTranslation, setNewTranslation] = useState(
    possibleNewLocales.length > 0
      ? {
          [possibleNewLocales[0].value]: { title: '' },
        }
      : null
  )
  const [addingTranslations, setAddingTranslations] = useState(false)
  const [deletingTranslationLocale, setDeletingTranslationLocale] =
    useState(null)

  async function saveTranslations(translationsUpdate) {
    const success = await onSave(
      { translations: translationsUpdate },
      'Widget title translations updated successfully!'
    )

    if (success) {
      setTranslations(translationsUpdate)
      setNewTranslation(
        possibleNewLocales.length > 0
          ? {
              [possibleNewLocales[0].value]: { title: '' },
            }
          : null
      )
    }
  }

  async function updateTranslations({ ignoreNewTranslation = false }) {
    setAddingTranslations(true)
    await saveTranslations({
      ...translations,
      ...(ignoreNewTranslation ? {} : newTranslation),
    })
    setAddingTranslations(false)
  }

  async function deleteTranslationLocale(locale) {
    setDeletingTranslationLocale(locale)
    await saveTranslations(
      Object.keys(translations).reduce((accum, key) => {
        if (key === locale) {
          return accum
        } else {
          return { ...accum, [key]: translations[key] }
        }
      }, {})
    )
    setDeletingTranslationLocale(null)
  }

  return (
    <div className="flex flex-col items-center">
      <Box className="self-center w-2/3">
        <div className="flex flex-col items-stretch">
          <span className="pb-2 mb-6 text-sm font-semibold text-gray-600 uppercase border-b border-gray-400">
            Multi Language Widget Title
          </span>
          <ul className="flex flex-col items-stretch">
            {Object.keys(translations).map((key) => {
              const locale = key === 'primaryLocale' ? primaryLocale : key
              const label = labelFromLocale(locale)
              const value = translations[key].title

              return (
                <div
                  key={key}
                  className="flex flex-row items-center pb-2 mb-2 text-sm border-b border-gray-200"
                >
                  <div className="flex flex-col w-1/3 mr-12">
                    <span className="font-medium text-gray-600 text-xxs">
                      Language:
                    </span>
                    <span>
                      {label}({locale})
                    </span>
                  </div>
                  <div className="flex flex-col flex-1 mr-3">
                    <span className="font-medium text-gray-600 text-xxs">
                      Value:
                    </span>
                    <span className="font-semibold">{value}</span>
                  </div>
                  {key !== 'primaryLocale' && (
                    <Button
                      size="xSmall"
                      color="red"
                      variant="bordered"
                      disabled={addingTranslations || deletingTranslationLocale}
                      loading={deletingTranslationLocale === locale}
                      onClick={() => {
                        deleteTranslationLocale(locale)
                      }}
                    >
                      Delete
                    </Button>
                  )}
                </div>
              )
            })}
          </ul>
          {newTranslation && Object.keys(newTranslation).length > 0 && (
            <div className="flex flex-row items-center text-sm">
              <div className="flex flex-col w-1/3 mr-12">
                <span className="font-medium text-gray-600 text-xxs">
                  Choose Language:
                </span>
                <div className="relative">
                  <select
                    className={classNames(
                      'block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-sm'
                    )}
                    onChange={(event) => {
                      setNewTranslation({
                        [event.target.value]: { title: null },
                      })
                    }}
                  >
                    {possibleNewLocales.map(({ label, value }) => (
                      <option value={value} key={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                    <svg
                      className="w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-stretch flex-1 mr-6">
                <span className="font-medium text-gray-600 text-xxs">
                  Title:
                </span>
                <input
                  placeholder={`Enter title in ${labelFromLocale(
                    Object.keys(newTranslation)[0]
                  )}`}
                  className={classNames(
                    'block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-sm'
                  )}
                  value={
                    Object.keys(newTranslation).length > 0
                      ? newTranslation[Object.keys(newTranslation)[0]].title
                      : null
                  }
                  onChange={(event) => {
                    const newLocaleKey = newTranslation
                      ? Object.keys(newTranslation)[0]
                      : null

                    if (newLocaleKey) {
                      setNewTranslation({
                        [newLocaleKey]: { title: event.target.value },
                      })
                    }
                  }}
                />
              </div>
              <Button
                size="regular"
                className="self-end"
                onClick={onUpgrade ? onUpgrade : updateTranslations}
                disabled={
                  !_get(
                    newTranslation[Object.keys(newTranslation)[0]],
                    'title'
                  ) || deletingTranslationLocale
                }
                loading={addingTranslations}
              >
                <div className="flex items-center justify-center">
                  <span className="mr-1">+ Add</span>
                  {onUpgrade && (
                    <Icons.Lock
                      className={'w-3 mb-1 fill-current opacity-75'}
                    />
                  )}
                </div>
              </Button>
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}
