import { useReducer, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { _find } from 'utils/lodash'
import { updateURLSearch } from 'utils/router'

export default function useOrderBy({
  queryParams,
  resourceType,
  resourceSchema,
  onChange,
  initialView,
}) {
  const orderableFields = getOrderableFields({ resourceSchema })
  const history = useHistory()

  const initialQueryObject = initialView
    ? initialView.order
    : objectFromQueryParam(queryParams.o)

  const [orderState, dispatch] = useReducer(
    reducer,
    getInitialState(orderableFields, initialQueryObject)
  )

  useEffect(() => {
    if (!orderState) {
      return
    }

    const searchUpdate =
      orderState.length > 0
        ? { o: btoa(JSON.stringify(orderState)) }
        : { o: '' }
    history.replace({ search: updateURLSearch(searchUpdate) })

    onChange()
  }, [orderState])

  return {
    fields: orderableFields,
    state: orderState,
    query: orderState,

    toggleOrderBy: (field) => dispatch({ type: 'TOGGLE_ORDER', field }),
    clearOrderBy: (field) => dispatch({ type: 'CLEAR_ORDER', field }),
  }
}

function reducer(state = DEFAULT_ORDER_BY_DATA, action) {
  switch (action.type) {
    case 'RESET':
      return DEFAULT_ORDER_BY_DATA

    case 'TOGGLE_ORDER':
      const { field } = action
      const fieldExists = _find(state, { field })

      return fieldExists
        ? state
            .map((o) => {
              if (o.field === field) {
                const currentDirection = o.value
                const newDirection =
                  currentDirection === 'desc'
                    ? 'asc'
                    : currentDirection === null
                    ? 'desc'
                    : null
                return newDirection
                  ? { field: o.field, value: newDirection }
                  : null
              } else {
                return o
              }
            })
            .filter((i) => !!i)
        : [...state, { field, value: DEFAULT_ORDER_BY_DIRECTION }]

    case 'CLEAR_ORDER':
      return state.filter((o) => o.field !== action.field)

    default:
      throw new Error('OrderBy:InvalidAction')
  }
}

function getInitialState(orderableFields, queryObject) {
  return queryObject.filter((o) => {
    return Object.keys(orderableFields).indexOf(o.field) !== -1
  })
}

function objectFromQueryParam(str) {
  try {
    return JSON.parse(atob(str))
  } catch (err) {}
  return []
}

function getOrderableFields({ resourceSchema }) {
  const { schema } = resourceSchema
  return Object.keys(schema).reduce((accum, key) => {
    const field = schema[key]
    if (!field.allowSort) {
      return accum
    }

    return { ...accum, [key]: field }
  }, {})
}

const DEFAULT_ORDER_BY_DATA = []
const DEFAULT_ORDER_BY_DIRECTION = 'desc'
