import React from 'react'

import RenderMoney from 'components/RenderMoney'

import { getDiscountedAmount } from '../../../utils'

export default function OrderUpdate({ itemsAdded, slots, accountData }) {
  if (!itemsAdded) {
    return null
  }
  const totalPrice = calculateTotal(itemsAdded, slots)

  return (
    <div className="flex flex-col w-full">
      <h1 className="pb-2 text-lg">Order Update</h1>
      <div className="flex flex-col w-full p-3 border border-gray-300 text-xxs">
        <div className="flex flex-row justify-between w-full pb-2">
          <span>Subtotal</span>
          <RenderMoney price={totalPrice} />
        </div>
        <div className="flex flex-row justify-between w-full pb-2">
          <span>Shipping</span>
          <span>Rs 0.00</span>
        </div>
        <div className="flex flex-row justify-between w-full pb-2">
          <span>Taxes</span>
          <span>Rs 0.00</span>
        </div>
        <div className="w-full h-0 my-2 border border-gray-200"></div>
        <div className="flex flex-row justify-between w-full pb-6">
          <span>Total</span>
          <RenderMoney price={totalPrice} />
        </div>
        <button className="relative inline-block py-5 mb-3 font-medium text-white duration-300 ease-in-out rounded-md cursor-pointer transition-color bg-denim hover:bg-denim-dark px-7">
          <span className="transition-opacity duration-300 ease-in-out ">
            Pay Now •
            <RenderMoney price={totalPrice} className="ml-1" />
          </span>
        </button>
        <button className="relative inline-block py-5 font-medium duration-300 ease-in-out border border-gray-200 rounded-md cursor-pointer transition-color text-denim hover:text-denim-dark px-7">
          <span className="transition-opacity duration-300 ease-in-out">
            Decline Upsell Offer
          </span>
        </button>
      </div>
    </div>
  )
}

function calculateTotal(itemsAdded, slots) {
  var total = 0
  itemsAdded.forEach((item, index) => {
    const { gloodDiscount: discount } = item
    const selectedVariant = item.variants[0]
    const discountedPrice =
      discount.value > 0
        ? getDiscountedAmount({ original: selectedVariant.price, discount })
        : null

    total += discountedPrice || selectedVariant.price
  })
  return total
}
