import React, { useCallback, useState } from 'react'

import { useToast } from 'components/Toast'
import * as QueryUtils from 'utils/query'
import { _find, _identity, _get } from 'utils/lodash'
import {
  createFunnel as createFunnelApi,
  updateFunnel as updateFunnelApi,
} from 'api/funnel'
import { getAccountData } from 'utils/account'

export default function useFunnelBuilder({
  store,
  initialFunnel = {},
  initialActiveScreen,
}) {
  const [funnel, setFunnel] = useState(initialFunnel)
  const [activeScreen, setActiveScreen] = useState(
    getInitialScreen({ funnel: initialFunnel, initialActiveScreen })
  )
  const [saveHandler, setSaveHandler] = useState(_identity)
  const [saveNextHandler, setSaveNextHandler] = useState(_identity)
  const [publishHandler, setPublishHandler] = useState(_identity)
  const [funnelSaveError, setFunnelSaveError] = useState(null)
  const [savingButton, setSavingButton] = useState(null)
  const openToast = useToast()
  const accountData = getAccountData()
  const { name: shop } = accountData

  const saveFunnel = useCallback(
    async (
      data,
      {
        successMessage = 'Funnel saved successfully!',
        errorMessage = 'There was an error in saving the funnel.',
      } = {}
    ) => {
      if (funnel?.id) {
        data.slug = funnel.slug
        data.id = funnel.id
      }

      setFunnelSaveError(null)
      try {
        let response
        if (!data.id) {
          response = await createFunnelApi(shop, data)
        } else {
          response = await updateFunnelApi(shop, data)
        }
        const {
          data: { funnel: savedFunnel },
        } = response

        QueryUtils.invalidate(['funnels'])
        QueryUtils.invalidate(['funnel'])
        openToast({
          type: 'success',
          text: successMessage,
        })
        setFunnel(savedFunnel)
        return savedFunnel
      } catch (err) {
        openToast({
          type: 'error',
          text: errorMessage,
        })
        setFunnelSaveError(err.response.data)
      }
    },
    [funnel?.id, funnel?.slug]
  )

  return {
    screens: SCREENS,
    accountData,
    store,
    activeScreen,
    funnel,
    setFunnel,
    saveFunnel,
    saveHandler,
    setSaveHandler,
    saveNextHandler,
    setSaveNextHandler,
    publishHandler,
    setPublishHandler,
    savingButton,
    setSavingButton,
    heading: 'Create Funnel',
    setActiveScreen: useCallback(
      (name) => {
        const screen = _find(SCREENS, { name })
        if (screen) {
          setActiveScreen(screen)
        }
      },
      [setActiveScreen]
    ),

    funnelSaveError,
  }
}

function getInitialScreen({ funnel, initialActiveScreen }) {
  if (initialActiveScreen) {
    return _find(SCREENS, { name: initialActiveScreen })
  }
  return _find(SCREENS, (s) => !s.complete(funnel)) || SCREENS[0]
}

export const SCREENS = [
  {
    name: 'details',
    label: 'Details',
    complete: (funnel) => funnel?.id && funnel?.name,
    disabled: () => false,
  },
  {
    name: 'trigger',
    label: 'Trigger',
    complete: (funnel) => funnel?.id && funnel?.name,
    disabled: (funnel) => !funnel?.id,
  },
  {
    name: 'workflow',
    label: 'Funnel',
    complete: (funnel) =>
      funnel?.id && (funnel?.workflow?.nodes || []).length > 0,
    disabled: (funnel) => !funnel?.id,
  },
  {
    name: 'review',
    label: 'Review',
    complete: (funnel) => funnel?.status && funnel.status === 'active',
    disabled: (funnel) => !funnel?.id,
  },
]
