import React, { useRef } from 'react'
import humps from 'humps'
import classNames from 'classnames'
import Button from 'components/Button'
import Box from 'components/Box'
import ActionCompletedIcon from 'components/Icons/ActionCompleted'
import { useApi } from 'hooks/api'
import { isStorefrontPasswordEnabled, proxyHost } from 'utils/account'
import { useQueryParams } from 'hooks/router'
import { useToast } from 'components/Toast'
import { trackEvent } from 'utils/analytics'
import Loader from 'components/Loader'
import SettingsForm from './SettingsForm'
import './styles.css'

export default function WidgetEditor({
  account,
  widget,
  store,
  onStart,
  onClose,
  onSave,
  variant,
  buttonSize,
  actionCompleted,
  disabled,
}) {
  const {
    shop: { domain },
    links,
    name: shop,
  } = account
  const [visible, setVisible] = React.useState(false)
  const [previewFrameKey, setPreviewFrameKey] = React.useState(0)
  const [frameUrl, setFrameUrl] = React.useState(null)
  const widgetTemplate = useApi(`templates/${widget.template}/`, { shop })
  const openToast = useToast()
  const passwordEnabled = isStorefrontPasswordEnabled(account)

  function handleOpen() {
    setVisible(true)
    typeof onStart === 'function' && onStart()
  }

  function handleClose() {
    setVisible(false)
    setPreviewFrameKey(previewFrameKey + 1)
    typeof onClose === 'function' && onClose()
  }

  async function handleSave(payload) {
    try {
      await widgetTemplate.update(payload, { method: 'patch' })
      onSave({
        layout: payload.layout,
        title: payload.title,
        maxContent: payload.maxContent,
      })
      setPreviewFrameKey(previewFrameKey + 1)
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in updating widget styles.',
      })
    }
  }

  React.useEffect(() => {
    const { pageType, id: widgetId } = widget
    try {
      const pageTypeUrl = new URL(
        links[humps.camelize(pageType)] || links[humps.camelize('home')]
      )
      setFrameUrl(
        `https://${domain.split('.').join('---')}.${proxyHost(account)}${
          pageTypeUrl.pathname
        }?rkWidgetId=${widgetId}&rk_preview=true&rk_force_random=true&rk_editor_mode=preview`
      )
    } catch (err) {
      setFrameUrl(null)
    }
  }, [links, shop, widget.id])

  const frame = (
    <EditorFrame
      widgetTemplate={widgetTemplate}
      store={store}
      widget={widget}
      isVisible={visible}
      frameUrl={frameUrl}
      onHide={handleClose}
      onSave={handleSave}
      key={previewFrameKey}
      passwordEnabled={passwordEnabled}
    />
  )

  let content
  if (variant === 'button') {
    content = (
      <div>
        <Button size={buttonSize} onClick={handleOpen} disabled={disabled}>
          <div className="flex items-center">
            <span>Update Widget Design</span>
            {actionCompleted !== undefined && !disabled && (
              <ActionCompletedIcon actionCompleted={actionCompleted} />
            )}
          </div>
        </Button>
        {visible ? frame : null}
      </div>
    )
  } else if (variant === 'box') {
    content = (
      <Box className="mb-5">
        <div className="flex flex-col items-start">
          <span className="mb-3 text-sm font-semibold text-gray-800">
            *Try our new visual editor to update the widget's look and feel on
            your store.
          </span>
          <Button onClick={handleOpen}>Open Visual Editor</Button>
        </div>
        {visible ? frame : null}
      </Box>
    )
  }

  return frameUrl ? content : null
}

WidgetEditor.defaultProps = {
  variant: 'box',
  buttonSize: 'xSmall',
}

const EditorFrame = ({
  store,
  widget,
  widgetTemplate,
  frameUrl,
  isVisible,
  onHide,
  onSave,
  passwordEnabled,
}) => {
  const frameEl = useRef(null)
  const [frameLoading, setFrameLoading] = React.useState(true)
  const queryParams = useQueryParams()
  const [formKey, setFormKey] = React.useState(0)
  const [isMobileView, setIsMobileView] = React.useState(false)

  React.useEffect(() => {
    setFormKey(formKey + 1)
  }, [widget])

  return (
    <div
      className={classNames(
        'flex flex-col pb-6 px-6 pt-2 bg-gray-200 widgetEditorFrame',
        {
          'widgetEditorFrame--visible': isVisible,
        }
      )}
    >
      <SettingsForm
        widget={widget}
        widgetTemplate={widgetTemplate}
        store={store}
        onHide={onHide}
        onSave={onSave}
        key={formKey}
        isMobileView={isMobileView}
        setIsMobileView={setIsMobileView}
        onChange={(payload) => {
          if (!frameEl.current || !widgetTemplate.data || !store) {
            return
          }

          frameEl.current.contentWindow.postMessage(
            {
              type: 'rk:settings:update',
              payload: {
                widgetId: widget.id,
                params: queryParams,
                layout: payload.layout,
                title: payload.title,
                settings: payload.settings,
                translations: payload.translations.primaryLocale,
                maxContent: payload.maxContent,
              },
            },
            '*'
          )
        }}
      />
      <div className="relative flex justify-center mb-5 ">
        {frameLoading && !passwordEnabled && (
          <Loader
            className="absolute border-2 border-black border-dashed rounded shadow"
            size="large"
            style={{
              zIndex: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              minHeight: MIN_EDITOR_FRAME_HEIGHT,
              width: isMobileView ? `${MOBILE_VIEW_WIDTH}px` : '100%',
            }}
          />
        )}
        {passwordEnabled ? (
          <div
            className="flex items-start justify-center pt-5 text-sm font-semibold text-red-600 border-2 border-black border-dashed rounded shadow"
            style={{
              minHeight: MIN_EDITOR_FRAME_HEIGHT,
              width: isMobileView ? `${MOBILE_VIEW_WIDTH}px` : '100%',
            }}
          >
            Visual editor is not supported for password protected stores. Please
            disable password protection temporarily to update the widget
          </div>
        ) : (
          <StoreFrame
            onLoad={() => {
              setFrameLoading(false)
              frameEl.current.contentWindow.postMessage(
                {
                  type: 'rk:queryparams:init',
                  payload: {
                    rkWidgetId: widget.id,
                    rk_preview: true,
                    rk_force_random: true,
                    rk_editor_mode: 'preview',
                  },
                },
                '*'
              )
            }}
            width={isMobileView ? `${MOBILE_VIEW_WIDTH}px` : '100%'}
            frameUrl={frameUrl}
            ref={frameEl}
          />
        )}
      </div>
    </div>
  )
}

const StoreFrame = React.memo(
  React.forwardRef((props, ref) => {
    const { loading, onLoad, frameUrl, width } = props
    return (
      <iframe
        className={classNames(
          { 'border-2 border-black border-dashed rounded shadow': !loading },
          {
            'opacity-25': loading,
          }
        )}
        style={{
          minHeight: MIN_EDITOR_FRAME_HEIGHT,
          width: width,
        }}
        src={frameUrl}
        data-hj-allow-iframe=""
        ref={ref}
        onLoad={onLoad}
        sandbox="allow-same-origin allow-scripts"
      />
    )
  }),
  (prevProps, nextProps) => {
    return (
      prevProps.loading !== nextProps.loading ||
      prevProps.frameUrl !== nextProps.frameUrl
    )
  }
)

const MIN_EDITOR_FRAME_HEIGHT = 600
const MOBILE_VIEW_WIDTH = 480
