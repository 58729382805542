import React from 'react'
import ProductEdit from 'components/SetupRecommendations/ProductRecommendations/ProductEdit'
import ProductSearch from 'components/SetupRecommendations/ProductRecommendations/ProductSearch'
import { getCurrencyFormat, isRkSupport } from 'utils/account'
export default function ManualRecommendations({
  widgetType,
  onClose,
  store,
  account,
}) {
  const [selectedProduct, setSelectedProduct] = React.useState(null)
  const currencyFormat = getCurrencyFormat(account, store)
  const rkSupport = isRkSupport(account.data)
  let content
  if (selectedProduct) {
    content = (
      <ProductEdit
        product={selectedProduct}
        widgetType={widgetType}
        onClose={() => setSelectedProduct(null)}
        currencyFormat={currencyFormat}
        rkSupport={rkSupport}
      />
    )
  } else {
    content = (
      <ProductSearch
        onProductSelect={(product) => setSelectedProduct(product)}
        widgetType={widgetType}
        onClose={onClose}
        store={store}
      />
    )
  }

  return <div className="flex flex-col items-stretch">{content}</div>
}

// if (configureGlobal) {
//     content = (
//       <GlobalRecommendations
//         widgetType={widgetType}
//         onClose={() => setconfigureGlobal(false)}
//       />
//     )
//   } else
