import React from 'react'
import _get from 'lodash.get'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import Callout from 'components/Callout'
import ShopifyPlusBadge from 'components/Badge/ShopifyPlus'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import * as Pages from 'constants/pages'
import * as Widgets from 'constants/widgets'
import * as Tracker from 'utils/tracker'

import useCreateWidget from './hooks/useCreateWidget'

export default function CreateWidget({
  pages,
  billing,
  account,
  onCreateWidgets,
  experience,
  whiteListedPages,
}) {
  const [widget, selectWidget, createWidget, savingWidget] =
    useCreateWidget(pages)
  const [checkedPages, setCheckedPages] = React.useState([])
  const { name: shop, id: shopId } = account.data

  React.useEffect(() => {
    Tracker.record('add_widget:view')
  }, [])

  React.useEffect(() => {
    if (!widget) {
      return setCheckedPages([])
    }

    const pageTypesWithWidgetEnabeld = pages.data
      .filter((page) => {
        return page.widgets.reduce((hasSimilarWidgetEnabled, pageWidget) => {
          return (
            hasSimilarWidgetEnabled ||
            (pageWidget.type === widget.value &&
              pageWidget.enabled &&
              pageWidget.experience.id == experience.id)
          )
        }, false)
      })
      .map((page) => page.type)

    setCheckedPages(
      widget.allowedOnPages.filter((page) =>
        pageTypesWithWidgetEnabeld.includes(page.value)
      )
    )
  }, [widget])

  function togglePage(page, selected){
    if (selected) {
      setCheckedPages([...checkedPages, page])
    } else {
      setCheckedPages(checkedPages.filter((p) => p !== page))
    }
  }

  async function saveWidgets() {
    const data = await createWidget(
      widget,
      checkedPages.map(({ value }) => value),
      shop,
      shopId,
      experience
    )

    onCreateWidgets(data)
  }

  const openModal = useOpenModal()

  function widgetUpgradeAccount(widget) {
    let widgetToEnable = null
    Object.keys(Widgets).some((widgetKey) => {
      if (Widgets[widgetKey].value == widget) {
        widgetToEnable = Widgets[widgetKey]
        return true
      }
      return false
    })

    openModal(
      <UpgradeAccountModal account={account} widgetToEnable={widgetToEnable} />
    )
  }
  function pageUpgradeAccount(page) {
    let pageToEnable = null
    Object.keys(Pages).some((pageKey) => {
      if (Pages[pageKey].value == page) {
        pageToEnable = Pages[pageKey]
        return true
      }
      return false
    })
    openModal(
      <UpgradeAccountModal account={account} pageToEnable={pageToEnable} />
    )
  }

  let content
  if (widget) {
    content = (
      <React.Fragment>
        <PageSelect
          widget={widget}
          pages={Object.keys(Pages).map((k) => Pages[k])}
          checkedPages={checkedPages}
          enabledPages={_get(billing.data, 'shopPlan.pagesEnabled', [])}
          whiteListedPages={whiteListedPages}
          onTogglePage={togglePage}
          onUpgrade={async (page) => {
            Tracker.record('add_widget:start_trial')
            await Tracker.flush()
            pageUpgradeAccount(page)
          }}
          onBack={() => selectWidget(null)}
          shopifyPlusStore={account.data.shop.planName === 'shopify_plus'}
          experience={experience}
        />
        <div className="flex flex-row justify-center mt-6">
          <Button
            disabled={checkedPages.length === 0}
            onClick={saveWidgets}
            loading={savingWidget}
          >
            Create Widget
          </Button>
        </div>
      </React.Fragment>
    )
  } else {
    content = (
      <WidgetSelect
        onSelect={selectWidget}
        recommendationsEnabled={_get(
          billing.data,
          'shopPlan.recommendationsEnabled',
          []
        )}
        experience={experience}
        onUpgrade={(widget) => {
          widgetUpgradeAccount(widget)
        }}
      />
    )
  }

  return <Box className="flex flex-col items-stretch">{content}</Box>
}

CreateWidget.defaultProps = {
  onCreateWidgets: () => {},
}

const ORDER = [
  Widgets.SIMILAR_PRODUCTS.value,
  Widgets.BOUGHT_TOGETHER.value,
  Widgets.PERSONALIZED.value,
  Widgets.CROSS_SELL.value,
  Widgets.RECENTLY_VIEWED.value,
  Widgets.RECENTLY_LAUNCHED.value,
  Widgets.TRENDING.value,
  Widgets.BESTSELLERS.value,
  Widgets.PROMOTED_PRODUCTS.value,
  Widgets.SLOTS_WIDGET.value,
  Widgets.PURCHASES.value,
  // Widgets.DISCOUNTS.value,
  Widgets.CUSTOM_WIDGET.value,
  Widgets.COLLECTIONS_WIDGET.value,
]
function WidgetSelect({
  onSelect,
  recommendationsEnabled,
  experience,
  onUpgrade,
}) {
  const widgets = Object.keys(Widgets)
    .map((k) => Widgets[k])
    .map((widget) => ({
      ...widget,
      disabled: !recommendationsEnabled.includes(widget.value),
    }))
    .sort((widget1, widget2) => {
      const widget1Score = widget1.disabled
        ? 1000
        : ORDER.indexOf(widget1.value)
      const widget2Score = widget2.disabled
        ? 1000
        : ORDER.indexOf(widget2.value)

      return widget1Score - widget2Score
    })

  return (
    <React.Fragment>
      <h2 className="pb-2 mb-6 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
        {experience && `${experience.name} - `}Select the type of Widget you
        want to add
      </h2>
      <div className="flex flex-wrap -mx-3">
        {widgets.map((widget, index) => (
          <div className="w-full px-4 mb-6 lg:w-1/2" key={widget.value}>
            <WidgetItem
              widget={widget}
              onClick={() => {
                onSelect(widget)
                Tracker.record(`click:add_widget:${widget.value}`)
              }}
              onUpgrade={() => onUpgrade(widget.value)}
              index={index}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

function WidgetItem({ widget, onClick, index, onUpgrade }) {
  const { disabled } = widget

  return (
    <div
      onClick={disabled ? () => {} : onClick}
      className={classNames(
        'flex items-start p-4 cursor-pointer rounded h-full border lg:border-0 group hover:bg-gray-300',
        {
          'flex-row': index % 2 === 0,
          'lg:flex-row-reverse': index % 2 === 1,
        }
      )}
    >
      <div
        className={classNames('rounded-full p-2 bg-gray-200 mr-3', {
          'lg:ml-3 lg:mr-0': index % 2 === 1,
        })}
      >
        <widget.icon
          className="opacity-50 group-hover:opacity-100"
          height="40"
          width="40"
        />
      </div>
      <div className="flex flex-col items-stretch flex-1">
        <div className="mb-2 text-sm font-semibold uppercase">
          {widget.label}
        </div>
        <p className="text-xs leading-tight">{widget.description}</p>
        {disabled && (
          <Callout
            className="self-start mt-2"
            textSize="small"
            noHoverEffect={true}
          >
            Upgrade plan to add this widget.
            <Button size="xxSmall" className="ml-1" onClick={onUpgrade}>
              Start Free Trial
            </Button>
          </Callout>
        )}
      </div>
    </div>
  )
}

function PageSelect({
  widget,
  pages = [],
  checkedPages,
  enabledPages,
  whiteListedPages,
  onTogglePage,
  onBack,
  onUpgrade,
  shopifyPlusStore,
  experience,
}) {
  return (
    <React.Fragment>
      <h2 className="flex flex-row items-center justify-between pb-2 mb-6 text-xs border-b">
        <span className="font-bold leading-tight tracking-wide text-black uppercase">
          {experience && `${experience.name} - `}
          {widget.label}
        </span>
        <span
          className="underline opacity-75 cursor-pointer text-primary hover:opacity-100"
          onClick={onBack}
        >
          Change
        </span>
      </h2>
      <h2 className="pb-2 mb-6 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
        Choose pages to enable this widget on
      </h2>
      <div className="flex flex-row flex-wrap -mx-3">
        {pages
          .sort((pageOne, pageTwo) => {
            function getScore(page) {
              const isAllowedOnPage = widget.allowedOnPages.includes(page)
              const isEnabled = enabledPages.some((enabledPage) => {
                return enabledPage == page.value
              })
              if (!isAllowedOnPage) {
                return 1000
              }
              if (widget.recommendedPages.includes(page)) {
                if (isEnabled) {
                  return 1
                }
                return 50
              } else if (isEnabled) {
                return 100
              }

              return 500
            }

            const pageOneScore = getScore(pageOne)
            const pageTwoScore = getScore(pageTwo)

            return pageOneScore - pageTwoScore
          })
          .map((page) => (
            <div className="w-full px-2 mb-2 lg:w-1/2" key={page.value}>
              <PageItem
                page={page}
                isAllowedOn={widget.allowedOnPages.includes(page)}
                checkedPages={checkedPages}
                enabledPages={enabledPages}
                isNotWhitelisted={
                  whiteListedPages
                    ? !whiteListedPages.includes(page.value)
                    : false
                }
                onTogglePage={onTogglePage}
                onUpgrade={onUpgrade}
                widget={widget}
                shopifyPlusStore={shopifyPlusStore}
              />
            </div>
          ))}
      </div>
      <div className="flex justify-end mt-1 text-xs italic text-gray-600">
        You can select multiple pages by checking the boxes for each page.
      </div>
    </React.Fragment>
  )
}

function PageItem({
  widget,
  page,
  isAllowedOn,
  checkedPages,
  enabledPages,
  isNotWhitelisted,
  onTogglePage,
  onUpgrade,
  shopifyPlusStore,
}) {
  const isRecommended = widget.recommendedPages.includes(page)
  const needsUpgrade = !enabledPages.includes(page.value)
  const pageDescription =
    widget.recommendedPageDescriptions[page.value] || page.description
  const pageUnsupported = page.shopifyPlusOnly && !shopifyPlusStore
  const isCheckoutPage = page.value == Pages.CHECKOUT.value

  return (
    <div
      className={classNames(
        'relative overflow-hidden h-full flex items-start border rounded group hover:bg-gray-300  pr-3 mb-3',
        {
          'opacity-75':
            !isRecommended && !checkedPages.includes(page) && isAllowedOn,
          'opacity-50 cursor-not-allowed':
            !isAllowedOn ||
            (isCheckoutPage && !shopifyPlusStore) ||
            isNotWhitelisted,
          'hover:opacity-100': isAllowedOn,
        }
      )}
      key={page.value}
    >
      <label
        className={classNames('flex flex-col items-start flex-1 p-3 xl:pr-12', {
          'cursor-pointer':
            !needsUpgrade &&
            isAllowedOn &&
            (isCheckoutPage ? shopifyPlusStore : true) &&
            !isNotWhitelisted,
          'cursor-not-allowed':
            !isAllowedOn ||
            (isCheckoutPage && !shopifyPlusStore) ||
            isNotWhitelisted,
        })}
        htmlFor={page.value}
      >
        <div className="flex flex-row items-center mb-2">
          <span className="text-sm font-semibold uppercase"> {page.label}</span>{' '}
          {page.shopifyPlusOnly && <ShopifyPlusBadge className="ml-3" />}
        </div>
        {pageDescription && (
          <p className="mb-2 text-xs leading-tight">{pageDescription}</p>
        )}
        {isRecommended && (
          <div className="px-1 text-xs text-white rounded-sm bg-primary">
            Recommended
          </div>
        )}
        {!pageUnsupported && needsUpgrade && isAllowedOn && (
          <Callout className="mt-1" textSize="small" noHoverEffect={true}>
            Upgrade plan to place widgets on {page.label}.
            <Button
              size="xxSmall"
              className="ml-1"
              onClick={() => onUpgrade(page.value)}
            >
              Start Free Trial
            </Button>
          </Callout>
        )}

        {!shopifyPlusStore && isCheckoutPage && (
          <span className="mt-2 text-xs text-red-600">
            {page.label} is currently only supported for Shopify Plus stores
          </span>
        )}

        {isCheckoutPage && !pageUnsupported && needsUpgrade && (
          <span className="mt-2 text-xs text-primary-700">
            Chat with us to enable {Pages.CHECKOUT.label} on your store
          </span>
        )}

        {!isAllowedOn && (
          <span className="mt-2 text-xs text-red-600">
            {page.label} is not supported for this widget type.
          </span>
        )}
      </label>
      <input
        id={page.value}
        disabled={needsUpgrade || pageUnsupported}
        type="checkbox"
        className="mt-3"
        checked={checkedPages.indexOf(page) !== -1}
        onChange={(e) => {
          if (
            !isNotWhitelisted &&
            isAllowedOn &&
            (isCheckoutPage ? shopifyPlusStore : true)
          ) {
            onTogglePage(page, e.target.checked)
          }
        }}
      />
    </div>
  )
}
