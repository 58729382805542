import React from 'react'

import FieldValue from 'components/ResourceList/FieldValue'

export default function ResourceListCell(props) {
  const {
    value,
    cell: {
      column: { id: fieldName },
    },
    state: { resourceSchema },
  } = props

  return (
    <div className="w-full truncate" title={value}>
      <FieldValue
        value={value}
        type={resourceSchema.schema[fieldName].type}
        isList={resourceSchema.schema[fieldName].list}
      />
    </div>
  )
}
