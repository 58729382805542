import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'

import ImageSettings from './ImageSettings'
import LineSettings from './LineSettings'
import useImageSection from './hooks/useImageSection'

export default function PromotionBuilderLayoutEditorImageForm({
  section,
  handleUpdate,
}) {
  const {
    groups,
    activeGroup,
    toggleActiveGroup,
    handleSettingsUpdate,
    handleLineUpdate,
    handleLineDelete,
    handleLineAdd,
  } = useImageSection({
    section,
    handleUpdate,
  })

  return (
    <ul className="relative flex flex-col border-t-8 border-gray-50">
      {groups.map((group, index) => {
        const isActive = group.id === activeGroup
        const nextIsActive = groups[index + 1]?.id === activeGroup

        return (
          <li
            key={group.id}
            className={classNames('flex flex-col items-stretch bg-white', {
              'border-b-8 border-gray-50': isActive,
              'border-t-8': isActive && index > 0,
            })}
          >
            <div
              className={classNames(
                'flex items-center justify-between px-4 text-base font-medium cursor-pointer group h-16 hover:bg-gray-10',
                {
                  'border-b border-gray-200': !isActive && !nextIsActive,
                  'text-primary': isActive,
                }
              )}
              onClick={() => toggleActiveGroup(group.id)}
            >
              <span>{group.label}</span>
              <Icons.ChevronDown
                className={classNames('w-4 transition-transform', {
                  'text-primary': isActive,
                  'transform rotate-180 text-gray-600': !isActive,
                })}
              />
            </div>
            {isActive && (
              <div className={classNames('p-4 border-t border-gray-10')}>
                {group.id === 'image_settings' ? (
                  <ImageSettings
                    value={group.settings}
                    handleUpdate={handleSettingsUpdate}
                  />
                ) : (
                  <LineSettings
                    value={group.settings}
                    handleUpdate={handleSettingsUpdate}
                    handleLineUpdate={handleLineUpdate}
                    handleLineDelete={handleLineDelete}
                    handleLineAdd={handleLineAdd}
                  />
                )}
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}

/**
 * Settings
 * {
 *  source: "Any image source",
 *  source_file_name: "Any image source",
 *  aspect_ratio: "Any fraction",
 *  alt_text: "Any string",
 *  border: "show" | "hide",
 *  lines: [
 *    {
 *      text: "Any string",
 *      timer: "Any number",
 *      appearance: "critical" | "warning" | "success",
 *      size: "small" | "medium" | "large" | "xlarge",
 *    },
 *  ],
 *  text_block_placement: "right" | "left",
 *  text_block_spacing: "xtight" | "tight" | "loose" | "xloose"
 *  alignment: "leading" | "center" | "trailing"
 *  spacing: "xtight" | "tight" | "loose" | "xloose"
 * }
 */
