import React, { useEffect, useRef } from 'react'

import { DISCOUNT_TYPES } from 'constants/promotions'

import { Input, SwitchSelect } from 'components/EditorForm'

export default function PromotionBuilderLayoutUpsellDiscounts({
  value: formValue,
  handleUpdate,
  accountData,
}) {
  const { discount = {} } = formValue
  const { type, value } = discount
  const inputRef = useRef(null)
  const discTypes = Object.keys(DISCOUNT_TYPES)
    .map((k) => DISCOUNT_TYPES[k])
    .map((item) => ({
      label:
        item.value === DISCOUNT_TYPES.FLAT.value
          ? accountData.currency
          : item.label,
      value: item.value,
    }))

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [type])

  return (
    <div className="flex flex-row items-center">
      <SwitchSelect
        value={type}
        onChange={(v) => handleUpdate({ discount: { type: v, value: '' } })}
        controlProps={{
          options: discTypes,
        }}
      />
      <div className="flex flex-col ml-4">
        <Input
          value={value}
          onChange={(v) => handleUpdate({ discount: { value: v, type } })}
          controlProps={{
            type: 'number',
            placeholder: 'Enter value...',
            ref: inputRef,
          }}
        />
      </div>
    </div>
  )
}
