import React from 'react'
import classNames from 'classnames'
import CreateWidget from 'components/CreateWidget'
import Box from 'components/Box'
import Button from 'components/Button'
import Badge from 'components/Badge'
import Tooltip from 'components/Tooltip'
import { pageLabelFromType, widgetLabelFromType } from 'utils/constants'

export default function AddWidgetModal({
  close,
  updateWidgetData,
  ...createWidgetProps
}) {
  const [newAddedWidgets, setNewAddedWidgets] = React.useState([])
  const experience = createWidgetProps.experience
  return newAddedWidgets.length > 0 ? (
    <Box>
      <Box.Heading>
        {experience && `${experience.name} - `}Configure Widgets
      </Box.Heading>
      <div className="flex flex-col items-stretch">
        <WidgetsList widgets={newAddedWidgets} />
      </div>
    </Box>
  ) : (
    <CreateWidget
      {...createWidgetProps}
      onCreateWidgets={(newWidgets) => {
        if (!newWidgets || newWidgets.length === 0) {
          return
        }
        if (updateWidgetData && typeof updateWidgetData === 'function') {
          updateWidgetData(newWidgets, createWidgetProps.experience)
        }
        setNewAddedWidgets(newWidgets)
      }}
    />
  )
}

function WidgetsList({ widgets }) {
  return (
    <div className="flex flex-col items-stretch">
      {widgets.map((widget, index) => {
        const isLast = index === widgets.length - 1
        return (
          <div
            className={classNames('flex flex-col items-start py-3', {
              ['mb-3 border-b-2 border-gray-300 pb-6']: !isLast,
            })}
            key={widget.id}
          >
            <div className="flex flex-row items-baseline justify-between w-full">
              <div className="mb-2 text-xl font-semibold leading-tight text-gray-800 notranslate">
                {widget.title}
              </div>
              <Tooltip
                position="right"
                content="You can configure title, position & recommendations for this widget"
              >
                <Button
                  size="xSmall"
                  variant="bordered"
                  href={`widgets/${widget.id}/`}
                  internalLink={true}
                >
                  Configure
                </Button>
              </Tooltip>
            </div>
            <div className="flex flex-row items-center">
              <Badge
                color="yellow"
                className="mr-2 uppercase"
                spacing="regular"
              >
                {widgetLabelFromType(widget.type)}
              </Badge>
              <Badge
                color="yellow"
                className="mr-2 uppercase"
                spacing="regular"
              >
                {pageLabelFromType(widget.pageType)}
              </Badge>
            </div>
          </div>
        )
      })}
    </div>
  )
}
