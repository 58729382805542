import React from 'react'

import ConfirmStatusChangeModal from 'containers/Experiences/Modals/ConfirmStatusChange'
import Form from 'components/Form'
import { useOpenModal } from 'components/Modal'

export default function StatusSelector({
  canUnpublish,
  experience,
  totalWeight,
  updateLocalExperiences,
  shop,
  openUpgradeAccount,
}) {
  const openModal = useOpenModal()
  return (
    <Form.Field
      control="select"
      size="small"
      options={[
        { label: 'Published', value: 'published' },
        {
          label: 'Unpublished',
          value: 'unpublished',
        },
      ]}
      onClick={(event) => {
        event.stopPropagation()
      }}
      onChange={(event) => {
        if (openUpgradeAccount && typeof openUpgradeAccount === 'function') {
          openUpgradeAccount()
          return
        }
        switch (event.target.value) {
          case 'unpublished':
            if (experience.status === 'unpublished') {
              return
            }
            break
          case 'published':
            if (experience.status === 'published') {
              return
            }
            break
        }
        openModal(
          <ConfirmStatusChangeModal
            experience={experience}
            totalWeight={totalWeight}
            action={event.target.value}
            canUnpublish={canUnpublish}
            updateLocalExperiences={updateLocalExperiences}
            shop={shop}
          />,
          { fullScreen: false }
        )
      }}
      value={experience.status}
    />
  )
}
