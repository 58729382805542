import React from 'react'

import Button from 'components/Button'
import WaitForData from 'components/WaitForData'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useQueryParams } from 'hooks/router'
import { RECOMMENDATION_RULES } from 'constants/features'
import { isFeatureEnabled } from 'utils/features'

import CreateRule from './CreateRule'
import RuleCard from './RuleCard'

export default function RecommendationRules({
  widgetType,
  widgetId,
  onClose,
  account,
  recommendations,
}) {
  const { shop } = useQueryParams()
  const [newRuleFormVisible, setNewRuleFormVisible] = React.useState(false)
  const rules = recommendations
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(account.data, RECOMMENDATION_RULES)

  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal
        account={account}
        featureToEnable={RECOMMENDATION_RULES}
      />
    )
  }

  return (
    <WaitForData
      objects={[rules]}
      loadingMessage={'Loading recommendation rules for ' + widgetType}
    >
      {() => {
        const rulesForWidgetType = rules.data.rules.filter(
          (r) => r.recommendationType === widgetType
        )
        return (
          <div className="flex flex-col items-stretch">
            <div className="flex flex-row items-center justify-between mb-1">
              <span className="text-xl font-semibold">
                Configure Recommendation Rules
              </span>
              <Button onClick={() => setNewRuleFormVisible(true)}>
                + Add New Rule
              </Button>
            </div>
            {onClose && (
              <span
                className="self-start mb-6 -mt-1 text-sm underline cursor-pointer text-primary"
                onClick={onClose}
              >
                ← Go Back
              </span>
            )}
            <div className="flex flex-col items-stretch">
              {newRuleFormVisible && (
                <CreateRule
                  rules={rules}
                  shop={shop}
                  widgetType={widgetType}
                  widgetId={widgetId}
                  openUpgradeAccount={
                    featureEnabled ? false : openUpgradeAccount
                  }
                  onClose={() => setNewRuleFormVisible(false)}
                />
              )}
              {rulesForWidgetType.map((rule) => (
                <RuleCard
                  key={rule.id}
                  rule={rule}
                  rules={rules}
                  shop={shop}
                  widgetType={widgetType}
                  widgetId={widgetId}
                />
              ))}
            </div>
          </div>
        )
      }}
    </WaitForData>
  )
}
