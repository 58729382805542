import React from 'react'
import Icons from 'components/Icons'
import classNames from 'classnames'
export default function ActionCompletedIcon({ actionCompleted }) {
  return (
    <Icons.CircleTick
      className={classNames(
        'w-4 h-4 ml-2 rounded-full',
        {
          'text-gray-100 bg-gray-700 opacity-50': !actionCompleted,
        },
        {
          'bg-primary text-white': actionCompleted,
        }
      )}
    />
  )
}
