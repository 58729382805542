import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Box from 'components/Box'
import Button from 'components/Button'
import Callout from 'components/Callout'
import Form from 'components/Form'
import { Select } from 'components/Form/controls'
import Text from 'components/Text'
import classnames from 'classnames'
import { useToast } from 'components/Toast'

export default function Translations({ account, store }) {
  const { primaryLocale = 'en' } = account.data.shop
  const translations = getTranslationsFromStoreData(store.data)
  const hasEnabledTranslations =
    account.data.plan.featuresEnabled.includes('translations')
  const openToast = useToast()
  const [activeLanguage, setActiveLanguage] = React.useState(primaryLocale)

  const { register, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: translations.primaryLocale,
  })

  useEffect(() => {
    if (activeLanguage === primaryLocale) {
      reset(translations.primaryLocale)
    } else {
      reset(translations[activeLanguage])
    }
  }, [activeLanguage])

  async function onSubmit(data) {
    let requestData
    if (activeLanguage === primaryLocale) {
      requestData = { primaryLocale: data, [activeLanguage]: data }
    } else {
      requestData = { [activeLanguage]: data }
    }

    try {
      await store.update({ translations: requestData })
      openToast({
        type: 'success',
        text: 'Translations have been updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Translations were not saved successfully!',
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <Text.ScreenHeading>Translations</Text.ScreenHeading>
      <Box>
        <div className={'flex flex-col items-stretch mb-6'}>
          <label
            className="mb-1 text-xs font-semibold"
            htmlFor={'languageSelect'}
          >
            Store Default Language
          </label>
          <Select
            options={SUPPORTED_LANGUAGES}
            name="languageSelect"
            className={hasEnabledTranslations ? '' : 'opacity-50'}
            onChange={(event) => {
              setActiveLanguage(event.target.value)
            }}
            disabled={!hasEnabledTranslations}
            value={activeLanguage}
          />
          {hasEnabledTranslations ? (
            <div className="flex items-center justify-end mt-2 text-sm text-gray-800">
              <span className="text-xs text-gray-600">
                Please choose the language you want to modify the translations
                for.
              </span>
            </div>
          ) : (
            <div className="flex justify-end mt-1">
              <Callout type="info" noHoverEffect={true}>
                <span className="mr-2">
                  Please contact us if you need support for multiple languages
                </span>
                <Button
                  color="primary"
                  size="xSmall"
                  href="mailto:support@glood.ai"
                >
                  Contact Us
                </Button>
              </Callout>
            </div>
          )}
        </div>
        <form
          className="flex flex-col items-start flex-1 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-row flex-wrap w-full">
            {TRANSLATION_FIELDS.map((field, index) => {
              const isEven = index % 2 === 0
              return (
                <Form.Field
                  label={field.label}
                  name={field.value}
                  control="input"
                  ref={register({ required: false })}
                  controlClassName="block w-full px-4 py-2 pr-8 leading-tight bg-white border border-gray-400 rounded shadow appearance-none flex-1/2 hover:border-gray-500 focus:outline-none focus:shadow-outline"
                  className={classnames('w-full lg:w-1/2 mb-6', {
                    'lg:pr-3': isEven,
                    'lg:pl-3': !isEven,
                  })}
                  helpText={field.helpText}
                  key={field.value}
                />
              )
            })}
          </div>
          <Button size="small">Save</Button>
        </form>
      </Box>
    </div>
  )
}

const SUPPORTED_LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'sp', label: 'Spanish' },
  { value: 'hi', label: 'Hindi' },
]

const TRANSLATION_FIELDS = [
  {
    label: 'This Product Text',
    value: 'thisProductText',
    default: 'This Product:',
    helpText:
      "This text will replace 'This Product:' label in Amazon style frequently bought together widget.",
  },
  {
    label: 'Total price Text',
    value: 'totalPriceText',
    default: 'Total Price:',
    helpText:
      "This text will replace 'Total Price:' label in Amazon style frequently bought together widget.",
  },
  {
    label: 'Adding to cart Text',
    value: 'addingToCartText',
    default: 'Adding...',
    helpText:
      'This text will be displayed whenever a customer is adding a recommended product to their cart.',
  },
  {
    label: 'Added to cart Text',
    value: 'addedToCartText',
    default: 'Added to cart',
    helpText:
      'This text will be displayed when a customer has added a recommended product to their cart.',
  },
  {
    label: 'Out of stock Text',
    value: 'outOfStockText',
    default: 'Out of stock',
    helpText:
      'This text will be displayed whenever a recommended product is out of stock.',
  },
  {
    label: 'Go to cart Text',
    value: 'goToCartText',
    default: 'Go to cart',
    helpText:
      'This text will be displayed in the go to cart link under every recommendation.',
  },
]

function getTranslationsFromStoreData(store) {
  const defaults = SUPPORTED_LANGUAGES.reduce(
    (obj, lang) => ({
      ...obj,
      [lang.value]: {},
    }),
    {}
  )

  return {
    ...defaults,
    ...store.translations,
  }
}
