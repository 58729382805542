import React from 'react'
import api from 'api'
import _uniq from 'lodash.uniq'

import WaitForData from 'components/WaitForData'
import { useToast } from 'components/Toast'
import Products from 'components/MultiSelects/Products'
import Tags from 'components/MultiSelects/Tags'
import { useApi } from 'hooks/api'

export default function ExcludedProducts({
  account,
  store,
  currencyFormat,
  rkSupport,
}) {
  const { name: shop } = account.data
  const excludedData = useApi('exclusion/', { shop })
  const { excludedTags = [] } = store.data
  const openToast = useToast()

  async function updateExcludedProducts(productIds, twoWayEnabled = false) {
    try {
      const { data } = await api.post(
        '/exclusion/',
        { productIds: productIds, reset: true },
        { params: { shop } }
      )
      excludedData.update(data, { local: true })
      openToast({
        type: 'success',
        text: 'Exclusion List has been Updated. It might take a few minutes for the changes to be visible.!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Exclusion List could not be updated successfully! Please contact support.',
      })
    }
  }

  async function removeProduct(productId) {
    try {
      await api.delete(`/exclusion/${productId}/`, { params: { shop } })
      const updatedProducts = excludedData.data.products.filter(
        (product) => product.id !== productId
      )
      excludedData.update({ products: updatedProducts }, { local: true })
      openToast({
        type: 'success',
        text: 'Product removed from Exclusion List. It might take a few minuts for the effects to take place.!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Product could not be removed from exclusion list! Please contact support.',
      })
    }
  }

  async function updateTags(newTags) {
    try {
      await store.update({ excludedTags: newTags })
      openToast({
        type: 'success',
        text: 'Tags successfully updated..',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Unable to update the tags. Please contact support.',
      })
    }
  }

  async function onTagAdd({ tag }) {
    if (excludedTags.includes(tag)) {
      openToast({
        type: 'error',
        text: 'Tag already exists',
      })
    } else {
      const newTags = _uniq([...excludedTags, tag])
      await updateTags(newTags)
    }
  }

  async function onTagRemove(tag) {
    const newTags = _uniq(excludedTags.filter((t) => t !== tag))
    await updateTags(newTags)
  }
  return (
    <WaitForData
      objects={[excludedData, store]}
      loadingMessage="Loading Excluded Products"
    >
      {() => {
        return (
          <div className="flex flex-row items-start">
            <Products
              products={excludedData.data.products}
              currencyFormat={currencyFormat}
              rkSupport={rkSupport}
              updateProducts={updateExcludedProducts}
              removeProduct={removeProduct}
              copies={PRODUCT_COPIES}
            />
            <Tags
              onTagAdd={onTagAdd}
              onTagRemove={onTagRemove}
              tags={excludedTags}
              copies={TAG_COPIES}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}

const PRODUCT_COPIES = {
  title: 'Excluded Products',
  tooltipContent:
    'Add a product here to exclude it from showing up in recommendations. It may take a few minutes for the configuration to take effect.',
  resourcePickerHeading: 'Choose excluded products',
  fallbackContent:
    'No products added to exclusion list yet. Adding a product here will remove that product from showing up as recommendation.',
}
const TAG_COPIES = {
  title: 'Excluded Tags',
  tooltipContent:
    'Any product in your store with the following tags will not show up as a recommendation.',
  fallbackContent:
    'No tags added to exclusion list yet. Adding a tag here will remove the products with these tags from recommendations.',
}
