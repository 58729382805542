import React from 'react'
import _uniq from 'lodash.uniq'

import { useToast } from 'components/Toast'
import Products from 'components/MultiSelects/Products'
import Tags from 'components/MultiSelects/Tags'
import Box from 'components/Box'

export default function WhitelistedProducts({
  currencyFormat,
  rkSupport,
  onSave,
  widget,
}) {
  const { whitelistedProducts = [] } = widget
  const { whitelistedTags = [] } = widget
  const openToast = useToast()

  async function updateWhitelistedProducts(productIds, twoWayEnabled = false) {
    onSave(
      { whitelistedProducts: productIds, reset: true },
      'Whitelist has been updated. It might take a few minutes for the changes to be visible.!'
    )
  }

  async function removeProduct(productId) {
    onSave(
      {
        whitelistedProducts: whitelistedProducts
          .filter((product) => product.id !== productId)
          .map((product) => product.id),
      },
      'Product removed from the whitelist. It might take a few minutes for the effects to take place.!'
    )
  }

  async function updateTags(newTags) {
    onSave({ whitelistedTags: newTags }, 'Tags successfully updated..')
  }

  async function onTagAdd({ tag }) {
    if (whitelistedTags.includes(tag)) {
      openToast({
        type: 'error',
        text: 'Tag already exists',
      })
    } else {
      const newTags = _uniq([...whitelistedTags, tag])
      await updateTags(newTags)
    }
  }

  async function onTagRemove(tag) {
    const newTags = _uniq(whitelistedTags.filter((t) => t !== tag))
    await updateTags(newTags)
  }
  return (
    <Box className="mt-6">
      <div className="flex flex-row items-start w-full">
        <Products
          products={whitelistedProducts}
          currencyFormat={currencyFormat}
          rkSupport={rkSupport}
          updateProducts={updateWhitelistedProducts}
          removeProduct={removeProduct}
          copies={PRODUCT_COPIES}
        />
        <Tags
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          tags={whitelistedTags}
          copies={TAG_COPIES}
        />
      </div>
    </Box>
  )
}

const PRODUCT_COPIES = {
  title: 'Whitelisted Products',
  tooltipContent:
    'Add a product here to ensure that recommendations show up only for those products. It may take a few minutes for the configuration to take effect.',
  resourcePickerHeading: 'Choose whitelisted products',
  fallbackContent:
    'No products added to whitelist yet. Adding a product here will ensure that recommendations show up only for those products.',
}
const TAG_COPIES = {
  title: 'Whitelisted Tags',
  tooltipContent:
    'Only products in your store with the following tags will show  recommendations.',
  fallbackContent:
    'No tags added to whitelist yet. Adding a tag here ensure that recommendations show up only for products with that tag.',
}
