import React from 'react'
import EmailView from 'components/EmailView'
import { SectionHeading } from '../index'

export default function CampaignDetails({ account, campaign, onCampaignActivated }) {
  return (
    <React.Fragment>
      <SectionHeading>Activate Campaign</SectionHeading>
      <EmailView account={account} campaign={campaign} onSave={onCampaignActivated} />
    </React.Fragment>
  )
}
