import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import { STRING } from 'constants/fieldTypes'

export default function StringFilterInput({ filters, field }) {
  const fieldState = filters.state[field.key]
  const inputRef = useRef()

  useEffect(() => {
    if (!fieldState.operator) {
      filters.setFieldOperator({
        key: field.key,
        value: STRING.filterOperatorOptions[0].value,
      })
    }
  }, [])

  return (
    <div className="flex flex-col items-stretch w-full mt-2 pl-7">
      <select
        onChange={(e) => {
          filters.setFieldOperator({
            key: field.key,
            value: e.target.value,
          })
          inputRef.current.focus()
        }}
        value={fieldState.operator}
        className="block w-full px-1 py-1 mb-1 bg-gray-200 border border-gray-400 rounded sm:text-sm focus:border-indigo-500"
      >
        {STRING.filterOperatorOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <input
        ref={inputRef}
        className={classNames(
          'appearance-none block w-full px-2 py-1 border focus:outline-none ease-in-out sm:text-sm rounded border-gray-400 placeholder-gray-400 focus:border-primary-500'
        )}
        value={fieldState.values.join(',')}
        onChange={(e) => {
          const values = e.target.value ? e.target.value.split(',') : []
          filters.setFieldValues({ key: field.key, values })
        }}
        type="text"
      />
    </div>
  )
}
