import React, { useEffect, useState } from 'react'

import { shopifyCallback } from 'api/shopify'
import Loader from 'components/Loader'
import Icons from 'components/Icons'
import Button from 'components/Button'
import { useQueryParams } from 'hooks/router'
import { openChat } from 'utils/external'

export default function Callback() {
  const queryParams = useQueryParams()
  const [error, setError] = useState(false)

  useEffect(() => {
    async function callback() {
      try {
        const {
          data: { redirectUrl },
        } = await shopifyCallback(queryParams)
        window.location.href = redirectUrl
      } catch (err) {
        setError(true)
      }
    }
    callback()
  }, [])
  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 bg-gray-200">
        <div className="flex flex-col items-center justify-center">
          <Icons.Glood className="w-32 text-primary" />
          <span className="mt-8 text-lg">
            There was an error processing your request
          </span>
          <Button onClick={openChat} className="mt-4" size="small">
            Contact Support
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="flex items-center justify-center flex-1">
      <Loader message="Fetching details" />
    </div>
  )
}
