import React from 'react'
import Box from 'components/Box'

const Onboarding = {}

Onboarding.Box = ({ children }) => {
  return (
    <Box className="flex flex-col items-stretch mb-6 w-full lg:w-5/6 xl:w-3/4">
      {children}
    </Box>
  )
}

Onboarding.BoxHeading = ({ children }) => {
  return (
    <h2 className="text-xs text-gray-700 uppercase tracking-wide leading-tight pb-2 border-b mb-6">
      {children}
    </h2>
  )
}

Onboarding.ActionTray = ({ children }) => {
  return <div className="flex justify-center items-center">{children}</div>
}

export default Onboarding
