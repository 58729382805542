import React from 'react'
import { Route } from 'react-router-dom'

import AuthFail from 'components/AuthFail'
import Maintenance from 'containers/Maintenance'
import Loader from 'components/Loader'
import { useQueryParams } from 'hooks/router'
import { useApi } from 'hooks/api'
import * as QueryUtils from 'utils/query'

export default function ({
  component: Component,
  account,
  shops,
  ...otherRouteProps
}) {
  const { shop } = useQueryParams()
  const store = useApi('config/', { shop })
  const accountData = QueryUtils.data(account)
  const currentUTCTimestamp = Math.floor(new Date().getTime() / 1000)
  if (!shop) {
    return null
  }
  let content = null
  if (account.isLoading || store.loading) {
    content = (
      <div className="flex items-center justify-center flex-1">
        <Loader message="Fetching store details" />
      </div>
    )
  } else if (accountData && account.isSuccess && store.data) {
    const underMaintenence =
      accountData.underMaintenance &&
      accountData.maintenanceEndsAt > currentUTCTimestamp

    content = underMaintenence ? (
      <Maintenance account={account.data} store={store} />
    ) : (
      <Component account={account.data} store={store} shops={shops} />
    )
  } else if (account.isError || store.error) {
    content = <AuthFail />
  } else {
    content = null
  }

  return <Route {...otherRouteProps}>{content}</Route>
}
