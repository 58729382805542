import React from 'react'
import classNames from 'classnames'
import Icons from 'components/Icons'
import AddNode from './Nodes/AddNode'

export default function Connector({ position, edge, previousNode, wb }) {
  const { setSelectedNode } = wb
  const [showAddNode, setShowAddNode] = React.useState(false)
  if (!previousNode) {
    return null
  }

  if (position === 'center') {
    return (
      <div className="relative flex flex-col items-center mx-auto">
        <div className="w-[5px] h-[5px] bg-black rounded-full" />
        <div className="w-[1px] h-[20px] bg-black" />
        {!edge && (
          <div
            className="p-2 font-semibold text-white rounded-full cursor-pointer bg-primary"
            style={{ fontSize: '24px', lineHeight: '14px' }}
            onClick={() => setShowAddNode(true)}
          >
            +
          </div>
        )}
        {showAddNode && (
          <div className="absolute z-10" style={{ top: 'calc(50% + 40px)' }}>
            <AddNode
              onClose={() => setShowAddNode(false)}
              edge={edge}
              wb={wb}
              previousNode={previousNode}
            />
          </div>
        )}
        <div className="w-[1px] h-[20px] bg-black" />
        <div className=" text-black -mt-[0.4rem]">
          <Icons.ChevronDown width="15px" />
        </div>
      </div>
    )
  }

  if (position === 'left') {
    return (
      <div className="flex flex-row-reverse items-center -ml-10">
        <div className="w-[5px] h-[5px] bg-black rounded-full" />
        <div
          className="h-[1px] bg-black"
          style={{ width: 'calc(50% - 25px)' }}
        />
        <div className="relative flex flex-col items-center top-12 left-4">
          <div className="w-[1px] h-[32px] bg-black" />
          <div
            className="p-2 font-semibold text-white rounded-full cursor-pointer bg-primary"
            style={{ fontSize: '24px', lineHeight: '14px' }}
            onClick={() => setShowAddNode(true)}
          >
            +
          </div>
          {showAddNode && (
            <div className="absolute z-10" style={{ top: 'calc(50% + 48px)' }}>
              <AddNode
                onClose={() => setShowAddNode(false)}
                edge={edge}
                wb={wb}
                previousNode={previousNode}
              />
            </div>
          )}
          <div className="w-[1px] h-[32px] bg-black" />
          <div className=" text-black -mt-[0.4rem]">
            <Icons.ChevronDown width="15px" />
          </div>
          <div className="absolute bg-brand text-white text-xs rounded px-1 py-0.5 font-semibold top-[36px] right-10">
            FALSE
          </div>
        </div>
      </div>
    )
  }
  if (position === 'right') {
    return (
      <div className="relative flex items-center -mr-10">
        <div className="w-[5px] h-[5px] bg-black rounded-full" />
        <div
          className="h-[1px] bg-black"
          style={{ width: 'calc(50% - 25px)' }}
        />
        <div className="flex flex-col items-center relative top-12 left-[-15px]">
          <div className="w-[1px] h-[32px] bg-black" />
          <div
            className="p-2 font-semibold text-white rounded-full cursor-pointer bg-primary"
            style={{ fontSize: '24px', lineHeight: '14px' }}
            onClick={() => setShowAddNode(true)}
          >
            +
          </div>
          {showAddNode && (
            <div className="absolute z-10" style={{ top: 'calc(50% + 48px)' }}>
              <AddNode
                onClose={() => setShowAddNode(false)}
                edge={edge}
                wb={wb}
                previousNode={previousNode}
              />
            </div>
          )}
          <div className="w-[1px] h-[32px] bg-black" />
          <div className="transform rotate-90 text-black -mt-1.5">
            <Icons.ChevronDown width="5px" />
          </div>
          <div className="absolute bg-brand text-white text-xs rounded px-1 py-0.5 font-semibold top-[36px] left-10">
            TRUE
          </div>
        </div>
      </div>
    )
  }

  return null
}
