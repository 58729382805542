export const IS_EQUAL = { label: 'is equal to', value: 'eq' }
export const IS_NOT_EQUAL = { label: 'is not equal to', value: '!eq' }

export const CONTAINS = { label: 'contains', value: 'in' }
export const NOT_CONTAINS = { label: 'does not contain', value: '!in' }

export const MORE_THAN = { label: 'is more than', value: 'gt' }
export const LESS_THAN = { label: 'is less than', value: 'lt' }
export const IS_BEFORE = { label: 'is before', value: 'lt' }
export const IS_AFTER = { label: 'is after', value: 'gt' }

export const WITHIN_RANGE = { label: 'is within the range', value: 'range' }
export const OUTSIDE_RANGE = {
  label: 'is not within the range',
  value: '!range',
}

export const IS_ANY = { label: 'is any of', value: 'any' }
export const IS_NOT_ANY = { label: 'is not any of', value: '!any' }

export const IS_PRESENT = { label: 'is present', value: 'exists' }
export const IS_NOT_PRESENT = { label: 'is not present', value: 'null' }

export const IS_TRUE = { label: 'is true', value: 'true' }
export const IS_FALSE = { label: 'is false', value: 'false' }
