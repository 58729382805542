import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'

import PreCheckout from './PreCheckout'
import PostCheckout from './PostCheckout'
import { WORKFLOW_TASK_TYPES } from '../constants'
import { sectionLabelFromType, canDeleteTask } from '../utils'

export default function TaskOptions({ wb, builder }) {
  const {
    deleteNode,
    setSelectedNode,
    selectedNode,
    closeTaskOptions: onClose,
  } = wb
  const ActiveSectionComponent = selectedNode
    ? SECTION_TYPE_TO_COMPONENT_MAP[selectedNode.task.type]
    : null
  return (
    <div className="flex flex-col items-stretch w-full h-full bg-gray-background">
      <nav
        className={classNames(
          'flex flex-row items-center px-4 bg-white h-16 group shadow-navbar border-b border-gray-200',
          {
            'hover:bg-gray-10 cursor-pointer': selectedNode,
          }
        )}
        onClick={onClose}
      >
        {selectedNode && (
          <Icons.ChevronRight className="w-5 mr-5 text-gray-600 transition-transform transform rotate-180 translate-x-1 stroke-current group-hover:translate-x-0 group-hover:text-primary" />
        )}
        <span
          className={classNames('text-black text-lg', {
            'font-semibold': !selectedNode,
            'font-medium': selectedNode,
          })}
        >
          {selectedNode
            ? sectionLabelFromType(selectedNode.task.type)
            : 'Task Options'}
        </span>
      </nav>
      <div className="flex flex-1 min-h-0 bg-white">
        <div className="flex-1 overflow-y-auto">
          {ActiveSectionComponent && (
            <div className="flex flex-col h-full">
              <ActiveSectionComponent
                node={selectedNode}
                builder={builder}
                wb={wb}
                // handleUpdate={(sectionUpdate) =>
                //   updateSection({
                //     sectionId: activeSection.uuid,
                //     update: sectionUpdate,
                //   })
                // }
                // accountData={accountData}
              />
              {canDeleteTask({ node: selectedNode }) && (
                <button
                  className="flex items-center justify-end flex-shrink-0 w-full h-12 px-6 mt-2 text-red-400 transition-colors cursor-pointer hover:text-red-600"
                  onClick={() => {
                    deleteNode({ node: selectedNode })
                    setSelectedNode(null)
                  }}
                >
                  <Icons.Delete className="w-4 mr-2" />
                  <span className="font-semibold leading-none">
                    Delete Task
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SECTION_TYPE_TO_COMPONENT_MAP = {
  [WORKFLOW_TASK_TYPES.PRE_CHECKOUT.value]: PreCheckout,
  [WORKFLOW_TASK_TYPES.POST_CHECKOUT.value]: PostCheckout,
}
