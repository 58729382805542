import humps from 'humps'
import { useRef, useEffect } from 'react'
import { useQuery } from 'react-query'

import * as QueryUtils from 'utils/query'
import { fetchVisitors as fetchVisitorsApi } from 'api/visitors'
import { fetchFunnels as fetchFunnelsApi } from 'api/funnel'
import { fetchBundles as fetchBundlesApi } from 'api/bundles'
import { fetchPopups as fetchPopupsApi } from 'api/popups'
import { useQueryParams } from 'hooks/router'

export default function useListData({
  resourceType,
  filters,
  pagination,
  order,
}) {
  const { shop } = useQueryParams()
  const query = useQuery(
    [
      RESOURCE_TYPE_TO_QUERY_NAME_MAP[resourceType],
      shop,
      {
        filters: filters.query,
        pagination: pagination.query,
        order: decamelizeOrderQuery(order.query),
      },
    ],
    RESOURCE_TYPE_TO_FETCH_QUERY_METHOD_MAP[resourceType]
  )

  const queryData = QueryUtils.data(query)
  const resolvedData = useRef()

  useEffect(() => {
    if (query.status === 'success') {
      resolvedData.current = queryData
      pagination.setTotal(queryData.total)
    }
  }, [query, query.status, queryData])

  return {
    query,
    queryData,
    queryStatus: query.status,
    resolvedData: resolvedData.current,
  }
}

const RESOURCE_TYPE_TO_QUERY_NAME_MAP = {
  visitor: 'visitors',
  funnel: 'funnels',
  bundle: 'bundles',
  popup: 'popups',
}

const RESOURCE_TYPE_TO_FETCH_QUERY_METHOD_MAP = {
  visitor: fetchVisitorsApi,
  funnel: fetchFunnelsApi,
  bundle: fetchBundlesApi,
  popup: fetchPopupsApi,
}

function decamelizeOrderQuery(obj) {
  return obj.map((i) => ({
    ...i,
    field: humps.decamelize(i.field),
  }))
}
