import React from 'react'
import { format, parse } from 'date-fns'
import {
  BarChart as RechartBarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
  AreaChart as RechartAreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts'
import numeral from 'numeral'
import Box from 'components/Box'
import Text from 'components/Text'
import { renderCurrency } from 'utils/currency'

const dateFormatter = (tick) => {
  return format(parse(tick, 'yyyy-MM-dd', new Date()), 'd MMM, yy')
}

const currencyFormatter = (tick, currencyFormat) => {
  const value = numeral(tick).format('0,0a')
  return currencyFormat
    ? renderCurrency(value, currencyFormat, {
        escapeHTML: true,
        skipFloatParse: true,
      })
    : '$' + value
}

const numberFormatter = (tick, type) => {
  const value = numeral(tick).format('0,0')

  if (type && type == 'percent') {
    return `${value}%`
  }
  return value
}

function BarChart({ chart, data, currencyFormat }) {
  return (
    <Box>
      <div className="flex flex-col items-stretch">
        <Text.ScreenHeading>{chart.title}</Text.ScreenHeading>
        <ResponsiveContainer width={'100%'} height={400}>
          <RechartBarChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis dataKey="date" tickFormatter={dateFormatter} />
            <YAxis
              tickFormatter={(tick) => currencyFormatter(tick, currencyFormat)}
              width={90}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend layout="vertical" verticalAlign="top" align="right" />
            {chart.bars.map((bar) => {
              return (
                <Bar
                  key={bar.key}
                  name={bar.title}
                  stackId={bar.id}
                  dataKey={bar.key}
                  fillOpacity={1}
                  fill={bar.color}
                />
              )
            })}
          </RechartBarChart>
        </ResponsiveContainer>
      </div>
    </Box>
  )
}

BarChart.defaultProps = {
  chart: {},
  data: [],
}

function AreaChart({ chart, data }) {
  return (
    <Box>
      <div className="flex flex-col items-stretch">
        <Text.ScreenHeading>{chart.title}</Text.ScreenHeading>
        <ResponsiveContainer width={'100%'} height={400}>
          <RechartAreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              {chart.areas.map((area) => (
                <linearGradient
                  key={area.key}
                  id={`color${area.key}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="5%" stopColor={area.color} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={area.color} stopOpacity={0} />
                </linearGradient>
              ))}
            </defs>
            <XAxis dataKey="date" tickFormatter={dateFormatter} />
            <YAxis
              tickFormatter={(tick) => numberFormatter(tick, chart.type)}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend layout="vertical" verticalAlign="top" align="right" />
            {chart.areas.map((area) => (
              <Area
                key={area.key}
                name={area.title}
                type="monotone"
                dataKey={area.key}
                stroke={area.color}
                fillOpacity={1}
                fill={`url(#color${area.key})`}
              />
            ))}
          </RechartAreaChart>
        </ResponsiveContainer>
      </div>
    </Box>
  )
}

AreaChart.defaultProps = {
  chart: {},
  data: [],
}

export { BarChart, AreaChart }
