import React, { useState, useCallback } from 'react'

import {
  createPopup as createPopupApi,
  updatePopup as updatePopupApi,
} from 'api/popups'
import { useToast } from 'components/Toast'
import { _find, _identity } from 'utils/lodash'
import * as QueryUtils from 'utils/query'
import { getAccountData } from 'utils/account'

export default function usePopupBuilder({
  initialPopup = {},
  initialActiveScreen,
}) {
  const [popup, setPopup] = useState(initialPopup)
  const [activeScreen, setActiveScreen] = useState(
    getInitialScreen({ popup: initialPopup, initialActiveScreen })
  )
  const [saveHandler, setSaveHandler] = useState(_identity)
  const [saveNextHandler, setSaveNextHandler] = useState(_identity)
  const [publishHandler, setPublishHandler] = useState(_identity)
  const [popupSaveError, setPopupSaveError] = useState(null)
  const [savingButton, setSavingButton] = useState(null)
  const openToast = useToast()
  const accountData = getAccountData()
  const { name: shop } = accountData

  const savePopup = useCallback(
    async (
      data,
      {
        successMessage = 'Popup saved successfully!',
        errorMessage = 'There was an error in saving the popup.',
      } = {}
    ) => {
      if (popup?.id) {
        data.slug = popup.slug
        data.id = popup.id
      }

      setPopupSaveError(null)
      try {
        let response
        if (!data.id) {
          response = await createPopupApi(shop, data)
        } else {
          response = await updatePopupApi(shop, data)
        }
        const {
          data: { popup: savedPopup },
        } = response

        QueryUtils.invalidate(['popups'])
        QueryUtils.invalidate(['popup'])
        openToast({
          type: 'success',
          text: successMessage,
        })
        setPopup(savedPopup)
        return savedPopup
      } catch (err) {
        openToast({
          type: 'error',
          text: errorMessage,
        })
        setPopupSaveError(err.response.data)
      }
    },
    [popup?.id, popup?.slug]
  )

  return {
    screens: SCREENS,
    accountData,
    activeScreen,
    popup,
    setPopup,
    savePopup,
    saveHandler,
    setSaveHandler,
    saveNextHandler,
    setSaveNextHandler,
    publishHandler,
    setPublishHandler,
    savingButton,
    setSavingButton,
    heading: 'Create Popup',
    setActiveScreen: useCallback(
      (name) => {
        const screen = _find(SCREENS, { name })
        if (screen) {
          setActiveScreen(screen)
        }
      },
      [setActiveScreen]
    ),

    popupSaveError,
  }
}

function getInitialScreen({ popup, initialActiveScreen }) {
  if (initialActiveScreen) {
    return _find(SCREENS, { name: initialActiveScreen })
  }
  return _find(SCREENS, (s) => !s.complete(popup)) || SCREENS[0]
}

export const SCREENS = [
  {
    name: 'details',
    label: 'Details',
    complete: (popup) => popup?.id && popup.name && popup.recommendationType,
    disabled: () => false,
  },
  {
    name: 'trigger',
    label: 'Triggers',
    complete: (popup) =>
      popup?.triggers &&
      Object.keys(popup.triggers).some(
        (triggerKey) => popup.triggers[triggerKey].enabled
      ),
    disabled: (popup) => !Boolean(popup?.id),
  },
  {
    name: 'template',
    label: 'Templates',
    complete: (popup) => Boolean(popup?.template),
    disabled: (popup) => !Boolean(popup?.id),
  },
  {
    name: 'review',
    label: 'Review',
    complete: (popup) => popup?.status && popup.status === 'active',
    disabled: (popup) => !Boolean(popup?.id),
  },
]
