import React from 'react'
import classNames from 'classnames'

export default function ShopifyPlus({ size = 'regular', className }) {
  return (
    <span
      className={classNames(
        'px-2 italic border border-yellow-700 rounded-full',
        SIZE_TO_CLASS_MAP[size],
        className
      )}
    >
      <span className="font-semibold ">Shopify</span>{' '}
      <span className="text-yellow-700">plus</span>
    </span>
  )
}

const SIZE_TO_CLASS_MAP = {
  regular: 'text-xs',
  small: 'text-xxs',
}
