import React, { useCallback } from 'react'

import { useOpenModal } from 'components/Modal'
import Icons from 'components/Icons'

export default function HelpArticle({ link, close }) {
  return (
    <div className="relative flex flex-col items-stretch min-w-[1024px]">
      <div className="absolute z-20 justify-end p-1.5 bg-gray-800 rounded-full hover:bg-gray-900 top-2 right-2">
        <Icons.Cross
          onClick={close}
          className="w-5.5 text-white cursor-pointer"
        />
      </div>
      <iframe
        title={`intercomHelp-${link}`}
        src={link}
        className="w-full h-screen overflow-hidden rounded"
      />
    </div>
  )
}

export function useHelpArticle() {
  const openModal = useOpenModal()

  return useCallback(
    (link) => {
      // link = link.replace(
      //   'glood-ai.notion.site',
      //   'glood-ai---notion---site.proxy.glood.ai'
      // )

      window.open(link, '_blank').focus()
      // openModal(<HelpArticle link={link} />)
    },
    [openModal]
  )
}
