import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Form from 'components/Form'
import { useToast } from 'components/Toast'
import { getNearestHalfHour } from 'utils/date'

export default function Details({ builder }) {
  const {
    bundle,
    saveBundle,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    getValues,
    control: formControl,
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(bundle),
  })
  const formValues = watch()

  const openToast = useToast()
  useEffect(() => {
    async function bundleSave(formData) {
      var payload = Object.fromEntries(
        Object.entries(formData).filter(([_, v]) => v != null)
      )
      // if (payload.endTime) {
      //   payload.endTime = Math.floor(payload.endTime.getTime() / 1000)
      // }
      // if (payload.startTime) {
      //   payload.startTime = Math.floor(payload.startTime.getTime() / 1000)
      // }
      return saveBundle(payload)
    }
    setSaveHandler(() => handleSubmit(bundleSave))
    setSaveNextHandler(() => async () => {
      await handleSubmit(
        async function onSubmit(formData) {
          const savedBundle = await bundleSave(formData)
          if (savedBundle) {
            setActiveScreen('trigger')
          }
        },
        function onError(errors, e) {
          setActiveScreen('details')
        }
      )()
    })
  }, [])
  return (
    <div className="flex flex-col w-full px-16 py-10">
      <Form.Field
        label="Name"
        name="name"
        control="input"
        ref={register({
          required: 'Bundle name cannot be empty.',
        })}
        formError={errors.name}
      />
      {/* <Form.FieldRow>
        <Form.Field
          label="Start date & time"
          name="startTime"
          control="date-time"
          autoFocus={false}
          date={true}
          time={true}
          formControl={formControl}
          min={getNearestHalfHour()}
          max={formValues.endTime ? formValues.endTime : undefined}
          formError={errors.startTime}
        />
        <Form.Field
          label="End date & time"
          name="endTime"
          control="date-time"
          autoFocus={false}
          date={true}
          time={true}
          formControl={formControl}
          rules={{
            validate: (value) => {
              if (getValues().startTime && !value) {
                return 'End Time must be specified'
              }
              return true
            },
          }}
          min={
            formValues.startTime ? formValues.startTime : getNearestHalfHour()
          }
          formError={errors.endTime}
        />
      </Form.FieldRow> */}
    </div>
  )
}

function getDefaultValues(bundle) {
  return {
    name: bundle.name,
    // startTime: bundle?.startTime ? new Date(bundle.startTime * 1000) : null,
    // endTime: bundle?.endTime ? new Date(bundle.endTime * 1000) : null,
  }
}
