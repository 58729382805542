import React from 'react'
import Header from 'containers/Home/Header'
import Sidebar from 'containers/Home/Sidebar'
import Text from 'components/Text'
import ChatWithUs from 'components/ChatWithUs'
import classNames from 'classnames'
import Countdown from 'react-countdown'

import styles from 'containers/Home/styles.module.css'

export default function Maintenance({ account, store }) {
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <div className="flex flex-col justify-center w-full">
          <div className="text-center">
            <Text.ScreenHeading>
              Maintainence Window is now complete.
            </Text.ScreenHeading>
          </div>
          <div className="text-center">
            <Text.SubText>
              Please reload the window to continue using the application. Please{' '}
              <ChatWithUs>contact support </ChatWithUs> for any issue.
            </Text.SubText>
          </div>
        </div>
      )
    } else {
      // Render a countdown
      return (
        <div className="flex flex-col justify-center w-full">
          <div className="text-center">
            <Text.ScreenHeading marginBottom={0}>
              Currently Under Maintenance
            </Text.ScreenHeading>
          </div>
          <div className="text-center">
            <Text.SubText>
              We'll be back shortly. Widgets should work as expected on the
              storefront during this time. For anything urgent please{' '}
              <ChatWithUs>contact support via chat</ChatWithUs>.
            </Text.SubText>
          </div>
          <div className="flex flex-row justify-center p-4 text-center ">
            <div className="flex flex-row">
              <div className="p-2 mr-4 text-white rounded bg-primary-400">
                <div className="px-4 py-2 m-2 text-3xl rounded bg-primary">
                  {('0' + hours).slice(-2)}
                </div>
                <div className="mt-2">Hours</div>
              </div>
              <div className="p-2 mr-4 text-white rounded bg-primary-400">
                <div className="px-4 py-2 m-2 text-3xl rounded bg-primary">
                  {('0' + minutes).slice(-2)}
                </div>
                <div className="mt-2">Minutes</div>
              </div>
              <div className="p-2 mr-4 text-white rounded bg-primary-400">
                <div className="px-4 py-2 m-2 text-3xl rounded bg-primary">
                  {('0' + seconds).slice(-2)}
                </div>
                <div className="mt-2">Seconds</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={classNames(styles.container, 'flex flex-1 bg-gray-200')}>
      <Sidebar />
      <Header account={account.data} store={store.data} />
      <Countdown
        date={account.data.maintenanceEndsAt * 1000}
        renderer={renderer}
      />
    </div>
  )
}
