import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { shopifyOnlineCallback } from 'api/shopify'
import Loader from 'components/Loader'
import Icons from 'components/Icons'
import Button from 'components/Button'
import { useQueryParams } from 'hooks/router'
import { openChat } from 'utils/external'

export default function OnlineCallback() {
  const queryParams = useQueryParams()
  const history = useHistory()
  const [error, setError] = useState(false)

  useEffect(() => {
    async function callback() {
      try {
        const {
          data: { next },
        } = await shopifyOnlineCallback(queryParams)
        if (next.charAt(0) == '/') {
          history.push(`${next}${window.location.search}`)
        } else {
          const url = new URL(next)
          if (url.origin == window.location.origin) {
            history.push(
              `${url.origin}${url.pathname}${window.location.search}`
            )
          } else {
            window.location.href = next
          }
        }
      } catch (err) {
        setError(true)
      }
    }
    callback()
  }, [])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 bg-gray-200">
        <div className="flex flex-col items-center justify-center">
          <Icons.Glood className="w-32 text-primary" />
          <span className="mt-8 text-lg">
            There was an error processing your request
          </span>
          <Button onClick={openChat} className="mt-4" size="small">
            Contact Support
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="flex items-center justify-center flex-1">
      <Loader message="Fetching details" />
    </div>
  )
}
