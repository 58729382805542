import React from 'react'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import Loader from 'components/Loader'
import WaitForData from 'components/WaitForData'
import { Select, SelectVersionTwo } from 'components/Form/controls'
import NavigationTabs from 'components/NavigationTabs'
import Breadcrumbs from 'components/Breadcrumbs'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import Icons from 'components/Icons'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { getAccountData } from 'utils/account'
import * as QueryUtils from 'utils/query'
import { isFeatureEnabled } from 'utils/features'
import { _find } from 'utils/lodash'
import { BUNDLES } from 'constants/features'

import useBundleDetails from './hooks/useBundleDetails'
import Overview from '../BundleBuilder/Review/Overview'
import Analytics from './Analytics'
import Events from './Events'
import { getBundleStatusOptions } from '../utils'

export default function BundleDetails({ onEditBundle }) {
  const { bundleDetailsQuery, bundleUpdating, updateBundle } =
    useBundleDetails()
  const accountData = getAccountData()
  const featureEnabled = isFeatureEnabled(accountData, BUNDLES)
  const openModal = useOpenModal()
  const openToast = useToast()

  return (
    <WaitForData objects={[bundleDetailsQuery]} isQueries={true}>
      {() => {
        const bundleData = QueryUtils.data(bundleDetailsQuery)?.data
        const bundleStatusOptions = getBundleStatusOptions(
          bundleData.status,
          accountData
        )
        return (
          <div className="flex flex-col items-stretch">
            <Breadcrumbs
              items={[
                { label: 'Bundles', path: '/bundles' },
                { label: bundleData.name, path: `/bundles/${bundleData.id}` },
              ]}
              className="py-2 pl-8"
            />
            <Box className="mb-4">
              <div className="flex flex-row items-center justify-between">
                <span className="text-lg font-semibold">{bundleData.name}</span>
                <div className="flex flex-row items-stretch">
                  <div className="flex-1 w-32 mr-5">
                    {bundleUpdating ? (
                      <Loader className="w-4 h-full mr-3 text-primary" />
                    ) : (
                      <SelectVersionTwo
                        label={
                          <div
                            className={classNames(
                              'flex flex-row py-1.5 px-4 rounded border border-gray-400 h-full w-full justify-between items-center',
                              {
                                'pointer-events-none opacity-50':
                                  bundleUpdating,
                              }
                            )}
                          >
                            <span>
                              {
                                _find(bundleStatusOptions, function (o) {
                                  return o.value === bundleData.status
                                }).label
                              }
                            </span>
                            <Icons.ChevronDown className="w-3 ml-2 text-current" />
                          </div>
                        }
                        options={bundleStatusOptions}
                        onChange={(selectedOption) => {
                          {
                            const status = selectedOption.value
                            if (!bundleUpdating) {
                              if (
                                status === 'active' &&
                                status != bundleData.status
                              ) {
                                if (
                                  featureEnabled &&
                                  bundleData.items.products?.length > 0
                                ) {
                                  updateBundle({
                                    id: bundleData.id,
                                    status,
                                  })
                                } else if (featureEnabled) {
                                  openToast({
                                    type: 'error',
                                    text: 'Empty bundles cannot be published',
                                  })
                                } else {
                                  openModal(
                                    <UpgradeAccountModal
                                      featureToEnable={BUNDLES}
                                      account={{ data: accountData }}
                                    />
                                  )
                                }
                              } else {
                                updateBundle({
                                  id: bundleData.id,
                                  status,
                                })
                              }
                            }
                          }
                        }}
                        value={_find(bundleStatusOptions, function (o) {
                          return o.value === bundleData.status
                        })}
                      />
                    )}
                  </div>
                  <Button onClick={() => onEditBundle(bundleData)}>Edit</Button>
                </div>
              </div>
            </Box>
            <Box>
              <NavigationTabs
                tabs={TABS}
                visibilityCheckParams={[]}
                tabProps={{
                  bundle: bundleData,
                  onEdit: (screen) => {
                    onEditBundle(bundleData, screen)
                  },
                  accountData,
                  mode: 'details',
                }}
              />
            </Box>
          </div>
        )
      }}
    </WaitForData>
  )
}

const STATUS_TO_CLASS_NAME_MAP = {
  active: 'bg-primary-200 text-primary',
  draft: 'bg-red-100 text-red-600',
  paused: 'bg-gray-200 text-black',
  archived: 'bg-gray-200 text-black',
}

const TABS = [
  {
    label: 'Overview',
    value: 'overview',
    component: Overview,
    root: true,
  },
  // {
  //   label: 'Analytics',
  //   value: 'analytics',
  //   component: Analytics,
  // },
  // {
  //   label: 'Events',
  //   value: 'events',
  //   component: Events,
  // },
]
