import React from 'react'
import classNames from 'classnames'

import { storeScriptTagEnabled } from 'utils/account'
import Button from 'components/Button'
import { APP_EMBED_ENABLE_HELP_LINK } from 'constants/support'
import useAppEmbedCard, { EMBED_BLOCK_STATES } from './hooks'

export default function AppEmbedCard({
  className,
  account,
  onEnabled,
  checkStorefront = false,
}) {
  const { state, handleConfigure, appEmbedLink } = useAppEmbedCard({
    account,
    checkStorefront,
    onEnabled,
  })

  // Do not render if script tag is enabled or events are being received.
  if (
    [
      EMBED_BLOCK_STATES.LOADING,
      EMBED_BLOCK_STATES.ENABLED_ON_START,
      EMBED_BLOCK_STATES.SHOP_STOREFRONT_ENABLED,
    ].includes(state) ||
    storeScriptTagEnabled(account.data)
  ) {
    return null
  }

  return state === EMBED_BLOCK_STATES.DISABLED ? (
    <div
      className={classNames(
        'flex flex-row items-start justify-between px-3 pt-3 pb-2 text-red-600 bg-red-200 rounded-sm font-medium',
        className
      )}
    >
      <span className="mr-4 text-sm">
        Personalized Recommendations is{' '}
        <span className="font-bold">not active on your store</span>. Enable the
        App Embed block via your Shopify Theme editor. This action will not
        affect existing widgets or your store theme.
      </span>
      <div className="flex flex-col flex-shrink-0">
        <Button
          color="red"
          className="flex-shrink-0"
          size="small"
          onClick={() => handleConfigure()}
        >
          {appEmbedLink ? 'Click to Configure' : 'See how to enable'}
        </Button>
        {appEmbedLink && (
          <a
            href={APP_EMBED_ENABLE_HELP_LINK}
            className="mt-1 ml-auto underline text-xxs"
            target="_blank"
          >
            Read more here
          </a>
        )}
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        'flex flex-row items-center px-3 py-2 text-green-600 bg-green-100 rounded-sm font-medium',
        className
      )}
    >
      Personalized Recommendations is now active on your store. You can proceed
      ahead with other actions.
    </div>
  )
}
