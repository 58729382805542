import React from 'react'
import classNames from 'classnames'


export default function Text({ className, children }) {
  return <span color="text-gray-800 text">{children}</span>
}

Text.ScreenHeading = ({ className='', children, marginBottom=5}) => (
  <h3 className={classNames("text-sm font-bold text-gray-800 uppercase", className, `mb-${marginBottom}`)}>{children}</h3>
)

Text.SubText = ({children, marginBottom=0}) => (
  <span className={classNames("text-xs text-gray-600", `mb-${marginBottom}`)}>{children}</span>
)

Text.Strong = ({children}) => (
  <span className="">{children}</span>
)
