import React from 'react'
import Icons from 'components/Icons'
import classNames from 'classnames'


export default function EmptyState({ text, className }) {
  return (
    <div className={classNames("flex w-full mt-32", className)}>
      <div className="flex flex-col w-full items-center justify-start px-3 py-6 text-center">
        <div className="flex justify-center w-full mb-2">
          <Icons.Influencer className="w-32 my-3 fill-current align-center"></Icons.Influencer>
        </div>
        <div className="pb-2 mb-3 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
          {text}
        </div>
      </div>
    </div>
  )
}
