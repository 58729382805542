import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'

import Box from 'components/Box'
import Icons from 'components/Icons'
import { pageLabelFromType } from 'utils/constants'

export default function Pages({
  selectedPages = [],
  setSelectedPages,
  className,
  allPages,
}) {
  function addOrRemovePage(type) {
    if (selectedPages.includes(type)) {
      setSelectedPages(selectedPages.filter((pageType) => pageType != type))
    } else {
      setSelectedPages(selectedPages.concat(type))
    }
  }
  return (
    <Listbox value={selectedPages} onChange={addOrRemovePage}>
      <div className="relative">
        <Listbox.Button className={className}>
          <div className="flex flex-row items-center justify-between w-48 px-5 py-2 overflow-x-auto bg-white border border-gray-400 rounded shadow cursor-pointer">
            {selectedPages.length === 0 ? (
              <span>All pages</span>
            ) : (
              <span>Selected Pages</span>
            )}
            <Icons.ChevronDown className="w-4" />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute z-10 w-full py-1 mt-3 rounded-md shadow-lg"
            style={{ width: '20rem', left: '-35%' }}
          >
            <Box className="w-full">
              <div className="flex flex-col">
                <div className="flex flex-row flex-wrap mb-3">
                  {selectedPages.map((pageType) => {
                    const pageLabel = pageLabelFromType(pageType)
                    return (
                      <div
                        key={pageType}
                        className="flex flex-row items-center justify-start px-2 py-1 mb-1 mr-2 text-sm bg-gray-400 rounded"
                      >
                        <span className="pr-1">{pageLabel}</span>
                        <Icons.Close
                          className="w-2 cursor-pointer"
                          onClick={() => {
                            addOrRemovePage(pageType)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className="flex flex-col w-full h-64 mt-3 overflow-y-auto">
                  {allPages.length > 0 ? (
                    allPages.map((page) => {
                      return (
                        <Listbox.Option key={page.value} value={page.value}>
                          <div
                            className="flex flex-col px-2 py-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                              addOrRemovePage(page.value)
                            }}
                          >
                            <div className="flex flex-row items-center justify-between mb-2">
                              <span className="text-base font-semibold">
                                {page.label}
                              </span>
                              {selectedPages.includes(page.value) && (
                                <Icons.CircleTickThin className="w-4 text-green-700" />
                              )}
                            </div>
                          </div>
                        </Listbox.Option>
                      )
                    })
                  ) : (
                    <div className="flex items-center justify-center w-full h-full mb-3">
                      No pages to display
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
