import React from 'react'

import Button from 'components/Button'
import Icons from 'components/Icons'
import SlideOver from 'components/SlideOver'

import FilterField from './Field'

export default function ResourceListFilters({ filters }) {
  return (
    <SlideOver open={filters.sidebarOpen} onClose={filters.closeSidebar}>
      <div className="relative flex flex-col items-stretch flex-1">
        <header className="px-4 py-4 border-b border-gray-200 sm:px-6">
          <div className="flex items-start justify-between space-x-3">
            <h2 className="text-lg font-medium leading-7 text-gray-900">
              Apply Filters
            </h2>
            <div className="flex items-center h-7">
              <button
                aria-label="Close filters panel"
                className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500"
                onClick={filters.closeSidebar}
              >
                <Icons.Close className="w-6 h-6 text-current" />
              </button>
            </div>
          </div>
        </header>
        <div className="flex flex-col items-stretch flex-1 px-4 mb-8 overflow-y-auto sm:px-6">
          {filters.resourceSchema.sections.map((section) => (
            <FilterSection
              key={section.name}
              section={section}
              filters={filters}
            />
          ))}
        </div>
        <footer className="absolute bottom-0 left-0 right-0 z-50 flex justify-end flex-shrink-0 p-3 bg-white border-t border-gray-200">
          <Button
            variant="bordered"
            color="gray"
            size="small"
            onClick={filters.clearAll}
          >
            Clear All
          </Button>
        </footer>
      </div>
    </SlideOver>
  )
}

function FilterSection({ section, filters }) {
  const filterableFieldsInSection = section.attributes.filter(
    (attribute) => filters.fields[attribute]
  )
  return filterableFieldsInSection.length ? (
    <div className="flex flex-col items-stretch self-stretch mt-4">
      <span className="mb-1 text-xs text-gray-600 uppercase">
        {section.name}
      </span>
      <div className="flex flex-col items-stretch flex-1 space-y-2">
        {filterableFieldsInSection.map((k) => {
          const field = { ...filters.fields[k], key: k }
          return <FilterField key={k} field={field} filters={filters} />
        })}
      </div>
    </div>
  ) : null
}
