import React from 'react'

import EditorForm from 'components/EditorForm'
import { DISCOUNT_TYPES } from 'constants/discount'
export default function Discount({ threshold, handleUpdate }) {
  const discountType = threshold.settings.type
  return (
    <div className="flex flex-col mt-6">
      <EditorForm
        layout={LAYOUT.concat([
          ...(discountType === 'AMOUNT'
            ? [
                {
                  key: 'amount',
                  label: 'Discount Amount',
                  controlType: 'currency',
                },
              ]
            : [
                {
                  key: 'amount',
                  label: 'Discount Amount',
                  controlType: 'percentage',
                },
              ]),
        ])}
        value={threshold.settings}
        handleUpdate={handleUpdate}
      />
    </div>
  )
}

const LAYOUT = [
  {
    key: 'type',
    label: 'Discount Type',
    controlType: 'single_select',
    options: Object.keys(DISCOUNT_TYPES).map((type) => DISCOUNT_TYPES[type]),
  },
]
