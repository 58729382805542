import React from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Box from 'components/Box'
import Button from 'components/Button'
import InfiniteScroll from 'components/InfiniteScroll'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Badge from 'components/Badge'
import Tooltip from 'components/Tooltip'
import Icons from 'components/Icons'
import { Select } from 'components/Form/controls'
import { useQueryParams } from 'hooks/router'
import { useApi } from 'hooks/api'
import { _uniqBy } from 'utils/lodash'
import { optimisedImage } from 'utils/shopify'

export default function ProductSearch({
  onProductSelect,
  widgetType,
  onGlobalConfiguration,
  onClose,
  store,
}) {
  const { shop } = useQueryParams()
  const productSearch = useApi('recommendations/', {
    shop,
    page: 1,
    count: 20,
    recommendation_type: widgetType,
    q: '',
  })

  const [productSearchQuery, setProductSearchQuery] = React.useState('')
  const [productFilter, setProductFilter] = React.useState(
    PRODUCT_FILTER_OPTIONS[0].value
  )

  const getUpdatedSearchResults = useDebouncedCallback(() => {
    productSearch.get({ q: productSearchQuery })
  }, 300)

  function onQueryChange(event) {
    const query = event.target.value
    setProductSearchQuery(query)
    getUpdatedSearchResults.callback()
  }

  function onFilterChange(event) {
    const filter = event.target.value
    setProductFilter(filter)

    productSearch.get({ q: productSearchQuery })
  }

  if (!productSearch.data) {
    return null
  }

  return (
    <React.Fragment>
      <div className="flex flex-col mb-3">
        {store && !store.data?.productsSyncedAt && (
          <div className="flex mb-3">
            <Badge color="yellow" spacing="regular">
              <Loader.Circle color="gray" />
              <span className="ml-2">
                Product sync in progress. You might not be able to search all
                products
              </span>
            </Badge>
          </div>
        )}
        {onClose && (
          <span
            className="mt-1 mb-2 text-sm underline cursor-pointer text-primary"
            onClick={onClose}
          >
            ← Go Back
          </span>
        )}
        <span className="flex flex-row justify-between">
          <span className="text-xl font-semibold">Choose product</span>
          {onGlobalConfiguration && (
            <div>
              <span
                className="text-sm underline cursor-pointer text-primary"
                onClick={onGlobalConfiguration}
              >
                <Tooltip
                  position="left"
                  content={`These products will be shown on all Product Pages after Manual Recommendations`}
                >
                  Configure Global Recommendations →
                </Tooltip>
              </span>
            </div>
          )}
        </span>
        <span className="mt-1 text-xs text-gray-600">
          You can setup Product to Product recommendations from this section.
          First choose the parent product by clicking configure on any of the
          products from the list below. Then you can add manual recommendations
          for that product.
        </span>
        {store && store.data?.onboarding?.completed && (
          <span className="self-start p-1 px-2 my-3 text-sm text-gray-900 bg-white">
            Have large number of products ?{' '}
            <Link
              to={'/recommendationconfig/bulk-upload/'}
              className="font-semibold underline text-primary"
            >
              Click here for Bulk Upload Configuration
            </Link>{' '}
          </span>
        )}
      </div>
      <div className="flex flex-row items-center">
        <input
          type="text"
          value={productSearchQuery}
          onChange={onQueryChange}
          className="flex-1 block px-3 py-3 text-sm leading-tight bg-white border border-gray-400 rounded shadow cursor-pointer hover:border-gray-500 focus:outline-none focus:shadow-outline"
          placeholder="Cannot find product? Search by name here..."
        />
        {false && (
          <Select
            options={PRODUCT_FILTER_OPTIONS}
            value={productFilter}
            className="py-3 ml-3 text-xs"
            onChange={onFilterChange}
          />
        )}
      </div>
      <InfiniteScroll
        onLoadMore={() => {
          productSearch.fetchMore((existingData, newData) => {
            return {
              ...existingData,
              ...newData,
              products: _uniqBy(
                [...existingData.products, ...(newData.products || [])],
                (p) => p.productId
              ),
            }
          })
        }}
      >
        {productSearch.loading ? (
          <Loader className="mt-3" />
        ) : !productSearch.data.products.length && productSearchQuery ? (
          <div className="flex flex-col items-center">
            <Icons.Search className="w-12 h-12 mt-3 ml-2 opacity-25" />
            <span className="mt-4 text-center text-gray-600">
              {' '}
              No products found matching "{productSearchQuery}"
            </span>
          </div>
        ) : (
          <div className="flex flex-row flex-wrap mt-4 -mx-2">
            {productSearch.data.products.map((product) => {
              return (
                <ProductCard
                  product={product}
                  key={product.productId}
                  onClick={() => onProductSelect(product)}
                />
              )
            })}
          </div>
        )}
        {!productSearch.loading && productSearch.loadingMore && (
          <Loader className="mt-3" />
        )}
      </InfiniteScroll>
    </React.Fragment>
  )
}

const PRODUCT_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Only products with manual recommendations', value: 'manual-only' },
  {
    label: 'Only products with automatic recommendations',
    value: 'automatic-only',
  },
]

function ProductCard({ product, onClick }) {
  return (
    <div className="w-1/2 px-2 mb-4" onClick={onClick}>
      <Box
        spacing="small"
        className="flex flex-row h-full p-6 cursor-pointer hover:bg-gray-100"
      >
        <img
          src={optimisedImage({ baseUrl: product.image, size: 150 })}
          className="object-cover w-24 h-24 mr-4"
        />
        <div className="flex flex-col flex-1">
          <span className="pb-2 mb-2 text-sm font-semibold leading-snug border-b border-gray-200 notranslate">
            {product.title}
          </span>
          <span>
            <span className="text-gray-600">Manual Recommendations: </span>
            <span className="font-semibold">
              {product.manualRecommendationCount}
            </span>
          </span>
          <span>
            <span className="text-gray-600">Automatic Recommendations: </span>
            <span className="font-semibold">
              {product.automaticRecommendationCount}
            </span>
          </span>
          <Button
            size="xSmall"
            className="self-start mt-3"
            variant="bordered"
            onClick={onClick}
          >
            View/Edit Recommendations
          </Button>
        </div>
      </Box>
    </div>
  )
}
