import React, { useState } from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import { experienceSave } from 'api/experiences'

export default function ConfirmArchiveModal({
  experience,
  close,
  updateLocalExperiences,
  shop,
}) {
  const { isDefault, name } = experience
  const [loading, setLoading] = useState(false)

  async function handleArchive() {
    setLoading(true)
    var { data: updatedExperiences } = await experienceSave(
      experience.id,
      {
        status: 'archieved',
      },
      { shop }
    )
    updateLocalExperiences(updatedExperiences)
    setLoading(false)
    close()
  }
  return (
    <Box>
      <Box.Heading>{name} - Confirm Archive</Box.Heading>
      <div className="flex flex-col items-start ">
        {isDefault ? (
          <span>
            {name} cannot be archived as this is the default experience.
          </span>
        ) : (
          <span>
            Are you sure you wish to archive this experience ? This action
            cannot be reversed.
          </span>
        )}
        <div className="flex flex-row items-center justify-end w-full mt-4">
          <Button size="small" variant="bordered" onClick={close}>
            Close
          </Button>
          {!isDefault && (
            <Button
              loading={loading}
              size="small"
              className="ml-3"
              onClick={handleArchive}
            >
              Archive
            </Button>
          )}
        </div>
      </div>
    </Box>
  )
}
