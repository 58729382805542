import React, { useState } from 'react'
import classNames from 'classnames'

import api from 'api'
import Box from 'components/Box'
import Button from 'components/Button'
import Callout from 'components/Callout'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useToast } from 'components/Toast'
import { TRANSLATIONS } from 'constants/features'
import { useOpenModal } from 'components/Modal'
import { _uniqBy, _get } from 'utils/lodash'
import { isFeatureEnabled } from 'utils/features'
import { labelFromLocale } from 'utils/languages'

export default function Translations({ account, store }) {
  const { name: shop } = account.data
  const { primaryLocale = 'en' } = account.data.shop
  const publishedLocales = _uniqBy(
    [primaryLocale, ..._get(store, 'data.publishedLocales', [])],
    (i) => i
  )
  const canSyncTranslations = isFeatureEnabled(account.data, TRANSLATIONS)

  const openModal = useOpenModal()
  const openToast = useToast()
  const [syncInProgress, setSyncInProgress] = useState(false)

  async function triggerTranslationsSync() {
    setSyncInProgress(true)
    try {
      await api.post('/translations/sync/', {}, { params: { shop } })
      await store.get()
      openToast({
        type: 'success',
        text: 'Translations sync has been successfully triggered. It will be completed in few minutes.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Translations sync could not be successfully triggered. Please contact support@glood.ai',
      })
    }
    setSyncInProgress(false)
  }

  return (
    <div className="flex flex-col items-start">
      {!canSyncTranslations && (
        <Callout className="px-4 py-2 mb-6 bg-white" noHoverEffect>
          <span className="text-base font-medium">
            Multi language support is not enabled for your store.
          </span>
          <Button
            size="small"
            className="ml-6"
            onClick={() => {
              openModal(
                <UpgradeAccountModal
                  account={account}
                  featureToEnable={TRANSLATIONS}
                />
              )
            }}
          >
            Enable
          </Button>
        </Callout>
      )}
      <Box
        className={classNames('mb-3 flex flex-col items-stretch w-full', {
          'opacity-50 pointer-events-none': !canSyncTranslations,
        })}
      >
        <span className="mb-1 text-base font-semibold uppercase">
          Product Titles
        </span>
        <span className="pb-1 mb-6 text-sm text-gray-600 border-b border-gray-200">
          Product titles are available in the following locales for your store.
        </span>
        <div className="flex flex-row items-start">
          <ul className="flex flex-col items-stretch flex-1 ml-6 list-disc">
            {publishedLocales.map((locale) => {
              return (
                <li key={locale} className="mb-1 font-semibold text-gray-800">
                  {labelFromLocale(locale)}({locale})
                </li>
              )
            })}
          </ul>
          <div className="relative flex flex-col" style={{ maxWidth: 190 }}>
            <Button
              onClick={triggerTranslationsSync}
              loading={syncInProgress}
              size="regular"
              className="ml-auto"
            >
              Sync From Shopify
            </Button>
            <span className="mt-1 text-right text-gray-600 text-xxs">
              Trigger sync to fetch latest published languages from Shopify.
            </span>
          </div>
        </div>
      </Box>
      <Box
        className={classNames('flex flex-col items-stretch w-full', {
          'opacity-50 pointer-events-none': !canSyncTranslations,
        })}
      >
        <span className="mb-1 text-base font-semibold uppercase">
          In-App Translations
        </span>
        <span className="mb-3 text-sm text-gray-600">
          Please refer to the following guide to setup translations for Widget
          Titles & other texts such as "Add to cart", "Total Price: " etc.
        </span>
        <a
          href="https://app.intercom.com/a/apps/k29sf3ak/articles/articles/5127238/show?language=en"
          target="_blank"
          className="self-start px-2 py-1 text-sm text-white rounded bg-primary hover:bg-primary-800"
        >
          Open In-App Translation Guide
        </a>
      </Box>
    </div>
  )
}
