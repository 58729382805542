import React, { useEffect } from 'react'
import { noTransformApi } from 'api'

import WaitForData from 'components/WaitForData'
import Text from 'components/Text'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import Loader from 'components/Loader'
import CodeEditor from 'components/CodeEditor'
import { escapeSpecialChars } from 'utils/json'
import Box from 'components/Box'
import Form from 'components/Form'

export default function ConfigData({ account, store }) {
  const openToast = useToast()
  const [configData, setConfigData] = React.useState('{}')
  const [configStatus, setConfigStatus] = React.useState('fetching')
  const { name: shop } = account.data
  const skipParentRef = React.useRef()
  async function fetchConfig() {
    const response = await noTransformApi.get(`/config/?shop=${shop}`)
    setConfigStatus('fetched')
    setConfigData(JSON.stringify(response.data.data, null, 4))
    skipParentRef.current.checked = store.data.extra["skipParentProduct"]
  }

  async function saveConfig() {
    setConfigStatus('saving')
    const response = await noTransformApi.post(`/config/?shop=${shop}`, {
      data: JSON.parse(escapeSpecialChars(configData)),
    })
    setConfigData(JSON.stringify(response.data.data, null, 4))
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  async function onSave() {
    try {
      await saveConfig()
      openToast({
        type: 'success',
        text: 'Config has been updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error in saving config. Please ensure input is valid JSON',
      })
    }
    setConfigStatus('fetched')
  }

  async function saveShopExtra() {
    setConfigStatus('saving')
    await store.update(store.data)
  }

  const onSaveExtra = async () => {
    store.data.extra["skipParentProduct"] = skipParentRef.current.checked
    try {
      await saveShopExtra()
      openToast({
        type: 'success',
        text: 'Shop has been updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error in saving settings.',
      })
    } finally {
      setConfigStatus('fetched')
    }
  }

  if (configStatus === 'fetching') {
    return (
      <div className="flex items-center justify-center flex-1 h-full">
        <Loader message={'Fetching advanced setting'} />
      </div>
    )
  }

  return (
    <WaitForData
      objects={[store]}
      loadingMessage="Fetching account setting"
      errorMessage="Trouble fetching account settings"
    >
      {() => (
        <div className="flex flex-col items-stretch">
          <Text.ScreenHeading marginBottom={1}>
            Advanced Recommendation Setting
          </Text.ScreenHeading>
          <Text.SubText marginBottom={5}>
            Edit this setting only if you are aware of it's functioning. Any
            breaking changes can lead to errors in recommendation generation and
            widgets. For any assistance please write to support@glood.ai
          </Text.SubText>
          <div className="flex flex-col items-start flex-1 w-full">
            <Box
              className="flex flex-col items-stretch w-full mb-3"
            >
              <div className="flex flex-row mb-5 text-sm font-semibold text-gray-800 uppercase">
                Advanced Settings
              </div>
              <Form.Field
                name='skipParentProduct'
                control="checkbox"
                label="Skip Parent Product"
                ref={skipParentRef}
                className='w-full'
                helpText='Parent product will be skipped for widgets that have amazon bought together layout'
              />
              <div className="flex flex-row justify-end">
                <Button size="small" onClick={onSaveExtra} loading={configStatus === 'saving'}>Save</Button>
              </div>
            </Box>
            <Box
              className="flex flex-col items-stretch w-full mb-3"
            >
              <div className="flex flex-row mb-5 text-sm font-semibold text-gray-800 uppercase">
                Store Data
              </div>
              <div className="flex flex-row flex-wrap w-full notranslate">
                <CodeEditor
                  placeholder="You can add ConfigData to the shop."
                  mode="json"
                  value={configData}
                  onChange={(value) => setConfigData(value)}
                  name="account_config_editor"
                />
              </div>
              <div className='flex flex-row justify-start'>
                <Button
                  size="small"
                  onClick={onSave}
                  loading={configStatus === 'saving'}
                >
                  Save
                </Button>
              </div>
            </Box>
          </div>
        </div>
      )}
    </WaitForData>
  )
}
