import React from 'react'
import classNames from 'classnames'
import format from 'date-fns/format'

import { useApi } from 'hooks/api'
import {
  getTriggerTypeLabelFromType,
  popupRecommendationLabelFromType,
} from 'containers/Popups/utils'
import Icons from 'components/Icons'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import WaitForData from 'components/WaitForData'
import { isFeatureEnabled } from 'utils/features'
import { POPUPS } from 'constants/features'
import { pageLabelFromType } from 'utils/constants'
import { SCREENS } from '../../hooks/usePopupBuilder'

export default function Overview({
  onEdit,
  popup,
  mode = 'builder',
  accountData,
  builder,
}) {
  const canPublish = isFeatureEnabled(accountData, POPUPS)
  const isBuilder = mode == 'builder'
  if (!popup) {
    return null
  }
  return (
    <div className="flex flex-col items-stretch pb-10">
      {!canPublish && isBuilder && (
        <div
          className={classNames('flex flex-col items-stretch mb-6', {
            'px-2': mode === 'details',
          })}
        >
          <UpgradeAccountModal
            featureToEnable={POPUPS}
            account={{ data: accountData }}
            mode="banner"
          />
        </div>
      )}
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 border-b border-gray-200',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <DetailsReview popup={popup} onEdit={() => onEdit('details')} />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <TriggerReview
          popup={popup}
          accountData={accountData}
          onEdit={() => onEdit('trigger')}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <TemplateReview
          popup={popup}
          accountData={accountData}
          onEdit={() => onEdit('template')}
        />
      </div>
    </div>
  )
}

export function DetailsReview({ popup, accountData, onEdit }) {
  const DATE_FORMAT = 'dd-MM-yyyy hh:mmaa'
  const isCompleted = SCREENS.find(
    (screen) => screen.name === 'details'
  ).complete(popup)
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick
          className={classNames('w-6 mr-2', {
            'text-primary': isCompleted,
            'text-gray-400': !isCompleted,
          })}
        />
        <span className="text-lg font-semibold text-gray-800">Details</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <div className="flex flex-col mr-32">
          <span className="mb-1 text-sm text-gray-600">Name</span>
          <span className="font-medium text-gray-800">{popup.name}</span>
        </div>
        <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">
            Start Date &#38; Time
          </span>
          <span className="font-medium text-gray-800">
            {popup.startTime
              ? format(popup.startTime * 1000, DATE_FORMAT)
              : 'N/A'}
          </span>
        </div>
        <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">
            End Date &#38; Time
          </span>
          <span className="font-medium text-gray-800">
            {popup.endTime ? format(popup.endTime * 1000, DATE_FORMAT) : 'N/A'}
          </span>
        </div>
        <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">
            Recommendation Type
          </span>
          <span className="font-medium text-gray-800">
            {popup.recommendationType
              ? popupRecommendationLabelFromType(popup.recommendationType)
              : 'N/A'}
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

function TriggerReview({ accountData, popup, onEdit }) {
  const isCompleted = SCREENS.find(
    (screen) => screen.name === 'trigger'
  ).complete(popup)
  return (
    <div className="flex flex-row items-start">
      <Icons.CircleTick
        className={classNames('w-6 mr-2', {
          'text-primary': isCompleted,
          'text-gray-400': !isCompleted,
        })}
      />
      <div className="flex flex-col items-stretch w-full">
        <div className="flex flex-row items-center justify-between mb-2">
          <span className="text-lg font-semibold text-gray-800">Trigger</span>
          <Button size="small" variant="light" onClick={onEdit}>
            Edit
          </Button>
        </div>
        <div className="flex flex-row items-start w-full mb-6">
          <div className="flex flex-col mr-32">
            <span className="mb-1 text-sm text-gray-600">Trigger Type</span>
            <span className="font-medium text-gray-800">
              {Object.values(popup.triggers)
                .map((trigger) => {
                  return getTriggerTypeLabelFromType(trigger.type)
                })
                .join(', ')}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-start w-full mb-6">
          <div className="flex flex-col mr-32">
            <span className="mb-1 text-sm text-gray-600">Inclusions</span>
            <div className="flex flex-col">
              {popup.inclusions?.map((inclusion, index) => {
                return (
                  <div
                    className="flex items-center"
                    key={`${inclusion.pageType}_${index}`}
                  >
                    <div className="flex items-center justify-between mr-3 w-28">
                      <span>{pageLabelFromType(inclusion.pageType)}</span>
                      <span>:</span>
                    </div>
                    <span className="break-words">
                      {inclusion.pageLinks.join(', ')}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start w-full mb-6">
          <div className="flex flex-col mr-32">
            <span className="mb-1 text-sm text-gray-600">Exclusions</span>
            <div className="flex flex-col">
              <span className="break-words">
                {popup.exclusions[0]?.pageLinks.join(', ')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TemplateReview({ popup, onEdit, accountData }) {
  const templates = useApi('templates/', { shop: accountData.name })
  const isCompleted = SCREENS.find(
    (screen) => screen.name === 'template'
  ).complete(popup)
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick
          className={classNames('w-6 mr-2', {
            'text-primary': isCompleted,
            'text-gray-400': !isCompleted,
          })}
        />
        <span className="text-lg font-semibold text-gray-800">Template</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <WaitForData objects={[templates]}>
          {() => {
            const selectedTemplate = templates.data.templates.find(
              (template) => template.id === popup.template
            )
            return (
              <div className="flex flex-col mr-10">
                <span className="mb-1 text-sm text-gray-600">Template</span>
                <div className="flex flex-row items-center">
                  <span className="mr-10 font-medium text-gray-800">
                    {selectedTemplate ? selectedTemplate.name : 'N/A'}
                  </span>
                  {/* <Button size="small" variant="text">
              <div className="flex flex-row items-center">
                <Icons.WidgetView className="w-3" />
                <span className="ml-2">Preview</span>
              </div>
            </Button> */}
                </div>
              </div>
            )
          }}
        </WaitForData>
      </div>
    </React.Fragment>
  )
}
