import React from 'react'
import Badge from 'components/Badge'
import CampaignGeneral from './General'
import CampaignLayout from './Layout'
import CampaignAnalytics from './Analytics'
import Tabs from 'components/Tabs'
import WaitForData from 'components/WaitForData'
import { campaignUpdate as campaignUpdateApi } from 'api/campaigns'
import { campaignLabelFromType, channelLabelFromType } from 'utils/constants'
import { useApi } from 'hooks/api'
import { useRouteMatch } from 'react-router-dom'
import { useToast } from 'components/Toast'

export default function Campaign({ account }) {
  const { name: shop } = account.data
  const {
    params: { campaignId },
  } = useRouteMatch('/campaigns/:campaignId')
  const openToast = useToast()
  const campaigns = useApi('marketing/campaigns/', { shop })
  // const campaign = useApi(`marketing/campaigns/${campaignId}/`, { shop })

  async function saveCampaign(campaignId, data) {
    try {
      await campaignUpdateApi(campaignId, data, { shop })
      openToast({
        type: 'success',
        text: 'Campaign saved successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in saving the campaign.',
      })
    }
  }

  return (
    <WaitForData
      objects={[campaigns]}
      loadingMessage="Fetching campaign data"
      errorMessage="Trouble fetching campaign data"
    >
      {() => {
        const campaign = campaigns.data.campaigns.filter(
          (c) => c.id.toString() === campaignId.toString()
        )[0]

        return campaign ? (
          <div className="flex flex-col items-stretch flex-1">
            <div className="flex flex-row flex-wrap items-center mb-6">
              <h3 className="mr-3 text-2xl font-semibold text-gray-800 lg:text-3xl">
                {campaign.name}
              </h3>
              <Badge
                color="yellow"
                className="mr-1 uppercase"
                spacing="regular"
              >
                {campaignLabelFromType(campaign.recommendationType)}
              </Badge>
              <Badge color="yellow" className="uppercase" spacing="regular">
                {channelLabelFromType(campaign.channelSubType)}
              </Badge>
            </div>
            <Tabs tabs={TABS}>
              {(activeTab) => (
                <activeTab.component
                  campaign={campaign}
                  account={account}
                  onSave={(data) => saveCampaign(campaign.id, data)}
                />
              )}
            </Tabs>
          </div>
        ) : (
          <div className="flex flex-col items-stretch flex-1">
            <span className="mt-6 text-sm text-center text-gray-600">
              Campaign data not found. Please reload the page
            </span>
          </div>
        )
      }}
    </WaitForData>
  )
}

const TABS = [
  {
    label: 'General',
    value: 'general',
    component: CampaignGeneral,
  },
  {
    label: 'Layout',
    value: 'layout',
    tooltip: 'You can update the email template for this campaign.',
    component: CampaignLayout,
  },
  {
    label: 'Analytics',
    value: 'analytics',
    tooltip: 'Keep track of the performance of this campaign.',
    component: CampaignAnalytics,
  },
]
