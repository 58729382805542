import { useState, useEffect } from 'react'

export default function ({ pages, onboardingState }) {
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    if (!onboardingState || !pages.data) {
      return
    }

    const {
      data: { widgetIds = [] },
    } = onboardingState
    const widgets = pages.data.reduce((items, page) => {
      const match = page.widgets.filter(
        (widget) => widgetIds.indexOf(widget.id) !== -1
      )
      return [...items, ...match]
    }, [])

    setWidgets(widgets)
  }, [onboardingState, pages.data])

  return [widgets]
}
