import React from 'react'
import NavigationTabs from 'components/NavigationTabs'
import AppCard from 'components/AppCard'
import Locked from 'components/Locked'
import WaitForData from 'components/WaitForData'
import { useToast } from 'components/Toast'
import { useApi } from 'hooks/api'
import {
  integrationLabelFromType,
  integrationScoreFromType,
} from 'utils/constants'

export default function Integrations({ account, store }) {
  const { name: shop } = account.data
  const openToast = useToast()
  const integrations = useApi('integrations/', { shop })

  const grouped = integrations.data ? groupBy(integrations.data, 'group') : {}
  const TABS = Object.keys(grouped)
    .sort((g1, g2) => {
      const g1Score = integrationScoreFromType(g1) || 0
      const g2Score = integrationScoreFromType(g2) || 0
      return g1Score - g2Score
    })
    .map((group, index) => {
      return {
        ...(index === 0 ? { root: true } : {}),
        label: integrationLabelFromType(group),
        value: group,
        component: IntegrationTab,
      }
    })

  async function onUpdateIntegration(data) {
    try {
      await integrations.update(data)
      openToast({
        type: 'success',
        text: 'Integration Setting updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Integration Setting could not bt saved successfully!',
      })
    }
  }

  return (
    <WaitForData
      objects={[integrations]}
      loadingMessage="Fetching integrations data"
      errorMessage="Trouble fetching integrations data"
    >
      {() => (
        <div className="flex flex-col items-stretch flex-1 mb-56">
          <div className="flex flex-row flex-wrap items-center mb-6">
            <h3 className="text-sm font-bold text-gray-800 uppercase">
              Enabled Integrations
            </h3>
          </div>
          <NavigationTabs
            tabs={TABS}
            tabProps={{
              account,
              onUpdateIntegration,
              integrationsData: integrations.data,
            }}
          />
        </div>
      )}
    </WaitForData>
  )
}

const IntegrationTab = ({
  account,
  integrationsData,
  onUpdateIntegration,
  activeTab,
}) => {
  const group = activeTab.value
  const applications = integrationsData.filter(
    (integration) => integration.group === activeTab.value
  )
  return (
    <Locked
      locked={
        account.data && !account.data.plan.integrationsEnabled.includes(group)
      }
      account={account}
    >
      <div className="flex flex-row flex-wrap items-stretch -mx-2">
        {applications
          .sort((app1, app2) => {
            const app1Score = app1.isRecommended ? 1 : 0
            const app2Score = app2.isRecommended ? 1 : 0

            return app2Score - app1Score
          })
          .map((app) => (
            <div
              className="flex self-auto w-full md:w-1/3 lx:w-1/4"
              key={app.slug}
            >
              <AppCard
                {...app}
                onUpdateIntegration={onUpdateIntegration}
              ></AppCard>
            </div>
          ))}
      </div>
    </Locked>
  )
}

function groupBy(array, property) {
  return array.reduce(function (grp, x) {
    if (!grp[x[property]]) {
      grp[x[property]] = []
    }
    grp[x[property]].push(x)
    return grp
  }, {})
}

// account.data && !account.data.plan.integrationsEnabled.includes(group)
