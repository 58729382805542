import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

export default function ({ to, useNav, ...otherProps }) {
  const location = useLocation()

  let toWithQueryParams = to
  if (typeof to === 'string') {
    // TODO(Dhruv): Handle case when `to` already contains
    // a query fragment.
    toWithQueryParams = `${to}${location.search}`
  } else if (typeof to === 'object') {
    toWithQueryParams = {
      ...to,
      // TODO(Dhruv): Merge location.search with to.search
      search: to.search || location.search,
    }
  }

  const Component = useNav ? NavLink : Link

  return <Component to={toWithQueryParams} {...otherProps} />
}
