import React from 'react'
import humps from 'humps'
import classNames from 'classnames'
import Button from 'components/Button'
import Box from 'components/Box'
import ActionCompletedIcon from 'components/Icons/ActionCompleted'
import { openChat } from 'utils/external'
import { trackEvent } from 'utils/analytics'
import { isStorefrontPasswordEnabled, proxyHost } from 'utils/account'
import * as Tracker from 'utils/tracker'
import './styles.css'

export default function Placement({
  account,
  widget,
  onSave,
  onStart,
  onClose,
  variant,
  buttonSize,
  actionCompleted,
  disabled,
}) {
  const [visible, setVisible] = React.useState(false)
  const [previewFrameKey, setPreviewFrameKey] = React.useState(0)
  const [frameUrl, setFrameUrl] = React.useState(null)
  const {
    shop: { domain },
    links,
    name: shop,
  } = account
  const passwordEnabled = isStorefrontPasswordEnabled(account)

  function handleOpen() {
    setVisible(true)
    typeof onStart === 'function' && onStart()
    // Tracker.record('widget_placement:open')
  }

  function handleClose() {
    setVisible(false)
    setPreviewFrameKey(previewFrameKey + 1)
    typeof onClose === 'function' && onClose()
  }

  React.useEffect(() => {
    const { pageType, id: widgetId } = widget
    try {
      const pageTypeUrl = new URL(links[humps.camelize(pageType)])

      setFrameUrl(
        `https://${domain.split('.').join('---')}.${proxyHost(account)}${
          pageTypeUrl.pathname
        }?rkWidgetId=${widgetId}&rk_preview=true&rk_force_random=true`
      )
    } catch (err) {
      setFrameUrl(null)
    }
  }, [links, shop, widget.id])

  const frame = (
    <StoreFrame
      frameUrl={frameUrl}
      widgetId={widget.id}
      onHide={handleClose}
      onPositionUpdate={onSave}
      key={previewFrameKey}
    />
  )

  let content
  if (variant === 'button') {
    content = (
      <div>
        <Button
          size={buttonSize}
          onClick={frameUrl ? handleOpen : () => {}}
          variant={frameUrl ? 'solid' : 'suppressed'}
          disabled={disabled || passwordEnabled}
          tooltipContent={
            passwordEnabled
              ? 'Placement tool is not supported for password protected stores'
              : frameUrl
              ? ''
              : disabled
              ? ''
              : 'Coming soon. Contact support@glood.ai for manual placement help'
          }
        >
          <div className="flex items-center">
            <span>Update Widget Position</span>
            {actionCompleted !== undefined && frameUrl && (
              <ActionCompletedIcon actionCompleted={actionCompleted} />
            )}
          </div>
        </Button>
        {visible ? frame : null}
      </div>
    )
  } else if (variant === 'box') {
    content = (
      <Box className="mb-5">
        <div className="flex flex-col items-start">
          <span className="mb-3 text-sm font-semibold text-gray-800">
            *Try our new placement tool to update your widget position without
            any code changes.
          </span>
          <Button
            onClick={frameUrl ? handleOpen : () => {}}
            variant={frameUrl ? 'solid' : 'suppressed'}
            disabled={disabled || passwordEnabled}
            tooltipContent={
              frameUrl
                ? ''
                : 'Coming soon. Contact support@glood.ai for manual placement help'
            }
          >
            Update Widget Position
          </Button>
          {passwordEnabled && (
            <span className="mt-3 text-xs font-semibold text-red-600">
              Placement tool is not supported for password protected stores.
              Please disable password protection temporarily to update the
              widget
            </span>
          )}
        </div>
        {visible ? frame : null}
      </Box>
    )
  }

  return content
}

Placement.defaultProps = {
  variant: 'box',
  buttonSize: 'xSmall',
}

const StoreFrame = ({ widgetId, frameUrl, onHide, onPositionUpdate }) => {
  const frameEl = React.useRef(null)
  const [positionData, setPositionData] = React.useState(null)

  function receiveMessage(event) {
    // TODO(Dhruv): Add strict origin check here before passing the data forward
    if (event.data && event.data.widgetId === widgetId) {
      setPositionData({
        location: event.data.location,
        position: event.data.position,
      })
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
    return () => window.removeEventListener('message', receiveMessage, false)
  }, [])

  return (
    <div
      className={classNames(
        'flex flex-col items-stretch text-gray-800 storeFrame storeFrame--visible'
      )}
    >
      <div className="flex flex-row items-center justify-between h-12 px-5 border-b">
        <span>
          Not able to get your placement correct ? Or Not happy with styling of
          widgets?{' '}
          <span
            className="underline cursor-pointer text-primary"
            onClick={() => {
              onHide()
              openChat()
            }}
          >
            Chat with us
          </span>
        </span>
        <div className="flex flex-row ml-3">
          <Button
            size="small"
            className="mr-3"
            onClick={() => {
              if (positionData) {
                onPositionUpdate(positionData)
                Tracker.record('widget_placement:position_saved')
              }
              onHide()
            }}
          >
            Save Position
          </Button>
          <Button
            size="small"
            variant="bordered"
            onClick={() => {
              onHide()
              Tracker.record('widget_placement:closed')
            }}
          >
            Cancel & Close
          </Button>
        </div>
      </div>
      <iframe
        className="flex-1"
        src={frameUrl}
        data-hj-allow-iframe=""
        ref={frameEl}
        onLoad={() => {
          frameEl.current.contentWindow.postMessage(
            {
              type: 'rk:queryparams:init',
              payload: {
                rkWidgetId: widgetId,
                rk_preview: true,
                rk_force_random: true,
              },
            },
            '*'
          )
        }}
        sandbox="allow-same-origin allow-scripts"
      />
    </div>
  )
}
