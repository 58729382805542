import React from 'react'
import { v4 as uuid } from 'uuid'

export const PROMOTION_STATUS_OPTIONS = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Active',
    actionLabel: 'Publish',
    value: 'active',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Paused',
    value: 'paused',
    actionLabel: 'Pause',
  },
  {
    label: 'Archived',
    actionLabel: 'Archive',
    value: 'archived',
  },
]

export const PROMOTION_RECOMMENDATION_TYPES = {
  AUTOMATIC: {
    label: 'Based on past orders',
    value: 'automatic',
    help: 'By default automatic recommendations are shown to users based on their usage',
  },

  MANUAL: {
    label: 'Manual',
    value: 'manual',
  },

  PR: {
    label: 'Personalized Recommendations',
    value: 'pr',
    help: 'Recommendations are fetched from Personalized Recommendations app',
    visible: () => false,
  },

  METAFIELDS: {
    label: 'Pick from Meta-fields',
    value: 'metafields',
    help: () => {},
    visible: () => false,
  },
}

export const DISCOUNT_TYPES = {
  PERCENTAGE: {
    label: '%',
    value: 'PERCENTAGE',
  },
  FLAT: {
    label: '$',
    value: 'AMOUNT',
  },
}

export const PROMOTION_TYPES = {
  SINGLE_PRODUCT_UPSELL: {
    label: 'Single Product Upsell',
    description:
      'Upsell a single product from your catalog to customers based on their initial purchase.',
    value: 'single_upsell',
    enabled: () => true,
    icon: (
      <svg
        fill="none"
        height="21"
        viewBox="0 0 21 21"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="prefix__a">
          <path d="M.5.5h20v20H.5z" />
        </clipPath>
        <g clipPath="url(#prefix__a)">
          <path
            clipRule="evenodd"
            d="M10.192 13.178h.52l.004-2.664h-.522zM9.224 11.6h.522l.008-1.448h-.522zm1.967-.01h.522l-.008-1.438h-.522zM9.594 3.976a.859.859 0 101.717.001.859.859 0 00-1.717-.001zm1.289 2.147a.429.429 0 10-.858-.001.429.429 0 00.858.001zm-4.05 4.39c-.277-2.44 1.005-3.41 1.53-3.926l-.001-.007c-.108-.707-.577-3.751 2.086-6.08 2.707 2.34 2.269 5.347 2.161 6.088.526.516 1.812 1.485 1.535 3.926a3.02 3.02 0 00-2.134-1.728c-.073.18-.247.589-.327.756l-2.338.007a19.308 19.308 0 01-.363-.775c-.854.16-1.594.569-2.15 1.74zm4.067 4.95V20.5l5.222-2.786v-2.053l-3.369 1.763zm-6.05.158v2.092l5.217 2.784v-4.999l-1.81 1.988zm1.348-4.175c-.506-1.068-.322-2.47-.026-3.334l-3.2 1.653 1.878 1.767-1.878 2.069 5.11 2.839 1.985-2.18v-.033l-4.542-2.41zm9.924.086L18 13.693l-5.074 2.7L10.9 14.25v-.003l4.547-2.43-.688-.38c.551-1.052.343-2.447.018-3.319L18 9.801l-1.897 1.722z"
            fill="#38a169"
            fillRule="evenodd"
          />
          <path
            d="M6.756-.074h7.66v12.453h-7.66zm2.443 13.452h2.64v-.999H9.2z"
            fill="#ebf6f0"
          />
        </g>
        <path
          clipRule="evenodd"
          d="M8.776 7.378a.25.25 0 00-.25-.25H7.523a.25.25 0 01-.177-.427l2.846-2.846a.25.25 0 01.353 0l2.846 2.846a.25.25 0 01-.177.427H12.21a.25.25 0 00-.25.25v4.01a.25.25 0 01-.25.25H9.026a.25.25 0 01-.25-.25z"
          stroke="#38a169"
          strokeLinecap="round"
        />
      </svg>
    ),
  },

  MULTI_PRODUCT_UPSELL: {
    label: 'Multi Product Upsell',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ',
    value: 'multiple_upsell',
    enabled: () => false,
    icon: (
      <svg
        fill="none"
        height="18"
        viewBox="0 0 24 18"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="prefix__a">
          <path d="M.988 1.065h11.791v11.791H.988z" />
        </clipPath>
        <clipPath id="prefix__b">
          <path d="M5.694.32h17.627v17.627H5.694z" />
        </clipPath>
        <g clipPath="url(#prefix__a)">
          <path
            clipRule="evenodd"
            d="M6.702 8.54h.307l.002-1.57h-.308zm-.57-.93h.307l.005-.855h-.308zm1.159-.007h.308l-.005-.848h-.307zM6.35 3.115a.506.506 0 101.012 0 .506.506 0 00-1.012 0zm.76 1.266a.253.253 0 10-.506 0 .253.253 0 00.505 0zM4.72 6.969c-.163-1.439.593-2.01.903-2.314l-.001-.005c-.063-.417-.34-2.212 1.23-3.585 1.596 1.38 1.337 3.152 1.274 3.59.31.304 1.068.875.905 2.314A1.78 1.78 0 007.774 5.95c-.043.106-.146.348-.193.446L6.203 6.4c-.05-.1-.169-.347-.215-.457-.503.094-.939.335-1.267 1.026zm2.4 2.918v2.97l3.079-1.643v-1.21l-1.986 1.039zm-3.566.093v1.234l3.075 1.64V9.909L5.562 11.08zm.794-2.461c-.298-.63-.19-1.456-.015-1.966l-1.887.975L3.553 7.57 2.445 8.79l3.013 1.673 1.17-1.284v-.02L3.951 7.737zm5.851.051l1.107 1.273-2.991 1.592L7.12 9.172V9.17L9.8 7.738l-.405-.224c.324-.62.202-1.443.01-1.957l1.9.992-1.118 1.015z"
            fill="#38a169"
            fillOpacity={0.71}
            fillRule="evenodd"
          />
          <g fill="#ebf6f0">
            <path d="M4.676.727h4.516v7.342H4.676zm1.44 7.93h1.557V8.07H6.116z" />
            <path d="M8.92 4.979h3.764v7.182H8.92z" />
          </g>
        </g>
        <path
          clipRule="evenodd"
          d="M5.868 5.223a.25.25 0 00-.25-.25h-.242a.25.25 0 01-.177-.427l1.43-1.43a.25.25 0 01.354 0l1.43 1.43a.25.25 0 01-.177.427h-.241a.25.25 0 00-.25.25v2.159a.25.25 0 01-.25.25H6.118a.25.25 0 01-.25-.25z"
          stroke="#6cba90"
          strokeLinecap="round"
        />
        <g clipPath="url(#prefix__b)">
          <path
            clipRule="evenodd"
            d="M14.236 11.494h.459l.003-2.348h-.46zm-.853-1.391h.46l.007-1.277h-.46zm1.733-.01h.46l-.007-1.267h-.46zM13.71 3.384a.757.757 0 101.514.001.757.757 0 00-1.514 0zm1.136 1.893a.378.378 0 10-.756-.001.378.378 0 00.756 0zm-3.57 3.869c-.244-2.151.886-3.005 1.35-3.46l-.002-.007c-.095-.624-.508-3.306 1.838-5.359 2.386 2.062 2 4.712 1.905 5.366.464.455 1.597 1.309 1.353 3.46a2.662 2.662 0 00-1.88-1.523 17.78 17.78 0 01-.29.666l-2.06.006a18.989 18.989 0 01-.32-.683c-.752.14-1.404.501-1.894 1.534zm3.585 4.362v4.439l4.602-2.455v-1.81l-2.969 1.554zm-5.332.14v1.843l4.598 2.454v-4.406l-1.595 1.752zm1.188-3.68c-.446-.941-.284-2.176-.023-2.939l-2.82 1.457 1.655 1.558-1.655 1.823 4.504 2.502 1.749-1.92v-.03l-4.003-2.124zm8.746.076l1.655 1.903-4.472 2.38-1.785-1.888v-.003l4.008-2.141-.607-.335c.486-.927.302-2.157.016-2.925l2.84 1.482-1.672 1.518z"
            fill="#38a169"
            fillRule="evenodd"
          />
          <path
            d="M11.208-.186h6.75V10.79h-6.75zM13.36 11.67h2.328v-.88h-2.327z"
            fill="#ebf6f0"
          />
        </g>
        <path
          clipRule="evenodd"
          d="M12.989 6.411a.25.25 0 00-.25-.25h-.784a.25.25 0 01-.177-.426l2.437-2.437a.25.25 0 01.353 0l2.437 2.437a.25.25 0 01-.177.426h-.783a.25.25 0 00-.25.25v3.476a.25.25 0 01-.25.25h-2.306a.25.25 0 01-.25-.25z"
          stroke="#38a169"
          strokeLinecap="round"
        />
      </svg>
    ),
  },
}

export const PROMOTION_LAYOUT_SECTION_TYPES = {
  BANNER: {
    value: 'banner',
    label: 'Banner',
    allowNewSection: true,
    icon: (
      <svg
        width="50"
        viewBox="0 0 50 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10"
          y="1"
          width="30"
          height="19"
          rx="3"
          stroke="currentColor"
          strokeWidth="2"
        />
        <rect y="29" width="50" height="3" rx="1.5" fill="currentColor" />
        <rect y="45" width="50" height="3" rx="1.5" fill="currentColor" />
        <rect y="37" width="50" height="3" rx="1.5" fill="currentColor" />
      </svg>
    ),
    getNewSectionDefaults: () => ({
      settings: {
        background: 'secondary',
        border: 'block',
        layout: 'full_width',
        alignment: 'center',
        lines: [
          {
            id: uuid(),
            text: '**Special offer for you**',
            appearance: 'regular',
            size: 'medium',
          },
        ],
      },
      translations: {},
    }),
  },

  TEXT: {
    value: 'text',
    label: 'Text',
    allowNewSection: true,
    icon: (
      <svg
        width="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor">
          <path d="M18.75 6.25h12.5v6.25h3.125V3.125H0V12.5h3.125V6.25h12.5v37.5h-6.25v3.125H25V43.75h-6.25V6.25z" />
          <path d="M21.875 15.625v6.25H25V18.75h9.375v25h-6.25v3.125H43.75V43.75H37.5v-25h9.375v3.125H50v-6.25H21.875z" />
        </g>
      </svg>
    ),
    getNewSectionDefaults: () => ({
      settings: {
        text: 'Add line text here',
        appearance: 'regular',
        size: 'medium',
        layout: 'full_width',
        alignment: 'center',
      },
      translations: {},
    }),
  },

  SINGLE_PRODUCT_UPSELL: {
    value: 'single_product',
    label: 'Single product upsell',
    allowNewSection: false,
    icon: (
      <svg
        width="50"
        viewBox="0 0 50 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10"
          y="1"
          width="30"
          height="19"
          rx="3"
          stroke="#38A169"
          strokeWidth="2"
        />
        <rect y="29" width="50" height="3" rx="1.5" fill="#38A169" />
        <rect y="45" width="50" height="3" rx="1.5" fill="#38A169" />
        <rect y="37" width="50" height="3" rx="1.5" fill="#38A169" />
      </svg>
    ),
    getNewSectionDefaults: () => ({
      settings: {
        recommendationType: PROMOTION_RECOMMENDATION_TYPES.AUTOMATIC.value,
        recommendationRules: {},
        discount: {
          type: DISCOUNT_TYPES.PERCENTAGE.value,
          value: 0,
        },
      },
      translations: {
        buyButtonText: 'Pay now • {{total}}',
        declineButtonText: 'Decline upsell offer',
        freeText: 'Free',
      },
    }),
  },

  MULTI_PRODUCT_UPSELL: {
    value: 'multiple_product',
    label: 'Multiple product upsell',
    allowNewSection: false,
    icon: (
      <svg
        width="50"
        viewBox="0 0 61 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="17"
          height="17"
          rx="3"
          stroke="#38A169"
          strokeWidth="2"
        />
        <rect x="25" y="3" width="36" height="3" rx="1.5" fill="#38A169" />
        <rect x="25" y="11" width="25" height="3" rx="1.5" fill="#38A169" />
        <rect
          x="1"
          y="29"
          width="17"
          height="17"
          rx="3"
          stroke="#38A169"
          strokeWidth="2"
        />
        <rect x="25" y="31" width="36" height="3" rx="1.5" fill="#38A169" />
        <rect x="25" y="39" width="25" height="3" rx="1.5" fill="#38A169" />
      </svg>
    ),
    getNewSectionDefaults: () => ({
      settings: {
        slots: [
          {
            id: uuid(),
            recommendationType: PROMOTION_RECOMMENDATION_TYPES.AUTOMATIC.value,
            recommendationRules: {},
            discount: {
              type: DISCOUNT_TYPES.PERCENTAGE.value,
              value: 0,
            },
          },
          {
            id: uuid(),
            recommendationType: PROMOTION_RECOMMENDATION_TYPES.AUTOMATIC.value,
            recommendationRules: {},
            discount: {
              type: DISCOUNT_TYPES.PERCENTAGE.value,
              value: 0,
            },
          },
        ],
      },
      translations: {
        buyButtonText: 'Pay now • {{total}}',
        declineButtonText: 'Decline upsell offer',
      },
    }),
  },

  IMAGE: {
    value: 'image',
    label: 'Image',
    allowNewSection: false,
    icon: (
      <svg
        width="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.61 0H6.39A6.396 6.396 0 000 6.39v37.22A6.396 6.396 0 006.39 50h37.22A6.396 6.396 0 0050 43.61V6.39A6.396 6.396 0 0043.61 0zm3.445 43.61c0 1.9-1.545 3.445-3.444 3.445H6.389a3.448 3.448 0 01-3.444-3.444v-5.372l9.693-8.247a.952.952 0 011.227-.007l6.071 5.041a1.473 1.473 0 001.983-.092l14.427-14.449a.944.944 0 01.723-.278.945.945 0 01.691.351l9.295 11.444v11.609zm0-16.28l-7.008-8.629a3.904 3.904 0 00-2.827-1.436 3.908 3.908 0 00-2.958 1.14L20.784 31.901l-5.038-4.183a3.892 3.892 0 00-5.017.03l-7.784 6.623V6.39c0-1.899 1.545-3.444 3.444-3.444h37.222c1.899 0 3.444 1.545 3.444 3.444v20.942z"
          fill="currentColor"
        />
        <path
          d="M15.74 6.152a7.109 7.109 0 00-7.101 7.1c0 3.916 3.185 7.102 7.1 7.102 3.916 0 7.101-3.186 7.101-7.101 0-3.916-3.185-7.101-7.1-7.101zm0 11.257a4.16 4.16 0 01-4.156-4.156 4.16 4.16 0 014.156-4.156 4.16 4.16 0 014.155 4.156 4.16 4.16 0 01-4.155 4.156z"
          fill="currentColor"
        />
      </svg>
    ),
    getNewSectionDefaults: () => ({
      settings: {
        alignment: 'center',
        spacing: 'loose',
        textBlockPlacement: 'left',
        textBlockSpacing: 'tight',
        lines: [
          {
            id: uuid(),
            text: 'Text to highlight image',
            appearance: 'regular',
            size: 'medium',
          },
        ],
      },
      translations: {},
    }),
  },

  DISCOUNT_BANNER: {
    value: 'discount_banner',
    label: 'Discount banner',
    allowNewSection: false,
    getNewSectionDefaults: () => ({
      settings: {},
      translations: {},
    }),
  },

  SPACER: {
    value: 'spacer',
    label: 'Vertical spacer',
    hidden: true,
    getNewSectionDefaults: () => ({
      settings: {},
      translations: {},
    }),
  },
}

export const DEFAULT_PROMOTION = {
  steps: [
    {
      sections: [],
      settings: {},
    },
  ],
  type: PROMOTION_TYPES.SINGLE_PRODUCT_UPSELL.value,
  settings: {},
  translations: {},
}
