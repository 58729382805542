import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { storeSave as storeSaveApi } from 'api/store'
import AddTemplateModal from 'components/Modals/AddTemplateModal'
import Box from 'components/Box'
import Form from 'components/Form'
import Button from 'components/Button'
import Callout from 'components/Callout'
import { useToast } from 'components/Toast'
import { useOpenModal } from 'components/Modal'
import WaitForData from 'components/WaitForData'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import TEMPLATE_TYPES from 'constants/templates'
import { GLOOD_PROXY } from 'constants/features'
import { useApi } from 'hooks/api'
import { getAccountData } from 'utils/account'
import { isFeatureEnabled } from 'utils/features'
import { _get, _uniqBy } from 'utils/lodash'

export default function StorefrontTemplate({ store }) {
  const accountData = getAccountData()
  const { name: shop } = accountData
  const templates = useApi('templates/', { shop })
  return (
    <div className="flex flex-col items-stretch justify-start">
      <WaitForData objects={[templates]}>
        <BundleTemplate
          store={store}
          templates={templates}
          accountData={accountData}
        />
      </WaitForData>
    </div>
  )
}

function BundleTemplate({ store, templates, accountData }) {
  const updateAllowed = isFeatureEnabled(accountData, GLOOD_PROXY)
  let bundleTemplateId = store.data.bundleTemplate
  const openToast = useToast()
  const openModal = useOpenModal()
  const templateOptions = [
    { label: 'None', value: '' },
    ..._get(templates, 'data.templates', [])
      .filter((temp) => temp.type === TEMPLATE_TYPES.BUNDLES_PAGE.value)
      .map((t) => ({
        label: t.name,
        value: t.id,
      })),
  ]
  const [loading, setLoading] = useState(false)
  const [currentSelectedTemplateOption, setCurrentSelectedTemplateOption] =
    useState(getTemplateOption(bundleTemplateId))
  function getTemplateOption(templateId) {
    return templateId
      ? templateOptions.find((option) => option.value === templateId)
      : templateOptions.length > 1
      ? { label: 'None', value: '' }
      : null
  }

  async function onSave(data) {
    setLoading(true)
    bundleTemplateId = data.bundleTemplate
      ? parseInt(data.bundleTemplate)
      : null
    if (updateAllowed) {
      try {
        const response = await storeSaveApi(
          { bundleTemplate: bundleTemplateId },
          accountData.name
        )
        bundleTemplateId = response.data.bundleTemplate
        setCurrentSelectedTemplateOption(getTemplateOption(bundleTemplateId))
        openToast({
          type: 'success',
          text: 'Template updated successfully',
        })
      } catch (err) {
        openToast({
          type: 'error',
          text: 'There was an error updating the template',
        })
      }
    } else {
      openUpgradeAccountModal()
    }
    setLoading(false)
  }

  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      bundleTemplate: currentSelectedTemplateOption?.value,
    },
  })

  function openUpgradeAccountModal() {
    openModal(
      <UpgradeAccountModal
        account={{ data: accountData }}
        featureToEnable={GLOOD_PROXY}
      />
    )
  }

  function handleCreate() {
    if (updateAllowed) {
      openModal(
        <AddTemplateModal
          templates={templates}
          account={{ data: accountData }}
          defaultType={TEMPLATE_TYPES.BUNDLES_PAGE.value}
          onCreate={(newTemplate) => {
            templates.update(
              {
                ...templates.data,
                templates: _uniqBy(
                  [newTemplate, ...templates.data.templates],
                  (t) => t.id
                ),
              },
              {
                local: true,
              }
            )
          }}
        />
      )
    } else {
      openUpgradeAccountModal()
    }
  }
  return (
    <Box className="mb-5">
      <div className="flex flex-row items-center justify-between">
        <div className="text-sm font-semibold text-gray-800 uppercase">
          {GLOOD_PROXY.label} Template
        </div>
        {!currentSelectedTemplateOption && (
          <Button onClick={handleCreate} variant={'bordered'} size="small">
            <div className="flex items-center justify-center">
              <span className=""> + Add new template</span>
              {!updateAllowed && (
                <Icons.Lock className={'w-3 ml-2 fill-current opacity-75'} />
              )}
            </div>
          </Button>
        )}
      </div>

      {currentSelectedTemplateOption && (
        <form
          className="flex flex-col items-stretch flex-1 w-full mt-5"
          onSubmit={handleSubmit((data) => onSave(data))}
        >
          <Form.Field
            label={`${GLOOD_PROXY.label} Template`}
            name="bundleTemplate"
            control="select"
            options={templateOptions}
            onChange={(e) => {
              setCurrentSelectedTemplateOption(
                getTemplateOption(parseInt(e.target.value))
              )
            }}
            ref={register()}
            helpText={`Choose the template that is used to render on the bundles page ie, on https://${accountData.shop.domain}/a/recommendations.`}
          />

          <div className="flex flex-row justify-end">
            <Button
              size="small"
              variant="bordered"
              className="mr-3"
              disabled={!Boolean(currentSelectedTemplateOption.value)}
              href={`/templates/${currentSelectedTemplateOption.value}`}
              internalLink={true}
            >
              Configure Template
            </Button>
            <Button loading={loading} size="small">
              <div className="flex items-center justify-center">
                <span className="">Save</span>
                {!updateAllowed && (
                  <Icons.Lock className={'w-3 ml-2 fill-current opacity-75'} />
                )}
              </div>
            </Button>
          </div>
        </form>
      )}
    </Box>
  )
}
