import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'
import Time from 'components/Time'
import Link from 'components/Link'
import { pageLabelFromType, widgetLabelFromType } from 'utils/constants'
import { deviceType } from 'utils/browser'
import { _last, _reverse } from 'utils/lodash'
import COUNTRIES from 'constants/countries.json'

export default function TimelineCard({ visitor }) {
  const visits = groupByVisitId(visitor.events)
  const [visibleVisits, setVisibleVisits] = useState(
    visits.slice(0, VISIT_SIZE)
  )

  function loadMore() {
    setVisibleVisits(visits.slice(0, visibleVisits.length + VISIT_SIZE))
  }

  useEffect(() => {
    setVisibleVisits(visits.slice(0, VISIT_SIZE))
  }, [visitor.events])

  return (
    <div className="flex flex-col items-start bg-white border border-gray-300 rounded shadow">
      <span className="flex flex-row items-center justify-between w-full px-4 py-2 mb-4 leading-6 text-gray-900 border-b border-gray-300">
        <span className="font-medium leading-relaxed">Activity Timeline</span>
      </span>
      <div className="clearfix w-full px-4 pb-4">
        <ul>
          {visibleVisits.map((visit, index) => (
            <TimelineVisit visit={visit} key={index} />
          ))}
        </ul>
        {visibleVisits.length < visits.length && (
          <div className="mt-6 text-center">
            <span
              className="cursor-pointer text-primary hover:underline"
              onClick={loadMore}
            >
              Load more...
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function TimelineVisit({ visit }) {
  const startEvent = Array.isArray(visit) ? visit[visit.length - 1] : visit

  let eventContent
  if (Array.isArray(visit)) {
    eventContent = (
      <React.Fragment>
        {_reverse(visit).map((item, index) => (
          <TimelineItem
            item={item}
            key={index}
            isLast={index === visit.length - 1}
          />
        ))}
      </React.Fragment>
    )
  } else {
    eventContent = <TimelineItem item={visit} isLast={true} />
  }

  return (
    <div className="flex flex-col items-stretch mb-8">
      <div className="mb-3 text-xs text-gray-600">
        <Time
          value={startEvent.createdAt}
          className="font-medium text-gray-900"
          formatPrefix="On"
          distanceSuffix="ago"
        />{' '}
        from{' '}
        <span className="font-medium text-gray-900">
          {startEvent.city
            ? `${startEvent.city}, ${startEvent.country}`
            : COUNTRIES_MAP[startEvent.country]}
        </span>{' '}
        on{' '}
        <span className="font-medium text-gray-900 capitalize">
          {deviceType(startEvent.userAgent)}
        </span>{' '}
        device
      </div>
      <ul className="pl-4">{eventContent}</ul>
    </div>
  )
}

function TimelineItem({ item, isLast }) {
  const mainEvent = Array.isArray(item) ? item[item.length - 1] : item
  const { eventType } = mainEvent
  const EventIcon = EVENT_TYPE_TO_ICON_MAP[eventType]

  let itemContent = null
  switch (eventType) {
    case 'ppv':
      itemContent = (
        <p className="text-sm text-gray-600">
          Viewed&nbsp;
          <a
            href={`https://${mainEvent.pageUrl}`}
            tab="_blank"
            className="font-medium text-gray-900 hover:underline"
          >
            {pageLabelFromType(mainEvent.pageType)}
          </a>
        </p>
      )
      break
    case 'viewed':
      itemContent = (
        <p className="text-sm text-gray-700">
          Viewed&nbsp;
          <Link
            to={`/widgets/${mainEvent.widgetId}`}
            className="font-medium text-gray-900 hover:underline"
          >
            {widgetLabelFromType(mainEvent.recommendationType)}
          </Link>
          &nbsp;on&nbsp;
          <a
            href={`https://${mainEvent.pageUrl}`}
            tab="_blank"
            className="font-medium text-gray-900 hover:underline"
          >
            {pageLabelFromType(mainEvent.pageType)}
          </a>
        </p>
      )
      break
    case 'served':
      itemContent = (
        <p className="text-sm text-gray-700">
          App served&nbsp;
          <Link
            to={`/widgets/${mainEvent.widgetId}`}
            className="font-medium text-gray-900 hover:underline"
          >
            {widgetLabelFromType(mainEvent.recommendationType)}
          </Link>
          &nbsp;on&nbsp;
          <a
            href={`https://${mainEvent.pageUrl}`}
            tab="_blank"
            className="font-medium text-gray-900 hover:underline"
          >
            {pageLabelFromType(mainEvent.pageType)}
          </a>
        </p>
      )
      break
    case 'clicked':
      itemContent = (
        <p className="text-sm text-gray-700">
          Clicked product inside&nbsp;
          <Link
            to={`/widgets/${mainEvent.widgetId}`}
            className="font-medium text-gray-900 hover:underline"
          >
            {widgetLabelFromType(mainEvent.recommendationType)}
          </Link>
          &nbsp;on&nbsp;
          <a
            href={`https://${mainEvent.pageUrl}`}
            tab="_blank"
            className="font-medium text-gray-900 hover:underline"
          >
            {pageLabelFromType(mainEvent.pageType)}
          </a>
        </p>
      )
      break
    case 'addtocart':
      itemContent = (
        <p className="text-sm text-gray-700">
          Added product to cart from&nbsp;
          <Link
            to={`/widgets/${mainEvent.widgetId}`}
            className="font-medium text-gray-900 hover:underline"
          >
            {widgetLabelFromType(mainEvent.recommendationType)}
          </Link>
          &nbsp;on&nbsp;
          <a
            href={`https://${mainEvent.pageUrl}`}
            tab="_blank"
            className="font-medium text-gray-900 hover:underline"
          >
            {pageLabelFromType(mainEvent.pageType)}
          </a>
        </p>
      )
      break
    default:
      throw new Error(`Missing: Item content for "${eventType}" event`)
  }

  return (
    <li>
      <div className={classNames('relative', { 'pb-6': !isLast })}>
        {!isLast && (
          <span
            className="absolute h-full -ml-px bg-gray-200"
            aria-hidden="true"
            style={{ top: '1rem', left: '1rem', width: '0.125rem' }}
          ></span>
        )}
        <div className="relative flex">
          <div>
            <span
              className={classNames(
                'flex items-center justify-center w-8 h-8 rounded-full',
                EVENT_TYPE_TO_ICON_BG_MAP[eventType]
              )}
              style={{ boxShadow: '0 0 0 5px #fff' }}
            >
              <EventIcon className="w-4 h-4 text-white" />
            </span>
          </div>
          <div className="flex justify-between flex-1 min-w-0 pt-1 ml-3">
            <div>{itemContent}</div>
          </div>
        </div>
      </div>
    </li>
  )
}

function groupByVisitId(events = []) {
  return events
    .sort((e1, e2) => e2.id - e1.id)
    .reduce((accum, event) => {
      const lastItem = accum[accum.length - 1]
      const accumBeforeLastItem = accum.slice(0, accum.length - 1)
      if (!lastItem) {
        return [event]
      }

      if (Array.isArray(lastItem)) {
        const lastItemEvent = lastItem[lastItem.length - 1]
        if (lastItemEvent.visitId === event.visitId) {
          return [...accumBeforeLastItem, [...lastItem, event]]
        } else {
          return [...accumBeforeLastItem, lastItem, event]
        }
      } else {
        const lastItemEvent = lastItem
        if (lastItemEvent.visitId === event.visitId) {
          return [...accumBeforeLastItem, [lastItem, event]]
        } else {
          return [...accumBeforeLastItem, lastItem, event]
        }
      }
    }, [])
}

const VISIT_SIZE = 25
const EVENT_TYPE_TO_ICON_MAP = {
  ppv: Icons.PageView,
  served: Icons.WidgetServe,
  viewed: Icons.WidgetView,
  clicked: Icons.Click,
  addtocart: Icons.Cart,
}
const EVENT_TYPE_TO_ICON_BG_MAP = {
  ppv: 'bg-primary-500',
  served: 'bg-gray-500',
  viewed: 'bg-yellow-500',
  clicked: 'bg-green-500',
  addtocart: 'bg-green-500',
}
const COUNTRIES_MAP = COUNTRIES.reduce(
  (accum, item) => ({ ...accum, [item['alpha-2']]: item.name }),
  {}
)
