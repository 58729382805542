import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import SlideOver from 'components/SlideOver'
import ProductsSlider from 'components/ProductsSlider'
import WithProducts from 'components/WithProducts'
import Icons from 'components/Icons'
import EditorForm from 'components/EditorForm'
import { getCurrencyFormat } from 'utils/account'
import { queryClient } from 'utils/query'
import { renderCurrency } from 'utils/currency'

export default function PromotionBuilderProductUpsellManual({
  value,
  handleUpdate,
}) {
  const { productId, variantIds } = value
  const [productSliderVisible, setProductSliderVisible] = useState(false)
  const { data: accountData } = queryClient.getQueryData(['account'])

  return productId ? (
    <WithProducts productIds={[productId]}>
      {({ products }) => {
        const [product] = products
        const { variants } = product
        const selectedVariants = variants.filter((v) =>
          variantIds.length > 0 ? variantIds.includes(v.id) : true
        )

        return (
          <ProductItem
            key={product.id}
            product={product}
            productSettings={value}
            selectedVariants={selectedVariants}
            accountData={accountData}
            onDelete={() => handleUpdate({})}
            onChange={(newSettings, errors) => {
              handleUpdate(
                {
                  ...value,
                  ...newSettings,
                },
                errors
              )
            }}
          />
        )
      }}
    </WithProducts>
  ) : (
    <React.Fragment>
      <button
        className="flex justify-center w-full py-2 text-sm font-semibold rounded cursor-pointer sitems-center bg-primary-200 hover:bg-primary-300 text-primary"
        onClick={() => setProductSliderVisible(true)}
      >
        Choose Product
      </button>
      <SlideOver
        open={productSliderVisible}
        onClose={() => setProductSliderVisible(false)}
      >
        <ProductsSlider
          onClose={() => setProductSliderVisible(false)}
          initialSelectedProducts={[]}
          onSelect={(selectedProducts = []) => {
            const [selectedProduct] = selectedProducts
            if (selectedProduct) {
              handleUpdate({
                productId: selectedProduct.productId,
                variantIds:
                  selectedProduct.variantsCount ===
                  selectedProduct.variantIds?.length
                    ? []
                    : selectedProduct.variantIds,
                defaultQuantity: '1',
              })
            }
          }}
          allowMultipleProducts={false}
          allowMultipleVariants={true}
        />
      </SlideOver>
    </React.Fragment>
  )
}

function ProductItem({
  product,
  productSettings,
  selectedVariants,
  accountData,
  onDelete,
  onChange,
}) {
  const { image } = product
  const [showAllSelectedVariants, setShowAllSelectedVariants] = useState(false)
  const [formError, setFormError] = useState(validateFields(productSettings))
  const visibleSelectedVariants = selectedVariants.slice(
    0,
    showAllSelectedVariants ? selectedVariants.length : 2
  )
  const hiddenSelectedVariantsCount =
    selectedVariants.length - visibleSelectedVariants.length

  function updateSettings(values) {
    onChange(values, validateFields(values))
  }

  function validateFields(values) {
    let errors = {}
    const { minQuantity, maxQuantity, defaultQuantity, allowQuantityChange } =
      values
    if (parseInt(minQuantity) > parseInt(maxQuantity)) {
      errors['minQuantity'] = 'Min qty has to be less than max qty'
      errors['maxQuantity'] = 'Max qty has to be greater than min qty'
    }

    if (!defaultQuantity) {
      errors['defaultQuantity'] = 'Default Qty cannot be empty'
    } else if (parseInt(defaultQuantity) === 0) {
      errors['defaultQuantity'] = 'Default Qty cannot be 0'
    }

    if (allowQuantityChange) {
      if (typeof minQuantity !== 'undefined') {
        if (!minQuantity) {
          errors['minQuantity'] = 'Min Qty cannot be empty'
        } else if (parseInt(minQuantity) === 0) {
          errors['minQuantity'] = 'Min Qty cannot be 0'
        }
      }
      if (typeof maxQuantity !== 'undefined') {
        if (!maxQuantity) {
          errors['maxQuantity'] = 'Max Qty cannot be empty'
        } else if (parseInt(maxQuantity) === 0) {
          errors['maxQuantity'] = 'Max Qty cannot be 0'
        }
      }
    }
    return errors
  }

  useEffect(() => {
    setFormError(validateFields(productSettings))
  }, [productSettings])

  return (
    <div className="flex flex-col items-stretch p-4 -mx-4 border-t rounded border-gray-10">
      <div className="relative flex flex-col items-start">
        <span className="mb-1 font-semibold text-black mr-7 text">
          {product.title}
        </span>
        <span
          className="text-xs text-gray-800"
          dangerouslySetInnerHTML={{
            __html: renderCurrency(
              selectedVariants[0].price,
              getCurrencyFormat({ data: accountData })
            ),
          }}
        ></span>
        <Icons.Delete
          onClick={onDelete}
          className="absolute w-4 text-red-400 transition-colors cursor-pointer top-1 right-1 hover:text-red-600"
        />
      </div>
      <div className="flex flex-col items-stretch mt-6">
        <div className="flex flex-col items-stretch mb-6">
          <span className="mb-1 text-xs text-gray-600">Allowed Variants</span>
          {visibleSelectedVariants.map((v) => (
            <span
              className="flex mb-0.5 flex-row items-center text-sm text-gray-800"
              key={v.id}
            >
              <span className="font-medium">{v.title} - </span>
              <span
                className="ml-1 text-xxs mt-0.5"
                dangerouslySetInnerHTML={{
                  __html: renderCurrency(
                    v.price,
                    getCurrencyFormat({ data: accountData })
                  ),
                }}
              ></span>
            </span>
          ))}
          {hiddenSelectedVariantsCount > 0 ? (
            <span
              className="mt-1 text-blue-400 cursor-pointer text-xxs hover:underline"
              onClick={() => setShowAllSelectedVariants(true)}
            >
              Show {hiddenSelectedVariantsCount} more
            </span>
          ) : (
            <span
              className={classNames(
                'mt-1 text-blue-400 cursor-pointer text-xxs hover:underline',
                { hidden: selectedVariants.length <= 2 }
              )}
              onClick={() => setShowAllSelectedVariants(false)}
            >
              Show less
            </span>
          )}
        </div>
        <EditorForm
          layout={DEFAULT_OPTIONS_LAYOUT}
          formError={formError}
          value={productSettings}
          handleUpdate={(values) => {
            const keys = Object.keys(values)
            if (keys[0] === 'defaultQuantity') {
              const { defaultQuantity: newDefaultQuantity } = values
              const { minQuantity, maxQuantity } = productSettings
              if (!newDefaultQuantity) {
                const updatedValues = {
                  defaultQuantity: newDefaultQuantity,
                  minQuantity: newDefaultQuantity,
                  maxQuantity: newDefaultQuantity,
                }
                updateSettings(updatedValues)
              } else {
                const updatedValues = {
                  defaultQuantity: newDefaultQuantity,
                  minQuantity: minQuantity
                    ? Math.min(newDefaultQuantity, minQuantity)
                    : newDefaultQuantity,
                  maxQuantity: maxQuantity
                    ? Math.max(newDefaultQuantity, maxQuantity)
                    : newDefaultQuantity,
                }
                updateSettings(updatedValues)
              }
            } else {
              const { minQuantity, maxQuantity, defaultQuantity } =
                productSettings
              const updatedValues = {
                ...values,
                minQuantity,
                maxQuantity,
                defaultQuantity,
              }
              updateSettings(updatedValues)
            }
          }}
          turnOffAutoFocus={true}
        />
        <div className={'mt-3'}>
          <EditorForm
            disabled={!productSettings.allowQuantityChange}
            layout={QUANTITY_OPTIONS_LAYOUT}
            value={productSettings}
            formError={formError}
            handleUpdate={(values) => {
              const keys = Object.keys(values)

              let finalDefaultQuantity
              let finalMinQuantity
              let finalMaxQuantity
              if (keys[0] === 'minQuantity') {
                const { minQuantity: newMinQuantity } = values
                const {
                  defaultQuantity: existingDefaultQuantity,
                  maxQuantity: existingMaxQuantity,
                } = productSettings
                if (newMinQuantity) {
                  finalDefaultQuantity = Math.max(
                    newMinQuantity,
                    existingDefaultQuantity
                  )

                  finalDefaultQuantity = Math.min(
                    finalDefaultQuantity,
                    existingMaxQuantity ? existingMaxQuantity : 1
                  ).toString()

                  finalMaxQuantity = Math.max(
                    existingMaxQuantity,
                    newMinQuantity
                  ).toString()
                }
              } else if (keys[0] === 'maxQuantity') {
                const { maxQuantity: newMaxQuantity } = values
                const {
                  defaultQuantity: existingDefaultQuantity,
                  minQuantity: existingMinQuantity,
                } = productSettings

                if (newMaxQuantity) {
                  finalDefaultQuantity = Math.min(
                    newMaxQuantity,
                    existingDefaultQuantity
                  )

                  finalDefaultQuantity = Math.max(
                    finalDefaultQuantity,
                    existingMinQuantity ? existingMinQuantity : 1
                  ).toString()

                  finalMinQuantity = Math.min(
                    existingMinQuantity,
                    newMaxQuantity
                  ).toString()
                }
              }

              const updatedValues = {
                ...productSettings,
                ...values,
                ...(finalDefaultQuantity
                  ? { defaultQuantity: finalDefaultQuantity }
                  : {}),
                ...(finalMaxQuantity ? { maxQuantity: finalMaxQuantity } : {}),
                ...(finalMinQuantity ? { minQuantity: finalMinQuantity } : {}),
              }

              updateSettings(updatedValues)
            }}
            turnOffAutoFocus={true}
          />
        </div>
      </div>
    </div>
  )
}

const DEFAULT_OPTIONS_LAYOUT = [
  {
    key: 'productTitle',
    label: 'Product Title',
    controlType: 'input',
    helpText: 'Use custom title text instead of product title',
  },
  {
    key: 'productDescription',
    label: 'Product Description',
    controlType: 'textarea',
    helpText: 'Use custom description instead of product description',
  },
  {
    key: 'defaultQuantity',
    label: 'Default Quantity',
    controlType: 'number',
  },
  {
    key: 'allowQuantityChange',
    label: 'Allow customers to change quantity',
    controlType: 'checkbox',
  },
]

const QUANTITY_OPTIONS_LAYOUT = [
  [
    {
      key: 'minQuantity',
      label: 'Minimum Quantity',
      controlType: 'number',
    },
    {
      key: 'maxQuantity',
      label: 'Maximum Quantity',
      controlType: 'number',
    },
  ],
]
