import React from 'react'
import Button from 'components/Button'
import Callout from 'components/Callout'
import { openChat } from 'utils/external'

export default function AdvancedDesign() {
  return (
    <div className="flex flex-col items-stretch">
      <p className="mb-2 text-sm font-semibold text-gray-800">
        Want to have more control over the widget design & achieve same look as
        your store's theme?
      </p>
      <div className="flex flex-row">
        <Callout noHoverEffect={true}>
          <span className="mr-2">
            Try out powerful and customisable widget templates
          </span>
          <Button size="xSmall" onClick={openChat}>
            Contact Us
          </Button>
        </Callout>
      </div>
    </div>
  )
}
