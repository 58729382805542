import api from 'api'

export function fetchPages({ queryKey }) {
  const [_key, shop] = queryKey
  return api.get('/pages/', {
    params: {
      shop,
    },
  })
}
