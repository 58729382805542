import React, { useRef, useState } from 'react'

import WithProducts from 'components/WithProducts'
import { PROMOTION_RECOMMENDATION_TYPES } from 'constants/promotions'
import { _find, _get } from 'utils/lodash'

import CartItems from './CartItems'
import OrderUpdate from './OrderUpdate'
import ProductRow from './ProductRow'

function getProductIdForPreview(slot) {
  let productIdsForPreview = []
  const { recommendationRules, recommendationType } = slot
  if (recommendationType === PROMOTION_RECOMMENDATION_TYPES.MANUAL.value) {
    productIdsForPreview.push(_get(recommendationRules, 'productId'))
  }

  return productIdsForPreview.filter(Boolean)
}

export default function PromotionBuilderPreviewMultiProductUpsell({
  accountData,
  section,
}) {
  const {
    translations,
    settings: { slots },
  } = section
  const [itemsAdded, setItemsAdded] = useState([])
  var automaticRecommendedCount = 0
  return (
    <div className="flex flex-row items-start justify-start max-w-[1024px] w-full py-5">
      <div className="grid w-3/4 gap-8 ">
        {slots.map((slot, index) => {
          const productIdForPreview = getProductIdForPreview(slot)

          return (
            <WithProducts productIds={productIdForPreview} key={slot.id}>
              {({ products }) => {
                var product
                if (
                  slot.recommendationType ===
                    PROMOTION_RECOMMENDATION_TYPES.MANUAL.value &&
                  slot.recommendationRules.productId
                ) {
                  product = products[0]
                } else {
                  product =
                    products[products.length - 1 - automaticRecommendedCount++]
                }

                return (
                  <ProductRow
                    product={product}
                    accountData={accountData}
                    slot={slot}
                    key={product.productId}
                    itemAdded={_find(
                      itemsAdded,
                      (item) => item.productId === product.productId
                    )}
                    setItemsAdded={setItemsAdded}
                    translations={translations}
                    index={index}
                  />
                )
              }}
            </WithProducts>
          )
        })}
      </div>
      <div className="w-1/4 pl-5">
        <CartItems itemsAdded={itemsAdded} />
        <OrderUpdate
          itemsAdded={itemsAdded}
          slots={section?.settings?.slots}
          accountData={accountData}
        />
      </div>
    </div>
  )
}
