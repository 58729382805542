import React from 'react'
import classNames from 'classnames'

const SPACING_TO_CLASS_MAP = {
  regular: 'p-6',
  small: 'p-4',
  xSmall: 'p-3'
}

export default function Box({ spacing, className, children }) {
  return (
    <div
      className={classNames(
        'bg-white rounded shadow-sm',
        SPACING_TO_CLASS_MAP[spacing],
        className
      )}
    >
      {children}
    </div>
  )
}

Box.Heading = ({ children }) => {
  return (
    <div className="pb-2 mb-3 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
      {children}
    </div>
  )
}

Box.Section = ({ className, children }) => {
  return (
    <div className={classNames("pb-6 pt-6 leading-tight tracking-wide border-b", className)}>
      {children}
    </div>
  )
}

Box.ActionTray = ({ children }) => {
  return <div className="flex justify-center items-center">{children}</div>
}

Box.defaultProps = {
  spacing: 'regular'
}
