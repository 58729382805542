import React from 'react'
import classNames from 'classnames'

import { SelectVersionTwo } from 'components/Form/controls'
import FieldValue from 'components/ResourceList/FieldValue'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import Icons from 'components/Icons'
import Loader from 'components/Loader'
import useBundleMutations from 'hooks/queries/bundles'
import { trimString } from 'utils/string'
import {
  getBundleStatusOptions,
  getDiscountLevelLabelFromType,
  getTriggerTypeLabelFromType,
} from 'containers/Bundles/utils'
import { getAccountData } from 'utils/account'
import { isFeatureEnabled } from 'utils/features'
import { _find } from 'utils/lodash'
import { BUNDLES } from 'constants/features'
import UpgradeAccountModal from 'components/UpgradeAccountModal'

export default function TableCell(props) {
  const {
    value,
    cell: {
      column: { id: fieldName },
      row: { original },
    },
    state: { resourceSchema },
  } = props

  const type = resourceSchema.schema[fieldName].type
  const accountData = getAccountData()
  const openModal = useOpenModal()
  const openToast = useToast()
  const {
    updateBundle: { mutate: updateBundle, status: updateBundleStatus },
  } = useBundleMutations({ shop: accountData.name })

  const bundleUpdating = updateBundleStatus === 'loading'
  const featureEnabled = isFeatureEnabled(accountData, BUNDLES)
  const bundleStatusOptions = getBundleStatusOptions(
    original.status,
    accountData
  )

  switch (type) {
    case 'details':
      return (
        <div className="flex flex-col items-start w-full">
          <span className="text-lg font-medium text-gray-900">
            {trimString(original.name, 20)}
          </span>
          <span className="mt-1 text-xs text-gray-500">
            Bundle ID: {original.id}
          </span>
        </div>
      )
    case 'status':
      return (
        <div className="flex flex-row justify-center px-4">
          <div
            className="w-32"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {bundleUpdating ? (
              <Loader />
            ) : (
              <SelectVersionTwo
                size="small"
                label={
                  <div
                    className={classNames(
                      'flex flex-row py-1.5 px-4 rounded border text-sm border-gray-400 w-full justify-between',
                      {
                        'pointer-events-none opacity-50': bundleUpdating,
                      }
                    )}
                  >
                    <span>
                      {
                        _find(bundleStatusOptions, function (o) {
                          return o.value === original.status
                        }).label
                      }
                    </span>
                    <Icons.ChevronDown className="w-3 ml-2 text-current" />
                  </div>
                }
                options={bundleStatusOptions}
                onChange={(selectedOption) => {
                  const status = selectedOption.value
                  if (!bundleUpdating) {
                    if (status === 'active' && status != original.status) {
                      if (
                        featureEnabled &&
                        original.items.products?.length > 0
                      ) {
                        updateBundle({
                          id: original.id,
                          status,
                        })
                      } else if (featureEnabled) {
                        openToast({
                          type: 'error',
                          text: 'Empty bundles cannot be published',
                        })
                      } else {
                        openModal(
                          <UpgradeAccountModal
                            featureToEnable={BUNDLES}
                            account={{ data: accountData }}
                          />
                        )
                      }
                    } else {
                      updateBundle({
                        id: original.id,
                        status,
                      })
                    }
                  }
                }}
                value={_find(bundleStatusOptions, function (o) {
                  return o.value === original.status
                })}
              />
            )}
          </div>
        </div>
      )
    case 'triggers':
      return (
        <div className="flex flex-col items-start w-full whitespace-normal">
          <span className="text-sm text-gray-900">
            {Object.values(original.triggers)
              .map((trigger) => {
                return getTriggerTypeLabelFromType(trigger.type)
              })
              .join(', ')}
          </span>
        </div>
      )
    case 'discountLevel':
      return (
        <div className="flex flex-col items-start w-full whitespace-normal">
          <span className="text-sm text-gray-900">
            {getDiscountLevelLabelFromType(original.type)}
          </span>
        </div>
      )
    default:
      return (
        <div className="w-full truncate" title={value}>
          <FieldValue
            value={value}
            type={type}
            isList={resourceSchema.schema[fieldName].list}
          />
        </div>
      )
  }
}
