import React from 'react'

import ResourceList from 'components/ResourceList'
import TableCell from './Cell'
export default function FunnelList() {
  return (
    <ResourceList
      resourceType="funnel"
      schema={FUNNEL_SCHEMA}
      initialView={{
        fields: [
          'details',
          'status',
          'aovImprovement',
          'views',
          'availed',
          // 'ctr',
          'additionalRevenue',
        ],
        filters: {},
        order: [],
      }}
      CustomTableCell={TableCell}
      options={{ filters: { skipFilters: true } }}
    />
  )
}

const FUNNEL_SCHEMA = {
  sections: [],
  schema: {
    details: {
      title: 'Campaign Details',
      type: 'details',
      allowFilter: true,
      width: '19rem',
    },
    status: {
      title: 'Status',
      type: 'status',
      allowFilter: true,
      width: '150px',
    },
    // ctr: {
    //   title: 'Promotion Conversion',
    //   type: 'percentage',
    //   accessor: 'stats.conversion',
    //   width: '100px',
    // },
    aovImprovement: {
      title: 'AoV Improvement',
      type: 'percentage',
      accessor: 'stats.aovIncrease',
      minWidth: '100px',
    },
    views: {
      title: 'Promotions Views',
      type: 'number',
      accessor: 'stats.views',
      minWidth: '100px',
    },
    availed: {
      title: 'Promotions Availed',
      type: 'number',
      accessor: 'stats.availed',
      minWidth: '100px',
    },

    additionalRevenue: {
      title: 'Additional Revenue',
      type: 'currency',
      accessor: 'stats.upsell',
      allowFilter: true,
      minWidth: '150px',
    },
  },
}
