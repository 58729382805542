import React from 'react'
import classNames from 'classnames'
import Tooltip from 'components/Tooltip'

const BACKGROUND_TO_TAB_CLASS_MAP = {
  grey: 'bg-white',
  white: 'bg-gray-200',
}

function isTabVisible(tab, visibilityCheckParams) {
  return typeof tab.isVisible === 'function'
    ? tab.isVisible.apply(this, visibilityCheckParams)
    : true
}

export default function Tabs({
  tabs,
  visibilityCheckParams,
  children,
  background,
}) {
  const visibleTabs = tabs.filter((t) => isTabVisible(t, visibilityCheckParams))
  const [activeTab, setActiveTab] = React.useState(visibleTabs[0])

  React.useEffect(() => {
    const activeTabMatch = tabs.filter((t) => t.value === activeTab.value)[0]
    if (activeTabMatch && isTabVisible(activeTabMatch, visibilityCheckParams)) {
      setActiveTab(activeTabMatch)
    } else {
      const visibleTabs = tabs.filter((t) =>
        isTabVisible(t, visibilityCheckParams)
      )
      setActiveTab(visibleTabs[0])
    }
  }, [tabs, visibilityCheckParams])

  return (
    <React.Fragment>
      <div className="flex flex-row items-center pb-3 mb-6 border-b border-gray-400">
        {visibleTabs.map((tab) => {
          const isActiveTab = tab === activeTab
          return (
            <Tooltip
              content={tab.tooltip}
              key={tab.value}
              triggerClassName="group"
            >
              <div
                className={classNames(
                  'px-4 py-2 mr-2 text-sm rounded capitalize cursor-pointer hover:opacity-100 group-last:mr-0',
                  BACKGROUND_TO_TAB_CLASS_MAP[background],
                  {
                    'bg-primary text-white': isActiveTab,
                    'opacity-75 bg-white text-gray-800': !isActiveTab,
                  }
                )}
                onClick={() => setActiveTab(tab)}
              >
                {tab.label}
              </div>
            </Tooltip>
          )
        })}
      </div>
      {children(activeTab, setActiveTab)}
    </React.Fragment>
  )
}

Tabs.defaultProps = {
  background: 'grey',
}
