import React from 'react'
import { useMutation } from 'react-query'
import { useToast } from 'components/Toast'

import {
  saveBundle as updateBundleApi,
  createBundle as createBundleApi,
} from 'api/bundles'

import { invalidate, refetch } from 'utils/query'

export default function useBundleMutations({ shop }) {
  const openToast = useToast()
  const updateBundle = useMutation(
    async (data) => {
      return await updateBundleApi(shop, data)
    },
    {
      onSuccess: (data, variables, context) => {
        invalidate(['bundles'])
        invalidate(['bundle'])
        openToast({
          type: 'success',
          text: 'Bundle updated successfully!',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error updating the bundle',
        })
      },
    }
  )

  const createBundle = useMutation(
    async (data) => {
      return await createBundleApi(shop, data)
    },
    {
      onSuccess: () => {
        invalidate(['bundles'])
        invalidate(['bundle'])
        openToast({
          type: 'success',
          text: 'Bundle created successfully',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error creating the bundle',
        })
      },
    }
  )

  return {
    updateBundle,
    createBundle,
  }
}
