import { fakeApi } from 'utils/misc'
import api from 'api'

export function fetchPopups({ queryKey }) {
  const [_key, shop, { pagination, order, filters }] = queryKey
  // return api
  //   .get(`/aov/popups/`, {
  //     params: {
  //       shop,
  //       filters,
  //       pagination,
  //       order,
  //     },
  //   })
  //   .then((response) => {
  //     return {
  //       data: {
  //         size: pagination.size,
  //         offset: pagination.offset,
  //         data: response.data.popups,
  //         total: response.data.totalPages,
  //       },
  //     }
  //   })

  return fakeApi(
    {
      data: {
        size: pagination.size,
        offset: pagination.offset,
        // data: POPUP_LIST_MOCK(shop),
        data: [],
        total: 1,
      },
    },
    1000
  )
}

export function createPopup(shop, payload) {
  const { name } = payload

  if (!name) {
    return null
  }

  return fakeApi({ data: { popup: { ...payload } } }, 1000)
  // return api.post(`/aov/popups/`, payload, {
  //   params: {
  //     shop,
  //   },
  // })
}

export function updatePopup(shop, payload) {
  if (!payload.id) {
    return null
  }

  return fakeApi({ data: { popup: { ...payload } } }, 1000)
  // return api.put(`/aov/popups/${payload.id}/`, payload, {
  //   params: {
  //     shop,
  //   },
  // })
}
const POPUP_LIST_MOCK = (shop) => [
  {
    id: 3,
    name: 'New popup',
    recommendationType: null,
    triggers: {},
    template: null,
    stats: {},
    status: 'inactive',
    inclusions: [
      { pageType: 'home', pageLinks: [] },

      {
        pageType: 'product_page',
        pageLinks: [
          `https://${shop}/products/some_product`,
          `https://${shop}/products/some_other_product`,
        ],
      },
    ],
    exclusions: [
      {
        pageLinks: [
          `https://${shop}/products/some_product`,
          `https://${shop}/products/some_other_product`,
        ],
      },
    ],
  },
  {
    id: 1,
    name: 'Extra popup',
    recommendationType: null,
    triggers: {},
    template: null,
    stats: {},
    status: 'inactive',
    inclusions: [
      { pageType: 'home', pageLinks: [] },

      {
        pageType: 'product_page',
        pageLinks: [
          `https://${shop}/products/some_product`,
          `https://${shop}/products/some_other_product`,
        ],
      },
    ],
    exclusions: [
      {
        pageLinks: [
          `https://${shop}/products/some_product`,
          `https://${shop}/products/some_other_product`,
        ],
      },
    ],
  },
]
