import React, { useEffect } from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import { useQueryParams } from 'hooks/router'
import { REDIRECT_ON_LOGIN_STORAGE_KEY } from 'constants/support'

import helpImage from './help.jpg'

export default function AuthFail({ error }) {
  const { shop } = useQueryParams()

  useEffect(() => {
    localStorage.setItem(
      REDIRECT_ON_LOGIN_STORAGE_KEY,
      window.location.pathname
    )
  }, [])

  return (
    <div className="flex flex-col items-center flex-1 p-5 bg-gray-200 md:p-10 xl:p-20">
      <Box className="flex flex-col items-stretch w-full mb-6 lg:w-5/6 xl:w-3/4">
        <span className="mt-3 mb-4 text-xl font-semibold text-center text-gray-800">
          There was an error in logging you in... 😿
        </span>
        <div className="flex self-center mb-4">
          <Button href={`https://${shop}/admin/apps/recommendation-kit/`}>
            Continue to Login...
          </Button>
        </div>
        <img src={helpImage} alt="Shopify Admin Apps Page" />
        <span className="mt-1 mb-3 text-sm text-gray-600">
          Please close this tab and try to open the application again from your
          Shopify admin applications tab. Incase you are still not able to login{' '}
          <a className="text-primary underline" href="mailto:support@glood.ai">
            Contact Us
          </a>
        </span>
      </Box>
    </div>
  )
}
