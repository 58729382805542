import { useState } from 'react'

import api from 'api'
import * as QueryUtils from 'utils/query'

export default function useCreateWidget (pages) {
  const [widget, setWidget] = useState(null)
  const [savingWidget, setSavingWidget] = useState(false)

  async function createWidget(
    widget,
    pagesToEnableWidgetOn,
    shop,
    shopId,
    experience
  ) {
    setSavingWidget(true)
    const pagesData = pages.data
    const existingWidgets = pagesData.reduce((widgets, page) => {
      var expWidgets = []
      page.widgets.forEach((widget) => {
        if (widget.experience.id === experience.id) {
          expWidgets.push(widget)
        }
      })
      return [...widgets, ...expWidgets]
    }, [])

    return await Promise.all(
      pagesToEnableWidgetOn.map(async (page) => {
        return await enableWidgetForPage(
          widget,
          page,
          shop,
          shopId,
          existingWidgets,
          pagesData,
          experience
        )
      })
    )
      .then(async (widget) => {
        if (typeof pages.get === 'function') {
          await pages.get()
        }
        await QueryUtils.invalidate('pages')

        return widget
      })
      .then(
        (widget) => {
          setSavingWidget(false)
          return widget
        },
        () => setSavingWidget(false)
      )
  }

  return [widget, setWidget, createWidget, savingWidget]
}

async function enableWidgetForPage(
  widget,
  pageType,
  shop,
  shopId,
  existingWidgets,
  pagesData,
  experience
) {
  const { value: widgetType } = widget
  let widgetForPage = existingWidgets.filter(
    (widget) => widget.type === widgetType && widget.pageType === pageType
  )[0]

  if (!widgetForPage) {
    let page = pagesData.filter((p) => p.type === pageType)[0]
    if (!page) {
      page = await createPage(shop, shopId, pageType)
    }

    widgetForPage = await createWidgetForPage(
      widget,
      page,
      shop,
      shopId,
      experience
    )
  }

  if (!widgetForPage.enabled) {
    const request = { ...widgetForPage, enabled: true }
    await api.put(`widgets/${widgetForPage.id}/`, request, { params: { shop } })
  }
  return widgetForPage
}

async function createWidgetForPage(widget, page, shop, shopId, experience) {
  const request = {
    page: page.id,
    type: widget.value,
    shop: shopId,
    title: widget.label === 'Custom' ? 'You May Also Like' : widget.label,
    ...(experience ? { experience: experience.id } : {}),
  }

  const { data: createdWidget } = await api.post('widgets/', request, {
    params: { shop },
  })

  return createdWidget
}

async function createPage(shop, shopId, pageType) {
  const { data: createdPage } = await api.post(
    'pages/',
    {
      shop: shopId,
      type: pageType,
    },
    {
      params: { shop },
    }
  )

  return createdPage
}
