import React from 'react'
import classNames from 'classnames'
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker'

import './styles.css'

export default function DateRangePicker({
  startDate,
  endDate,
  onRangeChange,
  className,
  maxDate = new Date(),
  minDate,
}) {
  return (
    <ReactDateRangePicker
      onChange={onRangeChange}
      value={[startDate, endDate]}
      maxDate={maxDate}
      minDate={minDate}
      required={true}
      className={classNames('bg-white p-1 rounded', className)}
    />
  )
}
