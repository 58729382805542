import classNames from 'classnames'
import Icons from 'components/Icons'
import React from 'react'

export default function Thresholds({ layout }) {
  const {
    data: {
      settings: {
        thresholds,
        general: { primaryColor, tertiaryColor },
      },
    },
    lineItems,
  } = layout

  const sortedThresholds = [...thresholds].sort((thresh1, thresh2) => {
    return thresh1.amount - thresh2.amount
  })
  if (sortedThresholds.length < 1) {
    return null
  }
  const lineItemsTotal = lineItems.reduce((accm, lineItem) => {
    return parseFloat(lineItem.price) + accm
  }, 0.0)

  return (
    <div className="flex flex-row items-center justify-center py-4 px-7">
      {sortedThresholds.map((threshold, index) => {
        const {
          label: { default: defaultLabel },
          type,
          amount,
        } = threshold
        const isCompleted = parseFloat(amount.default) < lineItemsTotal
        const previousThresholdCompleted =
          index > 0 &&
          parseFloat(sortedThresholds[index - 1].amount.default) <
            lineItemsTotal
        return (
          <div
            className="relative flex flex-col items-center w-32 overflow-hidden"
            key={`${type}-${defaultLabel}`}
          >
            <div className="flex flex-row justify-center">
              {index !== 0 && (
                <div
                  className={classNames(
                    'w-1/2 h-0.5 top-4  absolute right-20 transition-colors duration-200'
                  )}
                  style={{
                    background:
                      isCompleted || previousThresholdCompleted
                        ? primaryColor
                        : tertiaryColor,
                  }}
                ></div>
              )}
              <div
                className={classNames(
                  'flex items-center text-white justify-center p-2  rounded-full transition-colors duration-200'
                )}
                style={{
                  background: isCompleted ? primaryColor : tertiaryColor,
                }}
              >
                <Icons.Tick className="w-4 h-4 text-white stroke-current" />
              </div>
              {index !== sortedThresholds.length - 1 && (
                <div
                  className={classNames(
                    'w-1/2 h-0.5 top-4 absolute left-20 transition-colors duration-200'
                  )}
                  style={{
                    background: isCompleted ? primaryColor : tertiaryColor,
                  }}
                ></div>
              )}
            </div>
            <span
              className={classNames(
                'mt-2 text-sm transition-colors duration-200'
              )}
              style={{
                color: isCompleted ? primaryColor : tertiaryColor,
              }}
            >
              {defaultLabel}
            </span>
          </div>
        )
      })}
    </div>
  )
}
