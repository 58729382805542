import React from 'react'

import ResourceBuilder from 'components/ResourceBuilder'
import Button from 'components/Button'

import Details from './Screens/Details'
import Layout from './Screens/Layout'
import Review from './Screens/Review'
import useWidgetBuilder from './hooks/useWidgetBuilder'
import Configure from './Screens/Configure'

export default function AddWidgetNew({
  initialWidget,
  close,
  initialActiveScreen,
  pages,
  billing,
  account,
  experience,
}) {
  const builder = useWidgetBuilder({
    initialWidget,
    initialActiveScreen,
    account,
    experience,
    pages,
    billing,
  })

  const ActiveScreenComponent =
    SCREEN_NAME_TO_COMPONENT_NAME[builder.activeScreen.name]

  return (
    <ResourceBuilder
      builder={builder}
      close={close}
      resource={builder.localWidget}
      initialActiveScreen={initialActiveScreen}
      ActiveScreenComponent={ActiveScreenComponent}
      PrimaryCTA={PrimaryCTA}
      SecondaryCTA={SecondaryCTA}
    />
  )
}

function PrimaryCTA({ builder,rb }) {
  const {
    activeScreen,
    savingAndNextLoading,
    setSavingAndNextBtn,
    saveNextHandler,
    publishHandler,
  } = builder
  const isReviewScreen = activeScreen.name === 'review'
  if (isReviewScreen) {
    return (
      <Button
        loading={savingAndNextLoading}
        variant="solid"
        onClick={async () => {
          setSavingAndNextBtn(true)
          await publishHandler()
          setSavingAndNextBtn(false)
          rb.close({
            skipCloseHandler: true
          })
        }}
      >
        <div className="flex flex-row items-center justify-start">
          Publish Now
        </div>
      </Button>
    )
  }
  return (
    <Button
      loading={savingAndNextLoading}
      variant="solid"
      onClick={async () => {
        setSavingAndNextBtn(true)
        await saveNextHandler()
        setSavingAndNextBtn(false)
      }}
    >
      Save & Next
    </Button>
  )
}

function SecondaryCTA({ builder, rb }) {
  const {
    activeScreen,
    savingWidget,
    setSavingWidget,
    saveDisabled,
    saveHandler,
  } = builder
  const isReviewScreen = activeScreen.name === 'review'

  if (isReviewScreen) {
    return (
      <Button variant="bordered" className="mr-4" onClick={rb.close}>
        Close
      </Button>
    )
  }

  return (
    <Button
      variant="bordered"
      className="mr-4"
      loading={savingWidget}
      disabled={saveDisabled}
      onClick={async () => {
        setSavingWidget(true)
        await saveHandler()
        setSavingWidget(false)
      }}
    >
      Save
    </Button>
  )
}

const SCREEN_NAME_TO_COMPONENT_NAME = {
  detail: Details,
  layout: Layout,
  review: Review,
  configure: Configure,
}
