import React from 'react'
import classNames from 'classnames'
import Box from 'components/Box'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Badge from 'components/Badge'
import { getTemplateLabelFromType } from 'utils/template'

export default function TemplatesCard({ templates }) {
  return (
    <React.Fragment>
      {templates.map((template, index) => {
        return (
          <Box key={template.id} className="flex flex-col items-start mb-3">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row items-center justify-start">
                <div className="text-xl font-semibold leading-tight text-gray-800 ">
                  {template.name}
                </div>
                <Badge
                  color="yellow"
                  spacing="regular"
                  textSize="small"
                  className="ml-4"
                >
                  {getTemplateLabelFromType(template.type)}
                </Badge>
              </div>
              <div className="flex flex-row items-center justify-end">
                <Tooltip
                  position="right"
                  content="You can configure Liquid, CSS, JS & Settings for this template"
                >
                  <Button
                    size="xSmall"
                    variant="bordered"
                    href={`/templates/${template.id}`}
                    internalLink={true}
                  >
                    Configure
                  </Button>
                </Tooltip>
              </div>
            </div>
            {template.enabled && false && (
              <templateStats stats={template.stats} />
            )}
          </Box>
        )
      })}
    </React.Fragment>
  )
}
