import React, { useCallback, useState } from 'react'

import { useToast } from 'components/Toast'
import * as QueryUtils from 'utils/query'
import { _find, _identity, _get } from 'utils/lodash'
import { createWorkflowNode, updateWorkflowNode } from 'api/funnel'
import { getAccountData } from 'utils/account'

export default function useLayoutBuilder({
  initialPromotion = {},
  node,
  funnel,
  onSave,
}) {
  const [promotion, setPromotion] = useState(initialPromotion)
  const [activeScreen, setActiveScreen] = useState(SCREENS[0])
  const [saveHandler, setSaveHandler] = useState(_identity)
  const [promotionSaveError, setPromotionSaveError] = useState(null)
  const [saving, setSaving] = useState(false)
  const openToast = useToast()
  const { name: shop } = getAccountData()

  const savePromotionLayout = useCallback(
    async (
      data,
      {
        successMessage = 'Promotion saved successfully!',
        errorMessage = 'There was an error in saving the promotion.',
      } = {}
    ) => {
      setPromotionSaveError(null)
      try {
        const updatedPromotion = { ...promotion, steps: [data] }
        node.updateTask({
          settings: updatedPromotion,
        })
        if (node.id) {
          const response = await updateWorkflowNode(shop, funnel, {
            id: node.id,
            settings: updatedPromotion,
          })
          QueryUtils.invalidate('workflow-nodes')
        }

        openToast({
          type: 'success',
          text: successMessage,
        })
        if (onSave && typeof onSave === 'function') {
          await onSave()
        }
        setPromotion(updatedPromotion)
        return updatedPromotion
      } catch (err) {
        openToast({
          type: 'error',
          text: errorMessage,
        })
        setPromotionSaveError(err.response?.data)
      }
    },
    [promotion]
  )

  return {
    screens: SCREENS,
    promotion,
    activeScreen,
    setActiveScreen,
    savePromotionLayout,
    promotionSaveError,
    saveHandler,
    setSaveHandler,
    saving,
    setSaving,
    heading: 'Create Layout',
  }
}

export const SCREENS = [
  {
    name: 'layout',
    label: 'Layout',
    complete: (promotion) =>
      Array.isArray(promotion.steps) &&
      _get(promotion, 'steps[0].sections', []).length > 0,
    disabled: () => false,
  },
]
