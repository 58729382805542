import { WorkflowReview } from 'containers/Funnels/FunnelBuilder/Review/Overview'
import React from 'react'

export default function Funnel({ funnel, onEdit, accountData }) {
  return (
    <WorkflowReview
      funnel={funnel}
      onEdit={() => onEdit('workflow')}
      accountData={accountData}
    />
  )
}
