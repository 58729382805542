import React, { useEffect } from 'react'

export function BecomePartnerForm() {
  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '22080564',
        formId: '5e9a7dde-488e-46fb-8425-404abf7e6f54',
        target: '#hubspotBecomePartnerForm',
      })
    }
  }, [])

  return (
    <div>
      <div id="hubspotBecomePartnerForm"></div>
    </div>
  )
}
