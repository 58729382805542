import React from 'react'

import { getAccountData } from 'utils/account'

import Header from './Header'
import useResourceBuilder from './hooks/useResourceBuilder'

export default function ResourceBuilder({
  builder,
  resource,
  PrimaryCTA,
  SecondaryCTA,
  ActiveScreenComponent,
  close,
}) {
  const rb = useResourceBuilder({
    resource,
    close,
    screens: builder.screens,
  })

  return (
    <div className="flex flex-col items-stretch h-full">
      <Header
        rb={rb}
        builder={builder}
        PrimaryCTA={PrimaryCTA}
        SecondaryCTA={SecondaryCTA}
      />
      <div className="flex flex-1 min-h-0">
        <div className="relative flex-1 overflow-y-auto">
          <ActiveScreenComponent
            rb={rb}
            builder={builder}
            accountData={getAccountData()}
          />
        </div>
      </div>
    </div>
  )
}
