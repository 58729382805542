import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { validCssColor } from 'utils/browser'

const COMPARE_PRICE_MODES = [
  { label: 'Hidden', value: 'hidden' },
  { label: 'Before Original Price', value: 'before' },
  { label: 'After Original Price', value: 'after' },
]

const COMPARE_PRICE_VISIBILITY_OPTIONS = [
  {
    label: 'Hidden',
    value: 'hidden',
  },
  {
    label: 'Visible',
    value: 'visible',
  },
]

export default function PricesForm(props) {
  const {
    onChange,
    settings: { productCard },
  } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])
  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Price Font Size"
          name="fontSize"
          control="input"
          type="number"
          ref={register({
            required: 'Please enter a valid number as product price font size',
          })}
          formError={errors.fontSize}
        />

        {typeof props.settings.productCard.priceFontFamily !== 'undefined' && (
          <Form.Field
            label="Price Font Family"
            name="priceFontFamily"
            control="input"
            type="text"
            ref={register()}
            formError={errors.priceFontFamily}
          />
        )}
        <Form.Field
          label="Compare At Price Mode"
          name="comparePriceMode"
          control="select"
          options={COMPARE_PRICE_MODES}
          ref={register({
            required: 'Compare price mode cannot be empty',
          })}
        />
        {typeof productCard.comparePriceVisibilityMobile !== 'undefined' && (
          <Form.Field
            label="Compare At Price Visibility (Mobile)"
            name="comparePriceVisibilityMobile"
            control="select"
            options={COMPARE_PRICE_VISIBILITY_OPTIONS}
            ref={register({
              required: 'Compare price mode cannot be empty',
            })}
          />
        )}
      </Form.FieldRow>
      <Form.FieldRow className="pt-3 mt-3 border-t border-gray-200">
        <Form.Field
          label="Compare At Price Color"
          name="comparePriceColor"
          control="color"
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          setColor={setValue}
          color={formValues.comparePriceColor}
          formError={errors.comparePriceColor}
        />
        <Form.Field
          label="Sale Price Color"
          name="salePriceColor"
          control="color"
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          setColor={setValue}
          color={formValues.salePriceColor}
          formError={errors.salePriceColor}
        />
        <Form.Field
          label="Price Color"
          name="defaultPriceColor"
          control="color"
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          setColor={setValue}
          color={formValues.defaultPriceColor}
          formError={errors.defaultPriceColor}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings }) {
  return {
    comparePriceColor: settings.productCard.priceColorCompare,
    salePriceColor: settings.productCard.priceColorOriginal,
    defaultPriceColor: settings.productCard.priceColor,
    fontSize: parseInt(settings.productCard.priceFontSize),
    comparePriceMode: settings.productCard.priceCompareAtMode,
    priceFontFamily: settings.productCard.priceFontFamily,
    comparePriceVisibilityMobile:
      settings.productCard.comparePriceVisibilityMobile,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      productCard: {
        priceColor: values.defaultPriceColor,
        priceColorOriginal: values.salePriceColor,
        priceColorCompare: values.comparePriceColor,
        priceFontSize: `${values.fontSize}px`,
        priceCompareAtMode: values.comparePriceMode,
        priceFontFamily: values.priceFontFamily,
        comparePriceVisibilityMobile: values.comparePriceVisibilityMobile,
      },
    },
  }
}
