import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { _find, _get } from 'utils/lodash'
import { useForm } from 'react-hook-form'

import WaitForData from 'components/WaitForData'
import Button from 'components/Button'
import Box from 'components/Box'
import { Select } from 'components/Form/controls'
import Form from 'components/Form'
import Callout from 'components/Callout'
import ChatWithUs from 'components/ChatWithUs'
import WidgetPlacement from 'components/WidgetPlacement'
import WidgetEditor from 'components/WidgetEditor'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import * as FilterCriteria from 'constants/widgets/filterCriteria'
import * as RankingCriteria from 'constants/widgets/rankingCriteria'
import { isFeatureEnabled, isTemplateEnabled } from 'utils/features'
import { isRkSupport } from 'utils/account'
import {
  excludedOptionsFromType,
  includedOptionsFromType,
} from 'utils/constants'
import * as Tracker from 'utils/tracker'
import { RETURNS } from 'constants/pages'
import { RETURNS as RETURNS_INTEGRATION } from 'constants/integrations'
import { WIDGET_POSITIONING_HELP_LINK } from 'constants/support'
import { TRANSLATIONS } from 'constants/features'
import { useApi } from 'hooks/api'

import TitleTranslationsModal from './TitleTranslationsModal'
import Segmentation from 'components/Segmentation'

export default function WidgetGeneral({ account, store, widget, onSave }) {
  return (
    <div className="flex flex-col">
      {widget.pageType === RETURNS.value && (
        <ReturnsPageSettings account={account} store={store} />
      )}
      <Translations account={account} widget={widget} onSave={onSave} />
      <WidgetActions widget={widget} onSave={(data) => onSave(data)} />
      <div className="flex flex-row items-start justify-between p-0">
        {isTemplateEnabled(account.data) && (
          <div className="flex-1 mr-6 h-full">
            <WidgetEditor
              widget={widget}
              account={account.data}
              store={store.data}
              onSave={onSave}
            />
          </div>
        )}
        <div className="flex-1">
          <Box>
            <div className="flex flex-col items-start">
              <span className="mb-3 text-sm font-semibold text-gray-800 uppercase">
                Widget Position
              </span>
              <Button href={WIDGET_POSITIONING_HELP_LINK} target="_blank">
                Update Widget Position
              </Button>
            </div>
          </Box>
        </div>
      </div>
      <Visibility widget={widget} onSave={onSave} account={account} />
      <Segmentation widget={widget} onSave={onSave} account={account} />
    </div>
  )
}

function Visibility({ account, widget, onSave }) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      maxContent: widget.maxContent,
      allowOutOfStock: widget.allowOutOfStock,
      allowProductsWithoutImage: widget.allowProductsWithoutImage,
      automaticEnabled: widget.automaticEnabled,
      enableRandom: widget.enableRandom,
      filterCriteria: widget.filterCriteria,
      rankingCriteria: widget.rankingCriteria,
    },
  })
  const [saving, setSaving] = useState(false)

  const excludedOptions = excludedOptionsFromType(widget.type) || []
  const includedOptions = includedOptionsFromType(widget.type) || []

  const hasFields =
    !excludedOptions.includes('automaticEnabled') ||
    includedOptions.includes('randomEnabled') ||
    includedOptions.includes('filterCriteria') ||
    includedOptions.includes('rankingCriteria') ||
    !isTemplateEnabled(account.data)

  return hasFields ? (
    <Box className="mb-5">
      <div className="mb-5 text-sm font-semibold text-gray-800 uppercase">
        Recommendations Behaviour
      </div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(async (data) => {
          setSaving(true)
          await onSave(data)
          setSaving(false)
        })}
      >
        {!excludedOptions.includes('automaticEnabled') && (
          <Form.Field
            label="Automatic Recommendations"
            name="automaticEnabled"
            control="checkbox"
            ref={register()}
            spacing="loose"
            helpText="Check this option to show recommendations based on your store's sales data. We use our proprietary algorithms to generate these."
            display="none"
          />
        )}
        {includedOptions.includes('randomEnabled') && (
          <Form.Field
            label="Random Recommendations"
            name="enableRandom"
            control="checkbox"
            ref={register()}
            spacing="loose"
            helpText="Check this option to fallback to randomized product recommendations incase automatic/manual recommendations are not found."
            display="none"
          />
        )}
        {includedOptions.includes('rankingCriteria') && (
          <Form.Field
            label="Product Ranking Criteria"
            name="rankingCriteria"
            control="select"
            options={[
              RankingCriteria.PERSONALISED,
              RankingCriteria.BESTSELLERS,
              RankingCriteria.NEW_ARRIVALS,
              RankingCriteria.HIGHEST_PRICED,
              RankingCriteria.LOWEST_PRICED,
            ]}
            ref={register()}
            spacing="loose"
            helpText="This is the criteria which should be used for ranking products. Change this according to your store setup."
          />
        )}
        {includedOptions.includes('filterCriteria') && (
          <Form.Field
            label="Product Filter Attribute"
            name="filterCriteria"
            control="select"
            options={[FilterCriteria.PRODUCT_TYPE, FilterCriteria.VENDOR]}
            ref={register()}
            spacing="loose"
            helpText="This is the criteria which should be used for filtering products. Change this according to your store setup."
          />
        )}
        {!isTemplateEnabled(account.data) && (
          <div className="flex flex-row justify-between">
            <Form.Field
              label="Maximum number of recommended products in the widget"
              name="maxContent"
              control="input"
              className="flex-1 mr-4"
              type="number"
              ref={register()}
              spacing="loose"
              helpText="This is the maximum number of recommended products that will show up in the widget."
            />
          </div>
        )}
        <div className="flex flex-row justify-end">
          <Button loading={saving} size="small">
            Save
          </Button>
        </div>
      </form>
    </Box>
  ) : null
}

function Translations({ account, widget, onSave }) {
  const openModal = useOpenModal()
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      title: widget.title,
    },
  })
  const [saving, setSaving] = useState(false)

  React.useEffect(() => {
    setValue('title', widget.title)
  }, [widget.title])
  const translationsEnabled = isFeatureEnabled(account.data, TRANSLATIONS)

  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal account={account} featureToEnable={TRANSLATIONS} />
    )
  }
  return (
    <Box className="mb-5">
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(async (data) => {
          setSaving(true)
          await onSave(data)
          setSaving(false)
        })}
      >
        <Form.Field
          label="Widget Title"
          name="title"
          control="input"
          ref={register({ required: 'Please enter a title for the widget' })}
          formError={errors.title}
          spacing="loose"
          helpText="You can give a custom title to this widget. This title will be shown on your Store."
        />
        <div className="flex flex-row justify-end">
          {isTemplateEnabled(account.data) && (
            <div
              className="flex items-center mr-6 text-xs font-semibold cursor-pointer text-primary hover:underline"
              onClick={() => {
                openModal(
                  <TitleTranslationsModal
                    widget={widget}
                    account={account}
                    onSave={onSave}
                    onUpgrade={!translationsEnabled && openUpgradeAccount}
                  />
                )
              }}
            >
              Have multi-language store? Set title translations here
            </div>
          )}

          <Button loading={saving} size="regular">
            Save
          </Button>
        </div>
      </form>
    </Box>
  )
}

function WidgetActions({ widget, onSave }) {
  const isEnabled = widget.enabled
  return (
    <Box className="flex flex-col items-stretch mb-5">
      <div className="flex flex-row items-center justify-between text-sm text-gray-800">
        <span className="flex flex-col">
          <span className={classNames({ 'text-red-600': !isEnabled })}>
            This widget is{' '}
            <strong>{isEnabled ? 'Enabled ' : 'Disabled '}</strong>
            on your store.
          </span>
          {isEnabled ? null : (
            <span className="mt-1 text-sm text-gray-600">
              Facing any issues with the widget setup?{' '}
              <span className="font-semibold">
                <ChatWithUs />
              </span>
            </span>
          )}
        </span>
        <Button
          className="w-24"
          variant={isEnabled ? 'bordered' : 'solid'}
          size="xSmall"
          onClick={(event) => {
            onSave(
              {
                enabled: !widget.enabled,
              },
              event,
              widget.enabled
                ? 'Widget is now disabled on the store!'
                : 'Widget is now enabled on the store!'
            )
            Tracker.record(
              `widget_action:${!widget.enabled ? 'enable' : 'disable'}`
            )
            Tracker.flush()
          }}
        >
          {isEnabled ? 'Disable' : 'Enable'}
        </Button>
      </div>
    </Box>
  )
}

function ReturnsPageSettings({ account, store }) {
  const { name: shop } = account.data
  const { js: customHooks } = store.data
  const integrations = useApi('integrations/', { shop })

  var customHookAdded
  try {
    const allHooks = new Function(customHooks)()
    customHookAdded = typeof allHooks.loadReturnsPage === 'function'
  } catch (err) { }
  return (
    <Box className="mb-6">
      <div className="flex flex-row items-center justify-between">
        <span>Enable one of our returns page app integrations</span>
        <div className="">
          <WaitForData
            objects={[integrations]}
            loadingMessage="Fetching integrations data"
            errorMessage="Trouble fetching integrations data"
          >
            {() => {
              const integrationEnabled = _find(
                integrations.data,
                (integration) =>
                  integration.group === RETURNS_INTEGRATION.value &&
                  integration.isEnabled
              )
              return integrationEnabled ? (
                <div className="flex flex-col items-end justify-center">
                  <span className="px-2 text-sm">
                    Integration :{' '}
                    <span className="font-semibold">{`${integrationEnabled.name}`}</span>
                  </span>
                  <Button
                    size="xSmall"
                    variant="text"
                    href={`/integrations/returns`}
                    internalLink={true}
                  >
                    View all integrations
                  </Button>
                </div>
              ) : customHookAdded ? (
                <div className="flex flex-col items-end justify-center">
                  <span className="px-2 text-sm">
                    Integration :{' '}
                    <span className="font-semibold">Custom Implementation</span>
                  </span>

                  <Button
                    size="xSmall"
                    variant="text"
                    href={`/js`}
                    internalLink={true}
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                <div className="flex justify-end">
                  <Button href={`/integrations/returns`} internalLink={true}>
                    View all integrations
                  </Button>
                </div>
              )
            }}
          </WaitForData>
        </div>
      </div>
    </Box>
  )
}
