import React from 'react'
import classNames from 'classnames'
import { DateTimePicker } from 'react-widgets'
import { Controller } from 'react-hook-form'

import ColorPicker from 'components/ColorPicker'

import { Radio, Select, SelectVersionTwo, Switch } from './controls'

const Form = {}

function Field(
  {
    label,
    helpText,
    helpTextSizeClass = 'text-xs',
    supportElement,
    control,
    spacing = 'regular',
    className,
    formError,
    formControl,
    ...controlProps
  },
  ref
) {
  if (controlProps.name) {
    controlProps.id = controlProps.id || controlProps.name
  }

  const fieldClassName = classNames(
    'flex flex-col items-stretch',
    FIELD_SPACING_TO_CLASS_MAP[spacing],
    className
  )

  let content = null
  if (control === 'select') {
    const { options, controlClassName, ...restControlProps } = controlProps
    content = (
      <Select
        {...restControlProps}
        className={controlClassName}
        options={options}
        ref={ref}
      />
    )

    return (
      <div className={fieldClassName}>
        {label && (
          <label
            className="mb-1 text-xs font-semibold"
            htmlFor={controlProps.name}
          >
            {label}
          </label>
        )}
        {content}
        {helpText &&
          helpText.split('\n').map((str, index) => (
            <div
              className={classNames(
                'flex justify-end mt-1 italic text-gray-600',
                helpTextSizeClass
              )}
              key={index}
            >
              {str}
            </div>
          ))}
        {supportElement ? supportElement : null}
      </div>
    )
  }

  if (control === 'switch') {
    const { controlClassName, ...restControlProps } = controlProps
    return (
      <Switch className={controlClassName} {...restControlProps} ref={ref} />
    )
  }

  if (control === 'radio') {
    const { controlClassName, ...restControlProps } = controlProps
    return (
      <Radio className={controlClassName} {...restControlProps} ref={ref} />
    )
  }

  if (control === 'color') {
    const { controlClassName, setColor, color, id, ...restControlProps } =
      controlProps

    content = (
      <div className="relative">
        <input
          {...restControlProps}
          ref={ref}
          className={classNames(
            'block w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline',
            controlClassName
          )}
        />

        <div className="absolute top-0 right-0 flex items-center h-full mr-2">
          <ColorPicker
            value={color}
            onChange={(value) => setColor(id, value, true)}
            pickerPosition="bottom"
          />
        </div>
      </div>
    )

    return (
      <div className={fieldClassName}>
        <div className="flex flex-row justify-between mb-1 text-xs font-semibold">
          <label className="flex-1" htmlFor={controlProps.name}>
            {label}
          </label>
          {formError && (
            <span className="text-red-600">{formError.message || 'Error'}</span>
          )}
        </div>
        {content}
        {helpText && (
          <div className="flex justify-end mt-1 text-xs italic text-gray-600">
            {helpText}
          </div>
        )}
        {supportElement ? supportElement : null}
      </div>
    )
  }

  if (control === 'date-time') {
    const { setDateTime, id, controlClassName, ...restControlProps } =
      controlProps

    // content = (
    //   <DateTimePicker
    //     onChange={(value) => setDateTime(id, value, true)}
    //     {...restControlProps}
    //   />
    // )
    content = (
      <Controller
        as={DateTimePicker}
        control={formControl}
        onChange={(v) => v[0]}
        className={controlClassName}
        {...restControlProps}
      />
    )
    return (
      <div className={fieldClassName}>
        <div className="flex flex-row justify-between mb-1 text-xs font-semibold">
          <label className="flex-1" htmlFor={controlProps.name}>
            {label}
          </label>
          {formError && (
            <span className="text-red-600">{formError.message || 'Error'}</span>
          )}
        </div>
        {content}
        {helpText && (
          <div
            className={classNames(
              'flex justify-end mt-1 italic text-gray-600',
              helpTextSizeClass
            )}
          >
            {helpText}
          </div>
        )}
      </div>
    )
  }

  if (control === 'input' || control === 'textarea') {
    const { controlClassName, valType, currencySymbol, ...restControlProps } =
      controlProps
    const TagName = control
    const isPercentOrCurr =
      valType && (valType === 'percentage' || valType === 'currency')
    const isPercent = valType && valType === 'percentage'
    content = (
      <TagName
        {...restControlProps}
        ref={ref}
        className={classNames(
          'block w-full bg-white border border-gray-400 hover:border-gray-500 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline',
          controlClassName,
          {
            'px-5': isPercentOrCurr && !isPercent,
            'px-4': !isPercentOrCurr || isPercent,
          }
        )}
      />
    )

    return (
      <div className={fieldClassName}>
        <div className="flex flex-row justify-between mb-1 text-xs font-semibold">
          <label className="flex-1" htmlFor={controlProps.name}>
            {label}
          </label>
          {formError && (
            <span className="text-red-600">{formError.message || 'Error'}</span>
          )}
        </div>
        {isPercentOrCurr ? (
          <div className="relative">
            {content}
            <span
              className={classNames('absolute top-1/2 -translate-y-1/2', {
                'left-2': !isPercent,
                'right-2': isPercent,
              })}
            >
              {valType === 'percentage'
                ? '%'
                : currencySymbol
                ? currencySymbol
                : '$'}
            </span>
          </div>
        ) : (
          <React.Fragment>{content}</React.Fragment>
        )}

        {helpText && (
          <div
            className={classNames(
              'flex justify-end mt-1 italic text-gray-600',
              helpTextSizeClass
            )}
          >
            {helpText}
          </div>
        )}
        {supportElement ? supportElement : null}
      </div>
    )
  }

  if (control === 'checkbox') {
    const { controlClassName, ...restControlProps } = controlProps
    content = (
      <input
        {...restControlProps}
        type="checkbox"
        ref={ref}
        className={classNames(
          'block bg-white border border-gray-400 hover:border-gray-500 mr-2 cursor-pointer rounded shadow leading-tight focus:outline-none focus:shadow-outline',
          controlClassName
        )}
      />
    )

    return (
      <div className={fieldClassName}>
        <div className="flex flex-row items-center justify-between border border-2 border-gray-400 rounded shadow">
          <label
            className="w-full p-2 text-xs font-semibold cursor-pointer"
            htmlFor={controlProps.name}
          >
            {label}
          </label>
          {content}
        </div>
        {helpText && (
          <div
            className={classNames(
              'flex justify-end mt-1 italic text-gray-600',
              helpTextSizeClass
            )}
          >
            {helpText}
          </div>
        )}
      </div>
    )
  }
}

const FIELD_SPACING_TO_CLASS_MAP = {
  regular: 'mb-3',
  loose: 'mb-6',
}

function FieldRow({ children, className }) {
  return (
    <div
      className={classNames(
        'flex flex-row items-start justify-between',
        className
      )}
    >
      {children.filter(Boolean).map((child, index) => (
        <div
          className={classNames('flex flex-1 flex-col items-stretch', {
            'ml-3': index !== 0,
          })}
          key={index}
        >
          {child}
        </div>
      ))}
    </div>
  )
}

Form.Field = React.forwardRef(Field)
Form.FieldRow = FieldRow

export default Form
