import React, { useEffect, useState } from 'react'

import Form from 'components/Form'
import { useToast } from 'components/Toast'

export default function Details({ builder }) {
  const {
    funnel,
    saveFunnel,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder

  const [name, setName] = useState(funnel?.name || '')
  const openToast = useToast()

  useEffect(() => {
    async function funnelSave() {
      if (!name) {
        openToast({
          type: 'error',
          text: 'Please choose a name for the funnel',
        })
        return false
      }

      await saveFunnel({ name })
    }
    setSaveHandler(() => funnelSave)
    setSaveNextHandler(() => async () => {
      const savedFunnel = await funnelSave()
      if (!savedFunnel) {
        setActiveScreen('trigger')
      }
    })
  }, [name])
  return (
    <div className="flex flex-col w-full px-8 mt-6">
      <span className="mb-4 text-lg font-semibold uppercase">Basic</span>
      <Form.Field
        label="Name"
        name="name"
        control="input"
        value={name}
        onChange={(event) => {
          setName(event.target.value)
        }}
      />
    </div>
  )
}
