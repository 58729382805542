import React from 'react'
import EmailEditor from 'components/EmailEditor'
import { SectionHeading } from '../index'

export default function Editor({ account, campaign, onCampaignSaved }) {
  return (
    <React.Fragment>
      <SectionHeading>Edit Email Template</SectionHeading>
      <EmailEditor account={account} campaign={campaign} onSave={onCampaignSaved} />
    </React.Fragment>
  )
}
