import { useReducer, useState } from 'react'
import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

import { _debounce, _find } from 'utils/lodash'
import { OPERATORS } from '../constants'

export default function useTriggerRules({ initialRules }) {
  initialRules =
    initialRules && Object.keys(initialRules).length > 0
      ? initialRules
      : {
          ...INITIAL_RULES_STATE,
          id: uuid(),
        }

  const [state, dispatch] = useReducer(
    rulesReducer,
    identifyRules(initialRules)
  )
  const [conditionSliderGroupId, setConditionSliderGroupId] = useState(null)

  if (process.env.NODE_ENV !== 'production') {
    window._triggerState = state
    window._triggerUpdate = update
  }

  return {
    data: state,

    addNewGroup: ({ groupId }) => {
      dispatch({ type: 'ADD_GROUP', groupId })
    },

    addNewCondition: ({ attribute }) => {
      dispatch({
        type: 'ADD_ATTRIBUTE',
        attribute,
        groupId: conditionSliderGroupId,
      })
      // Allow ADD_ATTRIBUTE dispatch to resolve before closing slider
      setTimeout(() => setConditionSliderGroupId(null), 50)
    },

    deleteCondition: ({ type, id }) => {
      if (type === 'group') {
        dispatch({ type: 'DELETE_GROUP', groupId: id })
      } else if (type === 'attribute') {
        dispatch({ type: 'DELETE_ATTRIBUTE', attributeId: id })
      }
    },

    updateCondition: _debounce(({ id, groupId, update }) => {
      dispatch({
        type: 'UPDATE_ATTRIBUTE',
        id,
        groupId,
        update,
      })
    }, 0),

    updateGroup: ({ groupId, update }) => {
      dispatch({
        type: 'UPDATE_GROUP',
        groupId,
        update,
      })
    },

    conditionSliderVisible: !!conditionSliderGroupId,
    openConditionSlider: ({ groupId }) => setConditionSliderGroupId(groupId),
    closeConditionSlider: () => {
      setConditionSliderGroupId(null)
    },
  }
}

function identifyRules(rulesObject) {
  if (!rulesObject.id) {
    rulesObject.id = uuid()
  }

  if (rulesObject.conditions && rulesObject.conditions.length > 1) {
    rulesObject.conditions.forEach((condition) => {
      if (condition.type === 'attribute' && !condition.id) {
        condition.id = uuid()
      } else if (condition.type === 'group') {
        identifyRules(condition)
      }
    })
  }

  return rulesObject
}

function rulesReducer(state, action) {
  switch (action.type) {
    case 'ADD_GROUP':
      return update(state, buildAddConditionUpdate(state, action.groupId))
    case 'DELETE_GROUP':
      return update(state, buildDeleteConditionUpdate(state, action.groupId))
    case 'UPDATE_GROUP':
      return update(
        state,
        buildUpdateGroupUpdate(state, action.groupId, action.update)
      )

    case 'ADD_ATTRIBUTE':
      return update(
        state,
        buildAddConditionUpdate(state, action.groupId, action.attribute)
      )
    case 'DELETE_ATTRIBUTE':
      return update(
        state,
        buildDeleteConditionUpdate(state, action.attributeId)
      )
    case 'UPDATE_ATTRIBUTE':
      const u = buildUpdateConditionUpdate(
        state,
        action.groupId,
        action.id,
        action.update
      )
      return update(state, u)

    default:
      return state
  }
}

function buildAddConditionUpdate(conditionGroup, groupId, attribute) {
  if (conditionGroup.id === groupId) {
    const conditionItem =
      attribute && attribute.label
        ? {
            type: 'attribute',
            attribute: attribute.label,
            operator: attribute.operators[0],
            values: [],
            id: uuid(),
          }
        : {
            type: 'group',
            operator: OPERATORS.AND.value,
            id: uuid(),
            conditions: [],
          }

    return {
      conditions: {
        $push: [conditionItem],
      },
    }
  }

  return conditionGroup.conditions.reduce((accum, condition, i) => {
    if (condition.type === 'group') {
      const u = buildAddConditionUpdate(condition, groupId, attribute)
      return {
        conditions: {
          ...accum.conditions,
          [i]: {
            $apply: (o) => update(o, u),
          },
        },
      }
    } else {
      return accum
    }
  }, {})
}

function buildDeleteConditionUpdate(conditionGroup, conditionId) {
  const match = _find(conditionGroup.conditions, (c) => c.id === conditionId)
  const matchIndex = conditionGroup.conditions.indexOf(match)

  if (matchIndex >= 0) {
    return {
      conditions: {
        $splice: [[matchIndex, 1]],
      },
    }
  }

  return conditionGroup.conditions.reduce((accum, condition, i) => {
    if (condition.type === 'group') {
      const u = buildDeleteConditionUpdate(condition, conditionId)
      return {
        conditions: {
          ...accum.conditions,
          [i]: {
            $apply: (o) => update(o, u),
          },
        },
      }
    } else {
      return accum
    }
  }, {})
}

function buildUpdateConditionUpdate(
  conditionGroup,
  groupId,
  conditionId,
  updateObject
) {
  if (conditionGroup.id === groupId) {
    const match = _find(
      conditionGroup.conditions,
      (c) => c.type === 'attribute' && c.id === conditionId
    )
    const matchIndex = conditionGroup.conditions.indexOf(match)

    if (matchIndex >= 0) {
      return {
        conditions: {
          [matchIndex]: (o) => {
            return {
              ...o,
              ...updateObject,
            }
          },
        },
      }
    } else {
      return {}
    }
  }

  return conditionGroup.conditions.reduce((accum, condition, i) => {
    if (condition.type === 'group') {
      const u = buildUpdateConditionUpdate(
        condition,
        groupId,
        conditionId,
        updateObject
      )

      return {
        conditions: {
          ...accum.conditions,
          [i]: {
            $apply: (o) => update(o, u),
          },
        },
      }
    } else {
      return accum
    }
  }, {})
}

function buildUpdateGroupUpdate(conditionGroup, groupId, updateObject) {
  if (conditionGroup.id === groupId) {
    return {
      $merge: updateObject,
    }
  }

  return conditionGroup.conditions.reduce((accum, condition, i) => {
    if (condition.type === 'group') {
      const u = buildUpdateGroupUpdate(condition, groupId, updateObject)
      return {
        conditions: {
          ...accum.conditions,
          [i]: {
            $apply: (o) => update(o, u),
          },
        },
      }
    } else {
      return accum
    }
  }, {})
}

const INITIAL_RULES_STATE = {
  type: 'group',
  operator: OPERATORS.AND.value,
  conditions: [],
}
