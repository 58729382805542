import React from 'react'
import classNames from 'classnames'

import GlobalRecommendations from 'components/SetupRecommendations/GlobalRecommendations'
import WaitForData from 'components/WaitForData'
import { useApi } from 'hooks/api'
import * as Widgets from 'constants/widgets'
import {
  PRODUCT as PRODUCT_PAGE,
  CART as CART_PAGE,
  COLLECTION as COLLECTION_PAGE,
  ORDER_CONFIRM as ORDER_CONFIRM_PAGE,
  AJAX_CART,
  CHECKOUT as CHECKOUT_PAGE,
} from 'constants/pages'
import { RECOMMENDATION_RULES } from 'constants/features'

import ManualRecommendations from './ManualRecommendations'
import RecommendationRules from './RecommendationRules'

export default function ProductRecommendations({
  account,
  widgetType,
  pageType,
  widgetId,
  store,
  onClose,
  title,
}) {
  const [activeSection, setActiveSection] = React.useState(null)
  const [noOfWidgetsDisplayed, setNoOfWidgetsDisplayed] = React.useState(
    SECTIONS.reduce((obj, item) => {
      obj[item.type] = 2
      return obj
    }, {})
  )
  const {
    data: { name: shop },
  } = account
  const topProductsData = useApi('topproducts/', {
    shop,
    recommendationType: widgetType,
  })
  const rulesRecommendations = useApi('rules/', {
    shop,
    recommendation_type: widgetType,
    ...(!widgetId ? {} : { widget_id: widgetId }),
  })

  function handleOnClick(isVisible, isFeatureEnabled, section) {
    if (isVisible) {
      setActiveSection(section)
    }
  }

  function getRecommendationsData(type) {
    switch (type) {
      case 'global-recommendations':
        return topProductsData
      case 'recommendation-rules':
        return rulesRecommendations
      default:
        return null
    }
  }

  return (
    <WaitForData objects={[topProductsData, rulesRecommendations]}>
      {() =>
        activeSection ? (
          <div className="flex flex-col ">
            <activeSection.component
              widgetType={widgetType}
              widgetId={widgetId}
              onClose={() => setActiveSection(null)}
              account={account}
              store={store}
              recommendations={getRecommendationsData(activeSection.type)}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between mb-5 ">
              {title && <h3 className="text-2xl font-semibold ">{title}</h3>}
              {onClose && (
                <span
                  className="text-sm underline cursor-pointer text-primary"
                  onClick={() => onClose()}
                >
                  ← Go Back
                </span>
              )}
            </div>

            <div className="flex flex-row flex-wrap -mx-3">
              {SECTIONS.sort((sectionA, sectionB) => {
                const sectionAScore = sectionA.visible(
                  widgetType,
                  pageType,
                  account.data
                )
                  ? SECTIONS.indexOf(sectionA)
                  : 100

                const sectionBScore = sectionB.visible(
                  widgetType,
                  pageType,
                  account.data
                )
                  ? SECTIONS.indexOf(sectionB)
                  : 100

                return sectionAScore - sectionBScore
              }).map((section) => {
                const isVisible = section.visible(
                  widgetType,
                  pageType,
                  account.data
                )
                const isFeatureEnabled = section.isFeatureEnabled(account.data)
                return (
                  <div
                    className={classNames(
                      'flex flex-col items-stretch w-1/2 px-3 mb-5',
                      {
                        'opacity-50': !isVisible,
                      }
                    )}
                    key={section.type}
                  >
                    <div
                      className="relative flex flex-col h-full p-5 bg-white border border-gray-200 rounded cursor-default hover:border-gray-400"
                      onClick={() =>
                        handleOnClick(isVisible, isFeatureEnabled, section)
                      }
                    >
                      <span className="mb-2 text-xl font-semibold">
                        {section.label}
                      </span>
                      <span className="flex flex-col mb-2 text-sm text-gray-600">
                        {typeof section.helpText === 'function'
                          ? section.helpText(
                              getRecommendationsData(section.type),
                              isVisible
                            )
                          : section.helpText}
                      </span>
                      {!isVisible ? (
                        <span className="mt-2 text-xs text-red-800">
                          This type of recommendation is not available for this
                          widget. To enable this option add either{' '}
                          {section.supportedWidgets
                            .slice(0, noOfWidgetsDisplayed[section.type])
                            .map((supportedWidget, index, arr) => {
                              return (
                                <span key={supportedWidget.value}>
                                  <strong className="text-bold">
                                    {supportedWidget.label}
                                  </strong>
                                  {index < arr.length - 1 ? ', ' : ' '}
                                </span>
                              )
                            })}{' '}
                          {noOfWidgetsDisplayed[section.type] <
                          section.supportedWidgets.length ? (
                            <strong
                              className="underline cursor-pointer text-bold"
                              onClick={() => {
                                let update = { ...noOfWidgetsDisplayed }
                                update[section.type] =
                                  section.supportedWidgets.length
                                setNoOfWidgetsDisplayed(update)
                              }}
                            >
                              Show more ...
                            </strong>
                          ) : (
                            ''
                          )}
                        </span>
                      ) : null}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
    </WaitForData>
  )
}

const SECTIONS = [
  {
    type: 'manual-recommendations',
    label: '1-1 Manual Recommendations',
    helpText:
      'Choose products to recommend for each product in your catalog manually. These recommendations have the highest priority.',
    component: ManualRecommendations,
    visible: (widgetType, pageType, accountData) =>
      [
        Widgets.SIMILAR_PRODUCTS.value,
        Widgets.CROSS_SELL.value,
        Widgets.BOUGHT_TOGETHER.value,
      ].includes(widgetType),
    isFeatureEnabled: (accountData) => true,
    supportedWidgets: [
      Widgets.BOUGHT_TOGETHER,
      Widgets.CROSS_SELL,
      Widgets.SIMILAR_PRODUCTS,
    ],
  },
  {
    type: 'recommendation-rules',
    label: 'Rule Based Recommendations',
    helpText: (recommendations, isVisible) => {
      if (!recommendations.data) {
        return null
      }
      const {
        data: { rules },
      } = recommendations
      return (
        <div className="flex flex-col">
          <span>
            Recommend products based on collection type, tags, product types or
            vendor.
          </span>
          {isVisible && (
            <span className="mt-2 text-xs font-semibold text-primary">
              {rules.length} Recommendations Rules Configured
            </span>
          )}
        </div>
      )
    },
    component: RecommendationRules,
    visible: (widgetType, pageType, accountData) =>
      [Widgets.BOUGHT_TOGETHER.value, Widgets.CROSS_SELL.value].includes(
        widgetType
      ) &&
      [
        COLLECTION_PAGE.value,
        PRODUCT_PAGE.value,
        CART_PAGE.value,
        ORDER_CONFIRM_PAGE.value,
        AJAX_CART.value,
        CHECKOUT_PAGE.value,
      ].includes(pageType),
    isFeatureEnabled: (accountData) =>
      accountData &&
      accountData.plan.featuresEnabled.includes(RECOMMENDATION_RULES.value),
    supportedWidgets: [Widgets.BOUGHT_TOGETHER, Widgets.CROSS_SELL],
  },
  {
    type: 'global-recommendations',
    label: 'Global Recommendations',
    helpText: (recommendations, isVisible) => {
      if (!recommendations.data) {
        return null
      }
      const {
        data: { products },
      } = recommendations
      return (
        <div className="flex flex-col">
          <span>
            Want to always recommend some products. Use this option to quickly
            set them up.
          </span>
          {isVisible && (
            <span className="mt-2 text-xs font-semibold text-primary">
              {products.length} Global Recommendations Configured
            </span>
          )}
        </div>
      )
    },
    component: GlobalRecommendations,
    visible: (widgetType, pageType, accountData) =>
      [
        Widgets.BOUGHT_TOGETHER.value,
        Widgets.SIMILAR_PRODUCTS.value,
        Widgets.TRENDING.value,
        Widgets.PURCHASES.value,
        Widgets.BESTSELLERS.value,
        Widgets.RECENTLY_LAUNCHED.value,
      ].includes(widgetType),
    isFeatureEnabled: (accountData) => true,
    supportedWidgets: [
      Widgets.TRENDING,
      Widgets.BOUGHT_TOGETHER,
      Widgets.SIMILAR_PRODUCTS,
      Widgets.PURCHASES,
      Widgets.BESTSELLERS,
      Widgets.RECENTLY_LAUNCHED,
    ],
  },
]
