import * as Pages from 'constants/pages'
import * as Widgets from 'constants/widgets'
import * as Campaigns from 'constants/campaigns'
import * as Integrations from 'constants/integrations'
import * as EmailChannels from 'constants/campaigns/channels'

function findItem(type, collection) {
  return Object.keys(collection)
    .map((k) => collection[k])
    .filter(({ value }) => value === type)[0]
}

export function keyFromType(key, collection, type) {
  const item = findItem(type, collection)
  if (!item) {
    return
  }

  return item[key]
}

export function campaignLabelFromType(type) {
  return keyFromType('label', Campaigns, type)
}

export function campaignDescriptionFromType(type) {
  return keyFromType('description', Campaigns, type)
}

export function pageLabelFromType(type) {
  return keyFromType('label', Pages, type)
}

export function widgetDescriptionFromType(type) {
  return keyFromType('description', Widgets, type)
}

export function widgetLabelFromType(type) {
  return keyFromType('label', Widgets, type)
}

export function channelLabelFromType(type) {
  return keyFromType('label', EmailChannels, type)
}

export function excludedOptionsFromType(type) {
  return keyFromType('excludedOptions', Widgets, type)
}

export function integrationLabelFromType(type) {
  return keyFromType('label', Integrations, type)
}

export function includedOptionsFromType(type) {
  return keyFromType('includedOptions', Widgets, type)
}

export function integrationScoreFromType(type) {
  return keyFromType('score', Integrations, type)
}
