import React, { useState } from 'react'
import classNames from 'classnames'

import EditorForm from 'components/EditorForm'
import Button from 'components/Button'

import FreeGift from './FreeGift'
import Discount from './Discount'

export default function Thresholds({
  value,
  handleUpdate,
  accountData,
  formError,
}) {
  const [selectedThresholds, setSelectedThresholds] = useState(
    value.settings.thresholds || []
  )
  function addThreshold() {
    if (selectedThresholds.length < MAX_THRESHOLD) {
      let updatedThresholds = [
        ...selectedThresholds,
        {
          type: THRESHOLD_OPTIONS[1].value,
          amount: 0,
          label: 'Default label',
          settings: {},
        },
      ]
      setSelectedThresholds(updatedThresholds)
      handleUpdate({
        settings: {
          thresholds: updatedThresholds,
        },
      })
    }
  }

  function removeThreshold(index) {
    let updatedThresholds = [...selectedThresholds]
    updatedThresholds.splice(index, 1)
    setSelectedThresholds(updatedThresholds)
    handleUpdate({
      settings: {
        ...value,
        thresholds: updatedThresholds,
      },
    })
  }

  function updateThreshold(threshold, index) {
    let updatedThresholds = [...selectedThresholds]
    updatedThresholds[index] = threshold
    setSelectedThresholds(updatedThresholds)
    handleUpdate({
      settings: {
        thresholds: updatedThresholds,
      },
    })
  }
  return (
    <div className="flex flex-col items-stretch ">
      {selectedThresholds && selectedThresholds.length > 0 && (
        <div className="mb-4">
          {selectedThresholds.map((threshold, index) => {
            const { type } = threshold
            const Component = THRESHOLD_TYPE_TO_COMPONENT_MAP[type]

            return (
              <div
                className="flex flex-col items-stretch py-4 border-b border-gray-400 last:border-none"
                key={`threshold-${threshold.type}-${index}`}
              >
                <EditorForm
                  layout={THRESHOLD_LAYOUT}
                  value={threshold}
                  handleUpdate={(values) => {
                    const updateObj = {
                      ...threshold,
                      ...values,
                    }
                    updateThreshold(updateObj, index)
                  }}
                  formError={formError ? formError[index] : null}
                />
                {Component && (
                  <Component
                    threshold={threshold}
                    handleUpdate={(values) => {
                      updateThreshold(
                        {
                          ...threshold,
                          settings: {
                            ...threshold.settings,
                            ...values,
                          },
                        },
                        index
                      )
                    }}
                  />
                )}
                <Button
                  variant={'bordered'}
                  size="small"
                  color={'red'}
                  className="mt-4"
                  onClick={() => {
                    removeThreshold(index)
                  }}
                >
                  Remove
                </Button>
              </div>
            )
          })}
        </div>
      )}
      <span
        className={classNames('text-sm font-semibold  ', {
          'cursor-pointer text-primary hover:text-primary-500':
            selectedThresholds.length < MAX_THRESHOLD,
          'cursor-not-allowed text-gray-600':
            selectedThresholds.length >= MAX_THRESHOLD,
        })}
        onClick={addThreshold}
      >
        + Add threshold
      </span>
    </div>
  )
}

const THRESHOLD_OPTIONS = [
  { label: 'Select Option', value: '', hidden: true },
  { label: 'Free Gift', value: 'free_gift' },
  { label: 'Free Shipping', value: 'free_shipping' },
  { label: 'Discount', value: 'discount' },
]

const MAX_THRESHOLD = 3

const THRESHOLD_TYPE_TO_COMPONENT_MAP = {
  free_gift: FreeGift,
  discount: Discount,
}

const THRESHOLD_LAYOUT = [
  {
    key: 'type',
    label: 'Type of Threshold',
    controlType: 'single_select',
    options: THRESHOLD_OPTIONS,
  },
  {
    key: 'amount',
    label: 'Threshold Amount',
    controlType: 'currency',
  },
  {
    key: 'label',
    label: 'Threshold Label',
    controlType: 'translation',
  },
]
