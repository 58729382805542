import React from 'react'
import Badge from 'components/Badge'
import Button from 'components/Button'
import Form from 'components/Form'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { campaignLabelFromType, channelLabelFromType } from 'utils/constants'
import { campaignUpdate as campaignUpdateApi } from 'api/campaigns'
import { useForm } from 'react-hook-form'
import { useToast } from 'components/Toast'
import { useQueryParams } from 'hooks/router'

export default function EmailView({ campaign, onSave }) {
  const [isSaving, setIsSaving] = React.useState(false)
  const { shop } = useQueryParams()
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      name: campaign.name,
      html: campaign.html,
    },
  })
  const openToast = useToast()

  async function onSubmit(formData) {
    try {
      setIsSaving(true)
      const { data } = await campaignUpdateApi(
        campaign.id,
        {
          name: formData.name,
          state: 'active',
        },
        { shop }
      )

      setIsSaving(false)
      onSave(data.campaign)

      openToast({
        type: 'success',
        text: 'Campaign activated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in saving the campaign!',
      })
    }
  }

  return (
    <React.Fragment>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-row items-stretch justify-between">
          <Form.Field
            label="Campaign name"
            name="name"
            control="input"
            className="flex-1 mr-4"
            type="text"
            ref={register({ required: true })}
          />
          <div className="flex flex-row items-center flex-1">
            <Badge color="yellow" className="mr-2" spacing="regular">
              Type:&nbsp;
              <strong>
                {campaignLabelFromType(campaign.recommendationType)}
              </strong>
            </Badge>
            <Badge color="yellow" className="mr-2" spacing="regular">
              Email Client:&nbsp;
              <strong>{channelLabelFromType(campaign.channelSubType)}</strong>
            </Badge>
          </div>
        </div>
        <Form.Field
          label="HTML Code"
          name="html"
          control="textarea"
          className="flex-1"
          type="text"
          readOnly={true}
          rows={10}
          ref={register({ required: true })}
        />
        <div className="flex flex-row justify-end mb-6 -my-1">
          {campaign.html && (
            <CopyToClipboard
              text={campaign.html}
              onCopy={() => alert('Email template copied to clipboard!')}
            >
              <Button size="small" variant="bordered" type="button">
                Copy template to clipboard
              </Button>
            </CopyToClipboard>
          )}
        </div>
        <div className="flex flex-col items-stretch mb-6">
          <span className="pb-2 mb-2 text-xs font-semibold leading-tight tracking-wide uppercase border-b">
            What's next?
          </span>
          <span className="mb-2 text-xs text-gray-600">
            Copy the code snippet, save this campaign, and include the snippet
            into your email template such as a newsletter.
            {false && (
              <Button
                type="button"
                size="xSmall"
                className="ml-2"
                onClick={() => {
                  console.log('OPen video in modal')
                }}
              >
                ▶️ Watch an example video
              </Button>
            )}
          </span>
        </div>
        <Button className="self-start" size="small" loading={isSaving}>
          Save & Activate
        </Button>
      </form>
    </React.Fragment>
  )
}
