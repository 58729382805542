import React from 'react'
import _uniq from 'lodash.uniq'

import Box from 'components/Box'
import Tooltip from 'components/Tooltip'
import TagsCard from 'components/TagsCard'

export default function Tags({ tags, onTagAdd, onTagRemove, copies = {} }) {
  const { title, tooltipContent, fallbackContent } = copies
  return (
    <Box className="flex flex-col items-stretch flex-1 mb-6 ml-3">
      <div className="flex flex-row items-center mb-3">
        <span className="mr-2 text-xl font-semibold text-gray-800">
          {title ? title : 'Tags'}
        </span>
        {tooltipContent && (
          <Tooltip
            position="right"
            content={tooltipContent}
            showHelpIcon={true}
            helpIconVariant="dark"
          />
        )}
      </div>
      <TagsCard onAdd={onTagAdd} onRemove={onTagRemove} tags={tags} />
      {tags.length === 0 && fallbackContent ? (
        <div className="mt-3 text-xs text-gray-600">{fallbackContent}</div>
      ) : (
        tags.length === 0 && (
          <div className="mt-3 text-xs text-gray-600">No tags added yet.</div>
        )
      )}
    </Box>
  )
}
