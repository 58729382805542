import React from 'react'
import _uniqBy from 'lodash.uniqby'

import Button from 'components/Button'
import Text from 'components/Text'
import WaitForData from 'components/WaitForData'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import AddTemplateModal from 'components/Modals/AddTemplateModal'
import Icons from 'components/Icons'
import { CUSTOM_TEMPLATES } from 'constants/features'
import { useApi } from 'hooks/api'
import { canUpdateTemplate } from 'utils/features'

import TemplatesCard from './TemplatesCard'

export default function Templates({ account, store }) {
  const { name: shop } = account.data
  const templates = useApi('templates/', { shop })
  const openModal = useOpenModal()
  const canCreateTemplate = canUpdateTemplate(account.data)

  function handleOnClick() {
    if (canCreateTemplate) {
      openModal(
        <AddTemplateModal
          templates={templates}
          account={account}
          onCreate={(newTemplate) => {
            templates.update(
              {
                ...templates.data,
                templates: _uniqBy(
                  [newTemplate, ...templates.data.templates],
                  (t) => t.id
                ),
              },
              { local: true }
            )
          }}
        />
      )
    } else {
      openModal(
        <UpgradeAccountModal
          account={account}
          featureToEnable={CUSTOM_TEMPLATES}
        />
      )
    }
  }
  return (
    <WaitForData objects={[templates]} loadingMessage="Loading templates">
      {() => (
        <div className="flex flex-col items-stretch flex-1">
          <Text.ScreenHeading marginBottom={1}>
            Widget Templates for your store
          </Text.ScreenHeading>
          <Text.SubText marginBottom={5}>
            Have a store with custom theme? Use advanced templates to create
            widgets with <span className="text-primary">exact same theme.</span>
          </Text.SubText>
          <div className="flex flex-col items-stretch lg:items-start lg:flex-row">
            <div className="flex flex-col w-full mr-3 lg:w-2/3">
              <TemplatesCard templates={templates.data.templates} />
            </div>
            <div className="flex flex-col w-full ml-3 lg:w-1/3">
              <div className="self-start w-full pr-3 mb-6">
                <Button className="w-full" onClick={handleOnClick}>
                  <div className="flex items-center justify-center">
                    <span className="">+ Create Template</span>
                    {!canCreateTemplate && (
                      <Icons.Lock
                        className={'w-5 ml-4 mb-1 fill-current opacity-75'}
                      />
                    )}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </WaitForData>
  )
}
