import React from 'react'
import _debounce from 'lodash.debounce'
import _isEqual from 'lodash.isequal'
import deepMerge from 'deepmerge'

import Button from 'components/Button'
import Box from 'components/Box'
import Tabs from 'components/Tabs'
import Form from 'components/Form'
import Icons from 'components/Icons'
import * as Layouts from 'constants/layouts'
import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'

import ProductTitle from './ProductTitle'
import Prices from './Prices'
import CartButton from './CartButton'
import Carousel from './Carousel'
import AmazonTranslations from './AmazonTranslations'
import AmazonSettings from './AmazonSettings'
import AmazonCartButton from './AmazonCartButton'
import DiscountLabel from './DiscountLabel'
import General from './General'
import AdvancedDesign from './AdvancedDesign'
import ImageSettings from './ImageSettings'
import Items from './Items'

export default function SettingsForm({
  widget,
  widgetTemplate,
  store,
  onChange,
  onHide,
  onSave,
  isMobileView,
  setIsMobileView,
}) {
  const [settings, setSettings] = React.useState(null)
  const [translations, setTranslations] = React.useState(null)
  const [saving, setSaving] = React.useState(false)
  const [layout, setLayout] = React.useState(widget.layout)
  const [title, setTitle] = React.useState(widget.title)
  const [maxContent, setMaxContent] = React.useState(widget.maxContent)

  function buildSettings() {
    if (store && widgetTemplate.data && widget) {
      return deepMerge.all([
        store.extra,
        widgetTemplate.data.template.settings,
        widget.extra,
        settings || {},
      ])
    } else {
      return settings
    }
  }

  function buildTranslations() {
    if (store && widgetTemplate.data) {
      return deepMerge.all([
        store.translations,
        widgetTemplate.data.template.translations,
        translations || {},
      ])
    } else {
      return translations
    }
  }

  const handleFormChange = _debounce(
    (update) => {
      const payload = {
        layout,
        title: update.title || title,
        maxContent: update.maxContent || maxContent,
        settings: deepMerge(settings || {}, update.settings || {}),
        translations: deepMerge(translations || {}, update.translations || {}),
      }
      if (
        _isEqual(settings, payload.settings) &&
        _isEqual(translations, payload.translations) &&
        title === payload.title &&
        maxContent === payload.maxContent
      ) {
        return
      }
      onChange(payload)
      setSettings(payload.settings)
      setTranslations(payload.translations)
      setTitle(payload.title)
      setMaxContent(payload.maxContent)
    },
    100,
    {
      leading: true,
      trailing: true,
    }
  )

  React.useEffect(() => {
    setSettings(buildSettings())
    setTranslations(buildTranslations())
  }, [widgetTemplate.data, store, widget])

  const renderTabs =
    Boolean(settings) &&
    Boolean(translations) &&
    !Boolean(store.extra.visualEditorDisabled)

  return (
    <React.Fragment>
      <div className="flex flex-row items-center justify-end mb-2">
        <Button
          size="small"
          onClick={onHide}
          className="mr-2"
          variant="bordered"
        >
          Close
        </Button>
        <Button
          size="small"
          loading={saving}
          onClick={() => {
            if (store.extra.visualEditorDisabled) {
              onHide()
              return
            }
            setSaving(true)
            onSave({
              layout,
              title,
              maxContent,
              settings,
              translations,
            })
          }}
        >
          Publish
        </Button>
      </div>
      <Box className="mb-5">
        <div className="flex items-start">
          <Form.Field
            label="Choose widget layout type"
            options={[
              Layouts.CAROUSEL,
              Layouts.HORIZONTAL_GRID,
              ([
                Widgets.BOUGHT_TOGETHER.value,
                Widgets.PROMOTED_PRODUCTS.value,
              ].includes(widget.type) &&
                widget.pageType === Pages.PRODUCT.value) ||
              widget.layout == Layouts.AMAZON_BOUGHT_TOGETHER.value
                ? Layouts.AMAZON_BOUGHT_TOGETHER
                : null,
            ].filter(Boolean)}
            value={layout}
            control="select"
            onChange={(event) => {
              const newLayout = event.target.value
              setLayout(newLayout)
              onChange({
                layout: newLayout,
                title,
                maxContent,
                settings,
                translations,
              })
            }}
            className="flex-grow "
            helpText="It is adviced to use 'Amazon like bought together' for Frequently Bought Together and 'Carousel' for others. This can lead to higher order value."
            size="small"
          />
          <div className="ml-6">
            <Button
              variant={isMobileView ? 'solid' : 'inactive'}
              color="gray"
              size="xSmall"
              tooltipContent="Mobile View"
              onClick={() => {
                onChange({
                  layout,
                  title,
                  maxContent,
                  settings,
                  translations,
                })
                setIsMobileView(true)
              }}
            >
              <Icons.Mobile className="w-5 h-5" />
            </Button>
            <Button
              variant={!isMobileView ? 'solid' : 'inactive'}
              color="gray"
              size="xSmall"
              tooltipContent="Desktop View"
              onClick={() => {
                onChange({
                  layout,
                  title,
                  maxContent,
                  settings,
                  translations,
                })
                setIsMobileView(false)
              }}
            >
              <Icons.Desktop className="w-5 h-5" />
            </Button>
          </div>
        </div>

        {renderTabs && (
          <Tabs
            tabs={TABS}
            visibilityCheckParams={[layout, settings]}
            background={'white'}
          >
            {(activeTab) => (
              <activeTab.component
                settings={settings}
                store={store}
                translations={translations}
                widget={{
                  ...widget,
                  layout,
                  maxContent,
                  title,
                }}
                onChange={handleFormChange}
              />
            )}
          </Tabs>
        )}
      </Box>
    </React.Fragment>
  )
}

const TABS = [
  {
    label: 'Widget Settings',
    value: 'general',
    tooltip:
      'Customise widget title text, size & number of recommended products',
    component: General,
  },
  {
    label: 'Image Settings',
    value: 'image',
    tooltip: 'Customise image settings for the recommended products',
    component: ImageSettings,
    isVisible: (layout, settings) =>
      layout ===
        (Layouts.CAROUSEL.value || layout === Layouts.HORIZONTAL_GRID.value) ||
      settings.amazonBoughtTogether.imageObjectFit ||
      settings.amazonBoughtTogether.imageAspectRatio,
  },
  {
    label: 'Add to cart button',
    value: 'cart-button-fbt',
    tooltip: 'Customise background, text color & label for cart button',
    component: AmazonCartButton,
    isVisible: (layout) => layout === Layouts.AMAZON_BOUGHT_TOGETHER.value,
  },
  {
    label: 'Translations',
    value: 'translations-fbt',
    tooltip: 'Customise texts in the widget',
    component: AmazonTranslations,
    isVisible: (layout) => layout === Layouts.AMAZON_BOUGHT_TOGETHER.value,
  },
  {
    label: 'Price Colors',
    value: 'settings-fbt',
    tooltip: 'Customise background & text color for cart button & prices',
    component: AmazonSettings,
    isVisible: (layout) => layout === Layouts.AMAZON_BOUGHT_TOGETHER.value,
  },
  {
    label: 'Product Title',
    value: 'product-title',
    tooltip: 'Customise font size, color & alignment of product titles.',
    component: ProductTitle,
    isVisible: (layout) =>
      layout === Layouts.CAROUSEL.value ||
      layout === Layouts.HORIZONTAL_GRID.value,
  },
  {
    label: 'Prices',
    value: 'prices',
    tooltip: 'Customise color & alignment of sale & original prices.',
    component: Prices,
    isVisible: (layout) =>
      layout === Layouts.CAROUSEL.value ||
      layout === Layouts.HORIZONTAL_GRID.value,
  },
  {
    label: 'Add to Cart Button',
    value: 'cart-button',
    tooltip: 'Customise color, background & behaviour of add to cart button.',
    component: CartButton,
    isVisible: (layout) =>
      layout === Layouts.CAROUSEL.value ||
      layout === Layouts.HORIZONTAL_GRID.value,
  },
  {
    label: 'Sale Label',
    value: 'sale-label',
    tooltip:
      'Customise color, formatting, positioning & background of "SALE" labels',
    component: DiscountLabel,
    isVisible: (layout) =>
      layout === Layouts.CAROUSEL.value ||
      layout === Layouts.HORIZONTAL_GRID.value,
  },
  {
    label: 'Carousel',
    value: 'carousel',
    tooltip:
      'You can configure which products to show in the widget from here.',
    component: Carousel,
    isVisible: (layout) => layout === Layouts.CAROUSEL.value,
  },
  {
    label: 'Items per Row',
    value: 'items',
    tooltip: 'Configure maximum items per row',
    component: Items,
    isVisible: (layout) =>
      layout === Layouts.CAROUSEL.value ||
      layout === Layouts.HORIZONTAL_GRID.value,
  },
  {
    label: 'Advanced Design',
    value: 'advanced',
    tooltip: 'Use custom templates, css & javascript within the widget.',
    component: AdvancedDesign,
  },
]
