import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { useQueryParams } from 'hooks/router'
import useBundleMutations from 'hooks/queries/bundles'
import { fetchBundle } from 'api/bundles'

export default function useBundleDetails() {
  const { bundleId } = useParams()
  const { shop } = useQueryParams()

  const query = useQuery(['bundle', { shop, bundleId }], fetchBundle)
  const {
    updateBundle: { mutate: updateBundle, status: updateBundleStatus },
  } = useBundleMutations({ shop })

  const bundleUpdating = updateBundleStatus === 'loading'

  return {
    bundleDetailsQuery: query,
    bundleUpdating,
    updateBundle,
  }
}
