import React from 'react'
import { useForm } from 'react-hook-form'

import Form from 'components/Form'
import * as Layouts from 'constants/layouts'

export default function ImageSettingsForm(props) {
  const {
    onChange,
    settings: { productCard, amazonBoughtTogether },
    widget: { layout },
  } = props

  const isAmazonLayout = layout === Layouts.AMAZON_BOUGHT_TOGETHER.value
  const { register, errors, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  const IMAGE_OBJECT_FIT_OPTIONS = [
    { label: 'Cover', value: 'cover' },
    { label: 'Contain', value: 'contain' },
  ]

  const IMAGE_HOVER_OPTIONS = [
    { label: 'Secondary', value: 'secondary' },
    { label: 'Zoom', value: 'zoom' },
    { label: 'None', value: '' },
  ]

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues, layout))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        {((!isAmazonLayout && productCard.imageObjectFit) ||
          (isAmazonLayout && amazonBoughtTogether.imageObjectFit)) && (
          <Form.Field
            label="Object Fit"
            name="imageObjectFit"
            control="select"
            options={IMAGE_OBJECT_FIT_OPTIONS}
            ref={register({
              required: 'Image Object Fit cannot be empty.',
            })}
            helpText={
              'Contain - The image keeps its aspect ratio, but is resized to fit within the given dimension.\nCover - The image keeps its aspect ratio and fills the given dimension. The image will be clipped to fit.'
            }
            helpTextSizeClass="text-xxs"
            formError={errors.imageObjectFit}
          />
        )}
        {((!isAmazonLayout &&
          typeof productCard.imageAspectRatio !== 'undefined') ||
          (isAmazonLayout &&
            typeof amazonBoughtTogether.imageAspectRatio !== 'undefined')) && (
          <Form.Field
            label="Aspect Ratio"
            name="imageAspectRatio"
            control="input"
            type="text"
            ref={register({
              pattern: {
                value: /^([0-9]+(:|x|X)[0-9]+)$/,
                message:
                  "Please follow the format 'Width:Height' or 'WidthxHeight'",
              },
            })}
            helpText="Enter the aspect ratio in the format 'Width:Height' or 'WidthxHeight'. You can also add image size directly. Eg- 800x600 , 3:4."
            helpTextSizeClass="text-xxs"
            formError={errors.imageAspectRatio}
          />
        )}
        {!isAmazonLayout && (
          <Form.Field
            label="Image Hover Mode"
            name="imageHoverMode"
            control="select"
            options={IMAGE_HOVER_OPTIONS}
            ref={register({})}
            formError={errors.imageHoverMode}
          />
        )}
        {!isAmazonLayout && (
          <Form.Field
            label="Spacing (Desktop)"
            name="gutter"
            control="input"
            type="number"
            ref={register({
              required: 'Please enter a valid number as spacing',
            })}
            helpText="Adjust space between each product image (in px)"
            formError={errors.gutter}
          />
        )}
        {!isAmazonLayout && (
          <Form.Field
            label="Spacing (Mobile)"
            name="gutterMobile"
            control="input"
            type="number"
            ref={register({
              required: 'Please enter a valid number as spacing',
            })}
            helpText="Adjust space between each product image (in px)"
            formError={errors.gutterMobile}
          />
        )}
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, widget: { layout } }) {
  if (layout === Layouts.AMAZON_BOUGHT_TOGETHER.value) {
    return {
      imageObjectFit: settings.amazonBoughtTogether.imageObjectFit,
      imageAspectRatio: settings.amazonBoughtTogether.imageAspectRatio,
    }
  }
  return {
    imageObjectFit: settings.productCard.imageObjectFit,
    imageAspectRatio: settings.productCard.imageAspectRatio,
    imageHoverMode: settings.productCard.imageHoverMode,
    gutter: settings.productCard.gutter,
    gutterMobile: settings.productCard.gutterMobile,
  }
}

function getUpdateObjectFromValues(values, layout) {
  if (layout === Layouts.AMAZON_BOUGHT_TOGETHER.value) {
    return {
      settings: {
        amazonBoughtTogether: {
          imageObjectFit: values.imageObjectFit,
          imageAspectRatio: values.imageAspectRatio,
        },
      },
    }
  }
  return {
    settings: {
      productCard: {
        imageObjectFit: values.imageObjectFit,
        imageAspectRatio: values.imageAspectRatio,
        imageHoverMode: values.imageHoverMode,
        gutter: values.gutter,
        gutterMobile: values.gutterMobile,
      },
    },
  }
}
