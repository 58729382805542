import GridTemplateImage from './assets/template_grid.png'
import AmazonTemplateImage from './assets/template_amazon.png'
import CarouselTemplateImage from './assets/template_carousel.png'

export const POPUP_RECOMMENDATION_TYPES = {
  PERSONALIZED_FOR_YOU: {
    icon: '',
    label: 'Personalized for you',
    value: 'personalized',
    description:
      "Show Products based on user's browsing history. A complete Personalized Experience to your store visitors to increase conversion.",
    enabled: true,
  },
  SIMILAR_PRODUCTS: {
    icon: '',
    label: 'Similar Products',
    value: 'similar_products',
    description:
      'Show Related/Relevant products to increase your conversion and keep visitors hooked to the store.',
    enabled: true,
  },
  BOUGHT_TOGETHER: {
    icon: '',
    label: 'Frequently Bought Together',
    value: 'bought_together',
    description:
      'Upsell products and increase your Average Order Value(AoV). Perfect for "Shop the look" or "Pair With" style recommendations.',
    enabled: true,
  },
  RECENTLY_VIEWED: {
    icon: '',
    label: 'Recently Viewed',
    value: 'recently_viewed',
    description:
      'Re-marketing is one of the most rewarding technique. This widget gives user the opportunity to buy the products that they have already seen.',
    enabled: true,
  },
  RECENTLY_LAUNCHED: {
    icon: '',
    label: 'Recently Launched',
    value: 'recent_launch',
    description:
      'Showcase your recently launched products. Make these products visible to the customers and increase sales.',
    enabled: true,
  },
  TRENDING: {
    icon: '',
    label: 'Trending',
    value: 'trending',
    description:
      'Show products gaining demand to make sure visitors buy products currently in trend.',
    enabled: true,
  },
  RECENTLY_PURCHASED: {
    icon: '',
    value: 'purchases',
    label: 'Recent Purchases',
    description:
      'Recommend products that the customer has bought in the past. This widget helps in increasing repeat purchases.',
    enabled: true,
  },
  COLLECTION: {
    icon: '',
    value: 'collections',
    label: 'Collections',
    description: 'Highlight collections across your store.',
    enabled: true,
  },
}

export const TRIGGER_OPTIONS = [
  {
    label: 'Exit intent pop up',
    value: 'popup_exit',
    type: 'popupExit',
    description:
      'Show the bundle as a pop up when the customer is about to leave your store. Display bundles to pique customer interest.',
    available: true,
  },
  {
    label: 'Time triggered pop up',
    value: 'popup_time',
    type: 'popupTime',
    description:
      'Show the bundle as a pop up after a time interval. Have a bundle sale ? Let your customers know',
    available: true,
  },
  {
    label: 'Manual',
    value: 'popup_manual',
    type: 'popupManual',
    description:
      'Show the bundle as a pop up when an item has been added to cart. Increase AoV by suggesting bundles that go with the added item',
    available: true,
  },
]

export const POPUP_RECOMMENDATION_TYPES_LIST = [
  POPUP_RECOMMENDATION_TYPES.PERSONALIZED_FOR_YOU,
  POPUP_RECOMMENDATION_TYPES.SIMILAR_PRODUCTS,
  POPUP_RECOMMENDATION_TYPES.BOUGHT_TOGETHER,
  POPUP_RECOMMENDATION_TYPES.RECENTLY_VIEWED,
  POPUP_RECOMMENDATION_TYPES.RECENTLY_LAUNCHED,
  POPUP_RECOMMENDATION_TYPES.TRENDING,
  POPUP_RECOMMENDATION_TYPES.RECENTLY_PURCHASED,
  POPUP_RECOMMENDATION_TYPES.COLLECTION,
]

export const POPUP_TEMPLATES = {
  GRID_TEMPLATE: {
    image: GridTemplateImage,
    label: 'Grid Template',
    value: 'popup_grid',
    description:
      'Stack products horizontally as shown below. Works best when there are only fewer recommendations',
  },
  AMAZON_FBT: {
    image: AmazonTemplateImage,
    label: 'Amazon Template',
    value: 'popup_amazon_fbt',
    description:
      'Showcase bundles or frequently bought together items in this layout.',
  },
  CAROUSEL: {
    image: CarouselTemplateImage,
    label: 'Carousel Template',
    value: 'popup_carousel',
    description:
      'Stack products horizontally and scroll through them. Works best when there are more recommendations',
  },
}

export const POPUP_STATUS_OPTIONS = [
  {
    label: 'All Status',
    value: '',
  },
  {
    label: 'Active',
    actionLabel: 'Publish',
    value: 'active',
  },
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    actionLabel: 'Inactivate',
  },
  {
    label: 'Deleted',
    actionLabel: 'Delete',
    value: 'delete',
  },
]
