import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { fetchPages } from 'api/pages'
import { fetchBilling } from 'api/account'
import { getExperiences } from 'api/experiences'
import AddWidgetModal from 'containers/Dashboard/AddWidgetModal'
import WaitForData from 'components/WaitForData'
import { SelectVersionTwo } from 'components/Form/controls'
import { useOpenModal } from 'components/Modal'
import Icons from 'components/Icons'
import * as Pages from 'constants/pages'
import * as QueryUtils from 'utils/query'
import { _find } from 'utils/lodash'

export default function Recommendations({ value, handleUpdate, accountData }) {
  const { name: shop } = accountData
  const {
    settings: {
      recommendation: {
        type: recommendationType,
        settings: recommendationSettings,
      },
    },
  } = value

  const pagesQuery = useQuery(['pages', shop], fetchPages)
  const experiencesQuery = useQuery(['experiences', shop], getExperiences)
  const billingQuery = useQuery(['billing', shop], fetchBilling)
  const openModal = useOpenModal()

  return (
    <WaitForData
      objects={[pagesQuery, billingQuery, experiencesQuery]}
      isQueries={true}
    >
      {() => {
        const pages = QueryUtils.data(pagesQuery)
        const ajaxCartWidgets =
          pages.find((page) => page.type === Pages.AJAX_CART.value)?.widgets ||
          []
        const experiences = QueryUtils.data(experiencesQuery)
        const billing = QueryUtils.data(billingQuery)
        const widgetOptions = [
          {
            label: 'Select Recommendation',
            value: '',
            hidden: true,
          },
          {
            label: '+ Create New Widget',
            value: 'create',
          },
          {
            label: 'No Recommendation',
            value: 'none',
          },
          ...ajaxCartWidgets.map((widget) => {
            return {
              label: widget.title,
              value: widget.id,
            }
          }),
        ]
        return (
          <SelectVersionTwo
            size="small"
            label={
              <div
                className={
                  'flex flex-row py-1.5 px-4 rounded border text-sm border-gray-400 w-full justify-between'
                }
              >
                <span>
                  {
                    _find(widgetOptions, function (o) {
                      if (recommendationType === 'none') {
                        return o.value === 'none'
                      } else if (!recommendationType) {
                        return o.value === ''
                      }
                      return o.value === recommendationSettings.widgetId
                    }).label
                  }
                </span>
                <Icons.ChevronDown className="w-3 ml-2 text-current" />
              </div>
            }
            options={widgetOptions}
            onChange={(selectedOption) => {
              if (selectedOption.value === 'create') {
                openModal(
                  <AddWidgetModal
                    pages={{ data: pages }}
                    billing={{ data: billing }}
                    account={{ data: accountData }}
                    experience={
                      experiences?.experiences
                        ? _find(
                            experiences.experiences,
                            (exp) => exp.slug === 'default-experience',
                            undefined
                          )
                        : undefined
                    }
                    whiteListedPages={[Pages.AJAX_CART.value]}
                    updateWidgetData={function updateWidgetData(
                      widgetData,
                      experience
                    ) {
                      QueryUtils.invalidate('experiences')
                    }}
                  />
                )
              } else if (selectedOption.value === 'none') {
                handleUpdate({
                  settings: {
                    recommendation: {
                      type: selectedOption.value,
                      settings: {
                        widgetId: null,
                      },
                    },
                  },
                })
              } else {
                handleUpdate({
                  settings: {
                    recommendation: {
                      type: 'widget',
                      settings: {
                        widgetId: selectedOption.value,
                      },
                    },
                  },
                })
              }
            }}
            value={_find(widgetOptions, function (o) {
              if (recommendationType === 'none') {
                return o.value === 'none'
              }
              return o.value === recommendationSettings.widgetId
            })}
          />
        )
      }}
    </WaitForData>
  )
}
