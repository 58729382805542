import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import Tooltip from 'components/Tooltip'
import { experienceCreate, experienceSave } from 'api/experiences'
import { getPercentage } from 'utils/numbers'

export default function UpsertExperienceModal({
  account,
  billing,
  experience,
  currentTotalWeight,
  updateLocalExperiences,
  close,
}) {
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control: formControl,
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(experience),
  })
  const {
    shop: { domain },
    name,
  } = account.data
  const [loading, setLoading] = useState(false)
  const formValues = watch()

  function getWeightagePercentage() {
    if (experience) {
      return getPercentage(
        formValues.weight,
        parseFloat(currentTotalWeight) -
          parseFloat(experience.weight) +
          parseFloat(formValues.weight)
      )
    } else {
      return getPercentage(
        formValues.weight,
        parseFloat(currentTotalWeight) + parseFloat(formValues.weight)
      )
    }
  }

  async function onSubmit(formData) {
    var payload = formData
    if (payload.endTime) {
      payload.endTime = Math.floor(payload.endTime.getTime() / 1000)
    }
    if (payload.startTime) {
      payload.startTime = Math.floor(payload.startTime.getTime() / 1000)
    }
    setLoading(true)
    if (experience) {
      var { data: updatedExperience } = await experienceSave(
        experience.id,
        payload,
        { shop: name }
      )
      updateLocalExperiences(updatedExperience)
    } else {
      var { data: updatedExperiences } = await experienceCreate(payload, {
        shop: name,
      })
      updateLocalExperiences(updatedExperiences, false)
    }
    setLoading(false)
    close()
  }
  return (
    <Box>
      <Box.Heading>
        {experience ? 'Edit Experience' : 'Add New Experience'}
      </Box.Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <Form.Field
            label="Name"
            name="name"
            control="input"
            type="text"
            ref={register({
              required: 'Experience name cannot be empty.',
            })}
            formError={errors.name}
          />
          <Form.Field
            label="Weightage"
            name="weight"
            control="input"
            type="number"
            ref={register({
              required: 'Experience weightage cannot be empty.',
              validate: (value) => {
                if (value < 1 || value > 10000) {
                  return 'Weightage must be between 1 and 10,000'
                }
                return true
              },
            })}
            formError={errors.weight}
          />
          <div className="pb-2 mt-1 mb-2 text-xs ">
            This will account for {getWeightagePercentage()}
            {'% '}
            of all experiences.
          </div>
          {/* <div className="pb-2 my-2 text-xs leading-tight tracking-wide text-gray-700 uppercase">
            Schedule
          </div>
          <Form.FieldRow>
            <Form.Field
              control="date-time"
              autoFocus={false}
              date={true}
              time={true}
              label="Start Time"
              name="startTime"
              formControl={formControl}
              min={getNearestHalfHour()}
              max={formValues.endTime ? formValues.endTime : undefined}
              value={formValues.startTime}
              disabled={experience ? experience.isDefault : false}
            />
            <Form.Field
              control="date-time"
              autoFocus={false}
              date={true}
              time={true}
              label="End Time"
              name="endTime"
              formControl={formControl}
              min={
                formValues.startTime
                  ? formValues.startTime
                  : getNearestHalfHour()
              }
              disabled={experience ? experience.isDefault : false}
              value={formValues.endTime}
            />
          </Form.FieldRow> */}
          <div className="flex flex-row items-center pb-2 mt-2 text-xs leading-tight tracking-wide text-gray-700 uppercase">
            <span className="mr-2">Manual Trigger</span>
            <Tooltip
              position="top"
              content={
                <div className="flex flex-col">
                  <span className="mb-1">
                    Trigger the experience manually by adding query parameter to
                    the url.
                  </span>
                  <span className="mb-1">
                    URL Example ( Home Page ): https://{domain}/?
                    {formValues.triggerKey ? formValues.triggerKey : '<key>'}=
                    {formValues.triggerValue
                      ? formValues.triggerValue
                      : '<value>'}
                  </span>
                  <span className="mb-1">
                    Adding this query parameter will ensure that this is the
                    experience being loaded.
                  </span>
                  <span className="">This is an optional setting</span>
                </div>
              }
              showHelpIcon={true}
              helpIconVariant="dark"
            />
          </div>
          <div className="pb-2 mt-1 mb-2 text-xs ">
            URL Example ( Home Page ): https://{domain}/?
            {formValues.triggerKey ? formValues.triggerKey : '<key>'}=
            {formValues.triggerValue ? formValues.triggerValue : '<value>'}
          </div>
          <Form.FieldRow>
            <Form.Field
              label="Key"
              name="triggerKey"
              control="input"
              type="text"
              ref={register()}
            />
            <Form.Field
              label="Value"
              name="triggerValue"
              control="input"
              type="text"
              ref={register({
                validate: (value) => {
                  if (formValues.triggerKey && value) {
                    return true
                  } else if (formValues.triggerKey) {
                    return 'Enter both key and value'
                  } else {
                    return true
                  }
                },
              })}
              formError={errors.triggerValue}
            />
          </Form.FieldRow>
          <div className="flex flex-row items-center justify-end mt-4">
            <Button loading={loading}>Save</Button>
          </div>
        </div>
      </form>
    </Box>
  )
}

function getDefaultValues(experience) {
  return {
    name: experience?.name,
    weight: experience?.weight || 100,
    // startTime: experience?.startTime
    //   ? new Date(experience.startTime * 1000)
    //   : getNearestHalfHour(),
    // endTime: experience?.endTime ? new Date(experience.endTime * 1000) : null,
    triggerKey: experience?.triggerKey,
    triggerValue: experience?.triggerValue,
  }
}
