import api from './index'

export function ruleSave(payload, shop) {
  return api.post('rules/', payload, { params: { shop } })
}

export function ruleDestroy(ruleId, shop) {
  return api.delete(`rules/${ruleId}/?shop=${shop}`)
}

export function ruleUpdate(ruleId, payload, shop) {
  return api.patch(`rules/${ruleId}/?shop=${shop}`, payload)
}
