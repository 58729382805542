import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'
import { validCssColor } from 'utils/browser'

export default function AmazonSettings(props) {
  const { onChange } = props
  const { register, errors, watch, formState, setValue } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow>
        <Form.Field
          label="Total price color"
          name="totalPriceColor"
          control="color"
          setColor={setValue}
          color={formValues.totalPriceColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.totalPriceColor}
        />
        <Form.Field
          label="Product price color"
          name="productPriceColor"
          control="color"
          setColor={setValue}
          color={formValues.productPriceColor}
          ref={register({
            validate: (value) =>
              validCssColor(value) || 'Enter valid CSS color value',
          })}
          formError={errors.productPriceColor}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings }) {
  return {
    totalPriceColor: settings.amazonBoughtTogether.totalPriceColor,
    productPriceColor: settings.amazonBoughtTogether.productPriceColor,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    settings: {
      amazonBoughtTogether: {
        totalPriceColor: values.totalPriceColor,
        productPriceColor: values.productPriceColor,
      },
    },
  }
}
