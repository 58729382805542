import React from 'react'

export default function TranslationUpsell({ templateId, store }) {
  return templateId && store.onboarding?.completed ? (
    <a
      href={`/templates/${templateId}/translations${window.location.search}`}
      className="mt-1 text-xs font-medium underline text-primary hover:text-primary-800"
      target="_blank"
      rel="noopener noreferrer"
    >
      Need multi-language support? Add translations here
    </a>
  ) : null
}
