import React from 'react'
import classNames from 'classnames'

import WithProducts from 'components/WithProducts'
import RenderMoney from 'components/RenderMoney'
import * as ShopifyUtils from 'utils/shopify'

export default function Pricing({ layout }) {
  const { lineItems, productSyncInProgress } = layout
  return (
    <div className="flex flex-col items-stretch justify-center py-4 border-t border-gray-400 px-7">
      {lineItems.length > 0 ? (
        lineItems.map((product) => {
          return <LineItem product={product} key={product.productId} />
        })
      ) : (
        <div className="flex items-center justify-center h-24 px-6 text-sm text-center break-words border border-black border-dashed">
          {productSyncInProgress
            ? 'Line items will be displayed here. Please wait for product sync to be completed for a better preview.'
            : 'No products found. Line items will be displayed here.'}
        </div>
      )}
    </div>
  )
}

function LineItem({ product }) {
  const { title, image, variants } = product
  const firstVariant = variants[0]
  return (
    <div className="flex flex-row items-center py-4 last:pb-0 first:pt-0">
      <div className="w-1/5">
        <div className="relative overflow-hidden h-0 pt-[150%]">
          <img
            src={ShopifyUtils.optimisedImage({
              baseUrl: image,
              size: 460,
            })}
            alt={title}
            className="absolute top-0 left-0 object-cover object-top w-full h-full"
          />
        </div>
      </div>
      <div className="flex flex-col items-start w-4/5 pl-6">
        <span className="text-sm font-semibold">{title}</span>
        <span className="mt-1 text-xs text-gray-600">{firstVariant.title}</span>
        <div className="flex flex-row items-center mt-1">
          <RenderMoney price={firstVariant.price} className={'text-xs'} />
          {firstVariant.comparePrice && (
            <RenderMoney
              price={firstVariant.comparePrice}
              className={'pl-2 text-xxs line-through text-gray-600'}
            />
          )}
        </div>
        <div className="flex flex-row items-center justify-between w-full mt-2 cursor-pointer">
          <div className="flex flex-row text-gray-800 h-7">
            <div className="flex flex-row items-center justify-center border border-gray-400 rounded-l-lg w-7">
              +
            </div>
            <div className="flex flex-row items-center justify-center w-10 border-gray-400 border-y">
              1
            </div>
            <div className="flex flex-row items-center justify-center border border-gray-400 rounded-r-lg w-7">
              -
            </div>
          </div>
          <span className="text-xs font-semibold text-gray-800">Remove</span>
        </div>
      </div>
    </div>
  )
}
