export async function getSourceAsDOM(url, onTimeout = () => {}) {
  const responseText = await makeAsyncRequest('GET', url, 5 * 1000, onTimeout)
  const parser = new DOMParser()
  return parser.parseFromString(responseText, 'text/html')
}

export function makeAsyncRequest(method, url, timeout, onTimeout) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.responseText)
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        })
      }
    }
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      })
    }
    xhr.timeout = timeout
    xhr.ontimeout = () => {
      if (typeof onTimeout === 'function') {
        onTimeout()
      }
      reject({
        status: xhr.status,
        statusText: 'Storefront request timed out',
      })
    }
    xhr.send()
  })
}

export function poll(
  pollingFn,
  timeoutInSeconds = 5,
  intervalInSeconds = 1,
  resolveCallbackFn,
  rejectCallbackFn
) {
  var endTime = Number(new Date()) + timeoutInSeconds * 1000

  var checkCondition = async function (resolve, reject) {
    // If the condition is met, we're done!
    var result = await pollingFn()

    if (result) {
      if (resolveCallbackFn && typeof resolveCallbackFn === 'function') {
        resolveCallbackFn()
      }
      resolve(result)
    }
    // If the condition isn't met but the timeout hasn't elapsed, go again
    else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, intervalInSeconds * 1000, resolve, reject)
    }
    // Didn't match and too much time, reject!
    else {
      if (rejectCallbackFn && typeof rejectCallbackFn === 'function') {
        rejectCallbackFn()
      }
      reject('Polling Fn Timed out')
    }
  }

  return new Promise(checkCondition)
}

export function fakeApi(response, delay = 5000) {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      resolve(response)
    }, delay)
  )
}
