import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { _get } from 'utils/lodash'
import { storeSave as storeSaveApi } from 'api/store'
import Welcome from '../Welcome'
import Preview from '../Preview'
import About from '../About'
import CreateWidget from '../CreateWidget'
import Pricing from '../Pricing'

export default function ({ account, store }) {
  const [pendingUpdates, setPendingUpdates] = useState([])
  const {
    onboarding: { stage = WHAT_YOU_NEED_FLOW[0], ...restOnboarding },
    productsSyncedAt,
    ordersSyncedAt,
    trendingGeneratedAt,
    bestsellerGeneratedAt,
  } = store.data
  const { name: shop } = account.data
  const history = useHistory()
  const currentStageIndex = WHAT_YOU_NEED_FLOW.indexOf(stage)
  const nextStage =
    WHAT_YOU_NEED_FLOW[currentStageIndex + 1] || WHAT_YOU_NEED_FLOW[4]
  const previousStage =
    WHAT_YOU_NEED_FLOW[currentStageIndex - 1] || WHAT_YOU_NEED_FLOW[0]

  async function updateOnboarding(payload) {
    const update = {
      ...store.data,
      onboarding: { ...store.data.onboarding, ...payload },
    }

    if (payload.completed) {
      const { data: newData } = await storeSaveApi(update, shop)
      store.update(newData, { local: true })
      history.push({
        pathname: '/',
        search: `?shop=${_get(account.data, 'name')}`,
      })
    } else {
      setPendingUpdates([...pendingUpdates, update])
      store.update(update, { local: true })
    }
  }

  useEffect(() => {
    async function processPendingUpdates(shop) {
      const index = pendingUpdates.length - 1
      const lastUpdate = pendingUpdates[index]
      if (!lastUpdate) {
        return
      }

      try {
        await storeSaveApi(lastUpdate, shop)
      } catch (err) {}
      setPendingUpdates([])
    }

    processPendingUpdates(shop)
  }, [pendingUpdates])

  return {
    stages: {
      current: stage,
      next: nextStage,
      previous: previousStage,
    },
    data: restOnboarding,
    updateOnboarding,
    component: SCREEN_TO_STAGE_MAP[stage],
    isSyncingStore:
      !productsSyncedAt ||
      !ordersSyncedAt ||
      !bestsellerGeneratedAt ||
      !trendingGeneratedAt,
    progress: {
      current: WHAT_YOU_NEED_FLOW.indexOf(stage),
      total: WHAT_YOU_NEED_FLOW.length,
    },
  }
}

const SCREEN_TO_STAGE_MAP = {
  welcome: Welcome,
  preview: Preview,
  about: Pricing,
  widget_setup: CreateWidget,
  pricing: Pricing,
}

const WHAT_YOU_NEED_FLOW = ['welcome', 'widget_setup', 'preview', 'pricing']
