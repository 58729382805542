import React from 'react'
import classNames from 'classnames'

import WidgetRecommendations from 'containers/Widget/Recommendations'
import Badge from 'components/Badge'
import Button from 'components/Button'
import Onboarding from 'components/Onboarding'
import Loader from 'components/Loader'
import Box from 'components/Box'
import AppEmbedCard from 'components/AppEmbedCard'
import ActionCompletedIcon from 'components/Icons/ActionCompleted'
import WidgetPlacement from 'components/WidgetPlacement'
import WidgetEditor from 'components/WidgetEditor'
import { useOpenModal } from 'components/Modal'
import * as Tracker from 'utils/tracker'
import { openChat } from 'utils/external'
import { trackEvent } from 'utils/analytics'
import { isTemplateEnabled } from 'utils/features'
import { pageLabelFromType } from 'utils/constants'
import { isFreeUser, storeScriptTagEnabled } from 'utils/account'
import { useApi } from 'hooks/api'
import { useTrackView } from 'hooks/analytics'

import useOnboardingWidgets from './hooks/useOnboardingWidgets'
import useSaveWidget from './hooks/useSaveWidget'
import useEnableWidgets from './hooks/useEnableWidgets'
import './styles.css'

export default function ({ onboardingState, account, store }) {
  const {
    stages: { next: nextStage, previous: previousStage },
    updateOnboarding,
  } = onboardingState
  const { name: shop, links } = account.data
  const hasLinks = Boolean(links && links.productPage)

  const pages = useApi('pages/', { shop })
  const [widgets] = useOnboardingWidgets({
    pages,
    onboardingState,
  })
  const [loading, setLoading] = React.useState(false)
  const [canMoveForward, setCanMoveForward] = React.useState(
    storeScriptTagEnabled(account.data)
  )
  const { savingWidgetId, saveWidget } = useSaveWidget({ account, pages })
  const { enablingWidgets, enableWidgets } = useEnableWidgets({
    account,
    onboardingState,
    widgets,
  })
  const freeUser = isFreeUser(account.data)

  React.useEffect(() => {
    Tracker.record('view:onboarding:widget_placement')
  }, [])

  useTrackView({
    category: 'Onboarding',
    action: 'Load',
    label: 'Widget Preview',
  })

  return (
    <React.Fragment>
      <Onboarding.Box>
        <div className="flex flex-row items-center justify-between pb-2 mb-6 text-xs leading-tight tracking-wide text-gray-700 uppercase border-b">
          Place Widgets On Your Store
          {false && (
            <Button
              variant="bordered"
              size="xSmall"
              onClick={() => {
                updateOnboarding({ stage: previousStage })
                Tracker.record('click:onboarding:setup_another_widget')
              }}
            >
              + Add another widget
            </Button>
          )}
        </div>
        {canMoveForward ? (
          hasLinks ? (
            <p className="mb-6 text-sm text-gray-800">
              Let's ensure the widget is setup correctly for your store. Click
              on <span className="font-semibold">"Update Position"</span> to set
              widget placement,{' '}
              <span className="font-semibold">"Visual Editor"</span> to adjust
              widget's styles and{' '}
              <span className="font-semibold">"Set Recommendation"</span> to
              choose products to recommend in the widget.
            </p>
          ) : (
            <div className="mb-6 text-sm text-gray-800">
              <Loader.Circle />
              Data sync is in progress. Automatic recommendations will be
              generated once sync has completed. You may see random
              recommendations during the setup
            </div>
          )
        ) : null}
        <AppEmbedCard
          account={account}
          onEnabled={() => setCanMoveForward(true)}
          className="mb-6"
        />
        <div className="flex flex-col items-stretch mb-6">
          {widgets.map((widget) => {
            return (
              <WidgetCard
                key={widget.id}
                widget={widget}
                allowActions={canMoveForward}
                isSaving={savingWidgetId === widget.id}
                account={account.data}
                store={store.data}
                onSave={(payload, onSuccess) =>
                  saveWidget(widget, payload, onSuccess)
                }
              />
            )
          })}
        </div>
        {canMoveForward && (
          <p className="text-xs text-gray-600">
            <span className="font-semibold">Not able to place the widget?</span>{' '}
            or{' '}
            <span className="font-semibold">
              Not happy with the widget styles?
            </span>
            <br />
            We'll help you place the widget and style them to match your store's
            theme.
            <br />
            <Button
              size="xxSmall"
              variant="bordered"
              className="mt-1"
              onClick={openChat}
            >
              Contact Us
            </Button>
          </p>
        )}
      </Onboarding.Box>
      <Onboarding.ActionTray>
        <Button
          className="self-center mr-2 opacity-75 hover:opacity-100"
          variant="bordered"
          disabled={!canMoveForward}
          onClick={() => {
            updateOnboarding({ stage: nextStage })
            Tracker.record('click:onboarding:skip_widget_placement')
            trackEvent({
              category: 'Onboarding',
              action: 'Skip Enabling Widgets',
              label: 'Widget Preview',
            })
          }}
        >
          Setup later
        </Button>
        <Button
          className="self-center"
          loading={enablingWidgets || loading}
          disabled={!canMoveForward}
          onClick={async () => {
            setLoading(true)
            enableWidgets()
            if (freeUser) {
              await updateOnboarding({
                stage: nextStage,
              })
              trackEvent({
                category: 'Onboarding',
                action: 'Click Next',
                label: 'Widget Preview',
              })
            } else {
              await onboardingState.updateOnboarding({
                completed: true,
                stage: onboardingState.stages.next,
              })
              trackEvent({
                category: 'Onboarding',
                action: 'Enable Widgets and Click Next',
                label: 'Widget Preview',
              })
            }
            setLoading(false)
          }}
        >
          Enable Widgets & {freeUser ? 'Next' : 'Go To Dashboard'}
        </Button>
      </Onboarding.ActionTray>
    </React.Fragment>
  )
}

function WidgetCard({
  widget,
  account,
  store,
  onSave,
  isSaving,
  allowActions,
}) {
  const templatesEnabled = isTemplateEnabled(account)
  const [positionSaved, setPositionSaved] = React.useState(false)
  const [designSaved, setDesignSaved] = React.useState(false)
  const [actionCompleted, setActionCompleted] = React.useState({
    widgetPlacement: false,
    visualEditor: false,
    setRecommendations: false,
  })
  const openModal = useOpenModal()

  function handlePositionSave(position) {
    const payload = {
      id: widget.id,
      ...position,
    }

    onSave(payload, () => {
      setPositionSaved(true)
    })

    trackEvent({
      category: 'Onboarding',
      action: 'Save Widget Position',
      label: 'Widget Preview',
    })
  }

  function handleDesignSave(update) {
    const payload = {
      id: widget.id,
      ...update,
    }

    onSave(payload, () => {
      setDesignSaved(true)
    })

    trackEvent({
      category: 'Onboarding',
      action: 'Save Widget Design',
      label: 'Widget Preview',
    })
  }

  function handleRecommendationSave(recommmendation) {
    const payload = {
      id: widget.id,
      ...recommmendation,
    }

    onSave(payload)

    trackEvent({
      category: 'Onboarding',
      action: 'Save Widget Recommendation',
      label: 'Widget Preview',
    })
  }

  if (!store || !account) {
    return null
  }

  return (
    <div
      className={classNames(
        'flex flex-col items-stretch flex-1 p-3 bg-gray-100 border border-gray-400 rounded'
      )}
    >
      <div className="flex flex-row items-center mb-2 font-semibold leading-tight text-gray-800">
        <span className="text-xl notranslate">{widget.title}</span>
        <span className="ml-2 text-gray-700 notranslate">
          (Widget ID: {widget.id})
        </span>
      </div>
      <div className="flex flex-row">
        <Badge
          color="yellow"
          className="uppercase"
          spacing="regular"
          textSize="small"
        >
          {pageLabelFromType(widget.pageType)}
        </Badge>
        {!widget.enabled && (
          <Badge
            color="neutral"
            className="uppercase"
            spacing="regular"
            textSize="small"
          >
            Disabled
          </Badge>
        )}
      </div>
      <div className="flex flex-row items-center mt-4">
        <WidgetPlacement
          account={account}
          onSave={handlePositionSave}
          disabled={!allowActions}
          onStart={() => {
            setDesignSaved(false)
            setPositionSaved(false)
            trackEvent({
              category: 'Onboarding',
              action: 'Click Widget Placement',
              label: widget.type,
            })
            setActionCompleted({ ...actionCompleted, widgetPlacement: true })
            // Tracker.record('click:onboarding:update_widget_position')
          }}
          variant="button"
          buttonSize="small"
          store={store}
          widget={widget}
          actionCompleted={actionCompleted.widgetPlacement}
        />
        <div className="mx-2" />
        {templatesEnabled && (
          <WidgetEditor
            widget={widget}
            account={account}
            store={store}
            disabled={!allowActions}
            variant="button"
            buttonSize="small"
            onSave={handleDesignSave}
            onStart={() => {
              trackEvent({
                category: 'Onboarding',
                action: 'Click Widget Editor',
                label: widget.type,
              })
              setActionCompleted({ ...actionCompleted, visualEditor: true })
            }}
            actionCompleted={actionCompleted.visualEditor}
          />
        )}
        <div className="mx-2" />
        <Button
          size="small"
          disabled={!allowActions}
          onClick={() => {
            trackEvent({
              category: 'Onboarding',
              action: 'Click Set Recommendation',
              label: widget.type,
            })
            setActionCompleted({ ...actionCompleted, setRecommendations: true })

            openModal(
              <Box>
                <WidgetRecommendations
                  account={{ data: account }}
                  widget={widget}
                  onSave={handleRecommendationSave}
                  store={{ data: store }}
                />
              </Box>
            )
          }}
        >
          <div className="flex items-center">
            <span>Set Recommendation</span>
            {allowActions && (
              <ActionCompletedIcon
                actionCompleted={actionCompleted.setRecommendations}
              />
            )}
          </div>
        </Button>

        {isSaving && <Loader.Circle className="ml-auto" size={10} />}
      </div>
      {positionSaved && (
        <span className="text-xs font-semibold text-gray-600">
          ✅ Position updated successfully!
        </span>
      )}
      {designSaved && (
        <span className="text-xs font-semibold text-gray-600">
          ✅ Design updated successfully!
        </span>
      )}
    </div>
  )
}
