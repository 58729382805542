import EditorForm from 'components/EditorForm'
import React from 'react'

export default function Shipping({ value, handleUpdate, accountData }) {
  const {
    settings: { shipping: shippingValues },
  } = value

  return (
    <EditorForm
      layout={LAYOUT}
      value={shippingValues}
      handleUpdate={(values) => {
        const updateObj = {
          settings: {
            shipping: {
              ...shippingValues,
              ...values,
            },
          },
        }
        handleUpdate(updateObj)
      }}
    />
  )
}

const LAYOUT = [
  {
    key: 'enabled',
    label: 'Enabled',
    controlType: 'switch_toggle',
  },
  {
    key: 'amount',
    label: 'Threshold Amount',
    controlType: 'currency',
  },
  {
    key: 'shippingText',
    label: 'Threshold Label',
    controlType: 'translation',
  },
  {
    key: 'thresholdBypassText',
    label: 'Threshold Bypass Text',
    controlType: 'translation',
    helpText:
      'This text is displayed instead of the threshold label when the threshold has been reached',
  },
]
