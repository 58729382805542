import React from 'react'
import Button from 'components/Button'
import Badge from 'components/Badge'

export default function AppCard({
  name,
  slug,
  isRecommended,
  logo,
  siteLink,
  installLink,
  description,
  installedOnShop,
  isEnabled,
  comingSoon,
  onUpdateIntegration,
}) {
  let actionButton
  if (installedOnShop === false) {
    actionButton = (
      <Button href={installLink} className="mt-auto" target="_blank">
        Install
      </Button>
    )
  } else if (comingSoon) {
    actionButton = (
      <Button variant='bordered' href={installLink} className="mt-auto" target="_blank">
        Coming Soon
      </Button>
    )
  } else {
    actionButton = (
      <Button
        variant={isEnabled ? 'bordered' : 'solid'}
        onClick={() =>
          onUpdateIntegration({
            client: slug,
            isEnabled: !isEnabled,
            usageLimit: 5000,
          })
        }
        className="mt-auto"
      >
        {isEnabled ? 'Disable' : 'Enable'}
      </Button>
    )
  }
  return (
    <div className="flex w-full px-2 mb-4">
      <div className="relative flex flex-col items-center justify-start w-full px-3 pt-10 pb-6 text-center bg-white rounded-lg shadow-lg">
        {isRecommended && (
          <Badge
            className="absolute top-0 right-0 mt-1 mr-1"
            color="green"
            spacing="regular"
          >
            Recommended
          </Badge>
        )}
        <a href={siteLink} target="_blank">
          <img
            className="w-auto h-16 mx-auto mb-6 rounded xl:h-24"
            src={logo}
            alt={name}
          />
        </a>
        <a href={siteLink} target="_blank">
          <h2 className="mb-1 text-xl font-medium leading-tight text-gray-700">
            {name}
          </h2>
        </a>
        <span className="block mb-5 text-sm text-blue-500">{description}</span>
        {actionButton}
      </div>
    </div>
  )
}
