import { POPUPS } from 'constants/features'
import { isFeatureEnabled } from 'utils/features'
import { keyFromType } from 'utils/constants'

import {
  TRIGGER_OPTIONS,
  POPUP_TEMPLATES,
  POPUP_RECOMMENDATION_TYPES,
  POPUP_STATUS_OPTIONS,
} from './constants'

export function getTriggerTypeLabelFromType(type) {
  return keyFromType('label', TRIGGER_OPTIONS, type)
}

export function popupRecommendationLabelFromType(type) {
  return keyFromType('label', POPUP_RECOMMENDATION_TYPES, type)
}

export function getTemplateInfoFromType(type) {
  const templateKey = Object.keys(POPUP_TEMPLATES).find(
    (key) => POPUP_TEMPLATES[key].value === type
  )
  return POPUP_TEMPLATES[templateKey]
}

export function getPopupStatusOptions(currStatus, accountData) {
  let allowedStatuses = []
  const featureEnabled = isFeatureEnabled(accountData, POPUPS)
  switch (currStatus) {
    case 'draft':
      allowedStatuses = ['active']
      if (featureEnabled) {
        allowedStatuses.push('deleted')
      }
      break
    case 'inactive':
      allowedStatuses = ['active', 'deleted']
      break
    case 'active':
      allowedStatuses = ['inactive', 'deleted']
      break
    case 'deleted':
    default:
      break
  }

  return POPUP_STATUS_OPTIONS.map((option) => {
    if (allowedStatuses.includes(option.value) || option.value === currStatus) {
      return {
        ...option,
        ...(option.value === 'active'
          ? { locked: !featureEnabled }
          : { locked: false }),
      }
    }
  }).filter(Boolean)
}
