import React from 'react'

import EditorForm from 'components/EditorForm'

export default function GeneralSettings({ value, handleUpdate }) {
  return (
    <EditorForm layout={LAYOUT} value={value} handleUpdate={handleUpdate} />
  )
}

const LAYOUT = [
  [
    {
      key: 'layout',
      label: 'Layout',
      controlType: 'single_select',
      options: [
        { label: 'Full Width', value: 'full_width' },
        { label: 'Centered', value: 'center' },
      ],
    },
    {
      key: 'background',
      label: 'Background',
      controlType: 'single_select',
      options: [
        { label: 'Transparent', value: 'transparent' },
        { label: 'Light Gray', value: 'secondary' },
      ],
    },
  ],
  [
    {
      key: 'border', // TODO: Switch to checkbox control type
      label: 'Border',
      controlType: 'single_select',
      options: [
        { label: 'Hide', value: 'none' },
        { label: 'Show', value: 'block' },
      ],
    },
    {
      key: 'alignment',
      label: 'Alignment',
      controlType: 'single_select',
      options: [
        { label: 'Leading', value: 'leading' },
        { label: 'Center', value: 'center' },
        { label: 'Trailing', value: 'trailing' },
      ],
    },
  ],
  [
    {
      key: 'spacing',
      label: 'Line Spacing',
      controlType: 'single_select',
      options: [
        { label: 'Extra Tight', value: 'xtight' },
        { label: 'Tight', value: 'tight' },
        { label: 'Loose', value: 'loose' },
        { label: 'Extra Loose', value: 'xloose' },
      ],
    },
    {},
  ],
]
