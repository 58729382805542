import React from 'react'

import Form from 'components/Form'
import { useOpenModal } from 'components/Modal'

import UpsertExperienceModal from 'containers/Experiences/Modals/UpsertExperience'
import ConfirmDuplicationModal from 'containers/Experiences/Modals/ConfirmDuplication'
import ConfirmArchiveModal from 'containers/Experiences/Modals/ConfirmArchive'

export default function Options({
  experience,
  totalWeight,
  billing,
  account,
  updateLocalExperiences,
  openUpgradeAccount,
}) {
  const openModal = useOpenModal()
  const { name: shop } = account.data

  return (
    <Form.Field
      control="select"
      size="small"
      options={[
        {
          label: 'Options',
          value: 'all',
          disabled: true,
        },
        { label: 'Duplicate', value: 'duplicate' },
        {
          label: 'Edit',
          value: 'edit',
        },
        {
          label: 'Archive',
          value: 'archive',
        },
      ]}
      onClick={(event) => {
        event.stopPropagation()
      }}
      onChange={(event) => {
        var selectedOption = event.target.value
        if (openUpgradeAccount && typeof openUpgradeAccount === 'function') {
          openUpgradeAccount()
          return
        }
        switch (selectedOption) {
          case 'edit':
            openModal(
              <UpsertExperienceModal
                billing={billing}
                account={account}
                experience={experience}
                currentTotalWeight={totalWeight}
                updateLocalExperiences={updateLocalExperiences}
              />,
              { fullScreen: false, className: 'w-2/5' }
            )
            break
          case 'archive':
            openModal(
              <ConfirmArchiveModal
                experience={experience}
                updateLocalExperiences={updateLocalExperiences}
                shop={shop}
              />,
              {
                fullScreen: false,
              }
            )
            break
          case 'duplicate':
            openModal(
              <ConfirmDuplicationModal
                experience={experience}
                updateLocalExperiences={updateLocalExperiences}
                shop={shop}
              />,
              {
                fullScreen: false,
              }
            )
            break
        }
      }}
      value="all"
    />
  )
}
