import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import WaitForData from 'components/WaitForData'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import DateRangePicker from 'components/DateRangePicker'
import { AB_TESTING } from 'constants/features'

import ExperienceCard from './ExperienceCard'
import UpsertExperience from './Modals/UpsertExperience'
import useExperiences from './hooks/useExperiences'

export default function Experiences({ account, store }) {
  const {
    pages,
    billing,
    experiences,
    onDateRangeChange,
    updateLocalExperiences,
    updateWidgetData,
    featureEnabled,
    startDate,
    endDate,
  } = useExperiences({ account })

  const openModal = useOpenModal()

  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal account={account} featureToEnable={AB_TESTING} />
    )
  }

  return (
    <WaitForData
      objects={[pages, billing, experiences]}
      loadingMessage="Loading dashboard data"
      errorMessage="Trouble fetching account details..."
    >
      {() => {
        const { experiences: allExperiences, totalWeight } = experiences.data
        return (
          <div className="flex flex-col items-stretch flex-1">
            <div className="flex flex-row items-center justify-between mb-5">
              <Text.ScreenHeading marginBottom={0}>
                A/B Experiences
              </Text.ScreenHeading>
              <div>
                {false && (
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onRangeChange={onDateRangeChange}
                    className="mr-3"
                  />
                )}
                <Button
                  onClick={(event) => {
                    event.stopPropagation()
                    if (featureEnabled) {
                      openModal(
                        <UpsertExperience
                          billing={billing}
                          account={account}
                          currentTotalWeight={totalWeight}
                          updateLocalExperiences={updateLocalExperiences}
                        />,
                        { fullScreen: false, className: 'w-2/5' }
                      )
                    } else {
                      openUpgradeAccount()
                    }
                  }}
                >
                  <div className="flex items-center justify-center">
                    <span className="">+ Add New Experience</span>
                    {!featureEnabled && (
                      <Icons.Lock
                        className={'w-5 ml-4 mb-1 fill-current opacity-75'}
                      />
                    )}
                  </div>
                </Button>
              </div>
            </div>
            <div className="flex flex-col w-full mr-3">
              {allExperiences
                .filter((experience) => experience.status !== 'archieved')
                .sort((e1, e2) => {
                  if (
                    e1.status === 'unpublished' &&
                    e2.status === 'unpublished'
                  ) {
                    if (e1.id > e2.id) {
                      return 1
                    } else {
                      return -1
                    }
                  } else if (e1.status === 'unpublished') {
                    return 1
                  } else if (e2.status === 'unpublished') {
                    return -1
                  } else if (e1.id > e2.id) {
                    return 1
                  } else {
                    return -1
                  }
                })
                .map((experience) => {
                  return (
                    <ExperienceCard
                      key={experience.id}
                      experience={experience}
                      account={account}
                      pages={pages}
                      billing={billing}
                      updateLocalExperiences={updateLocalExperiences}
                      updateWidgetData={updateWidgetData}
                      totalWeight={totalWeight}
                      openUpgradeAccount={
                        !featureEnabled ? openUpgradeAccount : null
                      }
                      canUnpublish={
                        allExperiences.filter(
                          (exp) => exp.status === 'published'
                        ).length > 1 && !experience.isDefault
                      }
                    />
                  )
                })}
            </div>
          </div>
        )
      }}
    </WaitForData>
  )
}
