import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import useAppState from 'hooks/appState'

export function useQueryParams() {
  const location = useLocation()
  const {
    state: { shop },
  } = useAppState()

  return { shop, ...queryString.parse(location.search) }
}
