const NONE = {
  value: null,
  label: 'No criteria',
}

const RANDOM = {
  value: 'random',
  label: 'Random',
}

const BESTSELLERS = {
  value: 'bestsellers',
  label: 'Best Sellers',
}

const TRENDING = {
  value: 'trending',
  label: 'Trending',
}

export { NONE, RANDOM, BESTSELLERS, TRENDING }
