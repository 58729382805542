import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { EMAIL_SUPPORT } from 'constants/support'
import CodeEditor from 'components/CodeEditor'
import { openChat } from 'utils/external'
import { isRkSupport } from 'utils/account'

export default function CustomJS({ store, account }) {
  const [js, setJs] = React.useState(store.data.js || DEFAULT_HOOKS)
  const openToast = useToast()

  async function onSave() {
    try {
      new Function(js)
      await store.update({ js })
      openToast({
        type: 'success',
        text: 'Custom JS has been updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Custom JS could not be saved! Please ensure valid format.',
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <Text.ScreenHeading marginBottom={1}>
        Add Custom Javascript
      </Text.ScreenHeading>
      <Text.SubText marginBottom={5}>
        Add custom javascript hooks to be run on the store. For any assistance
        please write to{' '}
        <a className="hover:underline" href={`mailto:${EMAIL_SUPPORT}`}>
          {EMAIL_SUPPORT}
        </a>{' '}
        or {'  '}
        <span
          onClick={openChat}
          className="font-semibold text-primary cursor-pointer hover:underline"
        >
          Chat with us
        </span>
      </Text.SubText>

      <div className="flex flex-col items-start flex-1 w-full">
        <div className="flex flex-row flex-wrap w-full notranslate">
          <CodeEditor
            placeholder="You can edit/add Javascript code here."
            mode="javascript"
            value={js}
            onChange={setJs}
            name="shop_js_editor"
            rkSupport={isRkSupport(account.data)}
          />
        </div>
        <Button size="small" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  )
}

const DEFAULT_HOOKS = `return {
  onAddToCart: function() {},

  loadAjaxCart: function() {},
  unloadAjaxCart: function() {},
}`
