export function renderCurrency(
  price,
  currencyFormat,
  options = {
    escapeHTML: false,
    skipFloatParse: false,
  }
) {
  const { escapeHTML, skipFloatParse } = options
  const formattedCurrency = currencyFormat?.replace(
    /{{(.*?)}}/,
    skipFloatParse ? price : parseFloat(price).toFixed(2)
  )

  if (!escapeHTML) {
    return formattedCurrency
  }

  var div = document.createElement('div')
  div.innerHTML = formattedCurrency
  return div.textContent || div.innerText || ''
}
