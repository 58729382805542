import React from 'react'
import classNames from 'classnames'

import { useToast } from 'components/Toast'
import Icons from 'components/Icons'
import Button from 'components/Button'

import General from './General'
import Banner from './Banner'
import Shipping from './Shipping'
import Thresholds from './Thresholds'
import Recommendations from './Recommendations'
import Pricing from './Pricing'
import Actions from './Actions'

export default function Editor({ layout, accountData }) {
  const {
    data,
    groups,
    activeGroup,
    toggleActiveGroup,
    updateGroup,
    setErrors,
    formError,
    onSave,
    isSaving,
  } = layout
  const openToast = useToast()
  if (activeGroup) {
    const activeGroupObj = groups.find((group) => group.id === activeGroup)
    const GroupComponent = GROUP_ID_TO_COMPONENT_MAP[activeGroup]
    if (!GroupComponent) {
      return null
    }
    return (
      <div className="flex flex-col items-stretch w-full">
        <div
          className={classNames(
            'flex items-center justify-start px-4 py-5 text-base font-medium cursor-pointer group hover:bg-gray-10'
          )}
          onClick={() => toggleActiveGroup(activeGroupObj.id)}
        >
          <Icons.ChevronRight
            className={classNames(
              'mr-4 w-4 transition-transform rotate-180',
              {}
            )}
          />
          <span>{activeGroupObj.label}</span>
        </div>
        <div className={'p-4 border-t border-gray-10'}>
          <div className="">
            <GroupComponent
              value={data}
              handleUpdate={(values) => {
                updateGroup(values)
                if (typeof activeGroupObj.validate === 'function') {
                  activeGroupObj.validate(values)
                }
              }}
              formError={formError[activeGroupObj.id]}
              accountData={accountData}
            />
          </div>
          <div className="flex flex-row justify-end w-full mt-3">
            <Button
              size="small"
              onClick={() => {
                const payload = activeGroupObj.buildUpdate(activeGroupObj.value)
                const isValid =
                  typeof activeGroupObj.validate === 'function'
                    ? activeGroupObj.validate(payload)
                    : true
                if (isValid) {
                  onSave(payload)
                } else {
                  openToast({
                    type: 'error',
                    text: 'Please correct the errors to save',
                  })
                }
              }}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <React.Fragment>
      <ul className="relative flex flex-col w-full ">
        {groups.map((group, index) => {
          const isActive = group.id === activeGroup
          const nextIsActive = groups[index + 1]?.id === activeGroup
          const GroupComponent = GROUP_ID_TO_COMPONENT_MAP[group.id]

          if (!GroupComponent) {
            return null
          }

          return (
            <li
              key={group.id}
              className={classNames('flex flex-col items-stretch bg-white', {
                'border-b-8 border-gray-50': isActive,
                'border-t-8': isActive && index > 0,
              })}
            >
              <div
                className={classNames(
                  'flex items-center justify-between px-4 py-5 text-base font-medium cursor-pointer group hover:bg-gray-10',
                  {
                    'border-b border-gray-200': !isActive && !nextIsActive,
                    'text-primary': isActive,
                  }
                )}
                onClick={() => toggleActiveGroup(group.id)}
              >
                <span>{group.label}</span>
                <Icons.ChevronRight
                  className={classNames('w-4 transition-transform', {
                    'transform rotate-180 text-primary': isActive,
                    ' text-gray-600': !isActive,
                  })}
                />
              </div>
              {/* {isActive && (
                <div className={classNames('p-4 border-t border-gray-10')}>
                  <GroupComponent
                    value={data.settings}
                    handleUpdate={(values) => {
                      updateGroup(values)
                      if (typeof group.validate === 'function') {
                        group.validate(values)
                      }
                    }}
                    formError={formError[group.id]}
                    accountData={accountData}
                  />
                  <div className="flex flex-row justify-end w-full mt-3">
                    <Button
                      size="small"
                      onClick={() => {
                        const payload = group.buildUpdate(group.value)
                        const isValid =
                          typeof group.validate === 'function'
                            ? group.validate(payload)
                            : true
                        if (isValid) {
                          onSave(payload)
                        } else {
                          openToast({
                            type: 'error',
                            text: 'Please correct the errors to save',
                          })
                        }
                      }}
                      loading={isSaving}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )} */}
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}

const GROUP_ID_TO_COMPONENT_MAP = {
  general: General,
  banner: Banner,
  thresholds: Thresholds,
  shipping: Shipping,
  recommendation: Recommendations,
  pricing: Pricing,
  actions: Actions,
}
