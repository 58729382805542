import React, { useState } from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import { experienceCreate } from 'api/experiences'

export default function ConfirmDuplication({
  experience,
  close,
  updateLocalExperiences,
  shop,
}) {
  const { name } = experience
  const [loading, setLoading] = useState(false)

  async function handleDuplicate() {
    setLoading(true)
    var { data: updatedExperiences } = await experienceCreate(
      {
        copyExperienceId: experience.id,
      },
      { shop }
    )
    updateLocalExperiences(updatedExperiences, false)
    setLoading(false)
    close()
  }
  return (
    <Box>
      <Box.Heading>{name} - Confirm Duplication</Box.Heading>
      <div className="flex flex-col items-start ">
        <span>
          Are you sure you wish to duplicate this experience ? This action will
          duplicate all the settings of '{name}'.
        </span>

        <div className="flex flex-row items-center justify-end w-full mt-4">
          <Button size="small" variant="bordered" onClick={close}>
            Close
          </Button>

          <Button
            loading={loading}
            size="small"
            className="ml-3"
            onClick={handleDuplicate}
          >
            Duplicate
          </Button>
        </div>
      </div>
    </Box>
  )
}
