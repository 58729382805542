import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { cacheKey, useApi } from 'hooks/api'
import NavigationTabs from 'components/NavigationTabs'
import WaitForData from 'components/WaitForData'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useToast } from 'components/Toast'
import { templateUpdate as templateUpdateApi } from 'api/templates'
import { canUpdateTemplate } from 'utils/features'
import { isRkSupport } from 'utils/account'
import { CUSTOM_TEMPLATES } from 'constants/features'

import TemplateLiquid from './Liquid'
import TemplateJS from './JS'
import TemplateCSS from './CSS'
import TemplateSettings from './Settings'
import TemplateTranslations from './Translations'

export default function Template({ account }) {
  const { name: shop } = account.data
  const {
    params: { templateId },
  } = useRouteMatch('/templates/:templateId')
  const openToast = useToast()
  const template = useApi(`templates/${templateId}/`, { shop })
  const [isSaving, setIsSaving] = React.useState(false)
  const updateTemplateEnabled = canUpdateTemplate(account.data)

  const openModal = useOpenModal()
  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal
        account={account}
        featureToEnable={CUSTOM_TEMPLATES}
      />
    )
  }
  async function saveTemplate(data) {
    setIsSaving(true)

    const newTemplate = await templateUpdateApi(templateId, data, { shop })
    try {
      template.update(newTemplate.data, {
        local: true,
        cacheKey: cacheKey(`templates/${templateId}/`, { shop }),
      })
      openToast({
        type: 'success',
        text: 'Template saved successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'There was an error in saving the template.',
      })
    }
    setIsSaving(false)
  }

  function handleValueUpdate(data) {
    template.update(
      {
        template: {
          ...template.data.template,
          ...data,
        },
      },
      { local: true }
    )
  }

  return (
    <WaitForData
      objects={[template]}
      loadingMessage="Fetching template data"
      errorMessage="Trouble fetching template data"
    >
      {() => {
        return (
          <div className="flex flex-col items-stretch flex-1">
            <div className="flex flex-row flex-wrap items-center mb-6">
              <h3 className="mr-3 text-xl font-semibold text-gray-800 lg:text-xl">
                {template.data.template.name}
              </h3>
            </div>
            <NavigationTabs
              tabs={TABS}
              visibilityCheckParams={[account.data]}
              tabProps={{
                template: template.data.template,
                account,
                isSaving,
                onSave: (data) => saveTemplate(data),
                onChange: (data) => handleValueUpdate(data),
                openUpgradeAccount: updateTemplateEnabled
                  ? false
                  : openUpgradeAccount,
              }}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}

const TABS = [
  {
    label: 'Liquid',
    value: 'liquid',
    tooltip: 'You can update liquid code of this template.',
    component: TemplateLiquid,
    root: true,
  },
  {
    label: 'Javascript',
    value: 'js',
    tooltip: 'Update Javascript using this tab.',
    component: TemplateJS,
  },
  {
    label: 'CSS',
    value: 'css',
    tooltip: 'Update style using CSS from this tab.',
    component: TemplateCSS,
  },
  {
    label: 'Multi-Language Translations',
    value: 'translations',
    tooltip: 'Update multi-language translations from this tab.',
    component: TemplateTranslations,
  },
  {
    label: 'Advanced Settings',
    value: 'settings',
    tooltip: 'Update advanced settings from this tab..',
    component: TemplateSettings,
  },
]
