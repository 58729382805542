function template(account, campaign, isPreview = false) {
  if (!campaign || !campaign.settings) {
    return ''
  }
  const baseURL = 'https://recommendations.loopclub.io/'
  const previewEmail = account.shop.email
  const trackingURL = `${baseURL}v1/marketing/track/view.gif?d=${
    campaign.trackingStr
  }&email=${isPreview ? `${previewEmail}` : '*|EMAIL|*'}`
  const products = (campaign.data && campaign.data.products) || []
  const {
    productsPerRow,
    productsCount,
    title,
    titleFontSize,
    showProductTitle,
    showProductPrice,
    callToActionText,
  } = campaign.settings
  let productPositionCounter = 0

  const productRows = products
    .slice(0, productsCount)
    .reduce((rows, product) => {
      const lastRow = rows[rows.length - 1]
      if (!lastRow) {
        return [[product]]
      }

      if (lastRow.length < productsPerRow) {
        return [...rows.slice(0, rows.length - 1), [...lastRow, product]]
      }

      return [...rows, [product]]
    }, [])

  function productHTML(product, index) {
    const { image, price, title, trackingStr } = product
    const clickURL = `${baseURL}v1/marketing/track/click/?d=${trackingStr}&position=${productPositionCounter}${
      isPreview ? `&email=${previewEmail}` : '&email=*|EMAIL|*'
    }`
    productPositionCounter += 1
    return `
      <div class="rk_email_col_t" style="display: inline-table; max-width: 50%; width: 100%; word-break: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; border-collapse: collapse !important; vertical-align: top; text-align: left; position: relative; color: #222; margin: 20px 0 20px 0; padding: 0;">
        <table class="rk_email_col" style="display: inline-table; word-break: break-word; border-spacing: 0; border-collapse: collapse; vertical-align: top; text-align: left; width: 100%; margin: 0 auto; padding: 0;">
          <tbody>
            <tr style="vertical-align: bottom; text-align: left; padding: 0;">
              <td class="rk_email_imgwrap_t" style="word-break: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; border-collapse: collapse !important; vertical-align: middle; text-align: center; color: #222222; margin: 0; padding: 0 10px 0 10px; height: 200px" align="center" valign="bottom">
                <a href="${clickURL}" target="_blank">
                  <img class="rk_email_img" border="0" height="auto" width="100" src="${image}" alt="" style="outline: none; text-decoration: none; -ms-interpolation-mode: bicubic; width: auto; height: auto; float: none; clear: both; display: block; margin: 0 auto; border: none; max-width: 100%; max-height: 200px">
                </a>
              </td>
            </tr>
            <tr style="vertical-align: bottom; text-align: left; padding: 0;">
              <td style="word-break: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; border-collapse: collapse !important; vertical-align: top; text-align: center; color: #222222; margin: 0; padding: 0 10px 0 10px;" align="center" valign="top">
                <a href="${clickURL}" target="_blank" style="text-decoration: none !important;">
                  ${
                    showProductTitle
                      ? `<h3 style="font-size: 14px !important;font-weight: normal !important; text-align: center !important; color: #000000 !important; text-decoration: none !important; font-style: normal !important;margin: 10px 0;">${title}</h3>`
                      : ''
                  }
                  ${
                    showProductPrice
                      ? `<p style="font-size: 12px !important;font-weight: normal !important; text-align: center !important; color: #000000 !important; text-decoration: none !important; font-style: normal !important;margin: 10px 0;">${price}</p>`
                      : ''
                  }
                  ${
                    callToActionText
                      ? `<span style="display: inline-block !important; vertical-align: top !important; width: 50% !important;font-size: 12px !important;color: #FFFFFF !important;border-radius: 2px !important;background: #000000 !important;width: 100%;border: 0;margin-bottom: 5px;padding: 7px 15px !important;cursor: pointer;text-decoration: none !important;" name="add" class="btn">${callToActionText}</span>`
                      : ''
                  }
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    `
  }

  function rowHTML(row) {
    return `
      <tr>
        <td align="center" valign="top" style="font-size:0;padding:0;text-align:center;">
          <!--[if mso]>
            <table border="0" cellspacing="0" cellpadding="0" role="presentation" align="center" width="580"><tbody>
          <![endif]-->
            <!--[if mso]>
              <tr>
            <![endif]-->
            <!--[if mso]>
              <td valign="top" width="125" align="center">
            <![endif]-->
            ${row.map(productHTML).join('')}
            <!--[if mso]>
              </td>
            <![endif]-->
            <!--[if mso]>
              </tr>
            <![endif]-->
          <!--[if mso]>
            </tbody></table>
          <![endif]-->
        </td>
      </tr>
    `
  }

  //NOTE(Dhruv): Use https://www.freeformatter.com/css-beautifier.html when editing
  const STYLE = `
    <style type="text/css">
      .rk_email_row_t{display:table!important;width:100%!important;table-layout:fixed!important}.rk_email_col,.rk_email_col_t{display:inline-table!important}.rk_email_col_t{text-align:center!important}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:480px){.rk_email_header_t{font-size:20px!important}table[class=rk_email_wrapper_t]{width:100%!important}div[class=rk_email_col_t]{max-width:50%!important}td[class=rk_email_imgwrap_t]{height:190px!important}img[class=rk_email_img]{max-height:190px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:400px){img[class=rk_email_img]{max-height:180px!important}td[class=rk_email_imgwrap_t]{height:180px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:380px){td[class=rk_email_imgwrap_t]{height:170px!important}img[class=rk_email_img]{max-height:170px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:360px){td[class=rk_email_imgwrap_t]{height:160px!important}img[class=rk_email_img]{max-height:160px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:340px){img[class=rk_email_img]{max-height:150px!important}td[class=rk_email_imgwrap_t]{height:150px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:320px){img[class=rk_email_img]{max-height:140px!important}td[class=rk_email_imgwrap_t]{height:140px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:300px){img[class=rk_email_img]{max-height:130px!important}td[class=rk_email_imgwrap_t]{height:130px!important}}
    </style>
  `

  return `
    ${STYLE}
    <img src=${trackingURL} height="1px" width="1px" />
    <!--[if mso]>
      <table role="presentation" cellspacing="0" align="center" width="581px" border="0" cellpadding="0"><tbody><tr><td>
    <![endif]-->
    <table class="rk_email_wrapper_t" cellpadding="0" border="0" cellspacing="0" align="center" bgcolor="#fff" style="max-width: 501px !important; background-color: transparent;font-family: Arial !important;">
      <tbody>
        <tr>
          <td align="center" valign="top" style="padding:0;">
            <h2 class="rk_email_header_t" style="font-size: ${titleFontSize}px !important; font-weight: normal !important; text-align: center !important; color: #000000 !important; text-decoration: none !important; font-style: normal !important; padding: 20px 0 20px 0 !important; margin: 0 !important;">${title}</h2>
            <table class="rk_email_row_t" style="border-spacing: 0; border-collapse: collapse; vertical-align: top; text-align: left; width: 100%; position: relative; padding: 0; table-layout: fixed;">
              <tbody>
              ${productRows.map(rowHTML).join('')}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <!--[if mso]>
      </td></tr></tbody></table>
    <![endif]-->
  `
}

export default template
