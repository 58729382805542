import api from 'api'
import { useState, useEffect } from 'react'
import { widgetCreate as widgetCreateApi } from 'api/widgets'

export default function ({ pages }) {
  const [widget, setWidget] = useState(null)
  const [checkedPages, setCheckedPages] = useState([])

  useEffect(() => {
    if (!widget) {
      return setCheckedPages([])
    }

    const pageTypesWithWidgetEnabeld = pages.data
      .filter((page) => {
        return page.widgets.reduce((hasSimilarWidgetEnabled, pageWidget) => {
          return (
            hasSimilarWidgetEnabled ||
            (pageWidget.type === widget.value && pageWidget.enabled)
          )
        }, false)
      })
      .map((page) => page.type)

    setCheckedPages(
      widget.allowedOnPages.filter((page) =>
        pageTypesWithWidgetEnabeld.includes(page.value)
      )
    )
  }, [widget, pages.data])

  async function createWidget(widget, pagesToEnableWidgetOn, shop, shopId) {
    const { data: pagesData } = await api.get('pages/', { params: { shop } })

    return await Promise.all(
      pagesData
        .filter(({ type }) => pagesToEnableWidgetOn.includes(type))
        .map(async (pageData) => {
          return await enableWidgetForPage(pageData, widget, shop, shopId)
        })
    )
  }

  return {
    widget,
    setWidget,
    createWidget,
    checkedPages,
    setCheckedPages,
  }
}

async function enableWidgetForPage(pageData, widget, shop, shopId) {
  const { value: widgetType } = widget
  let widgetForPage = pageData.widgets.filter(
    ({ type }) => type === widgetType
  )[0]

  if (!widgetForPage) {
    widgetForPage = await createWidgetForPage(widget, pageData, shop, shopId)
  }

  return widgetForPage
}

async function createWidgetForPage(widget, page, shop, shopId) {
  const { data: createdWidget } = await widgetCreateApi(
    {
      page: page.id,
      type: widget.value,
      shop: shopId,
      title: widget.label === 'Custom' ? 'You May Also Like' : widget.label,
    },
    { shop }
  )

  return createdWidget
}
