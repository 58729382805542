import { useState, useMemo, useRef, useCallback } from 'react'
import { useInfiniteQuery } from 'react-query'

import { fetchProducts as fetchProductsApi } from 'api/products'
import * as QueryUtils from 'utils/query'
import { useApi } from 'hooks/api'
import { _get, _find, _uniqBy } from 'utils/lodash'
import { getAccountData } from 'utils/account'

export default function useProductsSlider({
  initialSelectedProducts,
  allowMultipleProducts,
  allowMultipleVariants,
  productsLimit,
  onSelect,
  onClose,
}) {
  const accountData = getAccountData()
  const [query, setQuery] = useState('')
  const [selectedProducts, setSelectedProducts] = useState(
    initialSelectedProducts
  )
  const queryInput = useRef(null)

  const productsQuery = useInfiniteQuery(
    [
      'products',
      {
        shop: accountData.name,
        filters: { q: query },
        order: [],
      },
    ],
    fetchProductsApi,
    {
      getNextPageParam: (lastPage, allPages) => {
        const lastPageData = _get(lastPage, 'data')
        const total = _get(lastPageData, 'total')
        const page = _get(lastPageData, 'pageParam.page')
        return page + 1 > total ? undefined : { page: page + 1 }
      },
    }
  )

  const pages = QueryUtils.pages(productsQuery)
  const products = pages.reduce((products, page) => {
    return _uniqBy([...products, ...page.data.data], 'productId')
  }, [])

  const clearQuery = useCallback(() => {
    setQuery('')
    if (queryInput && queryInput.current) {
      setTimeout(() => queryInput.current.focus())
    }
  }, [queryInput])

  const loadMore = useCallback(() => {
    productsQuery.fetchNextPage()
  }, [productsQuery])

  const toggleProductSelection = useCallback(
    (product) => {
      const productId = product.productId
      const isSelected = _find(selectedProducts, { productId })
      if (isSelected) {
        setSelectedProducts(
          selectedProducts.filter((p) => p.productId !== productId)
        )
      } else {
        const product = _find(products, { productId })
        if (!product) {
          return
        }

        const productObjectInSelectedProducts = {
          productId,
          variantsCount: product.variants.length,
          variantIds: allowMultipleVariants
            ? product.variants.map((v) => v.id)
            : [product.variants[0].id],
          shopifyProductObject: product,
        }

        if (
          allowMultipleProducts &&
          (productsLimit ? productsLimit > selectedProducts.length : true)
        ) {
          setSelectedProducts(
            _uniqBy(
              [productObjectInSelectedProducts, ...selectedProducts],
              'productId'
            )
          )
        } else if (productsLimit && productsLimit <= selectedProducts.length) {
          return
        } else {
          setSelectedProducts([productObjectInSelectedProducts])
        }
      }
    },
    [
      products,
      selectedProducts,
      setSelectedProducts,
      allowMultipleProducts,
      allowMultipleVariants,
    ]
  )

  const toggleVariantSelection = useCallback(
    (variantId, productId) => {
      setSelectedProducts(
        selectedProducts.map((selectedProduct) => {
          let update = null
          const product = _find(products, { productId })
          if (selectedProduct.productId === productId) {
            const variantSelected =
              selectedProduct.variantIds.includes(variantId)
            if (variantSelected) {
              if (selectedProduct.variantIds.length === 1) {
                toggleProductSelection(product)
              } else {
                update = {
                  variantIds: selectedProduct.variantIds.filter(
                    (id) => id !== variantId
                  ),
                }
              }
            } else if (
              selectedProduct.variantIds.length < 1 &&
              allowMultipleVariants
            ) {
              update = {
                variantIds: product.variants
                  .filter((variant) => variant.id !== variantId)
                  .map((variant) => variant.id),
              }
            } else {
              if (allowMultipleVariants) {
                update = {
                  variantIds: _uniqBy([
                    variantId,
                    ...selectedProduct.variantIds,
                  ]),
                }
              } else {
                update = { variantIds: [variantId] }
              }
            }
          }

          return update ? { ...selectedProduct, ...update } : selectedProduct
        })
      )
    },
    [products, selectedProducts, setSelectedProducts, allowMultipleVariants]
  )

  const handleSelectionComplete = useCallback(() => {
    if (typeof onSelect === 'function') {
      onSelect(selectedProducts)
    }
    setTimeout(onClose)
  }, [selectedProducts, onClose, onSelect])

  return useMemo(
    () => ({
      query,
      setQuery,
      clearQuery,
      queryInput,
      products,
      loadMore,
      toggleProductSelection,
      toggleVariantSelection,
      handleSelectionComplete,
      selectedProducts,
      isSearching: productsQuery.isFetching,
      isLoadingMore: productsQuery.isFetchingNextPage,
    }),
    [
      setQuery,
      query,
      products,
      clearQuery,
      loadMore,
      toggleProductSelection,
      toggleVariantSelection,
      handleSelectionComplete,
      selectedProducts,
      productsQuery.isFetching,
      productsQuery.isFetchingNextPage,
    ]
  )
}
