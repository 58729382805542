import React from 'react'
import classNames from 'classnames'

import {
  getTriggerTypeLabelFromType,
  getPopupStatusOptions,
} from 'containers/Popups/utils'
import { SelectVersionTwo } from 'components/Form/controls'
import FieldValue from 'components/ResourceList/FieldValue'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import Icons from 'components/Icons'
import Loader from 'components/Loader'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import usePopupMutations from 'hooks/queries/popup'
import { trimString } from 'utils/string'
import { getAccountData } from 'utils/account'
import { isFeatureEnabled } from 'utils/features'
import { _find } from 'utils/lodash'
import { POPUPS } from 'constants/features'

export default function TableCell(props) {
  const {
    value,
    cell: {
      column: { id: fieldName },
      row: { original },
    },
    state: { resourceSchema },
  } = props

  const type = resourceSchema.schema[fieldName].type
  const accountData = getAccountData()
  const openModal = useOpenModal()
  const openToast = useToast()
  const {
    updatePopup: { mutate: updatePopup, status: updatePopupStatus },
  } = usePopupMutations({ shop: accountData.name })

  const popupUpdating = updatePopupStatus === 'loading'
  const featureEnabled = isFeatureEnabled(accountData, POPUPS)
  const popupStatusOptions = getPopupStatusOptions(original.status, accountData)

  switch (type) {
    case 'details':
      return (
        <div className="flex flex-col items-start w-full">
          <span className="text-lg font-medium text-gray-900">
            {trimString(original.name, 20)}
          </span>
          <span className="mt-1 text-xs text-gray-500">
            Popup ID: {original.id}
          </span>
        </div>
      )
    case 'status':
      return (
        <div className="flex flex-row justify-center px-4">
          <div
            className="w-32"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {popupUpdating ? (
              <Loader />
            ) : (
              <SelectVersionTwo
                size="small"
                label={
                  <div
                    className={classNames(
                      'flex flex-row py-1.5 px-4 rounded border text-sm border-gray-400 w-full justify-between',
                      {
                        'pointer-events-none opacity-50': popupUpdating,
                      }
                    )}
                  >
                    <span>
                      {
                        _find(popupStatusOptions, function (o) {
                          return o.value === original.status
                        }).label
                      }
                    </span>
                    <Icons.ChevronDown className="w-3 ml-2 text-current" />
                  </div>
                }
                options={popupStatusOptions}
                onChange={(selectedOption) => {
                  const status = selectedOption.value
                  if (!popupUpdating) {
                    if (status === 'active' && status != original.status) {
                      if (!featureEnabled) {
                        openModal(
                          <UpgradeAccountModal
                            featureToEnable={POPUPS}
                            account={{ data: accountData }}
                          />
                        )
                      } else if (
                        Object.keys(original.triggers).some(
                          (triggerKey) => original.triggers[triggerKey].enabled
                        )
                      ) {
                        openToast({
                          type: 'error',
                          text: 'Cannot publish popup with no triggers',
                        })
                      } else if (!original.recommendationType) {
                        openToast({
                          type: 'error',
                          text: 'Cannot publish popup with no recommendation type',
                        })
                      } else if (!original.name) {
                        openToast({
                          type: 'error',
                          text: 'Cannot publish popup with no name',
                        })
                      } else if (!Boolean(original.template)) {
                        openToast({
                          type: 'error',
                          text: 'Cannot publish popup without a template',
                        })
                      } else if (featureEnabled) {
                        updatePopup({
                          id: original.id,
                          status,
                        })
                      }
                    } else {
                      updatePopup({
                        id: original.id,
                        status,
                      })
                    }
                  }
                }}
                value={_find(popupStatusOptions, function (o) {
                  return o.value === original.status
                })}
              />
            )}
          </div>
        </div>
      )
    case 'triggers':
      return (
        <div className="flex flex-col items-start w-full whitespace-normal">
          <span className="text-sm text-gray-900">
            {Object.values(original.triggers)
              .map((trigger) => {
                return getTriggerTypeLabelFromType(trigger.type)
              })
              .join(', ')}
          </span>
        </div>
      )
    default:
      return (
        <div className="w-full truncate" title={value}>
          <FieldValue
            value={value}
            type={type}
            isList={resourceSchema.schema[fieldName].list}
          />
        </div>
      )
  }
}
