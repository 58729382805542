import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { invalidate } from 'utils/query'
import { useToast } from 'components/Toast'

import Overview from './Overview'

export default function Review({ builder, rb, accountData }) {
  const { bundle, saveBundle, setPublishHandler, setActiveScreen } = builder
  const { setCloseHandler } = rb
  const history = useHistory()
  const openToast = useToast()

  useEffect(() => {
    async function bundlePublish() {
      if (bundle.items.products?.length < 1) {
        openToast({
          type: 'error',
          text: 'Cannot publish bundle with no products',
        })
      } else {
        await saveBundle(
          { status: 'active' },
          {
            successMessage: 'Congratulations! The bundle is live on your store',
          }
        )
        history.push(`/bundles?shop=${accountData.name}`)
        invalidate('bundles')
      }
    }

    async function confirmClose() {
      // TODO: Use toast to accept confirmation
      return window.confirm(
        'Bundle is not live! Are you sure you want to close ?'
      )
    }

    setPublishHandler(() => bundlePublish)
    setCloseHandler(() => confirmClose)
  }, [bundle, saveBundle, setPublishHandler, setCloseHandler, history])
  return (
    <div className="px-8 mt-6">
      <Overview
        accountData={accountData}
        bundle={bundle}
        onEdit={(screen) => setActiveScreen(screen)}
        mode="builder"
        builder={builder}
      />
    </div>
  )
}
