import React, { useState } from 'react'
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import getSymbolFromCurrency from 'currency-symbol-map'

import WaitForData from 'components/WaitForData'
import DateRangePicker from 'components/DateRangePicker'
import StatCard from 'components/StatCard'
import { AreaChart } from 'components/Chart'
import { DATE_FORMAT } from 'constants/date'
import { useApi } from 'hooks/api'
import { getStoreCurrency } from 'utils/account'

export default function WidgetAnalytics({ account, widget, store }) {
  const { name: shop } = account.data
  const [startDate, setStartDate] = useState(sub(new Date(), { days: 30 }))
  const [endDate, setEndDate] = useState(new Date())
  const widgetStats = useApi(`widgets/${widget.id}/stats/`, {
    shop,
  })

  const storeCurrency = getStoreCurrency(account.data)
  const currencySymbol = getSymbolFromCurrency(storeCurrency)

  function onDateRangeChange(date) {
    if (!date) {
      setStartDate(null)
      setEndDate(null)
      widgetStats.get(
        {
          date__gte: format(new Date(0), DATE_FORMAT),
          date__lte: format(new Date(), DATE_FORMAT),
        },
        { ignoreCache: true }
      )

      return
    }

    const [startDate, endDate] = date
    if (!startDate || !endDate) {
      return
    }

    setStartDate(startDate)
    setEndDate(endDate)
    widgetStats.get(
      {
        date__gte: format(startDate, DATE_FORMAT),
        date__lte: format(endDate, DATE_FORMAT),
      },
      {
        ignoreCache: true,
      }
    )
  }

  return (
    <WaitForData
      objects={[widgetStats]}
      loadingMessage="Loading widget analytics..."
      errorMessage="Trouble fetching analytics data..."
    >
      {() => {
        const { tilesData, conversionData } = widgetStats.data
        const charts = [
          {
            title: 'Widget Performance',
            areas: [
              { id: 'a', title: 'Page Views', key: 'ppv', color: '#9a3412' },
              {
                id: 'a',
                title: 'Widget Served',
                key: 'served',
                color: '#f97316',
              },
              {
                id: 'a',
                title: 'Widget Views',
                key: 'viewed',
                color: '#2dd4bf',
              },
              {
                id: 'a',
                title: 'Widget Clicks',
                key: 'clicked',
                color: '#ecc94b',
              },
            ],
          },
        ]
        return (
          <React.Fragment>
            <div className="flex flex-row items-center justify-end px-3 mb-3">
              <span className="mr-3 font-semibold">Filter by date range:</span>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onRangeChange={onDateRangeChange}
              />
            </div>
            <div className="flex flex-row flex-wrap">
              {tilesData.map(({ title, currency, value, description }) => {
                const type = currency ? 'currency' : 'number'
                return (
                  <StatCard
                    title={title}
                    description={description}
                    numberFormat={'0a'}
                    currencySymbol={currencySymbol}
                    storeCurrency={storeCurrency}
                    className="w-full px-3 mb-6 lg:w-1/3"
                    value={value}
                    key={title}
                    type={type}
                  />
                )
              })}
            </div>
            {charts.map((chart) => {
              let tag = <div></div>
              if (chart.areas) {
                tag = (
                  <AreaChart
                    key={chart.title}
                    data={conversionData}
                    chart={chart}
                  />
                )
              }
              return (
                <div key={chart.title} className="w-full px-3 mb-6">
                  {tag}
                </div>
              )
            })}
          </React.Fragment>
        )
      }}
    </WaitForData>
  )
}
