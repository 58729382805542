import React from 'react'

import Button from 'components/Button'
import Loader from 'components/Loader'
import Icons from 'components/Icons'

export default function ResourceListPagination({
  pagination,
  filters,
  queryStatus,
}) {
  const {
    offset,
    size,
    total,
    canMoveToNextPage,
    canMoveToPreviousPage,
    nextPage,
    prevPage,
    setSize,
    pageSizeOptions,
  } = pagination
  const activeFiltersCount = Object.keys(filters.state).reduce((count, key) => {
    const { active, values } = filters.state[key]
    count += active && values.length > 0 ? 1 : 0
    return count
  }, 0)

  return (
    <div className="flex items-center self-stretch justify-between mb-2 text-sm text-gray-800">
      <span className="flex items-center">
        <span className="pr-2 mr-2 border-r border-gray-400">
          Showing <span className="font-semibold">{offset + 1}</span> to{' '}
          <span className="font-semibold">
            {Math.min(offset + size, total)}
          </span>{' '}
          of <span className="font-semibold">{total}</span> results
        </span>
        <select
          onChange={(e) => setSize(parseInt(e.target.value))}
          value={size}
          className="py-1 pl-2 pr-4 text-xs border-gray-300 rounded cursor-pointer"
        >
          {pageSizeOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label} records per page
            </option>
          ))}
        </select>
        {!filters.skipFilters && (
          <div
            className="relative flex flex-row items-center px-2 py-1 ml-2 text-xs bg-white border border-gray-300 rounded cursor-pointer hover:bg-gray-100"
            onClick={filters.openSidebar}
          >
            <Icons.Filter className="w-3 mr-2" />
            Filter
            {activeFiltersCount > 0 && (
              <span className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 pt-0 -mt-2 -mr-2 text-xs font-medium text-white rounded-full bg-primary-800">
                {activeFiltersCount}
              </span>
            )}
          </div>
        )}
      </span>
      <span className="flex items-center">
        <Button
          variant="text"
          color="gray"
          size="xSmall"
          disabled={!canMoveToPreviousPage}
          onClick={prevPage}
        >
          Previous
        </Button>
        <span className="w-px h-4 mx-2 bg-gray-400"></span>
        <Button
          variant="text"
          color="gray"
          size="xSmall"
          disabled={!canMoveToNextPage}
          onClick={nextPage}
        >
          Next
        </Button>
        {queryStatus === 'loading' && (
          <Loader.Circle className="ml-2 text-indigo-600" color="indigo" />
        )}
      </span>
    </div>
  )
}
