import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import RenderMoney from 'components/RenderMoney'
import { _get, _find } from 'utils/lodash'
import { htmlToText, trimString } from 'utils/string'
import * as ShopifyUtils from 'utils/shopify'

import { getDiscountedAmount, buildAllowedQuantities } from '../../../utils'

export default function ProductRow({
  product,
  accountData,
  slot,
  itemAdded,
  translations,
  setItemsAdded,
  index,
}) {
  useEffect(() => {
    if (index == 0) {
      setItemsAdded([{ ...product, gloodDiscount: slot.discount }])
    }
  }, [index])
  const { recommendationRules = {}, recommendationType, discount = {} } = slot
  const { currency, currencyFormat } = accountData
  const { image, title, variants, bodyHtml } = product
  const { freeText, declineButtonText } = translations
  const {
    productTitle: titleFromRules,
    productDescription: descriptionFromRules,
    allowQuantityChange,
  } = recommendationRules
  const defaultQuantity = parseInt(recommendationRules.defaultQuantity) || 1
  const minQuantity = parseInt(recommendationRules.minQuantity) || 1
  const maxQuantity = parseInt(recommendationRules.maxQuantity) || 1

  const allowedVariants =
    recommendationType === 'manual' &&
    _get(recommendationRules, 'variantIds', []).length > 0
      ? variants.filter((v) =>
          _get(recommendationRules, 'variantIds', []).includes(v.id)
        )
      : variants

  const [selectedVariantId, setSelectedVariantId] = useState(
    allowedVariants[0].id
  )
  const [quantity, setQuantity] = useState(parseInt(defaultQuantity) || 1)

  if (!product || !selectedVariantId) {
    return null
  }
  const allowedQuantities = buildAllowedQuantities({
    min: allowQuantityChange ? minQuantity || defaultQuantity : defaultQuantity,
    max: allowQuantityChange ? maxQuantity || defaultQuantity : defaultQuantity,
  })

  const selectedVariant =
    _find(allowedVariants, { id: selectedVariantId }) || allowedVariants[0]

  const originalPrice = selectedVariant.price * quantity
  const discountedPrice =
    discount.value > 0
      ? getDiscountedAmount({ original: originalPrice, discount })
      : null

  return (
    <div className="p-6 border border-gray-300">
      <div className="flex items-start justify-start h-full">
        <div className="flex items-start justify-start w-full">
          <div className="w-36">
            <div className="relative overflow-hidden h-0 pt-[75%]">
              <img
                src={ShopifyUtils.optimisedImage({
                  baseUrl: image,
                  size: 460,
                })}
                alt={titleFromRules || title}
                className="absolute top-0 left-0 object-contain object-top w-full h-full"
              />
            </div>
          </div>
          <div className="flex-auto pl-4">
            <div className="flex flex-col justify-between">
              <h1 className="text-sm font-medium ">
                {titleFromRules || title}
              </h1>
              <div>
                <RenderMoney
                  price={originalPrice}
                  className={classNames('pr-2 text-xs ', {
                    'line-through text-gray-600': discount.value > 0,
                  })}
                />
                {discountedPrice != null &&
                  (parseInt(discountedPrice) > 0 ? (
                    <RenderMoney
                      price={discountedPrice}
                      className="text-xs font-medium leading-normal text-red-800"
                    />
                  ) : (
                    freeText
                  ))}
              </div>
              <div className="pt-3">
                <div className="flex items-stretch">
                  <div className="w-full">
                    <div className="relative">
                      <label className="absolute text-gray-600 top-1 left-3 text-xxs whitespace-nowrap">
                        Choose variant
                      </label>
                      <select className="w-full pt-4 pb-1 pl-3 text-xs border rounded cursor-pointer border-gray-250">
                        {allowedVariants.map((variant) => (
                          <option value={variant.id} key={variant.id}>
                            {variant.title}
                          </option>
                        ))}
                      </select>
                      {/* <div className="absolute flex border-l border-gray-300 top-1/2"></div> */}
                    </div>
                  </div>
                  <div className="w-full pl-6">
                    <div className="relative">
                      <label className="absolute text-gray-600 min-w-8 top-1 left-3 text-xxs whitespace-nowrap">
                        Quantity
                      </label>
                      <select className="w-full pt-4 pb-1 text-xs border rounded cursor-pointer pr-18 border-gray-250">
                        {allowedQuantities.map((quantity) => (
                          <option value={quantity} key={quantity}>
                            {quantity}
                          </option>
                        ))}
                      </select>
                      {/* <div className="absolute flex border-l border-gray-300 top-1/2"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow-0 flex-shrink-0 ml-4">
            <button
              className={classNames(
                'relative inline-block py-3 font-medium text-white rounded-md px-5 transition-color duration-300 ease-in-out',
                {
                  'bg-denim hover:bg-denim-dark cursor-pointer ': !itemAdded,
                  'bg-gray-300 cursor-not-allowed': itemAdded,
                }
              )}
            >
              <span className="text-xs leading-6 transition-opacity duration-300 ease-in-out">
                Add
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
