import React from 'react'
import { Waypoint } from 'react-waypoint'

export default function ({
  key,
  children,
  onLoadMore,
  scrollableAncestor = 'window',
}) {
  return (
    <React.Fragment>
      {children}
      <Waypoint
        key={key || 'infinite-scroll'}
        fireOnRapidScroll={true}
        scrollableAncestor={scrollableAncestor}
        bottomOffset="-20%"
        onEnter={onLoadMore}
      />
    </React.Fragment>
  )
}
