import React from 'react'
import SetupRecommendations from 'components/SetupRecommendations'

export default function WidgetRecommendations({
  widget,
  account,
  onSave,
  store,
}) {
  return (
    <SetupRecommendations
      widgetType={widget.type}
      pageType={widget.pageType}
      widgetId={widget.id}
      widget={widget}
      account={account}
      store={store}
      onWidgetSave={onSave}
    />
  )
}
