import React from 'react'
import classNames from 'classnames'
import Loader from 'components/Loader'
import Link from 'components/Link'
import Tooltip from 'components/Tooltip'
import styles from './styles.module.css'

function variantClass({ variant, disabled, color }) {
  const key = disabled ? 'disabled' : 'default'
  return VARIANT_TO_CLASS_MAP[variant][key](color)
}

const VARIANT_TO_CLASS_MAP = {
  solid: {
    default: (color) =>
      `bg-${color}-600 hover:bg-${color}-700 text-white border border-${color}-600 hover:border-${color}-600 focus:shadow-outline rounded shadow`,
    disabled: (color) =>
      'bg-gray-300 hover:bg-gray-300 text-gray-500 border border-gray-300 hover:border-gray-300 focus:shadow-outline rounded shadow',
  },
  light: {
    default: (color) =>
      `bg-${color}-200 hover:bg-${color}-600 hover:text-white text-${color}-600 focus:shadow-outline rounded shadow`,
    disabled: (color) =>
      'bg-gray-300 hover:bg-gray-300 text-gray-500 focus:shadow-outline rounded shadow',
  },
  bordered: {
    default: (color) =>
      `bg-white hover:bg-gray-100 border border-${color}-600 text-${color}-600 focus:shadow-outline rounded shadow`,
    disabled: (color) =>
      'bg-white hover:bg-white border border-gray-300 text-gray-300 focus:shadow-outline rounded shadow',
  },
  suppressed: {
    default: (color) =>
      'bg-gray-300 hover:bg-gray-300 text-gray-500 border border-gray-300 hover:border-gray-300 cursor-not-allowed outline-none focus:shadow-outline rounded shadow',
    disabled: (color) =>
      'bg-gray-300 hover:bg-gray-300 text-gray-500 border border-gray-300 hover:border-gray-300 outline-none focus:shadow-outline rounded shadow',
  },
  inactive: {
    default: (color) =>
      `bg-${color}-300 hover:bg-${color}-300 text-${color}-500 border border-${color}-300 hover:border-${color}-300 outline-none rounded shadow`,
    disabled: (color) =>
      `bg-${color}-300 hover:bg-${color}-300 text-${color}-500 border border-${color}-300 hover:border-${color}-300 outline-none rounded shadow`,
  },
  text: {
    default: (color) =>
      `bg-transparent hover:text-${color}-900 border-0 outline-none text-${color}-700`,
    disabled: (color) =>
      'bg-transparent cursor-not-allowed border-0 outline-none text-gray-400',
  },
}

const SIZE_TO_CLASS_MAP = {
  large: 'text-xl py-5 px-8',
  regular: 'text-base py-2 px-6',
  small: 'text-sm py-1 px-3',
  xSmall: 'text-xs py-1 px-2',
  xxSmall: styles.xxSmall,
}

export default function Button({
  variant,
  size,
  disabled,
  loading,
  color,
  className,
  href,
  internalLink,
  linkClassName,
  tooltipContent,
  tooltipTriggerClassName,
  children,
  ...rest
}) {
  disabled = disabled || loading

  let Tag = href ? 'a' : 'button'
  Tag = internalLink ? Link : Tag

  const tagProps = {
    disabled,
    className: classNames(
      'relative font-medium focus:outline-none ',
      variantClass({ variant, disabled, color }),
      SIZE_TO_CLASS_MAP[size],
      className,
      internalLink ? 'block' : '',
      {
        'cursor-not-allowed': disabled,
      }
    ),
    ...(href && !internalLink ? { href } : {}),
    ...(internalLink ? { to: href } : {}),
    ...rest,
  }

  let content = (
    <Tag {...tagProps}>
      <div className={loading ? 'opacity-0' : ''}>{children}</div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader color={color} />
        </div>
      )}
    </Tag>
  )

  if (!tooltipContent) {
    return content
  }

  return (
    <Tooltip
      content={tooltipContent}
      triggerClassName={classNames(tooltipTriggerClassName)}
    >
      {content}
    </Tooltip>
  )
}

Button.defaultProps = {
  color: 'primary',
  variant: 'solid',
  size: 'regular',
  disabled: false,
  loading: false,
}
