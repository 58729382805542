import React from 'react'
import numeral from 'numeral'
import { format, parse } from 'date-fns'
import WaitForData from 'components/WaitForData'
import Badge from 'components/Badge'
import Table from 'components/Table'
import { useApi } from 'hooks/api'

export default function Billing({ account }) {
  const { name: shop } = account.data
  const billingData = useApi(`account/billings/`, { shop })
  const { data: billingCycles } = billingData

  billingCycles &&
    billingCycles.sort((a, b) => (a.startedOn > b.startedOn ? -1 : 1))

  return (
    <WaitForData
      objects={[billingData]}
      loadingMessage="Fetching billing data"
      errorMessage="Trouble fetching billing data"
    >
      {() => (
        <div>
          <div className="flex flex-col px-3">
            <Table
              headings={['Start Date', 'End Date', 'Charges', 'Status']}
              rowItems={billingCycles}
              renderRow={(billing, index, rowClassName) => {
                return (
                  <tr key={index} className={rowClassName}>
                    <td className="px-4 py-2 text-left border">
                      {format(
                        parse(billing.startedOn, 'yyyy-MM-dd', new Date()),
                        'd MMMM, yyyy'
                      )}
                    </td>
                    <td className="px-4 py-2 text-left border">
                      {format(
                        parse(
                          billing.endedOn || billing.endingOn,
                          'yyyy-MM-dd',
                          new Date()
                        ),
                        'd MMMM, yyyy'
                      )}
                    </td>
                    <td className="p-4 text-left border">
                      <div>
                        Monthly Recurring Charge:{' '}
                        <strong>${billing.shopPlan.price}</strong>
                      </div>
                      <div>
                        Usage Charge: <strong>${billing.billingPrice}</strong>
                      </div>
                      <div>
                        Total:{' '}
                        <strong>
                          $
                          {numeral(
                            parseFloat(billing.shopPlan.price) +
                              parseFloat(billing.billingPrice)
                          ).format('0.00')}
                        </strong>
                      </div>
                      <div>
                        Total Widgets Served:{' '}
                        <strong>
                          {numeral(billing.currentUsage).format('0,0')}
                        </strong>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-left border">
                      {billing.endedOn ? (
                        <Badge color="yellow" spacing="regular">
                          {billing.isTrialPeriod ? 'Trial' : 'Charged'}
                        </Badge>
                      ) : (
                        <Badge color="green" spacing="regular">
                          {billing.isTrialPeriod ? 'Trial' : 'Pending'}
                        </Badge>
                      )}
                    </td>
                  </tr>
                )
              }}
            />
          </div>
        </div>
      )}
    </WaitForData>
  )
}
