import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Button from 'components/Button'
import Icons from 'components/Icons'
import Connector from '../Connector'
import NodeComponent from './Node'

export default function PreCheckoutNode({ node, wb, readOnly }) {
  const { selectedNode, setSelectedNode } = wb
  const { name, subject } = node.task.settings
  return (
    <>
      <div
        className={classNames(
          'mx-auto p-3 flex rounded-[10px] border shadow-card w-[400px] bg-white gap-2.5  min-h-[92px]',
          {
            'bg-white border-gray-300': selectedNode?.id !== node.id,
            'bg-card-selected border-primary': selectedNode?.id === node.id,
            'cursor-pointer': !readOnly,
            'pointer-events-none': readOnly,
          }
        )}
        onClick={() => {
          if (!readOnly) {
            setSelectedNode(node)
          }
        }}
      >
        <div>
          <div className="rounded-full p-2.5 bg-gray-50 text-primary">
            <Icons.PreCheckout className="w-5 h-5" />
          </div>
        </div>
        <div className="flex gap-2.5 justify-between flex-1 items-center">
          <div className="flex flex-col gap-1">
            <p className="text-sm font-medium">{name || 'Pre-Checkout'}</p>
            <p className="text-xs text-gray-400 max-h-[36px] overflow-hidden">
              <strong className="text-black">Subject:</strong>{' '}
              {subject ||
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do'}
            </p>
          </div>
        </div>
      </div>
      <div>
        <Connector
          position="center"
          edge={node.center}
          previousNode={node}
          wb={wb}
        />
        <NodeComponent node={node.center?.node} wb={wb} />
      </div>
    </>
  )
}
