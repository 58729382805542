import { useReducer } from 'react'

export default function usePagination() {
  const [state, dispatch] = useReducer(reducer, DEFAULT_PAGINATION_DATA)

  return {
    ...state,
    query: { size: state.size, offset: state.offset },
    canMoveToPreviousPage: state.offset !== 0,
    canMoveToNextPage: state.total
      ? state.size + state.offset < state.total
      : true,
    pageSizeOptions: PAGE_SIZE_OPTIONS,

    setTotal: (v) => dispatch({ type: 'SET_TOTAL', value: v }),
    setSize: (v) => dispatch({ type: 'SET_SIZE', value: v }),
    setOffset: (v) => dispatch({ type: 'SET_OFFSET', value: v }),
    nextPage: () => dispatch({ type: 'NEXT_PAGE' }),
    prevPage: () => dispatch({ type: 'PREVIOUS_PAGE' }),
  }
}

function reducer(state = DEFAULT_PAGINATION_DATA, action) {
  switch (action.type) {
    case 'RESET':
      return DEFAULT_PAGINATION_DATA

    case 'NEXT_PAGE':
      return { ...state, offset: state.offset + state.size }

    case 'PREVIOUS_PAGE':
      return {
        ...state,
        offset: Math.max(state.offset - state.size, 0),
      }

    case 'SET_SIZE':
      return mergeIfDifferent(state, 'size', action.value)

    case 'SET_OFFSET':
      return mergeIfDifferent(state, 'offset', action.value)

    case 'SET_TOTAL':
      return mergeIfDifferent(state, 'total', action.value)

    default:
      throw new Error('Pagination:InvalidAction')
  }
}

function mergeIfDifferent(object, key, value) {
  return object[key] !== value ? { ...object, [key]: value } : object
}

const DEFAULT_PAGINATION_DATA = {
  size: 25,
  offset: 0,
  total: null,
}

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
]
