import React, { useEffect, useReducer } from 'react'
import api from 'api'
import classNames from 'classnames'

import { invalidate } from 'utils/query'
import Discounts from 'components/Discounts'
import Segmentation from 'components/Segmentation'
import Icons from 'components/Icons'
import * as WIDGETS from 'constants/widgets'
import { _find } from 'utils/lodash'

function configReducer(conf, action) {
  const newState = { ...conf }
  switch (action) {
    case 'automaticEnabled':
      newState.automaticEnabled = !conf.automaticEnabled
      break
    case 'enableRandom':
      newState.enableRandom = !conf.enableRandom
      break
    case 'allowIfUnavailable':
      newState.allowIfUnavailable = !conf.allowIfUnavailable
      break
    default:
      return newState
  }
  return newState
}

export default function Configure({ builder }) {
  const {
    widget,
    account,
    configuration,
    setWidget,
    shop,
    openToast,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
    localWidget,
    setLocalWidget,
  } = builder

  const [configState, dispatchConfig] = useReducer(configReducer, {
    automaticEnabled: widget.automaticEnabled,
    enableRandom: widget.enableRandom,
    allowIfUnavailable: widget.allowIfUnavailable,
  })

  const { supportedSettings } = _find(Object.values(WIDGETS), {
    value: widget.type,
  })

  useEffect(() => {
    for (let key in configState) {
      configuration[key] = configState[key]
    }
  }, [configState, configuration])

  useEffect(() => {
    async function updateConf() {
      try {
        const { id } = widget
        const { discountConfig } = configuration
        delete configuration.discountConfig
        const request = { id, ...configuration, enabled: false }
        if (discountConfig?.enabled) {
          request.discountConfig = discountConfig
        }
        const { data } = await api.put(`widgets/${id}/`, request, {
          params: { shop },
        })
        setWidget(data)
        localWidget.automaticEnabled = configuration.automaticEnabled
        openToast({
          type: 'success',
          text: 'Widget updated successfully!',
        })
        invalidate(['pages', shop])
        return data
      } catch (e) {
        openToast({
          type: 'error',
          text: 'There was an error in updating the widget',
        })
      }
    }

    setSaveHandler(() => async () => {
      await updateConf()
    })
    setSaveNextHandler(() => async () => {
      const data = await updateConf()
      if (data) {
        setActiveScreen('review')
      }
    })
  }, [configuration])

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-col w-full px-16 py-10">
        <div className="w-full">
          <h1 className=" text-lg font-semibold">Configurations</h1>
          <form className="grid gap-4 mt-5 grid-cols-3 lg:grid-cols-4 grid-rows-1">
            {SWITCHES.map((elem) => (
              <>
                {supportedSettings.includes(elem.name) && (
                  <ConfigurationCard
                    {...elem}
                    conf={configState}
                    toggleConf={() => {
                      dispatchConfig(elem.name)
                    }}
                  />
                )}
              </>
            ))}
          </form>
        </div>
      </div>
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className="flex flex-col w-full px-10 py-5">
        <Segmentation
          account={account}
          widget={widget}
          conf={configuration}
          onChange={(data) => {
            configuration['segment'] = data
          }}
          mode="builder"
          headingClassName="text-lg font-semibold capitalize"
        />
      </div>
      <div className="w-full h-0.5 bg-gray-200"></div>
      <div className="flex flex-col w-full px-10 py-10">
        <Discounts
          account={account}
          widget={widget}
          onChange={(data) => {
            configuration['discountConfig'] = data
          }}
          mode="builder"
          headingClassName="text-lg font-semibold capitalize"
        />
      </div>
    </div>
  )
}

const ConfigurationCard = ({ label, description, name, conf, toggleConf }) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-start justify-between p-4 border border-gray-400 rounded shadow-sm w-full cursor-pointer hover:border-primary focus:border-primary-400 group'
      )}
      onClick={() => {
        toggleConf()
      }}
    >
      <div className="flex flex-col items-center justify-start h-full">
        <div className="flex flex-row items-center justify-start w-full">
          <span className="text-sm font-semibold">{label}</span>
        </div>
        <span className="mt-2 text-xs text-gray-600 text-start w-full">
          {description}
        </span>
      </div>
      <div className="flex flex-col items-center justify-between ml-4">
        <span
          className={classNames(
            'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:border-primary-400 group-hover:border',
            {
              'bg-primary': conf[name],
            }
          )}
        >
          <Icons.Tick className="w-3 text-white stroke-current" />
        </span>
      </div>
    </div>
  )
}

const SWITCHES = [
  {
    name: 'automaticEnabled',
    label: 'Automatic Recommendation',
    description:
      "Check this option to show recommendations based on your store's sales data. We use our proprietary algorithms to generate these.",
  },
  {
    name: 'enableRandom',
    label: 'Random Recommendation',
    description:
      'Check this option to fallback to randomized product recommendations incase automatic/manual recommendations are not found.',
  },
  {
    name: 'allowIfUnavailable',
    label: 'Show Soldout items as Recommendations',
    description:
      'Products that are unavailable will also be shown as recommendations if enabled.',
  },
]
