import React from 'react'
import classNames from 'classnames'

import Button from 'components/Button'
import Icons from 'components/Icons'

export default function Step({ info, index, onboarding }) {
  const { onboardingState, onboardingSteps } = onboarding
  const currStageIndex = onboardingSteps.findIndex(
    (step) => step.value === onboardingState.stage
  )
  const stepIndex = onboardingSteps.findIndex(
    (step) => step.value === info.value
  )
  const completed = currStageIndex > stepIndex || onboardingState.completed
  const inProgress = onboardingState.stage === info.value
  const clickHandler = onboardingSteps[stepIndex].clickHandler
  const isLast = index === onboarding.onboardingSteps.length

  return (
    <div className="overflow-hidden">
      <div
        className={classNames('border border-gray-200', {
          'hover:bg-gray-100 cursor-pointer': inProgress || completed,
          'border-b-0': !isLast,
        })}
        onClick={() => {
          if (inProgress || completed) {
            clickHandler()
          }
        }}
      >
        <div
          className="flex flex-row items-center justify-between px-6 py-3 overflow-y-hidden"
          style={{ margin: '-1px' }}
        >
          <div className="flex flex-row items-center">
            <div className="relative">
              {index !== 1 && (
                <div
                  className={classNames(
                    {
                      'bg-gray-400': !completed && !inProgress,
                      'bg-primary': completed || inProgress,
                    },
                    'h-16 absolute'
                  )}
                  style={{
                    width: '2px',
                    left: '50%',
                    bottom: '2rem',
                  }}
                ></div>
              )}

              <Icons.CircleTickThin
                className={classNames(
                  'z-10',
                  { 'text-primary': completed },
                  {
                    'text-gray-300': !completed,
                  }
                )}
                style={{ width: '2.125rem' }}
              />

              {!isLast && (
                <div
                  className={classNames(
                    { 'bg-gray-400': !completed, 'bg-primary': completed },
                    'h-16 absolute'
                  )}
                  style={{
                    width: '2px',
                    left: '50%',
                    // bottom: '-4rem',
                  }}
                ></div>
              )}
            </div>
            <div className="flex flex-col items-start justify-center pl-5">
              <span
                className={classNames(
                  { 'text-gray-600': !completed && !inProgress },
                  'font-semibold'
                )}
              >
                <span
                  className={classNames({
                    'text-primary': completed,
                  })}
                >
                  Step {index} :{' '}
                </span>{' '}
                {info.heading}
              </span>
              <span className="pr-2 text-xs font-medium text-gray-600">
                {info.description}
              </span>
            </div>
          </div>
          {completed ? (
            <Icons.ChevronRight
              className="text-primary cursor-pointer"
              style={{ width: '2rem' }}
            />
          ) : (
            info.action &&
            inProgress && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  clickHandler()
                }}
              >
                {info.action}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  )
}
