import React from 'react'
import { useQuery } from 'react-query'

import { fetchPages } from 'api/pages'
import Box from 'components/Box'
import Button from 'components/Button'
import Text from 'components/Text'
import Tooltip from 'components/Tooltip'
import WaitForData from 'components/WaitForData'
import AppEmbedCard from 'components/AppEmbedCard'
import ReviewModal from 'components/ReviewModal'
import Welcome from 'components/Welcome'
import { useOpenModal } from 'components/Modal'
import { useApi } from 'hooks/api'
import * as QueryUtils from 'utils/query'

import AnalyticsCards from './AnalyticsCards'
import EnableFastLoadModal from './EnableFastLoadModal'
import useOnboarding from './hooks/useOnboarding'
import ExperienceList from './ExperienceList'
import RecommendedApps from './RecommendedApps'

export default function Dashboard({ account, store }) {
  const {
    data: { name: shop, isRkSupport },
  } = account
  const pagesQuery = useQuery(['pages', shop], fetchPages, {
    staleTime: 20 * 60 * 1000,
  })
  const experiences = useApi('experiences/', { shop })
  const billing = useApi('account/', { shop })
  const analytics = useApi('analytics/stats/', { shop })

  return (
    <WaitForData
      objects={[pagesQuery, billing, analytics, experiences]}
      loadingMessage="Loading dashboard data"
      errorMessage="Trouble fetching account details..."
    >
      <DashboardScreen
        pages={{ data: QueryUtils.data(pagesQuery) }}
        account={account}
        billing={billing}
        experiences={experiences}
        analytics={analytics}
        store={store}
      />
    </WaitForData>
  )
}

function DashboardScreen({
  account,
  store,
  pages,
  billing,
  analytics,
  experiences,
}) {
  const {
    data: { name: shop, isRkSupport },
  } = account
  const {
    data: {
      onboarding: { completed: onboardingCompleted },
    },
  } = store
  const openModal = useOpenModal()
  const onboarding = useOnboarding({
    account,
    pages,
    billing,
    store,
    experiences,
  })

  return (
    <div className="flex flex-col items-stretch flex-1">
      {onboardingCompleted && (
        <AppEmbedCard
          className="mb-6"
          account={account}
          onEnabled={() => {}}
          checkStorefront={true}
        />
      )}
      <Text.ScreenHeading>Dashboard</Text.ScreenHeading>
      {!onboardingCompleted && (
        <Welcome account={account} onboarding={onboarding} />
      )}

      <div className="flex flex-col items-stretch lg:items-start lg:flex-row">
        <div className="flex flex-col w-full mr-3 lg:w-2/3 ">
          <ExperienceList
            experiences={experiences}
            pages={pages}
            billing={billing}
            account={account}
          />
        </div>
        <div className="flex flex-col w-full lg:ml-3 lg:w-1/3 ">
          <div className="flex flex-col flex-1 xl:flex-row">
            {isRkSupport && account.data.fastLoadState === 'enabled' && (
              <div className="flex-1 mb-6">
                <Tooltip
                  position="right"
                  triggerClassName="w-full"
                  content={
                    account.data.fastLoadState === 'enabled'
                      ? 'Disable fast load'
                      : 'Widgets are slow to load on your site? Enable fast load without any coding required'
                  }
                >
                  <Button
                    className="w-full"
                    variant="bordered"
                    onClick={() =>
                      openModal(
                        <EnableFastLoadModal
                          billing={billing}
                          account={account}
                        />
                      )
                    }
                  >
                    {`${
                      account.data.fastLoadState === 'enabled'
                        ? 'Disable'
                        : 'Enable'
                    } Fast Load`}
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
          <AnalyticsCards
            shop={shop}
            analytics={analytics}
            account={account}
            store={store}
          />
          <ReviewModal store={store} account={account} analytics={analytics} />
          {true && <RecommendedApps account={account} />}
        </div>
      </div>
    </div>
  )
}
