import api from 'api'

export function shopifyInitiate(data = {}) {
  return api.post(`shopify/auth/initiate/`, data)
}

export function shopifyCallback(data = {}) {
  return api.post(`shopify/auth/callback/`, data)
}

export function shopifyOnlineCallback(data = {}) {
  return api.post(`shopify/auth/online_callback/`, data)
}
