import React, { useState } from 'react'
import api from 'api'
import classNames from 'classnames'

import Box from 'components/Box'
import Link from 'components/Link'
import Button from 'components/Button'
import Loader from 'components/Loader'
import ResourcePicker from 'components/ResourcePicker'
import RecommendationsList from 'components/SetupRecommendations/RecommendationsList'
import { useQueryParams } from 'hooks/router'
import { useApi, cacheKey } from 'hooks/api'
import { useOpenModal } from 'components/Modal'
import { useToast } from 'components/Toast'
import { stripStringBySeperator } from 'utils/string'
import { renderCurrency } from 'utils/currency'
import Tooltip from 'components/Tooltip'
import { BACKEND_DOMAIN } from 'constants/support'

export default function ProductEdit({
  product,
  widgetType,
  onClose,
  currencyFormat,
  rkSupport,
}) {
  const { shop } = useQueryParams()
  const productRecommendations = useApi(
    `recommendations/${product.productId}/`,
    {
      shop,
      recommendation_type: widgetType,
    }
  )
  const openModal = useOpenModal()
  const openToast = useToast()
  const [showTagsFull, setShowTagsFull] = React.useState(false)
  let manualRecommendations = []
  let automaticRecommendations = []
  if (productRecommendations.data) {
    manualRecommendations = (
      productRecommendations.data.manualRecommendations || []
    ).filter((rec) => rec.recommendationType === widgetType)
    automaticRecommendations = (
      productRecommendations.data.automaticRecommendations || []
    ).filter((rec) => rec.recommendationType === widgetType)
  }
  const [savingRecommendations, setSavingRecommendations] = useState(false)
  async function updateRecommendedProducts(productIds, twoWayEnabled = false) {
    setSavingRecommendations(true)
    try {
      const { data } = await api.post(
        'recommendations/',
        {
          recommendation_type: widgetType,
          product_id: product.productId,
          recommended_product_ids: productIds,
          reset: true,
          two_way_enabled: twoWayEnabled,
        },
        {
          params: { shop },
        }
      )
      productRecommendations.update(
        {
          ...productRecommendations.data,
          manualRecommendations: data.recommendations,
        },
        {
          local: true,
          cacheKey: cacheKey(`recommendations/${product.productId}/`, {
            shop,
            recommendation_type: widgetType,
          }),
        }
      )
      openToast({
        type: 'success',
        text: 'Recommendations updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Unable to update the recommendations. Please contact support.',
      })
    }
    setSavingRecommendations(false)
  }
  return (
    <div className="flex flex-col items-stretch">
      <Box spacing="small" className="flex flex-row">
        <img src={product.image} className="object-cover w-24 h-24 mr-4" />
        <div className="flex flex-col items-stretch flex-1">
          <span className="flex flex-row justify-between mb-2 ">
            <a
              href={product.url}
              target="_blank"
              className="font-semibold leading-snug text md:w-4/5 hover:underline notranslate"
            >
              {product.title}
            </a>
            <span className="text-right md:w-1/5">
              <a
                className="text-sm text-primary underline cursor-pointer"
                href={product.url}
                target="_blank"
              >
                Visit Product →
              </a>
            </span>
          </span>
          <div className="flex flex-row">
            <div className="flex flex-col w-1/2 pr-10">
              <span className="text-sm text-gray-600">
                Product Type:{' '}
                <span className="font-semibold">{product.productType}</span>
              </span>
              <span className="text-sm text-gray-600">
                Tags:{' '}
                {showTagsFull || product.tags.length <= INITIAL_TAG_STR_LEN ? (
                  <span className="font-semibold">{product.tags}</span>
                ) : (
                  <span className="font-semibold">
                    {stripStringBySeperator(
                      product.tags,
                      ',',
                      INITIAL_TAG_STR_LEN
                    )}{' '}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => setShowTagsFull(true)}
                    >
                      {' '}
                      Show More ...
                    </span>
                  </span>
                )}
              </span>
            </div>
            <div className="flex flex-col w-1/2">
              <span className="text-sm text-gray-600">
                Vendor: <span className="font-semibold">{product.vendor}</span>
              </span>
              <span className="text-sm text-gray-600">
                Id:{' '}
                <span
                  className={classNames('font-semibold', {
                    'cursor-pointer underline': rkSupport,
                  })}
                  onClick={
                    rkSupport
                      ? () => {
                          const windowRef = window.open(
                            `${BACKEND_DOMAIN}admin/djshopify/product/${product.productId}/change/`,
                            '_blank'
                          )
                          if (typeof windowRef.focus === 'function') {
                            windowRef.focus()
                          }
                        }
                      : undefined
                  }
                >
                  {product.productId}
                </span>
              </span>
              <span className="text-sm text-gray-600">
                Handle: <span className="font-semibold">{product.handle}</span>
              </span>
              <span className="text-sm text-gray-600">
                Price:{' '}
                <span
                  className="font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: renderCurrency(product.price, currencyFormat),
                  }}
                ></span>
              </span>
            </div>
          </div>
        </div>
      </Box>
      <div className="flex flex-row items-center mt-1 mb-6">
        <span
          className="text-sm text-primary underline cursor-pointer"
          onClick={onClose}
        >
          ← Choose another product
        </span>
      </div>
      <div className="flex flex-col items-stretch xl:flex-row xl:items-start">
        <Box className="flex flex-col items-stretch flex-1 mb-6 xl:mr-3 xl:mb-0">
          <div className="flex flex-row justify-between mb-4">
            <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
              <span className="mr-1">Manual Recommendations</span>
              <Tooltip
                position="top"
                content="Manual recommendations are given preference over automatic recommendations."
                showHelpIcon={true}
                helpIconVariant="dark"
              />
            </span>
            <Button
              size="xSmall"
              loading={savingRecommendations}
              onClick={() =>
                openModal(
                  <ResourcePicker
                    heading="Choose recommended products"
                    ignoreProductIds={[product.productId]}
                    initialSelectedProductIds={manualRecommendations.map(
                      (rec) => rec.productId
                    )}
                    showTwoWaySyncOption={true}
                    onSelect={(selectedProductIds, twoWayEnabled) =>
                      updateRecommendedProducts(
                        selectedProductIds.map((id) => id.toString()),
                        twoWayEnabled
                      )
                    }
                  />
                )
              }
            >
              + Add Recommendations
            </Button>
          </div>
          <div className="flex">
            {productRecommendations.loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                {manualRecommendations.length === 0 && (
                  <span className="text-sm text-gray-600">
                    You can add manual recommendations for this product using
                    "Add Recommendation" button above. Once added you can also
                    re-order the recommendations
                  </span>
                )}
                <RecommendationsList
                  recommendations={manualRecommendations}
                  type="manual"
                  allowReorder={true}
                  onReorder={(recommendations) => {
                    updateRecommendedProducts(
                      recommendations
                        .filter(Boolean)
                        .map((rec) => rec.productId.toString())
                    )
                  }}
                  onRemove={(recommendation) =>
                    updateRecommendedProducts(
                      manualRecommendations
                        .map((rec) => rec.productId)
                        .filter(
                          (productId) => productId !== recommendation.productId
                        )
                        .map((id) => id.toString())
                    )
                  }
                  currencyFormat={currencyFormat}
                  rkSupport={rkSupport}
                />
              </React.Fragment>
            )}
          </div>
        </Box>
        <Box className="flex flex-col items-stretch flex-1 xl:ml-3">
          <div className="mb-4 text-xl font-semibold text-gray-800">
            <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
              <span className="mr-1">Automatic Recommendations</span>

              <Tooltip
                position="top"
                content="These are items that have historically been purchased together with this product you are examining."
                showHelpIcon={true}
                helpIconVariant="dark"
              />
            </span>
          </div>
          <div className="flex">
            {productRecommendations.loading ? (
              <Loader />
            ) : (
              <RecommendationsList
                recommendations={automaticRecommendations}
                type="automatic"
                allowReorder={false}
                currencyFormat={currencyFormat}
                rkSupport={rkSupport}
              />
            )}
          </div>
        </Box>
      </div>
    </div>
  )
}

const INITIAL_TAG_STR_LEN = 50
