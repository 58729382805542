import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import numeral from 'numeral'

import Tooltip from 'components/Tooltip'
import Onboarding from 'components/Onboarding'
import Loader from 'components/Loader'
import Button from 'components/Button'
import { useApi } from 'hooks/api'
import { useTrackView } from 'hooks/analytics'
import * as Tracker from 'utils/tracker'
import { openChat } from 'utils/external'
import { trackEvent } from 'utils/analytics'
import { isRkSupport } from 'utils/account'

export default function OnboardingPricing({
  onboardingState,
  account,
  billing,
}) {
  const {
    stages: { next: nextStage, previous: previousStage },
    updateOnboarding,
  } = onboardingState
  const { name: shop } = account.data

  const [showPricing, setShowPricing] = React.useState(false)
  const { data: pricingPlans } = useApi('account/plans/', { shop })
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    Tracker.record('view:onboarding:pricing')
  }, [])

  useTrackView({
    category: 'Onboarding',
    action: 'Load',
    label: 'Pricing',
  })

  const trialPricingPlan = pricingPlans && pricingPlans[0]
  const shopSkipButton = true

  return (
    <React.Fragment>
      <Onboarding.Box>
        <Onboarding.BoxHeading>Start Free Trial</Onboarding.BoxHeading>
        <div className="flex flex-row items-start -mx-2">
          {shopSkipButton && <NewPlan shop={shop} />}
          {trialPricingPlan && (
            <NewPlan
              plan={trialPricingPlan}
              shop={shop}
              updateOnboarding={updateOnboarding}
              onlyPlan={!shopSkipButton}
            />
          )}
        </div>
        <div className="flex flex-col items-stretch pt-3 mt-6 border-t border-gray-400">
          <div className="flex flex-col items-center self-center w-1/2">
            <span>
              Need advanced enterprise features? We support{' '}
              <strong>Custom Templates</strong>,{' '}
              <strong>User Segmentation</strong> &{' '}
              <strong>Custom Recommendation Models</strong>
            </span>
            <span className="mt-3">
              <Button size="small" onClick={() => openChat()}>
                Chat with us
              </Button>
              <a
                href="https://calendly.com/loopclub/loopclub-intro-call"
                target="_blank"
                className="relative px-3 py-1 ml-3 text-sm font-medium text-white border rounded shadow text-primary border-primary-600 focus:outline-none focus:shadow-outline hover:border-primary-600 hover:bg-gray-100"
              >
                Book a Full Demo
              </a>
            </span>
          </div>
        </div>
      </Onboarding.Box>
      <Onboarding.ActionTray>
        <span
          className="mr-5 text-sm font-semibold text-gray-600 underline cursor-pointer"
          onClick={() => {
            if (showPricing) {
              setShowPricing(false)
              Tracker.record('click:onboarding:close_plans')
            } else {
              updateOnboarding({
                stage: previousStage,
              })
            }
          }}
        >
          Back
        </span>
        {shopSkipButton && (
          <span
            className="mr-5 text-sm font-semibold text-gray-600 underline cursor-pointer"
            onClick={async () => {
              if (loading) {
                return
              }

              Tracker.record('click:onboarding:go_to_dashboard')
              trackEvent({
                category: 'Onboarding',
                action: 'Skip Pricing',
                label: 'Pricing',
              })
              setLoading(true)
              await Promise.all([
                Tracker.flush(),
                updateOnboarding({
                  completed: true,
                  stage: nextStage,
                }),
              ])
              setLoading(false)

              if (account.data.newAppEnabled) {
                history.replace({
                  pathname: '/',
                  search: window.location.search,
                })
              } else {
                window.location.href = `${window.location.origin}/app/${window.location.search}`
              }
            }}
          >
            {loading ? <Loader /> : 'Skip'}
          </span>
        )}
      </Onboarding.ActionTray>
    </React.Fragment>
  )
}

function NewPlan({ plan, shop, updateOnboarding, onlyPlan = false }) {
  const isFree = !Boolean(plan)
  plan = plan || {
    name: 'Free',
    price: 0,
    maximumRecommendationsServed: 1000,
  }

  const planFeatures = [
    {
      value: 'support',
      label: (isFree) => (isFree ? 'Email Support' : 'Live Chat Support'),
    },
    {
      value: 'basic-recommendations',
      label: (isFree) =>
        isFree ? 'Basic Recommendations' : '1-1 Manual Recommendations',
    },
    {
      value: 'usage',
      label: 'No Service Cut',
      paid: true,
    },
    {
      value: 'discounts',
      label: 'Bundle discounts',
      paid: true,
    },
    {
      value: 'reviews',
      label: 'Product Ratings in Widgets',
      paid: true,
    },
    // {
    //   value: 'ajax-cart',
    //   label: 'AJAX Cart Recommendations',
    //   paid: true,
    // },
  ]

  return (
    <div
      className={classNames(
        'relative flex flex-col items-center w-1/2 mx-2 bg-white border rounded',
        {
          'w-1/2': !onlyPlan,
          'w-full': onlyPlan,
        }
      )}
      style={{ opacity: isFree ? 0.75 : 1 }}
    >
      <div className="flex flex-col items-center justify-center w-full py-2 pt-6">
        <h2 className="mb-2 text-sm font-semibold uppercase">{plan.name}</h2>
        <div className="flex items-baseline">
          <span className="self-center mr-1 text-gray-800">$</span>
          <span className="text-4xl font-semibold leading-none">
            {plan.price}
          </span>
          <span className="text-gray-800">/mo</span>
        </div>
        {!isFree && (
          <span
            className="px-6 py-1 font-bold text-white capitalize bg-green-600 rounded-sm"
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }}
          >
            {plan.trialDays} day free trial
          </span>
        )}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 bg-gray-300">
        <span className="mr-1 text-2xl font-semibold">
          {numeral(plan.maximumRecommendationsServed).format('0,0')}
        </span>
        <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
          <span className="mr-2 text-gray-800">Widget Serves</span>
          <Tooltip
            position="top"
            content="Widget Serves is the number of times widgets are served to a visitor. It is directly proportional to number of widgets on a page and page views."
            showHelpIcon={true}
            helpIconVariant="dark"
          />
        </span>
        {isFree ? (
          <p
            className="w-4/5 mt-2 font-semibold text-center text-gray-800"
            style={{ fontSize: 10 }}
          >
            *Once usage exceeds the free quota, widgets will get disabled on
            your store.
          </p>
        ) : (
          <p
            className="mt-2 text-center text-gray-800"
            style={{ fontSize: 10 }}
          >
            *Once usage exceeds plan quota, you will be charged{' '}
            <strong>
              ${plan.recommendationServesBatchPrice} per{' '}
              {numeral(plan.recommendationServesBatchSize).format('0,0')}{' '}
            </strong>{' '}
            widget serves. This additional charge is capped at{' '}
            <strong>${plan.maxPlanUsageCharge}/mo. </strong>
            {false && (
              <React.Fragment>
                Maximum widget serve threshold{' '}
                <strong>
                  {numeral(plan.recommendationsServedHardLimit).format('0,0')}{' '}
                </strong>{' '}
              </React.Fragment>
            )}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center justify-center w-full px-6 py-6 font-semibold">
        <span className="mb-3 text-sm text-gray-800 uppercase">Features</span>
        {planFeatures.map((feature) => {
          const isEnabled = feature.paid ? !isFree : true
          return (
            <span
              className={classNames(
                'mb-1 pb-1 border-b border-gray-200 font-medium capitalize',
                {
                  ['line-through']: !isEnabled,
                }
              )}
              key={feature.value}
            >
              {isEnabled ? '' : '❌'}{' '}
              {typeof feature.label === 'function'
                ? feature.label(isFree)
                : feature.label}
            </span>
          )
        })}
      </div>
      <Button
        className="w-full py-3 mt-3 rounded-t-none"
        color="green"
        onClick={() => {
          updateOnboarding({
            completed: true,
          })
          trackEvent({
            category: 'Onboarding',
            action: 'Start Trial',
            label: 'Pricing',
            value: plan.price,
          })
          window.top.location.href = `${plan.chargeUrl}&shop=${shop}`
        }}
        disabled={isFree}
      >
        {isFree ? 'Your Current Plan' : `Start Free Trial`}
      </Button>
    </div>
  )
}
