export const CURRENCY_FORMAT_DEFAULT = '$ {{ amount }}'
export const MONEY_TAGS = {
  USD: '${{amount}}',
  EUR: '€{{amount}}',
  GBP: '£{{amount}}',
  CAD: '${{amount}}',
  ALL: 'Lek {{amount}}',
  DZD: 'DA {{amount}}',
  AOA: 'Kz{{amount}}',
  ARS: '${{amount_with_comma_separator}}',
  AMD: '{{amount}} AMD',
  AWG: 'Afl{{amount}}',
  AUD: '${{amount}}',
  BBD: '${{amount}}',
  AZN: 'm.{{amount}}',
  BDT: 'Tk {{amount}}',
  BSD: 'BS${{amount}}',
  BHD: '{{amount}}0 BD',
  BYR: 'Br {{amount}}',
  BZD: 'BZ${{amount}}',
  BTN: 'Nu {{amount}}',
  BAM: 'KM {{amount_with_comma_separator}}',
  BRL: 'R$ {{amount_with_comma_separator}}',
  BOB: 'Bs{{amount_with_comma_separator}}',
  BWP: 'P{{amount}}',
  BND: '${{amount}}',
  BGN: '{{amount}} лв',
  MMK: 'K{{amount}}',
  KHR: 'KHR{{amount}}',
  KYD: '${{amount}}',
  XAF: 'FCFA{{amount}}',
  CLP: '${{amount_no_decimals}}',
  CNY: '&#165;{{amount}}',
  COP: '${{amount_with_comma_separator}}',
  CRC: '&#8353; {{amount_with_comma_separator}}',
  HRK: '{{amount_with_comma_separator}} kn',
  CZK: '{{amount_with_comma_separator}} K&#269;',
  DKK: '{{amount_with_comma_separator}}',
  DOP: 'RD$ {{amount}}',
  XCD: '${{amount}}',
  EGP: 'LE {{amount}}',
  ETB: 'Br{{amount}}',
  XPF: '{{amount_no_decimals_with_comma_separator}} XPF',
  FJD: '${{amount}}',
  GMD: 'D {{amount}}',
  GHS: 'GH&#8373;{{amount}}',
  GTQ: 'Q{{amount}}',
  GYD: 'G${{amount}}',
  GEL: '{{amount}} GEL',
  HNL: 'L {{amount}}',
  HKD: '${{amount}}',
  HUF: '{{amount_no_decimals_with_comma_separator}}',
  ISK: '{{amount_no_decimals}} kr',
  INR: 'Rs. {{amount}}',
  IDR: '{{amount_with_comma_separator}}',
  ILS: '{{amount}} NIS',
  JMD: '${{amount}}',
  JPY: '¥{{amount_no_decimals}}',
  JEP: '£{{amount}}',
  JOD: '{{amount}}0 JD',
  KZT: '{{amount}} KZT',
  KES: 'KSh{{amount}}',
  KWD: '{{amount}}0 KD',
  KGS: 'лв{{amount}}',
  LVL: 'Ls {{amount}}',
  LBP: 'ل.ل.‎{{amount}}',
  LTL: '{{amount}} Lt',
  MGA: 'Ar {{amount}}',
  MKD: 'ден {{amount}}',
  MOP: 'MOP${{amount}}',
  MVR: 'Rf{{amount}}',
  MXN: '$ {{amount}}',
  MYR: 'RM{{amount}} MYR',
  MUR: 'Rs {{amount}}',
  MDL: '{{amount}} MDL',
  MAD: '{{amount}} dh',
  MNT: '{{amount_no_decimals}} &#8366',
  MZN: '{{amount}} Mt',
  NAD: 'N${{amount}}',
  NPR: 'Rs{{amount}}',
  ANG: 'NAf{{amount}}',
  NZD: '${{amount}}',
  NIO: 'C${{amount}}',
  NGN: '₦{{amount}}',
  NOK: 'kr {{amount_with_comma_separator}}',
  OMR: '{{amount_with_comma_separator}} OMR',
  PKR: 'Rs.{{amount}}',
  PGK: 'K {{amount}}',
  PYG: 'Gs. {{amount_no_decimals_with_comma_separator}}',
  PEN: 'S/. {{amount}}',
  PHP: '₱{{amount}}',
  PLN: '{{amount_with_comma_separator}} zl',
  QAR: 'QAR {{amount_with_comma_separator}}',
  RON: '{{amount_with_comma_separator}} lei',
  RUB: '&#1088;&#1091;&#1073;{{amount_with_comma_separator}}',
  RWF: '{{amount_no_decimals}} RF',
  WST: 'WS$ {{amount}}',
  SAR: '{{amount}} SR',
  STD: 'Db {{amount}}',
  RSD: '{{amount}} RSD',
  SCR: 'Rs {{amount}}',
  SGD: '${{amount}}',
  SYP: '£S{{amount}}',
  ZAR: 'R {{amount}}',
  KRW: '₩{{amount_no_decimals}}',
  LKR: 'Rs {{amount}}',
  SEK: '{{amount_no_decimals}} kr',
  CHF: 'SFr. {{amount}}',
  TWD: '${{amount}}',
  THB: '{{amount}} &#xe3f;',
  TZS: '{{amount}} TZS',
  TTD: '${{amount}}',
  TND: '{{amount}}',
  TRY: '{{amount}}TL',
  UGX: 'Ush {{amount_no_decimals}}',
  UAH: '₴{{amount}}',
  AED: 'Dhs. {{amount}}',
  UYU: '${{amount_with_comma_separator}}',
  VUV: '${{amount}}',
  VEF: 'Bs. {{amount_with_comma_separator}}',
  VND: '{{amount_no_decimals_with_comma_separator}}₫',
  XBT: '{{amount_no_decimals}} BTC',
  XOF: 'CFA{{amount}}',
  ZMW: 'K{{amount_no_decimals_with_comma_separator}}',
}
