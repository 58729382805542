import React from 'react'

import Header from './Header'
import Banner from './Banner'
import Thresholds from './Thresholds'
import Shipping from './Shipping'
import Recommendations from './Recommendations'
import LineItems from './LineItems'
import Pricing from './Pricing'
import Actions from './Actions'

export default function Preview({ layout, accountData }) {
  const {
    data: {
      settings: {
        general: { backgroundColor },
      },
    },
    activeGroup,
  } = layout
  return (
    <div className="flex flex-col items-center w-full scale-[.80] origin-[50%_15%] -mb-32">
      <div
        className="w-3/4 max-w-md bg-white shadow"
        style={{ backgroundColor }}
      >
        {PREVIEW_COMPONENTS.map((cartEl) => {
          const isActive = cartEl.isActive(activeGroup)
          return (
            <div
              key={cartEl.value}
              style={
                isActive
                  ? {
                      backgroundColor,
                      transform: 'scale(1.3)',
                      border: '1px dashed',
                    }
                  : {}
              }
              className="transition-transform duration-150"
            >
              <cartEl.Component
                settings={layout.data.settings}
                layout={layout}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const PREVIEW_COMPONENTS = [
  {
    value: 'header',
    isActive: (activeGroup) => false,
    Component: Header,
  },
  {
    value: 'banner',
    isActive: (activeGroup) => activeGroup === 'banner',
    Component: Banner,
  },
  {
    value: 'thresholds',
    isActive: (activeGroup) => activeGroup === 'thresholds',
    Component: Thresholds,
  },
  {
    value: 'shipping',
    isActive: (activeGroup) => activeGroup === 'shipping',
    Component: Shipping,
  },
  {
    value: 'lineItems',
    isActive: (activeGroup) => false,
    Component: LineItems,
  },
  {
    value: 'recommendation',
    isActive: (activeGroup) => activeGroup === 'recommendation',
    Component: Recommendations,
  },
  {
    value: 'pricing',
    isActive: (activeGroup) => activeGroup === 'pricing',
    Component: Pricing,
  },
  {
    value: 'actions',
    isActive: (activeGroup) => activeGroup === 'actions',
    Component: Actions,
  },
]
