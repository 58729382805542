import React from 'react'
import _uniqBy from 'lodash.uniqby'
import classNames from 'classnames'
import Button from 'components/Button'
import RuleSection from 'components/SetupRecommendations/ProductRecommendations/RecommendationRules/RuleSection'
import {
  ruleDestroy as ruleDestroyApi,
  ruleUpdate as ruleUpdateApi,
} from 'api/rules'

import { useToast } from 'components/Toast'

let formKey = 0
function getFormKey() {
  return formKey++
}

export default function RuleCard({ rules, rule, shop, widgetType, widgetId }) {
  const primaryRule = rule.data.rules[0]
  const openToast = useToast()
  const [isSaving, setIsSaving] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)
  const [ruleData, setRuleData] = React.useState({})
  const [ifFormKey, setIfFormKey] = React.useState(getFormKey())
  const [thenFormKey, setThenFormKey] = React.useState(getFormKey())
  const [ruleWidgetId, setRuleWidgetId] = React.useState(rule.widget)

  const [isRemoving, setIsRemoving] = React.useState(false)
  async function onRemove() {
    if (!window.confirm('Are you sure you want to delete this rule?')) {
      return
    }

    setIsRemoving(true)
    try {
      await ruleDestroyApi(rule.id, shop)
      rules.update(
        {
          ...rules.data,
          rules: rules.data.rules.filter((r) => r.id !== rule.id),
        },
        { local: true }
      )

      openToast({
        type: 'success',
        text: 'Rule was successfully deleted! It may take some time for changes to take effect.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error in deleting rule! Please contact support',
      })
    }

    setIsRemoving(false)
  }

  async function onSave() {
    setIsSaving(true)
    try {
      const { data } = await ruleUpdateApi(
        rule.id,
        {
          id: rule.id,
          data: {
            rules: [
              {
                ...rule.data.rules[0],
                ...ruleData,
              },
            ],
          },
          recommendationType: widgetType,
          ruleType: 'list',
          state: 'active',
          ...(ruleWidgetId ? { widget: ruleWidgetId } : { widget: null }),
        },
        shop
      )

      rules.update(
        {
          ...rules.data,
          rules: rules.data.rules.map((r) => {
            if (r.id !== rule.id) {
              return r
            } else {
              return data.rule
            }
          }),
        },
        { local: true }
      )

      openToast({
        type: 'success',
        text: 'Rule was successfully updated! It may take some time for changes to take effect.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error in updating rule! Please contact support',
      })
    }

    setIsSaving(false)
    setIsEditing(false)
  }

  React.useEffect(() => {
    setIfFormKey(getFormKey())
    setThenFormKey(getFormKey())
  }, [rule])

  return (
    <div className="flex flex-col items-stretch p-3 mt-3 bg-white rounded">
      <div className="flex flex-row mb-3">
        <RuleSection
          type="if"
          key={ifFormKey}
          rule={primaryRule}
          extra={rule.extra}
          mode={isEditing ? 'edit' : 'view'}
          disableValuesUpdate={isSaving}
          onUpdate={(newIfData) => {
            setRuleData({
              ...ruleData,
              if: {
                ...ruleData.if,
                ...newIfData,
              },
            })
          }}
        />
        <RuleSection
          key={thenFormKey}
          type="then"
          rule={primaryRule}
          extra={rule.extra}
          mode={isEditing ? 'edit' : 'view'}
          disableValuesUpdate={isSaving}
          onUpdate={(newThenData) => {
            setRuleData({
              ...ruleData,
              then: {
                ...ruleData.then,
                ...newThenData,
              },
            })
          }}
        />
      </div>
      <div className="flex flex-row justify-between">
        {widgetId && (
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                value={Boolean(ruleWidgetId)}
                checked={Boolean(ruleWidgetId)}
                name={`widget-only-rule-${rule.id}`}
                id={`widget-only-rule-${rule.id}`}
                disabled={!isEditing}
                onChange={(e) =>
                  setRuleWidgetId(e.target.checked ? widgetId : null)
                }
              />
              <label
                className={classNames('w-full p-1 text-xs font-semibold', {
                  'opacity-50': !isEditing,
                  'cursor-pointer': isEditing,
                })}
                htmlFor={`widget-only-rule-${rule.id}`}
              >
                Apply rule only to this widget.
              </label>
            </div>
          </div>
        )}
        <div className="flex flex-row items-center ml-auto">
          {isEditing ? (
            <React.Fragment>
              <Button
                size="xSmall"
                variant="bordered"
                color="red"
                onClick={() => {
                  if (
                    !window.confirm(
                      'Are you sure you want to discard your changes?'
                    )
                  ) {
                    return
                  }

                  setIsEditing(false)
                  setRuleData({})
                  setIfFormKey(getFormKey())
                  setThenFormKey(getFormKey())
                }}
                className="opacity-50 hover:opacity-100"
                disabled={isSaving}
              >
                Discard changes
              </Button>
              <Button
                size="xSmall"
                onClick={onSave}
                disabled={isSaving}
                className="ml-3"
              >
                Save changes
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                size="xSmall"
                color="red"
                variant="bordered"
                className="opacity-50 hover:opacity-100"
                onClick={onRemove}
                disabled={isRemoving}
              >
                Delete
              </Button>
              <Button
                size="xSmall"
                className="ml-3"
                onClick={() => setIsEditing(true)}
                disabled={isRemoving}
              >
                Edit
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}
