const WIDGET = {
  label: 'Widget',
  value: 'widget',
}

const BUNDLES_PAGE = {
  label: 'Bundles Page',
  value: 'bundles_page',
}

const BUNDLES_POPUP = {
  label: 'Bundles Popup',
  value: 'bundle_popup',
}

const POPUP = {
  label: 'Popup',
  value: 'popup',
}

const MINI_CART = {
  label: 'Mini Cart',
  value: 'mini_cart',
}

export default {
  WIDGET,
  BUNDLES_PAGE,
  BUNDLES_POPUP,
  POPUP,
  MINI_CART,
}
