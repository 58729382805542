import React from 'react'
import api from 'api'

import FieldValue from 'components/ResourceList/FieldValue'
import Button from 'components/Button'
import Form from 'components/Form'
import { widgetLabelFromType, pageLabelFromType } from 'utils/constants'
import { _get, _find } from 'utils/lodash'

export default function Details({
  experience,
  updateWidgetData,
  account,
  pageOrder,
}) {
  const rowData = getRowData(experience, pageOrder)
  const { name: shop } = account.data
  async function handleWidgetEnabledChange(widgetId, enabled) {
    const { data } = await api.put(
      `widgets/${widgetId}/`,
      { id: widgetId, enabled },
      { params: { shop } }
    )
    return data
  }

  if (rowData.length === 0) {
    return (
      <div className="flex flex-row items-center justify-center w-full h-10">
        <span>There are no widgets assigned to this experience</span>
      </div>
    )
  }

  return (
    <table className="w-full">
      <thead>
        <tr>
          {COLUMNS.map((col) => {
            return (
              <th
                key={col.id}
                className="py-2 text-xs font-medium tracking-wider text-left text-gray-800 uppercase bg-gray-100 border-b border-r border-gray-400 "
              >
                <span className="flex flex-row justify-center w-full">
                  {col.title}
                </span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rowData.map((data) => {
          return (
            <tr key={data.original.id}>
              {COLUMNS.map((col) => {
                return (
                  <td
                    key={col.id}
                    className="px-4 py-3 text-sm font-medium leading-5 text-black border-b border-r border-gray-200 cursor-pointer whitespace-nowrap group-hover:bg-gray-100"
                  >
                    <TableCell
                      value={data[col.id]}
                      original={data.original}
                      type={col.type}
                      id={col.id}
                      isList={col.isList}
                      updateWidgetData={updateWidgetData}
                      onWidgetEnabledChange={handleWidgetEnabledChange}
                    />
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function TableCell({
  id,
  type,
  value,
  original,
  isList = false,
  onWidgetEnabledChange,
  updateWidgetData,
}) {
  switch (id) {
    case 'widgetDetails':
      return (
        <div className="flex flex-col items-start justify-center w-full">
          <span className="mb-2">{original.title}</span>
          <span className="text-xs font-normal text-gray-500">
            {widgetLabelFromType(original.pageType)}
          </span>
        </div>
      )
    case 'pageType':
      return (
        <div className="flex items-center justify-center w-full truncate">
          <FieldValue
            value={pageLabelFromType(value)}
            type={'string'}
            isList={isList}
          />
        </div>
      )
    case 'type':
      return (
        <div className="flex items-center justify-center w-full truncate">
          <FieldValue
            value={widgetLabelFromType(value)}
            type={'string'}
            isList={isList}
          />
        </div>
      )
    case 'status':
      return (
        <div className="flex items-center justify-center w-full -mb-3">
          <Form.Field
            control="switch"
            checked={original.enabled}
            onChange={async (val) => {
              const widgetData = await onWidgetEnabledChange(original.id, val)
              updateWidgetData([widgetData], original.experience)
            }}
          />
        </div>
      )
    case 'configure':
      return (
        <div className="flex items-center justify-center w-full ">
          <Button
            size="xSmall"
            variant="bordered"
            href={`/widgets/${original.id}`}
            internalLink={true}
          >
            Configure
          </Button>
        </div>
      )
    case 'ctr':
      return (
        <div className="flex items-center justify-center w-full truncate">
          <FieldValue
            value={parseFloat(value) * 100}
            type={type}
            isList={isList}
          />
        </div>
      )
    default:
      return (
        <div className="flex items-center justify-center w-full truncate">
          <FieldValue value={value} type={type} isList={isList} />
        </div>
      )
  }
}

const COLUMNS = [
  {
    id: 'widgetDetails',
    title: 'Widget Details',
    accessor: '.',
  },
  {
    id: 'type',
    title: 'Widget Type',
    type: 'string',
  },
  {
    id: 'pageType',
    title: 'Page Type',
    type: 'string',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'ctr',
    accessor: 'ctr',
    title: 'CTR',
    type: 'percentage',
    isStat: true,
  },
  {
    id: 'revenueAttribution',
    title: 'Revenue Attribution',
    type: 'currency',
    accessor: 'revenue_by_rk',
    isStat: true,
  },
  {
    id: 'configure',
    title: '',
    accessor: '.',
  },
]

function getRowData(experience, pageOrder) {
  var data = experience.widgets.reduce((rowData, widget) => {
    var reducedVal = {}
    COLUMNS.forEach((col) => {
      var value
      if (col.accessor === '.') {
        return
      }
      if (col.isStat) {
        value = _find(widget.stats, (stat) => stat.id == col.accessor, '').value
      } else {
        value = _get(widget, col.accessor || col.id, '')
      }
      reducedVal[col.id] = value
    })
    rowData.push({ ...reducedVal, original: widget })
    return rowData
  }, [])

  return pageOrder
    .map((widgetId) =>
      data.find(
        (rowData) =>
          rowData.original.id === widgetId &&
          rowData.original.experience.id === experience.id
      )
    )
    .filter((widget) => Boolean(widget))
}
