export function stripStringBySeperator(str, seperator, length) {
  const strippedString = str.substring(0, length)
  const lastSeperatorIndex = strippedString.lastIndexOf(seperator)
  return lastSeperatorIndex > 0
    ? strippedString.substring(0, lastSeperatorIndex)
    : strippedString
}

export function stringToArray(str, seperator = ',') {
  const spaceRemovedString = str.replace(/ /g, '')
  return spaceRemovedString ? spaceRemovedString.split(seperator) : []
}

export function htmlToText(html) {
  if (!html) {
    return html
  }

  return html.replace(/<[^>]+>/g, '')
}

export function trimString(string, length = 10) {
  if (!string) {
    return string
  }

  let trimmedString = string.substr(0, length)
  trimmedString = string.length > length ? `${trimmedString}...` : trimmedString
  return trimmedString
}
