import api from './index'

export function fetchVisitor({ queryKey }) {
  const [_key, shop, id] = queryKey
  return api.get(`segments/visitors/${id}/?shop=${shop}`).then((response) => {
    return { data: transformRecord(response.data) }
  })
}

export function fetchVisitors({ queryKey }) {
  const [_key, shop, { pagination, order, filters }] = queryKey
  return api
    .post(`segments/visitors/?shop=${shop}`, {
      filters,
      pagination,
      order,
    })
    .then((response) => {
      return {
        data: {
          size: pagination.size,
          offset: pagination.offset,
          data: response.data.data.map(transformRecord),
          total: response.data.count,
        },
      }
    })
}

function transformRecord(visitor) {
  return {
    ...visitor,
    ...(visitor.createdAt ? { createdAt: visitor.createdAt * 1000 } : {}),
    ...(visitor.lastSeenAt ? { lastSeenAt: visitor.lastSeenAt * 1000 } : {}),
    ...(visitor.lastBoughtAt
      ? { lastBoughtAt: visitor.lastBoughtAt * 1000 }
      : {}),
  }
}
