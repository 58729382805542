import React from 'react'
import { format, formatDistance, subDays } from 'date-fns'

export default function Time({
  value,
  distanceThreshold = 2,
  distanceSuffix,
  formatPrefix,
  className = '',
}) {
  if (typeof value === 'number' || typeof value === 'string') {
    value = new Date(value)
  }
  const now = new Date()
  const useDistance =
    subDays(now, distanceThreshold).getTime() <= value.getTime()

  return (
    <span title={value} className={className}>
      {!useDistance && formatPrefix ? `${formatPrefix} ` : ''}
      {useDistance ? formatDistance(value, now) : format(value, 'do LLL, yyyy')}
      {useDistance && distanceSuffix ? ` ${distanceSuffix}` : ''}
    </span>
  )
}
