import React from 'react'
import Box from 'components/Box'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'
import { Select } from 'components/Form/controls'
import _uniq from 'lodash.uniq'
import _get from 'lodash.get'
import * as RankingCriterias from 'constants/widgets/rankingCriteria'

export default function CollectionRecommendations({
  account,
  widget,
  onWidgetSave,
}) {
  const shopDomain = _get(account, 'data.shop.domain', 'yourstore.com')
  const [collections, setCollections] = React.useState(widget.collections || [])
  const [rankingCriteria, setRankingCriteria] = React.useState(
    widget.rankingCriteria || ''
  )
  const [newCollectionHandle, setNewCollectionHandle] = React.useState('')

  function onAddNewCollection() {
    if (!newCollectionHandle) {
      return
    }

    setCollections(_uniq([...collections, newCollectionHandle]))
    setNewCollectionHandle('')
  }

  function onRemoveCollection(collection) {
    setCollections(_uniq(collections.filter((c) => c !== collection)))
  }

  function saveCollections() {
    onWidgetSave({
      ...widget,
      collections,
    })
  }

  function saveRankingCriteria() {
    onWidgetSave({
      rankingCriteria,
    })
  }

  return (
    <div className="flex flex-col items-stretch xl:flex-row xl:items-start">
      <Box className="flex flex-col items-stretch flex-1 mb-6 xl:mr-3 xl:mb-0">
        <span className="flex flex-row items-center text-xl font-semibold text-gray-800 pb-1 border-b border-gray-200">
          <span className="mr-1">Add Collections To Recommend</span>
          <Tooltip
            position="top"
            content="The recommendations will be picked from all the collections you add below."
            showHelpIcon={true}
            helpIconVariant="dark"
          />
        </span>
        <div className="mt-6 flex flex-col items-stretch">
          <div className="flex flex-row items-start flex-1">
            <div className="flex flex-col items-stretch flex-1 mr-3">
              <input
                type="text"
                className="block w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline mr-3"
                value={newCollectionHandle}
                onChange={(e) => setNewCollectionHandle(e.target.value)}
                placeholder="Enter collection handle..."
              />
            </div>
            <Button
              size="small"
              className="ml-auto flex-shrink-0"
              disabled={!newCollectionHandle}
              onClick={onAddNewCollection}
            >
              + Add
            </Button>
          </div>
          <span className="text-xs text-gray-600 mt-2 leading-snug">
            If collection link is{' '}
            <em>
              {shopDomain}/collections/<strong>my-collection</strong>
            </em>
            . Then add <strong>my-collection</strong> in input above.
          </span>
        </div>
        <div className="my-3 flex flex-row flex-wrap flex-1 px-1 pb-1">
          {collections.map((value) => (
            <Collection
              key={value}
              value={value}
              onRemove={onRemoveCollection}
            />
          ))}
        </div>
        <Button onClick={saveCollections} className="self-end" size="small">
          Save
        </Button>
      </Box>
      {false && (
        <Box className="flex flex-col items-stretch flex-1 xl:ml-3">
          <span className="flex flex-row items-center text-xl font-semibold text-gray-800">
            <span className="mr-1">Set Ranking Criteria</span>
            <Tooltip
              position="top"
              content="Choose how the recommendations should be ordered when being displayed to a user."
              showHelpIcon={true}
              helpIconVariant="dark"
            />
          </span>
          <div className="mt-6 mb-3 flex flex-col items-stretch flex-1">
            <Select
              options={Object.values(RankingCriterias)}
              name="rankingCriteria"
              onChange={(e) => {
                if (e.target.value === RankingCriterias.NONE.label) {
                  setRankingCriteria(null)
                } else {
                  setRankingCriteria(e.target.value)
                }
              }}
              value={rankingCriteria}
            />
          </div>
          <Button
            onClick={saveRankingCriteria}
            className="self-end"
            size="small"
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  )
}

function Collection({ value, onRemove }) {
  return (
    <span className="flex flex-row items-center px-2 py-1 mt-1 mr-1 text-sm text-white bg-gray-600 rounded">
      <span>{value}</span>
      <span
        className="ml-1 font-semibold cursor-pointer hover:bg-gray-800"
        onClick={() => onRemove(value)}
      >
        ⓧ
      </span>
    </span>
  )
}
