import React, { useState } from 'react'
import { useQuery } from 'react-query'
import classNames from 'classnames'

import { fetchSmartCart } from 'api/smart_cart'
import { fetchProducts as fetchProductsApi } from 'api/products'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useOpenModal } from 'components/Modal'
import Icons from 'components/Icons'
import Text from 'components/Text'
import Button from 'components/Button'
import WaitForData from 'components/WaitForData'
import Loader from 'components/Loader'
import useSmartCartMutations from 'hooks/queries/smartcart'
import { MINI_CART } from 'constants/features'
import * as QueryUtils from 'utils/query'
import { getAccountData } from 'utils/account'
import { isFeatureEnabled } from 'utils/features'

import Editor from './Editor'
import Preview from './Preview'
import useMiniCart from './hooks/useMiniCart'

export default function SmartCart({ account, store }) {
  const accountData = getAccountData()
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(accountData, MINI_CART)
  const { name: shop } = accountData
  const smartCartQuery = useQuery(['smart-cart', { shop }], fetchSmartCart)
  const productSearchQuery = useQuery(
    [
      'products',
      {
        shop: accountData.name,
      },
    ],
    fetchProductsApi,
    {
      staleTime: 20 * 60 * 1000,
    }
  )
  const {
    updateSmartCart: { mutate: updateSmartCart, status: updateSmartCartStatus },
  } = useSmartCartMutations({ shop })
  return (
    <WaitForData
      objects={[smartCartQuery, productSearchQuery]}
      isQueries={true}
    >
      {() => {
        const cart = QueryUtils.data(smartCartQuery)?.smartCart
        const cartEnabled = cart.enabled
        return (
          <div className="flex flex-col h-full">
            <div className="flex flex-row items-center justify-between mb-5">
              <Text.ScreenHeading marginBottom={0}>MiniCart</Text.ScreenHeading>
              <div className="flex flex-row items-center justify-between min-h-[2.75rem]">
                {updateSmartCartStatus === 'loading' ? (
                  <Loader />
                ) : (
                  <Button
                    color={cartEnabled ? 'red' : 'primary'}
                    onClick={() => {
                      if (!featureEnabled && !cartEnabled) {
                        openModal(
                          <UpgradeAccountModal
                            featureToEnable={MINI_CART}
                            account={{ data: accountData }}
                            customMessage={
                              <React.Fragment>
                                <span className="mr-1 font-semibold">
                                  {MINI_CART.label}
                                </span>{' '}
                                is in beta. If you would like to opt in as a
                                beta user, let us know in the chat
                              </React.Fragment>
                            }
                          />
                        )
                      } else {
                        updateSmartCart({
                          ...cart,
                          enabled: !cartEnabled,
                        })
                      }
                    }}
                  >
                    {cartEnabled ? 'Disable' : 'Enable'}
                  </Button>
                )}
              </div>
            </div>
            <SmartCartEditor
              cart={cart}
              accountData={accountData}
              store={store}
              featureEnabled={featureEnabled}
              products={QueryUtils.data(productSearchQuery).data}
            />
          </div>
        )
      }}
    </WaitForData>
  )
}

function SmartCartEditor({
  cart,
  accountData,
  featureEnabled,
  products,
  store,
}) {
  const layout = useMiniCart({ cart, accountData, products, store })
  return (
    <div
      className={classNames(
        'flex flex-row items-stretch h-full bg-gray-300 border max-h-[79vh] relative',
        {
          'pointer-events-none': !featureEnabled,
        }
      )}
    >
      {!featureEnabled && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-300 opacity-50 pointer-events-none">
          <Icons.Lock className="w-20" />
        </div>
      )}
      <div className="items-stretch flex-1 h-full overflow-y-auto ">
        <Preview layout={layout} accountData={accountData} />
      </div>
      <div className="relative flex w-1/3 h-full max-w-md overflow-y-auto bg-white xxl:w-1/4">
        <Editor layout={layout} accountData={accountData} />
      </div>
    </div>
  )
}
