import * as Channels from './channels'
import { ReactComponent as ICON_BESTSELLER } from './icons/bestsellers.svg'
import { ReactComponent as ICON_TRENDING } from './icons/trending.svg'
import { ReactComponent as ICON_PERSONALISED } from './icons/personalised.svg'
import { ReactComponent as ICON_BOUGHT_TOGETHER } from './icons/fbt.svg'

const TRENDING_PRODUCTS = {
  value: 'trending',
  label: 'Trending Products',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  allowedChannels: [
    Channels.KLAVIYO,
    Channels.MAILCHIMP,
    Channels.OMNISEND,
    Channels.SHOPIFY,
    Channels.SPENTLY,
  ],
  icon: ICON_TRENDING,
}

const BESTSELLERS = {
  value: 'bestsellers',
  label: 'Best Sellers',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  allowedChannels: [
    Channels.KLAVIYO,
    Channels.MAILCHIMP,
    Channels.OMNISEND,
    Channels.SHOPIFY,
    Channels.SPENTLY,
  ],
  icon: ICON_BESTSELLER,
}

const PERSONALIZED = {
  value: 'personalized',
  label: 'Personalized',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  allowedChannels: [
    Channels.KLAVIYO,
    Channels.MAILCHIMP,
    Channels.OMNISEND,
    Channels.SHOPIFY,
    Channels.SPENTLY,
  ],
  icon: ICON_PERSONALISED,
}

const BOUGHT_TOGETHER = {
  value: 'bought_together',
  label: 'Bought Together',
  description:
    'Upsell products and increase your Average Order Value(AoV). Use it to show bundles, addons, upgrade products, etc.',
  allowedChannels: [
    Channels.KLAVIYO,
    Channels.MAILCHIMP,
    Channels.OMNISEND,
    Channels.SHOPIFY,
    Channels.SPENTLY,
  ],
  icon: ICON_BOUGHT_TOGETHER,
}

export { TRENDING_PRODUCTS, BESTSELLERS, PERSONALIZED, BOUGHT_TOGETHER }
