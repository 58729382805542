import React from 'react'
import Box from 'components/Box'
import Link from 'components/Link'
import Button from 'components/Button'
import classNames from 'classnames'

export default function InfoBox({ title, type, action, children, external,className }) {
  const linkClass =
    'relative px-3 py-1 text-xs font-medium text-white bg-primary border border-primary-600 rounded shadow focus:outline-none focus:shadow-outline hover:bg-primary-700 hover:border-primary-600'
  //TODO Fix external link redirection in case of same domain.
  return (
    <Box
      className={classNames('relative mb-6', BANNER_TYPE_TO_CLASS_MAP[type],className)}
      spacing="xSmall"
    >
      <div className="mr-2 font-semibold text-gray-800 text">{title}</div>
      <div className="mb-2 text-xs text-gray-800">{children}</div>
      {action && action.content && action.onAction && (
        <Button onClick={action.onAction}>{action.content}</Button>
      )}
      {action &&
        action.content &&
        action.link &&
        (external ? (
          <a className={linkClass} href={action.link}>
            {action.content}
          </a>
        ) : (
          <Link className={linkClass} to={action.link}>
            {action.content}
          </Link>
        ))}
    </Box>
  )
}

const BANNER_TYPE_TO_CLASS_MAP = {
  info: 'bg-primary-200',
  warning: 'bg-primary-200',
  critical: 'bg-red-200',
  success: 'bg-green-200',
}
