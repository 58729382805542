import React from 'react'

import { isFeatureEnabled } from 'utils/features'
import { getCurrencyFormat, isRkSupport } from 'utils/account'
import { SEARCH, GEO_TARGETING } from 'constants/features'
import NavigationTabs from 'components/NavigationTabs'

import ConfigureRecommendations from './Configure'
import ExcludedProducts from './ExcludedProducts'
import DisabledProducts from './DisabledProducts'
import BulkConfigure from './BulkConfigure'
import GeoTagging from './GeoTagging'
import SearchAPI from './SearchAPI'

export default function RecommendationConfiguration({ account, store }) {
  const currencyFormat = getCurrencyFormat(account, store)
  const rkSupport = isRkSupport(account.data)

  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-col mb-3">
        <NavigationTabs
          tabs={TABS}
          visibilityCheckParams={[account.data]}
          tabProps={{
            account,
            store,
            currencyFormat,
            rkSupport,
          }}
        />
      </div>
    </div>
  )
}

const TABS = [
  {
    label: 'Configure',
    value: 'configure',
    tooltip: 'Use this option to configure product recommendations.',
    component: ConfigureRecommendations,
    root: true,
  },
  {
    label: 'Bulk Configure',
    value: 'bulk-upload',
    tooltip: 'Use this option to upload product recommendations in bulk.',
    component: BulkConfigure,
  },
  {
    label: 'Excluded Products',
    value: 'excluded-products',
    tooltip:
      'Add products here to remove them from coming up as recommendations within widgets.',
    component: ExcludedProducts,
  },
  {
    label: 'Disabled Products',
    value: 'disabled-products',
    tooltip:
      'Add products here to disable widgets from appearing on these product pages.',
    component: DisabledProducts,
  },
  {
    label: 'Geo Tagging',
    value: 'geo-tagging',
    tooltip:
      'Add products here to display only to users from specific countries.',
    component: GeoTagging,
    isVisible: (accountData) => isFeatureEnabled(accountData, GEO_TARGETING),
  },
  {
    label: 'Search API',
    value: 'search-api',
    tooltip: 'Match specific products to specific search terms',
    component: SearchAPI,
    isVisible: (accountData) => isFeatureEnabled(accountData, SEARCH),
  },
]
