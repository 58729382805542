import TEMPLATE_TYPES from 'constants/templates'

export function getTemplateLabelFromType(type) {
  const templateType = Object.keys(TEMPLATE_TYPES).find((templateType) => {
    const templateOption = TEMPLATE_TYPES[templateType]
    if (templateOption.value === type) {
      return templateOption.value
    }
  })
  if(TEMPLATE_TYPES[templateType]){
    return TEMPLATE_TYPES[templateType].label
  }
  return type
}
