import React from 'react'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import CodeEditor from 'components/CodeEditor'

export default function AdminPanelShopExtra({ data, onSave, isSaving }) {
  const [extra, setExtra] = React.useState(
    JSON.stringify(data.extra, null, 4) || ''
  )
  const openToast = useToast()
  function validateAndSave(extra) {
    try {
      extra = JSON.parse(extra)
      onSave({ extra })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Error updating! Please ensure JSON is valid',
      })
    }
  }
  return (
    <div className="flex flex-col items-start flex-1 w-full">
      <div className="flex flex-row flex-wrap w-full notranslate">
        <CodeEditor
          placeholder="You can add Shop Extra settings here."
          mode="json"
          value={extra}
          onChange={setExtra}
          name="shop_extra_editor"
        />
      </div>
      <Button
        size="small"
        loading={isSaving}
        onClick={() => validateAndSave(extra)}
      >
        Save
      </Button>
    </div>
  )
}
