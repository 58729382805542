import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'

import Button from 'components/Button'

import useMultiProductUpsell from './hooks/useMultiProductUpsell'
import Slot from './Slot'

export default function PromotionBuilderLayoutEditorMultiProductUpsellForm({
  section,
  handleUpdate,
  accountData,
}) {
  const {
    groups,
    activeGroup,
    toggleActiveGroup,
    updateGroup,
    addSlot,
    deleteSlot,
    setErrors,
  } = useMultiProductUpsell({ section, handleUpdate })

  const {
    settings: { slots },
  } = section

  return (
    <React.Fragment>
      <ul className="relative flex flex-col border-t-8 border-gray-50">
        {groups.map((group, index) => {
          const isActive = group.id === activeGroup
          const nextIsActive = groups[index + 1]?.id === activeGroup
          const GroupComponent =
            GROUP_ID_TO_COMPONENT_MAP[group.type || group.id]

          if (!GroupComponent) {
            return null
          }

          return (
            <li
              key={group.id}
              className={classNames('flex flex-col items-stretch bg-white', {
                'border-b-8 border-gray-50': isActive,
                'border-t-8': isActive && index > 0,
              })}
            >
              <div
                className={classNames(
                  'flex items-center justify-between px-4 text-base font-medium cursor-pointer group h-16 hover:bg-gray-10',
                  {
                    'border-b border-gray-200': !isActive && !nextIsActive,
                    'text-primary': isActive,
                  }
                )}
                onClick={() => toggleActiveGroup(group.id)}
              >
                <span>{group.label}</span>
                <Icons.ChevronDown
                  className={classNames('w-4 transition-transform', {
                    'transform rotate-180 text-primary': isActive,
                    ' text-gray-600': !isActive,
                  })}
                />
              </div>
              {isActive && (
                <div className={classNames('p-4 border-t border-gray-10')}>
                  <GroupComponent
                    value={group.value}
                    handleUpdate={(values, errors) => {
                      updateGroup(group.buildUpdate(values))
                      setErrors(errors, group)
                    }}
                    {...(group.type === 'slot'
                      ? {
                          handleDelete: deleteSlot,
                          deleteDisabled: slots.length <= MIN_SLOTS,
                        }
                      : {})}
                    accountData={accountData}
                  />
                </div>
              )}
            </li>
          )
        })}
      </ul>
      <Button
        className="mt-auto mb-3"
        disabled={slots.length >= MAX_SLOTS}
        onClick={() => {
          if (slots.length < MAX_SLOTS) {
            addSlot()
          }
        }}
      >
        Add Slot
      </Button>
    </React.Fragment>
  )
}

const GROUP_ID_TO_COMPONENT_MAP = {
  // 'select-product': ProductSelection,
  // discounts: Discounts,
  // translations: Translations,
  slot: Slot,
}

const MAX_SLOTS = 4
const MIN_SLOTS = 2
