import api from 'api'
import { fakeApi } from 'utils/misc'

export function createFunnel(shop, payload) {
  const { name } = payload

  if (!name) {
    return null
  }

  return api.post(`/aov/funnels/`, payload, {
    params: {
      shop,
    },
  })
}

export function fetchFunnel({ queryKey }) {
  const [_key, { shop, funnelId }] = queryKey

  if (!funnelId) {
    return null
  }
  return api
    .get(`/aov/funnels/${funnelId}/`, {
      params: {
        shop,
      },
    })
    .then((response) => {
      return {
        data: {
          data: response.data.funnel,
        },
      }
    })
}

export function fetchFunnels({ queryKey }) {
  const [_key, shop, { pagination, order, filters }] = queryKey
  return api
    .get(`/aov/funnels/`, {
      params: {
        shop,
        filters,
        pagination,
        order,
      },
    })
    .then((response) => {
      return {
        data: {
          size: pagination.size,
          offset: pagination.offset,
          data: response.data.funnels,
          total: response.data.totalPages,
        },
      }
    })
}

export function updateFunnel(shop, payload) {
  if (!payload.id) {
    return null
  }

  return api.put(`/aov/funnels/${payload.id}/`, payload, {
    params: {
      shop,
    },
  })
}

export function createWorkflowNode(shop, funnel, payload) {
  if (!funnel || !funnel.id || !payload || !payload.type) {
    return null
  }

  return api.post(`/aov/funnels/${funnel.id}/workflow/`, payload, {
    params: {
      shop,
    },
  })
}

export function updateWorkflowNode(shop, funnel, payload) {
  if (!funnel || !funnel.id || !payload || !payload.id) {
    return null
  }

  return api.put(`/aov/funnels/${funnel.id}/workflow/${payload.id}/`, payload, {
    params: {
      shop,
    },
  })
}

export function fetchWorkflowNodes({ queryKey }) {
  const [_key, { shop, funnel }] = queryKey
  if (!funnel || !funnel.id) {
    return null
  }

  return api.get(`/aov/funnels/${funnel.id}/workflow/`, {
    params: {
      shop,
    },
  })
}
