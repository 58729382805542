import * as Pages from 'constants/pages'
import { ReactComponent as ICON_BESTSELLER } from './icons/bestsellers.svg'
import { ReactComponent as ICON_BOUGHT_TOGETHER } from './icons/fbt.svg'
import { ReactComponent as ICON_SIMILAR } from './icons/similar.svg'
import { ReactComponent as ICON_CROSS_SELL } from './icons/crossSell.svg'
import { ReactComponent as ICON_RECENTLY_VIEWED } from './icons/recentlyViewed.svg'
import { ReactComponent as ICON_PERSONALIZED } from './icons/personalised.svg'
import { ReactComponent as ICON_TRENDING } from './icons/trending.svg'
import { ReactComponent as ICON_DISCOUNTS } from './icons/discounts.svg'
import { ReactComponent as ICON_PROMOTED } from './icons/promoted.svg'
import { ReactComponent as ICON_RECENTLY_LAUNCHED } from './icons/recentlyLaunched.svg'
import { ReactComponent as ICON_COLLECTIONS } from './icons/collections.svg'

const ALL_PAGES = Object.keys(Pages).map((k) => Pages[k])

const BOUGHT_TOGETHER = {
  value: 'bought_together',
  label: 'Frequently Bought Together',
  allowedOnPages: [
    Pages.PRODUCT,
    Pages.CART,
    Pages.ORDER_CONFIRM,
    Pages.CHECKOUT,
    Pages.AJAX_CART,
    Pages.RETURNS,
  ],
  recommendedPages: [Pages.PRODUCT, Pages.CART],
  recommendedPageDescriptions: {
    [Pages.PRODUCT.value]:
      'Product pages get maximum number of visitors. Showing frequently bought together items here can boost your AOV(Average Order Value).',
    [Pages.CART.value]:
      'Cart page is a great place to up-sell your products. Users can directly add products to their cart from this widget.',
    [Pages.AJAX_CART.value]:
      'On themes supporting AJAX Cart, this is a great way to up-sell your products. Users can directly add products to their cart from this widget.',
  },
  description:
    'Upsell products and increase your Average Order Value(AoV). Perfect for "Shop the look" or "Pair With" style recommendations.',
  icon: ICON_BOUGHT_TOGETHER,
  includedOptions: ['randomEnabled'],
  allowedInSlots: true,
  score: 0,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.RETURNS.value]: 2,
    [Pages.PRODUCT.value]: 2,
  },
  supportedSettings: ["automaticEnabled","enableRandom","allowIfUnavailable"]
}

const SIMILAR_PRODUCTS = {
  value: 'similar_products',
  label: 'Similar Products',
  allowedOnPages: [Pages.PRODUCT, Pages.CART, Pages.AJAX_CART, Pages.RETURNS],
  recommendedPages: [Pages.PRODUCT],
  recommendedPageDescriptions: {
    [Pages.PRODUCT.value]:
      'Use "Similar Products" widget to show alternative options to users right on the product page. This can boost your conversion rate.',
  },
  description:
    'Show Related/Relevant products to increase your conversion and keep visitors hooked to the store.',
  icon: ICON_SIMILAR,
  includedOptions: ['filterCriteria', 'randomEnabled'],
  allowedInSlots: true,
  score: 1,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["automaticEnabled","enableRandom","allowIfUnavailable"]
}

const CROSS_SELL = {
  value: 'cross_sell',
  label: 'Cross Sell',
  allowedOnPages: [
    Pages.HOME,
    Pages.PRODUCT,
    Pages.COLLECTION,
    Pages.CART,
    Pages.ORDER_CONFIRM,
    Pages.CHECKOUT,
    Pages.AJAX_CART,
    Pages.RETURNS,
  ],
  recommendedPages: [Pages.PRODUCT, Pages.COLLECTION],
  recommendedPageDescriptions: {
    [Pages.PRODUCT.value]:
      'Add "Cross Sell" on product page to increase discoverability of your entire catalogue.',
    [Pages.COLLECTION.value]:
      'Add "Cross Sell" on product page to increase discoverability of your entire catalogue.',
    [Pages.AJAX_CART.value]:
      'On themes supporting AJAX Cart, this is a great way to cross sell your products. Users can directly add products to their cart from this widget.',
  },
  description:
    'Cross Sell to convert buyer into a repeat customer. Increases Product Discovery and Revenue Per Customer.',
  icon: ICON_CROSS_SELL,
  includedOptions: ['randomEnabled'],
  allowedInSlots: true,
  score: 2,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["automaticEnabled","enableRandom","allowIfUnavailable"]
}

const RECENTLY_VIEWED = {
  value: 'recently_viewed',
  label: 'Recently Viewed',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.CART, Pages.HOME, Pages.COLLECTION, Pages.RETURNS],
  recommendedPageDescriptions: {
    [Pages.CART.value]:
      'Cart page is a great place to remarket the products that a user has already visited.',
    [Pages.HOME.value]:
      'Homepage is the gateway to your storefront. Use "Recently Viewed" widget here to immediately capture attention of returning customers.',
    [Pages.COLLECTION.value]:
      'When browsing a collection, "Recently Viewed" widget can help your users get to products they have visited quickly.',
  },
  description:
    'Re-marketing is one of the most rewarding technique. This widget gives user the opportunity to buy the products that they have already seen.',
  icon: ICON_RECENTLY_VIEWED,
  excludedOptions: [
    'automaticEnabled',
    'allowProductsWithoutImage',
    'allowOutOfStock',
  ],
  allowedInSlots: true,
  score: 3,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["automaticEnabled","enableRandom","allowIfUnavailable"]
}

const TRENDING = {
  value: 'trending',
  label: 'Trending',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME, Pages.ORDER_CONFIRM, Pages.CHECKOUT],
  recommendedPageDescriptions: {
    [Pages.HOME.value]:
      'Use "Trending Products" on homepage to make sure that the top products of your store are getting proper screen time.',
    [Pages.ORDER_CONFIRM.value]:
      '"Trending Products" widget gives you an opportunity to showcase top products from your store to converted users.',
  },
  description:
    'Show products gaining demand to make sure visitors buy products currently in trend.',
  icon: ICON_TRENDING,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  allowedInSlots: true,
  score: 4,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const BESTSELLERS = {
  value: 'bestsellers',
  label: 'Bestsellers',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME],
  recommendedPageDescriptions: {
    [Pages.HOME.value]:
      'Use "Bestsellers" widget on homepage to make sure that the best selling products of your store are getting proper screen time.',
  },
  description:
    'This widget is useful in displaying the best selling products of your store.',
  icon: ICON_BESTSELLER,
  excludedOptions: ['automaticEnabled'],
  allowedInSlots: true,
  score: 5,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const PERSONALIZED = {
  value: 'personalized',
  label: 'Personalized for You',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME],
  recommendedPageDescriptions: {
    [Pages.HOME.value]:
      'Use "Personalized" widget on homepage to give your users a personalised experience from the word go.',
  },
  description:
    "Show Products based on user's browsing history. A complete Personalized Experience to your store visitors to increase conversion.",
  icon: ICON_PERSONALIZED,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  allowedInSlots: true,
  score: 6,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const DISCOUNTS = {
  value: 'discounts',
  label: 'Discounts For You',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME, Pages.ORDER_CONFIRM],
  recommendedPageDescriptions: {},
  description:
    'This is very helpful in case you want to keep the customer engaged. Using this widget you can show products that have high discounts as well as high prices on your store.',
  icon: ICON_DISCOUNTS,
  score: 7,
  supportMultiple: true,
  supportedSettings: ["automaticEnabled","enableRandom","allowIfUnavailable"]
}

const PROMOTED_PRODUCTS = {
  value: 'promoted',
  label: 'Promoted Products',
  allowedOnPages: [Pages.BLOG, Pages.OTHER, Pages.RETURNS],
  recommendedPages: [Pages.BLOG, Pages.OTHER],
  recommendedPageDescriptions: {
    [Pages.BLOG.value]:
      'Show products specific to a blog article using this widget.',
    [Pages.OTHER.value]:
      'Promote your products on a custom page of your store.',
  },
  description:
    'Show viewers of your store specific products based on the context.',
  icon: ICON_PROMOTED,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  score: 8,
  supportMultiple: true,
  maxAllowed: {
    default: 3,
    [Pages.BLOG.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const RECENTLY_LAUNCHED = {
  value: 'recent_launch',
  label: 'Recently Launched',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME],
  recommendedPageDescriptions: {},
  description:
    'Showcase your recently launched products. Make these products visible to the customers and increase sales.',
  icon: ICON_RECENTLY_LAUNCHED,
  excludedOptions: ['automaticEnabled'],
  allowedInSlots: true,
  score: 9,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const CUSTOM_WIDGET = {
  value: 'custom',
  label: 'Custom',
  allowedOnPages: [
    Pages.HOME,
    Pages.CART,
    Pages.PRODUCT,
    Pages.AJAX_CART,
    Pages.OTHER,
  ],
  recommendedPages: [],
  recommendedPageDescriptions: {},
  description:
    'Combine multiple widgets into a single one, or have a completely custom recommendation algorithm',
  icon: ICON_PERSONALIZED,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  score: 10,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const COLLECTIONS_WIDGET = {
  value: 'collections',
  label: 'Collections Widget',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.HOME],
  recommendedPageDescriptions: {},
  description: 'Highlight collections across your store.',
  icon: ICON_COLLECTIONS,
  excludedOptions: ['automaticEnabled'],
  includedOptions: ['rankingCriteria'],
  score: 11,
  supportMultiple: true,
  maxAllowed: {
    default: 3,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const PURCHASES = {
  value: 'purchases',
  label: 'Recent Purchases',
  allowedOnPages: [
    Pages.HOME,
    Pages.CART,
    Pages.PRODUCT,
    Pages.COLLECTION,
    Pages.AJAX_CART,
  ],
  recommendedPages: [Pages.CART, Pages.HOME],
  recommendedPageDescriptions: {},
  description:
    'Recommend products that the customer has bought in the past. This widget helps in increasing repeat purchases.',
  icon: ICON_BOUGHT_TOGETHER,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  score: 12,
  supportMultiple: true,
  maxAllowed: {
    default: 1,
    [Pages.AJAX_CART.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.PRODUCT.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

const SLOTS_WIDGET = {
  value: 'slots',
  label: 'Slot Based Recommendations',
  allowedOnPages: ALL_PAGES,
  recommendedPages: [Pages.PRODUCT, Pages.CART, Pages.ORDER_CONFIRM],
  recommendedPageDescriptions: {},
  description:
    'Recommend products using a combination of recommendation strategies. You can define these for every slot in the recommendation widget.',
  icon: ICON_COLLECTIONS,
  includedOptions: [],
  excludedOptions: ['automaticEnabled'],
  score: 13,
  supportMultiple: true,
  maxAllowed: {
    default: 3,
    [Pages.AJAX_CART.value]: 2,
    [Pages.BLOG.value]: 2,
    [Pages.CART.value]: 2,
    [Pages.CHECKOUT.value]: 2,
    [Pages.COLLECTION.value]: 2,
    [Pages.HOME.value]: 2,
    [Pages.NOT_FOUND.value]: 2,
    [Pages.ORDER_CONFIRM.value]: 2,
    [Pages.OTHER.value]: 2,
    [Pages.PRODUCT.value]: 2,
    [Pages.RETURNS.value]: 2,
  },
  supportedSettings: ["allowIfUnavailable"]
}

export {
  BOUGHT_TOGETHER,
  SIMILAR_PRODUCTS,
  CROSS_SELL,
  RECENTLY_VIEWED,
  RECENTLY_LAUNCHED,
  TRENDING,
  BESTSELLERS,
  PERSONALIZED,
  // DISCOUNTS,
  PURCHASES,
  SLOTS_WIDGET,
  PROMOTED_PRODUCTS,
  CUSTOM_WIDGET,
  COLLECTIONS_WIDGET,
}
