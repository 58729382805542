import React, { useEffect } from 'react'
import { Multiselect } from 'react-widgets'

import { _find } from 'utils/lodash'
import { COUNTRY } from 'constants/fieldTypes'
import COUNTRIES from 'constants/countries'

export default function OrgUserFilterInput({ filters, field }) {
  const countries = Object.keys(COUNTRIES).map((k) => ({
    label: COUNTRIES[k],
    value: k,
  }))
  const fieldState = filters.state[field.key]

  useEffect(() => {
    if (!fieldState.operator) {
      filters.setFieldOperator({
        key: field.key,
        value: COUNTRY.filterOperatorOptions[0].value,
      })
    }
  }, [])

  return (
    <div className="flex flex-col items-stretch w-full mt-2 space-y-1 pl-7">
      <select
        onChange={(e) => {
          filters.setFieldOperator({
            key: field.key,
            value: e.target.value,
          })
        }}
        value={fieldState.operator}
        className="block w-full shadow-sm sm:text-sm rounded-md py-1.5 bg-gray-100 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
      >
        {COUNTRY.filterOperatorOptions.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <Multiselect
        data={countries}
        valueField="value"
        textField="label"
        caseSensitive={false}
        autoFocus={false}
        inputProps={{
          autoFocus: false,
        }}
        defaultValue={fieldState.values}
        onChange={(values) => {
          filters.setFieldValues({
            values: values ? values.map((u) => u.value) : [],
            key: field.key,
          })
        }}
      />
    </div>
  )
}
