function template(account, campaign, isPreview = false) {
  if (!campaign || !campaign.settings) {
    return ''
  }
  const baseURL = 'https://recommendations.loopclub.io/'
  const previewEmail = account.shop.email
  const trackingURL = `${baseURL}v1/marketing/track/view.gif?d=${
    campaign.trackingStr
  }&email=${isPreview ? `${previewEmail}` : '{{email}}'}`
  const products = (campaign.data && campaign.data.products) || []
  const {
    productsPerRow,
    productsCount,
    title,
    titleFontSize,
    callToActionText,
  } = campaign.settings
  let productPositionCounter = 1

  const productRows = products
    .slice(0, productsCount)
    .reduce((rows, product) => {
      const lastRow = rows[rows.length - 1]
      if (!lastRow) {
        return [[product]]
      }

      if (lastRow.length < productsPerRow) {
        return [...rows.slice(0, rows.length - 1), [...lastRow, product]]
      }

      return [...rows, [product]]
    }, [])

  function productHTML(product, index) {
    const { trackingStr } = product
    const clickURL = `${baseURL}v1/marketing/recommendations/link/?recommendation_type=${
      campaign.recommendationType
    }&d=${trackingStr}=&position=${productPositionCounter}${
      isPreview ? `&email=${previewEmail}` : '&email={{email}}'
    }`
    const imageURL = `${baseURL}v1/marketing/recommendations/product.jpeg?recommendation_type=${
      campaign.recommendationType
    }&d=${trackingStr}=&position=${productPositionCounter}${
      isPreview ? `&email=${previewEmail}` : '&email={{email}}'
    }`
    productPositionCounter += 1

    return `
        <div class="rk_email_col_t"
          style="display: inline-table; max-width: 50%; width: 100%; word-break: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; border-collapse: collapse !important; vertical-align: top; text-align: left; position: relative; color: #222222; margin: 20px 0 20px 0; padding: 0px;">
          <table class="rk_email_col" style="display: inline-table; border-spacing: 0; border-collapse: collapse; word-break: break-word; vertical-align: top; text-align: left; width: 100%; margin: 0 auto; padding: 0;">
            <tbody>
              <tr style="text-align: left; padding: 0; vertical-align: bottom;">
                <td class="rk_email_imgwrap_t"
                  style="word-break: break-word; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; border-collapse: collapse !important; vertical-align: middle; text-align: center; color: #222222; margin: 0; padding: 0 10px 0 10px; height: 200px"
                  align="center" valign="bottom">
                  <a href="${clickURL}" target="_blank">
                    <img class="rk_email_img" border="0" height="auto" width="100"
                      src="${imageURL}"
                      alt="" style="outline: none; text-decoration: none; width: auto; height: auto; float: none; clear: both; display: block; margin: 0 auto; border: none; max-width: 100%; max-height: 200px; -ms-interpolation-mode: bicubic;">
                      ${
                        callToActionText
                          ? `<span style="display: inline-block !important; vertical-align: top !important; width: 50% !important;font-size: 12px !important;color: #FFFFFF !important;border-radius: 2px !important;background: #000000 !important;width: 100%;border: 0;margin: 5px 0;padding: 7px 15px !important;cursor: pointer;text-decoration: none !important;" name="add" class="btn">${callToActionText}</span>`
                          : ''
                      }
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      `
  }

  function rowHTML(row) {
    return `
        <tr>
          <td style="font-size:0;padding:0;text-align:center;" align="center" valign="top">
            <!--[if mso]>
              <table role="presentation" border="0" align="center" width="581" cellspacing="0" cellpadding="0"><tbody>
            <![endif]-->
              <!--[if mso]>
                <tr>
              <![endif]-->
              <!--[if mso]>
                <td align="center" width="125" valign="top">
              <![endif]-->
              ${row.map(productHTML).join('')}
              <!--[if mso]>
                </td>
              <![endif]-->
              <!--[if mso]>
                </tr>
              <![endif]-->
            <!--[if mso]>
              </tbody></table>
            <![endif]-->
          </td>
        </tr>
      `
  }

  //NOTE(Dhruv): Use https://www.freeformatter.com/css-beautifier.html when editing
  const STYLE = `
      <style type="text/css">
        .rk_email_row_t{display:table!important;width:100%!important;table-layout:fixed!important}.rk_email_col,.rk_email_col_t{display:inline-table!important}.rk_email_col_t{text-align:center!important}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:480px){table[class=rk_email_wrapper_t]{width:100%!important}div[class=rk_email_col_t]{max-width:50%!important}td[class=rk_email_imgwrap_t]{height:190px!important}img[class=rk_email_img]{max-height:190px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:400px){img[class=rk_email_img]{max-height:180px!important}td[class=rk_email_imgwrap_t]{height:180px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:380px){td[class=rk_email_imgwrap_t]{height:170px!important}img[class=rk_email_img]{max-height:170px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:360px){img[class=rk_email_img]{max-height:160px!important}td[class=rk_email_imgwrap_t]{height:160px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:340px){td[class=rk_email_imgwrap_t]{height:150px!important}img[class=rk_email_img]{max-height:150px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:320px){td[class=rk_email_imgwrap_t]{height:140px!important}img[class=rk_email_img]{max-height:140px!important}}@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:300px){td[class=rk_email_imgwrap_t]{height:130px!important}img[class=rk_email_img]{max-height:130px!important}}
      </style>
    `

  return `
      ${STYLE}
      <img src="${trackingURL}" height="1px" width="1px" />
      <!--[if mso]>
        <table cellpadding="0" cellspacing="0" role="presentation" border="0" align="center" width="581px"><tbody><tr><td>
      <![endif]-->
      <table class="rk_email_wrapper_t" border="0" align="center" bgcolor="#ffffff" cellpadding="0" cellspacing="0" style="max-width: 501px !important; background-color: transparent;font-family: Arial !important;">
        <tbody>
          <tr>
            <td valign="top" align="center" style="padding:0;">
              <h2 class="rk_email_header_t" style="font-size: ${titleFontSize}px; font-weight: normal !important; text-align: center !important; color: #000000 !important; text-decoration: none !important; font-style: normal !important; padding: 20px 0 20px 0 !important; margin: 0 !important;">${title}</h2>
              <table style="text-align: left; border-spacing: 0; border-collapse: collapse; vertical-align: top; width: 100%; position: relative; padding: 0px; table-layout: fixed;" class="rk_email_row_t">
                <tbody>
                ${productRows.map(rowHTML).join('')}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!--[if mso]>
        </td></tr></tbody></table>
      <![endif]-->
    `
}

export default template
