import React from 'react'
import Callout from 'components/Callout'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'

export default function UpgradeCallout({
  account,
  type = 'error',
  text = 'Widgets have been disabled!',
  buttonText = 'Upgrade to re-enable',
  tooltip = 'Your widget serve quota for current plan has finished. Widgets have stopped displaying on your store. Please upgrade your current plan to re-enable widgets',
}) {
  return (
    <Tooltip position="right" content={tooltip}>
      <Callout type={type} noHoverEffect={true}>
        <span className="mr-2">{text}</span>
        <Button
          color={type === 'error' ? 'red' : 'primary'}
          size="xSmall"
          href={`plans/`}
          internalLink={true}
        >
          {buttonText}
        </Button>
      </Callout>
    </Tooltip>
  )
}
