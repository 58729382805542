import { useEffect, useMemo, useReducer, useState, useCallback } from 'react'
import updateObject from 'immutability-helper'

import { _pick, _isEqual } from 'utils/lodash'

export default function useSingleProductUpsell({ section, handleUpdate }) {
  const [state, dispatch] = useReducer(reducer, section)
  const [formError, setFormError] = useState({})
  const groups = useMemo(
    () => [
      {
        label: 'Select Product',
        id: 'select-product',
        value: _pick(state?.settings, [
          'recommendationType',
          'recommendationRules',
        ]),
        buildUpdate: (values) => ({
          settings: {
            $merge: values,
          },
        }),
      },
      {
        label: 'Discounts',
        id: 'discounts',
        value: _pick(state?.settings, ['discount']),
        buildUpdate: (values) => ({
          settings: {
            $merge: values,
          },
        }),
      },
      // {
      //   label: 'Translations',
      //   id: 'translations',
      //   value: state?.translations,
      //   buildUpdate: (values) => ({
      //     translations: {
      //       $merge: values,
      //     },
      //   }),
      // },
    ],
    [state?.settings]
  )

  const [activeGroup, setActiveGroup] = useState(null)
  const toggleActiveGroup = useCallback(
    (groupId) => {
      setActiveGroup(activeGroup && activeGroup === groupId ? null : groupId)
    },
    [activeGroup]
  )

  useEffect(() => {
    if (!_isEqual(state, section)) {
      handleUpdate(state, formError)
    }
  }, [state, handleUpdate, section, formError])

  return useMemo(() => {
    return {
      data: state,
      groups,
      activeGroup,
      toggleActiveGroup,
      updateGroup: (update, errors) => {
        dispatch({ type: 'UPDATE', update })
      },
      setErrors: (errors, group) => {
        if (errors && Object.keys(errors).length > 0) {
          setFormError({ ...formError, [group.id]: errors })
        } else {
          const updatedErrors = { ...formError }
          delete updatedErrors[group.id]
          setFormError(updatedErrors)
        }
      },
    }
  }, [state, toggleActiveGroup, groups, activeGroup])
}

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      const update = updateObject(state, action.update)
      return _isEqual(update, state) ? state : update

    default:
      return state
  }
}
