const NONE = {
  value: null,
  label: 'No criteria',
}

const PRODUCT_TYPE = {
  value: 'product_type',
  label: 'Product Type',
}

const PRODUCTS = {
  value: 'products',
  label: 'Products',
}

const VENDOR = {
  value: 'vendor',
  label: 'Vendor',
}

const COLLECTION = {
  value: 'collection',
  label: 'Collection',
}

export { NONE, PRODUCT_TYPE, VENDOR, COLLECTION, PRODUCTS }
