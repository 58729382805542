import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Button from 'components/Button'
import Icons from 'components/Icons'
import Form from 'components/Form'
import { calculateDiscountPrice } from 'containers/Bundles/utils'
import * as ShopifyUtils from 'utils/shopify'
import { renderCurrency } from 'utils/currency'
import { getAccountData, getCurrencyFormat } from 'utils/account'
import { DISCOUNT_LEVEL_OPTIONS } from 'containers/Bundles/constants'
import { DISCOUNT_TYPES } from 'constants/discount'

export default function ProductList({
  discountLevel,
  productsWithData,
  setProductConfigs,
  productConfigs,
  setSliderVisible,
}) {
  function onDragEnd(result) {
    if (!result.destination || !result.source) {
      return
    }

    const reorderedProducts = orderProducts(
      productConfigs,
      result.source.index,
      result.destination.index
    )

    setProductConfigs(reorderedProducts)
  }

  function onRemove(product) {
    setProductConfigs(
      productConfigs.filter((pdt) => pdt.productId != product.productId)
    )
  }

  return (
    <div className="flex flex-col w-full mt-4 border border-gray-300">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`bundleProducts`}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="flex flex-col items-stretch flex-1"
            >
              {productConfigs.filter(Boolean).map((product, index) => {
                const productData = productsWithData.find(
                  (pdt) => pdt.productId == product.productId
                )
                if (!productData) {
                  return null
                }

                return (
                  <Draggable
                    key={product.productId}
                    draggableId={product.productId.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ProductItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        index={index + 1}
                        product={productData}
                        setProductConfigs={setProductConfigs}
                        productConfigs={productConfigs}
                        onRemove={onRemove}
                        discountLevel={discountLevel}
                        currencyFormat={getCurrencyFormat({
                          data: getAccountData(),
                        })}
                      />
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {productConfigs.length < 4 && (
        <div className={classNames('px-8 py-5')}>
          <Button
            variant="light"
            size="small"
            onClick={() => setSliderVisible(true)}
          >
            + Add Products
          </Button>
        </div>
      )}
    </div>
  )
}

const ProductItem = React.forwardRef(
  (
    {
      discountLevel,
      index,
      product,
      currencyFormat,
      onRemove,
      productConfigs,
      setProductConfigs,
      ...otherProps
    },
    ref
  ) => {
    const {
      productId,
      image,
      variants,
      title,
      price,
      compareAtPrice,
      discount,
    } = product
    const productConfig = productConfigs.find(
      (pdt) => pdt.productId === productId
    )
    const [productState, setProductState] = useState({
      id: productId,
      selectedVariants: variants.filter((variant) =>
        productConfig.variantIds.includes(variant.id)
      ),
      showVariants: false,
      discountType: productConfig.discount.type,
      discountValue: productConfig.discount.value,
    })

    const { showVariants, selectedVariants, discountType, discountValue } =
      productState

    useEffect(() => {
      var updatedProductConfigs = [...productConfigs]
      const productConfigIndex = productConfigs.findIndex(
        (pdt) => pdt.productId === productState.id
      )
      updatedProductConfigs[productConfigIndex].discount = {
        type: discountType,
        value: discountValue,
      }
      updatedProductConfigs[productConfigIndex].variantIds =
        selectedVariants.reduce((accm, currVal) => {
          accm.push(currVal.id)
          return accm
        }, [])
      setProductConfigs(updatedProductConfigs)
    }, [productState])
    return (
      <div
        className="flex flex-col items-stretch px-8 py-5 transition-all duration-300 transform border-b border-gray-300"
        {...otherProps}
        ref={ref}
      >
        <div className="flex flex-row items-center ">
          <Icons.Drag className="w-3 text-gray-600" />
          <span className="w-6 ml-4">{index}.</span>
          <div className="flex flex-row items-center w-full gap-5 ml-4">
            <div className="flex flex-row items-center w-72">
              <img
                src={ShopifyUtils.optimisedImage({
                  baseUrl: image,
                  size: 160,
                })}
                alt={title}
                className="object-cover h-20 w-14"
              />
              <div className="flex flex-col items-start ml-4">
                <span className="text-sm">{title}</span>
                <div className="flex flex-row items-center">
                  {compareAtPrice && (
                    <span
                      className="mr-2 text-xs text-red-400 line-through"
                      dangerouslySetInnerHTML={{
                        __html: renderCurrency(compareAtPrice, currencyFormat),
                      }}
                    ></span>
                  )}
                  <span
                    className="text-xs text-gray-500"
                    dangerouslySetInnerHTML={{
                      __html: renderCurrency(price, currencyFormat),
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <div className="w-36">
              {variants.length > 1 && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setProductState({
                      ...productState,
                      showVariants: !showVariants,
                    })
                  }}
                >
                  <div className="flex flex-row items-center text-primary">
                    <span className="mr-2 text-sm">Show variants</span>
                    <Icons.ChevronDown
                      className={classNames(
                        'w-3 transform transition-all duration-100',
                        {
                          'rotate-180': showVariants,
                        }
                      )}
                    />
                  </div>
                </Button>
              )}
            </div>
            {discountLevel ==
              DISCOUNT_LEVEL_OPTIONS.INDIVIDUAL_DISCOUNTS.value && (
              <React.Fragment>
                <div className="flex flex-col items-start">
                  <Form.Field
                    control="select"
                    label="Discount Type"
                    options={Object.values(DISCOUNT_TYPES)}
                    name={`discountType-${product.id}`}
                    onChange={(e) => {
                      setProductState({
                        ...productState,
                        discountType: e.target.value,
                      })
                    }}
                    value={discountType}
                  />
                </div>
                <div className="flex flex-col items-start">
                  <Form.Field
                    label="Discount"
                    control="input"
                    type="number"
                    name={`discountValue-${product.id}`}
                    onChange={(e) => {
                      setProductState({
                        ...productState,
                        discountValue: e.target.value,
                      })
                    }}
                    value={discountValue}
                    className="w-28"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <Form.Field
                    label="Discounted Price"
                    control="input"
                    name={`discountPrice-${product.id}`}
                    onChange={(e) => {}}
                    value={calculateDiscountPrice(
                      price,
                      discountType,
                      discountValue
                    )}
                    className=" w-28"
                    controlClassName="bg-gray-300"
                    disabled={true}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="">
              <Icons.Delete
                className="w-6 ml-6 text-gray-600 transition-colors duration-200 cursor-pointer hover:text-red-500"
                onClick={() => onRemove(product)}
              />
            </div>
          </div>
        </div>
        {showVariants && (
          <div className="flex flex-col items-start ml-12">
            {selectedVariants.length > 0
              ? selectedVariants.map((selectedVariant) => {
                  return (
                    <VariantItem
                      key={selectedVariant.id}
                      variant={selectedVariant}
                      onRemoveVariant={(variantId) => {
                        setProductState({
                          ...productState,
                          selectedVariants: selectedVariants.filter(
                            (variant) => variant.id != variantId
                          ),
                        })
                      }}
                      currencyFormat={currencyFormat}
                    />
                  )
                })
              : variants.map((variant) => {
                  return (
                    <VariantItem
                      key={variant.id}
                      variant={variant}
                      onRemoveVariant={(variantId) => {
                        setProductState({
                          ...productState,
                          selectedVariants: variants.filter(
                            (variant) => variant.id != variantId
                          ),
                        })
                      }}
                      currencyFormat={currencyFormat}
                    />
                  )
                })}
          </div>
        )}
      </div>
    )
  }
)

function VariantItem({ variant, onRemoveVariant, currencyFormat }) {
  const { id: variantId, title, price, compareAtPrice } = variant
  return (
    <div className="flex flex-row items-center mt-3" key={variantId}>
      {/* <Icons.Drag className="w-2 text-gray-600" /> */}
      <div className="flex flex-row items-center px-3 py-2 ml-3 overflow-x-auto text-sm border border-gray-300 w-80">
        <span className="mr-2">{title}</span>
        <span className="mr-2">-</span>
        {compareAtPrice && (
          <span
            className="mr-2 text-xs text-red-400 line-through"
            dangerouslySetInnerHTML={{
              __html: renderCurrency(compareAtPrice, currencyFormat),
            }}
          ></span>
        )}
        <span
          className="text-xs text-gray-500"
          dangerouslySetInnerHTML={{
            __html: renderCurrency(price, currencyFormat),
          }}
        ></span>
      </div>
      {false && (
        <Icons.Delete
          className="w-5 ml-3 text-gray-600 transition-colors duration-200 cursor-pointer hover:text-red-500"
          onClick={() => {
            onRemoveVariant(variantId)
          }}
        />
      )}
    </div>
  )
}

function getListStyle(isDraggingOver) {
  return {
    ...(isDraggingOver ? { background: '#f9f9f9' } : {}),
  }
}

function getItemStyle(isDragging, draggableStyle) {
  return {
    userSelect: 'none',
    ...(isDragging ? { background: 'lightblue' } : {}),
    ...draggableStyle,
  }
}

function orderProducts(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
