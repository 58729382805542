import React from 'react'
import _uniq from 'lodash.uniq'

import Box from 'components/Box'
import Products from 'components/MultiSelects/Products'
import Tags from 'components/MultiSelects/Tags'
import { useToast } from 'components/Toast'

export default function DisabledProducts({
  currencyFormat,
  rkSupport,
  onSave,
  widget,
}) {
  const { disabledProducts = [], disabledTags = [] } = widget
  const openToast = useToast()

  async function updateDisabledProducts(productIds, twoWayEnabled = false) {
    onSave(
      { disabledProducts: productIds, reset: true },
      'Exclusion List has been Updated. It might take a few minutes for the changes to be visible.!'
    )
  }

  async function removeProduct(productId) {
    onSave(
      {
        disabledProducts: disabledProducts
          .filter((product) => product.id !== productId)
          .map((product) => product.id),
      },
      'Product removed from Exclusion List. It might take a few minutes for the effects to take place.!'
    )
  }

  async function updateTags(newTags) {
    onSave({ disabledTags: newTags }, 'Tags successfully updated..')
  }

  async function onTagAdd({ tag }) {
    if (disabledTags.includes(tag)) {
      openToast({
        type: 'error',
        text: 'Tag already exists',
      })
    } else {
      const newTags = _uniq([...disabledTags, tag])
      await updateTags(newTags)
    }
  }

  async function onTagRemove(tag) {
    const newTags = _uniq(disabledTags.filter((t) => t !== tag))
    await updateTags(newTags)
  }
  return (
    <Box className="mt-6">
      <div className="flex flex-row items-start">
        <Products
          products={disabledProducts}
          currencyFormat={currencyFormat}
          rkSupport={rkSupport}
          updateProducts={updateDisabledProducts}
          removeProduct={removeProduct}
          copies={PRODUCT_COPIES}
        />
        <Tags
          tags={disabledTags}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          copies={TAG_COPIES}
        />
      </div>
    </Box>
  )
}

const PRODUCT_COPIES = {
  title: 'Disabled Products',
  tooltipContent:
    "Add a product here to hide widgets from showing up on it's page. It may take a few minutes for the configuration to take effect.",
  resourcePickerHeading: 'Choose disabled products',
  fallbackContent:
    'No products added to disabled list yet. Adding a product here will prevent widgets from showing up on those product pages.',
}
const TAG_COPIES = {
  title: 'Disabled Tags',
  tooltipContent:
    'Widgets will not show up for products that have any of the following tags.',
  fallbackContent:
    'No tags added to disabled list yet. Adding a tag here will remove the widgets on Product pages with these tags.',
}
