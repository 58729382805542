import React from 'react'
import classNames from 'classnames'

import FieldValue from 'components/ResourceList/FieldValue'
import { useToast } from 'components/Toast'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Loader from 'components/Loader'
import { SelectVersionTwo } from 'components/Form/controls'
import Icons from 'components/Icons'
import { trimString } from 'utils/string'
import { getAccountData } from 'utils/account'
import { isFeatureEnabled } from 'utils/features'
import { Select } from 'components/Form/controls'
import useFunnelMutations from 'hooks/queries/funnels'
import { FUNNELS } from 'constants/features'
import { _find } from 'utils/lodash'

import { getFunnelStatusOptions } from '../../utils'

export default function TableCell(props) {
  const {
    value,
    cell: {
      column: { id: fieldName },
      row: { original },
    },
    state: { resourceSchema },
  } = props

  const type = resourceSchema.schema[fieldName].type
  const accountData = getAccountData()
  const openModal = useOpenModal()
  const {
    updateFunnel: { mutate: updateFunnel, status: updateFunnelStatus },
  } = useFunnelMutations({ shop: accountData.name })

  const funnelUpdating = updateFunnelStatus === 'loading'
  const funnelStatusOptions = getFunnelStatusOptions(original, accountData)

  switch (type) {
    case 'details':
      return (
        <div className="flex flex-col items-start w-32">
          <span className="text-lg font-medium text-gray-900">
            {trimString(original.name, 20)}
          </span>
          <span className="mt-1 text-xs text-gray-500">
            Funnel ID: {original.id}
          </span>
        </div>
      )
    case 'status':
      return (
        <div className="flex flex-row justify-center px-4">
          <div
            className="w-32"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {funnelUpdating ? (
              <Loader />
            ) : (
              <SelectVersionTwo
                size="small"
                label={
                  <div
                    className={classNames(
                      'flex flex-row py-1.5 px-4 rounded border text-sm border-gray-400 w-full justify-between',
                      // STATUS_TO_CLASS_NAME_MAP[original.status],
                      {
                        'pointer-events-none opacity-50': funnelUpdating,
                      }
                    )}
                  >
                    <span>
                      {
                        _find(funnelStatusOptions, function (o) {
                          return o.value === original.status
                        }).label
                      }
                    </span>
                    <Icons.ChevronDown className="w-3 ml-2 text-current" />
                  </div>
                }
                options={funnelStatusOptions}
                onChange={(selectedOption) => {
                  const status = selectedOption.value
                  if (!funnelUpdating) {
                    if (selectedOption.locked) {
                      openModal(
                        <UpgradeAccountModal
                          featureToEnable={FUNNELS}
                          account={{ data: accountData }}
                        />
                      )
                    } else {
                      updateFunnel({
                        id: original.id,
                        status,
                      })
                    }
                  }
                }}
                value={_find(funnelStatusOptions, function (o) {
                  return o.value === original.status
                })}
              />
            )}
          </div>
        </div>
      )

    default:
      return (
        <div
          className="flex flex-row items-center justify-center w-full truncate"
          title={value}
        >
          <FieldValue
            value={value}
            type={type}
            isList={resourceSchema.schema[fieldName].list}
          />
        </div>
      )
  }
}
