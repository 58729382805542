import React from 'react'
import classNames from 'classnames'

import { LIMIT_BANNER_UPDATES } from 'containers/Funnels/constants'
import Icons from 'components/Icons'

import GeneralSettings from './GeneralSettings'
import LineSettings from './LineSettings'
import useBannerSection from './hooks/useBannerSection'

export default function PromotionBuilderLayoutEditorBannerForm({
  section,
  handleUpdate,
}) {
  const {
    data,
    groups,
    activeGroup,
    toggleActiveGroup,
    handleSettingsUpdate,
    handleLineUpdate,
    handleLineDelete,
    handleLineAdd,
  } = useBannerSection({
    section,
    handleUpdate,
  })

  return (
    <React.Fragment>
      <ul className="relative flex flex-col border-t-8 border-gray-50">
        {groups.map((group, index) => {
          const isActive = group.id === activeGroup
          const nextIsActive = groups[index + 1]?.id === activeGroup

          return (
            <li
              key={group.id}
              className={classNames('flex flex-col items-stretch bg-white', {
                'border-b-8 border-gray-50': isActive,
                'border-t-8': isActive && index > 0,
              })}
            >
              <div
                className={classNames(
                  'flex items-center justify-between px-4 text-base font-medium cursor-pointer group h-16 hover:bg-gray-10',
                  {
                    'border-b border-gray-200': !isActive && !nextIsActive,
                    'text-primary': isActive,
                  }
                )}
                onClick={() => toggleActiveGroup(group.id)}
              >
                <span>{group.label}</span>
                <Icons.ChevronDown
                  className={classNames('w-4 transition-transform', {
                    'transform rotate-180 text-primary': isActive,
                    ' text-gray-600': !isActive,
                  })}
                />
              </div>
              {isActive && (
                <div className={classNames('p-4 border-t border-gray-10')}>
                  {group.id === 'general_settings' ? (
                    <GeneralSettings
                      value={group.settings}
                      handleUpdate={handleSettingsUpdate}
                    />
                  ) : (
                    <LineSettings
                      value={group.line}
                      key={group.line.id}
                      handleUpdate={handleLineUpdate}
                      handleDelete={handleLineDelete}
                      canBeDeleted={
                        groups.filter((group) => group.type === 'line').length >
                        1
                      }
                    />
                  )}
                </div>
              )}
            </li>
          )
        })}
      </ul>
      {!LIMIT_BANNER_UPDATES && (
        <div className="flex items-center justify-center px-4 mt-4">
          <button
            className="flex items-center justify-center w-full py-2 font-semibold rounded cursor-pointer bg-primary-200 hover:bg-primary-300 text-primary"
            onClick={handleLineAdd}
          >
            <span className="mr-2 text-xl font-bold leading-none">+</span> Add
            line
          </button>
        </div>
      )}
    </React.Fragment>
  )
}
