import React from 'react'
import _uniqBy from 'lodash.uniqby'
import _get from 'lodash.get'

import { ruleSave as ruleSaveApi } from 'api/rules'
import Button from 'components/Button'
import RuleSection from 'components/SetupRecommendations/ProductRecommendations/RecommendationRules/RuleSection'
import { useToast } from 'components/Toast'
import Icons from 'components/Icons'

export default function CreateRule({
  rules,
  onClose,
  shop,
  widgetType,
  widgetId,
  openUpgradeAccount,
}) {
  const openToast = useToast()
  const [saving, setSaving] = React.useState(false)
  const [ruleData, setRuleData] = React.useState({})
  const [ruleWidgetId, setRuleWidgetId] = React.useState(null)

  async function onSave() {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      setSaving(true)
      try {
        const { data } = await ruleSaveApi(
          [
            {
              data: {
                rules: [ruleData],
              },
              recommendationType: widgetType,
              ruleType: 'list',
              state: 'active',
              ...(ruleWidgetId ? { widget: ruleWidgetId } : {}),
            },
          ],
          shop
        )

        rules.update(
          {
            ...rules.data,
            rules: _uniqBy([...data.rules, ...rules.data.rules], (r) => r.id),
          },
          { local: true }
        )

        openToast({
          type: 'success',
          text: 'Rule was successfully added!',
        })
        onClose()
      } catch (err) {
        openToast({
          type: 'error',
          text: 'Error in saving rule!',
        })
      }
      setSaving(false)
    }
  }

  return (
    <div className="flex flex-col items-stretch pb-3 mb-3 border-b border-gray-400">
      <div className="flex flex-col p-3 bg-white">
        <div className="flex flex-row mb-3">
          <RuleSection
            type="if"
            mode="edit"
            disableValuesUpdate={saving}
            onUpdate={(newIfData) => {
              setRuleData({
                ...ruleData,
                if: {
                  ...ruleData.if,
                  ...newIfData,
                },
              })
            }}
          />
          <RuleSection
            type="then"
            mode="edit"
            disableValuesUpdate={saving}
            onUpdate={(newThenData) => {
              setRuleData({
                ...ruleData,
                then: {
                  ...ruleData.then,
                  ...newThenData,
                },
              })
            }}
          />
        </div>
        <div className="flex flex-row items-center justify-between">
          {widgetId && (
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                value={Boolean(ruleWidgetId)}
                name="widget-only-rule"
                id="widget-only-rule"
                onChange={(e) =>
                  setRuleWidgetId(e.target.checked ? widgetId : null)
                }
              />
              <label
                className="w-full p-1 text-xs font-semibold cursor-pointer"
                htmlFor={'widget-only-rule'}
              >
                Apply rule only to this widget.
              </label>
            </div>
          )}
          <div className="flex flex-row items-center ml-auto">
            <Button
              size="xSmall"
              variant="bordered"
              className="opacity-50 hover:opacity-1"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className="ml-3"
              onClick={onSave}
              disabled={
                _get(ruleData, 'if.objectValues.length', 0) === 0 ||
                _get(ruleData, 'then.objectValues.length', 0) === 0 ||
                saving
              }
            >
              <div className="flex items-center justify-center">
                <span className="mr-1">Save</span>
                {openUpgradeAccount && (
                  <Icons.Lock className={'w-3 mb-1 fill-current opacity-75'} />
                )}
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
