import { useEffect } from 'react'
import { fetchAccountConfig as fetchAccountConfigApi } from 'api/account'
import { eventsReceived } from 'utils/account'

export default function ({ account }) {
  const { name: shop } = account.data

  let pollTimeout
  useEffect(() => {
    const { links } = account.data
    if (Boolean(links && links.productPage) && eventsReceived(account.data)) {
      return
    }

    async function pollAccountConfig(shop) {
      try {
        const { data: newAccountConfig } = await fetchAccountConfigApi(shop)
        const linksLoaded =
          newAccountConfig.links && newAccountConfig.links.productPage
        const eventsLoaded = eventsReceived(newAccountConfig)

        if (linksLoaded || eventsLoaded) {
          account.update(newAccountConfig, { local: true })
        }

        if (!linksLoaded || !eventsLoaded) {
          pollTimeout = setTimeout(() => pollAccountConfig(shop), 1000)
        }
      } catch (err) {}
    }

    pollAccountConfig(shop)

    return () => clearTimeout(pollTimeout)
  }, [])
}
