import { useEffect, useReducer, useState } from 'react'
import { useBoolean } from 'react-hanger'
import { useDebouncedCallback } from 'use-debounce'
import { useHistory } from 'react-router-dom'

import { updateURLSearch } from 'utils/router'
import { _find, _get } from 'utils/lodash'
import * as FILTER_OPERATORS from 'constants/filterOperators'

export default function useFilters({
  queryParams,
  resourceType,
  resourceSchema,
  onChange,
  initialView,
  options,
}) {
  const filterableFields = getFilterableFields({
    resourceSchema,
  })
  const { f } = queryParams
  const history = useHistory()

  const initialQueryObject = f ? objectFromQueryParam(f) : initialView.filters
  const [queryObject, setQueryObject] = useState(initialQueryObject)

  const [filterState, dispatch] = useReducer(
    reducer,
    getInitialState(filterableFields, queryObject)
  )

  const sidebar = useBoolean(false)

  const debouncedSetQueryObject = useDebouncedCallback((o) => {
    const searchUpdate = Object.keys(o).length
      ? { f: btoa(JSON.stringify(o)) }
      : { f: '' }

    history.replace({ search: updateURLSearch(searchUpdate) })

    setQueryObject(o)
    onChange()
  }, 300)

  useEffect(() => {
    if (!filterState) {
      return
    }

    const qp = Object.keys(filterState).reduce((accum, key) => {
      const { active, operator, values } = filterState[key]
      let validOperatorValuesPair = false
      if (values.length > 0) {
        validOperatorValuesPair = true
      } else {
        validOperatorValuesPair = [
          FILTER_OPERATORS.IS_PRESENT.value,
          FILTER_OPERATORS.IS_NOT_PRESENT.value,
        ].includes(operator)
      }

      return active && validOperatorValuesPair
        ? { ...accum, [key]: { operator, values } }
        : accum
    }, {})

    debouncedSetQueryObject.callback(qp)
  }, [filterState])

  return {
    skipFilters: options?.skipFilters,
    resourceSchema,
    fields: filterableFields,
    state: filterState,
    toggleField: ({ key, defaultOperator, defaultValues }) =>
      dispatch({
        type: 'TOGGLE_FIELD_ACTIVE',
        key,
        defaultOperator,
        defaultValues,
      }),

    setFieldOperator: ({ key, value }) =>
      dispatch({ type: 'SET_FIELD_OPERATOR', key, value }),

    setFieldValues: ({ key, values }) =>
      dispatch({ type: 'SET_FIELD_VALUES', key, values }),

    query: queryObject,

    sidebarOpen: sidebar.value,
    closeSidebar: sidebar.setFalse,
    openSidebar: sidebar.setTrue,
    clearAll: () => {
      dispatch({ type: 'RESET', filterableFields })
      sidebar.setFalse()
    },
  }
}

function getFilterableFields({ resourceSchema }) {
  const { schema } = resourceSchema
  return Object.keys(schema).reduce((accum, key) => {
    const field = schema[key]
    if (!field.allowFilter) {
      return accum
    }

    return { ...accum, [key]: field }
  }, {})
}

function objectFromQueryParam(str) {
  try {
    return JSON.parse(atob(str))
  } catch (err) {}
  return {}
}

function reducer(state = {}, action) {
  switch (action.type) {
    case 'TOGGLE_FIELD_ACTIVE':
      const currentFieldState = state[action.key]

      let newFieldState
      if (currentFieldState.active) {
        // NOTE(Dhruv): Use existing field value instead of default?
        newFieldState = DEFAULT_FIELD_STATE
      } else {
        newFieldState = {
          values: action.defaultValues,
          operator: action.defaultOperator,
          active: true,
        }
      }

      return { ...state, [action.key]: newFieldState }

    case 'SET_FIELD_OPERATOR':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          operator: action.value,
          values: [],
        },
      }

    case 'SET_FIELD_VALUES':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          values: action.values,
        },
      }
    case 'RESET':
      return getInitialState(action.filterableFields, {})

    default:
      throw new Error('Filters:InvalidAction')
  }
}

function getInitialState(filterableFields, queryObject) {
  const initialState = {}
  Object.keys(filterableFields).forEach((key) => {
    if (queryObject[key]) {
      initialState[key] = {
        active: true,
        ...queryObject[key],
      }
    } else {
      initialState[key] = DEFAULT_FIELD_STATE
    }
  })

  return initialState
}

const DEFAULT_FIELD_STATE = {
  active: false,
  operator: '',
  values: [],
}
