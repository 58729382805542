import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from 'components/Toast'
import { invalidate } from 'utils/query'

import Overview from './Overview'

export default function Review({ builder, rb, accountData }) {
  const { popup, savePopup, setPublishHandler, setActiveScreen } = builder
  const { setCloseHandler } = rb
  const history = useHistory()
  const openToast = useToast()

  useEffect(() => {
    async function popupPublish() {
      if (
        Object.keys(popup.triggers).some(
          (triggerKey) => popup.triggers[triggerKey].enabled
        )
      ) {
        openToast({
          type: 'error',
          text: 'Cannot publish popup with no triggers',
        })
      } else if (!popup.recommendationType) {
        openToast({
          type: 'error',
          text: 'Cannot publish popup with no recommendation type',
        })
      } else if (!popup.name) {
        openToast({
          type: 'error',
          text: 'Cannot publish popup with no name',
        })
      } else if (!Boolean(popup.template)) {
        openToast({
          type: 'error',
          text: 'Cannot publish popup without a template',
        })
      } else {
        await savePopup(
          { status: 'active' },
          {
            successMessage: 'Congratulations! The popup is live on your store',
          }
        )
        history.push(`/popups?shop=${accountData.name}`)
        invalidate('popups')
      }
    }

    async function confirmClose() {
      // TODO: Use toast to accept confirmation
      return window.confirm(
        'Popup is not live! Are you sure you want to close ?'
      )
    }

    setPublishHandler(() => popupPublish)
    setCloseHandler(() => confirmClose)
  }, [popup, savePopup, setPublishHandler, setCloseHandler, history])
  return (
    <div className="px-8 mt-6">
      <Overview
        accountData={accountData}
        popup={popup}
        onEdit={(screen) => setActiveScreen(screen)}
        mode="builder"
        builder={builder}
      />
    </div>
  )
}
