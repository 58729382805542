import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { useOpenModal } from 'components/Modal'
import useAppEmbedDetect, {
  EMBED_BLOCK_STATES,
} from 'components/AppEmbedCard/hooks'
import { storeSave as storeSaveApi } from 'api/store'
import { storeScriptTagEnabled } from 'utils/account'
import { _find } from 'utils/lodash'
import AddWidgetNew from 'components/CreateWidgetNew'

export default function useOnboarding({
  pages,
  billing,
  account,
  store,
  experiences,
}) {
  const openModal = useOpenModal()

  const allWidgets = (pages.data || []).reduce(
    (widgets, page) => [...widgets, ...page.widgets],
    []
  )
  const {
    plan: { name: planName },
    name,
  } = account.data
  const {
    data: {
      onboarding: { completed: storeOnboardingCompleted },
    },
  } = store
  const { state: appEmbedState, handleConfigure } = useAppEmbedDetect({
    account: account,
    checkStorefront: true,
  })
  const history = useHistory()
  const ONBOARDING_STEPS = [
    {
      heading: 'Enable Application In Your Theme',
      description:
        'Enable Glood.AI scripts on storefront. This will not enable recommendations on the store.',
      action: 'Visit Theme Setting',
      value: 'enable_app_block',
      clickHandler: handleConfigure,
    },
    {
      heading: 'Create Your First Widget',
      description:
        'Create a widget to start adding recommendations on the storefront.',
      action: 'Create Widget',
      value: 'create_widget',
      clickHandler: () => {
        openModal(
          <AddWidgetNew
            pages={pages}
            billing={billing}
            account={account}
            experience={
              experiences?.data?.experiences
                ? _find(
                    experiences.data.experiences,
                    (exp) => exp.slug === 'default-experience',
                    undefined
                  )
                : undefined
            }
            updateWidgetData={function updateWidgetData(
              widgetData,
              experience
            ) {
              experiences.get()
            }}
          />,
          {
            hideCloseButton: true,
            fullScreen: true,
            className: 'bg-white h-screen',
          }
        )
      },
    },
    {
      heading: 'Start Free Trial',
      description:
        'Enable more features and prevent service cut by selecting a plan.',
      action: 'Start Free Trial',
      value: 'upgrade_plan',
      clickHandler: () => {
        history.push(`/plans?shop=${name}`)
      },
    },
  ]

  const DEFAULT_ONBOARDING_STATE = {
    stage: ONBOARDING_STEPS[0].value,
    completed: false,
  }

  const [onboardingState, setOnboardingState] = useState(
    DEFAULT_ONBOARDING_STATE
  )
  const [loading, setLoading] = useState(true)

  async function updateOnboarding(payload) {
    const { data: newData } = await storeSaveApi(
      { onboarding: { ...payload } },
      name
    )
    store.update(newData, { local: true })
  }

  useEffect(() => {
    if (appEmbedState === EMBED_BLOCK_STATES.LOADING) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    if (
      [
        EMBED_BLOCK_STATES.ENABLED,
        EMBED_BLOCK_STATES.ENABLED_ON_START,
        EMBED_BLOCK_STATES.SHOP_STOREFRONT_ENABLED,
      ].includes(appEmbedState) ||
      storeScriptTagEnabled(account.data)
    ) {
      if (allWidgets.length > 0) {
        if (planName !== 'Free') {
          setOnboardingState({ completed: true, stage: 'upgrade_plan' })
          if (!storeOnboardingCompleted) {
            updateOnboarding({ completed: true })
          }
        } else {
          setOnboardingState({ completed: false, stage: 'upgrade_plan' })
        }
      } else {
        setOnboardingState({ completed: false, stage: 'create_widget' })
      }
    } else {
      setOnboardingState({ completed: false, stage: 'enable_app_block' })
    }
  }, [appEmbedState, account, pages])

  return {
    onboardingSteps: ONBOARDING_STEPS,
    onboardingState,
    loading,
  }
}
