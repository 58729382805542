import React from 'react'

import ResourceList from 'components/ResourceList'

export default function VisitorList({ schema }) {
  return (
    <ResourceList
      resourceType="visitor"
      schema={schema}
      initialView={{
        fields: [
          'userId',
          'email',
          'shopifyUser',
          'deviceType',
          'os',
          'browser',
          'city',
          'country',
          'phone',
          'postalCode',
          'createdAt',
          'lastSeenAt',
          'lastBoughtAt',
          'totalPurchaseValue',
          'orderCount',
          'pageViewCount',
          'tags',
          'productTypes',
          'collections',
        ],
        filters: {},
        order: [],
      }}
    />
  )
}
