import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Overview from './Overview'

export default function Review({ builder, rb, accountData }) {
  const { funnel, saveFunnel, setPublishHandler, setActiveScreen } = builder
  const { setCloseHandler } = rb
  const history = useHistory()

  useEffect(() => {
    async function funnelPublish() {
      await saveFunnel(
        { status: 'active' },
        {
          successMessage: 'Congratulations! The funnel is live on your store',
        }
      )
      history.push(`/funnels?shop=${accountData.name}`)
    }

    async function confirmClose() {
      // TODO: Use toast to accept confirmation
      return window.confirm(
        'Promotion is not live! Are you sure you want to close ?'
      )
    }

    setPublishHandler(() => funnelPublish)
    setCloseHandler(() => confirmClose)
  }, [funnel, saveFunnel, setPublishHandler, setCloseHandler, history])
  return (
    <div className="px-8 mt-6">
      <Overview
        accountData={accountData}
        funnel={funnel}
        onEdit={(screen) => setActiveScreen(screen)}
        mode="builder"
        builder={builder}
      />
    </div>
  )
}
