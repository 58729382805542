import React from 'react'
import classNames from 'classnames'

import Box from 'components/Box'
import Button from 'components/Button'
import { useTrackView } from 'hooks/analytics'
import { trackEvent } from 'utils/analytics'

import styles from './styles.module.css'

export default function PromotionCard({ className, promotion }) {
  const { title, description, buttonText, buttonUrl, app } = promotion
  useTrackView({
    category: 'Promotion Card',
    action: 'view',
    label: app,
  })

  return (
    <div className={className} key={title}>
      <Box className="bg-primary-100">
        <div className="flex flex-col items-stretch">
          <span className="text-sm font-semibold text-gray-800 uppercase">
            {title}
          </span>
          {false && (
            <div className="mt-1 text-3xl font-semibold text-gray-800 notranslate">
              {title}
            </div>
          )}
          <ul
            className={classNames(
              'mt-2 text-xs text-gray-600 mb-3 list-disc pl-3',
              styles.description
            )}
          >
            {description.split('\n').map(function (item, idx) {
              return <li key={idx}>{item}</li>
            })}
          </ul>
          {buttonText && (
            <Button
              size="small"
              className="text-center"
              target="_blank"
              href={buttonUrl}
              onClick={() => {
                trackEvent({
                  category: 'Promotion Card',
                  action: 'click',
                  label: app,
                })
              }}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </Box>
    </div>
  )
}
