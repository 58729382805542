import React from 'react'
import { useMutation } from 'react-query'
import { useToast } from 'components/Toast'

import {
  updatePopup as updatePopupApi,
  createPopup as createPopupApi,
} from 'api/popups'

import { invalidate, refetch } from 'utils/query'

export default function usePopupMutations({ shop }) {
  const openToast = useToast()
  const updatePopup = useMutation(
    async (data) => {
      return await updatePopupApi(shop, data)
    },
    {
      onSuccess: (data, variables, context) => {
        invalidate(['popups'])
        invalidate(['popup'])
        openToast({
          type: 'success',
          text: 'Popup updated successfully!',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error updating the popup',
        })
      },
    }
  )

  const createPopup = useMutation(
    async (data) => {
      return await createPopupApi(shop, data)
    },
    {
      onSuccess: () => {
        invalidate(['popups'])
        invalidate(['popup'])
        openToast({
          type: 'success',
          text: 'Popup created successfully',
        })
      },
      onError: () => {
        openToast({
          type: 'error',
          text: 'There was an error creating the popup',
        })
      },
    }
  )

  return {
    updatePopup,
    createPopup,
  }
}
