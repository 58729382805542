import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { BUNDLE_TEMPLATES, TRIGGER_OPTIONS } from 'containers/Bundles/constants'
import Icons from 'components/Icons'
import Button from 'components/Button'
import { useOpenModal } from 'components/Modal'
import Tooltip from 'components/Tooltip'
import { _omit } from 'utils/lodash'
import { getAccountData } from 'utils/account'

import ShowWidget from './ShowWidget'
import TimeTriggered, { TimeTriggeredExtra } from './TimeTriggered'

export default function Trigger({ builder }) {
  const {
    bundle,
    saveBundle,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder

  const [selectedTriggers, setSelectedTriggers] = useState(
    bundle.triggers ? bundle.triggers : {}
  )
  const [selectedTemplate, setSelectedTemplate] = useState(
    bundle.template ? bundle.template : {}
  )

  useEffect(() => {
    async function bundleSave() {
      return await saveBundle({
        triggers: selectedTriggers,
        // template: selectedTemplate,
      })
    }
    setSaveHandler(() => bundleSave)
    setSaveNextHandler(() => async () => {
      const savedBundle = await bundleSave()
      if (savedBundle) {
        setActiveScreen('bundle')
      }
    })
  }, [selectedTriggers])

  function addOrRemoveTrigger(trigger) {
    const selectedTrigger = selectedTriggers[trigger.type]
    if (selectedTrigger?.enabled) {
      setSelectedTriggers({
        ...selectedTriggers,
        [trigger.type]: {
          type: trigger.value,
          settings: trigger.settings,
          enabled: false,
        },
      })
    } else {
      setSelectedTriggers({
        ...selectedTriggers,
        [trigger.type]: {
          type: trigger.value,
          settings: trigger.settings || {},
          enabled: true,
        },
      })
    }
    return selectedTrigger
  }

  return (
    <div className="flex flex-col items-stretch w-full">
      <div className="flex flex-col w-full px-16 py-10 ">
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">Trigger Bundle on</span>
          <span className="mt-2 text-base text-gray-500">
            Complete the following steps to get started
          </span>
        </div>
        <div>
          <div className="grid items-stretch grid-cols-3 gap-5 mt-5 lg:grid-cols-4">
            {TRIGGER_OPTIONS.map((trigger) => {
              return (
                <TriggerCard
                  key={trigger.value}
                  trigger={trigger}
                  selectedTriggers={selectedTriggers}
                  addOrRemoveTrigger={addOrRemoveTrigger}
                  SettingsComponent={
                    TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP[trigger.type]
                      ?.SettingsComponent
                  }
                  ExtraComponent={
                    TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP[trigger.type]
                      ?.ExtraComponent
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col w-full px-16 py-10 border-t border-gray-400">
        <div className="flex flex-col items-start">
          <span className="text-lg font-semibold">Select Template</span>
        </div>
        <div className="grid items-stretch justify-around grid-cols-3 gap-20 overflow-x-hidden overflow-y-auto">
          {Object.keys(BUNDLE_TEMPLATES).map((template_key) => {
            return (
              <TemplateItem
                template={BUNDLE_TEMPLATES[template_key]}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            )
          })}
        </div>
      </div> */}
    </div>
  )
}

function TemplateItem({ template, selectedTemplate, setSelectedTemplate }) {
  const isSelected = selectedTemplate.type == template.value
  return (
    <div
      className="flex flex-col overflow-hidden border border-gray-300 rounded-lg cursor-pointer group hover:border-primary focus:border-primary mt-7"
      onClick={() => {
        setSelectedTemplate({ type: template.value })
      }}
    >
      <div className="flex flex-col items-stretch pb-6 pr-4 pt-7 pl-7">
        <div className="flex flex-row items-center justify-between">
          <span className="text-sm font-semibold">{template.label}</span>
          <div className="flex flex-row items-center">
            <Button size="small" variant="text" className="mr-4">
              Preview
            </Button>
            <span
              className={classNames(
                'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:bg-primary',
                {
                  'bg-primary': isSelected,
                }
              )}
            >
              <Icons.Tick className="w-3 text-white stroke-current" />
            </span>
          </div>
        </div>
        <span className="text-xs text-gray-500">
          Lorem ipsum dolor sit amet, some consectetur adipiscing elit sed do
          Lorem ipsum dolor sit amet,
        </span>
      </div>
      <div className="relative flex flex-row items-center justify-center w-full h-full p-6 bg-gray-800">
        <img src={template.image} />
        <div className="absolute inset-0 z-10 flex items-center justify-center w-full opacity-80 hover:bg-gray-900">
          <div className="flex flex-row items-center text-white">
            <Icons.WidgetView className="w-8" />
            <span className="ml-3 text-base font-semibold">Preview</span>
          </div>
        </div>
      </div>
    </div>
  )
}
const TRIGGER_OPTION_TO_SETTINGS_COMPONENT_MAP = {
  showWidget: {
    SettingsComponent: ShowWidget,
  },
  popupTime: {
    SettingsComponent: TimeTriggered,
    ExtraComponent: TimeTriggeredExtra,
  },
}

function TriggerCard({
  selectedTriggers,
  addOrRemoveTrigger,
  trigger,
  SettingsComponent,
  ExtraComponent,
}) {
  const selectedTrigger = selectedTriggers[trigger.type]
  const isUnavailable =
    typeof trigger.available != 'undefined' && !trigger.available
  const [settings, setSettings] = useState(
    selectedTrigger?.settings ? selectedTrigger.settings : {}
  )
  const openModal = useOpenModal()
  const accountData = getAccountData()
  return (
    <Tooltip content={trigger.tooltipContent} triggerClassName="!flex">
      <div
        className={classNames(
          'flex flex-row items-start justify-between p-4 border border-gray-400 rounded shadow-sm w-full',
          {
            'opacity-50 cursor-not-allowed': isUnavailable,
            'cursor-pointer hover:border-primary focus:border-primary-400 group':
              !isUnavailable,
          }
        )}
        onClick={() => {
          if (!isUnavailable) {
            if (!selectedTrigger?.enabled && SettingsComponent) {
              openModal(
                <SettingsComponent
                  settings={settings}
                  onChange={(value) => {
                    setSettings(value)
                  }}
                  onSave={(updatedSettings) => {
                    addOrRemoveTrigger({
                      ...trigger,
                      settings: {
                        ...settings,
                        ...updatedSettings,
                      },
                    })
                  }}
                />,
                {
                  fullScreen: false,
                  className: 'w-2/5',
                }
              )
            } else {
              addOrRemoveTrigger({
                ...trigger,
              })
            }
          }
        }}
      >
        <div className="flex flex-col items-center justify-start h-full">
          <div className="flex flex-row items-center justify-start w-full">
            <span className="text-sm font-semibold">{trigger.label}</span>
          </div>
          <span className="mt-2 text-xs text-gray-600">
            {typeof trigger.description === 'function'
              ? trigger.description(accountData)
              : trigger.description}
          </span>
        </div>
        <div className="flex flex-col items-center justify-between ml-4">
          <span
            className={classNames(
              'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:border-primary-400 group-hover:border',
              {
                'bg-primary': selectedTrigger?.enabled,
              }
            )}
          >
            <Icons.Tick className="w-3 text-white stroke-current" />
          </span>
          {ExtraComponent && <ExtraComponent settings={settings} />}
        </div>
      </div>
    </Tooltip>
  )
}
