import React from 'react'
import Button from 'components/Button'
export default function TemplateErrorBox({ error, label }) {
  const [showTemplateError, setShowTemplateError] = React.useState(false)

  return (
    <div
      className="fixed flex flex-col items-start w-7/12 mb-3 overflow-scroll bg-white rounded-sm"
      style={{ marginTop: 20, maxHeight: '60%', bottom: '-1.7rem' }}
    >
      <div className="sticky top-0 flex flex-row items-center justify-start w-full p-3 pb-0 bg-white ">
        <span className="p-2 mr-3 font-semibold text-white bg-red-600 rounded-sm notranslate">
          {label}
        </span>
        <Button
          variant="bordered"
          size="xSmall"
          color="red"
          onClick={() => setShowTemplateError(!showTemplateError)}
        >
          {showTemplateError ? 'Hide Details' : 'Show Details'}
        </Button>
      </div>
      <div className="flex flex-row justify-between w-full p-3">
        {showTemplateError ? (
          <code className="whitespace-pre-line ">{error}</code>
        ) : (
          <code className="whitespace-pre-line ">
            {error.split('\n')[0].substring(0, 50)}
          </code>
        )}
      </div>
    </div>
  )
}
