import React from 'react'
import Form from 'components/Form'
import { useForm } from 'react-hook-form'

export default function GeneralForm(props) {
  const { onChange } = props
  const { register, errors, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(props),
  })

  const TITLE_ALIGN_OPTIONS = [
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
  ]

  const formValues = watch()

  React.useEffect(() => {
    if (formState.isDirty && formState.isValid) {
      onChange(getUpdateObjectFromValues(formValues))
    }
  }, [formValues, formState.isValid, formState.isDirty])

  return (
    <form className="flex flex-col items-stretch flex-1 w-full">
      <Form.FieldRow className="pt-3 mt-3 border-t border-gray-200">
        <Form.Field
          label="Widget Title Text"
          name="title"
          control="input"
          ref={register({
            required: 'Required!',
          })}
          formError={errors.title}
        />
        {props.settings?.widgetTitleAlignment && (
          <Form.Field
            label="Widget Title Alignment"
            name="widgetTitleAlignment"
            control="select"
            options={TITLE_ALIGN_OPTIONS}
            ref={register({
              required: 'Widget title alignment cannot be empty.',
            })}
            formError={errors.widgetTitleAlignment}
          />
        )}
        <Form.Field
          label="Widget Title Font"
          name="widgetFont"
          control="input"
          ref={register({})}
          formError={errors.widgetFont}
          helpText="Use this to control the font you'd like to use inside the widget e.g 'Times New Roman'."
        />
      </Form.FieldRow>
      <Form.FieldRow className="pt-3 mt-3 border-t border-gray-200">
        <Form.Field
          label="Widget Title Font Size"
          name="fontSize"
          control="input"
          type="number"
          ref={register({
            required: 'Please enter a valid number as widget title font size',
          })}
          formError={errors.fontSize}
        />
        <Form.Field
          label="Maximum recommendations"
          name="maxContent"
          control="input"
          type="number"
          helpText="Use this value to update the maximum number of recommendations that show in the widget."
          ref={register({
            required:
              'Please enter a valid number as maximum recommendations count ',
          })}
          formError={errors.maxContent}
        />
      </Form.FieldRow>
    </form>
  )
}

function getDefaultValues({ settings, widget }) {
  return {
    title: widget.title,
    maxContent: widget.maxContent,
    fontSize: parseInt(settings.widgetTitleFontSize) || 32,
    widgetFont: settings.widgetFontFamily,
    widgetTitleAlignment: settings.widgetTitleAlignment,
  }
}

function getUpdateObjectFromValues(values) {
  return {
    title: values.title,
    maxContent: parseInt(values.maxContent),
    settings: {
      widgetTitleAlignment: values.widgetTitleAlignment,
      widgetTitleFontSize: `${values.fontSize}px`,
      widgetFontFamily: values.widgetFont,
    },
  }
}
