import React, { useState } from 'react'

import api from 'api'
import Box from 'components/Box'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { fromNow, hoursAgo, differnceInWords } from 'utils/time'

export default function Triggers({ account, store }) {
  const openToast = useToast()
  const [productSyncInProgress, setProductSyncInProgress] = useState(false)
  const [
    recommendationSyncInProgress,
    setRecommendationSyncInProgress,
  ] = useState(false)

  const { name: shop } = account.data

  async function triggerProductsync() {
    setProductSyncInProgress(true)
    try {
      await api.post('/products/sync/', {}, { params: { shop } })
      await store.get()
      openToast({
        type: 'success',
        text:
          'Product Sync has been successfully triggered. It will be completed in few minutes.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text:
          'Product Sync could not be successfully triggered. Please contact support',
      })
    }
    setProductSyncInProgress(false)
  }

  async function triggerRecommendationSync() {
    setRecommendationSyncInProgress(true)
    try {
      await api.post('/products/sync/', {}, { params: { shop } })
      await store.get()
      openToast({
        type: 'success',
        text:
          'Recommendation generation has been successfully triggered. It will be completed in few minutes.',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text:
          'Recommendation generation could not be successfully triggered. Please contact support!',
      })
    }
    setRecommendationSyncInProgress(false)
  }

  return (
    <React.Fragment>
      <Box className="flex flex-col items-stretch mb-3">
        <span className="mb-1 text-lg font-semibold">Product Sync</span>
        <div className="flex flex-row items-center justify-between text-sm text-gray-800">
          {
            <span>
              Last manual product sync completed:{' '}
              <strong>{fromNow(store.data.productsSyncedAt)}</strong>
            </span>
          }
          {!store.data.productsSyncedAt ||
          hoursAgo(store.data.productsSyncedAt) > 6 ? (
            <Button
              variant={'solid'}
              size="small"
              onClick={triggerProductsync}
              loading={productSyncInProgress}
            >
              Trigger Product sync
            </Button>
          ) : (
            <span className="font-semibold text-gray-600">{`Please try in ${differnceInWords(
              store.data.productsSyncedAt + 6 * 60 * 60
            )}`}</span>
          )}
        </div>
      </Box>
      {false && (
        <Box className="flex flex-col items-stretch mb-3">
          <span className="mb-1 text-lg font-semibold">
            Recommendation Generation
          </span>
          <div className="flex flex-row items-center justify-between text-sm text-gray-800">
            {
              <span>
                Last recommendation generation completed:{' '}
                <strong>{fromNow(store.data.trendingGeneratedAt)}</strong>
              </span>
            }
            {!store.data.trendingGeneratedAt ||
            hoursAgo(store.data.trendingGeneratedAt) > 2 ? (
              <Button
                variant={'solid'}
                size="small"
                onClick={triggerRecommendationSync}
                loading={recommendationSyncInProgress}
              >
                Trigger Recommendation Generation
              </Button>
            ) : (
              <span className="font-semibold text-gray-600">{`Please try in ${differnceInWords(
                store.data.trendingGeneratedAt + 2 * 60 * 60
              )}`}</span>
            )}
          </div>
        </Box>
      )}
    </React.Fragment>
  )
}
