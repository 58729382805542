import React from 'react'
import Button from 'components/Button'
import CodeEditor from 'components/CodeEditor'

export default function AdminPanelCSS({ data, onSave, isSaving }) {
  const [css, setCss] = React.useState(data.css || '')
  return (
    <div className="flex flex-col items-start flex-1 w-full">
      <div className="flex flex-row flex-wrap w-full notranslate">
        <CodeEditor
          placeholder="You can add CSS to the shop. For most of the stores default style is enough. But in case you require more styling options you can add CSS here."
          mode="css"
          value={css}
          onChange={setCss}
          name="shop_css_editor"
        />
      </div>
      <Button size="small" loading={isSaving} onClick={() => onSave({ css })}>
        Save
      </Button>
    </div>
  )
}
