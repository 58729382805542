import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import Loader from 'components/Loader'

import useVisitor from './hooks/useVisitor'
import DetailsCard from './DetailsCard'
import TimelineCard from './TimelineCard'

export default function VisitorDetail({ schema }) {
  const {
    params: { visitorId },
  } = useRouteMatch()
  const { fetching, data: visitor } = useVisitor({ visitorId })

  if (fetching) {
    return (
      <div className="flex items-center justify-center flex-1 h-full">
        <Loader message="Fetching visitor data..." />
      </div>
    )
  }

  if (visitor) {
    return (
      <div className="flex flex-row items-start -mx-2">
        <div className="flex flex-col items-stretch flex-1 mx-2">
          <DetailsCard visitor={visitor} schema={schema} />
        </div>
        <div className="flex flex-col items-stretch flex-1 mx-2">
          <TimelineCard visitor={visitor} schema={schema} />
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex items-center justify-center flex-1 h-full">
        <span className="text-sm text-gray-600">Visitor data not found...</span>
      </div>
    )
  }
}
