import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Box from 'components/Box'
import Button from 'components/Button'
import Form from 'components/Form'
import ShopifyPlusBadge from 'components/Badge/ShopifyPlus'
import Icons from 'components/Icons'
import { PRODUCT_DISCOUNTS_SHOPIFY_PLUS_HELP_LINK } from 'constants/support'
import classNames from 'classnames'

export default function ProductDiscounts({
  account,
  widget,
  onSave,
  onUpgrade,
  onDelete,
  mode,
  onChange,
  headingClassName
}) {
  const { name: shopName, shop, plan } = account.data
  async function onWidgetSave(newData) {
    if (onUpgrade && typeof onUpgrade === 'function') {
      onUpgrade()
      return
    }
    await onSave({
      ...widget,
      discountConfig: { ...widget.discountConfig, ...newData },
    })
  }

  return (
    <DiscountForm
      widget={widget}
      onWidgetSave={onWidgetSave}
      onUpgrade={onUpgrade}
      mode={mode}
      onChange={onChange}
      headingClassName={headingClassName}
    />
  )
}

function DiscountForm({ widget, onWidgetSave, onUpgrade, mode, onChange,headingClassName }) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      enabled: widget.discountConfig.enabled,
      type: widget.discountConfig.type,
      message: widget.discountConfig.message,
      value: widget.discountConfig.value,
    },
  })

  const formData = watch()

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(formData)
    }
  }, [formData])

  const watchEnabled = watch('enabled')
  const watchType = watch('type')
  const isPercentage = watchType === 'PERCENTAGE'
  const valueHelpText = isPercentage
    ? '% discount applicable on each recommended item in the widget.'
    : 'Flat discount amount applicable on each recommended item in the widget.'

  return (
    <Box className="w-full mb-5">
      <div className="flex flex-row items-center mb-5 ">
        <span className={classNames(
          "text-sm font-semibold text-gray-800 uppercase",
          headingClassName
        )}>
          Discount Settings
        </span>
        <ShopifyPlusBadge className="ml-3" />
        <Button
          href={PRODUCT_DISCOUNTS_SHOPIFY_PLUS_HELP_LINK}
          variant="text"
          size="small"
          target="_blank"
        >
          Click here to learn more
        </Button>
      </div>
      <div className="flex flex-row justify-start w-full"></div>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(onWidgetSave)}
      >
        <Form.Field
          label="Enable Discounts"
          name="enabled"
          control="checkbox"
          ref={register()}
          spacing="loose"
          helpText="Check this option to enable discounts for recommended products in this widget."
        />
        {watchEnabled === true && (
          <React.Fragment>
            <div className="flex flex-row justify-between -mx-4">
              <div className="flex-1 px-4">
                <Form.Field
                  label="Discount Type"
                  name="type"
                  control="select"
                  options={[
                    { label: 'Amount', value: 'AMOUNT' },
                    { label: 'Percentage', value: 'PERCENTAGE' },
                  ]}
                  ref={register()}
                  default="AMOUNT"
                  helpText="How should the discount be calculated."
                />
              </div>
              <div className="flex-1 px-4">
                <Form.Field
                  label="Value"
                  name="value"
                  control="input"
                  className="flex-1 mr-4"
                  type="number"
                  min={1}
                  max={isPercentage ? 100 : 99999999}
                  ref={register()}
                  spacing="loose"
                  suffix={isPercentage ? '%' : null}
                  helpText={valueHelpText}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between -mx-4">
              <div className="flex-1 px-4">
                <Form.Field
                  label="Discount message shown in checkout"
                  name="message"
                  control="input"
                  ref={register()}
                  spacing="loose"
                  helpText="This message is shown below each discounted item bought from the widget."
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {
          mode !== 'builder' && (
            <div className="flex flex-row justify-end">
              <Button size="small">
                <div className="flex items-center justify-center">
                  <span className="mr-1">Save</span>
                  {onUpgrade && (
                    <Icons.Lock className={'w-3 mb-1 fill-current opacity-75'} />
                  )}
                </div>
              </Button>
            </div>
          )
        }
      </form>
    </Box>
  )
}
