import React from 'react'
import classNames from 'classnames'
import * as Campaigns from 'constants/campaigns'
import { SectionHeading } from '../index'

export default function CampaignSelect({ campaignsEnabled, onSelect }) {
  const campaigns = Object.keys(Campaigns)
    .map(k => Campaigns[k])
    .map(campaign => ({
      ...campaign,
      disabled: false //!campaignsEnabled.includes(campaign.value)
    }))

  return (
    <React.Fragment>
      <SectionHeading>
        Select the type of Email Campaign you want to create
      </SectionHeading>
      <div className="flex flex-wrap -mx-3">
        {campaigns.map(campaign => (
          <div className="w-full px-4 mb-6 lg:w-1/2" key={campaign.value}>
            <CampaignItem
              campaign={campaign}
              onClick={() => onSelect(campaign)}
            />
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

function CampaignItem({ campaign, onClick, index }) {
  return (
    <div
      onClick={campaign.disabled ? () => {} : onClick}
      className={classNames(
        'flex items-start p-4 cursor-pointer rounded h-full border lg:border-0',
        {
          'flex-row': index % 2 === 0,
          'lg:flex-row-reverse': index % 2 === 1,
          'group hover:bg-gray-300': !campaign.disabled,
          'opacity-50 cursor-not-allowed': campaign.disabled
        }
      )}
    >
      <div
        className={classNames('rounded-full p-2 bg-gray-200 mr-3', {
          'lg:ml-3 lg:mr-0': index % 2 === 1
        })}
      >
        <campaign.icon
          className="opacity-50 group-hover:opacity-100"
          height="40"
          width="40"
        />
      </div>
      <div className="flex flex-col items-stretch flex-1">
        <div className="mb-2 text-sm font-semibold uppercase">
          {campaign.label}
        </div>
        <p className="text-xs leading-tight">{campaign.description}</p>
      </div>
    </div>
  )
}
