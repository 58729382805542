import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Text from 'components/Text'
import { useOpenModal } from 'components/Modal'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import { trackEvent } from 'utils/analytics'
import { isFeatureEnabled } from 'utils/features'
import { getAccountData } from 'utils/account'
import { FUNNELS } from 'constants/features'

import FunnelBuilder from './FunnelBuilder'
import FunnelList from './FunnelList'
import FunnelDetails from './FunnelDetails'

export default function Funnels({ store }) {
  const openModal = useOpenModal()
  const accountData = getAccountData()

  return (
    <Switch>
      <Route path="/funnels/:funnelId">
        <FunnelDetails
          onEditFunnel={(funnel, screen) => {
            if (funnel) {
              openModal(
                <FunnelBuilder
                  initialActiveScreen={screen}
                  initialFunnel={funnel}
                  store={store}
                />,
                {
                  hideCloseButton: true,
                  fullScreen: true,
                  className: 'bg-white h-screen',
                }
              )
            }
          }}
        />
      </Route>
      <Route path="/funnels">
        <FunnelHome accountData={accountData} store={store} />
      </Route>
    </Switch>
  )
}

function FunnelHome({ accountData, store }) {
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(accountData, FUNNELS)

  return (
    <div className="flex flex-col ">
      <div className="flex flex-row items-center justify-between mb-5">
        <Text.ScreenHeading marginBottom={0}>
          Checkout Funnels
        </Text.ScreenHeading>
        <Button
          onClick={() => {
            trackEvent({
              category: FUNNELS.label,
              label: 'Dashboard',
              action: 'Click add funnel',
            })
            if (!featureEnabled) {
              openModal(
                <UpgradeAccountModal
                  featureToEnable={FUNNELS}
                  account={{ data: accountData }}
                  customMessage={
                    <React.Fragment>
                      <span className="mr-1 font-semibold">
                        {FUNNELS.label}
                      </span>{' '}
                      is currently a beta feature. If you would like to opt in,
                      let us know in the chat
                    </React.Fragment>
                  }
                />
              )
            } else {
              openModal(<FunnelBuilder store={store} />, {
                hideCloseButton: true,
                fullScreen: true,
                className: 'bg-white h-screen',
              })
            }
          }}
        >
          <div className="flex items-center justify-center">
            <span className="">+ Add New Funnel</span>
            {!featureEnabled && (
              <Icons.Lock className={'w-5 ml-4 mb-1 fill-current opacity-75'} />
            )}
          </div>
        </Button>
      </div>
      <div className="flex flex-row w-full">
        <FunnelList />
      </div>
    </div>
  )
}
