import React from 'react'

import Icons from 'components/Icons'
import EditorForm from 'components/EditorForm'
import { CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES } from 'constants/support'

export default function LineSettings({
  value,
  handleUpdate,
  handleLineUpdate,
  handleLineDelete,
  handleLineAdd,
}) {
  const { textBlockVisible, lines } = value
  const canLineBeDeleted = lines?.length > 1
  return (
    <div className="flex flex-col items-stretch">
      <EditorForm
        layout={VISIBILITY_LAYOUT}
        value={value}
        handleUpdate={(update) => {
          handleUpdate(update)

          if (!value.lines?.length) {
            setTimeout(() => {
              handleLineAdd()
            })
          }
        }}
      />
      {textBlockVisible && (
        <React.Fragment>
          <div className="mt-6">
            <EditorForm
              layout={GENERAL_SETTINGS_LAYOUT}
              value={value}
              handleUpdate={handleUpdate}
            />
          </div>
          {(value.lines || []).map((line, index) => (
            <div
              className="flex flex-col px-4 py-3 mt-6 -mx-4 border-t border-b border-gray-100"
              key={line.id}
            >
              <div className="flex flex-row mb-3">
                <span className="text-sm font-semibold text-gray-600">
                  Line {index + 1}
                </span>
                {canLineBeDeleted && (
                  <Icons.Delete
                    className="w-3.5 ml-auto text-red-400 hover:text-red-600"
                    onClick={() => handleLineDelete(line.id)}
                  />
                )}
              </div>

              <EditorForm
                layout={LINE_LAYOUT}
                value={line}
                handleUpdate={(update) =>
                  handleLineUpdate({ id: line.id, update })
                }
              />
            </div>
          ))}
          <button
            className="flex items-center justify-center w-full py-2 my-3 font-medium rounded-sm cursor-pointer text-primary bg-primary-200"
            onClick={handleLineAdd}
          >
            +<span>Add line</span>
          </button>
        </React.Fragment>
      )}
    </div>
  )
}

const VISIBILITY_LAYOUT = [
  {
    key: 'textBlockVisible',
    label: 'Add text block alongside image',
    controlType: 'checkbox',
  },
]

const GENERAL_SETTINGS_LAYOUT = [
  [
    {
      key: 'textBlockPlacement',
      label: 'Block placement',
      controlType: 'single_select',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Right', value: 'right' },
      ],
    },
    {
      key: 'spacing',
      label: 'Spacing from Image',
      controlType: 'single_select',
      options: [
        { label: 'Extra Tight', value: 'xtight' },
        { label: 'Tight', value: 'tight' },
        { label: 'Loose', value: 'loose' },
        { label: 'Extra Loose', value: 'xloose' },
      ],
    },
  ],
  [
    {
      key: 'alignment',
      label: 'Block alignment',
      controlType: 'single_select',
      options: [
        { label: 'Image top', value: 'leading' },
        { label: 'Image center', value: 'center' },
        { label: 'Image bottom', value: 'trailing' },
      ],
    },
    {
      key: 'textBlockSpacing',
      label: 'Spacing between lines',
      controlType: 'single_select',
      options: [
        { label: 'Extra Tight', value: 'xtight' },
        { label: 'Tight', value: 'tight' },
        { label: 'Loose', value: 'loose' },
        { label: 'Extra Loose', value: 'xloose' },
      ],
    },
  ],
]

const LINE_LAYOUT = [
  [
    {
      key: 'text',
      label: 'Text',
      controlType: 'textarea',
      helpArticle: CHECKOUT_UPSELL_INTERCOM_HELP_ARTICLES.DYNAMIC_FIELDS,
      helpText: 'Use dynamic fields to modify text blocks.',
    },
  ],
  [
    {
      key: 'timer',
      label: 'Allow Timer',
      controlType: 'timer',
    },
  ],
  [
    {
      key: 'appearance',
      label: 'Color',
      controlType: 'single_select',
      options: [
        { label: 'Gray', value: 'regular' },
        { label: 'Red', value: 'critical' },
        { label: 'Yellow', value: 'warning' },
        { label: 'Green', value: 'success' },
      ],
    },
    {
      key: 'size',
      label: 'Size',
      controlType: 'single_select',
      options: [
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'Extra Large', value: 'xlarge' },
      ],
    },
  ],
]
