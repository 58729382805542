import React from 'react'
import MonacoEditor from '@monaco-editor/react'

import './theme.css'

export default function CodeEditor({
  value,
  onChange,
  name,
  placeholder,
  mode,
  rkSupport,
  height = '50vh',
}) {
  return (
    <div
      style={{
        width: '100%',
        margin: '20px 0 40px 0',
        zIndex: 0,
      }}
      id={name}
    >
      <MonacoEditor
        height={height}
        defaultLanguage={mode}
        value={value}
        options={{ fontSize: 14 }}
        onChange={onChange}
        className="notranslate"
      />
    </div>
  )
}
