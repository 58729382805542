import React from 'react'
import classNames from 'classnames'
import Badge from 'components/Badge'
import Box from 'components/Box'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import { Switch } from 'components/Form/controls'
import {
  campaignLabelFromType,
  campaignDescriptionFromType,
  channelLabelFromType
} from 'utils/constants'
import { campaignUpdate as campaignUpdateApi } from 'api/campaigns'
import { useQueryParams } from 'hooks/router'

export default function CampaignsCard({ campaigns }) {
  const { shop } = useQueryParams()
  async function onCampaignStateChange(campaignId, isActive) {
    try {
      const newState = isActive ? 'active' : 'paused'
      const { data } = await campaignUpdateApi(
        campaignId,
        { state: newState },
        { shop }
      )

      campaigns.update(
        {
          ...campaigns.data,
          campaigns: campaigns.data.campaigns.map(item => {
            return item.id === campaignId ? data.campaign : item
          })
        },
        { local: true }
      )
    } catch (err) {}
  }

  return (
    <React.Fragment>
      {campaigns.data.campaigns
        .filter(
          campaign => campaign.state === 'active' || campaign.state === 'paused'
        )
        .map(campaign => {
          const isActive = campaign.state === 'active'

          return (
            <Box
              className={classNames('flex flex-col items-stretch mb-3', {
                ['opacity-50 pb-6 hover:opacity-100']: !isActive
              })}
              key={campaign.id}
            >
              <div className="flex flex-row items-baseline justify-between w-full">
                <div className="mb-2 text-xl font-semibold leading-tight text-gray-800">
                  {campaign.name}
                </div>
                <Tooltip
                  position="right"
                  content={
                    isActive
                      ? 'Disable this campaign'
                      : campaignDescriptionFromType(campaign.recommenationType)
                  }
                >
                  <Switch
                    checked={isActive}
                    onChange={value =>
                      onCampaignStateChange(campaign.id, value)
                    }
                  />
                </Tooltip>
              </div>
              <div className="flex flex-row items-center">
                <Badge
                  color="yellow"
                  className="mr-2 uppercase"
                  spacing="regular"
                  textSize="small"
                >
                  {campaignLabelFromType(campaign.recommendationType)}
                </Badge>
                <Badge
                  color="yellow"
                  className="mr-2 uppercase"
                  spacing="regular"
                  textSize="small"
                >
                  {channelLabelFromType(campaign.channelSubType)}
                </Badge>
                {!isActive && (
                  <Badge
                    color="neutral"
                    className="uppercase"
                    spacing="regular"
                  >
                    Disabled
                  </Badge>
                )}
              </div>
              <div className="self-end">
                <Tooltip
                  position="right"
                  content="You can configure the email template for this campaign"
                >
                  <Button
                    size="xSmall"
                    variant="bordered"
                    href={`/campaigns/${campaign.id}/`}
                    internalLink={true}
                  >
                    Configure
                  </Button>
                </Tooltip>
              </div>
            </Box>
          )
        })}
    </React.Fragment>
  )
}
