import React from 'react'
import classNames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Icons from 'components/Icons'
import { _find, _get } from 'utils/lodash'
import { PROMOTION_LAYOUT_SECTION_TYPES } from 'constants/promotions'

export default function SectionNavigation({
  sections = [],
  handleSectionReorder,
  onSectionClick,
}) {
  function onDragEnd(result) {
    if (!result.destination || !result.source) {
      return
    }

    const reorderedSections = orderSections(
      sections.map((s) => s.uuid),
      result.source.index,
      result.destination.index
    )
    handleSectionReorder(reorderedSections)
  }

  return (
    // <DragDropContext onDragEnd={onDragEnd}>
    //   <Droppable droppableId="editorSections">
    //     {(provided, snapshot) => {
    //       return (
    <ul
      // {...provided.droppableProps}
      // ref={provided.innerRef}
      // style={getListStyle(snapshot.isDraggingOver)}
      className="flex flex-col items-stretch flex-1 bg-white border-t-4 border-gray-50"
    >
      {sections.map((section, index) => {
        return (
          // <Draggable
          //   key={section.uuid}
          //   draggableId={section.uuid}
          //   index={index}
          // >
          //   {(provided, snapshot) => {
          //     return (
          <li
            // ref={provided.innerRef}
            // {...provided.draggableProps}
            // {...provided.dragHandleProps}
            // style={getItemStyle(
            //   snapshot.isDragging,
            //   provided.draggableProps.style
            // )}
            key={section.uuid}
            className={classNames(
              'group flex items-center px-4 text-base font-medium cursor-pointer group h-16 hover:bg-gray-10 border-b border-gray-200 relative'
            )}
            onClick={() => onSectionClick(section.uuid)}
          >
            {/* <Icons.Drag className="w-2 mr-2 text-gray-600 fill-current hover:text-gray-800" /> */}
            <span>{sectionLabelFromType(section.type)}</span>
            <Icons.ChevronRight className="w-5 ml-auto text-gray-600 transition-transform -translate-x-2 group-hover:text-primary group-hover:translate-x-0" />
          </li>
        )
        //   }}
        // </Draggable>
      })}
    </ul>
  )
  //     }}
  //   </Droppable>
  // </DragDropContext>
}

function getListStyle(isDraggingOver) {
  return {
    ...(isDraggingOver ? { background: '#f9f9f9' } : {}),
  }
}

function getItemStyle(isDragging, draggableStyle) {
  return {
    userSelect: 'none',
    ...(isDragging ? { background: 'lightblue' } : {}),
    ...draggableStyle,
  }
}

function orderSections(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function sectionLabelFromType(type) {
  return _get(
    _find(
      Object.keys(PROMOTION_LAYOUT_SECTION_TYPES).map(
        (k) => PROMOTION_LAYOUT_SECTION_TYPES[k]
      ),
      { value: type }
    ),
    'label'
  )
}
