import classNames from 'classnames'
import EditorForm from 'components/EditorForm'
import { Switch } from 'components/Form/controls'
import React from 'react'

export default function Actions({
  value,
  handleUpdate,
  accountData,
  formError,
}) {
  const {
    settings: { actions: actionValues },
  } = value
  function updateAction(action, index) {
    let updatedActions = [...actionValues]
    updatedActions[index] = action
    handleUpdate({
      settings: {
        actions: updatedActions,
      },
    })
  }
  return (
    <div className="flex flex-col">
      {actionValues.map((action, index) => {
        return (
          <ActionItem
            action={action}
            key={action.type}
            handleUpdate={(action) => updateAction(action, index)}
            formError={formError ? formError[index] : null}
          />
        )
      })}
    </div>
  )
}

function ActionItem({ action, handleUpdate, formError }) {
  const { enabled, type } = action

  return (
    <div className="flex flex-col py-4 border-b border-gray-400 last:border-none">
      <div className="flex flex-row items-center justify-between mb-4">
        <span className="text-sm font-semibold uppercase">
          {ACTION_TYPE_TO_LABEL[type]}
        </span>
        <Switch
          checked={enabled}
          onChange={(value) => {
            handleUpdate({
              ...action,
              enabled: value,
            })
          }}
        />
      </div>
      <div
        className={classNames({
          'opacity-70 cursor-not-allowed pointer-events-none': !enabled,
        })}
      >
        <EditorForm
          value={action}
          layout={ACTION_LAYOUT}
          handleUpdate={(values) => {
            const updateObj = {
              ...action,
              ...values,
            }
            handleUpdate(updateObj)
          }}
          formError={formError}
        />
      </div>
    </div>
  )
}

const ACTION_TYPE_TO_LABEL = {
  cart: 'Cart Button',
  checkout: 'Checkout Button',
  continue: 'Continue Shopping Button',
}

const ACTION_LAYOUT = [
  {
    key: 'label',
    label: 'Button Label',
    controlType: 'translation',
  },
  {
    key: 'backgroundColor',
    label: 'Button Background',
    controlType: 'color',
  },
  {
    key: 'textColor',
    label: 'Button Text',
    controlType: 'color',
  },
  {
    key: 'priority',
    label: 'Priority',
    controlType: 'single_select',
    options: [
      {
        label: 'Primary',
        value: 1,
      },
      {
        label: 'Secondary',
        value: 2,
      },
      {
        label: 'Tertiary',
        value: 3,
      },
    ],
  },
]
