import api from 'api'

import { fakeApi } from 'utils/misc'

export async function fetchProducts({ queryKey, pageParam = { page: 1 } }) {
  const [_key, { order, filters, shop }] = queryKey

  return api
    .post(
      'shopify/resources/products/',
      {
        ...filters,
        ...pageParam,
      },
      {
        params: {
          shop,
        },
      }
    )
    .then((response) => {
      return {
        data: {
          data: response.data.products,
          total: response.data.totalPages,
          pageParam,
        },
      }
    })
}
