import React from 'react'
import { openPopupWidget } from 'react-calendly'

import Box from 'components/Box'
import { HubspotContactForm } from 'components/ExternalScripts/Hubspot/ContactForm'
import { BecomePartnerForm } from 'components/ExternalScripts/Hubspot/BecomePartnerForm'
import { _get } from 'utils/lodash'

export function openChat() {
  if (!window.HubSpotConversations?.widget) {
    return
  }

  window.HubSpotConversations.widget.open()
}

export function openRequestDemo({ accountData }) {
  return (
    <Box>
      <HubspotContactForm />
    </Box>
  )
}

export function openPartnerProgram({ accountData }) {
  return (
    <Box>
      <BecomePartnerForm />
    </Box>
  )
}
