import React from 'react'
import classNames from 'classnames'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { useApi } from 'hooks/api'
import { bannerRemove as bannerRemoveApi } from 'api/banners'
import Redirect from 'components/Redirect'
import Dashboard from 'containers/Dashboard'
import Experiences from 'containers/Experiences'
import Widget from 'containers/Widget'
import Translations from 'containers/Translations'
import Plans from 'containers/Plans'
import RecommendationConfiguration from 'containers/RecommendationConfiguration'
import Analytics from 'containers/Analytics'
import Campaigns from 'containers/Campaigns'
import Campaign from 'containers/Campaign'
import Account from 'containers/Account'
import Integrations from 'containers/Integrations'
import Templates from 'containers/Templates'
import Template from 'containers/Template'
import Segments from 'containers/Segments'
import Visitors from 'containers/Visitors'
import AdminPanel from 'containers/AdminPanel'
import Settings from 'containers/Settings'
import Attribution from 'containers/Attribution'
import Funnels from 'containers/Funnels'
import Bundles from 'containers/Bundles'
import Advanced from 'containers/Advanced'
import Popups from 'containers/Popups'
import MiniCart from 'containers/MiniCart'

import Banner from './Banner'
import Header from './Header'
import Sidebar from './Sidebar'
import GloodBanner from './GloodBanner'
import styles from './styles.module.css'

export default function Home({ account, store, shops }) {
  const { name: shop } = account.data
  // const { onboarding } = store.data
  const { path } = useRouteMatch()

  const banners = useApi('banners/', { shop })
  const activeBanner = (banners.data || [])[0]

  // if (!onboarding) {
  //   return null
  // }

  // if (!onboarding.completed) {
  //   return <Redirect to="/onboarding" />
  // }

  async function removeBanner(banner) {
    await bannerRemoveApi(banner.id, { shop })
    banners.update([], { local: true })
  }

  return (
    <div className={classNames(styles.container, 'flex flex-1 bg-gray-200')}>
      <Sidebar account={account} store={store} />
      <Header account={account.data} store={store.data} shops={shops} />
      <div className="w-full max-w-full p-6">
        {false && activeBanner && (
          <Banner banner={activeBanner} onClose={removeBanner} />
        )}
        {false && <GloodBanner />}
        <Switch>
          <Route exact path={path}>
            <Dashboard account={account} store={store} />
          </Route>
          <Route path={`${path}experiences`}>
            <Experiences account={account} store={store} />
          </Route>
          <Route path={`${path}translations`}>
            <Translations account={account} store={store} />
          </Route>
          <Route path={`${path}campaigns/:campaignId`}>
            <Campaign account={account} store={store} />
          </Route>
          <Route path={`${path}campaigns`}>
            <Campaigns account={account} store={store} />
          </Route>
          <Route path={`${path}widgets/:widgetId`}>
            <Widget account={account} store={store} />
          </Route>
          <Route path={`${path}plans`}>
            <Plans account={account} store={store} />
          </Route>
          <Route path={`${path}advanced`}>
            <Advanced account={account} store={store} />
          </Route>
          <Route path={`${path}adminpanel`}>
            <AdminPanel account={account} store={store} />
          </Route>
          <Route path={`${path}recommendationconfig`}>
            <RecommendationConfiguration account={account} store={store} />
          </Route>
          {/* <Route path={`${path}recommendations`}>
            <Recommendations account={account} store={store} />
          </Route> */}
          <Route path={`${path}analytics`}>
            <Analytics account={account} store={store} />
          </Route>
          <Route path={`${path}account`}>
            <Account account={account} store={store} />
          </Route>
          <Route path={`${path}integrations`}>
            <Integrations account={account} store={store} />
          </Route>
          <Route path={`${path}templates/:templateId`}>
            <Template account={account} store={store} />
          </Route>
          <Route path={`${path}templates`}>
            <Templates account={account} store={store} />
          </Route>
          <Route path={`${path}segments`}>
            <Segments account={account} store={store} />
          </Route>
          <Route path={`${path}visitors`}>
            <Visitors account={account} store={store} />
          </Route>
          <Route path={`${path}settings`}>
            <Settings account={account} store={store} />
          </Route>
          <Route path={`${path}attribution`}>
            <Attribution account={account} store={store} />
          </Route>
          <Route path={`${path}funnels`}>
            <Funnels account={account} store={store} />
          </Route>
          <Route path={`${path}bundles`}>
            <Bundles account={account} store={store} />
          </Route>
          <Route path={`${path}popups`}>
            <Popups account={account} store={store} />
          </Route>
          <Route path={`${path}minicart`}>
            <MiniCart account={account} store={store} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}
