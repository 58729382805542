import React, { useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  Switch,
  useHistory,
  useLocation,
  Route as DomRoute,
} from 'react-router-dom'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'

import { fetchAccountConfig } from 'api/account'
import Home from 'containers/Home'
import Onboarding from 'containers/Onboarding'
import AuthFail from 'components/AuthFail'
import Route from 'components/Route'
import Loader from 'components/Loader'
import ExternalScripts from 'components/ExternalScripts'
import LanguageSwitcher from 'components/LanguageSwitcher'
import Install from 'components/Auth/Install'
import Callback from 'components/Auth/Callback'
import OnlineCallback from 'components/Auth/OnlineCallback'
import { useLogin } from 'hooks/auth'
import { useQueryParams } from 'hooks/router'
import { useApi } from 'hooks/api'
import { trackPageView } from 'utils/analytics'
import { _get } from 'utils/lodash'
import * as Tracker from 'utils/tracker'
import * as QueryUtils from 'utils/query'
import { isRkSupport } from 'utils/account'
import { isEmbeddedApp } from 'utils/shopify'
import { APP_BRIDGE_CONFIG } from 'constants/shopify'
import { REDIRECT_ON_LOGIN_STORAGE_KEY } from 'constants/support'

export default function AppRoot() {
  const location = useLocation()
  const history = useHistory()
  const host = new URLSearchParams(window.location.search).get('host')
  const isEmbedded = React.useMemo(() => isEmbeddedApp(), [])
  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  )

  return (
    <Switch>
      <DomRoute path="/install" component={Install} />
      <DomRoute path="/callback" component={Callback} />
      <DomRoute path="/online-callback" component={OnlineCallback} />
      <DomRoute path="/">
        {!host && !isEmbedded ? (
          <App />
        ) : (
          <AppBridgeProvider config={APP_BRIDGE_CONFIG} router={router}>
            <App />
          </AppBridgeProvider>
        )}
      </DomRoute>
    </Switch>
  )
}

function App() {
  const [success, loading, error, shops] = useLogin()
  const history = useHistory()

  React.useEffect(() => {
    history.listen(trackPageView)
  }, [])

  if (loading) {
    return (
      <div className="flex items-center justify-center flex-1">
        <Loader message="Fetching store details" />
      </div>
    )
  }

  if (error) {
    return <AuthFail />
  }

  return success && <WithAccount shops={shops} />
}

function WithAccount({ shops }) {
  const { shop } = useQueryParams()
  const history = useHistory()
  const account = useQuery(
    'account',
    () => {
      return fetchAccountConfig(shop)
    },
    {
      cacheTime: Infinity,
      //Consider account config api data to be stale and refetch it again after 20 mins.
      staleTime: 20 * 60 * 1000,
    }
  )
  React.useEffect(() => {
    if (account.data && account.isSuccess) {
      const accountData = QueryUtils.data(account)
      waitForAnalyticsAndIdentify(accountData)

      const redirectUrl = localStorage.getItem(REDIRECT_ON_LOGIN_STORAGE_KEY)
      if (redirectUrl) {
        localStorage.removeItem(REDIRECT_ON_LOGIN_STORAGE_KEY)
        history.replace({
          pathname: redirectUrl.replace('/setup', ''),
          search: `?shop=${_get(accountData, 'name')}`,
        })
      }
    }
  }, [account.data])

  if (!shop) {
    return null
  }

  let content = null
  if (account.isLoading) {
    content = (
      <div className="flex items-center justify-center flex-1">
        <Loader message="Fetching store details" />
      </div>
    )
  } else if (account.isError) {
    content = (
      <div className="flex items-center justify-center w-full">
        Trouble fetching account details...
      </div>
    )
  } else if (account.data && account.isSuccess) {
    const accountData = QueryUtils.data(account)
    const rkSupport = isRkSupport(accountData)

    if (!accountData.isRkSupport) {
      Tracker.configure({
        shopName: accountData.name,
        shopId: accountData.id,
      })
    }

    content = (
      <div className="flex flex-1">
        <Switch>
          <Route path="/onboarding" component={Onboarding} account={account} />
          <Route path="/" component={Home} account={account} shops={shops} />
        </Switch>
        {!rkSupport && process.env.NODE_ENV === 'production' && (
          <ExternalScripts />
        )}
        {process.env.NODE_ENV === 'production' && <LanguageSwitcher />}
      </div>
    )
  }

  return content
}

let analyticsCheckInterval
function waitForAnalyticsAndIdentify(accountData) {
  if (window.analytics && typeof window.analytics.identify === 'function') {
    clearTimeout(analyticsCheckInterval)
    window.analytics.identify(accountData.name, {
      plan: accountData.plan && accountData.plan.name,
      shopifyPlan: accountData.shop && accountData.shop.planName,
    })
  } else {
    analyticsCheckInterval = setTimeout(
      () => waitForAnalyticsAndIdentify(accountData),
      1000
    )
  }
}
