import React from 'react'
import classNames from 'classnames'
import { useQuery } from 'react-query'

import Workflow from 'containers/Funnels/FunnelBuilder/Workflow'
import { ConditionGroup } from 'containers/Funnels/FunnelBuilder/Trigger'
import { TRIGGERS } from 'containers/Funnels/FunnelBuilder/Trigger/constants'
import WaitForData from 'components/WaitForData'
import Icons from 'components/Icons'
import Button from 'components/Button'
import { useOpenModal } from 'components/Modal'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { FUNNELS } from 'constants/features'
import { fetchTrigger as fetchTriggerApi } from 'api/funnel'
import { _get, _find, _identity } from 'utils/lodash'
import { isFeatureEnabled } from 'utils/features'
import * as QueryUtils from 'utils/query'

export default function Overview({
  onEdit,
  funnel,
  mode = 'builder',
  accountData,
  builder,
}) {
  const canPublish = isFeatureEnabled(accountData, FUNNELS)
  const openModal = useOpenModal()
  const isBuilder = mode == 'builder'
  if (!funnel) {
    return null
  }
  return (
    <div className="flex flex-col items-stretch py-10">
      {!canPublish && isBuilder && (
        <div
          className={classNames('flex flex-col items-stretch mb-6', {
            'px-2': mode === 'details',
          })}
        >
          <div className="flex flex-row items-center justify-between px-5 py-3 rounded bg-primary-200">
            <div className="flex flex-col items-stretch mr-4">
              <span className="mb-1 text-xl font-semibold text-primary">
                You are on {accountData.plan.name} Plan
              </span>
              <span className="text-sm font-medium text-gray-800">
                Live funnels are supported on PRO plans. Start your free trial
                now to set funnel live
              </span>
            </div>
            <Button
              onClick={() => {
                openModal(
                  <UpgradeAccountModal
                    featureToEnable={FUNNELS}
                    account={{ data: accountData }}
                  />
                )
              }}
            >
              Upgrade Plan
            </Button>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 border-b border-gray-200',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <DetailsReview funnel={funnel} onEdit={() => onEdit('details')} />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <TriggerReview
          funnel={funnel}
          accountData={accountData}
          onEdit={() => onEdit('trigger')}
        />
      </div>
      <div
        className={classNames(
          'flex flex-col items-stretch pb-6 mt-6 border-b border-gray-100',
          {
            'px-2': mode === 'details',
          }
        )}
      >
        <WorkflowReview
          builder={builder}
          funnel={funnel}
          accountData={accountData}
          onEdit={() => onEdit('workflow')}
        />
      </div>
    </div>
  )
}

export function DetailsReview({ funnel, accountData, onEdit }) {
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-2">
        <Icons.CircleTick className="w-6 mr-2 text-primary" />
        <span className="text-lg font-semibold text-gray-800">Details</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <div className="flex flex-row items-start pl-8">
        <div className="flex flex-col mr-16">
          <span className="mb-1 text-sm text-gray-600">Name</span>
          <span className="font-medium text-gray-800">{funnel.name}</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export function TriggerReview({ funnel, accountData, onEdit }) {
  const complete = Object.keys(funnel.trigger).length > 0
  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-4">
        <Icons.CircleTick
          className={classNames('w-6 mr-2', {
            'text-gray-300': !complete,
            'text-primary': complete,
          })}
        />
        <span className="text-lg font-semibold text-gray-800">Trigger</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          {complete ? 'Edit' : 'Add'}
        </Button>
      </div>
      {complete ? (
        <div className="flex flex-row items-start pl-8">
          <ConditionGroup
            condition={funnel.trigger}
            triggers={TRIGGERS}
            readOnly={true}
            onUpdateCondition={_identity}
          />
        </div>
      ) : null}
    </React.Fragment>
  )
}

export function WorkflowReview({ funnel, accountData, onEdit, builder }) {
  const complete =
    Object.keys(funnel.workflow.tasks ? funnel.workflow.tasks : funnel.workflow)
      .length > 0

  return (
    <React.Fragment>
      <div className="flex flex-row items-center mb-4">
        <Icons.CircleTick
          className={classNames('w-6 mr-2', {
            'text-gray-300': !complete,
            'text-primary': complete,
          })}
        />
        <span className="text-lg font-semibold text-gray-800">Funnel</span>
        <Button
          size="small"
          variant="light"
          className="ml-auto"
          onClick={onEdit}
        >
          {complete ? 'Edit' : 'Add'}
        </Button>
      </div>
      {complete ? (
        <div className="flex flex-row items-start justify-center pl-8 mt-5">
          <Workflow
            funnel={funnel}
            builder={builder}
            readOnly={true}
            accountData={accountData}
          />
        </div>
      ) : null}
    </React.Fragment>
  )
}
