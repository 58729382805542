import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import { useToast } from 'components/Toast'
import { EMAIL_SUPPORT } from 'constants/support'
import CodeEditor from 'components/CodeEditor'
import { isRkSupport } from 'utils/account'

export default function CSS({ store, account }) {
  const [css, setCss] = React.useState(store.data.css || '')
  const rkSupport = isRkSupport(account.data)

  const openToast = useToast()

  async function onSave() {
    try {
      await store.update({ css })
      openToast({
        type: 'success',
        text: 'Custom CSS has been updated successfully!',
      })
    } catch (err) {
      openToast({
        type: 'error',
        text: 'Custom CSS could not bt saved successfully!',
      })
    }
  }

  return (
    <div className="flex flex-col items-stretch">
      <Text.ScreenHeading marginBottom={1}>Add Custom Style</Text.ScreenHeading>
      <Text.SubText marginBottom={5}>
        Add custom style to all the widgets on all pages. For any assistance
        please write to <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <div className="flex flex-col items-start flex-1 w-full">
        <div className="flex flex-row flex-wrap w-full notranslate">
          <CodeEditor
            placeholder="You can edit/add CSS code here."
            mode="css"
            value={css}
            onChange={setCss}
            name="shop_template_css_editor"
            rkSupport={rkSupport}
          />
        </div>
        <Button size="small" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  )
}
