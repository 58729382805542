import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import classNames from 'classnames'

import { _debounce } from 'utils/lodash'

// import Header from './Header'
import Pagination from './Pagination'
import Filters from './Filters'
import Table from './Table'
import useResourceList from './hooks/useResourceList'

export default function ResourceList({
  resourceType,
  initialView,
  schema,
  CustomTableCell,
  options,
}) {
  const resourceList = useResourceList({
    resourceType,
    initialView,
    schema,
    CustomTableCell,
    options,
  })
  const listWrapper = useRef(null)
  const tableWrapper = useRef(null)
  const [wrapperStyles, setWrapperStyles] = useState({})
  const [wrapperSetupDone, setWrapperSetupDone] = useState(false)

  const setWrapperDimensions = useDebouncedCallback(() => {
    const domRect = tableWrapper.current?.getBoundingClientRect()
    if (
      tableWrapper.current &&
      domRect &&
      listWrapper.current &&
      !wrapperSetupDone
    ) {
      setWrapperStyles({
        // height: window.innerHeight - domRect.top - 30,
        width: listWrapper.current.offsetWidth - 10,
      })
      setWrapperSetupDone(true)
    }
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', setWrapperDimensions.callback)

    return () =>
      window.removeEventListener('resize', setWrapperDimensions.callback)
  }, [])

  useEffect(() => {
    if (!wrapperSetupDone) {
      setWrapperDimensions.callback()
    }
  }, [listWrapper, tableWrapper])

  return (
    <div ref={listWrapper} className="flex flex-col items-stretch w-full">
      {resourceList.resolvedData ? (
        <Pagination
          pagination={resourceList.pagination}
          queryStatus={resourceList.queryStatus}
          filters={resourceList.filters}
        />
      ) : (
        <div className="flex flex-row items-center justify-between mb-3">
          <span className="w-1/4 mr-2 bg-gray-200 rounded opacity-50 h-7"></span>
          <span className="w-1/5 bg-gray-200 rounded opacity-50 h-7"></span>
        </div>
      )}
      <div
        ref={tableWrapper}
        className={'flex flex-col items-stretch rounded overflow-x-auto'}
        style={wrapperStyles}
      >
        {wrapperSetupDone && <Table resourceList={resourceList} />}
      </div>
      <Filters filters={resourceList.filters} />
    </div>
  )
}
