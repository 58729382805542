import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Text from 'components/Text'
import { useOpenModal } from 'components/Modal'
import Button from 'components/Button'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import Icons from 'components/Icons'
import { trackEvent } from 'utils/analytics'
import { isFeatureEnabled } from 'utils/features'
import { getAccountData } from 'utils/account'
import { POPUPS } from 'constants/features'

import PopupList from './PopupList'
import PopupBuilder from './PopupBuilder'
import PopupDetails from './PopupDetails'

export default function Popups({}) {
  const accountData = getAccountData()
  const openModal = useOpenModal()
  return (
    <Switch>
      {/* <Route path="/popups/:popupId">
        <PopupDetails
          onEditPopup={(popup, screen) => {
            if (popup) {
              openModal(
                <PopupBuilder
                  initialActiveScreen={screen}
                  initialPopup={popup}
                />,
                {
                  hideCloseButton: true,
                  fullScreen: true,
                  className: 'bg-white h-screen',
                }
              )
            }
          }}
        />
      </Route> */}
      <Route path="/popups">
        <PopupHome accountData={accountData} />
      </Route>
    </Switch>
  )
}

function PopupHome({ accountData }) {
  const openModal = useOpenModal()
  const featureEnabled = isFeatureEnabled(accountData, POPUPS)

  return (
    <div className="flex flex-col ">
      <div className="flex flex-row items-center justify-between mb-5">
        <Text.ScreenHeading marginBottom={0}>Popups</Text.ScreenHeading>
        <Button
          onClick={() => {
            trackEvent({
              category: POPUPS.label,
              label: 'Dashboard',
              action: 'Click add popup',
            })
            if (!featureEnabled) {
              openModal(
                <UpgradeAccountModal
                  featureToEnable={POPUPS}
                  account={{ data: accountData }}
                  customMessage={
                    <React.Fragment>
                      <span className="mr-1 font-semibold">{POPUPS.label}</span>{' '}
                      is in beta. If you would like to opt in as a beta user,
                      let us know in the chat
                    </React.Fragment>
                  }
                />
              )
            } else {
              openModal(
                <PopupBuilder
                  initialPopup={{
                    id: 3,
                    name: 'New popup',
                    recommendationType: null,
                    triggers: {},
                    template: null,
                    inclusions: [
                      { pageType: 'home', pageLinks: [] },

                      {
                        pageType: 'product_page',
                        pageLinks: [
                          `https://${accountData.name}/products/some_product`,
                          `https://${accountData.name}/products/some_other_product`,
                        ],
                      },
                    ],
                    exclusions: [
                      {
                        pageLinks: [
                          `https://${accountData.name}/products/some_product`,
                          `https://${accountData.name}/products/some_other_product`,
                        ],
                      },
                    ],
                  }}
                />,
                {
                  hideCloseButton: true,
                  fullScreen: true,
                  className: 'bg-white h-screen',
                }
              )
            }
          }}
        >
          <div className="flex items-center justify-center">
            <span className="">+ Add New Popup</span>
            {!featureEnabled && (
              <Icons.Lock className={'w-5 ml-4 mb-1 fill-current opacity-75'} />
            )}
          </div>
        </Button>
      </div>
      <div className="flex flex-row w-full">
        <PopupList />
      </div>
    </div>
  )
}
