import React from 'react'

import Text from 'components/Text'
import Button from 'components/Button'
import Icons from 'components/Icons'
import TemplateErrorBox from 'components/TemplateErrorBox'
import CodeEditor from 'components/CodeEditor'
import { canUpdateTemplate } from 'utils/features'
import { isRkSupport } from 'utils/account'
import { EMAIL_SUPPORT } from 'constants/support'

export default function TemplateLiquid({
  account,
  template,
  isSaving,
  onSave,
  openUpgradeAccount,
}) {
  const [liquidTemplate, setLiquidTemplate] = React.useState(template.template)

  const canCreateTemplate = canUpdateTemplate(account.data)

  function handleOnClick() {
    if (openUpgradeAccount) {
      openUpgradeAccount()
    } else {
      onSave({ template: liquidTemplate })
    }
  }

  function handleChange(value) {
    setLiquidTemplate(value)
  }

  return (
    <div
      className="flex flex-col items-stretch"
      style={{ height: template.htmlError ? '125vh' : '100%' }}
    >
      <Text.ScreenHeading marginBottom={1}>
        Edit Liquid Template
      </Text.ScreenHeading>
      <Text.SubText marginBottom={2}>
        For any assistance please write to{' '}
        <a href={`mailto:${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
      </Text.SubText>

      <div className="relative flex flex-col items-center flex-1">
        <div className="flex flex-col items-start w-full">
          <CodeEditor
            placeholder="You can add Liquid code here."
            mode="liquid"
            value={liquidTemplate}
            onChange={setLiquidTemplate}
            name="shop_liquid_editor"
            rkSupport={isRkSupport(account.data)}
          />

          <Button loading={isSaving} onClick={handleOnClick}>
            <div className="flex items-center justify-center">
              <span>Save</span>
              {!canCreateTemplate && (
                <Icons.Lock className="w-4 ml-2 opacity-75 fill-current" />
              )}
            </div>
          </Button>
        </div>
        {template.htmlError && (
          <TemplateErrorBox label="Liquid Error" error={template.htmlError} />
        )}
      </div>
    </div>
  )
}
