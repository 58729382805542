import React from 'react'
import classNames from 'classnames'

import Icons from 'components/Icons'

export default function ResourceBuilderHeader({
  builder,
  rb,
  PrimaryCTA,
  SecondaryCTA,
}) {
  const { screens, activeScreen, setActiveScreen } = builder

  return (
    <div className="flex flex-row items-stretch h-16 px-8 shadow-navbar">
      <div className="flex flex-row items-center">
        <Icons.Cross
          className="w-8 h-8 mr-2 cursor-pointer text-primary"
          onClick={rb.close}
        />
        <span className="text-lg font-semibold">
          {builder.heading ? builder.heading : 'Create Resource'}
        </span>
      </div>
      <nav className="flex flex-row items-stretch ml-auto mr-auto">
        {screens.map((screen, index) => {
          const isComplete = screen.complete(rb.resource)
          const isActive = activeScreen.name === screen.name
          const isDisabled = screen.disabled(rb.resource)

          return (
            <div
              className={classNames('flex flex-row items-center px-8', {
                'border-b-2 border-primary-600': isActive && !isDisabled,
                'opacity-60': isDisabled,
                'cursor-pointer hover:bg-gray-10': !isDisabled,
              })}
              key={screen.name}
              onClick={() => !isDisabled && setActiveScreen(screen.name)}
            >
              <Icons.CircleTick
                className={classNames('w-4 mr-2', {
                  'text-gray-400': !isComplete && isActive,
                  'text-gray-300': !isComplete && !isActive,
                  'text-primary': isComplete,
                })}
              />
              <span
                className={classNames('text-sm whitespace-no-wrap', {
                  'text-gray-400': !isActive,
                  'text-primary': isActive,
                })}
              >
                {index + 1}. {screen.label}
              </span>
            </div>
          )
        })}
      </nav>
      <div className="flex flex-row items-center">
        {SecondaryCTA && <SecondaryCTA builder={builder} rb={rb} />}
        {PrimaryCTA && <PrimaryCTA builder={builder} rb={rb} />}
      </div>
    </div>
  )
}
